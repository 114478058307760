import pollberryApiService from '../../util/pollberryApiService';
import storageService from '../../util/storage';
import axios from 'axios';

async function ItemsUpload(images, apiType) {
  const token = storageService.get("auth", "accessToken", "local");
  const apiService = pollberryApiService;
  apiService.setBaererToken(token);

  const uploadPromises = images.map(async (image) => {

    const formData = new FormData();
    formData.append("image", image.url);
    if (image.imageUrl) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/image/${apiType}/item/new`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );
        formData.delete('image');

        return ({
          id: image.id,
          rank: image.rank,
          text: image.text,
          url: response.data.response.url
        });
      } catch (error) {
        console.error(error, 'error');
        formData.delete('image');
        return image;
      }
    }
    return image;
  });

  try {
    const results = await Promise.all(uploadPromises);
    return results;
  } catch (e) {
    console.error(e);
    return [];
  }
}


export default ItemsUpload;
