import React, {useState} from "react";
import {Button, TitleInputWrapper,} from "../../../common/styledComponents/commonStyle";
import {Content, ItemDetailList, PuzzleModifyBtn, TokenOptionDellBtn, Wrapper} from "./PuzzleTokenManagementStyle";

function TokenOption(props) {
    const {
        options = [],
        onAdd,
        onRemove,
        optionRef
    } = props;
    const [mode, setMode] = useState('read');
    const [targetWord, setTargetWord] = useState('');

    const handleSubmitOptions = (e) => {
        e.preventDefault();
        const {value} = optionRef.current;
        const existOption = options.find(option => {
            return option.contents === value
        });
        if (!value) {
            return;
        }
        if (existOption) {
            return;
        }
        onAdd([...options, {contents: value}]);
        optionRef.current.value = '';
        optionRef.current.focus();
    }

    const handleClickDeleteOption = (contents, e) => {
        e.stopPropagation();
        onRemove(options.filter(option => option.contents !== contents));

    }

    const handleEdit = (option) => {
        setMode('edit')
        setTargetWord(option.contents || '');
        optionRef.current.value = option.contents;
    }

    const submitEdit = (e) => {
        e.preventDefault();
        const {value} = optionRef.current;
        const existOption = options.find(option => {
            return option.contents === value
        });
        if (!value) {
            return;
        }
        if (existOption) {
            alert("이미 존재하는 항목 입니다.")
            return;
        }
        const newOptions = options.map(d => {
            if (d.contents === targetWord) {
                return {contents: value};
            } else {
                return d;
            }
        })
        optionRef.current.value = '';
        setMode('read')
        setTargetWord('');
        onAdd(newOptions);
    }

    const handleCancel = () => {
        setMode('read');
        optionRef.current.value = '';
        setTargetWord('');
    }

    return (
        <Content Column width="auto">
            <Wrapper Center Header>
                <span>항목</span>
            </Wrapper>
            <Wrapper Border Column height="731px">
                <ItemDetailList Row style={{overflow: "auto"}}>
                    {
                        options && options.map(option => (
                            <li key={option.id} onClick={() => handleEdit(option)}>
                                {option.contents}
                                <TokenOptionDellBtn
                                    onClick={handleClickDeleteOption.bind(null, option.contents)}>X</TokenOptionDellBtn>
                            </li>
                        ))
                    }
                </ItemDetailList>
                <form onSubmit={handleSubmitOptions}>
                    <Wrapper line padding={"16px 7px"}>
                        <TitleInputWrapper style={{position: "relative", width: "656px"}}>
                            <input ref={optionRef}/>
                            {
                                mode === 'edit' &&
                                (
                                    <>
                                        <PuzzleModifyBtn href="#none" onClick={handleCancel}>
                                            취소
                                        </PuzzleModifyBtn>
                                    </>
                                )
                            }
                        </TitleInputWrapper>
                        {
                            mode === 'edit' ?
                                (
                                    <>
                                        <Button short onClick={submitEdit} margin="0 0 0 8px">
                                            수정
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <Button short margin="0 0 0 8px">
                                        추가
                                    </Button>
                                )
                        }

                    </Wrapper>
                </form>
            </Wrapper>
        </Content>
    );
}

export default TokenOption;
