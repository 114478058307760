import useRequest from "hooks/useRequest";
import { useGlobalStore } from "stores";
import { useNavigate } from "react-router";

const useRepository = ({
	method,
	url,
	onSuccess = ({ response }) => response,
	onError = ({ error }) => error,
	type,
	template = (e) => e,
}) => {
	const request = useRequest(method, url);
	const navigate = useNavigate();
	const [, dispatch] = useGlobalStore();

	const call = async (param) => {
		return new Promise(async (resolve) => {
			request
				.asyncCall(template(param), type)
				.then(async (response) => {
					const responseData = await onSuccess({ response, dispatch, navigate });
					resolve(responseData);
				})
				.catch(async (error) => {
					const errorData = await onError({ error, dispatch, navigate });
					resolve(errorData);
				});
		});
	};

	return [call, request.response?.data, request.state];
};

export default useRepository;
