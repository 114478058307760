export const STATUS = {
    NORMAL: '정상',
    STOP: '정지',
}

export const STATUS_OPTIONS = [
    {
        label: '정상',
        value: 'NORMAL'
    }, {
        label: '정지',
        value: 'STOP'
    }
]


export const NOTICE_TYPE_STATUS = {
    '': '전체',
    GENERAL: '일반',
    SERVICE: '서비스',
    EVENT: '이벤트',
    UPDATE: '업데이트',
    TASK: '작업',
}

export const NOTICE_BOARD_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '7.5%'
}, {
    key: 'type',
    title: '구분',
    width: '7.5%'
}, {
    key: 'noticeType',
    title: '카테고리',
    width: '10%'
}, {
    key: 'title',
    title: '제목',
    width: '40%'
}, {
    key: 'createdBy',
    title: '작성자',
    width: '10%'
}, {
    key: 'createdAt',
    title: '등록일',
    width: '7.5%'
}, {
    key: 'viewCount',
    title: '조회',
    width: '7.5%'
}];

export const FAQ_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '7.5%'
}, {
    key: 'type',
    title: '구분',
    width: '7.5%'
}, {
    key: 'title',
    title: '제목',
    width: '40%'
}, {
    key: 'createdBy',
    title: '작성자',
    width: '10%'
}, {
    key: 'createdAt',
    title: '등록일',
    width: '7.5%'
}, {
    key: 'viewCount',
    title: '조회',
    width: '7.5%'
}];

export const TOS_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '7.5%'
}, {
    key: 'type',
    title: '구분',
    width: '7.5%'
}, {
    key: 'title',
    title: '제목',
    width: '40%'
}, {
    key: 'createdBy',
    title: '작성자',
    width: '10%'
}, {
    key: 'createdAt',
    title: '등록일',
    width: '7.5%'
}, {
    key: 'updatedAt',
    title: '수정일',
    width: '7.5%'
}, {
    key: 'viewCount',
    title: '조회',
    width: '7.5%'
}];

export const DATE_FILTER_OPTIONS = [{
    text: '전체',
    value: 'ALL'
}, {
    text: '1개월',
    value: '1M'
}, {
    text: '3개월',
    value: '3M'
}, {
    text: '6개월',
    value: '6M'
}, {
    text: '1년',
    value: '1Y'
}, {
    text: '직접설정',
    value: 'SELECT'
}];

export const NOTICE_SEARCH_KEY_OPTIONS = [
    {
        label: '전체',
        value: ''
    }, {
        label: '일반',
        value: 'GENERAL'
    }, {
        label: '서비스',
        value: 'SERVICE'
    }, {
        label: '이벤트',
        value: 'EVENT'
    }, {
        label: '업데이트',
        value: 'UPDATE'
    }, {
        label: '작업',
        value: 'TASK'
    }
];

export const TOS_TITLE_OPTIONS = [
    {
        label: '서비스 이용약관',
        value: '서비스 이용약관'
    },
    {
        label: '블록체인 지갑 이용약관',
        value: '블록체인 지갑 이용약관'
    }, {
        label: '개인정보 보호 방침약관',
        value: '개인정보 보호 방침약관'
    },
];


