import React, {useEffect, useState} from "react";

import {MenuArea, MenuInfoArea, Sub, Title} from "common/styledComponents/commonStyle";
import {
  GoPointBtn,
  MainNumBerryBox,
  MainTokenBox,
  MainTokenBoxInner,
  MainTokenSubTxt,
  MainTokenTxt,
  ReTokenCont,
  SubNumBerryBox,
  SubTokenBox,
  SubTokenBoxHead,
  SubTokenLi,
  SubTokenTxt,
  SubTokenUl,
  TokenBerryTxt,
  TokenNumTxt
} from "./pay001Sytle";
import {useNavigate} from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import {API_STATE} from '../../../common/constants/state';

const RewardTokenMng = () => {
  const navigate = useNavigate();
  const pointBalanceRequest = useRequest("get", "point/dashboard");
  const [pollBerryTotalPoint, setPollBerryTotalPoint] = useState(null);


  const movePage = (pageId) => {
    if (pageId === 'point') {
      navigate('/pointMng/berryPoint')

    } else if (pageId === 'reward') {

      navigate('/pointMng/rewardDetail')
    }
  }

  useEffect(() => {
    pointBalanceRequest.call()
  }, []);

  useEffect(() => {
    switch (pointBalanceRequest.state) {
      case API_STATE.done:
        if (pointBalanceRequest.response.success) {

          if (!pointBalanceRequest.response.response) {
            return;
          }
          setPollBerryTotalPoint(pointBalanceRequest.response.response);
        }

        if (!pointBalanceRequest.response.success) {
          alert('실패')
        }
        return;
      default:
        return;
    }
  }, [pointBalanceRequest.state, pointBalanceRequest.error, pointBalanceRequest.response])

  return (
    <>
      <MenuArea>
        <MenuInfoArea>
          <Title btn>
            포인트 관리
            <Sub>Reward Token과 Berry의 단위비율은 1:1 입니다.</Sub>
            {/* <Button tit>Point Token 발행 요청</Button>    */}
          </Title>
          <ReTokenCont>
            <MainTokenBox>
              <MainTokenBoxInner>
                <GoPointBtn onClick={() => movePage('point')}>관리 바로가기</GoPointBtn>
                <MainTokenTxt>운용 Point 현황</MainTokenTxt>
                <MainTokenSubTxt>Pollberry 서비스 안에서 운용되는 Piont 입니다.</MainTokenSubTxt>
                <MainNumBerryBox>
                  <TokenNumTxt>{pollBerryTotalPoint && pollBerryTotalPoint.totalPoint.toLocaleString()}</TokenNumTxt>
                  <TokenBerryTxt>Berry</TokenBerryTxt>
                </MainNumBerryBox>
              </MainTokenBoxInner>
              <MainTokenBoxInner>
                <GoPointBtn onClick={() => movePage('reward')}>관리 바로가기</GoPointBtn>
                <MainTokenTxt>Reward Token 현황</MainTokenTxt>
                <MainNumBerryBox>
                  <TokenNumTxt>{pollBerryTotalPoint && pollBerryTotalPoint.totalRewardToken}</TokenNumTxt>
                  <TokenBerryTxt>Token</TokenBerryTxt>
                </MainNumBerryBox>
              </MainTokenBoxInner>
            </MainTokenBox>

            <SubTokenBox>
              <SubTokenBoxHead>Berry Point 요약</SubTokenBoxHead>
              <SubTokenUl>
                <SubTokenLi>
                  <SubTokenTxt>보상 Point</SubTokenTxt>
                  <SubNumBerryBox>
                    <TokenNumTxt sub>
                      {pollBerryTotalPoint && pollBerryTotalPoint.totalRewardPoint.toLocaleString()}
                    </TokenNumTxt>
                    <TokenBerryTxt sub>Berry</TokenBerryTxt>
                  </SubNumBerryBox>
                </SubTokenLi>
                <SubTokenLi>
                  <SubTokenTxt>사용 요청 Point<span>사용 요청한 포인트</span></SubTokenTxt>
                  <SubNumBerryBox>
                    <TokenNumTxt
                      sub>{pollBerryTotalPoint && pollBerryTotalPoint.usagePoint.toLocaleString()}</TokenNumTxt>
                    <TokenBerryTxt sub>Berry</TokenBerryTxt>
                  </SubNumBerryBox>
                </SubTokenLi>
                <SubTokenLi cancle>
                  <SubTokenTxt>취소 Point</SubTokenTxt>
                  <SubNumBerryBox>
                    <TokenNumTxt sub>0</TokenNumTxt>
                    <TokenBerryTxt sub>Berry</TokenBerryTxt>
                  </SubNumBerryBox>
                </SubTokenLi>
              </SubTokenUl>
            </SubTokenBox>

            {/* <SubTokenBox>
             <SubTokenBoxHead>Berry Token 요약</SubTokenBoxHead>
             <SubTokenUl>
             <SubTokenLi>
             <SubTokenTxt>사용 요청 Token<span>보상 지급 완료된 포인트</span></SubTokenTxt>
             <SubNumBerryBox>
             <TokenNumTxt sub>000,000,000</TokenNumTxt>
             <TokenBerryTxt sub>RT</TokenBerryTxt>
             </SubNumBerryBox>
             </SubTokenLi>
             <SubTokenLi>
             <SubTokenTxt>Swap Token<span>iNAE Coin 환전 완료</span></SubTokenTxt>
             <SubNumBerryBox>
             <TokenNumTxt sub>000,000,000</TokenNumTxt>
             <TokenBerryTxt sub>RT</TokenBerryTxt>
             </SubNumBerryBox>
             </SubTokenLi>
             <SubTokenLi cancle>
             <SubTokenTxt>취소 Token</SubTokenTxt>
             <SubNumBerryBox>
             <TokenNumTxt sub>000,000,000</TokenNumTxt>
             <TokenBerryTxt sub>RT</TokenBerryTxt>
             </SubNumBerryBox>
             </SubTokenLi>
             </SubTokenUl>

             </SubTokenBox> */}
          </ReTokenCont>

        </MenuInfoArea>
      </MenuArea>

    </>
  )
}

export default RewardTokenMng;
