import styled, {css} from "styled-components";
import VeiwIcon from "asset/img/icon__find_in_page.png"
import {Wrapper} from '../../styledComponents/commonStyle';

//테이블

export const Colgroup = styled.colgroup`
`;

export const BoardBoxCont = styled.div`
  margin: 80px -64px 0;
  border-top: 16px solid #E5E5E5;
`;
export const BoardInTxt = styled.p`
  text-align: right;
  margin: -28px 0 28px;
  font-size: 14px;
  color: #757575;
`;


export const BoardBox = styled.div`
  width: 1180px;
  margin: 48px auto;

  ${(props) =>
          props.zero &&
          css`
            margin: 0;
          `}
  ${(props) =>
          props.user &&
          css`
            width: 100%;
            max-height: 658px;
            overflow: hidden;

            td {
              line-height: 1;
              padding: 13px 0;
            }
          `}
  ${(props) =>
          props.point &&
          css`
            width: 100%;
            max-height: 982px;
            overflow: hidden;
            margin: 40px auto 48px;
          `}

  ${(props) =>
          props.popPoint &&
          css`
            width: 100%;
            max-height: 658px;
            overflow: hidden;

            td {
              line-height: normal;
            }
          `}

  ${(props) =>
          props.Member &&
          css`
            width: 100%;
            height: 100%;
          `}

  ${(props) =>
          props.Reward &&
          css`
            margin: 10px auto 0;
            min-height: 593px;
          `}
`;


export const BoardTable = styled.table`
  width: 100%;
  text-align: center;
`;


export const BoardTableThead = styled.thead`
  border-bottom: 2px solid #000065;
`;

export const BoardTableTr = styled.tr`
  cursor: default;
  height: 60px;

  ${(props) =>
          props.modalList &&
          css`
            height: 52px;
          `}

`;

export const BoardTableTh = styled.th`
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 16px;
`;

export const BoardTableTbody = styled.tbody`
  padding: 24px 0;
  border-bottom: 2px solid #dbdbdb;

  tr:hover {
    background-color: #f5f5f5;
  }
`;

export const BoardTableTd = styled.td`
  padding: 18px 0;

  ${(props) =>
          props.left &&
          css`
            text-align: left;
          `}
  ${(props) =>
          props.right &&
          css`
            text-align: right;
          `}
  ${(props) =>
          props.red &&
          css`
            :last-child {
              div {
                color: #C91615;
                cursor: pointer;
              }
            }
          `}
  ${(props) =>
          props.gray &&
          css`
            :last-child {
              div {
                color: #757575;
                cursor: pointer;
              }
            }
          `}
  ${(props) =>
          props.UnderLine &&
          css`
            :last-child {
              div {
                color: #C91615;
                border-bottom: 1px solid #C91615;
                cursor: pointer;
              }
            }
          `
  }
  ${(props) =>
          props.Charge &&
          css`
            :last-child {
              span {
                color: #1A1A1A;
              }
            }
          `
  }
  ${(props) =>
          props.fontRed &&
          css`
            color: #C91615;
          `
  }
  ${(props) =>
          props.modalList &&
          css`
            padding: 10px 0;
          `
  }
  span + span {
    margin-left: 14px
  }

`;
export const BoardTableTdPick = styled.span`
  display: inline-block;
  color: #fff;
  width: 53px;
  height: 20px;
  font-size: 12px;
  background-color: #e92928;
  border-radius: 4px;
  line-height: 20px;
`;
export const BoardTableTextA = styled.a`
  color: #1a1a1a;
  text-decoration: underline;
`;

export const ImgSpan = styled.span`
  display: inline-block;
  margin-right: 20px;
  float: left;
  height: 50px;
`;
export const TxtSpan = styled.span`
  display: inline-block;
  line-height: 50px;
`;

export const BoardTableA = styled.a`
  background-image: url(${VeiwIcon});
  width: 24px;
  height: 24px;
  background-size: cover;
  font-size: 0;
  display: inline-block;
  vertical-align: top;
`;

export const BoardTableImg = styled.span`
  width: 32px;
  height: 32px;
  background-color: #d9d9d9;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
`;


export const BoardContainer = styled.div`
`;

export const Board = styled.table`
  display: flex;
  flex-flow: column;
`;

export const BoardHead = styled.thead`

  tr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

export const BoardBody = styled.tbody`
  border-top: 2px solid #000065;
  border-bottom: 2px solid #DBDBDB;
  padding: 24px 0
`;

export const BoardTitle = styled.th`

  span {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const BoardRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  :last-child {
    margin-bottom: 0;
  }

  td {
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }

  }
`;


// 카테고리 상세보기
export const BoardBoxNew = styled.div`
  width: 1180px;
  margin: 64px auto 48px;
`;
export const ColgroupNew = styled.colgroup`
`;
export const BoardTableNew = styled.table`
  width: 100%;
  text-align: center;
`;
export const NewBoardTableThead = styled.thead`
  border-bottom: 2px solid #000065;
`;
export const NewBoardTableTr = styled.tr`
  cursor: default;
  height: 60px;
`;
export const NewBoardTableTh = styled.th`
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 16px;
`;

export const NewBoardTableTbody = styled.tbody`
  padding: 24px 0;
  border-bottom: 2px solid #dbdbdb;

  tr:hover {
    background-color: #f5f5f5;
  }
`;

export const NewBoardTableTd = styled.td`
  padding: 0;
  ${(props) =>
          props.red &&
          css`
            color: #C91615;
          `}

`;
export const NewBoardTableImg = styled.span`
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  margin-right: 20px;
  float: left;
`;
export const NewTxtSpan = styled.span`
  display: inline-block;
  line-height: 50px;
  float: left;
`;

export const NoDataWrapper = styled(Wrapper)`
  justify-content: center;
`;

export const NoDataSpan = styled.span`
  font-size: 36px;
  font-weight: 400;
  line-height: 52px;
  text-align: center;
  color: #757575;
  margin-top: 48px;
`;


