import React from 'react';
import {ListFileLabel, ListInput, ListRstrong, ListThumbX} from '../../styledComponents/commonStyle';
import styled, {css} from 'styled-components';

const ImageWrapper = styled.span`
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;

  ${(props) =>
          props.Main &&
          css`
            position: relative;
            display: inline-block;
            width: 100px;
            height: 100px;

            &::before {
              content: "";
              position: absolute;
              border: 2px solid #000065;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            &::after {
              content: "대표";
              position: absolute;
              font-size: 10px;
              padding: 0 4px;
              background-color: #000065;
              color: #fff;
              width: 27px;
              left: 0;
              top: 0;
              height: 18px;
              box-sizing: border-box;
            }
          `}
  & + & {
    margin-left: 24px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;

`;

function ThumbnailsV2({thumbnails, onChange, ...props}) {
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

    return (
        <>
            <ListRstrong>
                <ListFileLabel htmlFor="thumbnail">파일 첨부</ListFileLabel>
                <ListInput type="file" id="thumbnail"
                           accept="image/jpg, image/jpeg, image/png, image/bmp"
                           onChange={(e, initThumbnails) => onChange(e, initThumbnails)}
                />
            </ListRstrong>
            {props.previewImages && props.previewImages.map((thumbnail, index) => {
                const {url, isRepresentative, previewImage} = thumbnail
                return (
                    <ImageWrapper key={index} Main={isRepresentative}>
                        {previewImage ?
                            <Thumbnail
                                src={previewImage}
                                Main={isRepresentative}
                                onClick={() => {
                                    props.handleThumbnailRepresentative(index)
                                }}
                            /> :
                            <>
                                <Thumbnail
                                    src={url.includes('amazonaws') ? url : url.includes('data:') ? url : `${domain}${url}`}
                                    Main={url.includes('data:') || !url.includes('amazonaws')}
                                    onClick={() => {
                                        props.handleThumbnailRepresentative(index);
                                    }}
                                />
                                {/*{url.includes('amazonaws') ?*/}
                                {/*    <Thumbnail*/}
                                {/*        src={url}*/}
                                {/*        onClick={() => {*/}
                                {/*            props.handleThumbnailRepresentative(index)*/}
                                {/*        }}*/}
                                {/*    /> : url.includes('data:') ?*/}
                                {/*        <Thumbnail*/}
                                {/*            src={url}*/}
                                {/*            Main={isRepresentative}*/}
                                {/*            onClick={() => {*/}
                                {/*                props.handleThumbnailRepresentative(index)*/}
                                {/*            }}*/}
                                {/*        /> :*/}
                                {/*        <Thumbnail*/}
                                {/*            src={`${domain}${url}`}*/}
                                {/*            Main={isRepresentative}*/}
                                {/*            onClick={() => {*/}
                                {/*                props.handleThumbnailRepresentative(index)*/}
                                {/*            }}*/}
                                {/*        />}*/}
                            </>
                        }
                        {/*{!props.type &&*/}
                        {/*    <ListThumbName>{name}</ListThumbName>*/}
                        {/*}*/}
                        <ListThumbX type='button' onClick={() => {
                            props.removeThumbnail(index)
                        }}/>
                    </ImageWrapper>)
            })
            }

        </>
    );
}

export default ThumbnailsV2;
