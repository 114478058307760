import styled, {css} from 'styled-components';

export const MainContainer = styled.div`
    width: 100%;

    form {
        height: 100%;
    }
`;

export const CreateChannelContent = styled.div`
    display: flex;
    padding: 24px 0 0;
`;

export const ContentContainer = styled.div`
    width: 100%;
    max-width: 1308px;
    min-height: 100%;
    background-color: #fff;
    margin: 0 auto;
    color: #1a1a1a;
    padding: 48px 64px 0 64px;
    box-sizing: border-box;
`;

export const ContentSection = styled.div`
    padding-right: 8px;
    height: 100%;
    max-height: 708px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #A8A8A8;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #F5F5F5;
        border-radius: 10px;
    }
`;


export const ContentWrapper = styled.div`
    display: flex;
    padding: ${props => props.padding ? '0 0 48px 0' : `48px 0 48px 0`};

    :first-child {
        padding-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }


    ${props => props.Border && css`
        border-bottom: 1px solid #DBDBDB;
    `}
`;


export const Content = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};

    ${props => props.ChannelInput && css`
        display: flex;
        flex-flow: column;

        textarea {
            resize: none;
            height: 96px;
            width: 608px;
            padding: 8px;
            border-radius: 8px;

            :focus {
                outline: none;
            }

            word-break: break-all;

            ::-webkit-scrollbar {
                width: 10px;
            }

            ::-webkit-scrollbar-thumb {
                background-color: #a8a8a8;
                border-radius: 10px;
            }

            ::-webkit-scrollbar-track {
                background-color: #f5f5f5;
                border-radius: 10px;
            }

        }


        input {
            background-color: #FFFFFF;
            border: 1px solid #757575;
            border-radius: 8px;
            padding: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #1A1A1A;
            width: 608px;
            height: 96px;
            //text-align: ;
        }

        span {
            height: 17px;
            color: #757575;
            margin-left: auto;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
        }

    `};

    ${props => props.ChannelProfile && css`
        display: flex;
        justify-content: space-between;
    `}

`;

export const ContentTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-right: 24px;
    width: 140px;
`;

export const PreviewWrapper = styled.div`
    width: 100%;
    max-width: 360px;
    margin-left: 29px;
`;

export const ProfilePreviewWrapper = styled.div`
    border-radius: 50%;
    border: 1px solid #757575;
    width: 140px;
    height: 140px;
    background-color: ${props => props.imageSrc ? '#fff' : '#F5F5F5'};

    img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
`;

export const ProfileFileWrapper = styled.div`
    display: flex;
`;

export const NoticeWrapper = styled.div`
    display: flex;
    flex-flow: column;

    span {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #757575;
        margin-top: 4px;
    }
`;


export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-top: 48px;

    button {
        background-color: #000065;
        width: 180px;
        padding: 11px 8px;
        border-radius: 8px;

        span {
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
        }
    }
`;

export const BizMembers = styled.select`
    color: #1A1A1A;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 36px 8px 8px;

    option {

    }
`;

export const FlexWrapper = styled.div`
    display: flex;
    flex-flow: column;
    margin: ${props => props.margin};

    ${props => props.Relative && css`
        position: relative;
    `}
`;

// 미리보기
export const PreviewCont = styled.div`
    width: 360px;
    height: 740px;
    background-color: #DBDBDB;
    cursor: default;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid #dbdbdb;
`;

export const ChPreviewName = styled.p`
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: 94px;
    z-index: 1;
    left: 20px;
`;

export const ChPreviewSubtxt = styled.span`
    font-size: 14px;
    color: #5c5c5c;
`;

export const ChPreviewBack = styled.div`
    position: absolute;
    height: 300px;

    img {
        height: 100%;
    }
`;

export const PreviewContentTop = styled.div`
    position: relative;
`;

export const PreviewThumbnail = styled.div`
    height: 290px;

    img {
        height: 290px;
        width: 100%;
    }
`;

export const HeartImage = styled.img`
    position: absolute;
    right: 20px;
    bottom: 16px;
`;

export const PreviewTopBarImage = styled.img`
    position: absolute;
    top: 0;
`;

export const PreviewChannelDescription = styled.div`
    height: 246px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    text-align: center;
    padding: 48px 20px;
    display: flex;
    flex-flow: column;
    width: 100%;
    word-wrap: break-word;
`;

export const PreviewChannelDescriptionTitle = styled.span`
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 28px;
`;

export const PreviewChannelDescriptionText = styled.p`

    text-align: ${props => props.Center ? 'center' : 'left'};;
    font-size: 14px;
    color: #5c5c5c;
    word-break: break-all;
    white-space: pre-wrap;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }

`;

export const PreviewContentBottom = styled.div`
    margin-top: 10px;
    position: relative;
    height: 192px;
`;

export const PreviewBottomImage = styled.img`
    position: absolute;
    bottom: 0;
    background-color: #1A1A1A;
`;
