import React, {useEffect, useState} from 'react';
import {
    CloseButton,
    ModalContainer,
    PointSelectButton,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Wrapper
} from '../../styledComponents/commonStyle';
import Table from '../Table';
import Page from '../Page';
import CloseIcon from '../../../asset/img/icon__close_black.svg';
import pageMaker from '../Page/pageMaker';
import useRequest from '../../../hooks/useRequest';
import useBoard from '../../../hooks/useBoard';
import dayjs from 'dayjs';
import DATE from '../../constants/date';
import {useSearchParams} from 'react-router-dom';
import {BIZ_POINT_LIST_STATUS, BIZ_POINT_LIST_TYPE_STATUS, POINT_LIST, POINT_LIST_STATUS} from '../../constants/user';

function PointListModal({...props}) {
    const {closeDetailModal, biz, id} = props;
    // const queryParams = useParams();
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({offset: 0, limit: 10});
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    // const request = useRequest("get", `user/${id}/point/list`);
    const request = useRequest("get", `user/${id}/point/list`);
    const bizRequest = useRequest("get", `user/${id}/biz/point/list`);
    const [searchParams, setSearchParams] = useSearchParams();

    const {rows, fetch, params} = useBoard(biz ? bizRequest : request, (res) => {
            setTotalNum(res.totalCount);
            const {data} = res;
            return data && data.map((data) => ({
                ...data,
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                type: BIZ_POINT_LIST_TYPE_STATUS[data.type],
            }));
        },
        {
            offset: 0, limit: 10,
            startAt: dayjs('2023-01-01'),
            endAt: dayjs().format(DATE.FORMAT.DASH_TIME),
        });

    const handlePage = (pageNum) => {
        setCurPage(pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
    }

    const handleSearch = (value) => {
        let reqParams = {};
        if (value || value === '') {
            reqParams = {
                ...reqParams,
                type: value,
            };
        }

        const {offset, limit} = params;

        fetch({
            ...reqParams,
            offset: 0,
            limit,
            startAt: dayjs('2023-01-01').format(DATE.FORMAT.DASH_TIME),
            endAt: dayjs().format(DATE.FORMAT.DASH_TIME),
        });
    };

    useEffect(() => {
        const {offset, limit} = formData;

        fetch({
            ...params,
            type: '',
            offset,
            limit
        });
    }, [formData]);

    return (
        <StyledModal>
            <ModalContainer Reward>
                <RewardDetailTitle>포인트 내역보기</RewardDetailTitle>
                <Wrapper Row width='100%' margin='38px 0 0 0'>
                    <Wrapper width='auto'>
                        {(biz ? BIZ_POINT_LIST_STATUS : POINT_LIST_STATUS).map((status, index) =>
                            <PointSelectButton type='button'
                                               key={index}
                                               onClick={() => handleSearch(status.value)}>{status.label}</PointSelectButton>
                        )}
                    </Wrapper>
                    <Wrapper width='auto'>
                        <span style={{
                            color: '#424242', fontSize: '14px',
                            fontWeight: 400
                        }}>
                        날짜 선택
                        </span>
                    </Wrapper>
                </Wrapper>
                <RewardDetailContent Reward>
                    <Table Member
                           pageData={formData}
                           totalPages={pageInfo.totalPages}
                           columns={POINT_LIST}
                           rows={rows}
                           totalNum={totalNum}
                           Reward
                           searchParams={searchParams}
                           defaultListValue={true}
                           modalList={true}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage} modalList={true}/>
                </RewardDetailContent>
                {/*<Button mini onClick={(e) => closeDetailModal(e)} margin='48px 0 0 0'*/}
                {/*        type='button' id='participantsCount'>확인</Button>*/}
                <CloseButton onClick={(e) => closeDetailModal(e)} type='button' id='participantsCount'>
                    <img src={CloseIcon} alt='close'/>
                </CloseButton>
            </ModalContainer>
        </StyledModal>
    );
}

export default PointListModal;
