import pollberryApiService from '../../util/pollberryApiService';
import storageService from '../../util/storage';
import axios from 'axios';

async function HandleThumbnailUpload(images, apiType) {
    const token = storageService.get("auth", "accessToken", "local");
    const apiService = pollberryApiService
    apiService.setBaererToken(token);

    const convertThumbnailUrl = async (image) => {
        const formData = new FormData();
        formData.append("image", image.url);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/image/${apiType}/thumbnail/new`,
                formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            formData.delete('image');
            return {
                text: image.text,
                url: response.data.response.url,
                isRepresentative: image.isRepresentative ? image.isRepresentative : false,
                id: image.id || null
            };

        } catch (error) {
            console.error(error, 'error');
            formData.delete('image');
            return image;
        }

    }

    const convertPlatformItemImage = async (image) => {
        const formData = new FormData();
        formData.append("image", image.url[0]);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/image/${apiType}/item/new`,
                formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            formData.delete('image');
            return ({
                // ...image,
                text: image.text,
                url: response.data.response.url,
                id: image.id || null
            });

        } catch (error) {
            console.error(error, 'error');
            formData.delete('image');
            return image;
        }
    }

    try {
        const imagesCase = await Promise.all(images.map(async (image, index) => {

            if (image.url.type) {
                return await convertThumbnailUrl(image)
            } else if (image.imageUrl && image.imageUrl.includes('data:')) {
                return await convertPlatformItemImage(image)
            } else {
                return image;
            }
        }));
        return imagesCase;
    } catch (e) {
        console.error(e)
        return []
    }

}

export default HandleThumbnailUpload;
