export const BIG_DATA_SEARCH_LIST = [
    {
        title: "성별",
        options: ["남성","여성"]
    },
    {
        title: "연령대",
        options: ["10대","20대","30대","40대","50대","60대","70대","80대","90대","100대",]
    },
    {
        title: "거주국가",
        options: ["대한민국", "해외거주"]
    },
    {
        title: "거주지역",
        options: ["서울특별시", "부산광역시", "대구광역시", "인천광역시", "광주광역시", "대전광역시", "울산광역시", "세종특별자치시" , "경기도", "강원도", "충청북도", "충청남도",
        "전라북도", "전라남도", "경상북도", "경상남도" , "제주특별자치도", "그 외 지역"
        ]
    },
    {
        title: "학력",
        options: ["초등학교 졸업" , "중학교 졸업" , "고등학교 졸업" ,"전문대학 졸업" ,"학사 취득" ,"석사 취득" ,"박사 취득" ,]
    }
]
;