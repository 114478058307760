import React, {useEffect} from "react";
import dayjs from 'dayjs'
import {API_STATE} from "common/constants/state";
import {useNavigate} from "react-router-dom";

import useRequest from "hooks/useRequest";
import {CreatePlatformPollContainer} from "./CreatePlatformPollStyle";
import DATE from "common/constants/date";
import CommonPlatformView from "../CommonPlatformView"
import {MenuArea} from "common/styledComponents/commonStyle";
import Title from '../../../../common/components/Title';

function CreatePlatformPoll(props) {
    const navigate = useNavigate();
    const savePlatformNewRequest = useRequest("post", "poll/event/new");
    const today = dayjs().format(DATE.FORMAT.DASH_TIME);
    const tomorrow = dayjs().add(1, 'day').format(DATE.FORMAT.DASH_TIME);
    const commonViewParams = {
        type: 'balance',
        title: '',
        today: today,
        // endAt: tomorrow,
        // items,
        isPaid: true,
        paidUnit: '',
        // paidAmount,
    }

    const handleSubmit = (formData) => {
        savePlatformNewRequest.call(formData);
    }

    useEffect(() => {
        switch (savePlatformNewRequest.state) {
            case API_STATE.done:
                if (savePlatformNewRequest.response.success) {
                    alert("설문이 생성 되었습니다.");
                    navigate('/contents/platformList');
                }

                if (savePlatformNewRequest.response.success === false) {
                    alert(savePlatformNewRequest.response.error.message)
                    // alert('해당 일자에 노출되는 플랫폼 설문이 있습니다. 일자를 확인 후 다시 등록해주세요')
                }

                break;
            default:
                break;
        }
    }, [savePlatformNewRequest.state, savePlatformNewRequest.error, savePlatformNewRequest.response]);

    return (
        <MenuArea>
            <CreatePlatformPollContainer>
                <Title>플랫폼 설문 등록하기</Title>
                <CommonPlatformView  {...commonViewParams} onSubmit={handleSubmit}/>
            </CreatePlatformPollContainer>

        </MenuArea>
    );
}

export default CreatePlatformPoll;
