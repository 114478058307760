import React, {useEffect, useState} from "react";

import {MenuInfoCont, MyChannelName, MyLeft} from "pages/Mypage/MypageStyle"
import {
    Button,
    LineBar,
    ListLi,
    ListLtxt,
    ListRtxt,
    MenuArea,
    MenuInfoArea,
    Wrapper
} from "common/styledComponents/commonStyle";
import {BoardBoxCont} from "pages/Channel/ChannelStyle"
import {useParams} from "react-router-dom";
import useRequest from "hooks/useRequest";
import {API_STATE} from "common/constants/state";
import CircleBg from "asset/img/circle-base.png";
import {UPLOAD_IMAGE_BASE_URL} from "common/constants/env";
import useBoard from "hooks/useBoard";
import Page from "common/components/Page";
import pageMaker from "common/components/Page/pageMaker";
import Table from "common/components/Table";
import dayjs from "dayjs";
import DATE from "common/constants/date";
import TopupPopup from "./innerComponent/TopupPopup";
import CancelPopup from "./innerComponent/CancelPopup";
import CancelReasonPopup from "./innerComponent/CancelReasonPopup";
import {
    BIZ_POINT_DETAIL_LIST_TYPE,
    BIZZ_POINT_LIST,
    POINTMNG_USERMNG_POINT_DETAIL_STATUS,
    USER_POINT_DETAIL_LIST_TYPE,
    USER_POINT_LIST
} from '../../../common/constants/point';
import {
    BalanceText,
    BalanceTextWrapper,
    BalanceWrapper,
    FlexWrapper,
    MyImg,
    UserDetailContent,
    UserProfileImage
} from '../UserStyle';
import Title from '../../../common/components/Title';
import storageService from '../../../util/storage';
import axios from 'axios';


const UserDetailList = () => {
    const {id, biz} = useParams();
    // const navigate = useNavigate();
    const [myInfo, setMyInfo] = useState({});
    const [totalNum, setTotalNum] = useState(0);
    const [detailInfo, setDetailInfo] = useState({});
    const [cancelAmount, setCancelAmount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openCancelRsModal, setOpenCancelRsModal] = useState(false);
    const [formData, setFormData] = useState({offset: 0, limit: 5});
    const [_, setCurPage] = useState(1);
    const [pointId, setPointId] = useState(null);
    const [cancelReasonData, setCancelReasonData] = useState(null);
    const [chargeCancelInfo, setChargeCancelInfo] = useState(null);
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const myPageRequest = useRequest("get", "user/me");
    const userInfoRequest = useRequest("get", `point/user/${id}/user`);
    const bizUserInfoRequest = useRequest("get", `point/user/${id}/biz`);
    const userPointListRequest = useRequest("get", `point/user/${id}/user/list`)
    const bizPointListRequest = useRequest("get", `point/user/${id}/biz/list`)
    const {rows, fetch,} = useBoard(biz ? bizPointListRequest : userPointListRequest, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data ? (data.map(d => {
            return {
                ...d,
                amount: {money: d?.amount, type: d?.type},
                createdAt: d.createdAt && dayjs(new Date(d.createdAt)).format(DATE.FORMAT.DOT),
                type: (biz ? BIZ_POINT_DETAIL_LIST_TYPE : USER_POINT_DETAIL_LIST_TYPE)[d.type],
            }
        })) : []
    });

    const handlePage = (pageNum) => {
        setCurPage(pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
    }

    const columnRenderer = (key, value, _, row) => {
        if (key === "amount" && (value.type === "CHARGE" || value.type === "RECEIVE" || value.type === 'REWARD')) {
            return <span
                style={{
                    color: value.type === 'REWARD' && biz ? "#C91615" : '#265AFF',
                    cursor: `${value.type === 'CHARGE' && "pointer"}`,
                    borderBottom: `${value.type === "CHARGE" && '1px solid #265AFF'}`,
                }}
                onClick={() => {
                    value.type === "CHARGE" && onChargeModal(row);
                    setCancelAmount(value?.money)
                    setPointId(row.id)
                }}>
                {biz ? value.type === 'CHARGE' ? `+${value.money}` : value.money : `+${value.money}`}
            </span>
        } else if (key === "amount" && value.type !== "CHARGE") {
            return <span style={{
                color: "#C91615",
            }}>
                    {biz ? value.money : `${value.money}`}
                    </span>
        } else if (key === "type" && value === "취소") {
            return <span
                style={{color: "#C91615", cursor: "pointer", borderBottom: '1px solid #C91615'}}
                onClick={() => onCanCelReasonModal(row.id)}
            >
                취소
            </span>
        }
        return value
    }

    const onCanCelReasonModal = async (id) => {
        const token = storageService.get("auth", "accessToken", "local");

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/point/${id}/cancel`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })

            document.body.style.overflow = 'hidden';
            setCancelReasonData(response.data.response)
            setOpenCancelRsModal(true)

            setPointId(id)
        } catch (e) {
            console.log(e)
        }
    }

    const closeCanCelReasonModal = () => {
        document.body.style.overflow = 'auto';
        setOpenCancelRsModal(false)
    }

    const onChargeModal = (row) => {
        setOpenCancelModal(true)
        setChargeCancelInfo(row)
    }

    useEffect(() => {
        myPageRequest.call()
    }, []);

    useEffect(() => {
        switch (myPageRequest.state) {
            case API_STATE.done:
                if (myPageRequest.response.success) {
                    setMyInfo(myPageRequest.response.response)
                }
                break;
            default:
                break;
        }
    }, [myPageRequest.state, myPageRequest.error, myPageRequest.response]);

    useEffect(() => {
        const {offset} = formData;
        fetch({
            ...formData,
            offset
        })
    }, [formData])

    useEffect(() => {
        if (biz) {
            bizUserInfoRequest.call()
        } else {
            userInfoRequest.call();
        }
    }, [])

    useEffect(() => {
        switch (userInfoRequest.state) {
            case API_STATE.done:
                if (!userInfoRequest.response.success) {
                    // alert("잠시후 다시 시도해 주십시오.")
                    // navigate("/pointMng/userMng");
                }
                setDetailInfo(userInfoRequest.response.response)
                return;
            default:
                return;
        }
    }, [userInfoRequest.state, userInfoRequest.error, userInfoRequest.response])


    useEffect(() => {

        switch (bizUserInfoRequest.state) {
            case API_STATE.done:
                if (!bizUserInfoRequest.response.success) {
                    // alert("잠시후 다시 시도해 주십시오.")
                    // navigate("/pointMng/userMng");
                }
                setDetailInfo(bizUserInfoRequest.response.response)
                return;
            default:
                return;
        }
    }, [bizUserInfoRequest.state, bizUserInfoRequest.error, bizUserInfoRequest.response])


    const onErrorImg = (e) => {
        e.target.src = CircleBg
    }

    // 최고 관리자 : "ROLE_SUPER"
    // 관리자 : "ROLE_ADMIN"
    // biz : "ROLE_BIZZ"

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>회원별 상세내역</Title>
                    <MenuInfoCont noline>
                        <FlexWrapper>
                            {/* 슈퍼 관리자, 관리자는 볼 수 없게 */}
                            {/*{*/}
                            {/*    myInfo?.role === "ROLE_SUPER" || myInfo?.role === "ROLE_ADMIN" || !myInfo?.role ?*/}
                            {/*        (*/}
                            {/*            <></>*/}
                            {/*        ) :*/}
                            {/*    (*/}
                            {/*        <MyLeft user>*/}
                            {/*            <MyImg>*/}
                            {/*                <CircleImgClear>*/}
                            {/*                    <img*/}
                            {/*                        src={detailInfo.profileImageUrl ? UPLOAD_IMAGE_BASE_URL + detailInfo.profileImageUrl : CircleBg}*/}
                            {/*                        style={{minWidth: "180px", height: "100%"}}*/}
                            {/*                        alt=''*/}
                            {/*                    />*/}
                            {/*                </CircleImgClear>*/}
                            {/*            </MyImg>*/}
                            {/*            <MyChannelName margin>{detailInfo.userId}</MyChannelName>*/}
                            {/*            <Button mini onClick={() => setOpenModal(false)}>포인트 충전</Button>*/}
                            {/*        </MyLeft>*/}
                            {/*    )*/}
                            {/*}*/}
                            <MyLeft user>
                                <MyImg>
                                    <UserProfileImage
                                        // src={detailInfo.profileImageUrl ? (UPLOAD_IMAGE_BASE_URL + detailInfo.profileImageUrl) : CircleBg}
                                        src={UPLOAD_IMAGE_BASE_URL + detailInfo.profileImageUrl}
                                        alt='profileImage'
                                        onError={onErrorImg}
                                    />
                                </MyImg>
                                <MyChannelName
                                    margin={detailInfo.channelName}>{detailInfo.userId ? detailInfo.userId : detailInfo.channelName}</MyChannelName>
                                {biz && <Button mini onClick={() => setOpenModal(true)}>포인트 충전</Button>}
                            </MyLeft>

                            <UserDetailContent bizz={!(detailInfo.userId && detailInfo.userId.includes('Bizz'))}>
                                <Wrapper>
                                    <ListLtxt>회원 상태</ListLtxt>
                                    <ListRtxt>{POINTMNG_USERMNG_POINT_DETAIL_STATUS[detailInfo.status]}</ListRtxt>
                                </Wrapper>
                                {biz ?
                                    <Wrapper margin='48px 0 0 0'>
                                        <ul>
                                            <ListLi>
                                                <ListLtxt>비즈회원 아이디</ListLtxt>
                                                <ListRtxt>{detailInfo.userId}</ListRtxt>
                                            </ListLi>
                                            <ListLi>
                                                <ListLtxt>회사명</ListLtxt>
                                                <ListRtxt>{detailInfo.companyName}</ListRtxt>
                                            </ListLi>
                                            <ListLi>
                                                <BalanceWrapper width='360px' height='126px'>
                                                    <BalanceText>포인트 현황</BalanceText>
                                                    <BalanceTextWrapper>
                                                        <BalanceText Number>{detailInfo.balance}</BalanceText>
                                                        <BalanceText Unit>Berry</BalanceText>
                                                    </BalanceTextWrapper>
                                                </BalanceWrapper>
                                            </ListLi>
                                        </ul>
                                    </Wrapper>
                                    : <Wrapper>
                                        <BalanceWrapper width='360px' height='126px'>
                                            <BalanceText>포인트 현황</BalanceText>
                                            <BalanceTextWrapper>
                                                <BalanceText Number>{detailInfo.pointBalance}</BalanceText>
                                                <BalanceText Unit>Berry</BalanceText>
                                            </BalanceTextWrapper>
                                        </BalanceWrapper>
                                        <BalanceWrapper width='360px' height='126px'>
                                            <BalanceText>Reward Token 현황</BalanceText>
                                            <BalanceTextWrapper>
                                                <BalanceText Number>{detailInfo.tokenBalance}</BalanceText>
                                                <BalanceText Unit>Token</BalanceText>
                                            </BalanceTextWrapper>
                                        </BalanceWrapper>
                                    </Wrapper>
                                }
                            </UserDetailContent>


                            {/* // 최고 관리자 : "ROLE_SUPER"
                             // 관리자 : "ROLE_ADMIN" */}

                            {/* <PointBox>
                             <PointListDiv>
                             <PointToptxt>포인트 현황</PointToptxt>
                             <PointBottomGroup>
                             <PointBottomStrong>{detailInfo?.totalPoint}</PointBottomStrong>
                             <PointBottomSpan>Berry</PointBottomSpan>
                             </PointBottomGroup>
                             </PointListDiv>
                             </PointBox>
                             <PointBox>
                             <PointListDiv>
                             <PointToptxt>코인 현황</PointToptxt>
                             <PointBottomGroup>
                             <PointBottomStrong>000,000,000</PointBottomStrong>
                             <PointBottomSpan>Berry</PointBottomSpan>
                             </PointBottomGroup>
                             </PointListDiv>
                             </PointBox>
                             <PointInputBox>
                             <PointInputUl>
                             <PointInputLi>
                             <ListSpan><ListInput type="number" value={chargeAmount} onChange={(e) => setChargeAmount(e.target.value)} ></ListInput></ListSpan>
                             <Button short onClick={handleCharge}>충전하기</Button>
                             </PointInputLi>
                             <PointInputLi>
                             <ListSpan><ListInput short type="number" value={cancelAmount} onChange={(e) => setCancelAmount(e.target.value)} ></ListInput></ListSpan>
                             <Button short line onClick={handleCancel}>취소하기</Button>
                             </PointInputLi>
                             </PointInputUl>
                             </PointInputBox> */}
                            {/*</MyRight>*/}
                        </FlexWrapper>
                        <LineBar/>
                        <BoardBoxCont>
                            <Table
                                // onSelectRow={() => {}}
                                pageData={formData}
                                columns={biz ? BIZZ_POINT_LIST : USER_POINT_LIST}
                                totalPages={pageInfo?.totalPages}
                                totalNum={totalNum}
                                columnRenderer={columnRenderer}
                                rows={rows}
                            />

                            {rows.length > 0 ? (<Page pageInfo={pageInfo} handlePage={handlePage}/>) : (<></>)}
                        </BoardBoxCont>
                    </MenuInfoCont>
                </MenuInfoArea>
                {openModal &&
                    <TopupPopup
                        {...{
                            openModal,
                            setOpenModal,
                            id,
                            userInfoRequest,
                            bizUserInfoRequest,
                            formData,
                            fetch,
                            biz,
                            chargeCancelInfo
                        }}
                    />
                }
                {
                    (myInfo?.role === "ROLE_SUPER" || myInfo?.role === "ROLE_ADMIN") &&
                    (openCancelModal &&
                        <CancelPopup {...{
                            openCancelModal,
                            setOpenCancelModal,
                            formData,
                            pointId,
                            bizUserInfoRequest,
                            bizPointListRequest,
                            cancelAmount,
                            setCancelAmount,
                            fetch,
                            chargeCancelInfo
                        }}/>)
                }

                {openCancelRsModal &&
                    <CancelReasonPopup {...{
                        openCancelRsModal,
                        setOpenCancelRsModal,
                        cancelReasonData,
                        closeCanCelReasonModal
                    }} />
                }
            </MenuArea>


            {/* 충전 */}
            {/* <PopUp>
             <PopBox check>
             <PopHeadTxt>운용 포인트 충전</PopHeadTxt>
             <PopClose><img src={CloseIcon} alt=''/></PopClose>
             <PopTxt>충전된 운용 포인트는 설문의 보상으로 사용자에게<br/>제공됩니다.</PopTxt>
             <FullPointBox>
             <PopTxt left>충전 금액을 입력해주세요.</PopTxt>
             <FullPointInputBox>
             <FullPointInput type="number"/>
             <FullPointInputTxt>Berry</FullPointInputTxt>
             </FullPointInputBox>
             <PopTxt left>! 충전 시 승인 관리자의 확인이 필요합니다.</PopTxt>
             </FullPointBox>
             <PopButton center>확인</PopButton>
             </PopBox>
             </PopUp> */}

            {/* 충전 취소 */}
            {/* <PopUp>
             <PopBox check>
             <PopHeadTxt>운용 포인트 충전 취소</PopHeadTxt>
             <PopClose><img src={CloseIcon} alt=''/></PopClose>
             <PopTable full>
             <PopTxt>
             <PopLeftTxt left>충전 일자</PopLeftTxt><PopRightTxt right>2023.04.19</PopRightTxt>
             </PopTxt>
             <PopTxt>
             <PopLeftTxt left>충전 포인트</PopLeftTxt><PopRightTxt right>100,000,000 Berry</PopRightTxt>
             </PopTxt>
             <PopTxt>
             <PopLeftTxt left>충전 관리자</PopLeftTxt><PopRightTxt right>관리자 이이디</PopRightTxt>
             </PopTxt>
             </PopTable>
             <FullPointBox full>
             <PopTxt left>취소 사유에 대해 입력해주세요.</PopTxt>
             <FullPointInputBox area>
             <FullPointTextarea placeholder="내용을 입력해주세요."></FullPointTextarea>
             </FullPointInputBox>
             </FullPointBox>
             <PopButton center>확인</PopButton>
             </PopBox>
             </PopUp> */}
        </>

    )
}

export default UserDetailList;
