import {createGlobalStyle} from "styled-components";
import "../../asset/style/common.css"

import RadioButtonActive from "asset/img/icon__radio_button_active.png";
import RadioButton from "asset/img/icon__radio_button.png";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    width: 100%;
    height: 100%;
    //background-color: #E5E5E5;
    background-color: #F5F5F5;
  }

  input:focus-visible {
    outline: none;
  }

  * {
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
  }

  p, span, h1, h2 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #1a1a1a;
  }

  ul, ol, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {

    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  input {
    border: 0;
    margin: 0;
    color: #1a1a1a;
    //-webkit-appearance: none;
    //-moz-appearance: none;
    //appearance: none;

  }

  textarea::placeholder {
    color: #757575
  }

  input::placeholder {
    color: #757575
  }

  input:checked {
    border-color: #1a1a1a
  }

  button {
    border: 0;
    display: inline-block;
  }

  table {
    border: 0;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
  }

  button {
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    overflow: visible;
    cursor: pointer
  }


  input[type="date"]::-webkit-clear-button,
  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    width: 100%;
    height: 30px;
    z-index: 1;
  }


  input[type="date"]:focus::before,
  input[type="date"]:valid::before {
    display: none;
  }

  /* 
  input[type=date]::-webkit-datetime-edit-text {
      -webkit-appearance: none;
      display: none;
  }
  input[type=date]::-webkit-datetime-edit-month-field{
      -webkit-appearance: none;
      display: none;
  }
  input[type=date]::-webkit-datetime-edit-day-field {
      -webkit-appearance: none;
      display: none;
  }
  input[type=date]::-webkit-datetime-edit-year-field {
      -webkit-appearance: none;
      display: none;
  } */

  ul::after {
    content: '';
    display: block;
    clear: both;
  }


  input[type="radio"] {
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    cursor: pointer;
    background: url(${RadioButton});

    &:checked {
      background: url(${RadioButtonActive});
    }

    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* input type number 에서 화살표 제거 */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  textarea::placeholder {
    color: #757575;
  }

  textarea:focus-visible {
    outline: none;
  }


`;


export default GlobalStyle;
