import {useEffect, useState} from "react";
import {API_STATE} from "common/constants/state";
import useRequest from "hooks/useRequest";

const useImageUpload = (uploadUrl, key) => {
    const uploadRequest = useRequest("post", uploadUrl);
    const [imageSrc, setImageSrc] = useState("");
    const [imageName, setImageName] = useState("");
    let _key
    if (key === 'file') {
        _key = key || "file";
    } else {
        _key = 'image'
    }


    const onUploadImage = (fileBlob) => {
        console.log("encode start,", fileBlob);
        const formData = new FormData();
        formData.append(_key, fileBlob);
        uploadRequest
            .asyncCall(formData)
            .then((res) => {
                const {success, response,error} = res;
                if (success) {
                    setImageSrc(response.url);
                    setImageName(fileBlob.name);
                }else{
                    if(error.code === 400){
                        alert("등록하신 파일의 확장자를 확인해주세요.")
                        return;
                    }
                }
            });
    }

    useEffect(() => {
        switch (uploadRequest.state) {
            case API_STATE.done:
                if (uploadRequest.response.success) {
                    const {response} = uploadRequest?.response;
                    setImageSrc(response.url);
                }
                break;
            default:
                break;
        }
    }, [uploadRequest.state, uploadRequest.error, uploadRequest.response]);

    return {imageSrc, imageName, onUploadImage};
};

export default useImageUpload;
