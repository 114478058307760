export const ROLE_SUPER = 'ROLE_SUPER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_BIZZ = 'ROLE_BIZZ';
export const ROLE_USER = 'ROLE_USER';

export const ROLE = {
    ROLE_ADMIN: '관리자',
    ROLE_BIZZ: '비즈 회원',
    ROLE_USER: '일반 회원'
};

export const STATUS = {
    NORMAL: '정상',
    STOP: '정지',
    WITHDRAWAL: '탈퇴'
};

export const GRADE = {
    FIRST: '1단계 회원',
    SECOND: '2단계 회원'
};

export const SEARCH_OPTIONS = [{
    text: '전체',
    value: ''
}, {
    text: '관리자',
    value: 'ROLE_ADMIN'
}, {
    text: '비즈 회원',
    value: 'ROLE_BIZZ'
}, {
    text: '일반 회원',
    value: 'ROLE_USER'
}];

export const USER_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '9%'
}, {
    key: 'role',
    title: '구분',
    width: '16%'
}, {
    key: 'userId',
    title: '아이디',
    width: '16%'
}, {
    key: 'name',
    title: '채널명/닉네임',
    width: '41%'
}, {
    key: 'createdAt',
    title: '생성/가입일',
    width: '9%'
}, {
    key: 'status',
    title: '상태',
    width: '9%'
}];

export const PUZZLE_TOKEN_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '9%'
}, {
    key: 'createdAt',
    title: '생성일자',
    width: '9%'
}, {
    key: 'userId',
    title: '아이디',
    width: '16%'
}, {
    key: 'token',
    title: 'NFT 발행코드',
    width: '*'
}, {
    key: 'joinedAt',
    title: '가입일',
    width: '9%'
}];

export const STATUS_OPTIONS = [{
    text: STATUS.NORMAL,
    value: 'NORMAL'
}, {
    text: STATUS.STOP,
    value: 'STOP'
}, {
    text: STATUS.WITHDRAWAL,
    value: 'WITHDRAWAL'
}];

export const POINT_LIST = [
    {
        key: 'id',
        title: 'NO.',
        width: '4.555%'
    }, {
        key: 'createdAt',
        title: '일자',
        width: '5.85%'
    }, {
        key: 'type',
        title: '구분',
        width: '5.85%'
    }, {
        key: 'amount',
        title: '포인트',
        width: '10%'
    }, {
        key: 'etc',
        title: '비고',
        width: '10%'
    }
];

export const POINT_LIST_STATUS = [
    {
        label: '전체',
        value: ''
    }, {
        label: '보상',
        value: 'REWARD'
    }, {
        label: '보내기',
        value: 'SEND'
    }, {
        label: '보상 지급',
        value: 'RECEIVE'
    }, {
        label: '사용',
        value: 'USAGE'
    },
];

export const BIZ_POINT_LIST_STATUS = [
    {
        label: '전체',
        value: ''
    }, {
        label: '보상',
        value: 'REWARD'
    }, {
        label: '충전',
        value: 'CHARGE'
    }, {
        label: '취소',
        value: 'CANCEL'
    },
];


export const BIZ_POINT_LIST_TYPE_STATUS = {
    REWARD: '보상',
    CHARGE: '충전',
    CANCEL: '취소',
    RECEIVE: '보상 지급',
};
