import React from "react";

import {CataTab, CataTabLi, CataTabUl} from "./TabStyle";

const Tab = ({tabList = [], initTab = '', onChange, ...props}) => {
    const {Channel} = props;
    const handleClick = (tab) => {
        onChange && onChange(tab);
    }

    return (
        <>
            <CataTab Channel={Channel}>
                <CataTabUl>
                    {tabList.map((tab, index) => {
                        return <CataTabLi On={tab.value === initTab}
                                          key={index}
                                          onClick={() => handleClick(tab.value)}
                                          Channel={tab.value === ''}
                        >
                            {tab.text}
                        </CataTabLi>
                    })}
                </CataTabUl>
            </CataTab>

        </>
    )
}

export default Tab;
