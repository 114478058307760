import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {API_STATE} from "common/constants/state";
import DATE from "common/constants/date";
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import {
    Button,
    CloseButton,
    GrayButton,
    LineBar,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    ModalSpan,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Wrapper
} from "common/styledComponents/commonStyle";
import Info from './Info'
import Title from 'common/components/Title'
import {useGlobalStore} from "stores";
import Table from '../../../../common/components/Table';
import {CATEGORY_LIST_OPTIONS} from '../../../../common/constants/category';
import ConvertCategoryProfileImage from '../../../../common/js/ConvertCategoryProfileImage';
import {useForm} from 'react-hook-form';
import storageService from '../../../../util/storage';
import CloseIcon from '../../../../asset/img/icon__close_black.svg';
import axios from 'axios';

const CommonDetail = ({category, onSelectTableRow}) => {
    const navigate = useNavigate();
    const pageParams = useParams();
    const {id} = pageParams;
    const refName = useRef(null);
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [formData, setFormData] = useState({offset: 0, limit: 10});
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const [categoryInfo, setCategoryInfo] = useState({});
    const [onReturnModal, setOnReturnModal] = useState(false);
    const [onConfirmModal, setOnConfirmModal] = useState(false);
    const [stopData, setStopData] = useState({
        reason: '', title: '',
    });
    const categoryDetailRequest = useRequest("get", `category/${id}/${category}`);
    const categoryUpdateRequest = useRequest("patch", `category/${id}/${category}/update`);
    const categoryDeleteRequest = useRequest("delete", `category/${id}/${category}/delete`);
    const addCategoryImgRequest = useRequest("post", "image/category/channel/new");
    const categoryList = useRequest("get", `${category}/category/${id}/list`);
    // const [categoryNameTest, setCategoryNameTest] = useState('');
    // const [updateFormTest, setUpdateFormTest] = useState({});
    const {watch, handleSubmit, control, setValue, register, formState: {isDirty}} = useForm();
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();


    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {rows, fetch, params} = useBoard(categoryList, (res) => {
        setTotalNum(res.totalCount);
        return CATEGORY_LIST_OPTIONS[category].iterator.bind(null, res);
    });

    // const handleUpdate = (e) => {
    //     addCategoryImgRequest.call(imageFileSrc)
    // }

    const removeCategory = () => {
        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }
        categoryDeleteRequest.call(id)
    }

    const onSubmit = async (data) => {

        // if (!isDirty) {
        //     return
        // }

        let formData

        if (data.Url && data.Url.Url) {
            await ConvertCategoryProfileImage(data.Url.Url, 'body').then((imageUrl) => {
                formData = {...data, Url: imageUrl.url}
            }).catch(error => console.log(error))
        } else {
            formData = {...data, Url: data.Url}
        }

        categoryUpdateRequest.call(formData, 'body')
    }

    // const handleClickBtnKeywords = () => {
    //     navigate(`/contents/keywordMange?categoryId=${id}`);
    // }

    const categoryNameOnchange = (e) => {
        const name = e.target.value;

        if (name.length > 10) {
            setValue('name', name.slice(0, 10))
        } else {
            setValue('name', name)
        }
    }

    const handlePage = (pageNum) => {
        setCurPage(pageNum);
        setFormData({...formData, page: pageNum, offset: pageNum - 1});
    }

    const handleStatus = (status) => {

        // setUpdateFormTest({...updateFormTest, status: status})
    }

    const openConfirmModal = () => {
        setOnConfirmModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeConfirmModal = () => {
        document.body.style.overflow = 'auto';
        setOnConfirmModal(false)
    }

    const categoryStatusStop = (e) => {
        setValue('status', 'STOP')
        closeConfirmModal()
    }

    const categoryStatusNormal = (e) => {
        setValue('status', 'NORMAL')
        closeConfirmModal()
    }


    const openStopReasonModal = () => {
        setOnReturnModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeStopReasonModal = () => {
        document.body.style.overflow = 'auto';
        setOnReturnModal(false)
    }

    const showReturnModal = async (e) => {
        const token = storageService.get("auth", "accessToken", "local");
        // console.log(e.target)
        const id = Number(e.currentTarget.id);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/poll/${id}/stop`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            setStopData(prevState => ({
                ...prevState, reason: response.data.response.reason,
                title: rows.filter((row) => row.id === id)[0].title
            }))
            openStopReasonModal()
        } catch (e) {
            console.log(e)
        }
    }

    const columnRenderer = (key, value, _, row) => {
        if (key === "viewStatus") {
            return <span onClick={showReturnModal}
                         id={row.id}
                         style={{
                             color: row.viewStatus ? '#1A1A1A' : '#C91615',
                             borderBottom: row.viewStatus ? null : '1px solid #C91615',
                             cursor: 'pointer'
                         }}>
                {row.viewStatus ? '활성화' : '비활성화'}
            </span>
        }

        if (key === 'status' && row.status === "검수반려") {
            return <span id={row.id}
                         style={{color: '#C91615', borderBottom: '1px solid #C91615', cursor: 'pointer'}}>
                {row.status}
            </span>
        }

        if (key === 'status' && row.status === "완료") {
            return <span style={{color: '#757575'}}>
                {row.status}
            </span>
        }

        return value
    }

    useEffect(() => {
        switch (addCategoryImgRequest.state) {
            case API_STATE.done:
                if (addCategoryImgRequest.response.success) {
                    categoryUpdateRequest.call(formData);
                }
                break;
            default:
                break;
        }
    }, [addCategoryImgRequest.state, addCategoryImgRequest.error, addCategoryImgRequest.response]);


    useEffect(() => {
        switch (categoryDetailRequest.state) {
            case API_STATE.done:
                if (categoryDetailRequest.response.success) {
                    const {response} = categoryDetailRequest.response;
                    // setUpdateFormTest({name: response.name})
                    if (!response) {
                        return;
                    }
                    const categoryInfo = {
                        ...response,
                        categoryName: CATEGORY_LIST_OPTIONS[category].NAME,
                        createdAt: dayjs(new Date(response.createdAt)).format(DATE.FORMAT.DOT)
                    }
                    setCategoryInfo(categoryInfo);
                    // setCategoryNameTest(categoryInfo.name)
                }

                break;
            default:
                break;
        }
    }, [categoryDetailRequest.state, categoryDetailRequest.error, categoryDetailRequest.response]);


    useEffect(() => {
        switch (categoryUpdateRequest.state) {
            case API_STATE.done:
                if (categoryUpdateRequest.response.success) {
                    alert("변경 되었습니다.");
                    navigate(`/contents/categoryManage?type=${category}`)
                }
                break;
            default:
                break;
        }
    }, [categoryUpdateRequest.state, categoryUpdateRequest.error, categoryUpdateRequest.response]);

    useEffect(() => {
        switch (categoryDeleteRequest.state) {
            case API_STATE.done:
                if (categoryDeleteRequest.response.success) {
                    alert("변경 되었습니다.");
                    navigate(`/contents/categoryManage?type=${category}`)
                }

                if (!categoryDeleteRequest.response.success) {
                    alert("소속된 컨텐츠가 존재하여 삭제가 불가능합니다.");
                    // navigate(`/contents/categoryManage?type=${category}`)
                }
                break;
            default:
                break;
        }
    }, [categoryDeleteRequest.state, categoryDeleteRequest.error, categoryDeleteRequest.response]);

    useEffect(() => {
        const {offset} = formData;
        if (offset === params.offset) {
            return;
        }
        fetch({
            ...params,
            offset
        });
    }, [formData]);


    useEffect(() => {
        categoryDetailRequest.call();
        const {offset, limit} = params;
        fetch({
            offset,
            limit
        });
    }, []);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>카테고리 상세보기</Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Info info={categoryInfo}
                            // categoryNameTest={categoryNameTest}
                              categoryNameOnchange={categoryNameOnchange}
                              inputRef={refName}
                              domain={domain}
                            // handleUpdate={handleUpdate}
                              onSubmit={onSubmit}
                            // setUpdateFormTest={setUpdateFormTest}
                              navigate={navigate}
                              pageId={id}
                              category={category}
                              contentsAuth={contentsAuth}
                              handleStatus={handleStatus}
                              watch={watch()}
                              control={control}
                              setValue={setValue}
                              register={register}
                              removeCategory={removeCategory}
                              openConfirmModal={openConfirmModal}
                        />
                    </form>
                    <LineBar/>
                    <Table pageData={formData} totalPages={pageInfo?.totalPages}
                           columns={CATEGORY_LIST_OPTIONS[category].LIST_COLUMNS} rows={rows}
                           onSelectRow={onSelectTableRow} totalNum={totalNum} searchParams={searchParams}
                           columnRenderer={columnRenderer}
                           showReturnModal={showReturnModal}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
            {onReturnModal &&
                <StyledModal>
                    <ModalContainer return>
                        <RewardDetailTitle>비활성화 사유 확인</RewardDetailTitle>
                        <RewardDetailContent return>
                            <ModalSpan>{`[${stopData.title}]`}</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>설문이 비활성화 됐습니다.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>아래에 사유를 확인해주세요.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>{stopData.reason}</ModalSpan>
                            <ModalSpan margin='auto 0 0 0'>문의 1:1 문의하기 혹은 info@pollberry.net</ModalSpan>
                        </RewardDetailContent>
                        <Button mini onClick={closeStopReasonModal} margin='48px 0 0 0'>확인</Button>
                        <CloseButton onClick={closeStopReasonModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }

            {onConfirmModal &&
                <StyledModal>
                    <ModalContainer notice Confirm>
                        <RewardDetailTitle>카테고리 관리</RewardDetailTitle>
                        <RewardDetailContent Confirm>
                            <ModalSpan>
                                카테고리에 소속된 컨텐츠 및 카테고리명 노출이 정지됩니다. 상태변경을 하시겠습니까?
                            </ModalSpan>
                        </RewardDetailContent>
                        <Wrapper Row margin='auto 0 0 0'>
                            <GrayButton mini onClick={categoryStatusNormal}>취소</GrayButton>
                            <Button mini onClick={categoryStatusStop}>확인</Button>
                        </Wrapper>
                        <CloseButton onClick={closeConfirmModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }
        </>
    )
}

export default CommonDetail;
