import styled, {css} from "styled-components";
import RadioButtonActive from "asset/img/icon__radio_button_active.png";
import RadioButton from "asset/img/icon__radio_button.png";
import CheckButtonActive from "asset/img/icon__check_box_active.png";
import CheckButton from "asset/img/icon__check_box.png";
import LeftArrowIcon from "asset/img/icon__keyboard_arrow_left.png";
import KeyLeft from "asset/img/icon__keyboard_arrow_left.png";
import RightArrowIcon from "asset/img/icon__keyboard_arrow_right.png";
import KeyRight from "asset/img/icon__keyboard_arrow_right.png";
import FirstArrowIcon from "asset/img/icon__first_page.png";
import LastArrowIcon from "asset/img/icon__last_page.png";
import BackIcon from "asset/img/icon__back.png";
import TextDelIcon from "asset/img/icon__cancel.png";
import InfoGray from "asset/img/icon__info_gray.png";
import DragIcon from "asset/img/icon__drag_indicator.png";
import DelIconBig from "asset/img/icon__cancel_big.png";
import WhiteArrow from "asset/img/topbar_back_white.png";
import TopImgIcon from "asset/img/poll_de.png";
import BerryIcon from "asset/img/berry_icon.png";
import CloseBlack from "asset/img/topbar_close_black.png";
import CheckButtonSmall from "asset/img/icon__check_box_smaill.png";
import CheckButtonSmallOn from "asset/img/icon__check_box_smaill_on.png";
import CircleCheck from "asset/img/icon_circle_check.svg";
import CircleCheckOn from "asset/img/icon_circle_check_on.svg";
import PopListUpIcon from "asset/img/icon__arrow_drop_up_default.png";
import PopListUpIconActive from "asset/img/icon__arrow_drop_up_active.png";
import PopListDownIcon from "asset/img/icon__arrow_drop_down_default.png";
import PopListDownIconActive from "asset/img/icon__arrow_drop_down_active.png"


import DatePicker from 'react-datepicker';
import {components} from 'react-select';
import Indicator from '../../asset/img/icon__arrow_drop_down_gray.png';
import {format} from 'date-fns'


export const Button = styled.button`
    border-radius: 8px;
    box-sizing: border-box;
    width: ${(props) => props.width || "380px"};
    padding: 12px 8px;
    font-size: ${(props) => props.fontsize || "24px"};
    color: ${(props) => (props.disabled ? "#757575" : "#fff")};
    background-color: ${(props) => (props.disabled ? "#c2c2c2" : "#000065")};
    font-weight: 500;
    height: ${(props) => props.height || "60px"};
        //color: ${(props) => (props.disabled ? "#8f8f8f" : "#1A1A1A")};
        //background: ${(props) => (props.disabled ? "#c2c2c2" : "#dbdbdb")};
    cursor: ${(props) => (props.disabled && "not-allowed")};

    ${(props) =>
            props.mini &&
            css`
                width: 180px;
                height: 48px;
                font-size: 18px;
                padding: 8px;
                margin: ${(props) => props.margin};
            `}
    ${(props) =>
            props.tit &&
            css`
                position: absolute;
                right: 0;
                bottom: 24px;
            `}
    ${(props) =>
            props.short &&
            css`
                width: 100px;
                height: 36px;
                font-size: 14px;
                padding: 6px;
                margin: ${(props) => props.margin || "0 0 0 20px"};
            `}
    ${(props) =>
            props.line &&
            css`
                background-color: #fff;
                color: #000065;
                border: 2px solid #000065;
            `}

    ${(props) =>
            props.preview &&
            css`
                background-color: #dbdbdb;
                color: #1a1a1a;

                & + & {
                    margin-left: 20px;
                }
            `}

    ${(props) =>
            props.MarginLine &&
            css`
                background-color: #fff;
                color: #000065;
                border: 2px solid #000065;

                & + & {
                    margin-left: 28px;
                }
            `}
    ${(props) =>
            props.bigdata &&
            css`
                width: 380px;
                height: 60px;
                font-size: 24px;
            `}
`;

export const AddButton = styled.button`
    padding: 8px;
    background-color: #dbdbdb;
    border-radius: 8px;
    width: ${(props) => props.width || "100px"};
    //width: 100%;
    margin: ${(props) => props.margin || "0 0 0 8px"};
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 20px;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: transparent;
`;

//팝업
export const PopInputTxt = styled.div`
    margin: 16px 0 48px;
    position: relative;
`;
export const PopInput = styled.input`
    background-color: #f5f5f5;
    border-radius: 8px;
    width: 470px;
    height: 48px;
    padding: 16px 24px;
    max-width: 100%;
`;
export const PopInputLong = styled.input`
    border: 1px solid #757575;
    border-radius: 8px;
    width: 580px;
    height: 48px;
    padding: 16px 54px 16px 16px;
    max-width: 100%;
`;

export const PopButton = styled.button`
    padding: 8px;
    background-color: #000065;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    width: 58px;
    height: 36px;
    margin-left: 32px;
    ${(props) =>
            props.end &&
            css`
                position: absolute;
                right: 48px;
                bottom: 59px;
            `}
    ${(props) =>
            props.wide &&
            css`
                position: absolute;
                right: 48px;
                width: 100px;
                bottom: 48px;
            `}
    ${(props) =>
            props.big &&
            css`
                width: 180px;
                height: 48px;
                font-size: 18px;
                line-height: 18px;
                text-align: center;
                margin: 0;
            `}

    ${(props) =>
            props.center &&
            css`
                width: 180px;
                height: 48px;
                font-size: 18px;
                line-height: 18px;
                //transform: translate(50%);
                //position: absolute;
                //right: 50%;
                //bottom: 24px;
                margin: 0;
            `}
    ${(props) =>
            props.right &&
            css`
                width: 66px;
                height: 48px;
                background-color: #dbdbdb;
                color: #1a1a1a;
                font-size: 18px;
                line-height: 18px;
                margin-left: 8px;
            `}

    ${(props) =>
            props.half &&
            css`
                width: 282px;
                height: 48px;
                font-size: 18px;
                line-height: 18px;
                margin: 0;
            `}
    ${(props) =>
            props.twin &&
            css`
                width: 238px;
                height: 48px;
                font-size: 18px;
                line-height: 18px;
                margin: 0;
                border: 2px solid #000065 !important;
            `}
    ${(props) =>
            props.line &&
            css`
                background-color: #fff;
                color: #000065;
                border: 2px solid #000065;
                float: left;
                margin-right: 24px;
            `}

    ${(props) =>
            props.Admin &&
            css`
                width: 180px;
                height: 48px;
                font-size: 18px;
                line-height: 18px;
                margin: 48px 0 0 0;
            `}
`;
export const PopButtonBox = styled.div`
    margin-top: 48px;
    height: 48px;
`;

export const PopTextareaCont = styled.div`
    margin-top: 48px;
    position: relative;
`;


export const PopTextareaBox = styled.span`
    border: 1px solid #000065;
    width: 384px;
    height: 284px;
    padding: 16px;
    border-radius: 8px;
    display: inline -block;
    margin-top: 16px;
    ${(props) =>
            props.show &&
            css`
                border: 0;
                height: auto;
                padding: 0;
                margin: 48px 0;
            `}
`;

export const PopTextarea = styled.textarea`
    width: 352px;
    height: 252px;
    border: 0;
    resize: none;
    color: #757575;

    &:focus {
        outline: none;
    }

`;

export const PopTextareaNum = styled.span`
    font-size: 12px;
    color: #757575;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: -20px;
`;

export const PopTextareaNoti = styled.p`
    font-size: 12px;
`;


export const PopUp = styled.div`
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;

    ${(props) =>
            props.dark &&
            css`
                background: rgba(26, 26, 26, 0.8);
            `}

`;

export const PopBox = styled.div`
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    padding: 48px 48px 42px;
    display: flex;
    flex-flow: column;
    align-items: center;
    //text-align: center;
    //justify-content: center;
    //width: 794px;
    //height: 346px;
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    ${(props) =>
            props.in &&
            css`
                width: 640px;
                height: 316px;
                padding: 20px 48px 24px;
            `}
    ${(props) =>
            props.info &&
            css`
                width: 540px;
                //height: 392px;
                padding: 20px 48px 24px;
            `}
    ${(props) =>
            props.cate &&
            css`
                width: 676px;
                height: 447px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                justify-content: space-between;
            `}
    ${(props) =>
            props.short &&
            css`
                width: 794px;
                height: 304px;
            `}

    ${(props) =>
            props.check &&
            css`
                width: 480px;
                height: auto;
                min-height: 296px;
                border-radius: 20px;
                padding: 15px 48px 24px;
                text-align: center;
                position: relative;
            `}
    ${(props) =>
            props.poll &&
            css`
                width: 740px;
                height: 396px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                text-align: center;
                position: relative;
            `}
    ${(props) =>
            props.user &&
            css`
                width: 656px;
                height: auto;
                max-height: 960px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                text-align: center;
                position: relative;
            `}
    ${(props) =>
            props.my &&
            css`
                width: 685px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                position: relative;
            `}
    ${(props) =>
            props.puzzle &&
            css`
                width: 384px;
                height: 312px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                text-align: center;
                position: relative;
            `}

    ${(props) =>
            props.grade &&
            css`
                width: 956px;
                height: 620px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                position: relative;
                text-align: center;
            `}
    ${(props) =>
            props.nick &&
            css`
                width: 685px;
                height: 920px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                text-align: center;
                position: relative;
            `}
    ${(props) =>
            props.point &&
            css`
                width: 776px;
                height: 982px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                text-align: center;
                position: relative;
            `}
    ${(props) =>
            props.modalForAuthSettings &&
            css`
                width: 776px;
                height: 550px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                position: relative;
            `}
    ${(props) =>
            props.pay &&
            css`
                width: 597px;
                height: 576px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                position: relative;
            `}
    ${(props) =>
            props.detail &&
            css`
                width: 740px;
                height: 476px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                position: relative;
            `}
    ${(props) =>
            props.option &&
            css`
                width: 876px;
                height: 890px;
                border-radius: 20px;
                padding: 20px 48px 24px;
                position: relative;
            `}

    ${(props) =>
            props.Cancel &&
            css`
                width: 480px;
                height: 738px;
                border-radius: 20px;
                padding: 15px 48px 24px;
            `}
`;

export const PopIcon = styled.p`
    text-align: center;
    height: 48px;
    margin-bottom: 48px;
`;
export const PopClose = styled.button`
    width: 32px;
    height: 32px;
    right: 12px;
    top: 12px;
    position: absolute;
`;

export const PollPreviewCloseButton = styled.button`
    width: 100%;
    background: rgba(26, 26, 26, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-top: 20px;

    img {
        margin-left: 4px;
    }
`;

export const PopHeadTxt = styled.p`
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 38px;
    text-align: center;
`;

export const PopTxt = styled.p`
    & + & {
        margin-top: 20px;
    }

    ${(props) =>
            props.check &&
            css`
                margin-left: 25px;
                font-size: 12px;
                color: #757575;
                line-height: 25px;
            `}

    ${(props) =>
            props.left &&
            css`
                width: 100%;
                text-align: left;
            `}
    ${(props) =>
            props.red &&
            css`
                color: #E92928;
            `}

    ${(props) =>
            props.pw &&
            css`
                margin-top: 240px;
            `}

    ${(props) =>
            props.Approve &&
            css`
                margin-top: 47px;
            `}

`;

export const PointCanCelReasonSpan = styled.p`
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    cursor: default;
    max-height: 284px;
    height: 100%;
    padding: 16px;
    margin-top: auto;
`

export const PopPollListBox = styled.div`
    margin: 38px 0 48px;
    ${(props) =>
            props.user &&
            css`
                padding-bottom: 48px;
            `}
    ${(props) =>
            props.puzzle &&
            css`
                margin: 24px 0 48px;
            `}
    ${(props) =>
            props.detail &&
            css`
                margin: 62px 0 48px;
            `}
`;
export const PopPollList = styled.p`
    text-align: left;

    & + & {
        margin-top: 16px;
    }

`;
export const PopPollListTxtL = styled.span`
    width: 140px;
    margin-right: 24px;
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    ${(props) =>
            props.margin &&
            css`
                margin: 0 0 16px;
            `}
`;
export const PopPollListTxtR = styled.span`
    max-width: 480px;
    display: inline-block;

    ${(props) =>
            props.center &&
            css`
                text-align: center;
            `}
    ${(props) =>
            props.underline &&
            css`
                text-decoration: underline;
            `}
`;

export const PopTable = styled.div`
    margin: 24px 0 20px;
    width: 100%;

    ${(props) =>
            props.full && css`
                display: grid;
                grid-row: auto;
                grid-template-columns: 0.4fr 1fr;
                grid-gap: 16px 24px;
                text-align: left;
                margin-bottom: 0;
            `}

    ${(props) =>
            props.Cancel &&
            css`
                margin: 0;

                p {
                    display: flex;
                }

                span {
                    flex: auto;
                }
            `}
`;

export const PopLeftTxt = styled.span`
    width: 140px;
    margin-right: 28px;
    font-weight: 500;
    display: inline-block;
    ${(props) =>
            props.left &&
            css`
                text-align: left !important;
            `}

`;

export const PopRightTxt = styled.span`
    width: 180px;
    display: inline-block;
    ${(props) =>
            props.right &&
            css`
                text-align: right !important;
            `}
`;

export const PopCheck = styled.label`
    position: relative;
    //margin: 60px 0 0;
    margin-bottom: 48px;
    width: 100%;
    cursor: pointer;
`;

export const PopCenterTxt = styled.p`
    line-height: 1.75;
    text-align: center;
`;
export const PoptextCount = styled.span`
    position: absolute;
    top: 16px;
    right: 8px;
    line-height: 1;
    color: #757575;
`;

export const Poperror = styled.p`
    font-size: 10px;
    color: #e92928;
    margin-top: 4px;
`;

export const PopCateUl = styled.ul`
    margin-top: 81px;
`;

export const PopCateli = styled.li`
    font-size: 14px;
    float: left;
    text-align: center;
    width: 33.33%;
`;

export const PopPointMenu = styled.div`
    margin: 34px 0 0;
    text-align: left;
    height: 30px;
`;

export const PopPointMenuUl = styled.ul`
    display: inline-block;
`;

export const PopPointMenuLi = styled.li`
    border: 1px solid #dbdbdb;
    padding: 6px 16px 8px;
    display: inline-block;
    border-radius: 8px;
    float: left;
    line-height: 1;
    height: 100%;
    box-sizing: border-box;

    a {
        color: #424242;
        font-size: 14px;
    }

    & + & {
        margin-left: 8px;
    }

    ${(props) =>
            props.on &&
            css`
                border: 1px solid #000065;
                background-color: #000065;

                a {
                    color: #fff;
                }
            `}
`;

export const PopPointMenuMonth = styled.div`
    float: right;
    margin-top: 5px;
`;


export const PopPointDiv = styled.div`
    margin: 38px 0 -24px;
    height: 30px;
`;
export const PopPointListUl = styled.ul`
    float: left;
`;
export const PopPointListLi = styled.li`
    float: left;
    margin-right: 8px;
    border: 1px solid #dbdbdb;
    padding: 0px 15px;
    border-radius: 8px;
    box-sizing: border-box;
    height: 30px;
    ${(props) =>
            props.on &&
            css`
                background-color: #000065;

                a {
                    color: #fff;
                }
            `}
`;
export const PopPointListA = styled.a`
    color: #424242;
    font-size: 14px;
`;
export const PopPointMonth = styled.div`
    position: absolute;
    right: 48px;
`;
export const PopPointMonthTxt = styled.span`
    color: #424242;
    font-size: 14px;
    float: left;
    line-height: 29px;
`;
export const PopPointMonthIcon = styled.span`
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    float: left;

    a {
        content: "";
        font-size: 0;
        width: 24px;
        height: 24px;
        display: inline-block;
        position: absolute;
        top: 1px;
    }

    ${(props) =>
            props.prev &&
            css`
                a {
                    background: url(${LeftArrowIcon});
                    left: 0;
                }
            `}
    ${(props) =>
            props.next &&
            css`
                a {
                    background: url(${RightArrowIcon});
                    right: 0;
                }
            `}
`;

export const PopNoticeTxt = styled.p`
    clear: both;
    font-size: 14px;
    color: #757575;
    padding-top: 16px;
    margin-bottom: 48px;
`;

export const PopListOption = styled.div`
    width: 380px;
    height: 658px;
    float: left;

    & + & {
        margin-left: 20px;
    }
`;
export const PopListOptionTit = styled.p`
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    padding: 16px 0;
    border-bottom: 2px solid #000065;
`;
export const PopListOptionUl = styled.ul`
    display: block;
    //border-top: 0;
    border: 1px solid #dbdbdb;
    min-height: 600px;
    ${(props) =>
            props.cate &&
            css`
                li:hover {
                    &::after {
                        content: "";
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        right: 4px;
                        top: 18px;
                        background-image: url(${RightArrowIcon});
                    }
                }
            `}
    ${(props) =>
            props.title &&
            css`
                li:after {
                    display: none;
                }
            `}
`;
export const PopListOptionLi = styled.li`
    padding: 18px 0 18px 20px;
    position: relative;

    &:hover {
        background-color: #f5f5f5;
        cursor: pointer;

        span {
            margin-left: 24px;
            font-weight: 500;
        }

        button {
            display: inline-block;
        }
    }

    ${(props) =>
            props.on &&
            css`
                background-color: #f5f5f5;

                span {
                    margin-left: 24px;
                    font-weight: 500;
                }

                button {
                    display: inline-block;
                }

                &::after {
                    content: "";
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 4px;
                    top: 18px;
                    background-image: url(${RightArrowIcon});
                }
            `}
`;
export const PopListUpBtn = styled.button`
    display: none;
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 8px;
    background-image: url(${PopListUpIcon});
    font-size: 0;

    &:hover {
        background-image: url(${PopListUpIconActive});
    }

    ${(props) =>
            props.on &&
            css`
                background-image: url(${PopListUpIconActive});
            `}
`;
export const PopListDownBtn = styled.button`
    display: none;
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 4px;
    left: 8px;
    background-image: url(${PopListDownIcon});
    font-size: 0;

    &:hover {
        background-image: url(${PopListDownIconActive});
    }

    ${(props) =>
            props.on &&
            css`
                background-image: url(${PopListDownIconActive});
            `}
`;
export const PopListTxt = styled.span`
`;


//채널 거래 내역
export const PayTable = styled.table`
    width: 500px;
    box-sizing: border-box;
    border: 1px solid #f5f5f5;
`;
export const PayTableTr = styled.tr`
`;
export const PayTableTh = styled.th`
    padding: 8px;
    width: 250px;
    line-height: 1;
    text-align: center;
    color: #757575;
    background-color: #f5f5f5;
    height: 32px;
`;
export const PayTableTd = styled.td`
    padding: 8px;
    width: 250px;
    line-height: 1;
    text-align: left;
    height: 44px;

    & + & {
        border-left: 1px solid #f5f5f5;
    }
`;


//운용 포인트 충전
export const FullPointBox = styled.div`
    height: 232px;
    margin-top: 48px;

    ${(props) => props.full && css`
        height: 244px;
        width: 100%;
    `}

    ${(props) => props.Approve && css`
        margin: 58px 0 105px;
        height: auto;
    `}

    ${(props) => props.Return && css`
        margin: 5px 0 48px;
        height: auto;
    `}

    ${(props) => props.Cancel && css`
        width: 100%;
        height: 100%;
        max-height: 318px;
    `}

`;
export const FullPointInputBox = styled.div`
    width: 384px;
    height: 60px;
    padding: 16px 65px 16px 16px;
    box-sizing: border-box;
    border: 1px solid #000065;
    border-radius: 8px;
    position: relative;
    margin: 8px auto 16px;

    ${(props) => props.area && css`
        padding: 16px;
        height: 116px;
    `}

    ${(props) => props.Cancel && css`
        margin-bottom: 0;
    `}

    ${(props) => props.Return && css`
        margin: 4px 0 0 0;
    `}

`;
export const FullPointInput = styled.input`
    width: 100%;
    height: 100%;
    text-align: right;

    ${(props) => props.Return && css`
        text-align: left;
    `}
`;

export const FullPointInputTxt = styled.span`
    position: absolute;
    right: 16px;
`;

export const FullPointTextarea = styled.textarea`
    resize: none;
    border: 0;
    width: 100%;
    height: 100%;

`;

//설문 팝업
export const PopPollBox = styled.div`
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 360px;
    height: 740px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 20px;
    //overflow: hidden;
`;

export const ArrowBtn = styled.button`
    position: absolute;
    left: auto;
    right: auto;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    cursor: pointer;
    ${(props) =>
            props.Left &&
            css`
                left: 25%;
            `}
    ${(props) =>
            props.Right &&
            css`
                right: 25%;
            `}
`;

export const TopImg = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 20px 20px 0 0;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-image: url(${TopImgIcon});
        width: 100%;
        height: 300px;
        display: inline-block;
        opacity: 0.5;
    }

    img {
        width: 100%;
        height: 300px;
    }
`;
export const TopInfo = styled.div`
    width: 100%;
    height: ${(props) => props.height};
    padding: 24px 0 20px;
    position: relative;

`;
export const TopHead = styled.div`
    font-weight: 700;
    font-size: 16px;
    padding: 12px 0 7px;
    text-align: center;
    height: 42px;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .2);

    button {
        position: absolute;
        top: 30px;
        left: 20px;
        content: "";
        background-image: url(${WhiteArrow});
        width: 30px;
        height: 30px;
        display: inline-block;
        font-size: 0;
        cursor: default;
    }

    ${(props) =>
            props.xButton &&
            css`
                color: #000000;
                position: relative;
                padding: 6px 0;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 120px;
                    height: 2px;
                    background: #0000fe;
                }

                button {
                    background-image: url(${CloseBlack});
                    top: 6px;
                    left: 10px;
                }
            `}
`;
export const TopTxtGroup = styled.div`
    margin: 28px 20px 0;

`;
export const TopTxtTit = styled.p`
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    text-shadow: 0 0 5px rgba(255, 255, 255, .25);
    text-align: left;
`;
export const TopTxtSub = styled.div`
    position: absolute;
    left: 20px;
    bottom: 50px;
`;
export const TopTxtSubspan = styled.span`
    border: 1px solid #424242;
    color: #424242;
    margin-right: 8px;
    border-radius: 6px;
    padding: 2px 6px;
    font-size: 13px;
    font-weight: 500;
    background-color: #fff;
    ${(props) =>
            props.blue &&
            css`
                color: #fff;
                background-color: #0000fe;
            `}
    ${(props) =>
            props.icon &&
            css`
                padding: 2px 6px 2px 18px;
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    background-image: url(${BerryIcon});
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    position: absolute;
                    left: 2px;
                    top: 3px;
                }
            `}
`;
export const BotInfo = styled.div`
    margin-top: -10px;
    border-radius: 10px 10px 20px 20px;
    background-color: #fff;
    height: 450px;
    overflow-y: auto;
    position: relative;
    padding-bottom: 52px;


    ::-webkit-scrollbar {
        width: 0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }
`;

export const BotChannel = styled.div`
    padding: 30px 20px 24px;
    border-bottom: 2px solid #f5f5f5;
    font-weight: 500;
    position: relative;
`;
export const BotChaImg = styled.span`
    float: left;
    width: 42px;
    height: 42px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: inline-block;
`;
export const BotChaTxtGroup = styled.div`
    margin-left: 10px;
    display: inline-block;
    height: 42px;
`;
export const BotChaTxtName = styled.p`
    color: #1a1a1a;
    font-size: 14px;
`;
export const BotChaLike = styled.span`
    position: absolute;
    right: 20px;
    top: 30px;
    font-size: 14px;
    color: #fe0166;

`;
export const BotChaTxtSub = styled.p`

`;
export const BotChaTxtSpan = styled.span`
    color: #757575;
    margin-right: 13px;
    font-size: 13px;
    position: relative;

    & + &:after {
        content: "|";
        position: absolute;
        left: -8px;
        top: -1px;
        font-size: 12px;
        color: #dbdbdb;
    }
`;
export const BotChaTxtStrong = styled.strong`
    color: #1a1a1a;
    margin-left: 4px;
    font-size: 13px;
`;
export const BotCate = styled.div`
    padding: 24px 20px 22px;
    border-bottom: 2px solid #f5f5f5;
    font-size: 13px;
    font-weight: 500;
`;
export const BotCateList = styled.p`
    span {
        font-weight: 500;
        font-size: 13px;
    }

    & + & {
        margin-top: 8px;
    }
`;
export const BotCateLeft = styled.span`
    width: 50px;
    margin-right: 40px;
    color: #757575;
`;
export const BotCateRight = styled.span`
    color: #1a1a1a;
`;
export const BotKeyUl = styled.ul`
    margin: 22px 0 -8px;
`;
export const BotKeyLi = styled.li`
    padding: 2px 6px;
    border: 1px solid #424242;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    margin: 0 8px 8px 0;
`;
export const PollTxt = styled.li`
    padding: 24px 20px;
    font-size: 14px;
    color: #5c5c5c;
    white-space: break-spaces;
`;

export const PollTxtarea = styled.textarea`
    font-size: 14px;
    color: #5c5c5c;
    border: 0;
    resize: none;
    width: 100%;
    height: 140px;
`;

export const PollBtn = styled.div`
    //position: fixed;
    //bottom: 0;
    padding: 14px 0 16px;
    text-align: center;
    width: 100%;
    border-top: 2px solid #f5f5f5;
    background: #fff;
    height: 52px;
    box-sizing: border-box;
`;


// 체크박스 팝업
export const PollQbox = styled.div`
    margin: 0 20px;
`;
export const PollQTitle = styled.p`
    margin: 28px 0 20px;
    font-size: 20px;
    font-weight: 700;
`;
export const PollQNoti = styled.p`
    margin: 20px 0 40px;
    font-size: 13px;
    color: #757575;
`;
export const PollCheckBox = styled.div`
    margin: 40px auto 0;
    overflow: auto;
    box-sizing: border-box;
    height: 509px;
    padding-bottom: 60px;

    ::-webkit-scrollbar {
        width: 0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }


`;
export const PollCheckUl = styled.ul`

`;
export const PollCheckLi = styled.li`
    padding: 16px 0 16px 2px;
    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;

    ${(props) =>
            props.square &&
            css`
                border-bottom: 0;
                padding: 0;
                float: left;
                border-radius: 12px;
                display: inline-block;
                height: 226px;
                width: 48.5%;
                margin: 10px 0 35px 0;
                position: relative;

                &:nth-child(2n) {
                    margin-left: 3%;
                }

            `}
`;

export const PollCheckLabel = styled.label`
    cursor: pointer;

    ${(props) =>
            props.square &&
            css`
                //padding: 0 12px;
                width: 100%;
                height: 100%;
            `}
`;

export const PollCheckImg = styled.div`

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;


    img {
        width: 100%;
        height: 100%;
        border-radius: 20px;

    }
`;

export const PollCheckTxt = styled.span`
    font-size: 14px;
    display: inline-block;
    width: 278px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 5px;
    ${(props) =>
            props.square &&
            css`
                white-space: normal;
                width: 100%;
                font-weight: 700;
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                color: #1A1A1A;
                margin-left: 0;
                position: absolute;
                bottom: -35px;
                z-index: 5;
            `}
`;
export const PollCheckInput = styled.input`
    &[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: url(${CheckButtonSmall});
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    &:checked {
        background: url(${CheckButtonSmallOn});
    }

    ${(props) =>
            props.square &&
            css`
                &[type="checkbox"] {
                    z-index: 2;
                    width: 72px;
                    height: 72px;
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: url(${CircleCheck}) no-repeat center;
                    background-size: cover;
                }

                &:checked {
                    background: url(${CircleCheckOn}) center center no-repeat;
                    background-size: cover;
                }

            `}

}
`;
export const PollCheckBtn = styled.div`
    position: fixed;
    bottom: 20px;
    padding: 9px 0 11px;
    text-align: center;
    width: 320px;
    background: #0000fe;
    height: 42px;
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    z-index: 5;
`;


export const MonthPrev = styled.a`
    position: relative;
    color: transparent;

    &:before {
        content: "";
        background-image: url(${KeyLeft});
        width: 24px;
        height: 24px;
        display: inline-block;
        position: absolute;
        left: 5px;
        top: 0;
    }
`;

export const MonthNext = styled.a`
    position: relative;
    color: transparent;

    &:before {
        content: "";
        background-image: url(${KeyRight});
        width: 24px;
        height: 24px;
        display: inline-block;
        position: absolute;
        right: 5px;
        top: 0;
    }
`;

export const MonthTxt = styled.span`
    color: #424242;
    font-size: 14px;
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const SelectBox = styled.span`
    position: absolute;
    top: -36px;
    right: 0;
`;

export const Select = styled.select`
    width: ${(props) => props.width || "280px"};
    border-radius: 8px;
    height: 36px;
    background-color: #f5f5f5;
    padding: 0 8px;
    box-sizing: border-box;
    cursor: pointer;
    ${(props) =>
            props.min &&
            css`
                width: 170px;
                font-size: 14px;
                border-color: #dbdbdb;
            `}
    ${(props) =>
            props.mid &&
            css`
                width: 120px;
            `}
    ${(props) =>
            props.maxmin &&
            css`
                width: 64px;
                height: 30px;
            `}
    ${(props) =>
            props.big &&
            css`
                width: 608px;
            `}
`;

export const Option = styled.option``;

export const RadioBox = styled.label`
    position: relative;

    input {
        border: 0;
    }

    & + label {
        margin-left: 26px;
    }
`;

export const CheckBox = styled.label`
    position: relative;

    & + & {
        margin-left: 26px;
    }
`;

export const GrayButton = styled.button`
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: ${props => props.margin};
    color: ${(props) => (props.disabled ? "#8f8f8f" : "#1A1A1A")};
    background: ${(props) => (props.disabled ? "#c2c2c2" : "#dbdbdb")};
    cursor: ${(props) => (props.disabled && "not-allowed")};
    border-radius: 8px;
    padding: 11px 8px;
    min-width: 180px;
    height: 48px;
    box-sizing: border-box;

    ${(props) =>
            props.short &&
            css`
                width: 100px;
                height: 36px;
                font-size: 14px;
                padding: 6px;
                min-width: 100px;
            `}
`;

export const ButtonRight = styled.div`

    button + button {
        margin-left: 20px;
    }

    text-align: right;
    padding-top: ${(props) => props.paddingtop || "48px"};

    ${(props) =>
            props.mini &&
            css`
                padding-top: 0;
            `}

    ${(props) =>
            props.big &&
            css`
                padding-top: 64px;
            `}
    ${(props) =>
            props.bigdata &&
            css`
                padding: 37px 0 53px;
            `}
`;

export const LabelName = styled.span`
    margin-left: 38px;
    font-size: 14px;
    min-width: 100px;
    display: inline-block;
`;

export const ListSpan = styled.span`
    position: relative;

    & + & {
        margin-left: 21px;
    }

    & + &::before {
        content: "-";
        position: absolute;
        left: -14px;
        top: 0;
    }
`;

export const ListSelectTxt = styled.span`
    font-size: 14px;
    margin: 0 8px 0 24px;
`;

export const ListFileBox = styled.span`
    position: relative;
`;

export const ListFileLabel = styled.label`
    display: inline-block;
    padding: 8px;
    vertical-align: middle;
    background-color: #dbdbdb;
    cursor: pointer;
    width: 100px;
    height: 36px;
    margin-left: 8px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
`;

export const ListInput = styled.input`
    &[type="text"] {
        border: 1px solid #757575;
        padding: 8px;
        border-radius: 8px;
        height: 36px;
        box-sizing: border-box;
        width: ${(props) => props.width || "280px"};
        padding-right: ${(props) => props.paddingRight || "55px"};


        ${(props) =>
                props.long &&
                css`
                    width: 608px;
                `}

        ${(props) =>
                props.mid &&
                css`
                    width: 390px;
                `}

        ${(props) =>
                props.short &&
                css`
                    width: 260px;
                    font-size: 14px;
                `}

        ${(props) =>
                props.item &&
                css`
                    width: 478px;
                    font-size: 14px;
                `}

        ${(props) =>
                props.wide &&
                css`
                    width: 100%;
                    font-size: 14px;
                `}

        ${(props) =>
                props.Rmax &&
                css`
                    width: 1016px;
                    font-size: 14px;
                    margin-left: 24px;
                `}
    }

    &[type="radio"] {
        width: 20px;
        height: 20px;
        position: absolute !important;
        top: 3px;
        cursor: pointer;
        background: url(${RadioButton});

        &:checked {
            background: url(${RadioButtonActive});
        }

        -webkit-appearance: none;
        -moz-appearance: none;
    }

    &[type="checkbox"] {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 2px;
        cursor: pointer;
        background: url(${CheckButton});

        &:checked {
            background: url(${CheckButtonActive});
        }

        -webkit-appearance: none;
        -moz-appearance: none;
    }

    &[type="number"] {
        position: relative;
        border: 1px solid #757575;
        padding: 8px;
        width: ${(props) => props.width || "80px"};
        height: 36px;
        border-radius: 8px;
        box-sizing: border-box;

        ${(props) =>
                props.two &&
                css`
                    width: 130px;
                `}
        ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &[type="datetime-local"] {
        height: 24px;
        font-size: 14px;
    }

    &[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }

    &[type="email"] {
        border: 1px solid #757575;
        padding: 8px;
        border-radius: 8px;
        height: 36px;
        box-sizing: border-box;
        width: ${(props) => props.width || "280px"};
    }

    ${(props) =>
            props.file &&
            css`
                display: inline-block;
                width: 500px;
                height: 36px;
                padding: 10px 28px 10px 8px;
                vertical-align: middle;
                border: 1px solid #757575;
                background-color: #f5f5f5;
                border-radius: 8px;
                font-size: 10px;
            `}
`;

/*
 &[type="number"] {
 border: 1px solid #757575;
 padding: 8px;
 width: 80px;
 border-radius: 8px;
 & + & {
 margin-left: 24px;
 }
 }
 */
//마이페이지,사용자관리
export const MenuArea = styled.div`
    width: 100%;
    height: auto;
`;
export const MenuInfoArea = styled.div`
    width: 1308px;
    min-height: 100%;
    background-color: #fff;
    margin: 0 auto;
    color: #1a1a1a;
    padding: 48px 64px 80px 64px;
    box-sizing: border-box;

    ${(props) =>
            props.gray &&
            css`
                background-color: #F5F5F5;
            `}

    ${(props) =>
            props.bottomLine &&
            css`
                border-bottom: 16px solid #F5F5F5;
            `}
`;
export const Title = styled.h1`
    position: relative;
    font-size: 36px;
    font-weight: 500;
    padding: ${(props) => props.padding || "0 0 12px 0"};
    margin-bottom: 24px;
    border-bottom: 4px solid #424242;

    ${(props) =>
            props.btn &&
            css`
                padding: 0 0 28px 0;
                position: relative;
            `}
`;

export const ChannelPollTitle = styled(Title)`

    button {
        position: absolute;
        top: 0;
        right: 0;
    }

`


export const SubTitle = styled.p`
    margin: 48px 0;
    font-size: 24px;
    font-weight: 500;

    span {
        font-size: 14px;
        margin-left: 24px;
    }
`;

export const SubTitleSpan = styled.span`
    font-size: 14px;
    margin-left: 24px;
`;

export const TitleNotice = styled.p`
    padding-top: 12px;
    color: #e92928;
    font-size: 10px;
    line-height: 14px;
`;

export const BackBtn = styled.a`
    content: "";
    width: 48px;
    height: 48px;
    background-image: url(${BackIcon});
    background-size: cover;
    position: absolute;
    top: 0;
    left: -56px;
    display: inline-block;
`;

export const Sub = styled.p`
    font-size: 10px;
    padding-top: 12px;
    color: #757575;
`;

export const List = styled.div`
    width: ${(props) => props.width || "590px"};
    margin: ${(props) => props.margin || "0 0 0 10px"};

    ${(props) =>
            props.channel &&
            css`
                margin: 0 0 0 100px;
            `}

    ${(props) =>
            props.zero &&
            css`
                margin: 0;
            `}

    ${(props) =>
            props.wide &&
            css`
                width: 100%;
                margin: 0;

                &:last-child {
                    padding-bottom: 0 !important;
                }
            `}

    ${(props) =>
            props.cate &&
            css`
                margin: -20px 0 0 10px;
            `}
`;

export const ListUl = styled.ul`
    ${(props) =>
            props.flex &&
            css`
                display: flex;
            `}
`;

export const ListLi = styled.li`

    & + & {
        margin-top: 48px;
    }

    ${(props) =>
            props.disabled &&
            css`
                color: #757575;
            `}
`;

export const ListLiHori = styled.li`
    width: 50%;
    //flex: auto;
    display: flex;
    align-items: center;
`;

export const ListLtxt = styled.span`
    width: ${(props) => props.width || "140px"};

    display: inline-block;
    font-weight: 500;

    input {
        :disabled {
            background-color: #f5f5f5;
            color: #757575;
        }

        ::placeholder {
            color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
        }
    }

    ${(props) =>
            props.top &&
            css`
                vertical-align: top;
            `}

    ${(props) =>
            props.what &&
            css`
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 36px;
                    width: 24px;
                    height: 24px;
                    background-image: url(${InfoGray});
                }
            `}
`;
export const ListRtxt = styled.div`
    margin-left: 24px;
    display: inline-block;
    position: relative;
    max-width: 1016px;

    ${(props) =>
            props.line &&
            css`
                text-decoration: underline;
            `}

    ${(props) =>
            props.max &&
            css`
                max-width: 1016px;
                width: 100%;
            `}

    ${(props) =>
            props.Max &&
            css`
                max-width: 1016px;
                width: 100%;
            `}

    ${(props) =>
            props.min &&
            css`
                span + span {
                    margin-left: 48px !important;
                }
            `}

    ${(props) =>
            props.Min &&
            css`
                span + span {
                    margin-left: 48px !important;
                }
            `}

    ${(props) =>
            props.topShow &&
            css`
                margin-left: 27px;
            `}

    ${(props) =>
            props.Notice &&
            css`
                margin-left: 16px;
                color: #757575;
            `}
`;

export const ListRTextArea = styled.textarea`
    width: 608px;
    height: 96px;
    border-radius: 8px;
    border-color: #757575 !important;
    resize: none;
    padding: 8px;
    color: #1a1a1a;
    outline: none;

    ${(props) =>
            props.max &&
            css`
                width: 1016px;
                height: 316px;
            `}

    ${(props) =>
            props.Max &&
            css`
                width: 1016px;
                height: 316px;
            `}

    ${(props) =>
            props.Rmax &&
            css`
                margin-left: 24px;
                width: 1016px;
                height: 316px;
            `}

    ${(props) =>
            props.mid &&
            css`
                margin-left: 24px;
                width: 616px;
            `}
`;

export const ListRlink = styled.a`
    cursor: pointer;
    text-decoration: underline;
    color: #1a1a1a;
    font-weight: 700;
`;

export const ListRspan = styled.span`
    & + & {
        margin-left: 16px;
    }

    ${(props) =>
            props.text &&
            css`
                display: inline-block;
                min-width: 100px;
            `}
    ${(props) =>
            props.noti &&
            css`
                font-size: 10px;
                color: #757575;
                margin-left: 8px;
            `}
    ${(props) =>
            props.rel &&
            css`
                position: relative;
            `}
    ${(props) =>
            props.Rel &&
            css`
                position: relative;
            `}
    ${(props) =>
            props.date &&
            css`
                float: left;
                height: 24px;
            `}
    ${(props) =>
            props.block &&
            css`
                display: block;
                margin-top: 12px;
            `}
    ${(props) =>
            props.disabled &&
            css`
                color: #757575;
            `}
    & + button {
        margin-left: 48px;
    }
`;

export const ListRspanImg = styled.span`
    width: 100px;
    position: relative;
    display: inline-block;

    & + & {
        margin-left: 24px;
    }
`;

export const ListRstrong = styled.strong`
    display: block;
    margin-bottom: 24px;
`;

export const ListThumb = styled.span`
    width: 100px;
    height: 100px;
    display: inline-block;
    background-size: cover;
    ${(props) =>
            props.main &&
            css`
                position: relative;
                display: inline-block;
                width: 100px;
                height: 100px;

                &::before {
                    content: "";
                    position: absolute;
                    border: 2px solid #000065;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &::after {
                    content: "대표";
                    position: absolute;
                    font-size: 10px;
                    padding: 0 4px;
                    background-color: #000065;
                    color: #fff;
                    width: 27px;
                    left: 0;
                    top: 0;
                    height: 18px;
                    box-sizing: border-box;
                }
            `}
`;

export const ListThumbName = styled.span`
    font-size: 14px;
    color: #757575;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
    display: block;
    ${(props) =>
            props.word &&
            css`
                width: auto;
            `}
`;

export const ListThumbX = styled.button`
    background-image: url(${TextDelIcon});
    width: 16px;
    height: 16px;
    position: absolute;
    right: -20px;
    bottom: 3px;
    background-position: 0 1px;
    font-size: 0;
    top: 50%;
    transform: translateY(-50%);
    ${(props) =>
            props.in &&
            css`
                right: 8px;
            `}
`;

export const SubjectListBox = styled.div`
    display: grid;
    margin: 48px 0 0;

    ${(props) =>
            props.input &&
            css`
                margin: 24px 0;
            `}
    ${(props) =>
            props.no &&
            css`
                margin: 0;
            `}
`;

export const SubjectList = styled.div`
    display: block;

    & + & {
        margin-top: 24px;
    }

    ${(props) =>
            props.input &&
            css`
                margin-left: 17px;
                position: relative;

                &::before {
                    content: "";
                    background-image: url(${DragIcon});
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    top: 6px;
                    left: -24px;
                    background-size: cover;
                }
            `}

    ${(props) =>
            props.img &&
            css`
                &::before {
                    top: 0;
                }
            `}

    ${(props) =>
            props.poll &&
            css`
                width: 792px;
                padding: 10px;
                box-sizing: border-box;
            `}
`;

export const SubjectListSpan = styled.span`
    vertical-align: top;
    max-width: 830px;
    overflow: hidden;
    display: inline-block;

    & + & {
        margin-left: 16px;
    }

    ${(props) =>
            props.head &&
            css`
                font-weight: 500;
            `}

    ${(props) =>
            props.img &&
            css`
                width: 80px;
                height: 80px;
                max-width: none;
                max-height: 80px;
                position: relative;
                display: inline-block;

                img {
                    width: 100%;
                    height: 100%;
                }
            `}

    ${(props) =>
            props.bigimg &&
            css`
                width: 140px;
                height: 140px;
                max-width: none;
                max-height: 140px;
                position: relative;
                display: inline-block;
                float: left;
                background-color: #f5f5f5;

                img {
                    width: 100%;
                    height: 100%;
                }
            `}

    ${(props) =>
            props.input &&
            css`
                input {
                    magin-left: 24px;
                }

                input + input {
                    margin-top: 8px;
                }
            `}

    ${(props) =>
            props.file &&
            css`
                margin-top: 8px;
            `}
    ${(props) =>
            props.rel &&
            css`
                position: relative;
            `}
`;

export const SubjectListBlock = styled.div`
    display: block;
    font-weight: 500;

    & + & {
        margin-top: 24px;
    }

    ${(props) =>
            props.short &&
            css`
                margin-top: 15px !important;
            `}

    ${(props) =>
            props.rel &&
            css`
                position: relative;
            `}

    ${(props) =>
            props.Rel &&
            css`
                position: relative;
            `}

    ${(props) =>
            props.img &&
            css`
                //margin: 20px 0 0 10px;
                margin-left: 10px;
                display: inline-block;
                //height: 120px;
                width: 1016px;
            `}
    ${(props) =>
            props.imgnew &&
            css`
                margin: 10px 0 0 10px;
                display: inline-block;
                height: 120px;
                width: 1016px;
            `}


    ${(props) =>
            props.pollDetailImg &&
            css`
                //margin: 20px 0 0 10px;
                margin-left: 5px;
                display: inline-block;
                height: 120px;
                width: 631px;
            `}

    ${(props) =>
            props.poll &&
            css`
                position: relative;
                display: inline-block;
                width: 632px;
                height: 140px;
                padding: 48px 124px 0 24px;
                box-sizing: border-box;
            `}
`;

export const SubjectListInputBox = styled.div`
    display: inline-block;
    width: 632px;

    input {
        margin-left: 24px;
    }

    input + input {
        margin-top: 8px;
    }
`;

export const SubjectListDel = styled.a`
    content: "";
    background-image: url(${DelIconBig});
    background-size: cover;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -28px;
    font-size: 0;

    ${(props) =>
            props.input &&
            css`
                right: -40px;
            `}
`;


export const ListRtxtSubText = styled.div`
    font-weight: 500;

    & + & {
        margin-left: 168px;
    }

    ${(props) =>
            props.right &&
            css`
                position: absolute;
                right: 20px;
                top: -6px;
            `}
`;

export const ListRtxtSubNum = styled.span`
    font-size: 14px;
    margin: 0 8px 0 24px;
    font-weight: 400;
    color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};

    ${(props) =>
            props.berry &&
            css`
                font-weight: 500;
                margin: 0;
            `}

    ${(props) =>
            props.In &&
            css`
                position: absolute;
                top: 0;
                right: 8px;
            `}

    ${(props) =>
            props.pollReward &&
            css`
                font-size: 16px;
                margin: 0 8px 0 16px;
            `}
`;

//파일 커스텀
export const ListInputFile = styled.input`
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
`;

//페이징
export const PageBox = styled.div`
    margin: 48px auto 0;
    text-align: center;

    ${(props) =>
            props.modalList &&
            css`
                margin-top: 24px;
            `}
`;

export const Paging = styled.button`
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: #1a1a1a;
    display: inline-block;
    vertical-align: top;

    & + & {
        margin-left: 16px;
    }

    ${(props) =>
            props.active &&
            css`
                color: #fff;
                background-color: #000065;
                border-radius: 4px;
            `}

    ${(props) =>
            props.icon &&
            css`
                font-size: 0;
            `}

    ${(props) =>
            props.firstpage &&
            css`
                background-image: url(${FirstArrowIcon});
            `}

    ${(props) =>
            props.prevpage &&
            css`
                background-image: url(${LeftArrowIcon});
            `}

    ${(props) =>
            props.nextpage &&
            css`
                background-image: url(${RightArrowIcon});
            `}

    ${(props) =>
            props.lastpage &&
            css`
                background-image: url(${LastArrowIcon});
            `}
`;

export const PollSettingContainer = styled.div`
    padding: 48px 64px 80px;
    max-width: 1308px;
    width: 100%;
    background-color: #ffff;
    margin: 0 auto;
`;

export const PollSettingTitleContainer = styled.div`
    padding-bottom: 12px;
    border-bottom: 4px solid black;
    margin-bottom: 48px;
    position: relative;
    width: ${(props) => props.width};

    span {
        font-size: 36px;
        font-weight: 500;
        line-height: 52px;
        color: #1a1a1a;
    }

    ${(props) =>
            props.Subheading &&
            css`
                width: 140px;
                border-bottom: none;
                padding-bottom: 0;
                margin: 0 24px 0 0;

                span {
                    font-size: 24px;
                    line-height: 24px;
                }
            `}
`;

export const PollSettingMainContainer = styled.div`
    display: flex;
    width: 100%;

    form {
        width: 100%;
    }
`;

export const PollSettingContentContainer = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
`;

export const PollSettingContentSection = styled.div`
    // padding-right: 8px;
    //
        // overflow-y: ${(props) => props.scroll && "scroll"};
    // width: 100%;
    //
    // ::-webkit-scrollbar {
    //     width: 10px;
    // }
    //
    // ::-webkit-scrollbar-thumb {
    //     background-color: #a8a8a8;
    //     border-radius: 10px;
    // }
    //
    // ::-webkit-scrollbar-track {
    //     background-color: #f5f5f5;
    //     border-radius: 10px;
    // }
`;

export const PollSettingContentWrapper = styled.div`
    display: flex;
    padding: ${(props) => (props.Padding ? "0 0 48px 0" : `48px 0 48px 0`)};
    width: 100%;

    :first-child {
        padding-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    ${(props) =>
            props.Border &&
            css`
                border-bottom: 2px solid #dbdbdb;
            `};

    ${(props) =>
            props.Select &&
            css`
                padding: 0 0 36px 0;
            `};

    ${(props) =>
            props.BorderTop &&
            css`
                border-top: 2px solid #dbdbdb;
            `};
`;

export const PollSettingContentTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    max-width: ${(props) => props.maxWidth || "126px"};
    margin: ${(props) => props.margin || "0 24px 0 0"};
    width: 100%;
    ${(props) =>
            props.Subheading &&
            css`
                font-size: 24px;
                line-height: 24px;
            `};

    ${(props) =>
            props.disabled &&
            css`
                color: #757575;
            `};
`;

export const TitleInputWrapper = styled.div`
    height: ${(props) => props.height};
    width: ${(props) => props.width || "100%"};
    //width: 100%;
    display: flex;
    flex-flow: column;
    //max-width: 608px;

    input {
        background-color: #ffffff;
        border: 1px solid #757575;
        border-radius: 8px;
        padding: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #1a1a1a;
        // width: 608px;
        width: 100%;
    }

    span {
        height: 17px;
        color: #757575;
        margin-left: auto;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
    }
`;

// 이미지 파일 업로드
export const AddFileWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "space-between"};

    input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }

    p {
        width: ${(props) => props.width || "100%"};
        max-width: 500px;
        font-size: 10px;
        font-weight: 400;
        color: #757575;
        padding: 10px 8px;
        background-color: #f5f5f5;
        border: 1px solid #757575;
        border-radius: 8px;
        height: 36px;
    }

    label {
        padding: 8px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        background-color: #dbdbdb;
        border-radius: 8px;
        width: 100px;
        margin: ${(props) => props.margin || "0 0 0 8px"};
        color: #1a1a1a;
        line-height: 20px;
        cursor: pointer;
    }

    span {
        font-size: 10px;
        color: #757575;
    }
`;

export const ItemTextInputWrapper = styled.div`
    padding: 8px;
    border: 1px solid #757575;
    border-radius: 8px;
    margin-bottom: 8px;

    input {
        width: 100%;
    }
`;

export const InputRadioWrapper = styled.div`
    & + & {
        margin-left: 24px;
    }

    input {
        margin-right: 18px;
    }
`;

export const RewardAmountWrapper = styled.div`
    width: 180px;
    position: relative;

    input {
        :disabled {
            background-color: #f5f5f5;
            color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
        }

        ::placeholder {
            color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
        }
    }

    span {
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        position: absolute;
        color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
        font-weight: 500;
    }

    p {
        color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    width: ${(props) => props.width || "100%"};
    max-width: ${(props) => props.maxWidth};
    max-height: ${(props) => props.maxHeight};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
    background-color: ${(props) => props.backgroundColor};
    gap: ${(props) => props.gap};

    ${(props) =>
            props.Column &&
            css`
                flex-flow: column;
            `}

    ${(props) =>
            props.Border &&
            css`
                border: 1px solid #dbdbdb;
                border-top: 2px solid #000065;
            `}

    ${(props) =>
            props.Center &&
            css`
                align-items: center;
            `}

    ${(props) =>
            props.Right &&
            css`
                justify-content: right;
            `}

    ${(props) =>
            props.Header &&
            css`
                span {
                    font-size: 18px;
                }

                margin: 0 0 15px 0;
            `}

    ${(props) =>
            props.MarginTop &&
            css`
                margin-top: 48px;
            `}

    ${(props) =>
            props.Row &&
            css`
                justify-content: space-between;
            `}

    ${(props) =>
            props.Form &&
            css`
                form {
                }
            `}

    ${(props) =>
            props.Radio &&
            css`
                input {
                    margin-right: 19px;
                }
            `}

    ${(props) =>
            props.Scroll &&
            css`
                overflow-y: scroll;

                ::-webkit-scrollbar {
                    width: 10px;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: #a8a8a8;
                    border-radius: 10px;
                }

                ::-webkit-scrollbar-track {
                    background-color: #f5f5f5;
                    border-radius: 10px;
                }
            `}
`;

export const NoticeWrapper = styled.div`
    display: flex;
    flex-flow: column;
    margin: ${props => props.margin};
    width: ${props => props.width};

    span {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #757575;
        //margin-top: 4px;
    }

    ${(props) =>
            props.Recommend &&
            css`
                justify-content: center;

                span {
                    margin-top: auto;
                    font-size: 14px;
                }
            `}

    ${(props) =>
            props.TextCount &&
            css`
                flex-flow: row;
                justify-content: right;
            `}
    ${(props) =>
            props.TextCountNew &&
            css`
                display: block;
                text-align: right;
                margin-right: 45px;
            `}

    ${(props) =>
            props.CategoryCharacterLimit &&
            css`
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
            `}
`;


export const NoticeSub = styled.p`
    font-size: 14px;
    color: #1a1a1a;

`;
// 회색 바
export const LineBar = styled.div`
    height: 16px;
    background-color: #F5F5F5;
    margin: 64px -64px -16px;

    ${(props) =>
            props.zero &&
            css`
                margin: 64px -64px 0;
            `}
`;

export const Click = styled.a`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    color: #1a1a1a;
`;

export const Thumbnail = styled.div`

    border: 1px solid #757575;
    background-color: ${props => props.profileImageSrc ? '#fff' : '#f5f5f5'};
    display: inline-block;
    float: left;
    width: 140px;
    height: 140px;

    ${props => props.Round && css`
        border-radius: 50%;
    `};

    img {
        height: 100%;
        width: 100%;
        border-radius: ${(props) => props.Round ? '50%' : '0'};
    }

`;

export const PlatformResultWrapper = styled.div`
    display: flex;

    & + & {
        margin-top: 24px;
    }

`;

export const ResultContentWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: space-between;
    //padding-top: 52px;
`;

export const ResultImage = styled.div`
    margin-right: 24px;

    img {
        height: 140px;
        width: 140px;
    }
`;

export const ResultContent = styled.div`
    ${props => props.Title && css`
        font-weight: 500;
        margin-bottom: 24px;
        margin-top: auto;
    `};


    ${props => props.Description && css`
        //position: relative;
        display: flex;
        justify-content: space-between;
    `};
`;

export const PlatformResultBarBox = styled.div`
    width: 100%;
    max-width: 484px;
    height: 40px;
    background-color: #dbdbdb;
    display: inline-block;
    margin-top: auto;
`;

export const PlatformResultBarNumber = styled.div`
    display: flex;
    flex-flow: column;
`;


export const SimpleWrapper = styled.div`
    display: flex;
    justify-content: right;
    margin-top: 48px;
    //padding-top: 63px;
`;


//파일삭제아이콘

export const DelFile = styled.a`
    position: absolute;
    right: 10px;
    cursor: pointer;
    background-image: url(${TextDelIcon});
    width: 16px;
    height: 16px;
    background-size: cover;
`;

// 채널 설문 상세보기 썸네일 이미지
export const ChannelDetailThumbnailImageWrapper = styled.div`
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;

    & + & {
        margin-left: 24px;
    }

    ${(props) =>
            props.main &&
            css`
                position: relative;
                display: inline-block;
                //width: 104px;
                //height: 104px;

                &::before {
                    content: "";
                    position: absolute;
                    border: 2px solid #000065;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &::after {
                    content: "대표";
                    position: absolute;
                    font-size: 10px;
                    padding: 0 4px;
                    background-color: #000065;
                    color: #fff;
                    width: 27px;
                    left: 0;
                    top: 0;
                    height: 18px;
                    box-sizing: border-box;
                }
            `}
`;

export const ChannelDetailThumbnailImage = styled.div`
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }

`;

export const StyledModal = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.35);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
`;

export const ModalImageWrapper = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    cursor: pointer;
    z-index: 10;

    img {
        width: 100%;
        height: 100%;
    }
`;

export const ModalContentWrapper = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    align-items: center;
    position: relative;
`;

export const StyledDatePicker = styled(DatePicker)`
    font-size: 14px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    color: #333;
    caret-color: transparent;
    text-align: center;

    ::placeholder {
        text-align: center;
    }

    ${(props) =>
            props.Start &&
            css`
                width: 100px;
                margin-right: 24px;
                text-align: center;
            `}

    ${(props) =>
            props.End &&
            css`
                width: 100px;
                margin-left: 24px;
                text-align: center;
            `}

    ${(props) =>
            props.Open &&
            css`
                //width: 200px;
                margin-right: 24px;
            `}

    ${(props) =>
            props.Close &&
            css`
                //width: 100px;
                margin-left: 24px;
            `}

    ${(props) =>
            props.pollStart &&
            css`
                width: 200px;
                margin-right: 24px;
                text-align: center;
            `}

    ${(props) =>
            props.pollEnd &&
            css`
                width: 200px;
                //width: 100px;
                //margin-right: 24px;
                text-align: center;
            `}
`;

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={Indicator} alt="Dropdown arrow"/>
      </components.DropdownIndicator>
    )
  );
};

export function CustomHeader({date, decreaseMonth, increaseMonth}) {
  const dateFormat = "yyyy년 MM월";

  return (
    <div className="header">
      <div className="title">
        <button type='button' onClick={decreaseMonth}>{'<'}</button>
        <span style={{margin: '0 20px'}}>{format(date, dateFormat)}</span>
        <button type='button' onClick={increaseMonth}>{'>'}</button>
      </div>
    </div>
  );
}

export const ModalContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;

    ${props => props.Reward && css`
        padding: 24px 45px;
        max-width: 593px;
        max-height: 860px;
        background-color: #FFFFFF;
    `}

    ${props => props.Inspection && css`
        padding: 15px 48px 24px;
        max-width: 480px;
        //max-height: 296px;
        max-height: ${props => props.checkPass ? '688px' : '296px'};
        background-color: #FFFFFF;
        justify-content: space-between;
    `}

    ${props => props.stop && css`
        padding: 15px 48px 24px;
        max-height: 634px;
        max-width: 480px;
        //max-height: 296px;
        background-color: #FFFFFF;
        justify-content: space-between;
    `}

    ${props => props.notice && css`
        padding: 17px 48px 24px;
        border-radius: 20px;
        max-width: 480px;
        max-height: 252px;
        line-height: 175%;
        background-color: #FFFFFF;
        justify-content: space-between;
    `}

    ${props => props.update && css`
        padding: 20px 48px 24px;
        max-width: 480px;
        max-height: 296px;
        background-color: #FFFFFF;
        justify-content: space-between;
        border-radius: 20px;
    `}

    ${props => props.return && css`
        padding: 20px 48px 24px;
        max-width: 480px;
        max-height: 704px;
        background-color: #FFFFFF;
        justify-content: space-between;
        border-radius: 20px;
    `}

    ${props => props.Confirm && css`
        max-width: 496px;
    `}

    ${props => props.Report && css`
        padding: 20px 48px 24px;
        max-width: 480px;
        max-height: 634px;
        background-color: #FFFFFF;
        border-radius: 20px;
    `}

    ${props => props.UserStop && css`
        padding: 20px 48px 24px;
        max-width: 480px;
        max-height: 795px;
        background-color: #FFFFFF;
        border-radius: 20px;
    `}
    ${props => props.Cancel && css`
        padding: 15px 48px 24px;
        max-width: 480px;
        max-height: 738px;
        background-color: #FFFFFF;
        border-radius: 20px;
    `}

    ${props => props.Participation && css`
        max-width: 478px;
    `}

`;

export const RewardDetailTitle = styled.span`
    font-size: 18px;
    font-weight: 700;

    ${props => props.Inspection && css`
        margin-bottom: 43px;
    `};

    ${props => props.notice && css`
        margin-bottom: 44px;
    `}

    ${props => props.Center && css`
        text-align: center;
    `}

`;

export const RewardDetailContent = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    span {
        :first-child {
            margin-bottom: ${props => props.checkPass ? '48px' : '16px'};

            ${props => props.Confirm && css`
                margin-bottom: 0;
                line-height: 175%;
            `};

            ${props => props.Report && css`
                margin-bottom: 0;
            `};
        }
    }

    ${props => props.Inspection && css`
        margin-top: 48px;
    `};

    ${props => props.return && css`
        height: 100%;
        margin-top: 43px;
    `};

    ${props => props.Reward && css`
        //min-height: 661px;
    `};

    ${props => props.Confirm && css`
        margin-top: 41px;
        text-align: center;
    `};

    ${props => props.Report && css`
        margin-top: 41px;
        display: grid;
        grid-row: auto;
        grid-template-columns: 0.4fr 1fr;
        grid-gap: 16px 24px;
    `};

    ${props => props.Cancel && css`
        margin-top: 41px;
        display: grid;
        grid-row: auto;
        grid-template-columns: 0.4fr 1fr;
        grid-gap: 16px 24px;
    `};
`;

export const NoticeDetailContent = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

export const InspectionReasonSpan = styled.textarea`
    width: 384px;
    height: 284px;
    resize: none;
    color: #757575;
    border: 1px solid #000065;
    border-radius: 8px;
    padding: 16px;
    margin: ${(props) => props.margin};

`;

export const RemoveButton = styled.button`
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
`

export const ItemRemoveButton = styled(RemoveButton)`

    ${props => props.URL && css`
        right: -48px;
    `};

    ${props => props.TEXT && css`
        right: -30px;
    `};
`

export const StyledTextArea = styled.textarea`
    font-size: 14px;
    color: #5c5c5c;
    resize: none;
    width: 384px;
    height: 284px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #000065;

`;

export const ModalDetailContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
`;

export const ModalText = styled.span`
    width: ${(props) => props.width};
    margin: ${(props) => props.margin};

    ${props => props.lengthLimit && css`
        color: #757575;
        font-size: 12px;
    `}

    ${props => props.TextCenter && css`
        text-align: center;
    `}

    ${props => props.TextRight && css`
        text-align: right;
    `}

`;

export const ModalSpan = styled.span`
    margin: ${(props) => props.margin};
    width: ${(props) => props.width};

`

export const ReportReasonSpan = styled.span`
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    background: #FFF;
    padding: 16px;
    width: 100%;
    max-width: 464px;
    height: 228px;
    overflow-y: auto;

    ${props => props.Cancel && css`
        height: 100%;
    `}
`

export const UserPollStopReasonTextArea = styled.textarea`
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    background: #FFF;
    padding: 16px;
    width: 100%;
    max-width: 384px;
    height: 312px;
    resize: none;
    line-height: 175%;
`

export const RewardRadioWrapper = styled.div`
    display: flex;

    input {
        margin-right: 16px;
    }
`;

export const CustomTdContent = styled.span`
    display: inline-block;
    color: #FCE1E1;
    background-color: #E92928;
    width: 53px;
    height: 20px;
    font-weight: 400;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    padding: 4px 15px;
`;

export const ExposureBadge = styled.span`
    //display: inline-block;
    color: #FFFFFF;
    background-color: #3189FA;
    width: 42px;
    height: 28px;
    font-weight: 500;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    line-height: 20.27px;
    //line-height: 12px;
    padding: 4px 8px;
`;

export const ReturnTdContent = styled.span`
    color: ${(props) => (props.Activate ? "#1A1A1A" : "#9B1110")};
    border-bottom: ${(props) => (props.Activate ? 'none' : "1px solid #9B1110")};
    //border-bottom: 1px solid #9B1110;
    cursor: ${(props) => (props.Activate ? "unset" : "pointer")};
`;

export const PreviewButton = styled(Button)`
    color: ${(props) => (props.disabled ? "#757575" : "#1a1a1a")};
    background-color: ${(props) => (props.disabled && "#8F8F8F")};
`;

export const CategoryProfileImage = styled.div`
    width: 50px;
    height: 50px;
    //border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`;

export const SurveyQuestionListWrapper = styled(Wrapper)`
    width: auto;

    & + & {
        margin-top: 24px;
    }

`;

export const SurveyImageItem = styled(Wrapper)`

    img {
        height: 100%;
        width: 100%;
    }

`;

export const ItemInputWrapper = styled(Wrapper)`
    position: relative;
    //padding-right: 55px;
`;

export const CenterWrapper = styled(Wrapper)`
    align-items: center;
    justify-content: center;
`;


export const UserProfileWrapper = styled(Wrapper)`
    width: auto;
    margin-right: 222px;

    span {
        font-weight: 500;
    }
`;

export const UserProfileImageWrapper = styled(Wrapper)`
    width: 200px;
    height: 200px;
    margin: 16px 0 0 38px;

    img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
`;

export const PointSelectButton = styled.button`
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #DBDBDB;
    color: #424242;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;

    & + & {
        margin-left: 8px;
    }
`;

export const PointListOpenButton = styled.button`
    color: #1A1A1A;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-decoration-line: underline;
`;


export const PointReturnInfoWrapper = styled(Wrapper)`
    span + span {
        margin-top: 16px;
        white-space: break-spaces;
    }
`;
