import React, {useEffect, useRef, useState} from "react";
import {ListInput, ListThumbName, ListThumbX} from "common/styledComponents/commonStyle";
import {
    KeywordLengthLimitNotice,
    KeywordWrapper,
    KeyWorldList,
    KeyWorldUl
} from "pages/ServeyManage/ChannelSurvey/channelSurveySytle"

const Keywords = ({onChange, initValue = [], withLength}) => {
    const inputRef = useRef(null);
    const [keywords, setKeywords] = useState([initValue]);
    const [keywordsLength, setKeywordsLength] = useState('');

    // const [keywords, setKeywords] = useState([]);

    useEffect(() => {
        setKeywords(initValue)
    }, [initValue]);

    const add = () => {

        if (keywords.length >= 5) {
            alert('등록 가능 키워드 초과');
            setKeywordsLength('');
            return;
        }

        const value = inputRef.current.value.trim();
        inputRef.current.value = '';

        if (value.length > 10) {
            alert('10자 이하로 작성해주세요.');
            setKeywordsLength('')
            return;
        }

        if (!value) {
            return;
        }

        // const exist = keywords.find(keyword => keyword === value);
        // if (exist) {
        //     return;
        // }

        setKeywords([
            ...keywords,
            {name: value}
        ]);

        setKeywordsLength('');

        onChange([
            ...keywords,
            {name: value}
        ]);
    }

    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     add();
    // }

    const handleKeyUp = (e) => {
        if (e.target.value.trim().length > 10) {
            alert('10자 이하로 작성해주세요.');
            setKeywordsLength(e.target.value.substr(0, 10))
            return
        }
        if (e.key === ' ') {
            add();
        }
    }

    const handleRemoveKeyword = (deleteIndex) => {
        // const {keyword} = e.target.dataset;
        setKeywords(keywords.filter((keyword, index) => {
            return index !== deleteIndex
        }));
    }

    const handelKeywordLength = (e) => {
        e.preventDefault();

        setKeywordsLength(e.target.value.trim())
    }

    return (
        <>
            {/*<form onSubmit={onSubmit}>*/}
            <KeywordWrapper>
                <ListInput long type="text" placeholder="" ref={inputRef} onKeyUp={(e) => handleKeyUp(e)}
                           onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                           onChange={handelKeywordLength} value={keywordsLength}/>
                {withLength &&
                    <KeywordLengthLimitNotice>{keywordsLength.length}/ 10자</KeywordLengthLimitNotice>
                }
            </KeywordWrapper>
            {/*</form>*/}
            <KeyWorldUl>
                {keywords && keywords.map((keyword, index) => {
                    return (
                        <KeyWorldList key={index}>
                            <ListThumbName word>{keyword.name}</ListThumbName>
                            <ListThumbX type='button'
                                        onClick={() => handleRemoveKeyword(index)}/>
                        </KeyWorldList>
                    )
                })}
            </KeyWorldUl>
        </>
    )
}

export default Keywords;
