import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import {STATUS} from "common/constants/user";
import DATE from "common/constants/date";

const CATEGORY_LIST_OPTIONS = {
    channel: {
        LIST_COLUMNS: [{
            key: 'id',
            title: 'NO.',
            width: '9%'
        }, {
            key: 'category',
            title: '구분',
            width: '10%'
        }, {
            key: 'name',
            title: '카테고리명',
            width: '*'
        }, {
            key: 'channelCount',
            title: '소속 채널수',
            width: '9%'
        }, {
            key: 'createdAt',
            title: '생성일',
            width: '9%'
        }, {
            key: 'status',
            title: '상태',
            width: '9%'
        }],
        iterator: (res) => {
            const {data} = res;
            return data && data.map((data) => ({
                ...data,
                category: '채널',
                status: STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
            }));
        }
    },
    poll: {
        LIST_COLUMNS: [{
            key: 'id',
            title: 'NO.',
            width: '9%'
        }, {
            key: 'category',
            title: '구분',
            width: '10%'
        }, {
            key: 'name',
            title: '카테고리명',
            width: '*'
        }, {
            key: 'pollCount',
            title: '소속 설문수',
            width: '9%'
        }, {
            key: 'keywordCount',
            title: '등록된 키워드',
            width: '9%'
        }, {
            key: 'createdAt',
            title: '생성일',
            width: '9%'
        }, {
            key: 'status',
            title: '상태',
            width: '9%'
        }],
        iterator: (res) => {
            return res && res.data && res.data.map((data) => ({
                ...data,
                category: '설문',
                status: STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
            }));
        }
    },
    ranking: {
        LIST_COLUMNS: [{
            key: 'id',
            title: 'NO.',
            width: '9%'
        }, {
            key: 'category',
            title: '구분',
            width: '10%'
        }, {
            key: 'name',
            title: '카테고리명',
            width: '*'
        }, {
            key: 'rankingCount',
            title: '소속 랭킹수',
            width: '9%'
        }, {
            key: 'createdAt',
            title: '생성일',
            width: '9%'
        }, {
            key: 'status',
            title: '상태',
            width: '9%'
        }],
        iterator: (res) => {
            return res && res.data && res.data.map((data) => ({
                ...data,
                category: '랭킹',
                status: STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
            }));
        }
    }
}

const CategoryChannelList = ({category = 'channel'}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        title: queryTitle || '',
    });
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", `category/${category}/list`);
    const {rows, fetch} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        return CATEGORY_LIST_OPTIONS[category].iterator.bind(null, res);
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    const handleSelectRow = (row) => {
        const _category = {
            channel: 'Channel',
            poll: 'Poll',
            ranking: 'Ranking'
        }
        if (!_category[category]) {
            return;
        }
        navigate(`/contents/category${_category[category]}Detail/${row.id}?page=${curPage}`);
    }

    const handleSearch = (data) => {
        const {text} = data;
        const {limit} = formData;

        if (text || text === '') {
            searchParams.set('title', text);
            searchParams.set('page', '1')
            setCurPage(1)
        }

        setSearchParams(searchParams)

        fetch({
            title: text || '',
            offset: 0,
            limit
        });
    };

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
        setCurPage(pageNum)
    };

    // useEffect(() => {
    //     if (!category) return;
    //     let reqParams = {};
    //     if (title) {
    //         reqParams.title = title;
    //     }
    //     fetch(reqParams);
    // }, [category]);

    useEffect(() => {
        setSearchParams(searchParams)

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        })

    }, [queryPage, queryTitle]);

    return (
        <>
            <ListSearch onSearch={handleSearch}
                        inputValue={formData.title}
                        onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
            />
            <Table pageData={formData} totalPages={pageInfo?.totalPages}
                   columns={CATEGORY_LIST_OPTIONS[category].LIST_COLUMNS} rows={rows} onSelectRow={handleSelectRow}
                   totalNum={totalNum} searchParams={searchParams}/>
            <Page pageInfo={pageInfo} handlePage={handlePage}/>
        </>
    )
}

export default CategoryChannelList;
