import {STATUS as CHANNEL_STATUS} from './channel';
import dayjs from 'dayjs';
import DATE from './date';
import {STATUS, TYPE} from './ranking';
import {LIST_STATUS} from './platform';
import {CategoryProfileImage} from '../styledComponents/commonStyle';

const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

export const CATEGORY_LIST_OPTIONS = {
    channel: {
        NAME: '채널',
        LIST_COLUMNS: [
            {
                key: 'id',
                title: 'NO.',
                width: '8%'
            }, {
                key: 'url',
                title: '',
                width: '10%'
            },
            {
                key: 'name',
                title: '채널명',
                width: '*'
            }, {
                key: 'status',
                title: '상태',
                width: '10%'
            }
        ],
        iterator: (res) => {
            return res && res.data && res.data.map((data) => ({
                ...data,
                status: CHANNEL_STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                url:
                    <CategoryProfileImage>
                        <img src={`${domain}${data.url}`} alt={`${data.name}_image`}
                             style={{width: '50px', height: '50px'}}/>
                    </CategoryProfileImage>,
            }));
        }
    },
    poll: {
        NAME: '설문',
        LIST_COLUMNS: [{
            key: 'id',
            title: 'NO.',
            width: '3%'
        }, {
            key: 'channelName',
            title: '채널 명',
            width: '10%'
        }, {
            key: 'title',
            title: '설문 제목',
            width: '20%'
        }, {
            key: 'createdAt',
            title: '등록일',
            width: '5%'
        }, {
            key: 'period',
            title: '설문 기간',
            width: '10%'
        }, {
            key: 'participantsCount',
            title: '참여자',
            width: '10%'
        }, {
            key: 'status',
            title: '상태',
            width: '5%'
        }, {
            key: 'viewStatus',
            title: '노출',
            width: '5%'
        }],
        iterator: (res) => {
            return res && res.data && res.data.map((data) => ({
                ...data,
                status: LIST_STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                period: `${dayjs(new Date(data.startAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.endAt)).format(DATE.FORMAT.DOT)}`,
            }));
        }
    },
    ranking: {
        NAME: '랭킹',
        LIST_COLUMNS: [{
            key: 'id',
            title: 'NO.',
            width: '3%'
        }, {
            key: 'categoryName',
            title: '카테고리',
            width: '5%'
        }, {
            key: 'title',
            title: '랭킹 제목',
            width: '20%'
        }, {
            key: 'publicStatus',
            title: 'Public Ranking',
            width: '4%'
        }, {
            key: 'berryStatus',
            title: 'Berry Ranking',
            width: '4%'
        }, {
            key: 'period',
            title: '투표 기간',
            width: '10%'
        }, {
            key: 'participantsCount',
            title: '참여자수',
            width: '5%'
        },
        ],
        iterator: (res) => {
            return res && res.data && res.data.map((data) => ({
                ...data,
                type: TYPE[data.type],
                berryStatus: STATUS[data.berryStatus],
                publicStatus: STATUS[data.publicStatus],
                period: data.openAt && data.closeAt && `${dayjs(new Date(data.openAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.closeAt)).format(DATE.FORMAT.DOT)}`,
            }));
        }
    }
}
