import { useState } from "react";
import pollberryApiService from "util/pollberryApiService";
import storageService from "util/storage";
import { API_STATE } from "common/constants/state";

/**
 * @param {"get"|"post"|"put"|"patch"|"delete"} method HTTP methods
 * @param {string} urlrequest
 * @param {object} body
 * @param {object} endpoint
 */
const useRequest = (method, url) => {
	const [state, setState] = useState(API_STATE.ready);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const token = storageService.get("auth", "accessToken", "local");

	const getUrl = (url, param, type) => {
		switch (type) {
			case "queryString":
				const paramList = Object.keys(param).reduce((acc, key) => {
					const value = param[key];
					return acc.concat(`${key}=${value}`);
				}, []);
				return `${url}/?${paramList.join("&")}`;
			case "path":
				return `${url}/${param}`;
			default:
				return url;
		}
	};

	const request = (param = {}, type = "body") => {
		const apiService = pollberryApiService
		apiService.setBaererToken(token);
		const requestUrl = getUrl(url, param, type);
		const req = apiService[method](
			requestUrl,
			type === "body" && param
		);
		return req;
	};

	const imageRequest = (param = {}, type = "image") => {
		const apiService = pollberryApiService
		apiService.setBaererToken(token);
		const requestUrl = getUrl(url, param, type);
		const req = apiService[method](
			requestUrl,
			type === "image" && param
		);
		return req;
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"} type
	 */
	const call = (param, type, callBack = null) => {
		if (state === API_STATE.loading) return;
		console.log("param >   " , param)
		setState(API_STATE.loading);
		request(param, type)
			.then((res) => {
				setResponse(res.data);
				setState(API_STATE.done);
				if (callBack) callBack(res.data);
			})
			.catch((e) => {
				const errorObject = e.response ? e.response.data : e;
				console.log("request error ",errorObject)
				setError(errorObject);
				setState(API_STATE.error);
			});
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"| "image"} type
	 */
	const call_new = (param, type, callBack = null) => {
		if (state === API_STATE.loading) return;
		setState(API_STATE.loading);

		// if (type === 'body') {
		// 	request(param, type)
		// 		.then((res) => {
		// 			setResponse(res.data);
		// 			setState(API_STATE.done);
		// 			if (callBack) callBack(res.data);
		// 		})
		// 		.catch((e) => {
		// 			const errorObject = e.response ? e.response.data : e;
		// 			console.log("request error ", errorObject)
		// 			setError(errorObject);
		// 			setState(API_STATE.error);
		// 		});
		// }

		if (type === 'image') {
			imageRequest(param, type)
			.then((res) => {
				setResponse(res.data);
				setState(API_STATE.done);
				if (callBack) callBack(res.data);
			})
			.catch((e) => {
				const errorObject = e.response ? e.response.data : e;
				console.log("request error ",errorObject)
				setError(errorObject);
				setState(API_STATE.error);
			});
		}
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"} type
	 */
	const asyncCall = async (param, type) => {
		return new Promise(async (resolve, reject) => {
			if (state === API_STATE.loading) return;
			setState(API_STATE.loading);
			request(param, type)
				.then((res) => {
					setResponse(res.data);
					setState(API_STATE.done);
					resolve(res.data);
				})
				.catch((e) => {
					const errorObject = e.response ? e.response.data : e;
					setError(errorObject);
					setState(API_STATE.error);
					reject(errorObject);
				});
		});
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"} type
	 */
	const singleCall = (param, type) => {
		if (state !== API_STATE.ready) return;
		setState(API_STATE.loading);
		request(param, type)
			.then((res) => {
				setResponse(res.data);
				setState(API_STATE.done);
			})
			.catch((e) => {
				const errorObject = e.response ? e.response.data : e;
				setError(errorObject);
				setState(API_STATE.error);
			});
	};

	/**
	 * @param {Object[]} listOfParam
	 * @param {"queryString"|"path"|"body"} type
	 */
	const multiCall = (listOfParam, type) => {
		return new Promise(async (resolve, reject) => {
			if (state === API_STATE.loading) return;
			setState(API_STATE.loading);
			const promiseList = listOfParam.map((param) => {
				return new Promise(async (resolve, reject) => {
					request(param, type)
						.then((res) => {
							resolve(res.data);
						})
						.catch((e) => {
							const errorObject = e.response ? e.response.data : e;
							reject(errorObject);
						});
				});
			});
			Promise.all(promiseList)
				.then((d) => {
					setResponse(d);
					setState(API_STATE.done);
					resolve(d);
				})
				.catch((e) => {
					setError(e);
					setState(API_STATE.error);
					reject(e);
				});
		});
	};

	return { call, asyncCall, singleCall, call_new, setState , multiCall, state, response, error };
};

export default useRequest;
