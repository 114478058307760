import React, {useState} from 'react';
import PopupServeyText from './PopupServeyText';
import PopupServeyImg from './PopupServeyImg';
import {
    ArrowBtn,
    PollCheckBtn,
    PollPreviewCloseButton,
    PollQbox,
    PollQNoti,
    PollQTitle,
    PopPollBox,
    PopUp,
    TopHead,
    TopInfo,
} from "common/styledComponents/commonStyle";

import BigArrowLeft from "asset/img/big_icon__keyboard_arrow_left.png";
import BigArrowRight from "asset/img/big_icon__keyboard_arrow_right.png";
import IconClose from '../../../../../asset/img/icon__close_bold.svg';

const PopupSurvey = ({order, whichPopup, moveLeft, moveRight, popupClose, data, questionsLength}) => {

    const [checkNum, setCheckNum] = useState([]);

    const handleCheck = (id) => {
        if (checkNum.some(d => d === id)) { // 아이디가 있으면? 제거
            const filtered = checkNum.filter(s => s !== id);
            setCheckNum(filtered);
        } else if (checkNum.length === data?.maximum && data?.answerType === "PLURAL") {
            if (data?.maximum === 1) {
                setCheckNum([id]);
            }
            return;
        } else if (checkNum.length === data?.maximum && data?.answerType === "SINGULAR") {
            setCheckNum([id])
        } else {                           // 아이디가 없으면? 추가
            setCheckNum([...checkNum, id])
        }
    }

    const surveyType = (data) => {
        switch (data?.itemType) {
            case "TEXT":
                return (<PopupServeyText data={data} handleCheck={handleCheck} checkNum={checkNum}/>);
            case "URL":
                return (<PopupServeyImg data={data} handleCheck={handleCheck} checkNum={checkNum}/>);
            default:
                return (<p>일시적 오류입니다. 다시 시도하여 주십시오.</p>);
        }
    }
    // answerType itemType items maximum minimum title

    return (
        <PopUp dark hidden={whichPopup !== order}>
            <ArrowBtn Left onClick={moveLeft} type='button'>
                <img src={BigArrowLeft} alt=""/>
            </ArrowBtn>
            <ArrowBtn Right onClick={moveRight} disabled={whichPopup === questionsLength} type='button'>
                <img src={BigArrowRight} alt=""/>
            </ArrowBtn>

            <PopPollBox>
                <TopInfo height='100%'>
                    <TopHead xButton>
                        <button type='button' style={{"cursor": "pointer"}} onClick={popupClose}>닫기</button>
                        설문 상세
                    </TopHead>
                    <PollQbox>
                        <PollQTitle>{data.title || "질문 제목을 입력해 주세요."}</PollQTitle>
                        {data && data.answerType === "PLURAL" &&
                            <PollQNoti>복수선택 ( 최소 {data.minimum}개 이상의 답변을 선택해주세요 )</PollQNoti>
                        }
                        {surveyType(data)}
                        <PollCheckBtn>
                            <span>{checkNum.length}</span>개 선택완료
                        </PollCheckBtn>
                    </PollQbox>
                </TopInfo>
                <PollPreviewCloseButton Items onClick={popupClose}>
                    미리보기 닫기 <img src={IconClose} alt='IconClose'/>
                </PollPreviewCloseButton>
            </PopPollBox>
        </PopUp>
    );
};

export default PopupSurvey;
