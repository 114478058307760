import styled from 'styled-components';

export const AdRewardFilterSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
    min-width: 651px;
`;

export const AdTotalReward = styled.div`
    display: flex;
    flex-direction: column;
    gap: 58px;
    border: 1px solid #dbdbdb;
    width: 481px;
    height: 100%;
    border-radius: 8px;
    padding: 32px 40px 0 24px;
`;

export const FilterTitle = styled.span`
    font-size: 14px;
    font-weight: 400;
`;

export const RewardAmount = styled.span`
    font-size: 48px;
    font-weight: 700;
    line-height: 100%; /* 48px */
`;

export const RewardDenom = styled.span`
    font-size: 18px;
    font-weight: 400;
    line-height: 100%; /* 18px */
`;

export const RewardAmountWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: flex-end;
    gap: 16px;
`;

export const SelectedStatusBox = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #000065;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: default;

    button {
        display: flex;
        align-items: center;
    }
`;

export const SelectedResetButton = styled.button`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #DBDBDB;

`;

export const TotalAmount = styled.p`
    font-size: 28px;
    font-weight: 500;
    line-height: 100%;
`;








