import React from "react";
import {
    BoardBox,
    BoardTable,
    BoardTableTbody,
    BoardTableTd,
    BoardTableTh,
    BoardTableThead,
    BoardTableTr,
    Colgroup,
    NoDataSpan,
    NoDataWrapper
} from './TableStyle';
import SearchIcon from 'asset/img/icon__search_off.svg';
import Nodata from 'asset/img/icon__scan_delete.svg';
import {useLocation} from 'react-router-dom';


const Table = ({pageData = {}, totalPages, columns, rows, onSelectRow, columnRenderer, totalNum, ...props}) => {
    // const _totalPages = totalPages - 1 || 0;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const {defaultListValue, modalList} = props;

    return (
        <BoardBox {...props} modalList={modalList}>
            <BoardTable>
                <Colgroup>
                    {columns && columns.map((column, index) => {
                        return (
                            <col width={column.width} key={index}/>
                        )
                    })}
                </Colgroup>
                <BoardTableThead>
                    <BoardTableTr>
                        {columns && columns.map((column, index) => {
                            return (
                                <BoardTableTh key={index}>{column.title}</BoardTableTh>
                            )
                        })}
                    </BoardTableTr>
                </BoardTableThead>
                <BoardTableTbody>
                    {rows && rows.map((row, idx) => {
                        return (
                            <BoardTableTr key={idx} modalList={modalList}>
                                {columns && columns.map((column, index) => {
                                    const {key} = column;
                                    const {offset, limit} = pageData;
                                    const no = totalNum - (offset * limit) - idx;
                                    const value = key === 'id' ? no : row[key];

                                    return (
                                        <BoardTableTd
                                            modalList={modalList}
                                            onClick={columns.length === Number(index) + 1 &&
                                            (row.status === '검수반려' || row.status === '비활성화' || row.viewStatus === '비활성화' || row.viewStatus === false)
                                                ? null : onSelectRow && onSelectRow.bind(row.id, row)}
                                            key={index}
                                            red={row.status === '정지' || row.status === '반려' || row.status === '검수반려' || row.status === '취소'}
                                            fontRed={row.status === 'STOP' || row.status === '답변 대기'}
                                            Charge={row.status === '충전'}
                                        >
                                            {/*<div id={row.id}*/}
                                            {/*     onClick={props.showReturnModal &&*/}
                                            {/*         ((e) => columns.length === Number(index) + 1 ? props.showReturnModal(e) : onSelectRow && onSelectRow.bind(row.id, row))*/}
                                            {/*     }*/}
                                            {/*>*/}
                                            {columnRenderer ? columnRenderer(key, value, row.isTop, row) : value}
                                            {/*</div>*/}
                                        </BoardTableTd>
                                    )
                                })}

                                {/* 필독 아이콘 추가 */}
                                {/* <BoardTableTd><BoardTableTdPick>필독</BoardTableTdPick></BoardTableTd> */}
                            </BoardTableTr>
                        )
                    })}
                </BoardTableTbody>
            </BoardTable>
            {rows && rows ? null :
                <NoDataWrapper Center Column height='600px'>
                    <img src={defaultListValue ? Nodata : queryParams.toString().length === 0 ? Nodata : SearchIcon}
                         alt='icon_no_data'/>
                    <NoDataSpan>{defaultListValue ? '데이터가 없습니다.' : queryParams.toString().length === 0 ? '데이터가 없습니다.' : '검색 데이터가 없습니다.'}</NoDataSpan>
                </NoDataWrapper>
            }
        </BoardBox>
    )
};

export default Table;
