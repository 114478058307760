import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import DATE from "common/constants/date";
import ListSearch from "common/components/ListSearch";
import Table from "common/components/Table";
import Page from "common/components/Page";
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import pageMaker from "../../../common/components/Page/pageMaker";
import {useGlobalStore} from "stores";
import {FAQ_LIST_COLUMNS} from '../../../common/constants/noticeBoard';

const FaqList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        title: searchParams || '',
    });
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", "faq/list");

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const boardAuth = useMemo(() => {
        const accessAuth = info?.access?.boardAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return (
            data &&
            data.map((data) => ({
                ...data,
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            }))
        );
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    function updateSearchParams(key, value) {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    }

    const handleSelectRow = (row) => {
        navigate(`/faq/detail/${row.id}?page=${curPage}`);
    };

    const handleSearch = (data) => {
        const {text} = data;

        if (text || text === '') {
            updateSearchParams('title', text);
            updateSearchParams('page', '1')
            setCurPage(1)
        }

        fetch({
            ...formData,
            title: text || '',
            page: 1,
            offset: 0,
        });
    };

    const moveFaqPost = () => {
        navigate("/faqPost");
    };

    const handlePage = (pageNum) => {
        updateSearchParams('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    };

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            // offset: 1
        })

    }, [queryPage, queryTitle,]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>
                        FAQ 관리
                        {
                            boardAuth.find(d => d === "W") &&
                            <Button tit onClick={moveFaqPost}>
                                FAQ 등록하기
                            </Button>
                        }

                    </Title>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={FAQ_LIST_COLUMNS} rows={rows}
                           onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    );
};

export default FaqList;
