// import { config } from "dotenv";
//
// config();

export const NODE_ENV =
	process.env.REACT_APP_ENV === undefined ? "development" : process.env.REACT_APP_ENV;

export const BUILD_TIME =
	process.env.REACT_APP_BUILD_TIME === undefined
		? "REALTIME"
		: process.env.REACT_APP_BUILD_TIME.replace(
			/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/,
			`$1.$2.$3 $4:$5`
		);

export const API_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`;

export const REQUEST_CREDENTIALS = process.env.REACT_APP_API_REQUEST_CREDENTIALS;

export const UPLOAD_IMAGE_BASE_URL = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;
