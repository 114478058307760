import React from 'react';
import {
    Button,
    CloseButton,
    GrayButton,
    ModalContainer,
    NoticeDetailContent,
    RewardDetailTitle,
    StyledModal,
    Wrapper
} from '../../styledComponents/commonStyle';
import CloseIcon from '../../../asset/img/icon__close_black.svg';

function NoticeModal({title, closeModal, onSubmit, children, check}) {

    return (
        <StyledModal>
            <ModalContainer notice>
                <RewardDetailTitle notice>{title}</RewardDetailTitle>
                <NoticeDetailContent>
                    {children}
                </NoticeDetailContent>
                <Wrapper Center width='auto' margin={check ? '59px 0 0 0' : '45px 0 0 0'}>
                    {check &&
                        <GrayButton mini onClick={closeModal} margin='0 24px 0 0'>취소</GrayButton>
                    }
                    <Button mini onClick={onSubmit ? onSubmit : closeModal}>확인</Button>
                </Wrapper>
                <CloseButton onClick={closeModal}>
                    <img src={CloseIcon} alt='close'/>
                </CloseButton>
            </ModalContainer>
        </StyledModal>
    );
}

export default NoticeModal;
