import React from "react";
import {useNavigate} from "react-router";
import {MenuInfoCont, MenuInfoSub} from "pages/Mypage/MypageStyle";
import {
    Button,
    ButtonRight,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl,
    Option,
    PointListOpenButton,
    Select,
    UserProfileImageWrapper,
    UserProfileWrapper,
    Wrapper
} from "common/styledComponents/commonStyle";
import Bg from '../../../asset/img/img-back.jpg';

const UserDetailBizz = ({userInfo, register, setFormValue, userChannelDetail, userAuth, openPointModal}) => {
    const navigate = useNavigate();
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

    return (
        <>
            <MenuInfoCont survey>
                <Wrapper>
                    <UserProfileWrapper>
                        <span>프로필 이미지</span>
                        <UserProfileImageWrapper>
                            {/*<img src={`${domain}${userInfo.profileImageUrl}`} alt='profileImage'/>*/}
                            {userInfo.profileImageUrl && userInfo.profileImageUrl.includes('amazonaws') ?
                                <img src={userInfo.profileImageUrl} alt='profileImage'/>
                                : <img src={userInfo.profileImageUrl ? `${domain}${userInfo.profileImageUrl}` : Bg}
                                       alt='profileImage'/>
                            }
                            {/*<img
                             // value={userInfo.profileImageUrl}
                             // name = "__profileImageUrl"
                             // onChange={(value) => {
                             //     setFormValue("profileImageUrl", UPLOAD_IMAGE_BASE_URL + value);
                             // }}
                             />*/}
                        </UserProfileImageWrapper>
                    </UserProfileWrapper>
                    <List>
                        <ListUl>
                            <ListLi>
                                <ListLtxt>아이디</ListLtxt>
                                <ListRtxt>{userInfo.userId}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>채널 이름</ListLtxt>
                                <ListRtxt>{userInfo.channelName}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>포인트</ListLtxt>
                                <ListRtxt>
                                    <PointListOpenButton onClick={openPointModal} type='button'>
                                        {Number(userInfo.totalPoint).toLocaleString()}Berry
                                    </PointListOpenButton>
                                </ListRtxt>
                            </ListLi>
                        </ListUl>
                    </List>
                </Wrapper>
            </MenuInfoCont>
            <MenuInfoCont info>
                <MenuInfoSub>부가 정보</MenuInfoSub>
                <List zero>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>회사명</ListLtxt>
                            <ListRtxt>{userInfo.companyName}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>사업자 등록번호</ListLtxt>
                            <ListRtxt>{userInfo.companyNumber}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>담당자</ListLtxt>
                            <ListRtxt>
                                <ListInput type="text" defaultValue={userInfo.name}
                                           placeholder="입력"  {...register('name')}/>
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>이메일 주소</ListLtxt>
                            <ListRtxt>
                                <ListInput type="email" defaultValue={userInfo.email}
                                           placeholder="입력"  {...register('email')}/>
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>연락처</ListLtxt>
                            <ListRtxt>
                                <ListInput type="text" defaultValue={userInfo.phone}
                                           placeholder="입력"  {...register('phone')}/>
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>계약구분</ListLtxt>
                            <ListRtxt>
                                <Select>
                                    <Option>텍스트 영역</Option>
                                    <Option>텍스트 영역</Option>
                                </Select>
                            </ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </MenuInfoCont>
            <ButtonRight mini>
                {userAuth.some(d => d === "W") && (
                    userInfo && userInfo.channelId ?
                        <Button mini line type="button"
                                onClick={() => navigate(`/channel/detail/${userInfo.channelId}`)}
                        >
                            채널 관리하기
                        </Button>
                        : <Button mini line type="button" onClick={() => navigate("/createChannel")}>
                            {/*: <Button mini line type="button"*/}
                            {/*          onClick={() => navigate("/createChannel", {state: {userId: userInfo.userId}})}*/}
                            {/*>*/}
                            채널 관리하기
                        </Button>)
                }
                {userAuth.some(d => d === "W") &&
                    <Button mini>수정 하기</Button>
                }
            </ButtonRight>
        </>
    )
}

export default UserDetailBizz;
