import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {MenuArea, MenuInfoArea, Title, Wrapper} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import SearchFilter from "common/components/SearchFilter"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {
  DateSearch,
  SearchConditionBox,
  SearchConditionDiv,
  SearchConditionText,
  SearchDateBox,
  SearchDateInput,
  SearchDateInputSpan,
  SearchDateMid,
  SearchDateTxt
} from "../../ServeyManage/ReportSurvey/channelSurveySytle"
import {DATE_FILTER_OPTIONS} from "common/constants/poll";
import Select from 'react-select';
import {
  COUPON_MANAGEMENT_LIST_COLUMN,
  COUPON_SEARCH_OPTIONS,
  COUPON_SEARCH_TYPE_OPTIONS,
  COUPON_TYPE
} from '../../../common/constants/point';

const CouponManagement = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get('page')
  const queryStartAt = searchParams.get('startAt') || ''
  const queryEndAt = searchParams.get('endAt') || ''
  const queryType = searchParams.get('type') || ''
  const queryTypeText = searchParams.get('typeText') || ''
  const queryDateOption = searchParams.get('dateOption') || 'ALL'
  const reportListRequest = useRequest("get", "point/coupon/list");
  const [curPage, setCurPage] = useState(1);
  const [formData, setFormData] = useState({
    offset: queryPage ? queryPage - 1 : 0,
    limit: 10,
    page: queryPage || 1,
    startAt: queryStartAt ? queryStartAt : '',
    endAt: queryEndAt ? queryEndAt : '',
    type: queryType ? queryType : '',
  });
  const [totalNum, setTotalNum] = useState(0);
  const [dateFilterOption, setDateFilterOption] = useState(formData.dateOption ? formData.dateOption : queryDateOption);
  const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
  const refStartAt = useRef(null);
  const refEndAt = useRef(null);

  const getSearchDate = () => {
    const now = dayjs(new Date());
    let date = {
      startAt: now,
      endAt: now,
    };
    switch (dateFilterOption) {
      case '1M':
        date.startAt = now.subtract(1, 'month');
        break;
      case '3M':
        date.startAt = now.subtract(3, 'month');
        break;
      case '6M':
        date.startAt = now.subtract(6, 'month');
        break;
      case '1Y':
        date.startAt = now.subtract(1, 'year');
        break;
      case 'SELECT':
        date.startAt = dayjs(refStartAt.current.value);
        date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
        break;
      case 'ALL':
        date.startAt = null;
        date.endAt = null;
        break;
      default:
        date.startAt = now;
        date.endAt = now;
        break;
    }

    return {
      startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
      endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
    }

  }

  const {startAt, endAt} = getSearchDate();

  const {rows, fetch} = useBoard(reportListRequest, (res) => {
    setTotalNum(res.totalCount);
    const {data} = res;
    return data && data.map((data) => ({
      ...data,
      type: COUPON_TYPE[data.type],
      createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
      amount: `${data.amount.toLocaleString()} Berry`
    }));
  }, {
    limit: 10,
    offset: 0,
    startAt,
    endAt,
    // ...getSearchDate()
  });

  const handleChangeSearchKey = (option) => {
    searchParams.set('type', option.value)
    setFormData({...formData, type: option.value})
  }

  const handleSearch = (data) => {
    const {startAt, endAt} = getSearchDate();
    const {type, text, limit,} = formData;

    if (type !== '' && !text) {
      alert('검색어 입력 또는 검색 조건을 변경해주세요.')
      return
    }

    searchParams.set('type', type)
    searchParams.set('typeText', text)
    searchParams.set('page', '1')

    if ((startAt && endAt) || (startAt === '' && endAt === '')) {
      setCurPage(1)
      searchParams.set('startAt', startAt)
      searchParams.set('endAt', endAt)

      if (dateFilterOption) {
        searchParams.set('dateOption', dateFilterOption)
      }

      setFormData({
        ...formData,
        page: 1,
        offset: 0,
        startAt: startAt,
        endAt: endAt,
        dateOption: dateFilterOption,
      })
    }

    switch (type) {
      case '' :
        fetch({
          name: text || '',
          product: text || '',
          brand: text || '',
          startAt: startAt || '',
          endAt: endAt || '',
          page: 1,
          offset: 0,
          limit
        });
        searchParams.set('name', text)
        break;
      case 'name' :
        fetch({
          name: text || '',
          startAt: startAt || '',
          endAt: endAt || '',
          page: 1,
          offset: 0,
          limit
        });
        searchParams.set('name', text)
        break;
      case 'product' :
        fetch({
          product: text || '',
          startAt: startAt || '',
          endAt: endAt || '',
          page: 1,
          offset: 0,
          limit
        });
        searchParams.set('product', text)
        break;
      case 'brand' :
        fetch({
          brand: text || '',
          startAt: startAt || '',
          endAt: endAt || '',
          page: 1,
          offset: 0,
          limit
        });
        searchParams.set('brand', text)
        break;
      default:
        break;
    }

    setCurPage(1)
    setSearchParams(searchParams)
  }

  const handleDateFilterSelect = (option) => {
    setDateFilterOption(option);
    const {startAt, endAt} = getSearchDate();
    refEndAt.current.value = endAt;

    if (option === 'SELECT') {
      refStartAt.current.value = dayjs().format(DATE.FORMAT.DASH);
    } else {
      refStartAt.current.value = startAt;
    }

    setFormData(prevState => ({...prevState, startAt: startAt, endAt: endAt, dateOption: option}))

  }

  const handleChangeStartAt = () => {
    const {startAt} = getSearchDate();
    refStartAt.current.value = startAt;
    searchParams.set('startAt', startAt)
    setFormData({...formData, startAt: startAt})
  }

  const handleChangeEndAt = () => {
    const {endAt} = getSearchDate();
    refEndAt.current.value = endAt;
    searchParams.set('endAt', endAt)
    setFormData({...formData, endAt: endAt})
  }

  const handlePage = (pageNum) => {
    searchParams.set('page', pageNum)
    setFormData({...formData, page: pageNum, offset: pageNum - 1})
    setCurPage(pageNum)
  };

  // const openStopReasonModal = () => {
  //   setOnReturnModal(true)
  //   document.body.style.overflow = 'hidden';
  // }

  // const closeStopReasonModal = () => {
  //   document.body.style.overflow = 'auto';
  //   setOnReturnModal(false)
  // }

  const customStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: 168,
        border: "1px solid #757575",
        borderRadius: "8px",
        background: '#F5F5F5',
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
    }),
    [],
  );

  useEffect(() => {

    setFormData(prevState => ({
      ...prevState,
      page: Number(queryPage) || 1,
      offset: queryPage ? Number(queryPage) - 1 : 0,
      type: queryType || '',
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      dateOption: queryDateOption || 'ALL',
      text: queryTypeText || '',
    }))

    setDateFilterOption(queryDateOption)

    switch (queryType) {
      case '' :
        fetch({
          name: queryTypeText || '',
          product: queryTypeText || '',
          brand: queryTypeText || '',
          startAt: startAt || '',
          endAt: endAt || '',
          page: 1,
          offset: queryPage ? Number(queryPage) - 1 : 0,
          limit: 10
        });
        break;
      case 'name' :
        fetch({
          name: queryTypeText || '',
          startAt: startAt || '',
          endAt: endAt || '',
          offset: queryPage ? Number(queryPage) - 1 : 0,
          limit: 10,
        });
        break;
      case 'product' :
        fetch({
          product: queryTypeText || '',
          startAt: startAt || '',
          endAt: endAt || '',
          offset: queryPage ? Number(queryPage) - 1 : 0,
          limit: 10,
        });
        break;
      case 'brand' :
        fetch({
          brand: queryTypeText || '',
          startAt: startAt || '',
          endAt: endAt || '',
          offset: queryPage ? Number(queryPage) - 1 : 0,
          limit: 10,
        });
        break;
      default:
        break;
    }

  }, [queryPage]);

  return (
    <>
      <MenuArea>
        <MenuInfoArea>
          <Title btn> 모바일 쿠폰 관리 </Title>
          <DateSearch>
            <SearchConditionBox>
              <Wrapper maxWidth='626px' Row>
                <Wrapper maxWidth='289px'>
                  <SearchConditionText top>검색 조건</SearchConditionText>
                  <Select
                    options={COUPON_SEARCH_OPTIONS}
                    placeholder={queryType ? COUPON_SEARCH_TYPE_OPTIONS[queryType] : COUPON_SEARCH_OPTIONS[0].label}
                    value={queryType ? COUPON_SEARCH_TYPE_OPTIONS[queryType] : COUPON_SEARCH_OPTIONS[0].label}
                    onChange={handleChangeSearchKey}
                    styles={customStyles}
                    isSearchable={false}
                  />
                </Wrapper>
              </Wrapper>
            </SearchConditionBox>
            <SearchConditionBox>
              <SearchConditionText>조회 기간</SearchConditionText>
              <SearchConditionDiv>
                <SearchFilter options={DATE_FILTER_OPTIONS} option={formData.dateOption}
                              onSelect={handleDateFilterSelect} type="filter"/>
                <SearchDateBox>
                  <SearchDateInputSpan>
                    <SearchDateInput type="date"
                                     {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                     ref={refStartAt} onChange={() => handleChangeStartAt()}/>
                    <SearchDateTxt>{startAt || startAt === '' ? startAt : formData.startAt}</SearchDateTxt>
                  </SearchDateInputSpan>
                  <SearchDateMid>~</SearchDateMid>
                  <SearchDateInputSpan>
                    <SearchDateInput type="date"
                                     min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                     ref={refEndAt} onChange={() => handleChangeEndAt()}/>
                    <SearchDateTxt>{endAt || endAt === '' ? endAt : formData.endAt}</SearchDateTxt>
                  </SearchDateInputSpan>
                </SearchDateBox>
              </SearchConditionDiv>
            </SearchConditionBox>
          </DateSearch>
          <ListSearch
            // initOption={queryStatus ? queryStatus : formData.status}
            inputValue={formData.text}
            onChange={(e) => setFormData(prevState => ({...formData, text: e.target.value}))}
            onSearch={handleSearch}/>
          <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={COUPON_MANAGEMENT_LIST_COLUMN}
                 rows={rows}
                 totalNum={totalNum}
            // columnRenderer={columnRenderer}
                 searchParams={searchParams}
          />
          <Page pageInfo={pageInfo} handlePage={handlePage}/>
        </MenuInfoArea>
      </MenuArea>
    </>
  )
}

export default CouponManagement;
