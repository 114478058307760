import pollberryApiService from '../../util/pollberryApiService';
import storageService from '../../util/storage';
import axios from 'axios';

async function ConvertCategoryProfileImage(image, apiType) {
    const token = storageService.get("auth", "accessToken", "local");
    const apiService = pollberryApiService
    apiService.setBaererToken(token);

    const formData = new FormData();
    formData.append("image", image);
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/image/poll/thumbnail/new`,
            formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            }
        );
        formData.delete('image');
        return {
            ...image,
            url: response.data.response.url
        };

    } catch (error) {
        console.error(error, 'error');
        formData.delete('image');
        return image;
    }

}

export default ConvertCategoryProfileImage;
