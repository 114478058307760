import styled, {css} from "styled-components";
import {Button, PollSettingContainer} from "../../../common/styledComponents/commonStyle";

import TextDelIcon from "asset/img/icon__cancel.png";
import RightArrowIcon from "asset/img/icon__keyboard_arrow_right.png";
import ModifyIcon from "asset/img/icon__reset.png";

export const Container = styled(PollSettingContainer)`
  padding: 48px 0 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
`;

export const Wrapper = styled.div`
  display: flex;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};

  ${(props) =>
          props.Column &&
          css`
            flex-flow: column;
            align-items: center;
            justify-content: space-between;
          `}

  ${(props) =>
          props.Border &&
          css`
            border: 1px solid #dbdbdb;
            border-top: 2px solid #000065;
          `}

  ${(props) =>
          props.Center &&
          css`
            justify-content: center;
          `}

  ${(props) =>
          props.Right &&
          css`
            justify-content: right;
          `}

  ${(props) =>
          props.Header &&
          css`
            span {
              font-size: 18px;
              font-weight: 500;
            }

            margin: 0 0 15px 0;
          `}
  ${(props) =>
          props.line &&
          css`
            border-top: 1px solid #dbdbdb;
          `}
`;

export const Content = styled.div`
  display: flex;
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.MaxWidth && "608px"};
  margin: ${(props) => props.margin};
  border: ${(props) => props.margin};

  ${(props) =>
          props.Subheading &&
          css`
            width: auto;

            span {
              margin-top: auto;
              font-size: 14px;
              color: #1a1a1a;
            }
          `}

  ${(props) =>
          props.Column &&
          css`
            flex-flow: column;
          `}

  ${(props) =>
          props.right &&
          css`
            justify-content: right;
          `}
`;

export const ItemDetailList = styled.ul`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  overflow: auto;

  li {
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  ${(props) =>
          props.Row &&
          css`
            flex-flow: row;
            flex-wrap: wrap;

            li {
              width: 50%;
            }
          `}
`;

export const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 48px;
  font-size: 18px;

  ${(props) =>
          props.Preview &&
          css`
            background-color: #ffffff;
            border: 2px solid #000065;
            color: #000065;
          `}
  & + & {
    margin-left: 20px;
  }
`;

// 삭제버튼
export const ItemLi = styled.li`
  position: relative;
  cursor: pointer;

  ${(props) =>
          props.On &&
          css`
            &::after {
              content: "";
              background-image: url(${RightArrowIcon});
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              right: 8px;
              top: 18px;
            }

            background-color: #f5f5f5;
          `}
`;

export const TokenDelBtn = styled.button`
  width: 42px;
  height: 28px;
  padding: 3px 0px;
  border: 1px solid #e92928;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  color: #e92928;
  position: absolute;
  right: 36px;
  top: 16px;

  &:hover {
    color: #fff;
    background-color: #e92928;
  }
`;

export const TokenEditBtn = styled.button`
  width: 70px;
  height: 28px;
  padding: 3px 0px;
  border: 1px solid #0000FF;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  color: #0000FF;
  position: absolute;
  right: 80px;
  top: 16px;

  &:hover {
    color: #fff;
    background-color: #0000FF;
  }
`;

export const TokenOptionDellBtn = styled.button`
  width: 16px;
  height: 16px;
  background-image: url(${TextDelIcon});
  background-size: cover;
  font-size: 0;
  position: relative;
  top: -6px;
  right: -8px;
`;

//수정 버튼
export const PuzzleModifyBtn = styled.a`
  width: 16px;
  height: 16px;
  background-image: url(${ModifyIcon});
  background-size: cover;
  font-size: 0;
  position: absolute;
  top: 10px;
  right: 10px;
`;
