export const STATUS = {
    NORMAL: '검수 완료',
    ONGOING: '진행중',
    EXPIRED: '기한 만료',
    EXHAUSTED: '보상 마감',
    COMPLETE: '수동 완료',
    DELETE: '삭제',
    STOP: '정지'
}

export const GRADE = {
    FIRST: '1단계 회원',
    SECOND: '2단계 회원'
}

export const ANSWER_TYPE = {
    SINGULAR: '단일 선택',
    PLURAL: '복수 선택'
}

export const ITEM_TYPE = {
    TEXT: '텍스트형',
    URL: '이미지형'
}

export const VIEW_TYPE_OPTIONS = [
    {
        label: 'S',
        value: 'S'
    }, {
        label: 'M',
        value: 'M'
    }, {
        label: 'L',
        value: 'L'
    }
];

export const VIEW_TYPE_STATUS = {
    S: 'S',
    M: 'M',
    L: 'L',
};

export const PAY_OPTIONS = [{
    text: '지급',
    value: 'Y'
}, {
    text: '지급 안함',
    value: 'N'
}];

export const ANSWER_TYPE_OPTIONS = [{
    text: ANSWER_TYPE.SINGULAR,
    value: 'SINGULAR'
}, {
    text: ANSWER_TYPE.PLURAL,
    value: 'PLURAL'
}];

export const ITEM_TYPE_OPTIONS = [{
    text: ITEM_TYPE.TEXT,
    value: 'TEXT'
}, {
    text: ITEM_TYPE.URL,
    value: 'URL'
}];

// export const MIN_MAX_OPTIONS = [{
//   text: '1',
//   value: '1'
// }]

export const QUESTION_DEFAULT = {
    title: '',
    answerType: 'SINGULAR',
    itemType: 'TEXT',
    minimum: 1,
    maximum: 1,
    items: [{
        text: ''
    }]
};

export const DATE_FILTER_OPTIONS = [{
    text: '전체',
    value: 'ALL'
}, {
    text: '1개월',
    value: '1M'
}, {
    text: '3개월',
    value: '3M'
}, {
    text: '6개월',
    value: '6M'
}, {
    text: '1년',
    value: '1Y'
}, {
    text: '직접설정',
    value: 'SELECT'
}];


export const INSPECTION_LIST_COLUMNS = [
    {
        key: 'id',
        title: 'NO.',
        width: '5%'
    },
    {
        key: 'channelName',
        title: '채널명',
        width: '9%'
    },
    {
        key: 'title',
        title: '설문 제목',
        width: '20%'
    },
    {
        key: 'createdAt',
        title: '등록일',
        width: '6%'
    },
    {
        key: 'period',
        title: '설문 기간',
        width: '9%'
    },
    {
        key: 'status',
        title: '상태',
        width: '5%'
    }
];

export const INSPECTION_SEARCH_KEY_OPTIONS = [
    {
        label: '전체',
        value: ''
    },
    {
        label: '채널명',
        value: 'name'
    }, {
        label: '설문 제목',
        value: 'title'
    }
];

export const INSPECTION_SEARCH_KEY_STATUS = {
    '': '전체',
    name: '채널명',
    title: '설문 제목'
};


export const CHANNEL_LIST_COLUMNS = [
    {
        key: 'id',
        title: 'NO.',
        width: '3.8%'
    }, {
        key: 'channelName',
        title: '채널명',
        width: '4.5%'
    }, {
        key: 'title',
        title: '설문 제목',
        width: '15%'
    }, {
        key: 'createdAt',
        title: '등록일',
        width: '5%'
    }, {
        key: 'period',
        title: '설문 기간',
        width: '8%'
    }, {
        key: 'participantsCount',
        title: '참여자 수',
        width: '4.5%'
    }, {
        key: 'status',
        title: '상태',
        width: '3.8%'
    }, {
        key: 'viewStatus',
        title: '노출',
        width: '3.8%'
    }
];

export const CHANNEL_SEARCH_KEY_OPTIONS = [
    {
        label: '전체',
        value: ''
    }, {
        label: '채널명',
        value: 'name'
    }, {
        label: '설문 제목',
        value: 'title'
    }, {
        label: '키워드명',
        value: 'keyword'
    }
];

export const CHANNEL_SEARCH_KEY_STATUS = {
    '': '전체',
    name: '채널명',
    title: '설문 제목',
    keyword: '키워드',
};

export const CHANNEL_SEARCH_FILTER_OPTIONS = [
    {
        text: '전체',
        value: ''
    }, {
        text: '검수완료',
        value: 'NORMAL'
    }, {
        text: '진행중',
        value: 'ONGOING'
    }, {
        text: '기한만료',
        value: 'EXPIRED'
    }, {
        text: '보상마감',
        value: 'EXHAUSTED'
    }, {
        text: '수동완료',
        value: 'COMPLETE'
    }
];

export const POINTMNG_REFUNDMNG_STATE_LIST = [
    {
        key: 'id',
        title: 'NO.',
        width: '5%'
    },
    {
        key: 'createdAt',
        title: '일자',
        width: '8%'
    },
    {
        key: 'type',
        title: '구분',
        width: '10%'
    },
    {
        key: 'userName',
        title: '회원 명',
        width: '15%'
    },
    // {
    //     key: 'berry',
    //     title: 'Berry 단위',
    //     width: '25%'
    // },
    // {
    //     key: 'token',
    //     title: 'Swap Token 단위',
    //     width: '25%'
    // },
    {
        key: 'Reward Token',
        title: 'Swap Token 단위',
        width: '50%'
    },
    {
        key: 'status',
        title: '상태',
    },
]

export const USER_LIST_COLUMNS = [
    {
        key: 'id',
        title: 'NO.',
        width: '5%'
    }, {
        key: 'nickname',
        title: '닉네임',
        width: '6%'
    }, {
        key: 'title',
        title: '설문 제목',
        width: '20%'
    }, {
        key: 'createdAt',
        title: '등록일',
        width: '7.5%'
    }, {
        key: 'period',
        title: '설문 기간',
        width: '15%'
    }, {
        key: 'participantsCount',
        title: '참여자 수',
        width: '6%'
    }, {
        key: 'status',
        title: '상태',
        width: '5%'
    }
];

export const REPORT_SURVEY_STATUS = {
    '': '전체',
    ONGOING: '진행중',
    STOP: '강제 중지',
    COMPLETE: '수동 완료',
    DELETE: '삭제',
}

export const REPORT_LIST_COLUMNS = [
    {
        key: 'id',
        title: 'NO.',
        width: '3%'
    }, {
        key: 'createdBy',
        title: '등록 회원',
        width: '3%'
    }, {
        key: 'title',
        title: '설문 제목',
        width: '20%'
    }, {
        key: 'createdAt',
        title: '신고일',
        width: '4%'
    }, {
        key: 'reportBy',
        title: '신고 회원',
        width: '4%'
    }, {
        key: 'reasonForReport',
        title: '신고 사유',
        width: '6%'
    }, {
        key: 'status',
        title: '상태',
        width: '3%'
    },
];

export const REPORT_SEARCH_TYPE_OPTIONS = {
    '': '전체',
    'CREATEDBY': '등록 회원',
    'TITLE': '설문 제목',
    'REPORTBY': '신고 회원',
};

export const REPORT_SEARCH_OPTIONS = [
    {
        label: '전체',
        value: ''
    }, {
        label: '등록 회원',
        value: 'CREATEDBY'
    }, {
        label: '설문 제목',
        value: 'TITLE'
    }, {
        label: '신고 회원',
        value: 'REPORTBY'
    }
];

export const USER_SEARCH_KEY_OPTIONS = [
    {
        label: '전체',
        value: ''
    },
    {
        label: '닉네임',
        value: 'name'
    }, {
        label: '설문 제목',
        value: 'title'
    }
];

export const USER_SEARCH_KEY_STATUS =
    {
        '': '전체',
        'name': '닉네임',
        'title': '설문 제목',
    };


export const USER_SEARCH_KEY_OPTIONS_TWO = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '닉네임',
        value: 'name'
    },
    {
        text: '채널 명',
        value: 'title'
    }
];

export const USER_SEARCH_FILTER_OPTIONS = [{
    text: '전체',
    value: ''
}, {
    text: '진행중',
    value: 'ONGOING'
}, {
    text: '완료',
    value: 'COMPLETE'
}];

export const SURVEY_DETAIL_OPTIONS = [{
    text: '정상',
    value: 'ONGOING'
}, {
    text: '정지',
    value: 'STOP'
}, {
    text: '완료',
    value: 'COMPLETE'
}, {
    text: '검수중',
    value: 'INSPECTION'
}, {
    text: '검수 반려',
    value: 'RETURN'
}
];

export const SURVEY_DETAIL_OPTIONS_VER2 = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '정상',
        value: 'ONGOING'
    },
    {
        text: '정지',
        value: 'STOP'
    },
    {
        text: '완료',
        value: 'COMPLETE'
    }
];

export const SURVEY_DETAIL_OPTIONS_CHARGE = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '충전',
        value: 'ONGOING'
    },
    {
        text: '취소',
        value: 'STOP'
    }
];

export const SURVEY_DETAIL_OPTIONS_USAGE = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '정상',
        value: 'ONGOING'
    },
    {
        text: '취소',
        value: 'STOP'
    }
];

export const USER_SURVEY_DETAIL_OPTIONS = [
    {
        label: '진행중',
        value: 'ONGOING'
    }, {
        label: '수동완료',
        value: 'COMPLETE'
    }, {
        label: '검수완료',
        value: 'NORMAL'
    }, {
        label: '기한만료',
        value: 'EXPIRED'
    }, {
        label: '보상마감',
        value: 'EXHAUSTED'
    },
];

export const USER_SURVEY_STATUS = {
    ONGOING: '진행중',
    COMPLETE: '수동 완료',
    NORMAL: '검수 완료',
    EXPIRED: '기한 만료',
    EXHAUSTED: '보상 마감',
    INSPECTION: '검수 대기',
    RETURN: '검수 반려',
    STOP: '정지',
    DELETE: '삭제',
};

export const INSPECTION_SURVEY_DETAIL_OPTIONS = [{
    text: '검수중',
    value: 'ONGOING'
}, {
    text: '검수 반려',
    value: 'RETURN'
}
];

export const POINTMNG_REWARDMNG_STATE_LIST = [
    {
        key: 'id',
        title: 'NO.'
    }, {
        key: 'createdAt',
        title: '일자'
    }, {
        key: 'type',
        title: '구분'
    },
    {
        key: 'title',
        title: '설문 제목'
    },
    {
        key: 'balance',
        title: '보상 합계'
    },
    // {
    //     key: 'gubun',
    //     title: '구분'
    // },
    {
        key: 'participantsCount',
        title: '참여 인원'
    },
    {
        key: 'status',
        title: '상태'
    },
]

export const POINTMNG_EXCHANGEMNG_STATE_LIST = [
    {
        key: 'number',
        title: 'NO.'
    },
    {
        key: 'date',
        title: '일자'
    },
    {
        key: 'name',
        title: '회원 명'
    },
    {
        key: 'berry',
        title: 'Berry 단위'
    },
    {
        key: 'coin',
        title: 'Coin'
    },
    {
        key: 'gubun',
        title: '구분'
    },
]

export const POINTMNG_USERMNG_STATE_LIST = [
    {
        key: 'number',
        title: 'NO.'
    },
    {
        key: 'useDate',
        title: '사용일'
    },
    {
        key: 'memberType',
        title: '회원 구분'
    },
    {
        key: 'name',
        title: '회원 명'
    },
    {
        key: 'berryState',
        title: 'Berry 현황'
    }
]

export const POINTMNG_USERMNG_POINT_DETAIL = [
    {
        key: 'id',
        title: 'NO.'
    },
    {
        key: 'latestAt',
        title: '일자'
    },
    {
        key: 'role',
        title: '회원 구분'
    },
    {
        key: 'userName',
        title: '회원 명'
    },
    {
        key: 'totalPoint',
        title: 'Berry Point 현황'
    },
    {
        key: '--',
        title: 'Reward Token 현황'
    },
    {
        key: 'status',
        title: '상태'
    },
]
