import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import SurveyManageDetail from "pages/ServeyManage/SurveyManageDetail"
import {API_STATE} from "common/constants/state";
import DATE from "common/constants/date";
import CloseIcon from "asset/img/icon__close_black.svg";
import {
    Button,
    CenterWrapper,
    CloseButton,
    GrayButton,
    InspectionReasonSpan,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Wrapper
} from "common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";


const UserSurveyDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const pollDetailRequest = useRequest("get", `poll/${id}`);
    const checkPollRequest = useRequest("patch", `poll/${id}/check`);
    const [detailInfo, setDetailInfo] = useState({});
    const [onThumbnail, setOnThumbnail] = useState(false)
    const [onInspectionModal, setOnInspectionModal] = useState(false)
    const [onCompleteModal, setOnCompleteModal] = useState(false)
    const [inspectionReason, setInspectionReason] = useState('')
    const [inspectionState, setInspectionState] = useState({
        return: false,
    })

    let checkPass;

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const pollAuth = useMemo(() => {
        const accessAuth = info?.access?.pollAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const openInspectionModal = (isPassed) => {
        setOnInspectionModal(true)
        setInspectionState(prevState => ({...prevState, return: true}))
    }

    const openCompleteModal = (isPassed) => {
        setOnCompleteModal(true)
    }

    const closeInspectionModal = () => {
        setOnInspectionModal(false)
        // navigate("/surveyMange/inspection");
    }

    const closeCompleteModal = (check) => {
        setOnCompleteModal(false)
        if (check) {
            navigate("/surveyMange/inspection");
        }
    }

    const handelInspectionReturn = (isPassed) => {
        checkPollRequest.call({
            isPassed,
            reasonForReturn: inspectionReason
        }, 'body');
        // closeInspectionModal()
    }

    const handelInspectionCheck = (isPassed) => {
        checkPollRequest.call({isPassed: true}, 'body');
    }

    useEffect(() => {
        pollDetailRequest.call()
    }, []);

    useEffect(() => {

        switch (pollDetailRequest.state) {
            case API_STATE.done:
                if (pollDetailRequest.response.success) {
                    const {response} = pollDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    const detailInfo = {
                        ...response,
                        startAt: dayjs(new Date(response.startAt)).format(DATE.FORMAT.DOT_TIME),
                        endAt: dayjs(new Date(response.endAt)).format(DATE.FORMAT.DOT_TIME)
                    }
                    setDetailInfo(detailInfo)
                }

                break;
            default:
                break;
        }
    }, [pollDetailRequest.state, pollDetailRequest.error, pollDetailRequest.response]);

    useEffect(() => {
        switch (checkPollRequest.state) {
            case API_STATE.done:
                if (checkPollRequest.response.success) {
                    if (inspectionState.return) {
                        setOnInspectionModal(false)
                    }
                    setOnCompleteModal(true)
                }
                break;
            default:
                break;
        }
    }, [checkPollRequest.state, checkPollRequest.error, checkPollRequest.response]);

    // const handleCheckPoll = (isPassed) => {
    //     checkPass = isPassed;
    //
    //     let params = {
    //         isPassed
    //     }
    //
    //     if (!isPassed) {
    //         params = {
    //             ...params,
    //             // returnReason: 'returnReason' // 추후 적용 예정
    //             reasonForReturn: 'inspectionReason' // 추후 적용 예정
    //         }
    //     }
    //     checkPollRequest.call(params, 'body');
    // }

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <SurveyManageDetail detailInfo={detailInfo} type="inspection" onThumbnail={onThumbnail}
                                        setOnThumbnail={setOnThumbnail} openInspectionModal={openInspectionModal}
                                        pollAuth={pollAuth} openCompleteModal={openCompleteModal}
                                        handelInspectionCheck={handelInspectionCheck}
                    />
                    {/*{*/}
                    {/*    pollAuth.some(d => d === "W") &&*/}
                    {/*    <ButtonRight>*/}
                    {/*        /!*<Button mini line onClick={handleCheckPoll.bind(null, false)}>검수 반려</Button>*!/*/}
                    {/*        <Button mini line onClick={() => openInspectionModal(false)}>검수 반려</Button>*/}
                    {/*        <Button mini line onClick={() => openInspectionModal(true)}>검수 완료</Button>*/}
                    {/*        /!*<Button mini line onClick={handelInspectionState}>검수 완료</Button>*!/*/}
                    {/*        <Button mini type='submit'>수정하기</Button>*/}
                    {/*    </ButtonRight>*/}
                    {/*}*/}
                </MenuInfoArea>
            </MenuArea>
            {onInspectionModal &&
                <StyledModal>
                    <ModalContainer Inspection checkPass={inspectionState.return}>
                        <RewardDetailTitle Inspection>검수 관리</RewardDetailTitle>
                        <RewardDetailContent>
                            <span>{`[${detailInfo.title}]`}</span>
                            <RewardDetailContent Inspection>
                                <span>아래에 사유를 입력해주세요.</span>
                                <InspectionReasonSpan id='inspectionReason'
                                                      name='inspectionReason'
                                                      margin='16px 0 0 0'
                                                      value={inspectionReason}
                                                      onChange={(e) => setInspectionReason(e.target.value)}
                                />
                            </RewardDetailContent>
                        </RewardDetailContent>
                        <Wrapper margin='48px 0 0 0' Row>
                            <GrayButton mini
                                        type='button'
                                        onClick={() => closeInspectionModal()}>취소</GrayButton>
                            <Button mini type='button'
                                    onClick={() => handelInspectionReturn(false)}>확인</Button>
                        </Wrapper>
                        <CloseButton type='button' onClick={closeInspectionModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }
            {onCompleteModal &&
                <StyledModal>
                    <ModalContainer Inspection>
                        <RewardDetailTitle Inspection>검수 관리</RewardDetailTitle>
                        <RewardDetailContent>
                            <span>{`[${detailInfo.title}]`}</span>
                            {inspectionState.return ?
                                <span>검수가 반려되었습니다.</span>
                                : <span>검수가 완료됐습니다.</span>
                            }
                        </RewardDetailContent>
                        <CenterWrapper margin='48px 0 0 0' Center>
                            {/*<GrayButton mini type='button'*/}
                            {/*            onClick={() => closeCompleteModal()}*/}
                            {/*>취소</GrayButton>*/}
                            <Button mini type='button'
                                    onClick={() => closeCompleteModal(true)}
                                // onClick={inspectionState.return ? () => handelInspectionState(false) : () => closeInspectionModal()}
                            >확인</Button>
                        </CenterWrapper>
                        <CloseButton type='button' onClick={() => closeCompleteModal(true)}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }
        </>
    )
}

export default UserSurveyDetail;
