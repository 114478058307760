import React from "react";

import {MenuInfoCont, MyImg, MyLeft} from "pages/Mypage/MypageStyle"

import {
    Button,
    ButtonRight,
    Center,
    List,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl,
    PointListOpenButton
} from "common/styledComponents/commonStyle";
import Bg from 'asset/img/img-back.jpg'

import {GRADE} from "common/constants/user";

const UserDetailUser = ({userInfo, userAuth, openPointModal, ...props}) => {
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

    return (
        <>
            <MenuInfoCont>
                <Center>
                    <MyLeft>
                        <MyImg>
                            {userInfo.profileImageUrl && userInfo.profileImageUrl.includes('amazonaws') ?
                                <img src={userInfo.profileImageUrl} alt='profileImage'/>
                                : <img src={userInfo.profileImageUrl ? `${domain}${userInfo.profileImageUrl}` : Bg}
                                       alt='profileImage'/>
                            }
                        </MyImg>
                        {/*<MyNickBox>*/}
                        {/*    <MyNick>닉네임</MyNick><MyNickUser>{userInfo.userId}</MyNickUser>*/}
                        {/*</MyNickBox>*/}
                    </MyLeft>
                    <List>
                        <ListUl>
                            <ListLi>
                                <ListLtxt>닉네임</ListLtxt>
                                <ListRtxt>{userInfo.userId}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>회원등급</ListLtxt>
                                <ListRtxt>{GRADE[userInfo.grade]}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>월렛 주소</ListLtxt>
                                <ListRtxt>{userInfo.walletAddress}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>포인트</ListLtxt>
                                <ListRtxt>
                                    <PointListOpenButton onClick={openPointModal} type='button'>
                                        {Number(userInfo.totalPoint).toLocaleString()}Berry
                                    </PointListOpenButton>
                                </ListRtxt>
                            </ListLi>
                        </ListUl>
                    </List>
                </Center>
            </MenuInfoCont>
            <ButtonRight mini>
                {
                    userAuth.some(d => d === "W") &&
                    <Button mini>수정 하기</Button>
                }

            </ButtonRight>
        </>
    )
}

export default UserDetailUser;
