import React, {useMemo, useState} from "react";
import {
  BackBtn,
  Button,
  ButtonRight,
  DropdownIndicator,
  List,
  ListInput,
  ListLi,
  ListLtxt,
  ListRspan,
  ListRspanImg,
  ListRtxt,
  ListUl,
  MenuInfoArea,
  PreviewButton,
  Title,
  Wrapper
} from "../../styledComponents/commonStyle";
import {ListInputTxtCount} from "pages/ServeyManage/ChannelSurvey/channelSurveySytle"
import {MenuInfoCont} from "../../../pages/Mypage/MypageStyle";
import Editor from "common/components/Editor"
import {Controller} from 'react-hook-form';
import {
  NOTICE_SEARCH_KEY_OPTIONS,
  NOTICE_TYPE_STATUS,
  STATUS,
  STATUS_OPTIONS,
  TOS_TITLE_OPTIONS
} from '../../constants/noticeBoard';
import Select from 'react-select';
import PostModal from '../../../pages/Post/PostModal/PostModal';

function PostListDetail({...props}) {
  const {
    title,
    info,
    register,
    navigate,
    watch,
    boardAuth,
    control,
    limitLength,
    type,
    onRemove,
  } = props;
  const [onPreviewModal, setOnPreviewModal] = useState(false);


  const customStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: 168,
        border: "1px solid #757575",
        borderRadius: "8px",
        background: '#F5F5F5',
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
    }),
    [],
  );

  const SelectCustomMediumStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: 280,
        border: "1px solid #757575",
        borderRadius: "8px",
        backgroundColor: "#F5F5F5",
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
    }),
    [],
  );

  const SelectCustomTitleStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: '100%',
        border: "1px solid #757575",
        borderRadius: "8px",
        backgroundColor: "#F5F5F5",
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
    }),
    [],
  );


  const openModal = () => {
    setOnPreviewModal(true)
    document.body.style.overflow = 'hidden';
  }

  const closeModal = () => {
    setOnPreviewModal(false)
    document.body.style.overflow = 'auto';
  }

  return (
    <>
      <MenuInfoArea>
        <Title>
          <BackBtn onClick={() => navigate(-1)}/>
          {title}
        </Title>
        <MenuInfoCont survey>

          <List wide>
            <ListUl>
              <ListLi>
                <Wrapper>
                  <Wrapper Center>
                    <ListLtxt>구분</ListLtxt>
                    <Wrapper width='auto' margin='0 0 0 24px'>
                      <ListRspan text>{info.type}</ListRspan>
                      {type === 'notice' &&
                        <Wrapper width='140px' Row>
                          <input type="checkbox" {...register('isTop')} id='isTop'/>
                          <label htmlFor='isTop'>상위 노출하기</label>
                        </Wrapper>
                      }
                    </Wrapper>
                  </Wrapper>
                  <Wrapper margin='0 0 0 auto' width='auto' Center>
                    <ListRspanImg>게시글 상태</ListRspanImg>
                    <div>
                      <Controller
                        name="status"
                        control={control}
                        render={({field: {onChange}}) =>
                          <Select
                            options={STATUS_OPTIONS}
                            styles={customStyles}
                            components={{DropdownIndicator}}
                            placeholder={STATUS[info.status]}
                            isSearchable={false}
                            onChange={selectedOption => {
                              onChange(selectedOption.value);
                            }}
                          />
                        }
                        // rules={{required: true}}
                      />
                    </div>
                  </Wrapper>
                </Wrapper>
              </ListLi>
              {type === 'notice' &&
                <ListLi>
                  <ListLtxt>카테고리</ListLtxt>
                  <ListRtxt>
                    <Controller
                      name="noticeType"
                      control={control}
                      render={({field: {onChange}}) =>
                        <Select
                          components={{DropdownIndicator}}
                          options={NOTICE_SEARCH_KEY_OPTIONS.slice(1)}
                          placeholder={NOTICE_TYPE_STATUS[info.noticeType]}
                          styles={SelectCustomMediumStyles}
                          isSearchable={false}
                          onChange={selectedOption => {
                            onChange(selectedOption.value);
                          }}
                        />
                      }
                      // rules={{required: true}}
                    />
                  </ListRtxt>
                </ListLi>
              }
            </ListUl>
          </List>
        </MenuInfoCont>
        <MenuInfoCont noline>
          <List wide>
            <ListUl>
              <ListLi>
                <ListLtxt>제목</ListLtxt>
                <ListRtxt Max>
                  {type === 'tos' ?
                    <Controller
                      name="title"
                      control={control}
                      render={({field: {onChange}}) =>
                        <Select
                          components={{DropdownIndicator}}
                          options={TOS_TITLE_OPTIONS}
                          styles={SelectCustomTitleStyles}
                          onChange={selectedOption => {
                            onChange(selectedOption.value);
                          }}
                          placeholder={info && info.title}
                        />
                      }
                      // rules={{required: true}}
                    />
                    : <>
                      <ListInput
                        wide
                        type="text"
                        placeholder=""
                        {...register('title', {onChange: limitLength})}
                      />
                      <ListInputTxtCount In>
                        {watch.title ? watch.title.length : 0}/40자
                      </ListInputTxtCount>
                    </>
                  }


                </ListRtxt>
              </ListLi>
              <ListLi>
                <ListLtxt top>내용</ListLtxt>
                <ListRtxt Max>
                  <Controller
                    name="contents"
                    control={control}
                    render={({field: {onChange, value}}) =>
                      <Editor placeholder={'내용을 입력 해주세요'} setEditorData={onChange}
                              editorDate={value}/>
                    }
                    // rules={{required: true}}
                  />
                  {/*<Editor editorDate={editorDate} setEditorData={setEditorData}/>*/}
                </ListRtxt>
              </ListLi>
            </ListUl>
          </List>
          {
            boardAuth.some(d => d === "W") &&
            <ButtonRight>
              <PreviewButton mini preview type='button' onClick={openModal}
                             disabled={watch.type === 'WEB'}>미리보기</PreviewButton>
              <Button mini line type='button' onClick={onRemove}>삭제하기</Button>
              <Button mini>수정하기</Button>
            </ButtonRight>
          }
        </MenuInfoCont>
        {onPreviewModal &&
          <PostModal type={type} title={watch.title} contents={watch.contents} createdAt={watch.createdAt}
                     closeModal={closeModal}/>
        }
      </MenuInfoArea>
    </>
  );
}

export default PostListDetail;
