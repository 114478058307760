import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";

import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";

import Select from 'common/components/CommonSelect'
import ListSearch from "common/components/ListSearch"
import SearchFilter from "common/components/SearchFilter"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"

import DATE from "common/constants/date";

import {
    DateSearch,
    SearchConditionBox,
    SearchConditionDiv,
    SearchConditionText,
    SearchDateBox,
    SearchDateInput,
    SearchDateInputSpan,
    SearchDateMid,
    SearchDateTxt
} from "../userSurveySytle";

import {
    DATE_FILTER_OPTIONS,
    POINTMNG_EXCHANGEMNG_STATE_LIST,
    STATUS,
    USER_SEARCH_FILTER_OPTIONS,
    USER_SEARCH_KEY_OPTIONS_TWO
} from "common/constants/poll";

const INIT_OPTION = {
    BOARD_PARAMS: {
        offset: 0,
        limit: 10
    },
    KEY: '',
    DATE_FILTER_OPTION: '1M',
    // START_AT: '2022-12-19',
    // END_AT: '2023-01-19'
}

const ExchangeMng = () => {
    const navigate = useNavigate();
    const [reqParams, setReqParams] = useState({});
    const [searchKey, setSearchKey] = useState(INIT_OPTION.KEY);
    const [dateFilterOption, setDateFilterOption] = useState(INIT_OPTION.DATE_FILTER_OPTION);
    const request = useRequest("get", "point/exchange/list");
    const [searchParams, setSearchParams] = useSearchParams();


    console.log(" point/exchange/list >>> ", request)

    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({offset: 0, limit: 10});
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);

    const refStartAt = useRef(null);
    const refEndAt = useRef(null);

    const [_cStartAt, setCStartAt] = useState(null);
    const [_cEndAt, setCEndAt] = useState(null);

    const getSearchDate = () => {
        const now = dayjs(new Date());
        let date = {
            startAt: now,
            endAt: now
        };
        switch (dateFilterOption) {
            case '1M':
                date.startAt = now.subtract(1, 'month');
                break;
            case '3M':
                date.startAt = now.subtract(3, 'month');
                break;
            case '6M':
                date.startAt = now.subtract(6, 'month');
                break;
            case '1Y':
                date.startAt = now.subtract(1, 'year');
                break;
            case 'SELECT':
                date.startAt = dayjs(refStartAt.current.value);
                date.endAt = dayjs(refEndAt.current.value);
                break;
            default:
                date.startAt = now;
                date.endAt = now;
                break;
        }
        return {
            startAt: date.startAt.format(DATE.FORMAT.DASH),
            endAt: date.endAt.format(DATE.FORMAT.DASH)
        }

    }

    const {startAt, endAt} = getSearchDate();

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            status: STATUS[data.status],
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            startAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            endAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
        }));
    }, {
        ...INIT_OPTION.BOARD_PARAMS,
        startAt,
        endAt
        // ...getSearchDate()
    });

    const handleSelectRow = (row) => {
        navigate(`detail/${row.id}`);
    }

    const handleChangeSearchKey = (value) => {
        setSearchKey(value);
    }

    const handleSearch = (data) => {
        const {text, selectedOption} = data;
        const {startAt, endAt} = getSearchDate();
        let reqParams = {
            status: selectedOption,
            startAt,
            endAt
        };
        if (searchKey === 'name') {
            reqParams.name = text;
        }
        if (searchKey === 'title') {
            reqParams.title = text;
        }
        const {offset, limit} = params;
        fetch({
            ...reqParams,
            offset,
            limit
        });
    }

    const handleDateFilterSelect = (option) => {
        setDateFilterOption(option);
        const {startAt, endAt} = getSearchDate();
        refStartAt.current.value = startAt;
        refEndAt.current.value = endAt;
    }

    const handleChangeStartAt = () => {
        const {startAt} = getSearchDate();
        refStartAt.current.value = startAt;
        setCStartAt(startAt);
    }

    const handleChangeEndAt = () => {
        const {endAt} = getSearchDate();
        refEndAt.current.value = endAt;
        setCEndAt(endAt);
    }

    const handlePage = (pageNum) => {
        setCurPage(pageNum)
        setFormData({...formData, page: pageNum})
    }

    useEffect(() => {
        const {offset} = formData;
        if (offset === params.offset) return;
        fetch({
            ...params,
            offset
        });
    }, [formData]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>환전 거래내역</Title>
                    <DateSearch>
                        <SearchConditionBox>
                            <SearchConditionText top>회원구분</SearchConditionText>
                            <Select mid options={USER_SEARCH_KEY_OPTIONS_TWO} initValue={searchKey}
                                    onChange={handleChangeSearchKey}/>
                        </SearchConditionBox>
                        <SearchConditionBox>
                            <SearchConditionText>조회 기간</SearchConditionText>
                            <SearchConditionDiv>
                                <SearchFilter options={DATE_FILTER_OPTIONS} option={dateFilterOption}
                                              onSelect={handleDateFilterSelect} type="filter"/>
                                <SearchDateBox>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         max={endAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refStartAt}
                                                         onChange={() => handleChangeStartAt()}></SearchDateInput>
                                        <SearchDateTxt>{startAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                    <SearchDateMid>~</SearchDateMid>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refEndAt}
                                                         onChange={() => handleChangeEndAt()}></SearchDateInput>
                                        <SearchDateTxt>{endAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                </SearchDateBox>
                            </SearchConditionDiv>
                        </SearchConditionBox>
                    </DateSearch>
                    <ListSearch options={USER_SEARCH_FILTER_OPTIONS} onSearch={handleSearch}/>
                    <Table pageData={formData} totalPages={pageInfo?.totalPages}
                           columns={POINTMNG_EXCHANGEMNG_STATE_LIST} rows={rows} onSelectRow={handleSelectRow}
                           searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    )
}

export default ExchangeMng;
