import React from "react";
import {ListInput} from "common/styledComponents/commonStyle";
import {ListInputTxtCount} from "pages/ServeyManage/ChannelSurvey/channelSurveySytle"

const TestInput = ({value, onBlur, withLength, maxLength, onChange, ...rest}) => {
    // const [_value, _setValue] = useState(value);

    const handleBlur = () => {
        onBlur && onBlur(value);
    }

    // useEffect(() => {
    //     _setValue(value);
    // }, [value]);

    // const handleValue = (e) => {
    //     console.log(e.target.value.length)
    //     if (e.target.value.length > 20) {
    //         onChange(e.target.value.substr(0, 20))
    //     } else {
    //         onChange(e)
    //     }
    // }

    return (
        <>
            <ListInput long
                       type="text"
                       placeholder=""
                       value={value}
                       maxLength={maxLength}
                       onChange={onChange}
                       onBlur={handleBlur}
                       {...rest}
            />
            {withLength && value && value.length ?
                <ListInputTxtCount>{value && value.length}/{maxLength}자</ListInputTxtCount>
                : <ListInputTxtCount>0/{maxLength}자</ListInputTxtCount>
            }
        </>
    )
}

export default TestInput;
