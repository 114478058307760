import styled from "styled-components";

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


export const LoginCont = styled.div`
  margin: 0 auto;
  width: 400px;
`;
export const LoginLogo = styled.p`
  margin: 0 auto;
  width: 400px;

  img {
    width: 100%
  }
`;

export const LoginInputCont = styled.div`
  padding: 48px 0 64px;
`;

export const LoginInputDiv = styled.div`
  background-color: white;
  position: relative;
  padding: 12px 16px 12px 44px;
  max-width: 380px;
  border: 1px solid #000065;
  border-radius: 8px;

  & + & {
    margin-top: 24px;
  }
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  font-size: 16px;

  &:placeholder {
    color: #757575;
  }
`;

export const InputIcon = styled.span`
  background-image: url(${(props) => props.img});
  width: 24px;
  height: 24px;
  background-size: cover;
  position: absolute;
  left: 12px;
  top: 12px;
`;

export const ErrorMsg = styled.p`
  font-size: 12px;
  color: #E92928;
  position: absolute;
  bottom: -25px;
  left: 0;
`;

export const GrayMiniMsg = styled.p`
  font-size: 12px;
  text-align: right;
  margin-top: 8px;
`;
export const GrayMiniMsgClick = styled.a`
  color: #757575;
`;
