import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {MenuArea, MenuInfoArea, Title, Wrapper} from "common/styledComponents/commonStyle";
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {
  SearchConditionDiv,
  SearchDateInput,
  SearchDateInputSpan,
  SearchDateMid,
  SearchDateTxt
} from "../PointManage/userSurveySytle";
import {
  AdRewardFilterSection,
  AdTotalReward,
  FilterTitle,
  RewardAmount,
  RewardAmountWrapper,
  RewardDenom,
  TotalAmount
} from './AdRewardManagementPage.style';
import Table from '../../common/components/Table';
import {
  AD_REWARD_LIST_COLUMN,
  AD_REWARD_LIST_TYPE_OPTIONS,
  AD_REWARD_TYPE,
  DATE_FILTER_OPTIONS
} from '../../common/constants/point';
import SearchFilter from '../../common/components/SearchFilter';
import ListSearch from '../../common/components/ListSearch';

const INIT_OPTION = {
  BOARD_PARAMS: {
    offset: 0,
    limit: 5
  },
  KEY: '',
  DATE_FILTER_OPTION: 'ALL',
}

const AdRewardManagementPage = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get('page')
  const queryName = searchParams.get('name') || ''
  const queryStartAt = searchParams.get('startAt') || ''
  const queryEndAt = searchParams.get('endAt') || ''
  const queryType = searchParams.get('type') || ''
  const queryDateOption = searchParams.get('dateOption') || 'ALL'
  const [dateFilterOption, setDateFilterOption] = useState(INIT_OPTION.DATE_FILTER_OPTION);
  const request = useRequest("get", "point/ad/list");
  const [totalNum, setTotalNum] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [curPage, setCurPage] = useState(null);
  const [formData, setFormData] = useState({
    offset: queryPage ? queryPage - 1 : 0,
    limit: 5,
    page: queryPage || 1,
    name: queryName || '',
    startAt: queryStartAt ? queryStartAt : '',
    endAt: queryEndAt ? queryEndAt : '',
    type: queryType ? queryType : '',
  });
  const pageInfo = pageMaker(curPage, formData.limit, totalNum);
  const refStartAt = useRef(null);
  const refEndAt = useRef(null);
  const [selectedOption, setSelectedOption] = useState(queryType || '');

  const getSearchDate = () => {
    const now = dayjs(new Date());
    let date = {
      startAt: now,
      endAt: now
    };
    switch (dateFilterOption) {
      case '1M':
        date.startAt = now.subtract(1, 'month');
        break;
      case '3M':
        date.startAt = now.subtract(3, 'month');
        break;
      case '6M':
        date.startAt = now.subtract(6, 'month');
        break;
      case '1Y':
        date.startAt = now.subtract(1, 'year');
        break;
      case 'SELECT':
        date.startAt = refStartAt.current.value ? dayjs(refStartAt.current.value) : now;
        date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
        break;
      case 'ALL':
        date.startAt = null;
        date.endAt = null;
        break;
      default:
        date.startAt = now;
        date.endAt = now;
        break;
    }

    return {
      startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
      endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
    }
  }

  const {startAt, endAt} = getSearchDate();

  const handleSearch = (data) => {
    const {startAt, endAt} = getSearchDate();
    const {type, limit} = formData;
    const {text} = data;
    searchParams.set('type', type)
    searchParams.set('name', text)
    searchParams.set('page', '1')
    setCurPage(1)

    if (startAt && endAt) {
      searchParams.set('startAt', startAt)
      searchParams.set('endAt', endAt)
      if (dateFilterOption) {
        searchParams.set('dateOption', dateFilterOption)
      }
    }

    if (text) {
      searchParams.set('name', text)
    }

    setFormData({
      ...formData,
      page: 1,
      offset: 0,
      startAt: startAt,
      endAt: endAt,
      dateOption: dateFilterOption,
      name: text,
    })

    setSearchParams(searchParams)

    fetch({
      name: text || '',
      type: type || '',
      startAt: startAt || '',
      endAt: endAt || '',
      page: 1,
      offset: 0,
      limit
    });
  }

  const {rows, fetch, params} = useBoard(request, (res) => {
    setTotalNum(res.totalCount);
    setTotalAmount(res.totalAmount)
    const {data} = res;
    return data && data.map((data) => ({
      ...data,
      createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
      type: AD_REWARD_TYPE[data.type],

    }));
  }, {
    ...INIT_OPTION.BOARD_PARAMS,
    startAt,
    endAt
    // ...getSearchDate()
  });

  const handleSelectRow = (row) => {
    navigate(`detail/${row.id}?page=${curPage}`);
  }

  const handleDateFilterSelect = (option) => {
    setDateFilterOption(option);
    const {startAt, endAt} = getSearchDate();
    refEndAt.current.value = endAt;

    if (option === 'SELECT') {
      refStartAt.current.value = dayjs().format(DATE.FORMAT.DASH);
    } else {
      refStartAt.current.value = startAt;
    }

    setFormData(prevState => ({...prevState, startAt: startAt, endAt: endAt, dateOption: option}))
  }


  const handleChangeStartAt = () => {
    const {startAt} = getSearchDate();
    refStartAt.current.value = startAt;
    searchParams.set('startAt', startAt)
    setFormData({...formData, startAt: startAt})
  }

  const handleChangeEndAt = () => {
    const {endAt} = getSearchDate();
    refEndAt.current.value = endAt;
    searchParams.set('endAt', endAt)
    setFormData({...formData, endAt: endAt})
  }

  const handlePage = (pageNum) => {
    searchParams.set('page', pageNum)
    setFormData({...formData, page: pageNum, offset: pageNum - 1, name: queryName})
    setSearchParams(searchParams)
    setCurPage(pageNum)
  };

  const handleTypeSelect = (option) => {
    setSelectedOption(option);
    setFormData({...formData, type: option})
  }

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      page: Number(queryPage) || 1,
      offset: queryPage ? Number(queryPage) - 1 : 0,
      type: queryType || '',
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      dateOption: queryDateOption || 'ALL',
      name: queryName || '',
    }))

    setDateFilterOption(queryDateOption)
    setSelectedOption(queryType)

    const fetchOptions = {
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      limit: 5,
      name: queryName || '',
      type: queryType || '',
    }

    fetch(fetchOptions)

  }, [queryPage]);

  useEffect(() => {
    setFormData({...formData, page: curPage, offset: curPage - 1})
  }, [curPage])

  useEffect(() => {
    setCurPage(parseInt(searchParams.get('page')) || 1)
  }, [])

  return (
    <>
      <MenuArea>
        <MenuInfoArea>
          <Wrapper width={'auto'} Column>
            <Title>광고 보상관리</Title>
            <Wrapper width={'auto'} height={'210px'} Row margin={'24px 0 0 0'}>
              <AdTotalReward>
                <TotalAmount>총 지급 보상금</TotalAmount>
                <RewardAmountWrapper>
                  <RewardAmount>{totalAmount.toLocaleString()}</RewardAmount>
                  <RewardDenom>Berry</RewardDenom>
                </RewardAmountWrapper>
              </AdTotalReward>
              <AdRewardFilterSection Column width={'auto'}>
                <Wrapper width={'auto'} Center gap={'24px'}>
                  <FilterTitle>조회 기간</FilterTitle>
                  <SearchConditionDiv>
                    <Wrapper width={'auto'} Center gap={'24px'}>
                      <SearchFilter options={DATE_FILTER_OPTIONS} option={dateFilterOption}
                                    onSelect={handleDateFilterSelect} type="filter"/>
                      <Wrapper width={'auto'}>
                        <SearchDateInputSpan>
                          <SearchDateInput type="date"
                                           max={endAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                           ref={refStartAt}
                                           onChange={() => {
                                             handleChangeStartAt()
                                           }}/>
                          <SearchDateTxt>{startAt || startAt === '' ? startAt : formData.startAt}</SearchDateTxt>
                        </SearchDateInputSpan>
                        <SearchDateMid>~</SearchDateMid>
                        <SearchDateInputSpan>
                          <SearchDateInput type="date"
                                           min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                           ref={refEndAt}
                                           onChange={() => handleChangeEndAt()}/>
                          <SearchDateTxt>{endAt || endAt === '' ? endAt : formData.endAt}</SearchDateTxt>
                        </SearchDateInputSpan>
                      </Wrapper>
                    </Wrapper>
                  </SearchConditionDiv>
                </Wrapper>
                <Wrapper width={'auto'} gap={'77px'} Center>
                  <FilterTitle>구분</FilterTitle>
                  <Wrapper width={'auto'}>
                    <SearchFilter options={AD_REWARD_LIST_TYPE_OPTIONS}
                                  option={selectedOption}
                                  onSelect={handleTypeSelect}
                    />
                  </Wrapper>
                </Wrapper>
                <Wrapper width={'auto'} gap={'40px'} Center>
                  <FilterTitle>검색어</FilterTitle>
                  <ListSearch
                    // initOption={queryStatus ? queryStatus : formData.status}
                    inputValue={formData.name}
                    onChange={(e) => setFormData(prevState => ({...formData, name: e.target.value}))}
                    onSearch={handleSearch}
                    margin={'0'}
                  />
                </Wrapper>
              </AdRewardFilterSection>
            </Wrapper>
            <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={AD_REWARD_LIST_COLUMN}
                   rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum}/>
            <Page pageInfo={pageInfo} handlePage={handlePage}/>
          </Wrapper>
        </MenuInfoArea>
      </MenuArea>
    </>
  )
}

export default AdRewardManagementPage;
