import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";

import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"

import {PUZZLE_TOKEN_LIST_COLUMNS} from "common/constants/user";
import DATE from "common/constants/date";

import {useGlobalStore} from "stores";

const PuzzleToken = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryName = searchParams.get('name');
    const queryPage = searchParams.get('page');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        name: queryName || '',
    });
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", "token/puzzle/list");
    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res?.totalCount);
        return res && res.data != null && res.data.map((data) => ({
            ...data,
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            joinedAt: dayjs(new Date(data.joinedAt)).format(DATE.FORMAT.DOT)
        }));
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    const userAuth = useMemo(() => {
        const accessAuth = info?.access?.userAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleSelectRow = (row) => {
        // navigate(`/puzzleToken/detail/${row.id}`);
        // navigate(`puzzleToken/detail/${row.id}?page=${curPage}`);
    }

    const handleSearch = (data) => {
        const {text} = data;
        const {limit} = formData;

        if (text || text === '') {
            searchParams.set('name', text);
            searchParams.set('page', '1')
            setCurPage(1)
        }

        setSearchParams(searchParams)

        fetch({
            name: text || '',
            offset: 0,
            limit
        });
    };

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
        setCurPage(pageNum)
    };

    useEffect(() => {
        setSearchParams(searchParams)

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        })

    }, [queryPage, queryName]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>퍼즐토큰 관리
                        {
                            userAuth.find(d => d === "W") &&
                            <Button tit onClick={() => navigate('/puzzleToken/management')}>퍼즐토큰 항목관리</Button>
                        }
                    </Title>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.name}
                                onChange={(e) => setFormData(prevState => ({...formData, name: e.target.value}))}
                    />
                    <Table pageData={formData} totalNum={totalNum} columns={PUZZLE_TOKEN_LIST_COLUMNS} rows={rows}
                           onSelectRow={handleSelectRow} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>

                </MenuInfoArea>
            </MenuArea>

            {/*
             <PopUp>
             <PopBox puzzle>
             <PopClose><img src={CloseIcon} alt='' /></PopClose>
             <PopHeadTxt>퍼즐토큰 항목 등록</PopHeadTxt>
             <PopTxt>
             수정한 정보를 적용할 일시를 설정해주세요.
             </PopTxt>
             <PopPollListBox puzzle>
             <PopPollList>
             <PopPollListTxtL>적용 날짜</PopPollListTxtL><PopPollListTxtR center>YYYY-MM-DD </PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL>적용 시간</PopPollListTxtL><PopPollListTxtR center>HH:MM</PopPollListTxtR>
             </PopPollList>
             </PopPollListBox>
             <PopButton big>확인</PopButton>
             </PopBox>
             </PopUp> */}


        </>
    )
}

export default PuzzleToken;
