import styled from 'styled-components';

export const DropDownContainer = styled.div`
  background-color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
`;

export const ImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: rgba(255, 0, 0, 0.5);
  margin-right: 8px;
`;

export const SideBarMenu = styled.div`
  padding: 16px 12px 16px 16px;
  display: flex;

  span {
    width: 120px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #424242;
    margin-right: 8px;
  }
`;

export const MenuItemList = styled.ul`
  background-color: #CDCDCD66;

`;

export const MenuItem = styled.li`
  padding: 10px 8px 10px 48px;
  background-color: rgba(205, 205, 205, 0.4);
  display: flex;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

