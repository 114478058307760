import React, {useMemo} from 'react';
import {MenuInfoCont, MenuInfoSub} from '../../../pages/Mypage/MypageStyle';
import {
    Button,
    ChannelDetailThumbnailImage,
    ChannelDetailThumbnailImageWrapper,
    CustomHeader,
    DropdownIndicator,
    GrayButton,
    List,
    ListLi,
    ListLtxt,
    ListRspan,
    ListRtxt,
    ListRtxtSubNum,
    ListRtxtSubText,
    ListUl,
    RewardRadioWrapper,
    StyledDatePicker,
    Wrapper
} from '../../styledComponents/commonStyle';
import ArrowRight from '../../../asset/img/icon__arrow_forward.png';
import Keywords from '../Keywords';
import CommonSelect from 'common/components/CommonSelect'
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import ko from 'date-fns/locale/ko'
import TestInput from '../TestInput';
import TestTextarea from '../TestTextarea';
import parseISO from 'date-fns/parseISO'
import {ListInputBerry} from '../../../pages/ServeyManage/ChannelSurvey/channelSurveySytle';
import ThumbnailsV2 from '../Thumbnails_v2/Thumbnails_v2';


function CommonSurveyDetail({...props}) {

    const {
        filterEndAtTime,
        type,
        detailInfo,
        control,
        watch,
        handlePaidUnits,
        setOnThumbnail,
        domain,
        openDetailModal,
        today,
        categoryList,
        setCategoryId,
        keywords,
        previewImages,
        thumbnails,
        handleChangeThumbnail,
        handleThumbnailRepresentative,
        removeThumbnail,
        onThumbnail,
        handleChangeKeyword,
        openCompleteReasonModal,
        openUserPollStopReasonModal
    } = props;

    const SelectCustomMediumStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 280,
                border: "1px solid #757575",
                borderRadius: "8px",
                backgroundColor: "#F5F5F5",
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    return (
        <>
            <MenuInfoCont survey>
                <MenuInfoSub>기본 정보</MenuInfoSub>
                <List wide>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>설문 제목</ListLtxt>
                            <ListRtxt Max={type === 'user'}>
                                {(type && (type === 'user')) || (watch.status === 'COMPLETE' || watch.status === 'EXPIRED' || watch.status === 'EXHAUSTED') ?
                                    <>{detailInfo.title}</> :
                                    <>
                                        <Controller
                                            name="title"
                                            control={control}
                                            render={({field: {onChange, value}}) =>
                                                <TestInput
                                                    value={value}
                                                    withLength={true}
                                                    maxLength={30}
                                                    onChange={(e) => {
                                                        if (e.target.value.length > 20) {
                                                            onChange(e.target.value.slice(0, 30))
                                                        } else {
                                                            onChange(e)
                                                        }
                                                    }}
                                                />
                                            }
                                            // rules={{required: true}}
                                        />
                                    </>
                                }
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt top>설문 설명</ListLtxt>
                            <ListRtxt Max={type === 'user'}>
                                {(type && (type === 'user')) || (watch.status === 'COMPLETE' || watch.status === 'EXPIRED' || watch.status === 'EXHAUSTED') ?
                                    <>{detailInfo.contents}</> : <>
                                        <Controller
                                            name="contents"
                                            control={control}
                                            render={({field: {onChange, value}}) =>
                                                <TestTextarea
                                                    value={value}
                                                    withLength={true}
                                                    maxLength={1000}
                                                    onChange={(e) => {
                                                        if (e.target.value.length > 100) {
                                                            onChange(e.target.value.slice(0, 1000))
                                                        } else {
                                                            onChange(e.target.value)
                                                        }
                                                    }}
                                                />
                                            }
                                            // rules={{required: true}}
                                        />
                                    </>
                                }
                                {/*{type && (type === 'channel' || type === 'inspection') &&*/}
                                {/*    <>*/}
                                {/*        <Controller*/}
                                {/*            name="contents"*/}
                                {/*            control={control}*/}
                                {/*            render={({field: {onChange}}) =>*/}
                                {/*                <TestTextarea*/}
                                {/*                    value={watch ? watch.contents : ''}*/}
                                {/*                    withLength={true}*/}
                                {/*                    maxLength={400}*/}
                                {/*                    onChange={(e) => {*/}
                                {/*                        if (e.target.value.length > 100) {*/}
                                {/*                            onChange(e.target.value.substr(0, 400))*/}
                                {/*                        } else {*/}
                                {/*                            onChange(e.target.value)*/}
                                {/*                        }*/}
                                {/*                    }}*/}
                                {/*                />*/}
                                {/*            }*/}
                                {/*            rules={{required: true}}*/}
                                {/*        />*/}
                                {/*    </>*/}
                                {/*}*/}
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <Wrapper>
                                <ListLtxt top>설문 기간</ListLtxt>
                                {/*{!type &&*/}
                                {/*    <ListRtxt>*/}
                                {/*        <ListRspan date>*/}
                                {/*            <ListInput type="datetime-local" onChange={props.handleChangeStartAt}*/}
                                {/*                       value={props.startAt} min={props.today}/>*/}
                                {/*        </ListRspan>*/}
                                {/*        <ListRspan date><img src={ArrowRight} alt=""/></ListRspan>*/}
                                {/*        <ListRspan date>*/}
                                {/*            <ListInput type="datetime-local" onChange={props.handleChangeEndAt}*/}
                                {/*                       value={props.endAt} min={props.startAt}*/}
                                {/*            />*/}
                                {/*        </ListRspan>*/}
                                {/*    </ListRtxt>*/}
                                {/*}*/}
                                {(type && (type === 'user')) || (watch.status === 'COMPLETE' || watch.status === 'EXPIRED') ?
                                    <Wrapper width='auto' margin='0 0 0 24px'>
                                        <ListRspan>{detailInfo.startAt}</ListRspan>
                                        <ListRspan>
                                            <img src={ArrowRight} alt=""/>
                                        </ListRspan>
                                        <ListRspan Max>{detailInfo.endAt}</ListRspan>
                                    </Wrapper> :
                                    <>
                                        <div>
                                            <Controller
                                                name="startAt"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <StyledDatePicker
                                                        pollStart
                                                        id="startAt"
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        selected={value}
                                                        onChange={(date, e) => {
                                                            onChange(date, e)
                                                            // onStartAtSelected(date)
                                                        }}
                                                        placeholderText="YYYY-MM-DD HH:mm"
                                                        locale={ko}
                                                        showTimeSelect
                                                        minDate={watch.startAt}
                                                        renderCustomHeader={(params) =>
                                                            <CustomHeader {...params} />}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </div>
                                        <ListRspan><img src={ArrowRight} alt=""/></ListRspan>
                                        <div>
                                            <Controller
                                                name="endAt"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <StyledDatePicker
                                                        pollEnd
                                                        id="endAt"
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        selected={value}
                                                        // selected={watch ? watch.endAt : parseISO(value)}
                                                        onChange={(date, e) => {
                                                            onChange(date)
                                                            // onStartAtSelected(date)
                                                        }}
                                                        placeholderText="YYYY-MM-DD HH:mm"
                                                        locale={ko}
                                                        minDate={watch && watch.startAt ? watch.startAt : parseISO(today)}
                                                        showTimeSelect
                                                        filterTime={filterEndAtTime}
                                                        renderCustomHeader={(params) =>
                                                            <CustomHeader {...params} />}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </div>
                                    </>
                                }
                            </Wrapper>
                        </ListLi>
                        {((type && type !== 'user') || !type) &&
                            <ListLi>
                                <ListLtxt>설문 카테고리</ListLtxt>
                                <ListRtxt>
                                    {type && type === 'user' ?
                                        <CommonSelect big options={categoryList}
                                                      onChange={setCategoryId}/> :
                                        <Controller
                                            name="categoryId"
                                            control={control}
                                            render={({field: {onChange}}) =>
                                                <Select
                                                    components={DropdownIndicator}
                                                    options={categoryList}
                                                    styles={SelectCustomMediumStyles}
                                                    // placeholder={watch.categoryId && watch.categoryId}
                                                    placeholder={(watch && watch.categoryId) && categoryList.filter((item) => item.id === watch.categoryId)[0].label}
                                                    onChange={selectedOption => {
                                                        onChange(selectedOption.id);
                                                    }}
                                                />
                                            }
                                            // rules={{required: true}}
                                        />
                                    }
                                </ListRtxt>
                            </ListLi>
                        }
                        {type !== 'user' &&
                            <ListLi>
                                <ListLtxt what top>설문 키워드</ListLtxt>
                                <ListRtxt Max>
                                    <Controller
                                        name="keywords"
                                        control={control}
                                        render={({field: {onChange}}) =>
                                            <Keywords onChange={handleChangeKeyword}
                                                // initValue={watch && watch.keywords ? watch.keywords : props.keywords}
                                                      initValue={watch ? watch.keywords : keywords}
                                                      withLength={true}
                                            />
                                        }
                                        // rules={{required: true}}
                                    />
                                </ListRtxt>
                            </ListLi>
                        }
                        {
                            type && (type === 'channel' || type === 'user') &&
                            <ListLi>
                                <ListLtxt>설문 참여자</ListLtxt>
                                <ListRtxt>
                                    <ListRspan>{detailInfo.participantsCount && detailInfo.participantsCount.toLocaleString()}</ListRspan>
                                    <ListRspan>명</ListRspan>
                                    <GrayButton short type='button' id='participantsCount'
                                                onClick={(e) => openDetailModal(e)}>상세내역</GrayButton>
                                </ListRtxt>
                            </ListLi>
                        }
                        {type && type === 'user' &&
                            <ListLi>
                                <ListLtxt>설문 초대 회원</ListLtxt>
                                <ListRtxt>
                                    <ListRspan>{detailInfo.invitedUserCount ? detailInfo.invitedUserCount : 0}</ListRspan>
                                    <ListRspan>명</ListRspan>
                                    <GrayButton short type='button' onClick={(e) => openDetailModal(e)}
                                                id='invite'>상세내역</GrayButton>
                                </ListRtxt>
                            </ListLi>
                        }
                        <ListLi>
                            <ListLtxt top>썸네일 이미지</ListLtxt>
                            <ListRtxt>
                                {type !== 'user' &&
                                    <>
                                        {(watch.status === 'COMPLETE' || watch.status === 'EXPIRED' || watch.status === 'EXHAUSTED') ?
                                            <>
                                                {watch.thumbnailImages.map((image) =>
                                                    <ChannelDetailThumbnailImageWrapper
                                                        onClick={() => setOnThumbnail(!onThumbnail)}>
                                                        <ChannelDetailThumbnailImage>
                                                            <img src={`${domain}${image.url}`}
                                                                 alt='thumbnail'/>
                                                        </ChannelDetailThumbnailImage>
                                                    </ChannelDetailThumbnailImageWrapper>
                                                )}
                                            </> :
                                            <ThumbnailsV2
                                                previewImages={previewImages}
                                                thumbnails={thumbnails ? thumbnails : watch.thumbnailImages}
                                                onChange={handleChangeThumbnail}
                                                handleThumbnailRepresentative={handleThumbnailRepresentative}
                                                removeThumbnail={removeThumbnail}
                                            />
                                        }
                                    </>
                                }
                                {type && type === 'user' &&
                                    <>
                                        <ChannelDetailThumbnailImageWrapper
                                            onClick={() => setOnThumbnail(!onThumbnail)}>
                                            {/*<ChannelDetailThumbnailImage {...(isRepresentative && {main: true})}>*/}
                                            <ChannelDetailThumbnailImage>
                                                <img src={`${domain}${detailInfo.thumbnailImageUrl}`} alt='thumbnail'/>
                                            </ChannelDetailThumbnailImage>
                                        </ChannelDetailThumbnailImageWrapper>
                                    </>
                                }
                            </ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
                {type && type === 'channel' &&
                    <>
                        {(watch && (watch.status === 'COMPLETE' || watch.status === 'EXPIRED' || watch.status === 'EXHAUSTED')) ||
                            <Wrapper Right margin='64px 0 0 0'>
                                <>
                                    <Button mini line margin='0 20px 0 0'
                                            onClick={openCompleteReasonModal}
                                            type='button'
                                    >
                                        수동 완료
                                    </Button>
                                    <Button mini>수정하기</Button>
                                </>
                            </Wrapper>
                        }
                        {(watch && (watch.status === 'COMPLETE' || watch.status === 'EXPIRED' || watch.status === 'EXHAUSTED')) &&
                            <Wrapper Right margin='64px 0 0 0'>
                                <Button mini>수정하기</Button>
                            </Wrapper>
                        }
                    </>
                }
                {(type && type === 'user' && detailInfo.status !== 'STOP') &&
                    <Wrapper Right margin='64px 0 0 0'>
                        <Button mini type='button' onClick={openUserPollStopReasonModal}>설문 정지</Button>
                    </Wrapper>
                }
            </MenuInfoCont>
            <MenuInfoCont survey>
                <MenuInfoSub>설문 보상</MenuInfoSub>
                <List wide>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>보상 지급 여부</ListLtxt>
                            {type && (type === 'user' || type === 'channel') ?
                                <ListRtxt>
                                    <span>{watch && watch.isPaid ? '지급' : '지급 안함'}</span>
                                </ListRtxt> :
                                <ListRtxt>
                                    <Controller name='isPaid'
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Wrapper width='304px' Row>
                                                        <RewardRadioWrapper>
                                                            <input type="radio" value="true" id='paid'
                                                                   checked={value}
                                                                   onChange={() => onChange(true)}
                                                            />
                                                            <label htmlFor='paid'>지급</label>
                                                        </RewardRadioWrapper>
                                                        <RewardRadioWrapper>
                                                            <input type="radio" value="false" id='no_paid'
                                                                   checked={value === false}
                                                                   onChange={() => onChange(false)}
                                                            />
                                                            <label htmlFor='no_paid'>지급 안함</label>
                                                        </RewardRadioWrapper>
                                                    </Wrapper>
                                                }
                                    />
                                </ListRtxt>
                            }
                        </ListLi>
                        {watch.isPaid &&
                            <>
                                <ListLi>
                                    <Wrapper>
                                        <ListLtxt what>보상 단위</ListLtxt>
                                        <Wrapper width='auto' margin='0 0 0 24px'>
                                            {(type && type === 'inspection') || !type ?
                                                <>
                                                    <ListRtxtSubText>
                                                        1단계 회원
                                                        <Controller name='paidUnits[0]'
                                                                    control={control}
                                                                    render={({field: {onChange, value}}) =>
                                                                        <ListRspan Rel>
                                                                            <ListInputBerry
                                                                                type="number"
                                                                                placeholder='0'
                                                                                id='FIRST'
                                                                                value={watch && watch.paidUnits && watch.paidUnits[0] ? watch.paidUnits[0].unit : value}
                                                                                // ref={props.refGradeFirst}
                                                                                // onChange={(e) => handlePaidUnits(e)}
                                                                                onChange={handlePaidUnits}
                                                                                disabled={watch && !watch.isPaid}
                                                                            />
                                                                            <ListRtxtSubNum
                                                                                watchedValue
                                                                                berry
                                                                                In>Berry</ListRtxtSubNum>
                                                                        </ListRspan>
                                                                    }
                                                        />
                                                    </ListRtxtSubText>
                                                    <ListRtxtSubText>
                                                        2단계 회원
                                                        <Controller name='paidUnits[1]'
                                                                    control={control}
                                                                    render={({field: {onChange, value}}) =>
                                                                        <ListRspan Rel>
                                                                            <ListInputBerry
                                                                                type="number"
                                                                                placeholder="0"
                                                                                id='SECOND'
                                                                                // ref={props.refGradeFirst}
                                                                                value={watch && watch.paidUnits && watch.paidUnits[1] ? watch.paidUnits[1].unit : value}
                                                                                onChange={handlePaidUnits}
                                                                                disabled={watch && !watch.isPaid}
                                                                            />
                                                                            <ListRtxtSubNum
                                                                                disabled={watch && watch.isPaid}
                                                                                berry
                                                                                In>Berry</ListRtxtSubNum>
                                                                        </ListRspan>
                                                                    }
                                                        />
                                                    </ListRtxtSubText>
                                                </>
                                                :
                                                <>
                                                    {type && type === 'user' ?
                                                        <span>{detailInfo.paidUnit}</span> :
                                                        <>
                                                            {detailInfo && detailInfo.paidUnits && detailInfo.paidUnits.map((paidUnit, index) =>
                                                                <ListRtxtSubText key={index}>
                                                                    {Number(index) + 1}단계 회원
                                                                    <ListRspan>
                                                                        <ListRtxtSubNum
                                                                            pollReward>{paidUnit.unit}</ListRtxtSubNum>
                                                                        <ListRtxtSubNum berry>Berry</ListRtxtSubNum>
                                                                    </ListRspan>
                                                                </ListRtxtSubText>
                                                            )}
                                                        </>}
                                                </>
                                            }
                                        </Wrapper>
                                    </Wrapper>
                                </ListLi>

                                {type && (type === 'user' || type === 'channel') &&
                                    <ListLi>
                                        <ListLtxt>보상 현황</ListLtxt>
                                        <ListRtxt>
                                            <ListRspan>{detailInfo && detailInfo.paidAmount}</ListRspan>
                                            <ListRspan>Berry</ListRspan>
                                            <GrayButton short
                                                        type='button'
                                                        onClick={(e) => openDetailModal(e)}
                                                        id='reward'
                                            >
                                                상세내역</GrayButton>
                                        </ListRtxt>
                                    </ListLi>
                                }
                            </>
                        }
                    </ListUl>
                </List>
            </MenuInfoCont>
        </>
    );
}

export default CommonSurveyDetail;
