import React, {useMemo, useState} from "react";
import {SideBarContainer} from "./SideBarStyle";
import DropDown from "../../common/DropDown/DropDown";
import {useGlobalStore} from "stores";

function SideBar(props) {
  const [
    {
      user: {info},
    },
  ] = useGlobalStore();

  const totalAuth = useMemo(() => {
    return info?.access
  }, [info]);

  const [closeMenuState, setCloseMenuState] = useState([
    {title: "사용자 관리", isOpen: false},
    {title: "채널 관리", isOpen: false},
    {title: "컨텐츠 관리", isOpen: false},
    {title: "설문 관리", isOpen: false},
    {title: "포인트 관리", isOpen: false},
    {title: "공지사항/게시판", isOpen: false},
  ]);

  const UsersOptions = totalAuth?.userAccess !== "R" ?
    [
      {id: 1, item: "회원생성", link: "/userCreate"},
      {id: 2, item: "회원조회", link: "/user/list"},
      {id: 3, item: "퍼즐토큰 관리", link: "/puzzleToken/list"},
    ]
    :
    [
      {id: 1, item: "회원조회", link: "/user/list"},
      {id: 2, item: "퍼즐토큰 관리", link: "/puzzleToken/list"},
    ]

  const ChannelOptions = [
    {id: 1, item: "채널 관리", link: "/channel/manage"},
  ];

  const ContentOptions = [
    // { id: 1, item: "노출 관리", link: "" },
    {id: 2, item: "플랫폼 설문", link: "/contents/platformList"},
    {id: 3, item: "랭킹 관리", link: "/contents/lankList"},
    {id: 4, item: "랭킹 노출 관리", link: "/contents/rankingViewManagement"},
    {id: 5, item: "카테고리 관리", link: "/contents/categoryManage"},
    {id: 6, item: "설문 키워드 관리", link: "/contents/keywordMange"},
  ];

  const PollOptions = [
    {id: 1, item: "채널 설문", link: "/surveyMange/channelSurvey"},
    {id: 2, item: "채널 설문 노출 관리", link: "/surveyMange/viewManagement"},
    {id: 3, item: "회원 설문", link: "/surveyMange/userSurvey"},
    {id: 4, item: "검수대기 설문", link: "/surveyMange/inspection"},
    {id: 5, item: "신고 설문", link: "/surveyMange/reportSurvey"},
  ];

  const PointOptions = [
    {id: 1, item: "전체 관리", link: "/pointMng/rewardTokenMng"},
    {id: 2, item: "보상 관리", link: "/pointMng/rewardMng"},
    {id: 3, item: "충전 관리", link: "/pointMng/topUpMng"},
    {id: 4, item: "사용 관리", link: "/pointMng/refundMng"},
    {id: 5, item: "Berry Point 상세내역", link: "/pointMng/berryPoint"},
    {id: 6, item: "Reward Token 관리", link: "/pointMng/rewardDetail"},
    {id: 7, item: "회원별 관리", link: "/pointMng/userMng"},
    {id: 8, item: "광고 보상 관리", link: "/pointMng/adRewardManagement"},
    {id: 9, item: "모바일 쿠폰 관리", link: "/pointMng/CouponManagement"},
    // {id: '', item: "환전 관리", link: "/pointMng/exchangeMng"},
  ];

  const NoticeOptions = [
    {id: 1, item: "공지사항 관리", link: "/notice/list"},
    {id: 2, item: "FAQ 관리", link: "/faq/list"},
    {id: 3, item: "이용약관 관리", link: "/tos/list"},
    {id: 4, item: "1:1 문의내역", link: "/qna/list"},
  ];

  return (
    <SideBarContainer>
      <DropDown title={"대시보드"}/>
      {info?.access?.userAccess !== "N" && (
        <DropDown options={UsersOptions} title={"사용자 관리"} closeMenuState={closeMenuState}
                  setCloseMenuState={setCloseMenuState}/>
      )}
      {info?.access?.channelAccess !== "N" &&
        (info?.role === "ROLE_ADMIN" || info?.role === "ROLE_SUPER") && (
          <DropDown options={ChannelOptions} title={"채널 관리"} closeMenuState={closeMenuState}
                    setCloseMenuState={setCloseMenuState}/>
        )}
      {info?.access?.contentsAccess !== "N" && (
        <DropDown options={ContentOptions} title={"컨텐츠 관리"} closeMenuState={closeMenuState}
                  setCloseMenuState={setCloseMenuState}/>
      )}
      {info?.access?.pollAccess !== "N" && (
        <DropDown options={PollOptions} title={"설문 관리"} closeMenuState={closeMenuState}
                  setCloseMenuState={setCloseMenuState}/>
      )}
      {info?.access?.pointAccess !== "N" && (
        <DropDown options={PointOptions} title={"포인트 관리"} closeMenuState={closeMenuState}
                  setCloseMenuState={setCloseMenuState}/>
      )}
      {info?.access?.boardAccess !== "N" && (
        <DropDown options={NoticeOptions} title={"공지사항/게시판"} closeMenuState={closeMenuState}
                  setCloseMenuState={setCloseMenuState}/>
      )}
    </SideBarContainer>
  );
}

export default SideBar;
