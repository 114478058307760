import React, {useEffect, useMemo, useState} from "react";
import {
    BackBtn,
    Button,
    ButtonRight,
    DropdownIndicator,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl,
    MenuArea,
    MenuInfoArea,
    PreviewButton,
    Title,
    Wrapper
} from "common/styledComponents/commonStyle";

import {ListInputTxtCount} from "pages/ServeyManage/ChannelSurvey/channelSurveySytle"
import {MenuInfoCont} from "pages/Mypage/MypageStyle";
import {useNavigate} from "react-router";
import Editor from "common/components/Editor"
import {Controller, useForm} from 'react-hook-form';
import {NOTICE_SEARCH_KEY_OPTIONS, TOS_TITLE_OPTIONS} from '../../constants/noticeBoard';
import Select from 'react-select';
import useRequest from '../../../hooks/useRequest';
import PostModal from '../../../pages/Post/PostModal/PostModal';
import {API_STATE} from '../../constants/state';

const Register = ({...props}) => {
    const {topShow, type, title,} = props;
    const request = useRequest("post", `${type}/new`);
    const [onPreviewModal, setOnPreviewModal] = useState(false);

    const {
        register,
        setValue,
        handleSubmit,
        watch,
        control,
    } = useForm()

    const navigate = useNavigate();

    const onSubmit = (data, e) => {
        request.call(data, "body");
    }

    const SelectCustomMediumStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 280,
                border: "1px solid #757575",
                borderRadius: "8px",
                backgroundColor: "#F5F5F5",
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    const SelectCustomTitleStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: '100%',
                border: "1px solid #757575",
                borderRadius: "8px",
                backgroundColor: "#F5F5F5",
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    const limitLength = (e) => {
        e.target.value = e.target.value.substring(0, 40)
        setValue('title', e.target.value);
    }

    const openModal = () => {
        setOnPreviewModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () => {
        setOnPreviewModal(false)
        document.body.style.overflow = 'auto';
    }

    const allPropertiesDefined = Object.values(watch()).every(value => value !== undefined && value !== null && value !== '') && watch().type === 'APP';

    useEffect(() => {
        switch (request.state) {
            case API_STATE.done:
                if (request.response.success) {
                    alert('저장 되었습니다');
                    navigate(`/${type}/list`);
                }
                break;
            default:
                break;
        }
    }, [request.state, request.error, request.response]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Title>
                            <BackBtn onClick={() => navigate(-1)}/>
                            {title}
                        </Title>
                        <MenuInfoCont survey>
                            <List wide>
                                <ListUl>
                                    <ListLi>
                                        <ListLtxt>구분</ListLtxt>
                                        <ListRtxt>
                                            <Wrapper Row Radio>
                                                <Controller name='type'
                                                            control={control}
                                                            render={({field: {onChange, value}}) =>
                                                                <>
                                                                    <Wrapper width='140px' margin='0 24px 0 0'>
                                                                        <input type="radio" value="APP" id='APP'
                                                                               checked={value === 'APP'}
                                                                               onChange={() => onChange('APP')}
                                                                        />
                                                                        <label htmlFor='APP'>APP</label>
                                                                    </Wrapper>
                                                                    <Wrapper width='140px' margin='0 24px 0 0'>
                                                                        <input type="radio" value="WEB" id='WEB'
                                                                               checked={value === 'WEB'}
                                                                               onChange={() => onChange('WEB')}
                                                                        />
                                                                        <label htmlFor='WEB'>WEB</label>
                                                                    </Wrapper>
                                                                </>
                                                            }
                                                />
                                                {type === 'notice' && topShow && (
                                                    <Wrapper width='140px'>
                                                        <input type="checkbox" {...register('isTop')} id='isTop'/>
                                                        <label htmlFor='isTop'>상위 노출하기</label>
                                                    </Wrapper>
                                                )
                                                }
                                            </Wrapper>
                                        </ListRtxt>
                                    </ListLi>
                                    {type === 'notice' &&
                                        <ListLi>
                                            <ListLtxt>카테고리</ListLtxt>
                                            <ListRtxt>
                                                <Controller
                                                    name="noticeType"
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <Select
                                                            components={{DropdownIndicator}}
                                                            options={NOTICE_SEARCH_KEY_OPTIONS.slice(1)}
                                                            styles={SelectCustomMediumStyles}
                                                            isSearchable={false}
                                                            onChange={selectedOption => {
                                                                onChange(selectedOption.value);
                                                            }}
                                                        />
                                                    }
                                                    // rules={{required: true}}
                                                />
                                            </ListRtxt>
                                        </ListLi>
                                    }
                                </ListUl>
                            </List>
                        </MenuInfoCont>

                        <MenuInfoCont noline>
                            <List wide>
                                <ListUl>
                                    <ListLi>
                                        <ListLtxt>제목</ListLtxt>
                                        <ListRtxt Max>
                                            {type === 'tos' ?
                                                <Controller
                                                    name="title"
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <Select
                                                            components={{DropdownIndicator}}
                                                            options={TOS_TITLE_OPTIONS}
                                                            styles={SelectCustomTitleStyles}
                                                            onChange={selectedOption => {
                                                                onChange(selectedOption.value);
                                                            }}
                                                            isSearchable={false}
                                                        />
                                                    }
                                                    // rules={{required: true}}
                                                />
                                                : <>
                                                    <ListInput
                                                        wide
                                                        type="text"
                                                        placeholder=""
                                                        {...register('title', {onChange: limitLength})}
                                                    />
                                                    <ListInputTxtCount In>
                                                        {watch().title ? watch().title.length : 0}/40자
                                                    </ListInputTxtCount>
                                                </>
                                            }
                                        </ListRtxt>
                                    </ListLi>
                                    <ListLi>
                                        <ListLtxt top>내용</ListLtxt>
                                        <ListRtxt Max>
                                            <Controller
                                                name="contents"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Editor placeholder={'내용을 입력 해주세요'} setEditorData={onChange}
                                                            editorDate={value}/>
                                                }
                                                // rules={{required: true}}
                                            />
                                            {/*api*/}
                                            {/*<Editor placeholder={'내용을 입력 해 주세요'} setEditorData={setEditorData}*/}
                                            {/*        editorDate={editorDate}/>*/}
                                        </ListRtxt>
                                    </ListLi>
                                </ListUl>
                            </List>
                            <ButtonRight>
                                <PreviewButton mini preview disabled={!allPropertiesDefined}
                                               onClick={openModal}
                                               type="button">
                                    미리보기
                                </PreviewButton>
                                <Button mini type="submit">
                                    저장하기
                                </Button>
                            </ButtonRight>
                        </MenuInfoCont>
                    </form>
                </MenuInfoArea>
            </MenuArea>
            {onPreviewModal &&
                <PostModal type={type} title={watch().title} contents={watch().contents} createdAt={watch.createdAt}
                           closeModal={closeModal}/>
            }
        </>
    );
};

export default Register;
