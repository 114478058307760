import styled, {css} from "styled-components";

export const BigDiv = styled.div`
  position: relative;
  margin-bottom: 27px;

  & + & {
    border-top: 2px solid #dbdbdb;
  }
`;
export const BigPollSubject = styled.p`
  margin: 30px 0 14px;
  font-size: 24px;
  font-weight: 500;
`;
export const BigPollTxt = styled.p`
  margin: 14px 0 28px;
`;
export const BigSearchTxt = styled.p`
  font-weight: 500;
  margin: 48px 0;

  strong {
    font-size: 24px;
    margin-right: 16px;
  }
`;
export const BigPollCountBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
export const BigPollCountName = styled.span`
  font-size: 18px;
  font-weight: 500;
  width: 140px;
  display: inline-block;
`;

export const BigPollCountNum = styled.span`
  font-size: 18px;
  margin: 0 7px 0 24px;
`;
export const BigPollCountUnit = styled.span`
  font-size: 18px;
  font-weight: 500;
`;
export const BigPollCountBtn = styled.button`
  margin-left: 34px;
  width: 100px;
  height: 36px;
  border-radius: 8px;
  background-color: #dbdbdb;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
`;
export const BigPollUl = styled.ul`
  width: 100%;
`;
export const BigPollLi = styled.li`
  margin-bottom: 27px;
  display: inline-flex;
`;
export const BigPollListTxt = styled.span`
  width: 140px;
  display: inline-block;
  line-height: 46px;
  float: left;
`;
export const BigPollStateUl = styled.ul`
  width: 1004px;
  display: inline-block;
  margin-bottom: -27px;
  ${(props) =>
          props.six &&
          `
        width: 100%;
        li:nth-child(5n){
            margin-right: 27px !important;
        }
        li:nth-child(6n){
            margin-right: 0 !important;
        }
        `};

`;
export const BigPollStateLi = styled.li`
  float: left;
  font-size: 20px;
  font-weight: 500;
  color: #000065;
  border-radius: 20px;
  border: 1px solid #000065;
  width: 168px;
  height: 46px;
  line-height: 44px;
  text-align: center;
  margin: 0 30px 27px 0;
  ${(props) =>
          props.on &&
          `
        color:#fff;
        background-color: #000065;
        `};

  &:nth-child(5n) {
    margin-right: 0 !important;
  }
`;


//설문 없음
export const BigPollNot = styled.p`
  font-size: 36px;
  padding: 80px 0;
  text-align: center;
`;

//참여자의 관심사 키워드
export const BigPollKeyP = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin: 24px 0;
`;


export const BarBox = styled.span`
  width: 100%;
  max-width: 980px;
  height: 40px;
  background-color: #dbdbdb;
  display: inline-block;
  ${(props) =>
          props.img &&
          css`
            max-width: 816px;
          `}

`;

export const BigResultBox = styled.div`
  margin: 48px 0 80px;
`;



