import React, {useState} from "react";
import {
    AddFileWrapper,
    Button,
    InputRadioWrapper,
    ItemTextInputWrapper,
    ListInput,
    PollSettingContainer,
    PollSettingContentContainer,
    PollSettingContentSection,
    PollSettingContentTitle,
    PollSettingContentWrapper,
    PollSettingMainContainer,
    PollSettingTitleContainer,
    RewardAmountWrapper,
    Select,
    TitleInputWrapper,
} from "../../common/styledComponents/commonStyle";
import {Content, Wrapper} from "./PlatformPollDetailBalanceStyle";

function PlatformPollDetailBalance(props) {
    const [rewardState, setRewardState] = useState(false);

    const itemTest = [
        {title: "항목 1", id: 1, value: "", name: "balance_item_1", file: "", fileName: ""},
        {title: "항목 2", id: 2, value: "", name: "balance_item_2", file: "", fileName: ""},
    ];

    const onChangeRewardState = (e) => {
        if (e.target.id === "no_reward") {
            setRewardState(true);
        } else {
            setRewardState(false);
        }
    };

    return (
        <PollSettingContainer>
            <PollSettingTitleContainer>
                <span>플랫폼 설문 상세보기</span>
            </PollSettingTitleContainer>
            <PollSettingMainContainer>
                <form>
                    <PollSettingContentContainer>
                        <PollSettingContentSection>
                            <PollSettingContentWrapper Border>
                                <Content>
                                    <Wrapper>
                                        <PollSettingContentTitle>설문 유형</PollSettingContentTitle>
                                        <span>밸런스 게임</span>
                                    </Wrapper>
                                    <Wrapper>
                                        <PollSettingContentTitle>설문 상태</PollSettingContentTitle>
                                        <Select width="122px">
                                            <option value={"test_1"}>test_1</option>
                                            <option value={"test_2"}>test_2</option>
                                            <option value={"test_3"}>test_3</option>
                                            <option value={"test_4"}>test_4</option>
                                        </Select>
                                    </Wrapper>
                                </Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper Border>
                                <PollSettingContentTitle>설문 제목</PollSettingContentTitle>
                                <Content MaxWidth>
                                    <TitleInputWrapper>
                                        <input
                                            type="text"
                                            required
                                            name="pollTitle"
                                            id="pollTitle"
                                        />
                                        <span>0/20자</span>
                                    </TitleInputWrapper>
                                </Content>
                            </PollSettingContentWrapper>
                        </PollSettingContentSection>
                        <PollSettingContentWrapper Border>
                            <PollSettingContentTitle>설문 노출기간</PollSettingContentTitle>
                            <Content>대기 중</Content>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper>
                            <PollSettingContentTitle>보상 지급 여부</PollSettingContentTitle>
                            <Content>
                                <InputRadioWrapper>
                                    <input
                                        type="radio"
                                        id="yes_reward"
                                        name="reward"
                                        value="yes_reward"
                                        onChange={onChangeRewardState}
                                    />
                                    <label htmlFor="yes_reward">지급</label>
                                </InputRadioWrapper>
                                <InputRadioWrapper>
                                    <input
                                        type="radio"
                                        id="no_reward"
                                        name="reward"
                                        value="no_reward"
                                        onChange={onChangeRewardState}
                                    />
                                    <label htmlFor="no_reward">지급 안함</label>
                                </InputRadioWrapper>
                            </Content>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper>
                            <PollSettingContentTitle>보상 단위</PollSettingContentTitle>
                            <Content>
                                <RewardAmountWrapper>
                                    <ListInput
                                        disabled={rewardState}
                                        width="180px"
                                        type="number"
                                        maxLength="4"
                                        name="reward_amount"
                                        id="reward_amount"
                                        placeholder="nnnn"
                                        // value={reward}
                                    />
                                    <span>Berry</span>
                                </RewardAmountWrapper>
                            </Content>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper Border>
                            <PollSettingContentTitle>보상 총액</PollSettingContentTitle>
                            <Content>
                                <RewardAmountWrapper disabled={rewardState}>
                                    <p>nnn,nnn,nnn</p>
                                    <span>Berry</span>
                                </RewardAmountWrapper>
                            </Content>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper>
                            <PollSettingContentTitle Subheading>설문 항목</PollSettingContentTitle>
                            <Content Subheading>
                                <span>
                                    이미지 권장크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000)
                                    / jpg, jpeg, png, bmp
                                </span>
                            </Content>
                        </PollSettingContentWrapper>
                        {itemTest.map((item) => (
                            <PollSettingContentWrapper key={item.id}>
                                <PollSettingContentTitle>{item.title}</PollSettingContentTitle>
                                <Wrapper
                                    width="80px"
                                    margin="0 24px 0 0"
                                    backgroundColor="#f5f5f5"
                                />
                                <Content MaxWidth>
                                    <Wrapper Column>
                                        <ItemTextInputWrapper>
                                            <input
                                                type="text"
                                                required
                                                // key={item.id}
                                                id={item.id}
                                                // value={watch().pollItems[index].value}
                                            />
                                        </ItemTextInputWrapper>
                                        <AddFileWrapper>
                                            <p>{item.fileName}</p>
                                            <input
                                                type="file"
                                                id={item.name}
                                                key={item.id}
                                                // name="test"
                                                accept="image/jpg, image/png, image/jpeg"
                                            />
                                            <label>파일 첨부</label>
                                        </AddFileWrapper>
                                    </Wrapper>
                                </Content>
                            </PollSettingContentWrapper>
                        ))}
                        <PollSettingContentWrapper Border Padding>
                            <Content right>
                                <Button preview mini>
                                    미리보기
                                </Button>
                                <Button mini>버튼</Button>
                            </Content>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper>
                            <PollSettingContentTitle Subheading>결과 집계</PollSettingContentTitle>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper Padding>
                            <Wrapper
                                width="140px"
                                height="140px"
                                margin="0 24px 0 0"
                                backgroundColor="#f5f5f5"
                            />
                            <Content Row>
                                <Wrapper Column width="816px">
                                    <span style={{marginTop: "auto"}}>
                                        텍스트 / 없을 경우 항목 n
                                    </span>
                                    <div style={{backgroundColor: "red"}}>막대 바</div>
                                </Wrapper>
                                <Wrapper Column width="140px">
                                    <span style={{marginTop: "auto"}}>60%</span>
                                    <span>1,141,400명</span>
                                </Wrapper>
                            </Content>
                        </PollSettingContentWrapper>
                        <PollSettingContentWrapper Padding Border>
                            <Wrapper
                                width="140px"
                                height="140px"
                                margin="0 24px 0 0"
                                backgroundColor="#f5f5f5"
                            />
                            <Content Row>
                                <Wrapper Column width="816px">
                                    <span style={{marginTop: "auto"}}>
                                        텍스트 / 없을 경우 항목 n
                                    </span>
                                    <div style={{backgroundColor: "blue"}}>막대 바</div>
                                </Wrapper>
                                <Wrapper Column width="140px">
                                    <span style={{marginTop: "auto"}}>60%</span>
                                    <span>1,141,400명</span>
                                </Wrapper>
                            </Content>
                        </PollSettingContentWrapper>
                    </PollSettingContentContainer>
                </form>
            </PollSettingMainContainer>
        </PollSettingContainer>
    );
}

export default PlatformPollDetailBalance;
