import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";

import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"

import {LIST_STATUS, PLATFORM_LIST_COLUMNS, TYPE} from "common/constants/platform";

import DATE from "common/constants/date";

import {
    Button,
    CloseButton,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    ModalSpan,
    ReturnTdContent,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Title
} from "common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";
import storageService from '../../../util/storage';
import axios from 'axios';
import CloseIcon from '../../../asset/img/icon__close_black.svg';


const PlatformMangeList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        title: queryTitle || '',
    });
    const [stopData, setStopData] = useState({
        reason: '', title: '',
    });
    const [onReturnModal, setOnReturnModal] = useState(false);
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", "poll/event/list");

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();


    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {rows, fetch} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            type: TYPE[data.type],
            status: LIST_STATUS[data.status],
            period: `${dayjs(new Date(data.startAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.endAt)).format(DATE.FORMAT.DOT)}`,
        }));
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    const handleSelectRow = (row) => {
        navigate(`/contents/platformDetail/${row.id}?page=${curPage}`);
    }

    const handleSearch = (data) => {
        const {text} = data;
        const {limit} = formData;


        if (text || text === '') {
            searchParams.set('title', text);
            searchParams.set('page', '1')
            setCurPage(1)
        }

        setSearchParams(searchParams)

        fetch({
            title: text || '',
            page: 1,
            offset: 0,
            limit
        });
    };

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    };

    const openStopReasonModal = () => {
        setOnReturnModal(true)
        // document.body.style.overflow = 'hidden';
    }

    const closeStopReasonModal = () => {
        // document.body.style.overflow = 'auto';
        setOnReturnModal(false)
    }

    const showReturnModal = async (e) => {
        const token = storageService.get("auth", "accessToken", "local");
        const id = Number(e.currentTarget.id);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/poll/${id}/event/stop`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            setStopData(prevState => ({
                ...prevState, reason: response.data.response.reason,
                title: rows.filter((row) => row.id === id)[0].title
            }))
            openStopReasonModal()

        } catch (e) {
            console.log(e)
        }
        // setOnReturnModal(true)
    }


    const columnRenderer = (key, value, _, row) => {
        if (key === "viewStatus") {
            return (<ReturnTdContent id={row.id} Activate={row.viewStatus}
                                     onClick={showReturnModal}>
                {row.viewStatus ? '활성화' : '비활성화'}
            </ReturnTdContent>)
        }
        return value
    }

    useEffect(() => {
        setSearchParams(searchParams)

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            // offset: 1
        })

    }, [queryPage, queryTitle,]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>플랫폼 설문 관리
                        {
                            contentsAuth.find(d => d === "W") &&
                            <Button tit onClick={() => navigate('/contents/platformCreate')}>신규 플랫폼 설문 등록하기</Button>
                        }
                    </Title>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={PLATFORM_LIST_COLUMNS}
                           rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}
                           columnRenderer={columnRenderer}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
            {onReturnModal &&
                <StyledModal>
                    <ModalContainer return>
                        <RewardDetailTitle>비활성화 사유 확인</RewardDetailTitle>
                        <RewardDetailContent return>
                            <ModalSpan>{`[${stopData.title}]`}</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>설문이 비활성화 됐습니다.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>아래에 사유를 확인해주세요.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>{stopData.reason}</ModalSpan>
                            <ModalSpan margin='auto 0 0 0'>문의 1:1 문의하기 혹은 info@pollberry.net</ModalSpan>
                        </RewardDetailContent>
                        <Button mini onClick={closeStopReasonModal} margin='48px 0 0 0'>확인</Button>
                        <CloseButton onClick={closeStopReasonModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }
        </>
    )
}

export default PlatformMangeList;
