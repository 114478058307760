import React, {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {DETAIL_VIEW_TYPE, LIST_STATUS} from "common/constants/platform";
import ko from 'date-fns/locale/ko'
import parseISO from 'date-fns/parseISO'
import {
    AddFileWrapper,
    ButtonWrapper,
    CircleBox,
    CircleImg,
    CircleTxt,
    Content,
    ContentContainer,
    ContentSection,
    ContentTitle,
    ContentWrapper,
    FileNameWrapper,
    ItemPrevImage,
    KeywordImgBox,
    KeywordImgTit,
    KeywordTextWrapper,
    KeywordTxtBox,
    MainContainer,
    PlatformRewardWrapper,
    PollTypeWrapper,
    PreviewDiv,
    PreviewImg,
    RemoveButton,
    RewardAmountWrapper
} from "./CreatePlatformPollStyle";
import {
    Button,
    CloseButton,
    CustomHeader,
    GrayButton,
    ListInput,
    ModalContainer,
    ModalText,
    RewardDetailContent,
    RewardDetailTitle,
    StyledDatePicker,
    StyledModal,
    StyledTextArea,
    Wrapper
} from "common/styledComponents/commonStyle";
import PlatformPollResult from '../PlatformDetail/PlatformPollResult';
import {useGlobalStore} from "stores";
import ArrowRight from 'asset/img/icon__arrow_forward.png';
import PreviewBalance from './PreviewBalance';
import AddIcon from 'asset/img/icon__add.png'
import AddDisableIcon from 'asset/img/icon__add_gray.png'
import DelIconBig from "asset/img/icon__cancel_big.png";
import RewardModal from '../../../../common/components/RewardModal/RewardModal';
import HandleThumbnailUpload from '../../../../common/js/HandleThumbnailUpload';
import ParticipationCountModal from '../../../../common/components/ParticipationCountModal/ParticipationCountModal';
import CloseIcon from '../../../../asset/img/icon__close_black.svg';

function CommonPlatformView({...props}) {
    const {
        type,
        status,
        title,
        today,
        items,
        isPaid,
        paidUnit,
        paidAmount,
        isView,
        participantsCount,
        onSubmit,
        onDelete,
        startAt,
        endAt,
        viewStatus,
        setOnStopModal,
        onStopModal,
        platformParticipantsListRequest,
        platformRewardListRequest,
        platformCompleteRequest,
        onCompleteReasonModal,
        setOnCompleteReasonModal,
    } = props;
    const defaultItem = {text: '', url: '', imageUrl: '', name: ''};
    const pollItems = items || Array.from({length: 2}).map(_ => defaultItem);
    const [onRewardModal, setOnRewardModal] = useState(false);
    const [onParticipationCountModal, setOnParticipationCountModal] = useState(false);
    const pollKeywordData = items || Array.from({length: 1}).map(_ => defaultItem);
    const KEYWORD_ITEM_MAX_LENGTH = 10;
    const [
        {
            user: {info},
        },
    ] = useGlobalStore();
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;
    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        getValues,
    } = useForm({
        defaultValues: {
            type: type.toUpperCase(),
            title: title && title,
            isPaid: isPaid,
            items: items ? items : pollItems,
            status: status && status,
            paidUnit: paidUnit,
            startAt: startAt ? parseISO(startAt) : null,
            endAt: endAt ? parseISO(endAt) : null,
            participantsCount: participantsCount || 0,
            viewStatus: viewStatus || false,
            reasonForStop: null,
        },
    });

    const _onSubmit = async (data) => {
        if (!data.viewStatus && !data.reasonForStop) {
            if (viewStatus) {
                setOnStopModal(true)
                return
            }
        }

        let formData

        if (data.isPaid && data.paidUnit <= 0) {
            alert('보상 단위는 0보다 커야됩니다.')
            return
        }

        await HandleThumbnailUpload(data.items, 'poll/event').then((images) => {
                data = {...data, items: images}
            }
        ).catch(error => console.log(error))

        if (isView) {
            formData = {
                ...data,
                paidUnit: Number(data.paidUnit),
                items: data.items.map((item) => {
                    const {id, text, url} = item;
                    return {text: text ? text : '', url: url ? url : '', id: id || null}
                })
            }
            if (formData.type) {
                delete formData.type
            }
            onSubmit && onSubmit(formData)
        } else {
            formData = {
                ...data,
                paidUnit: Number(data.paidUnit),
                items: data.items.map((item) => {
                    const {text, url, id} = item;
                    return {text: text ? text : '', url: url ? url : '', id: id || null}
                })
            }
            delete formData.status
            onSubmit && onSubmit(formData)
        }
    };

    const handleDelete = () => {
        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }
        onDelete && onDelete();
    }


    const onChangePollTitle = (e) => {
        e.target.value = e.target.value.substring(0, 30)
        setValue('title', e.target.value);
    };

    const handleBizNumberKeyPress = (e) => {
        const forbiddenCharacters = ['e', '+', '-', '.', 'ArrowDown', 'Down', 'down', 'ArrowUp'];
        if (forbiddenCharacters.includes(e.key)) {
            e.preventDefault();
        }
    }

    const removeItemImage = (idx) => {

        const newItems = watch().items.map((item, index) => (
            index !== idx ? item : {...item, url: '', name: '', imageUrl: ''}
        ))

        setValue('items', newItems)
    };

    const removeItems = (idx) => {

        if (watch().items.length === 1) {
            return
        }

        const newItems = watch().items.filter((item, index) => (
            index !== idx
        ))

        setValue('items', newItems)
    };

    const SelectCustomSmallStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 122,
                // height: 36,
                border: "1px solid #757575",
                borderRadius: "8px",
                backgroundColor: "#F5F5F5",
                caretColor: 'transparent',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
            placeholder: (provided, state) => ({
                ...provided,
                fontSize: '14px',
            }),
        }),
        [],
    );

    const AddKeywordItem = (e) => {
        const prevState = getValues('items');
        setValue('items', [...prevState, {text: '', url: '', imageUrl: '', name: ''}])
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        const startAtTimeDate = new Date(startAt);

        if (isView) {
            return startAtTimeDate.getTime() <= selectedDate.getTime()
        }
        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterEndAtTime = (time) => {
        const selectedDate = new Date(time);

        if (watch().startAt) {
            return watch().startAt.getTime() < selectedDate.getTime();
        } else if (today) {
            return new Date(today).getTime() < selectedDate.getTime()
        }
    };

    const handleStartAtDate = (date, e) => {
        const currentDate = new Date(date);

        // if (watch().endAt <= date) {
        //     setValue('endAt', date)
        // }

        if (watch().startAt > watch().endAt) {
            const customDate = currentDate.setMinutes(currentDate.getMinutes() + 30);
            setValue('endAt', new Date(customDate))
        }


    };

    const convertImage = (e, index) => {
        const file = e.target.files[0];
        const name = e.target.files[0].name;

        setValue(`items[${index}].name`, name)

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const res = reader.result;
            setValue(`items[${index}].imageUrl`, res)
        };
    }

    const openDetailModal = (e) => {
        document.body.style.overflow = 'hidden';
        const id = e.currentTarget.id

        if (id === 'reward') {
            setOnRewardModal(true)
        } else if (id === 'participantsCount') {
            setOnParticipationCountModal(true)
        }

    }

    const closeDetailModal = (e) => {
        document.body.style.overflow = 'auto';
        const id = e.currentTarget.id

        if (id === 'reward') {
            setOnRewardModal(false)

        } else if (id === 'participantsCount') {
            setOnParticipationCountModal(false)
        }
    }

    const handleReasonForStopValue = (e) => {
        e.target.value = e.target.value.slice(0, 500)
        setValue('reasonForStop', e.target.value);
    }

    const closeStopReasonModal = () => {
        document.body.style.overflow = 'auto';
        setOnStopModal(false)
    }

    const openCompleteReasonModal = () => {
        setOnCompleteReasonModal(true)
    }

    const closeCompleteReasonModal = () => {
        setOnCompleteReasonModal(false)
    }

    const patchCompleteReason = () => {
        platformCompleteRequest.call({reasonForComplete: watch().reasonForComplete}, 'body')
    }

    return (
        <>
            <MainContainer>
                <Wrapper>
                    <form onSubmit={handleSubmit(_onSubmit)}>
                        <ContentContainer>
                            <ContentSection
                                style={{height: "708px", overflow: "hidden", overflowY: "auto", paddingTop: "24px"}}>
                                {isView ?
                                    <>
                                        <ContentWrapper>
                                            <Content PollType>
                                                <ContentTitle>설문 유형</ContentTitle>
                                                <span>{type && DETAIL_VIEW_TYPE[type.toUpperCase()]}</span>
                                            </Content>
                                        </ContentWrapper>
                                        <ContentWrapper Padding>
                                            <Content PollType>
                                                <ContentTitle>설문 상태</ContentTitle>
                                                <span>{LIST_STATUS[watch().status]}</span>

                                                {/*<Controller*/}
                                                {/*    name="status"*/}
                                                {/*    control={control}*/}
                                                {/*    render={({field: {onChange}}) =>*/}
                                                {/*        <Select*/}
                                                {/*            options={STATUS_OPTIONS.map((option) => {*/}
                                                {/*                return ({*/}
                                                {/*                    value: option.value,*/}
                                                {/*                    label: STATUS[option.value]*/}
                                                {/*                })*/}
                                                {/*            })*/}
                                                {/*            }*/}
                                                {/*            placeholder={STATUS[watch().status]}*/}
                                                {/*            styles={SelectCustomSmallStyles}*/}
                                                {/*            components={{DropdownIndicator}}*/}
                                                {/*            onChange={selectedOption => {*/}
                                                {/*                onChange(selectedOption.value);*/}
                                                {/*            }}*/}
                                                {/*        />*/}
                                                {/*    }*/}
                                                {/*    // rules={{required: !isView}}*/}
                                                {/*/>*/}
                                            </Content>
                                        </ContentWrapper>
                                        <ContentWrapper Padding>
                                            <Content PollType>
                                                <ContentTitle>노출 설정</ContentTitle>
                                                <PollTypeWrapper>
                                                    <Controller
                                                        name='viewStatus'
                                                        control={control}
                                                        render={({field: {onChange}}) =>
                                                            <input
                                                                type="radio"
                                                                id="viewStatus_true"
                                                                value='true'
                                                                checked={watch().viewStatus}
                                                                onChange={e => {
                                                                    onChange(true)
                                                                    setValue('viewStatus', true)
                                                                }}
                                                            />
                                                        }
                                                        // rules={{required: !isView}}
                                                    />
                                                    <label htmlFor="viewStatus_true">활성화</label>
                                                </PollTypeWrapper>
                                                <PollTypeWrapper>
                                                    <Controller
                                                        name='viewStatus'
                                                        control={control}
                                                        render={({field: {onChange}}) =>
                                                            <input
                                                                type="radio"
                                                                id="viewStatus_false"
                                                                value='false'
                                                                checked={!watch().viewStatus}
                                                                onChange={e => {
                                                                    onChange(false)
                                                                    setValue('viewStatus', false)
                                                                }}
                                                            />
                                                        }
                                                        // rules={{required: watch().isPaid}}
                                                    />
                                                    <label htmlFor="viewStatus_false">비활성화</label>
                                                </PollTypeWrapper>
                                            </Content>
                                        </ContentWrapper>
                                    </>
                                    : <ContentWrapper Padding>
                                        <ContentTitle>설문 유형</ContentTitle>
                                        <Content PollType>
                                            <PollTypeWrapper>
                                                <Controller
                                                    name="type"
                                                    control={control}
                                                    // rules={{required: !isView}}
                                                    render={({field: {onChange, value}}) =>
                                                        <>
                                                            <input
                                                                type="radio"
                                                                id="BALANCE"
                                                                value="BALANCE"
                                                                checked={watch().type === "BALANCE"}
                                                                onChange={e => (
                                                                    onChange(e.target.value),
                                                                        setValue('items', pollItems)
                                                                )}
                                                            />
                                                            <label htmlFor="BALANCE">밸런스 게임</label>
                                                        </>
                                                    }
                                                />
                                            </PollTypeWrapper>
                                            <PollTypeWrapper>
                                                <Controller
                                                    name="type"
                                                    control={control}
                                                    // rules={{required: !isView}}
                                                    render={({field: {onChange, value}}) =>
                                                        <>
                                                            <input
                                                                type="radio"
                                                                id="KEYWORD"
                                                                value="KEYWORD"
                                                                checked={watch().type === "KEYWORD"}
                                                                onChange={e => (
                                                                    onChange(e.target.value),
                                                                        setValue('items', pollKeywordData))
                                                                }
                                                            />
                                                            <label htmlFor="KEYWORD">트렌드 랭킹</label>
                                                        </>
                                                    }
                                                />
                                            </PollTypeWrapper>
                                        </Content>
                                    </ContentWrapper>
                                }
                                <ContentWrapper Padding>
                                    <ContentTitle>설문 제목</ContentTitle>
                                    <Content ChannelInput>
                                        <input
                                            type="text"
                                            // required
                                            id="title"
                                            defaultValue={title && title}
                                            {...register("title", {
                                                onChange: onChangePollTitle
                                            })}
                                        />
                                        <span>{watch().title ? watch().title.length : 0}/30자</span>
                                    </Content>
                                </ContentWrapper>
                                <ContentWrapper Padding>
                                    <ContentTitle>설문 기간</ContentTitle>
                                    {/* <Content>대기 중</Content> */}
                                    <Wrapper Center width='auto'>
                                        <Wrapper>
                                            <Controller
                                                name="startAt"
                                                control={control}
                                                // rules={{required: !isView}}
                                                render={({field: {onChange, value}}) =>
                                                    <StyledDatePicker
                                                        id="startAt"
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        // selected={startAt ? parseISO(startAt) : value}
                                                        selected={value}
                                                        onChange={(date, e) => {
                                                            onChange(date)
                                                            handleStartAtDate(date, e)
                                                        }}
                                                        placeholderText="YYYY-MM-DD HH:mm"
                                                        locale={ko}
                                                        minDate={startAt ? parseISO(startAt) : parseISO(today)}
                                                        renderCustomHeader={(params) =>
                                                            <CustomHeader {...params} />}
                                                        showTimeSelect
                                                        timeCaption="시간"
                                                        filterTime={filterPassedTime}
                                                    />
                                                }
                                            />
                                        </Wrapper>
                                        <Wrapper margin='0 24px 0' width='24px' height='24px' Center>
                                            <img src={ArrowRight} alt="arrow"/>
                                        </Wrapper>
                                        <Wrapper>
                                            <Controller
                                                name="endAt"
                                                control={control}
                                                // rules={{required: !isView}}
                                                render={({field: {onChange, value}}) =>
                                                    <StyledDatePicker
                                                        id="endAt"
                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                        // selected={endAt ? parseISO(endAt) : value}
                                                        selected={value}
                                                        onChange={(date, e) => {
                                                            onChange(date, e)
                                                        }}
                                                        placeholderText="YYYY-MM-DD HH:mm"
                                                        locale={ko}
                                                        minDate={watch().startAt ? watch().startAt : parseISO(today)}
                                                        renderCustomHeader={(params) =>
                                                            <CustomHeader {...params} />}
                                                        showTimeSelect
                                                        timeCaption="시간"
                                                        filterTime={filterEndAtTime}
                                                    />
                                                }
                                            />
                                        </Wrapper>
                                    </Wrapper>
                                </ContentWrapper>
                                {isView &&
                                    <ContentWrapper Border Padding>
                                        <ContentTitle>설문 참여자</ContentTitle>
                                        <Wrapper Center width='auto'>
                                            <span>{watch().participantsCount} 명</span>
                                            <GrayButton short type='button' margin='0 0 0 24px' id='participantsCount'
                                                        onClick={openDetailModal}>상세내역</GrayButton>
                                        </Wrapper>
                                    </ContentWrapper>
                                }
                                <ContentWrapper Center Padding={!isView}>
                                    <ContentTitle>보상 지급 여부</ContentTitle>
                                    <div>
                                        <Content PollType>
                                            <PollTypeWrapper>
                                                <Controller
                                                    name='isPaid'
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <input
                                                            type="radio"
                                                            id="paid"
                                                            value='true'
                                                            checked={watch().isPaid}
                                                            onChange={e => {
                                                                onChange(true)
                                                                setValue('paidUnit', '')
                                                            }}
                                                        />
                                                    }
                                                    // rules={{required: !isView}}
                                                />
                                                <label htmlFor="paid">지급</label>
                                            </PollTypeWrapper>
                                            <PollTypeWrapper>
                                                <Controller
                                                    name='isPaid'
                                                    control={control}
                                                    render={({field: {onChange}}) =>
                                                        <input
                                                            type="radio"
                                                            id="no_paid"
                                                            value='false'
                                                            checked={!watch().isPaid}
                                                            onChange={e => {
                                                                onChange(false)
                                                                setValue('paidUnit', 0)
                                                            }}
                                                        />
                                                    }
                                                    // rules={{required: watch().isPaid}}
                                                />
                                                <label htmlFor="no_paid">지급 안함</label>
                                            </PollTypeWrapper>
                                        </Content>
                                    </div>
                                </ContentWrapper>
                                <ContentWrapper Padding Center>
                                    <ContentTitle>보상 단위</ContentTitle>
                                    <div>
                                        <Content>
                                            <RewardAmountWrapper disabled={watch().paidUnit}>
                                                <ListInput
                                                    disabled={!watch().isPaid}
                                                    width="180px"
                                                    type="number"
                                                    maxLength="4"
                                                    id="paidUnit"
                                                    placeholder={0}
                                                    value={watch().paidUnit ? watch().paidUnit : ''}
                                                    onKeyDown={handleBizNumberKeyPress}
                                                    {...register("paidUnit", {
                                                        // required: !isView
                                                    })}
                                                />
                                                <span>Berry</span>
                                            </RewardAmountWrapper>
                                        </Content>
                                    </div>
                                </ContentWrapper>
                                {isView &&
                                    <ContentWrapper Padding Center>
                                        <ContentTitle>보상 현황</ContentTitle>
                                        <div>
                                            <Content>
                                                <Wrapper Center Row width='258px'>
                                                    <PlatformRewardWrapper>
                                                        <span>{paidAmount}</span>
                                                        <span>Berry</span>
                                                    </PlatformRewardWrapper>
                                                    <GrayButton short
                                                                type='button'
                                                                id='reward'
                                                                onClick={openDetailModal}
                                                    >
                                                        상세내역
                                                    </GrayButton>
                                                </Wrapper>
                                            </Content>
                                        </div>
                                    </ContentWrapper>
                                }
                                <ContentWrapper BorderTop>
                                    {/*{watch().type === "KEYWORD" ? (*/}
                                    {/*    <ContentTitle PollItems>키워드 항목</ContentTitle>*/}
                                    {/*) : (*/}
                                    {/*    <ContentTitle PollItems>설문 항목</ContentTitle>*/}
                                    {/*)}*/}
                                    <ContentTitle PollItems>설문 항목</ContentTitle>
                                    <Content PollItems>
                                        <span>
                                            이미지 권장크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x
                                            1000) / jpg, jpeg, png, bmp
                                        </span>
                                    </Content>
                                </ContentWrapper>
                                {watch().items && watch().items.map((item, index) =>
                                    <ContentWrapper Padding Center key={index}>
                                        <ContentTitle
                                            keyword={watch().type === "KEYWORD"}
                                        >
                                            {`항목 ${index + 1}`}
                                        </ContentTitle>
                                        {(watch().type === "KEYWORD") &&
                                            <ItemPrevImage>
                                                {item.url &&
                                                    <img
                                                        src={item.imageUrl && item.imageUrl.includes('data:') ? item.imageUrl : typeof item.url === 'string' ? `${domain}${item.url}` : null}
                                                        alt={item.imageUrl ? 'keyword_image' : null}
                                                    />
                                                }
                                            </ItemPrevImage>
                                        }
                                        <Content balance={watch().type === "BALANCE"}
                                                 keyword={watch().type === "KEYWORD"}
                                        >
                                            <KeywordTextWrapper platformBalance>
                                                <input
                                                    type="text"
                                                    placeholder="입력해주세요"
                                                    id={`items.${index}.text`}
                                                    {...register(`items.${index}.text`,
                                                        {
                                                            // required: !isView,
                                                            onChange: watch().type === "KEYWORD" && ((e) => setValue(`items.${index}.text`, e.target.value.slice(0, 10))),
                                                        }
                                                    )}
                                                />
                                                {watch().type === "KEYWORD" &&
                                                    <span>{watch().items && watch().items[index].text ? watch().items[index].text.length : 0}/10</span>
                                                }
                                            </KeywordTextWrapper>
                                            <AddFileWrapper>
                                                <FileNameWrapper balance={watch().type === "BALANCE"}
                                                                 keyword={watch().type === "KEYWORD"}>
                                                    <p>{item.name}</p>
                                                    <RemoveButton type="button"
                                                                  onClick={() => {
                                                                      removeItemImage(index)
                                                                  }}
                                                    >
                                                        <img src={DelIconBig} alt='deleteButton'/>
                                                    </RemoveButton>
                                                </FileNameWrapper>
                                                <input
                                                    type="file"
                                                    // name={`items.${index}.url`}
                                                    id={`items.${index}`}
                                                    accept="image/jpg, image/png, image/jpeg"
                                                    {...register(`items.${index}.url`,
                                                        {onChange: (e) => convertImage(e, index)}
                                                    )
                                                    }
                                                />
                                                <label htmlFor={`items.${index}`}>파일 첨부</label>
                                            </AddFileWrapper>
                                        </Content>
                                        {watch().type === 'KEYWORD' &&
                                            <RemoveButton removeItem type="button"
                                                          onClick={() => {
                                                              removeItems(index)
                                                          }}
                                            >
                                                <img src={DelIconBig} alt='deleteButton'/>
                                            </RemoveButton>
                                        }
                                    </ContentWrapper>
                                )}
                                {watch().type === "KEYWORD" &&
                                    (
                                        contentsAuth.some(d => d === "W") &&
                                        <ButtonWrapper AddItem>
                                            <Button short type="button"
                                                    onClick={AddKeywordItem}
                                                    disabled={watch().items.length === KEYWORD_ITEM_MAX_LENGTH}
                                            >
                                                {watch().items.length === KEYWORD_ITEM_MAX_LENGTH ?
                                                    <img src={AddDisableIcon} alt="항목 추가"/> :
                                                    <img src={AddIcon} alt="항목 추가"/>}
                                                항목 추가 ({watch().items.length}/{KEYWORD_ITEM_MAX_LENGTH})
                                            </Button>
                                        </ButtonWrapper>
                                    )
                                }
                                {
                                    isView &&
                                    <PlatformPollResult participantsCount={participantsCount} domain={domain}
                                                        items={watch().items}
                                    />
                                }
                            </ContentSection>
                            <ButtonWrapper Margin>
                                {
                                    isView ? (
                                            <>
                                                {
                                                    contentsAuth.some(d => d === "D") &&
                                                    <GrayButton
                                                        onClick={() => handleDelete()}
                                                        type='button'>삭제하기</GrayButton>
                                                }
                                                <Button mini line margin='0 20px'
                                                        onClick={openCompleteReasonModal}
                                                        type='button'
                                                >수동 완료</Button>
                                                {
                                                    contentsAuth.some(d => d === "W") &&
                                                    <Button mini type="submit">
                                                        <span>수정하기</span>
                                                    </Button>
                                                }
                                            </>)
                                        :
                                        <Button mini type="submit" onClick={() => handleSubmit(_onSubmit)}>등록하기</Button>
                                }
                            </ButtonWrapper>
                        </ContentContainer>
                        {onStopModal &&
                            <StyledModal>
                                <ModalContainer stop>
                                    <RewardDetailTitle>비활성화 사유</RewardDetailTitle>
                                    <RewardDetailContent>
                                        <span>{`[${title}]`}</span>
                                        <Wrapper Column>
                                            <ModalText TextCenter margin='0 0 6px 0'>아래에 비활성화 사유를 입력해주세요.</ModalText>
                                            <StyledTextArea {...register('reasonForStop', {
                                                onChange: handleReasonForStopValue
                                            })}/>
                                            <Wrapper>
                                                <ModalText lengthLimit margin='4px 0 0 auto'>
                                                    {watch().reasonForStop ? watch().reasonForStop.length : 0}/500</ModalText>
                                            </Wrapper>
                                        </Wrapper>
                                    </RewardDetailContent>
                                    <Button mini margin='48px 0 0 0'>확인</Button>
                                    <CloseButton button='button' onClick={closeStopReasonModal}>
                                        <img src={CloseIcon} alt='close'/>
                                    </CloseButton>
                                </ModalContainer>
                            </StyledModal>
                        }
                    </form>
                </Wrapper>
                {watch().type === "KEYWORD" ? (
                    <PreviewDiv>
                        <PreviewImg>
                            <KeywordImgBox>
                                <KeywordImgTit>{watch().title}</KeywordImgTit>
                                {/* img 파일 없을 시 CircleBox 에 요청 색상 순서대로 background:#색상명; 추가*/}
                                {watch().items && watch().items.map((item, idx) => {
                                    if (idx + 1 > 7) {
                                        return <div key={idx}/>;
                                    } else {
                                        return (
                                            <CircleBox target={idx + 1} key={idx}>
                                                <CircleTxt>{watch().items[idx].text || '입력'}</CircleTxt>
                                                <CircleImg>
                                                    <img
                                                        src={item.imageUrl && item.imageUrl.includes('data:') ? item.imageUrl : `${domain}${item.url}`}
                                                        // onError={onErrorImg}
                                                        alt={item.imageUrl ? 'previewKeywordImage' : null}
                                                    />
                                                </CircleImg>
                                            </CircleBox>
                                        )
                                    }
                                })}
                                <KeywordTxtBox>n개의 관심 키워드 등록</KeywordTxtBox>
                            </KeywordImgBox>
                        </PreviewImg>
                        <Wrapper width='360px' margin='0 auto' Column>
                            <p>위 미리보기 화면에는 항목이 7개까지 노출됩니다.</p>
                            <p>등록되는 설문 항목은 내림차순으로 크기가 노출되며 색, 위치는 랜덤으로 등록됩니다.</p>
                            <p>등록 후 모바일에서 확인 부탁드립니다.</p>
                        </Wrapper>
                    </PreviewDiv>
                ) : (
                    <PreviewBalance title={watch().title} items={watch().items} domain={domain}/>
                )}
                {onRewardModal &&
                    <RewardModal closeDetailModal={closeDetailModal} request={platformRewardListRequest}
                                 modalList={true}/>
                }

                {onParticipationCountModal &&
                    <ParticipationCountModal closeDetailModal={closeDetailModal}
                                             request={platformParticipantsListRequest}
                                             modalList={true}
                    />
                }
                {onCompleteReasonModal &&
                    <StyledModal>
                        <ModalContainer stop>
                            <RewardDetailTitle>수동 완료</RewardDetailTitle>
                            <RewardDetailContent>
                                <span>{title}</span>
                                <Wrapper Column>
                                    <ModalText TextCenter margin='0 0 6px 0'>아래에 수동 완료 사유를 입력해주세요.</ModalText>
                                    <StyledTextArea {...register('reasonForComplete', {})}/>
                                    <Wrapper>
                                        <ModalText lengthLimit margin='4px 0 0 auto'>
                                            {watch().reasonForComplete ? watch().reasonForComplete.length : 0}/500</ModalText>
                                    </Wrapper>
                                </Wrapper>
                            </RewardDetailContent>
                            <Button mini margin='48px 0 0 0' type='button'
                                    onClick={patchCompleteReason}>확인</Button>
                            <CloseButton type='button' onClick={closeCompleteReasonModal}>
                                <img src={CloseIcon} alt='close'/>
                            </CloseButton>
                        </ModalContainer>
                    </StyledModal>
                }
            </MainContainer>
        </>
    );
}

export default CommonPlatformView;
