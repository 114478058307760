import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AddCount, MenuInfoCont, MenuInfoSub, MenuInfoSubButton} from "pages/Mypage/MypageStyle"
import {
  BackBtn,
  Button,
  ButtonRight,
  ChannelPollTitle,
  CloseButton,
  GrayButton,
  List,
  ListLi,
  ListLtxt,
  ListRtxt,
  ListUl,
  ModalContainer,
  ModalSpan,
  ModalText,
  RewardDetailContent,
  RewardDetailTitle,
  RewardRadioWrapper,
  StyledModal,
  StyledTextArea,
  SubjectList,
  SubjectListBlock,
  SubjectListBox,
  SubjectListSpan,
  SurveyImageItem,
  SurveyQuestionListWrapper,
  TitleNotice,
  UserPollStopReasonTextArea,
  Wrapper
} from "common/styledComponents/commonStyle";
import {BarBox, BarGraph, BarNumber, BarPercent, BarPerson} from "./ChannelSurvey/channelSurveySytle";
import ImgBack from "asset/img/img-back.jpg"
import ImgBack2 from "asset/img/img__priview.png"
import {ANSWER_TYPE, ITEM_TYPE, QUESTION_DEFAULT, USER_SURVEY_STATUS} from "common/constants/poll";
import {API_STATE} from '../../common/constants/state';
import useRequest from '../../hooks/useRequest';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import CommonSurveyDetail from '../../common/components/CommonSurveyDetail/CommonSurveyDetail';
import CloseIcon from "asset/img/icon__close_black.svg";
import ParticipationCountModal from '../../common/components/ParticipationCountModal/ParticipationCountModal';
import RewardModal from '../../common/components/RewardModal/RewardModal';
import {Question} from './ChannelSurvey/Create/Questions2';
import HandleThumbnailUpload from '../../common/js/HandleThumbnailUpload';
import SurveyItemsUpload from '../../common/js/SurveyItemsUpload';
import Modal from '../../common/components/Modal/Modal';
import dayjs from 'dayjs';
import DATE from '../../common/constants/date';
import {LengthCountSpan} from '../Join/JoinStyle';

const QuestionInspection = ({question}) => {

  return (
    <ListRtxt>
      {ITEM_TYPE[question.itemType]}
      <SubjectListBox>
        {question.items && question.items.map((item, idx) => {
          return (
            <SubjectList key={idx}>
              <SubjectListSpan head>항목 {++idx}</SubjectListSpan>
              {
                question.itemType === 'URL' &&
                <SubjectListSpan img>
                  <img src={item.url ? item.url : ImgBack} alt="img"/>
                </SubjectListSpan>
              }
              <SubjectListSpan>{item.text}</SubjectListSpan>
            </SubjectList>
          )
        })}
      </SubjectListBox>
    </ListRtxt>
  )
}

const QuestionChannelUser = ({question, participantsCount}) => {
  return (
    <>
      <ListRtxt>
        {ITEM_TYPE[question.itemType]}
      </ListRtxt>
      <SubjectListBox>
        {question.items && question.items.map((item, idx) => {
          const percent = `${(isNaN((item.selectionCount / question.totalSelectionCount * 100)) ? 0 : item.selectionCount / question.totalSelectionCount * 100).toFixed(1) || 0}%`;
          return (
            question.itemType === 'URL' ?
              <QuestionChannelUserImageItem item={item} key={idx} idx={idx} percent={percent}/>
              :
              <QuestionChannelUserItem item={item} key={idx} idx={idx} percent={percent}/>
          )
        })}
      </SubjectListBox>
    </>
  )
}

const QuestionChannelUserItem = ({item, idx, percent}) => {
  return (
    <SubjectList>
      <SubjectListBlock>항목 {++idx}</SubjectListBlock>
      <SubjectListBlock>{item.text}</SubjectListBlock>
      <SubjectListBlock Rel>
        <BarBox>
          <BarGraph first style={{width: percent}}/>
        </BarBox>
        <BarNumber>
          <BarPercent>{percent}</BarPercent>
          <BarPerson>{item.selectionCount.toLocaleString()}명</BarPerson>
        </BarNumber>
      </SubjectListBlock>
    </SubjectList>
  )
}

const QuestionChannelUserImageItem = ({item, idx, percent}) => {
  const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;
  return (
    <SurveyQuestionListWrapper>
      <SurveyImageItem width='140px' height='140px'>
        {item.url === '' || item.url.includes('amazonaws') ?
          <img src={`${item.url}` || ImgBack2} alt="img"/> :
          <img src={`${domain}${item.url}` || ImgBack2} alt="img"/>
        }
      </SurveyImageItem>
      <SubjectListBlock img>
        <SubjectListBlock>항목 {++idx}</SubjectListBlock>
        <SubjectListBlock>{item.text}</SubjectListBlock>
        <SubjectListBlock Rel>
          <BarBox img>
            <BarGraph first style={{width: percent}}/>
          </BarBox>
          <BarNumber>
            <BarPercent>{percent}</BarPercent>
            <BarPerson>{item.selectionCount.toLocaleString()}명</BarPerson>
          </BarNumber>
        </SubjectListBlock>
      </SubjectListBlock>
    </SurveyQuestionListWrapper>
  )
}

const SurveyManageDetail = ({
                              detailInfo,
                              type,
                              onThumbnail,
                              setOnThumbnail,
                              pollAuth,
                              openInspectionModal,
                              handelInspectionCheck,
                            }) => {
  // const [surveyStatus, setSurveyStatus] = useState(null)
  // const [pollViewType, setPollViewType] = useState(null)
  // const [initSurveyStatus, setInitSurveyStatus] = useState(null)
  const [categoryList, setCategoryList] = useState([])    // 카테고리 목록
  const [previewImages, setPreviewImages] = useState([]);       // 이미지 미리보기용 state
  const [uploadImages, setUploadImages] = useState([]);       // 이미지 업로드용 state
  const [onRewardDetailModal, setOnRewardDetailModal] = useState(false);
  const [onParticipationCountModal, setOnParticipationCountModal] = useState(false);
  const [onInviteModal, setOnInviteModal] = useState(false);
  const [onUpdateModal, setOnUpdateModal] = useState(false);       // 이미지 업로드용 state
  const [onStopModal, setOnStopModal] = useState(false);       // 반려 사유 state
  const [onCompleteModal, setOnCompleteModal] = useState(false)
  const [onUserPollStopModal, setOnUserPollStopModal] = useState(false);
  const [userPollStopData, setUserPollStopData] = useState({
    date: dayjs().format(DATE.FORMAT.DOT),
    userPollStopData: '',
    createdBy: detailInfo && detailInfo.createdBy,
    title: detailInfo && detailInfo.title
  });
  const refGradeFirst = useRef(null);                             // 보상단위: 1단계 회원
  const refGradeSecond = useRef(null);
  const navigate = useNavigate();
  const pollCategoryListRequest = useRequest("get", "category/poll/simple/list");
  const channelPollUpdate = useRequest("patch", `poll/${detailInfo.id}/update`);
  const channelPollBasicUpdate = useRequest("patch", `poll/${detailInfo.id}/basic/update`);
  const surveyCompleteRequest = useRequest("patch", `poll/${detailInfo.id}/complete`);
  const channelParticipantsListRequest = useRequest("get", `poll/${detailInfo.id}/participants`);
  const channelRewardListRequest = useRequest("get", `poll/${detailInfo.id}/reward/list`);
  const channelParticipantsPrivateListRequest = useRequest("get", `poll/${detailInfo.id}/private/participants`);
  const channelRewardPrivateListRequest = useRequest("get", `poll/${detailInfo.id}/private/reward/list`);
  const privateInviteListRequest = useRequest("get", `poll/${detailInfo.id}/invited`);
  const userPollStopRequest = useRequest("patch", `poll/${detailInfo.id}/private/stop`);


  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    getValues,
    formState: {isSubmitting},
  } = useForm();
  const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

  const {
    update: paidUnitsUpdate,
  } = useFieldArray({
    control,
    name: 'paidUnits',
  });

  const {
    field: questionsField,
    append: questionsAppend,
    remove: questionsRemove,
    update: questionsUpdate,
    replace: questionsReplace
  } = useFieldArray({
    control,
    name: 'questions'
  });

  const onSubmit = async (data) => {

    if (!data.viewStatus && !data.reasonForStop) {
      if (detailInfo.viewStatus) {
        setOnStopModal(true)
        return
      }
    }

    let customData


    await HandleThumbnailUpload(uploadImages, 'poll').then((thumbnailImages) => {
        customData = [data].map((value) =>
          ({...value, thumbnailImages: thumbnailImages})
        )
      }
    ).catch(error => console.log(error))


    const questionItems = data.questions.map(question => question.items.map((item) => ({...item})))

    await SurveyItemsUpload(questionItems, 'poll')
      .then((convertItems) => {
        for (let i = 0; i < convertItems.length; i++) {
          data.questions[i].items = convertItems[i]
        }
      }).catch(error => console.log(error))

    if (type === 'channel') {
      const basicUpdateDate = customData[0]
      delete basicUpdateDate.paidUnits
      delete basicUpdateDate.questions
      delete basicUpdateDate.isPaid
      channelPollBasicUpdate.call(...customData, 'body')
    } else {
      channelPollUpdate.call(...customData, 'body')
    }


  }

  const SelectCustomSmallStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: 122,
        height: 30,
        border: "1px solid #757575",
        borderRadius: "8px",
        padding: 0,
        backgroundColor: "#F5F5F5",
        caretColor: 'transparent',
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        fontSize: '14px',
      }),
    }),
    [],
  );

  const handleChangeKeyword = (keywords) => {
    setValue('keywords', keywords);
  }

  const handleChangeThumbnail = (e) => {
    const file = e.target.files[0]
    const name = e.target.files[0].name
    const ImageFileType = ['image/jpg', 'image/jpeg', 'image/png'];
    const MAX_LENGTH = 5;

    if (previewImages.length >= MAX_LENGTH) {
      alert(`썸네일은 최대 ${MAX_LENGTH}개까지 등록 가능합니다.`)
      e.target.value = null;
      return
    } else if (!ImageFileType.includes(file.type)) {
      alert("등록하신 파일의 확장자를 확인해주세요.");
      e.target.value = null;
      return;
    } else if (file.size > 30 * 1024 * 1024) {
      alert("등록하신 파일의 크기를 확인해주세요.");
      e.target.value = null;
      return
    }

    setUploadImages(prevState => [...prevState, {url: file, isRepresentative: false}])

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const res = reader.result;
      setPreviewImages(prevState => [...prevState, {url: res, name: name, isRepresentative: false}])
    };

    e.target.value = null;
  }

  const handleThumbnailRepresentative = (index) => {
    setPreviewImages(prevState => prevState.map((image, idx) =>
      ({...image, isRepresentative: index === idx}))
    )

    setUploadImages(prevState => prevState.map((image, idx) =>
      ({...image, isRepresentative: index === idx})))
  }

  const removeThumbnail = (index) => {
    if (uploadImages.length === 1) {
      alert('최소 1개 대표이미지가 있어야 됩니다.')
      return
    }
    setUploadImages(prevState =>
      prevState.filter((thumbnail, idx) => {
        return index !== idx
      }).map((d, idx) => {
        return {
          ...d,
          isRepresentative: idx === 0
        }
      })
    );

    setPreviewImages(prevState =>
      prevState.filter((thumbnail, idx) => {
        return index !== idx
      }).map((d, idx) => {
        return {
          ...d,
          isRepresentative: idx === 0
        }
      })
    );
  }

  const openUpdateModal = () => {
    document.body.style.overflow = 'hidden';
    setOnUpdateModal(true)
  }

  const closeDetailModal = (e) => {
    document.body.style.overflow = 'auto';
    const id = e.currentTarget.id

    if (id === 'reward') {
      setOnRewardDetailModal(false)
    } else if (id === 'participantsCount') {
      setOnParticipationCountModal(false)
    } else if (id === 'invite') {
      setOnInviteModal(false)
    }
  }

  const closeUpdateModal = () => {
    document.body.style.overflow = 'auto';
    setOnUpdateModal(false)
    if (type === 'channel') {
      navigate('/surveyMange/channelSurvey')
    } else {
      navigate('/surveyMange/inspection')
    }
  }

  const closeStopReasonModal = () => {
    document.body.style.overflow = 'auto';
    setOnStopModal(false)
  }

  const openDetailModal = (e) => {
    document.body.style.overflow = 'hidden';
    const id = e.target.id

    if (id === 'reward') {
      setOnRewardDetailModal(true)
    } else if (id === 'participantsCount') {
      setOnParticipationCountModal(true)
    } else if (id === 'invite') {
      setOnInviteModal(true)
    }
  }

  // const [
  //     {
  //         user: {info},
  //     },
  // ] = useGlobalStore();

  const handlePaidUnits = (e) => {
    const id = e.target.id;
    if (!watch().isPaid) {
      return
    }
    if (id === 'FIRST') {
      paidUnitsUpdate(0, {id: watch().paidUnits[0].id, grade: e.target.id, unit: parseInt(e.target.value)});
    } else if (id === 'SECOND') {
      paidUnitsUpdate(1, {id: watch().paidUnits[1].id, grade: e.target.id, unit: parseInt(e.target.value)});
    }
  }

  const handleReasonForStopValue = (e) => {
    e.target.value = e.target.value.slice(0, 500)
    setValue('reasonForStop', e.target.value);
  }

  // const pollAuth = useMemo(() => {
  //     const accessAuth = info?.access?.pollAccess;
  //     const authArray = accessAuth.split("");
  //     return authArray;
  // }, [info]);

  const filterEndAtTime = (time) => {
    const selectedDate = new Date(time);

    if (watch().startAt) {
      return watch().startAt.getTime() < selectedDate.getTime();
    }
  };

  const addQuestion = () => {
    if (watch().questions.length >= 5) {
      alert("등록 가능 질문 초과");
      return;
    }
    questionsAppend(QUESTION_DEFAULT)
  }

  const removeQuestion = (idx) => {
    if (!window.confirm("삭제 하시겠습니까?")) {
      return;
    }
    questionsRemove(idx)
  }

  const limitLength = (e, length) => {
    const name = e.target.name;
    e.target.value = e.target.value.substring(0, length)
    setValue(name, e.target.value);
  }

  const openCompleteReasonModal = () => {
    setOnCompleteModal(true)
    document.body.style.overflow = 'hidden';
  }

  const closeCompleteReasonModal = () => {
    setOnCompleteModal(false)
    document.body.style.overflow = 'auto';
  }

  const openUserPollStopReasonModal = () => {
    setOnUserPollStopModal(true)
    document.body.style.overflow = 'hidden';
  }

  const closeUserPollStopReasonModal = () => {
    setOnUserPollStopModal(false)
    document.body.style.overflow = 'auto';
  }

  const UserPollStopSubmit = () => {
    userPollStopRequest.call({ReasonForStop: userPollStopData.reasonForStop}, 'body')
  }

  const patchCompleteReason = () => {
    surveyCompleteRequest.call({reasonForComplete: watch().reasonForComplete}, 'body')
  }

  const onChangeUserPollStopReason = (e) => {
    const value = e.target.value;
    const truncatedValue = value.slice(0, 500);

    setUserPollStopData(prevState => ({
      ...prevState,
      reasonForStop: value.length > 500 ? truncatedValue : value
    }));
  }


  useEffect(() => {
    pollCategoryListRequest.call()
  }, []);

  useEffect(() => {
    if (!detailInfo) {
      return;
    }

    setValue('status', detailInfo.status);
    setValue('isPaid', detailInfo.isPaid);
    setValue('viewType', detailInfo.viewType);
    setValue('startAt', detailInfo.startAt && new Date(detailInfo.startAt));
    setValue('endAt', detailInfo.endAt && new Date(detailInfo.endAt));
    setValue('contents', detailInfo.contents);
    setValue('thumbnailImages', detailInfo.thumbnailImages);
    setUploadImages(detailInfo.thumbnailImages);
    setPreviewImages(detailInfo.thumbnailImages);
    setValue('paidUnits', detailInfo.paidUnits);
    setValue('title', detailInfo.title);
    // setValue('keywords', detailInfo.keywords);
    setValue('questions', detailInfo.questions);
    setValue('viewStatus', detailInfo.viewStatus);

    if (detailInfo.keywords && detailInfo.questions) {
      setValue('keywords', detailInfo.keywords);
      setValue('questions', detailInfo.questions);
    }

    setUserPollStopData({
      date: dayjs().format(DATE.FORMAT.DOT),
      contents: '',
      createdBy: detailInfo && detailInfo.createdBy,
      title: detailInfo && detailInfo.title,
    })
  }, [detailInfo, type]);


  useEffect(() => {
    switch (pollCategoryListRequest.state) {
      case API_STATE.done:
        const {response} = pollCategoryListRequest.response;
        if (response) {
          const list = response?.data?.map((data) => ({
            id: data.id, value: data.name, label: data.name
          }))
          setCategoryList(list)

          if (detailInfo.categoryId) {
            const initCategoryValue = list.filter((item) => item.id === detailInfo.categoryId)
            setValue('categoryId', initCategoryValue[0].id)
          }
        }
        break;
      default:
        break;
    }

    switch (channelPollUpdate.state) {
      case API_STATE.done:
        if (channelPollUpdate.response.success === false) {
          alert(`error: ${channelPollUpdate.response.error.message}`)

        } else {
          openUpdateModal()
        }
        break;
      default:
        break;
    }
  }, [pollCategoryListRequest.state, pollCategoryListRequest.error, pollCategoryListRequest.response, detailInfo.categoryName, detailInfo.categoryId, detailInfo, channelPollUpdate.state, channelPollUpdate.error, channelPollUpdate.response]);

  useEffect(() => {
    switch (channelPollBasicUpdate.state) {
      case API_STATE.done:
        if (channelPollBasicUpdate.response.success) {
          setOnStopModal(false)
          openUpdateModal()
        } else {
          alert('실패')
        }
        break;
      default:
        break;
    }
  }, [channelPollBasicUpdate.state, channelPollBasicUpdate.error, channelPollBasicUpdate.response]);

  useEffect(() => {
    switch (surveyCompleteRequest.state) {
      case API_STATE.done:
        if (surveyCompleteRequest.response.success) {
          closeCompleteReasonModal()
          openUpdateModal()
        } else {
          openUpdateModal()
        }
        break;
      default:
        break;
    }
  }, [surveyCompleteRequest.state, surveyCompleteRequest.error, surveyCompleteRequest.response]);


  useEffect(() => {
    switch (userPollStopRequest.state) {
      case API_STATE.done:
        if (userPollStopRequest.response.success) {
          closeUserPollStopReasonModal()
          alert('성공')
          navigate('/surveyMange/userSurvey')
        } else {
          alert('실패')
        }
        break;
      default:
        break;
    }
  }, [userPollStopRequest.state, userPollStopRequest.error, userPollStopRequest.response]);

  return (
    <>
      <ChannelPollTitle>
        <BackBtn type='button' onClick={() => navigate(-1)}/>
        설문 상세보기
        <TitleNotice>*설문 검수 완료 이후에는 기본 정보 외 다른 사항은 수정 불가능합니다.</TitleNotice>
        {(detailInfo && (detailInfo.status === "COMPLETE" || detailInfo.status === "EXHAUSTED" || detailInfo.status === "EXPIRED")) &&
          <Button mid type='button' onClick={() => navigate(`/bigdata/${detailInfo.id}`)}>
            빅데이터 결과보기
          </Button>
        }
      </ChannelPollTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MenuInfoCont survey>
          <List wide>
            <ListUl>
              <ListLi>
                <Wrapper Row maxWidth='951px'>
                  <Wrapper Center width='auto'>
                    <ListLtxt>{type !== 'user' ? '등록 채널' : '등록 회원'}</ListLtxt>
                    <ListRtxt line>
                      {type !== 'user' ?
                        <a href={`/channel/detail/${detailInfo.channelId}`}>
                          {detailInfo.createdBy}
                        </a> : detailInfo.createdBy
                      }
                    </ListRtxt>
                  </Wrapper>
                  {type && type === 'user' &&
                    <Wrapper Center Row width='auto'>
                      <ListLtxt width='140px'>설문 상태</ListLtxt>
                      <ListRtxt>
                        <span>{USER_SURVEY_STATUS[detailInfo.status]}</span>
                      </ListRtxt>
                    </Wrapper>
                  }
                  {type && (type !== 'user') &&
                    <>
                      {watch().status === 'COMPLETE' || watch().status === 'EXPIRED' ?
                        <Wrapper maxWidth='364px'>
                          <ListLtxt width='100px'>노출 구분</ListLtxt>
                          <ListRtxt w>
                            <span>{watch().viewType}</span>
                          </ListRtxt>
                        </Wrapper> :
                        <Wrapper width='auto'>
                          <ListLtxt width='100px'>노출 구분</ListLtxt>
                          <ListRtxt>
                            <Controller
                              name="viewType"
                              control={control}
                              render={({field: {onChange, value}}) =>
                                (<Wrapper Row width='240px'>
                                    <RewardRadioWrapper>
                                      <input type="radio" value="S"
                                             id='viewType_S'
                                             checked={value === 'S'}
                                             onChange={() => onChange('S')}
                                      />
                                      <label htmlFor='viewType_S'>S</label>
                                    </RewardRadioWrapper>
                                    <RewardRadioWrapper>
                                      <input type="radio" value="M"
                                             id='viewType_M'
                                             checked={value === 'M'}
                                             onChange={() => onChange('M')}
                                      />
                                      <label htmlFor='viewType_M'>M</label>
                                    </RewardRadioWrapper>
                                    <RewardRadioWrapper>
                                      <input type="radio" value="L"
                                             id='viewType_L'
                                             checked={value === 'L'}
                                             onChange={() => onChange('L')}
                                      />
                                      <label htmlFor='viewType_L'>L</label>
                                    </RewardRadioWrapper>
                                  </Wrapper>
                                )
                              }
                              // rules={{required: true}}
                            />
                          </ListRtxt>
                        </Wrapper>
                      }
                    </>
                  }
                </Wrapper>
              </ListLi>
              {(type && (type !== 'inspection') && (type === 'user')) ||
                <ListLi display='flex'>
                  <Wrapper Row maxWidth='951px'>
                    <Wrapper Center Row width='auto'>
                      <ListLtxt width='140px'>설문 상태</ListLtxt>
                      <ListRtxt>
                        <span>{USER_SURVEY_STATUS[detailInfo.status]}</span>
                      </ListRtxt>
                    </Wrapper>
                    {(type && type === 'channel') &&
                      <Wrapper width='auto' Center>
                        <ListLtxt width='100px'>노출 상태</ListLtxt>
                        <ListRtxt>
                          <Controller
                            name="viewStatus"
                            control={control}
                            render={({field: {onChange, value}}) =>
                              (<Wrapper Row width='240px'>
                                <RewardRadioWrapper>
                                  <input type="radio" value="true" id='viewStatus_on'
                                         checked={value}
                                         onChange={() => onChange(true)}
                                  />
                                  <label htmlFor='viewStatus_on'>활성화</label>
                                </RewardRadioWrapper>
                                <RewardRadioWrapper>
                                  <input type="radio" value="false"
                                         id='viewStatus_off'
                                         checked={!value}
                                         onChange={() => onChange(false)}
                                  />
                                  <label htmlFor='viewStatus_off'>비활성화</label>
                                </RewardRadioWrapper>
                              </Wrapper>)
                            }
                            // rules={{required: true}}
                          />
                        </ListRtxt>
                      </Wrapper>
                    }
                  </Wrapper>
                </ListLi>
              }
            </ListUl>
          </List>
        </MenuInfoCont>
        <CommonSurveyDetail detailInfo={detailInfo}
                            refGradeFirst={refGradeFirst}
                            refGradeSecond={refGradeSecond}
                            watch={watch()}
                            type={type}
                            control={control}
                            categoryList={categoryList}
                            handleChangeKeyword={handleChangeKeyword}
                            handleChangeThumbnail={handleChangeThumbnail}
                            domain={domain}
                            setOnThumbnail={setOnThumbnail}
                            onThumbnail={onThumbnail}
                            previewImages={previewImages}
                            handleThumbnailRepresentative={handleThumbnailRepresentative}
                            removeThumbnail={removeThumbnail}
                            handlePaidUnits={handlePaidUnits}
                            filterEndAtTime={filterEndAtTime}
                            openDetailModal={openDetailModal}
                            openCompleteReasonModal={openCompleteReasonModal}
                            openUserPollStopReasonModal={openUserPollStopReasonModal}
        />
        {type && type === 'inspection' &&
          <>
            <MenuInfoCont survey>
              <MenuInfoSub>
                질문 세부사항
                <MenuInfoSubButton add
                                   type='button'
                                   onClick={addQuestion}
                >
                  질문 추가
                  <AddCount>({watch().questions && watch().questions.length}/5)</AddCount>
                </MenuInfoSubButton>
              </MenuInfoSub>

              {watch().questions && watch().questions.map((question, idx) => {
                return <Question question={question}
                                 questionsIndex={idx}
                                 control={control}
                                 setValue={setValue}
                                 register={register}
                                 SelectCustomSmallStyles={SelectCustomSmallStyles}
                                 questionsField={questionsField}
                                 limitLength={limitLength}
                                 removeQuestion={removeQuestion}
                                 getValues={getValues}
                                 questionsReplace={questionsReplace}
                                 domain={domain}
                                 questionsUpdate={questionsUpdate}
                />
              })}
            </MenuInfoCont>
            {pollAuth.some(d => d === "W") &&
              <ButtonRight>
                {/*<Button mini line onClick={handleCheckPoll.bind(null, false)}>검수 반려</Button>*/}
                <GrayButton mini line type='button' onClick={() => openInspectionModal(false)}
                            disabled={watch().status === 'RETURN'}>
                  검수 반려
                </GrayButton>
                <Button mini type='button' onClick={handelInspectionCheck}
                        line={watch().status !== 'RETURN'}
                        disabled={watch().status === 'RETURN'}>
                  검수 완료
                </Button>
                <Button mini disabled={isSubmitting}>수정하기</Button>
              </ButtonRight>
            }
          </>
        }
        {type && type !== 'inspection' &&
          <MenuInfoCont survey>
            <MenuInfoSub>질문 세부사항</MenuInfoSub>
            {detailInfo.questions && detailInfo.questions.map((question, idx) => {
              return (
                <>
                  <MenuInfoSub key={idx} {...(idx > 0 && {ssub: true})}>질문 {++idx}</MenuInfoSub>
                  <List wide>
                    <ListUl>
                      <ListLi>
                        <ListLtxt top>질문 제목</ListLtxt>
                        <ListRtxt Max>{question.title}</ListRtxt>
                      </ListLi>
                      <ListLi>
                        <ListLtxt>답변 형태</ListLtxt>
                        <ListRtxt>{ANSWER_TYPE[question.answerType]}</ListRtxt>
                        {question.answerType !== 'SINGULAR' &&
                          <ListRtxt Notice>
                            {`최소 ${question.minimum}개 ~ 최대 ${question.maximum}개 선택`}
                          </ListRtxt>
                        }
                      </ListLi>
                      <ListLi>
                        <ListLtxt top>항목 구분</ListLtxt>
                        {
                          type && (type === 'channel' || type === 'user') &&
                          <QuestionChannelUser question={question}
                                               participantsCount={detailInfo.participantsCount}/>
                        }
                        {
                          type && type === 'inspection' &&
                          <QuestionInspection question={question}/>
                        }
                      </ListLi>
                    </ListUl>
                  </List>
                </>
              )
            })}
          </MenuInfoCont>
        }
        {onThumbnail &&
          <Modal state={onThumbnail} setState={setOnThumbnail}
                 data={type === 'user' ? detailInfo.thumbnailImageUrl : detailInfo.thumbnailImages}
                 domain={domain}/>
        }
        {onRewardDetailModal &&
          <RewardModal closeDetailModal={closeDetailModal}
                       request={type === 'user' ? channelRewardPrivateListRequest : channelRewardListRequest}
                       modalList={true}
          />
        }
        {onParticipationCountModal &&
          <ParticipationCountModal closeDetailModal={closeDetailModal}
                                   request={type === 'user' ? channelParticipantsPrivateListRequest : channelParticipantsListRequest}
                                   modalList={true}
          />
        }
        {onInviteModal &&
          <ParticipationCountModal closeDetailModal={closeDetailModal}
                                   request={privateInviteListRequest}
                                   id='invite'
                                   modalList={true}
          />
        }
        {onUpdateModal &&
          <StyledModal>
            <ModalContainer update>
              <RewardDetailTitle>{watch().reasonForComplete ? '수동 완료' : '검수 관리'}</RewardDetailTitle>
              <RewardDetailContent>
                <span>{`[${detailInfo.title}]`}</span>
                <span>수정 되었습니다.</span>
              </RewardDetailContent>
              <Button mini type='button' onClick={closeUpdateModal} margin='48px 0 0 0'>확인</Button>
              <CloseButton type='button' onClick={closeUpdateModal}>
                <img src={CloseIcon} alt='close'/>
              </CloseButton>
            </ModalContainer>
          </StyledModal>
        }
        {onStopModal &&
          <StyledModal>
            <ModalContainer stop>
              <RewardDetailTitle>비활성화 사유</RewardDetailTitle>
              <RewardDetailContent>
                <span>{`[${detailInfo.title}]`}</span>
                <Wrapper Column>
                  <ModalText TextCenter margin='0 0 6px 0'>아래에 비활성화 사유를 입력해주세요.</ModalText>
                  <StyledTextArea {...register('reasonForStop', {
                    onChange: handleReasonForStopValue
                  })}/>
                  <Wrapper>
                    <ModalText lengthLimit margin='4px 0 0 auto'>
                      {watch().reasonForStop ? watch().reasonForStop.length : 0}/500</ModalText>
                  </Wrapper>
                </Wrapper>
              </RewardDetailContent>
              <Button mini margin='48px 0 0 0'>확인</Button>
              <CloseButton button='button' onClick={closeStopReasonModal}>
                <img src={CloseIcon} alt='close'/>
              </CloseButton>
            </ModalContainer>
          </StyledModal>
        }
        {onCompleteModal &&
          <StyledModal>
            <ModalContainer stop>
              <RewardDetailTitle>수동 완료</RewardDetailTitle>
              <RewardDetailContent>
                <span>{`[${detailInfo.title}]`}</span>
                <Wrapper Column>
                  <ModalText TextCenter margin='0 0 6px 0'>아래에 수동 완료 사유를 입력해주세요.</ModalText>
                  <StyledTextArea {...register('reasonForComplete', {})}/>
                  <Wrapper>
                    <ModalText lengthLimit margin='4px 0 0 auto'>
                      {watch().reasonForComplete ? watch().reasonForComplete.length : 0}/500</ModalText>
                  </Wrapper>
                </Wrapper>
              </RewardDetailContent>
              <Button mini margin='48px 0 0 0' type='button' onClick={patchCompleteReason}>확인</Button>
              <CloseButton type='button' onClick={closeCompleteReasonModal}>
                <img src={CloseIcon} alt='close'/>
              </CloseButton>
            </ModalContainer>
          </StyledModal>
        }
        {onUserPollStopModal &&
          <StyledModal>
            <ModalContainer UserStop>
              <RewardDetailTitle>정지 사유 입력</RewardDetailTitle>
              <RewardDetailContent Report>
                <ModalSpan>정지일</ModalSpan>
                <ModalSpan>{userPollStopData.date}</ModalSpan>
                <ModalSpan>등록 회원</ModalSpan>
                <ModalSpan>{userPollStopData.createdBy}</ModalSpan>
                <ModalSpan>설문 제목</ModalSpan>
                <ModalSpan>{userPollStopData.title}</ModalSpan>
              </RewardDetailContent>
              <Wrapper Column margin='48px 0 0 0'>
                <ModalSpan margin='0 0 6px 0'>아래에 정지 사유를 입력해주세요.</ModalSpan>
                <span style={{color: '#C91615'}}>*등록된 정지 사유는 설문을 개설한 회원에게 노출됩니다.</span>
                <UserPollStopReasonTextArea value={userPollStopData.reasonForStop}
                  // onChange={(e) => setUserPollStopData(prevState => ({
                  //     ...prevState,
                  //     reasonForStop: e.target.value
                  // }))}
                                            onChange={onChangeUserPollStopReason}
                />
              </Wrapper>
              <LengthCountSpan width='100%'>
                {userPollStopData.reasonForStop ? userPollStopData.reasonForStop.length : 0} /500
              </LengthCountSpan>
              <Wrapper margin='48px 0 0 0' Center>
                <GrayButton mini
                            margin='0 20px 0 auto'
                            type='button'
                            onClick={closeUserPollStopReasonModal}
                >
                  취소
                </GrayButton>
                <Button mini type='button' margin='0 auto 0 0'
                        onClick={UserPollStopSubmit}>확인</Button>
              </Wrapper>
              <CloseButton onClick={closeUserPollStopReasonModal} type='button'>
                <img src={CloseIcon} alt='close'/>
              </CloseButton>
            </ModalContainer>
          </StyledModal>
        }
      </form>
    </>
  )
}

export default SurveyManageDetail
