import React, {useEffect, useState} from 'react';
import {
    Button,
    CloseButton,
    ModalContainer,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal
} from '../../styledComponents/commonStyle';
import Table from '../Table';
import {SURVEY_REWARD_COUNT} from '../../constants/channel';
import Page from '../Page';
import CloseIcon from 'asset/img/icon__close_black.svg';
import useBoard from '../../../hooks/useBoard';
import dayjs from 'dayjs';
import DATE from '../../constants/date';
import pageMaker from '../Page/pageMaker';
import {useSearchParams} from 'react-router-dom';
import PreviewImg from 'asset/img/img__priview.png'

function RewardModal({...props}) {
    const {closeDetailModal, id, request, modalList} = props;
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({offset: 0, limit: 10});
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    // const request = useRequest("get", `poll/${id}/reward/list`);
    // const request = useRequest("get", `ranking/${id}/reward/list`);
    // poll/{pollId}/event/reward/list
    const [searchParams, setSearchParams] = useSearchParams();
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;


    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
        }));
    });

    const handlePage = (pageNum) => {
        setCurPage(pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
    }

    const columnRenderer = (key, value, _, row) => {
        if (key === "profileImageUrl") {
            return <img style={{width: '32px', height: '32px', borderRadius: '50%'}}
                        src={value ? `${domain}${value}` : PreviewImg}
                        alt='profileImageUrl'/>
        }
        return value
    }

    useEffect(() => {
        const {offset, limit} = formData;

        fetch({
            ...params,
            offset,
            limit
        });
    }, [formData]);

    return (
        <StyledModal>
            <ModalContainer Reward>
                <RewardDetailTitle>보상 상세내역</RewardDetailTitle>
                <span style={{margin: '34px 0 0 auto'}}>{`총 ${totalNum}명`}</span>
                <RewardDetailContent Reward>
                    <Table Member
                           modalList={modalList}
                           pageData={formData}
                           totalPages={pageInfo?.totalPages}
                           columns={SURVEY_REWARD_COUNT}
                           rows={rows}
                           totalNum={totalNum}
                           Reward
                           searchParams={searchParams}
                           columnRenderer={columnRenderer}
                           defaultListValue={true}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage} modalList={modalList}/>
                </RewardDetailContent>
                <Button mini id='reward' onClick={(e) => closeDetailModal(e)} margin='auto 0 0 0'
                        type='button'>확인</Button>
                <CloseButton id='reward' onClick={(e) => closeDetailModal(e)} type='button'>
                    <img src={CloseIcon} alt='close'/>
                </CloseButton>
            </ModalContainer>
        </StyledModal>
    );
}

export default RewardModal;
