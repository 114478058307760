export const TYPE = {
    BALANCE: '밸런스',
    KEYWORD: '트렌드'
};

export const DETAIL_VIEW_TYPE = {
    BALANCE: '밸런스 게임',
    KEYWORD: '트렌드 랭킹'
};

export const STATUS = {
    ONGOING: '진행중',
    STOP: '정지'
};

export const LIST_STATUS = {
    ONGOING: '진행중',
    STOP: '정지',
    COMPLETE: '수동 완료',
    INSPECTION: '검수 중',
    RETURN: '검수반려',
    EXPIRED: '기한 만료'
    
};

export const PLATFORM_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '5%'
}, {
    key: 'type',
    title: '구분',
    width: '12%'
}, {
    key: 'title',
    title: '설문 제목',
    width: '*'
}, {
    key: 'period',
    title: '설문 기간',
    width: '20%'
}, {
    key: 'participantsCount',
    title: '참여자수',
    width: '9%'
}, {
    key: 'status',
    title: '상태',
    width: '9%'
}, {
    key: 'viewStatus',
    title: '노출',
    width: '9%'
}
];


export const STATUS_OPTIONS = [
    {
        text: STATUS.ONGOING,
        value: 'ONGOING'
    },
    {
        text: STATUS.STOP,
        value: 'STOP'
    }
];
