import CryptoJS from "crypto-js";
import { NODE_ENV } from "common/constants/env";
import {SECRET_KEY} from "common/constants/envKeys";

export default class cryptoService {
	static enctypt = (data) => {
		if (NODE_ENV === "production") {
			return CryptoJS.AES.encrypt(JSON.stringify({ ...data }), SECRET_KEY).toString();
		} else {
			return JSON.stringify({ ...data });
		}
	};

	static dectypt = (ciphertext) => {
		if (NODE_ENV === "production") {
			const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
			return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		} else {
			return JSON.parse(ciphertext);
		}
	};
}
