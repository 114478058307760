import React from 'react';
import {useNavigate} from "react-router-dom";

import {DropDownContainer, MenuItem, MenuItemList, SideBarMenu} from './DropDownStyle';
import KeyboardArrowDown from '../../asset/img/icon__keyboard_arrow_down.png';
import KeyboardArrowUp from '../../asset/img/icon__keyboard_arrow_up.png';

function DropDown({options, title, closeMenuState, setCloseMenuState}) {
    const navigate = useNavigate();

    const openMenu = () => {
        if (options) {
            let customData = closeMenuState.map((state) => state.title === title ?
                {...state, isOpen: !state.isOpen} : state
            );

            const isOpenCount = closeMenuState.filter((menu) => menu.isOpen).length

            if (isOpenCount > 0) {
                const firstOpen = closeMenuState.findIndex((count) => count.isOpen);
                closeMenuState[firstOpen].isOpen = false
            }

            setCloseMenuState(customData);

        } else {
            navigate('/dashboard');
        }
    }

    return (
        <DropDownContainer>
            <SideBarMenu onClick={openMenu}>
                {/* <ImageWrapper/> */}
                <span>{title}</span>
                {options ? (
                    closeMenuState && closeMenuState.filter((state) => state.title === title)[0].isOpen ?
                        <img src={KeyboardArrowUp} alt={'KeyboardArrowUp'}/> :
                        <img src={KeyboardArrowDown} alt={'KeyboardArrowDown'}/>) : null}
            </SideBarMenu>
            {options &&
                <MenuItemList>
                    {options.map((option) =>
                        closeMenuState && closeMenuState.filter((state) => state.title === title)[0].isOpen &&
                        <MenuItem key={option.id} onClick={() => navigate(option.link)}>
                        <span>
                            {option.item}
                        </span>
                        </MenuItem>
                    )}
                </MenuItemList>}
        </DropDownContainer>
    );
}

export default DropDown;
