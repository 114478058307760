import React, {useEffect, useMemo, useState} from "react";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import {API_STATE} from "common/constants/state";
import {useNavigate} from "react-router-dom";
import ko from 'date-fns/locale/ko'
import parseISO from 'date-fns/parseISO'
import DATE from "common/constants/date";
import {QUESTION_DEFAULT} from 'common/constants/poll';

import {AddCount, MenuInfoCont, MenuInfoSub, MenuInfoSubButton} from "pages/Mypage/MypageStyle"
import {
    Button,
    ButtonRight,
    CustomHeader,
    DropdownIndicator,
    List,
    ListLi,
    ListLtxt,
    ListRspan,
    ListRtxt,
    ListRtxtSubNum,
    ListRtxtSubText,
    ListUl,
    MenuArea,
    MenuInfoArea,
    RewardRadioWrapper,
    StyledDatePicker,
    Wrapper,
} from "common/styledComponents/commonStyle";
// import Select from 'common/components/CommonSelect'
import Title from '../../../../common/components/Title';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import Select from 'react-select';
import TestInput from '../../../../common/components/TestInput';
import TestTextarea from '../../../../common/components/TestTextarea';
import ArrowRight from '../../../../asset/img/icon__arrow_forward.png';
import Keywords from '../../../../common/components/Keywords';
import Thumbnails_v2 from '../../../../common/components/Thumbnails_v2/Thumbnails_v2';
import {ListInputBerry} from '../channelSurveySytle';
import {Question} from './Questions2';
import PopupPreview from './innerComponent/PopupPreview';
import PollThumbnailUpload from '../../../../common/js/PollThumbnailUpload';
import SurveyItemsUpload from '../../../../common/js/SurveyItemsUpload';


const ChannelSurveyCreate = () => {
    const navigate = useNavigate();
    const today = dayjs().format(DATE.FORMAT.DASH_TIME);
    const tomorrow = dayjs().add(1, 'day').format(DATE.FORMAT.DASH_TIME);
    const [channelList, setChannelList] = useState([]);             // 채널 목록
    const [categoryList, setCategoryList] = useState([]);           // 카테고리 목록
    const [channelId, setChannelId] = useState(null);                 // 채널아이디
    const [channelUserId, setChannelUserId] = useState('');         // 비즈 회원 아이디
    const [contents, setContents] = useState('');                   // 설문 설명
    const [categoryId, setCategoryId] = useState('');               // 설문 카테고리
    const [keywords, setKeywords] = useState([]);                   // 설문 키워드
    const [startAt, setStartAt] = useState(today);                  // 시작일
    const [endAt, setEndAt] = useState(tomorrow);                      // 종료일
    const [isPaid, setIsPaid] = useState('Y');                      // 보상 지급 여부
    const [whichPopup, setWhichPopup] = useState(false)
    const [channelDetail, setChannelDetail] = useState('');
    // const [previewImages, setPreviewImages] = useState([]);       // 이미지 미리보기용 state
    // const [uploadImages, setUploadImages] = useState([]);       // 이미지 업로드용 state
    // const [questions, setQuestions] = useState([QUESTION_DEFAULT]); // 질문
    const {control, watch, handleSubmit, setValue, getValues, register} = useForm({
        defaultValues: {
            thumbnailImages: [],
            isPaid: true,
            questions: [QUESTION_DEFAULT],
            viewType: 'S',
            title: ''
        }
    });
    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;


    const channelListRequest = useRequest("get", "channel/simple/list");
    const pollCategoryListRequest = useRequest("get", "category/poll/simple/list");
    const channelDetailRequest = useRequest("get", `channel/${channelId}`);


    const savePollNewRequest = useRequest("post", "poll/new");

    const {field: paidUnitsFields, append: paidUnitsAppend, remove: paidUnitsRemove, update, replace} = useFieldArray({
        control,
        name: 'paidUnits', // 배열 필드의 이름
    });

    const {
        field: questionsField,
        append: questionsAppend,
        remove: questionsRemove,
        update: questionsUpdate,
        replace: questionsReplace
    } = useFieldArray({
        control,
        name: 'questions'
    });

    const {
        field: thumbnailImagesField,
        append: thumbnailImagesAppend,
        remove: thumbnailImagesRemove,
        update: thumbnailImagesUpdate,
        replace: thumbnailImagesReplace
    } = useFieldArray({
        control,
        name: 'thumbnailImages'
    });

    const handlePaidUnits = (e) => {
        const id = e.target.id;

        if (!watch().isPaid) {
            return
        }

        if (id === 'FIRST') {
            update(0, {grade: e.target.id, unit: parseInt(e.target.value)});
        } else if (id === 'SECOND') {
            update(1, {grade: e.target.id, unit: parseInt(e.target.value)});
        }
    }

    const onSubmit = async (data) => {
        console.log(data, 'data')
        // if (!formState.isDirty) {
        //     return;
        // }

        let customData = []
        if (data.isPaid === false) {
            data.paidUnits = [
                {
                    grade: 'FIRST',
                    unit: 0
                }, {
                    grade: 'SECOND',
                    unit: 0
                }
            ]
        }
        customData = [{...data, isPaid: isPaid === 'Y'}]

        await PollThumbnailUpload(data.thumbnailImages, 'poll').then((thumbnailImages) => {
                customData = [data].map((value) =>
                    ({...value, thumbnailImages: thumbnailImages})
                )
            }
        ).catch(error => console.log(error))

        const questionItems = data.questions.map(question => question.items.map((item) => ({...item})))

        await SurveyItemsUpload(questionItems, 'poll')
            .then((convertItems) => {
                for (let i = 0; i < convertItems.length; i++) {
                    data.questions[i].items = convertItems[i]
                }
            }).catch(error => console.log(error))

        savePollNewRequest.call(...customData, 'body')
    }

    const handleChangeKeyword = (keywords) => {
        setKeywords(keywords);
        setValue('keywords', keywords)
    }

    const limitLength = (e, length) => {
        const name = e.target.name;
        e.target.value = e.target.value.substring(0, length)
        setValue(name, e.target.value);
    }

    const ChannelIdSelectStyles = useMemo(
        () => ({
            menu: (provided, state) => ({
                ...provided,
                width: 280,
                overflowX: 'hidden',
            }),

            option: (provided, state) => ({
                ...provided,
                // width: 608,
                width: '100%',
                overflowX: 'hidden',
            }),

            control: (provided) => ({
                ...provided,
                width: 280,
                overflowX: 'hidden',
                border: "1px solid #757575",
                borderRadius: "8px",
                marginRight: '48px',
            }),

            singleValue: (provided, state) => ({
                ...provided,
            }),

        }),
        [],
    );

    const ChannelStatusSelectStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                borderRadius: "8px",
                width: 122
            }),
            control: (provided) => ({
                ...provided,
                width: 122,
                border: "1px solid #757575",
                borderRadius: "8px",
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
        }),
        [],
    );

    const SelectCustomMediumStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 280,
                border: "1px solid #757575",
                borderRadius: "8px",
                backgroundColor: "#F5F5F5",
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    const SelectCustomSmallStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 122,
                height: 30,
                border: "1px solid #757575",
                borderRadius: "8px",
                padding: 0,
                backgroundColor: "#F5F5F5",
                caretColor: 'transparent',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: 0,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
            placeholder: (provided, state) => ({
                ...provided,
                fontSize: '14px',
            }),
        }),
        [],
    );


    const handleChangeThumbnail = (e) => {
        const file = e.target.files[0]
        const name = e.target.files[0].name
        const ImageFileType = ['image/jpg', 'image/jpeg', 'image/png'];
        const MAX_LENGTH = 5;

        if (watch().thumbnailImages.length >= MAX_LENGTH) {
            alert(`썸네일은 최대 ${MAX_LENGTH}개까지 등록 가능합니다.`)
            e.target.value = null;

            return
        } else if (!ImageFileType.includes(file.type)) {
            alert("등록하신 파일의 확장자를 확인해주세요.");
            e.target.value = null;

            return;
        } else if (file.size > 3 * 1024 * 1024) {
            alert("이미지는 3mb 이하로 올려주세요.");
            e.target.value = null;

            return
        }
        // setUploadImages(prevState => [...prevState, {url: file, isRepresentative: false}])
        // setUploadImages(prevState => prevState.length === 0 ? [...prevState, {
        //     url: file,
        //     isRepresentative: true
        // }] : [...prevState, {url: file, isRepresentative: false}])

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const res = reader.result;
            // setPreviewImages(prevState => prevState.length === 0 ? [...prevState, {
            //     url: res,
            //     name: name,
            //     isRepresentative: true
            // }] : [...prevState, {url: res, name: name, isRepresentative: false}])
            if (watch().thumbnailImages.length === 0) {
                thumbnailImagesAppend([{
                        url: file,
                        previewImage: res,
                        name: name,
                        isRepresentative: true
                    }]
                )
            } else {
                thumbnailImagesAppend([{
                        url: file,
                        previewImage: res,
                        name: name,
                        isRepresentative: false
                    }]
                )
            }
        }

        e.target.value = null;
    }

    const handleThumbnailRepresentative = (index) => {
        // setPreviewImages(prevState => prevState.map((image, idx) =>
        //     ({...image, isRepresentative: index === idx}))
        // )
        //
        // setUploadImages(prevState => prevState.map((image, idx) =>
        //     ({...image, isRepresentative: index === idx})))

        const changeRepresentativeArray = watch().thumbnailImages.map((image, idx) =>
            ({...image, isRepresentative: index === idx}))

        thumbnailImagesReplace(changeRepresentativeArray)
    }

    const getBizId = (selectedOption) => {
        setChannelId(selectedOption.value)
    }

    const removeThumbnail = (index) => {
        const removeRepresentative = watch().thumbnailImages.filter((thumbnail, idx) => {
            return index !== idx
        }).map((d, idx) => {
            return {
                ...d,
                isRepresentative: idx === 0
            }
        })

        thumbnailImagesRemove(index)
        thumbnailImagesReplace(removeRepresentative)

    }
    const filterEndAt = (date) => {
        const currentDate = new Date(date);

        if (watch().startAt >= watch().endAt) {
            const customDate = currentDate.setMinutes(currentDate.getMinutes() + 30);
            setValue('endAt', customDate)
        }
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterEndAtTime = (time) => {
        const selectedDate = new Date(time);
        const startDate = new Date(watch().startAt);

        return new Date(startDate).getTime() < selectedDate.getTime()
    };

    const removeQuestion = (idx) => {
        if (watch().questions.length === 1) {
            return;
        }

        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }

        questionsRemove(idx)
    }

    const addQuestion = () => {
        if (watch().questions.length >= 5) {
            alert("등록 가능 질문 초과");
            return;
        }
        questionsAppend(QUESTION_DEFAULT)
    }

    // const resetQuestionItems = (e) => {
    //     const type = e.target.value;
    //     questionsReplace({...QUESTION_DEFAULT, itemType: type})
    // }

    useEffect(() => {
        channelListRequest.call()
        pollCategoryListRequest.call();
    }, []);


    useEffect(() => {
        channelId && channelDetailRequest.call();
    }, [channelId]);

    useEffect(() => {
        categoryList && categoryList[0] && setCategoryId(categoryList[0].value);
    }, [categoryList]);

    useEffect(() => {
        switch (channelListRequest.state) {
            case API_STATE.done:
                const {response} = channelListRequest.response;
                if (response) {
                    setChannelList(response.map((data) => ({
                        label: data.channelName,
                        value: data.id
                    })))
                }
                break;
            default:
                break;
        }
    }, [channelListRequest.state, channelListRequest.error, channelListRequest.response]);

    useEffect(() => {
        switch (pollCategoryListRequest.state) {
            case API_STATE.done:
                const {response} = pollCategoryListRequest.response;
                if (response) {
                    setCategoryList(response?.data?.map((data) => ({
                        label: data.name,
                        value: data.id
                    })))
                }
                break;
            default:
                break;
        }
    }, [pollCategoryListRequest.state, pollCategoryListRequest.error, pollCategoryListRequest.response]);

    useEffect(() => {
        switch (channelDetailRequest.state) {
            case API_STATE.done:
                const {
                    response: {
                        userId
                    }
                } = channelDetailRequest.response;
                setChannelUserId(userId);
                setChannelDetail(channelDetailRequest?.response?.response);
                break;
            default:
                break;
        }
    }, [channelDetailRequest.state, channelDetailRequest.error, channelDetailRequest.response]);

    useEffect(() => {
        switch (savePollNewRequest.state) {
            case API_STATE.done:
                if (savePollNewRequest.response.success) {
                    alert("채널 설문이 생성 되었습니다.");
                    navigate('/surveyMange/inspection');
                } else if (savePollNewRequest.response.success === false) {
                    alert("미입력 값을 확인해주세요.");
                }
                break;
            default:
                break;
        }
    }, [savePollNewRequest.state, savePollNewRequest.error, savePollNewRequest.response]);

    return (
        <>
            <MenuArea>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MenuInfoArea>
                        <Title>설문 등록하기</Title>
                        <MenuInfoCont survey>
                            <ListUl wide>
                                <ListUl>
                                    <ListLi>
                                        <Wrapper Row maxWidth='994px'>
                                            <Wrapper width='auto'>
                                                <ListLtxt>채널 선택</ListLtxt>
                                                <ListRtxt>
                                                    <ListRtxtSubText>
                                                        <ListRspan>
                                                            <Controller
                                                                name="channelId"
                                                                control={control}
                                                                render={({field: {onChange}}) =>
                                                                    <Select
                                                                        options={channelList}
                                                                        styles={ChannelIdSelectStyles}
                                                                        onChange={(selectedOption) => {
                                                                            onChange(selectedOption && parseInt(selectedOption.value))
                                                                            getBizId(selectedOption)
                                                                        }}
                                                                        isSearchable={false}
                                                                    />
                                                                }
                                                                // rules={{required: true}}
                                                            />
                                                        </ListRspan>
                                                    </ListRtxtSubText>
                                                </ListRtxt>
                                            </Wrapper>
                                            <Wrapper width='auto'>
                                                <ListLtxt>노출 구분</ListLtxt>
                                                <ListRtxt>
                                                    <ListRtxtSubText>
                                                        <ListRspan>
                                                            <Controller
                                                                name="viewType"
                                                                control={control}
                                                                render={({field: {onChange, value}}) =>
                                                                    // <Select
                                                                    //     options={VIEW_TYPE_OPTIONS}
                                                                    //     styles={ChannelStatusSelectStyles}
                                                                    //     placeholder='L, M, S'
                                                                    //     onChange={selectedOption => {
                                                                    //         onChange(selectedOption && selectedOption.value);
                                                                    //     }}
                                                                    // />

                                                                    <Wrapper Row width='240px'>
                                                                        <RewardRadioWrapper>
                                                                            <input type="radio" value="S"
                                                                                   id='viewType_S'
                                                                                   checked={value === 'S'}
                                                                                   onChange={() => onChange('S')}
                                                                            />
                                                                            <label htmlFor='viewType_S'>S</label>
                                                                        </RewardRadioWrapper>
                                                                        <RewardRadioWrapper>
                                                                            <input type="radio" value="M"
                                                                                   id='viewType_M'
                                                                                   checked={value === 'M'}
                                                                                   onChange={() => onChange('M')}
                                                                            />
                                                                            <label htmlFor='viewType_M'>M</label>
                                                                        </RewardRadioWrapper>
                                                                        <RewardRadioWrapper>
                                                                            <input type="radio" value="L"
                                                                                   id='viewType_L'
                                                                                   checked={value === 'L'}
                                                                                   onChange={() => onChange('L')}
                                                                            />
                                                                            <label htmlFor='viewType_L'>L</label>
                                                                        </RewardRadioWrapper>
                                                                    </Wrapper>
                                                                }
                                                                // rules={{required: true}}
                                                            />
                                                        </ListRspan>
                                                    </ListRtxtSubText>
                                                </ListRtxt>
                                            </Wrapper>
                                        </Wrapper>
                                    </ListLi>
                                    <ListLi>
                                        <ListLtxt>비즈 회원 아이디</ListLtxt>
                                        <ListRtxt>{channelUserId}</ListRtxt>
                                    </ListLi>
                                </ListUl>
                            </ListUl>
                        </MenuInfoCont>
                        <MenuInfoCont survey>
                            <MenuInfoSub>기본 정보</MenuInfoSub>
                            <List wide>
                                <ListUl>
                                    <ListLi>
                                        <ListLtxt>설문 제목</ListLtxt>
                                        <ListRtxt>
                                            <Controller
                                                name="title"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <TestInput
                                                        withLength={true}
                                                        maxLength={30}
                                                        value={value && value}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 20) {
                                                                onChange(e.target.value.slice(0, 30))
                                                            } else {
                                                                onChange(e)
                                                            }
                                                        }}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </ListRtxt>
                                    </ListLi>
                                    <ListLi>
                                        <ListLtxt top>설문 설명</ListLtxt>
                                        <ListRtxt>
                                            <Controller
                                                name="contents"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <TestTextarea
                                                        withLength={true}
                                                        maxLength={1000}
                                                        value={value && value}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 100) {
                                                                onChange(e.target.value.slice(0, 1000))
                                                            } else {
                                                                onChange(e.target.value)
                                                            }
                                                        }}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </ListRtxt>
                                    </ListLi>
                                    <ListLi>
                                        <Wrapper>
                                            <ListLtxt top>설문 기간</ListLtxt>
                                            <div>
                                                <Controller
                                                    name="startAt"
                                                    control={control}
                                                    render={({field: {onChange, value}}) =>
                                                        <StyledDatePicker
                                                            pollStart
                                                            id="startAt"
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            selected={value}
                                                            // onChange={(date, e) => {
                                                            //     onChange(date, e)
                                                            // }}
                                                            onChange={(date) => {
                                                                onChange(date)
                                                                filterEndAt(date)
                                                            }}
                                                            placeholderText="YYYY-MM-DD HH:mm"
                                                            locale={ko}
                                                            showTimeSelect
                                                            minDate={parseISO(today)}
                                                            timeCaption="시간"
                                                            filterTime={filterPassedTime}
                                                            renderCustomHeader={(params) =>
                                                                <CustomHeader {...params} />}
                                                        />
                                                    }
                                                    // rules={{required: true}}
                                                />
                                            </div>
                                            <ListRspan><img src={ArrowRight} alt=""/></ListRspan>
                                            <div>
                                                <Controller
                                                    name="endAt"
                                                    control={control}
                                                    render={({field: {onChange, value}}) =>
                                                        <StyledDatePicker
                                                            pollEnd
                                                            id="endAt"
                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                            onChange={(date) => {
                                                                onChange(date)
                                                            }}
                                                            selected={value}
                                                            placeholderText="YYYY-MM-DD HH:mm"
                                                            locale={ko}
                                                            minDate={watch().startAt ? watch().startAt : parseISO(today)}
                                                            timeCaption="시간"
                                                            showTimeSelect
                                                            filterTime={watch().startAt && filterEndAtTime}
                                                            renderCustomHeader={(params) =>
                                                                <CustomHeader {...params} />}
                                                        />
                                                    }
                                                    // rules={{required: true}}
                                                />
                                            </div>
                                        </Wrapper>
                                    </ListLi>
                                    <ListLi>
                                        <ListLtxt>설문 카테고리</ListLtxt>
                                        <ListRtxt>
                                            <Controller
                                                name="categoryId"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Select
                                                        components={DropdownIndicator}
                                                        options={categoryList}
                                                        styles={SelectCustomMediumStyles}
                                                        onChange={selectedOption => {
                                                            onChange(selectedOption.value);
                                                        }}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </ListRtxt>
                                    </ListLi>
                                    <ListLi>
                                        <ListLtxt what top>설문 키워드</ListLtxt>
                                        <ListRtxt Max>
                                            <Controller
                                                name="keywords"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Keywords onChange={handleChangeKeyword}
                                                              initValue=''
                                                              withLength={true}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </ListRtxt>
                                    </ListLi>
                                    <ListLi>
                                        <ListLtxt top>썸네일 이미지</ListLtxt>
                                        <ListRtxt>
                                            <Controller
                                                name="thumbnailImages"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Thumbnails_v2
                                                        // previewImages={previewImages}
                                                        previewImages={watch().thumbnailImages}
                                                        thumbnails={watch().thumbnailImages}
                                                        onChange={handleChangeThumbnail}
                                                        handleThumbnailRepresentative={handleThumbnailRepresentative}
                                                        removeThumbnail={removeThumbnail}
                                                    />
                                                }
                                                // rules={{required: true}}
                                            />
                                        </ListRtxt>
                                    </ListLi>
                                </ListUl>
                            </List>
                        </MenuInfoCont>
                        <MenuInfoCont survey>
                            <MenuInfoSub>설문 보상</MenuInfoSub>
                            <List wide>
                                <ListUl>
                                    <ListLi>
                                        <ListLtxt>보상 지급 여부</ListLtxt>
                                        <ListRtxt>
                                            <Controller name='isPaid'
                                                        control={control}
                                                        render={({field: {onChange, value}}) =>
                                                            <Wrapper width='304px' Row>
                                                                <RewardRadioWrapper>
                                                                    <input type="radio" value="true" id='paid'
                                                                           checked={value}
                                                                           onChange={() => onChange(true)}
                                                                    />
                                                                    <label htmlFor='paid'>지급</label>
                                                                </RewardRadioWrapper>
                                                                <RewardRadioWrapper>
                                                                    <input type="radio" value="false" id='no_paid'
                                                                           checked={value === false}
                                                                           onChange={() => onChange(false)}
                                                                    />
                                                                    <label htmlFor='no_paid'>지급 안함</label>
                                                                </RewardRadioWrapper>
                                                            </Wrapper>
                                                        }
                                            />
                                        </ListRtxt>
                                    </ListLi>
                                    <ListLi>
                                        <Wrapper>
                                            <ListLtxt what>보상 단위</ListLtxt>
                                            <Wrapper width='auto' margin='0 0 0 24px'>
                                                <ListRtxtSubText>
                                                    1단계 회원
                                                    <Controller name='paidUnits[0]'
                                                                control={control}
                                                                render={({field: {onChange, value}}) =>
                                                                    <ListRspan Rel>
                                                                        <ListInputBerry
                                                                            type="number"
                                                                            placeholder='0'
                                                                            id='FIRST'
                                                                            // onChange={(e) => props.handlePaidUnits(e)}
                                                                            onChange={handlePaidUnits}
                                                                            disabled={!watch().isPaid}
                                                                        />
                                                                        <ListRtxtSubNum
                                                                            watchedValue
                                                                            berry
                                                                            In>Berry</ListRtxtSubNum>
                                                                    </ListRspan>
                                                                }
                                                    />
                                                </ListRtxtSubText>
                                                <ListRtxtSubText>
                                                    2단계 회원
                                                    <Controller name='paidUnits[1]'
                                                                control={control}
                                                                render={({field: {onChange, value}}) =>
                                                                    <ListRspan Rel>
                                                                        <ListInputBerry
                                                                            type="number"
                                                                            placeholder="0"
                                                                            id='SECOND'
                                                                            onChange={handlePaidUnits}
                                                                            disabled={!watch().isPaid}
                                                                        />
                                                                        <ListRtxtSubNum
                                                                            disabled={watch().isPaid}
                                                                            berry
                                                                            In>Berry</ListRtxtSubNum>
                                                                    </ListRspan>
                                                                }
                                                    />
                                                </ListRtxtSubText>
                                            </Wrapper>
                                        </Wrapper>
                                    </ListLi>
                                </ListUl>
                            </List>
                        </MenuInfoCont>
                        {/*<Question questions={questions} onChange={(_questions) => {*/}
                        {/*    // debugger;*/}
                        {/*    setQuestions(_questions);*/}
                        {/*    setValue('questions', _questions)*/}
                        {/*}}/>*/}
                        <MenuInfoSub>
                            질문 세부사항
                            <MenuInfoSubButton add
                                               type='button'
                                               onClick={addQuestion}
                            >
                                질문 추가
                                <AddCount>({watch().questions && watch().questions.length}/5)</AddCount>
                            </MenuInfoSubButton>
                        </MenuInfoSub>

                        {watch().questions && watch().questions.map((question, idx) => {
                            return <Question question={question}
                                             key={idx}
                                             questionsIndex={idx}
                                             control={control}
                                             setValue={setValue}
                                             register={register}
                                             SelectCustomSmallStyles={SelectCustomSmallStyles}
                                             limitLength={limitLength}
                                             removeQuestion={removeQuestion}
                                             getValues={getValues}
                                             domain={domain}
                                             questionsUpdate={questionsUpdate}
                            />
                        })}
                        <ButtonRight>
                            <Button mini preview type='button' onClick={() => setWhichPopup(0)}>미리 보기</Button>
                            <Button mini>등록 하기</Button>
                        </ButtonRight>
                    </MenuInfoArea>
                </form>
            </MenuArea>
            <PopupPreview
                whichPopup={whichPopup}
                setWhichPopup={setWhichPopup}
                questions={watch().questions}
                channelDetail={channelDetail}
                title={watch().title || ''}
                contents={watch().contents}
                categoryId={watch().categoryId}
                keywords={keywords}
                startAt={startAt}
                endAt={endAt}
                isPaid={isPaid}
                categoryList={categoryList}
                thumbnails={watch().thumbnailImages && watch().thumbnailImages}
            />
        </>
    )
}

export default ChannelSurveyCreate;
