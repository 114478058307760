import React,{useEffect, useState} from "react";
import { Case, If } from 'common/components/If';
import CrtWalletOne from 'pages/Join/Steps/CrtWalletOne';
import CrtWalletTwo from 'pages/Join/Steps/CrtWalletTwo';
import CrtWalletThree from 'pages/Join/Steps/CrtWalletThree';
import CrtChennal from 'pages/Join/Steps/CrtChennal';

const Join = () => {
    const [currentStep , setCurrentStep] = useState(0);
    return(
        <>
            <If condition={currentStep}  {...{setCurrentStep}}>
                <Case condition={0}>
                    <CrtWalletOne />
                </Case>
                <Case condition={1}>
                    <CrtWalletTwo />
                </Case>
                <Case condition={2}>
                    <CrtWalletThree />
                </Case>
                <Case condition={3}>
                    <CrtChennal />
                </Case>
            </If>
        </>
    )
}

export default Join;