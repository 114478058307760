export const TextByteCheck = (text, setTextValue, maxByte) => {
    let totalByte = 0;

    for (let i = 0; i < text.length; i++) {

        const each_char = text.charAt(i);
        const uni_char = escape(each_char);

        if (uni_char.length > 4) {
            // 한글 2Byte
            totalByte += 2;
        } else {
            // 영문,숫자,특수문자 1Byte
            totalByte += 1;
        }

        if (totalByte >= maxByte) {
            const res = text.substring(0, maxByte);
            text = res;
            setTextValue(res);
        }
    }
};
