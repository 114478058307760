import {PopBox, PopButton, PopClose, PopHeadTxt, PopUp, Wrapper} from 'common/styledComponents/commonStyle';
import React from 'react';
import CloseIcon from "asset/img/icon__close.png";

const OperationPointModal = ({...props}) => {
    const {
        title,
        children,
        handleCharge,
        Approve,
        setOpenModal,
        onReturnReason,
        openChargeReturnModal,
        returnCharge,
        openReturnReason,
        resetModalState
    } = props;

    const closeModal = () => {
        setOpenModal(false)
        if (resetModalState) {
            resetModalState()
        }
    }

    return (
        <PopUp>
            <PopBox check>
                <PopHeadTxt>{title}</PopHeadTxt>
                <PopClose onClick={closeModal}>
                    <img src={CloseIcon} alt='icon_close'/>
                </PopClose>
                {children}
                <Wrapper Row={Approve} width={Approve ? undefined : 'auto'}>
                    {Approve &&
                        <PopButton type='button' center line
                                   onClick={openReturnReason ? closeModal : onReturnReason}
                        >
                            {openReturnReason ? '취소' : '반려'}</PopButton>
                    }
                    {openChargeReturnModal &&
                        <PopButton type='button' center line onClick={closeModal}>취소</PopButton>
                    }
                    <PopButton type='button' center onClick={openReturnReason ? returnCharge : handleCharge}>
                        {openReturnReason ? '반려' : '확인'}
                    </PopButton>
                </Wrapper>
            </PopBox>
        </PopUp>
    );
};

export default OperationPointModal;
