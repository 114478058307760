import { SET_USER, CLEAR_USER } from "./actions";
import persistHelper from "util/persist";

export const INITIAL_STATE = {
	info: {
		userId: "",
		role: "",
		lastLoginAt: null,
		access: []
	},
};

export const PERSISTED_STATE = persistHelper.initState("user", INITIAL_STATE);

export const REDUCER = (state = PERSISTED_STATE, action) => {
	switch (action.type) {
		case SET_USER:
			return persistHelper.setPersist("user", {
				...state,
				info: action.payload.info,
			});
		case CLEAR_USER:
			return persistHelper.setPersist("user", {
				...state,
			});
		default:
			return state;
	}
};
