import React,{useState} from "react";
import { useForm } from "react-hook-form";

import Step1 from "asset/img/step1.png"
import {WalletCont , WalletTop , WalletStep , WalletStepOne , WalletStepImg , WalletBox , WalletTxt , InputCont , InputDiv , Input , EyeIcon , ErrorMsg, BlueButton , DisableButton} from "pages/Join/JoinStyle"
import {Button,Center} from "common/styledComponents/commonStyle";

import Eye from "asset/img/icon__visibility.png"
import EyeOff from "asset/img/icon__visibility_off.png"
const CrtWalletOne = ({setCurrentStep}) => {
    const { 
        register, 
        handleSubmit,
        formState: { errors },
        setError
     } = useForm();

    const [pwdShow, setPwdShow] = useState(false);
    const [pwdConfirmShow, setPwdConfirmShow] = useState(false);

    const onSubmit = (data) => {
        const {password, confirm} = data;
        if (password !== confirm) {
            setError("confirm", { type: "focus", message: "비밀번호가 일치하지 않습니다." }, { shouldFocus: true });
            return;
        }
        setCurrentStep(1)
    }
    return(
        <>
            <Center>
                <WalletCont>
                    <WalletTop>Welcome!</WalletTop>
                    <WalletStep>
                        <WalletStepOne>Step</WalletStepOne>
                        <WalletStepImg><img src={Step1} alt=""></img></WalletStepImg>
                    </WalletStep>
                    <WalletBox>
                        <WalletTxt>
                            안녕하세요! [ 채널명 ] 회원님,<br></br>
                            디지털 자산 운용을 위해 블록체인 지갑 생성이 필요합니다.
                        </WalletTxt>
                        
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputCont>
                                <InputDiv>                            
                                    <Input type={pwdShow ? 'text' : 'password'} placeholder="비밀번호를 입력해주세요." {...register('password', {required: true})}></Input>
                                    <EyeIcon img={pwdShow ? Eye : EyeOff} onClick={()=>setPwdShow(!pwdShow)} />
                                </InputDiv>
                                <InputDiv>                            
                                    <Input type={pwdConfirmShow ? 'text' : 'password'} placeholder="비밀번호를 재입력하여 확인해주세요." {...register('confirm', {required: true})}></Input>
                                    <EyeIcon img={pwdConfirmShow ? Eye : EyeOff} onClick={()=>setPwdConfirmShow(!pwdConfirmShow)} />
                                    {errors?.confirm && <ErrorMsg>{errors?.confirm?.message}</ErrorMsg>}
                                </InputDiv>
                            </InputCont>
                            {/* <Button onClick={()=>setCurrentStep(1)}>확인</Button> */}
                            <Button>확인</Button>
                        </form>
                    </WalletBox>
                </WalletCont>
            </Center>
        </>
    )
}

export default CrtWalletOne;