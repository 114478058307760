export const STATUS = {
    NORMAL: '정상',
    WITHDRAWAL: '탈퇴',
    ONGOING: '진행중',
    INSPECTION: '검수 대기',
    RETURN: '검수반려',
    COMPLETE: '완료',
    EXPIRED: '기한 만료',
    STOP: '정지'
};

export const CHANNEL_DETAIL_STATUS = {
    NORMAL: '정상',
    STOP: '정지',
    WITHDRAWAL: '탈퇴',
};

export const CHANNEL_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '9%'
}, {
    key: 'name',
    title: '채널명',
    width: '41%'
}, {
    key: 'likeCount',
    title: '관심회원 수',
    width: '16%'
}, {
    key: 'pollCount',
    title: '진행중 설문',
    width: '16%'
}, {
    key: 'createdAt',
    title: '생성일',
    width: '9%'
}, {
    key: 'status',
    title: '상태',
    width: '9%'
}];

export const SURVEY_REWARD_COUNT = [
    {
        key: 'id',
        title: 'NO.',
        width: '4%'
    }, {
        key: 'createdAt',
        title: '설문 완료일',
        width: '5%'
    }, {
        key: 'profileImageUrl',
        title: '',
        width: '2%'
    }, {
        key: 'userId',
        title: '닉네임',
        width: '5%'
    }, {
        key: 'amount',
        title: '보상 Berry',
        width: '5%'
    }
];

export const SURVEY_PARTICIPATION_COUNT = [
    {
        key: 'id',
        title: 'NO.',
        width: '2%'
    }, {
        key: 'createdAt',
        title: '설문 완료일',
        width: '2%'
    }, {
        key: 'profileImageUrl',
        title: '',
        width: '2%'
    }, {
        key: 'userId',
        title: '닉네임',
        width: '2%'
    }
];

export const SURVEY_INVITE_COUNT = [
    {
        key: 'id',
        title: 'NO.',
        width: '2%'
    }, {
        key: 'createdAt',
        title: '초대일',
        width: '2%'
    }, {
        key: 'profileImageUrl',
        title: '',
        width: '2%'
    }, {
        key: 'userId',
        title: '닉네임',
        width: '2%'
    }
];

export const POLL_LIST_COLUMNS = [
    {
        key: 'id',
        title: 'NO.',
        width: '4.555%'
    }, {
        key: 'title',
        title: '설문 제목',
        width: '38.89%'
    }, {
        key: 'createdAt',
        title: '등록일',
        width: '5.85%'
    }, {
        key: 'period',
        title: '설문 기간',
        width: '20%'
    }, {
        key: 'participantsCount',
        title: '참여자',
        width: '10%'
    }, {
        key: 'status',
        title: '상태',
        width: '5.85%'
    }, {
        key: 'viewStatus',
        title: '노출',
        width: '5.85%'
    },
];
