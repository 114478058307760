import {
    FullPointBox,
    FullPointInputBox,
    FullPointTextarea,
    GrayButton,
    PopBox,
    PopButton,
    PopClose,
    PopHeadTxt,
    PopLeftTxt,
    PopTable,
    PopTxt,
    PopUp,
    Wrapper
} from 'common/styledComponents/commonStyle';
import React, {useEffect, useState} from 'react';
import CloseIcon from "asset/img/icon__close.png";
import useRequest from 'hooks/useRequest';
import {API_STATE} from 'common/constants/state';
import {LengthCountSpan} from '../../../Join/JoinStyle';

const CancelPopup = ({
                         openCancelModal,
                         setOpenCancelModal,
                         pointId,
                         cancelAmount,
                         setCancelAmount,
                         fetch,
                         chargeCancelInfo,
                         ...props
                     }) => {
    const [reasonForCancel, setReasonForCancel] = useState('')
    const cancelRequest = useRequest("patch", `point/${pointId}/cancel`)
    const {bizUserInfoRequest} = props;

    const handleCancel = () => {
        const doCancel = Number(cancelAmount);

        if (!reasonForCancel || reasonForCancel === '') {
            alert("취소 사유를 입력해주세요.")
            return;
        }

        if (doCancel <= 0) {
            alert("취소하실 Berry Amount 를 입력해 주세요.")
            return;
        }

        cancelRequest.call({
            amount: doCancel,
            reasonForCancel
        }, 'body')
    }

    const onChangeReasonForCancel = (e) => {
        const value = e.target.value

        if (value.length <= 500) {
            setReasonForCancel(value)
        }
    }

    useEffect(() => {
        switch (cancelRequest.state) {
            case API_STATE.done:
                console.log("cancelRequest result >>> ", cancelRequest.response)
                if (!cancelRequest.response.success) {
                    alert('실패!')
                    return
                }
                fetch({
                    limit: 5,
                    offset: 0
                })
                setCancelAmount(0);
                setOpenCancelModal(false);

                alert("성공적으로 취소 되었습니다.")
                bizUserInfoRequest.call()
                return;
            default:
                return;
        }
    }, [cancelRequest.state, cancelRequest.error, cancelRequest.response])

    useEffect(() => {
        if (!openCancelModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [openCancelModal]);

    return (
        <PopUp>
            <PopBox check>
                <PopHeadTxt>운용 포인트 충전 취소</PopHeadTxt>
                <PopClose onClick={() => setOpenCancelModal(false)}>
                    <img src={CloseIcon} alt='icon_close'/>
                </PopClose>
                <PopTable full>
                    <PopLeftTxt>충전 일자</PopLeftTxt>
                    <PopLeftTxt>{chargeCancelInfo.createdAt}</PopLeftTxt>
                    <PopLeftTxt>충전 포인트</PopLeftTxt>
                    <PopLeftTxt>{cancelAmount} Berry</PopLeftTxt>
                    <PopLeftTxt>충전 관리자</PopLeftTxt>
                    <PopLeftTxt>{chargeCancelInfo.userId}</PopLeftTxt>
                </PopTable>
                <FullPointBox full>
                    <PopTxt left>취소 사유에 대해 입력해주세요.</PopTxt>
                    <FullPointInputBox area Cancel>
                        <FullPointTextarea placeholder="내용을 입력해주세요." value={reasonForCancel}
                                           onChange={onChangeReasonForCancel}/>
                    </FullPointInputBox>
                    <LengthCountSpan width='100%'>
                        {reasonForCancel ? reasonForCancel.length : 0} /500
                    </LengthCountSpan>
                </FullPointBox>
                <Wrapper Center>
                    <GrayButton type="button" margin=' 0 auto 0 0'
                                onClick={() => setOpenCancelModal(false)}>취소</GrayButton>
                    <PopButton center onClick={handleCancel}>확인</PopButton>
                </Wrapper>
            </PopBox>
        </PopUp>
    );
};

export default CancelPopup;
