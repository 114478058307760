import {
    CheckBox,
    PopBox,
    PopButton,
    PopClose,
    PopHeadTxt,
    PopPollList,
    PopPollListBox,
    PopPollListTxtL,
    PopUp
} from 'common/styledComponents/commonStyle';
import CloseIcon from "asset/img/icon__close.png";
import React, {useEffect, useState} from 'react';
import {
    Caption,
    CheckInput,
    CheckLabel,
    TableCont,
    TBody,
    TbodyTd,
    TbodyTh,
    Thead,
    TheadTh,
    TheadTr,
    Tr
} from 'pages/User/UserStyle';
import {Table} from 'reactstrap';
import useRequest from 'hooks/useRequest';
import {API_STATE} from 'common/constants/state';

const ACCESS_ITEM = [{
    title: '사용자 관리',
    id: 'user',
    keyBind: 'userAccess'
}, {
    title: '채널 관리',
    id: 'channel',
    keyBind: 'channelAccess'
}, {
    title: '컨텐츠 관리',
    id: 'contents',
    keyBind: 'contentsAccess'
}, {
    title: '설문 관리',
    id: 'poll',
    keyBind: 'pollAccess'
}, {
    title: '포인트 관리',
    id: 'point',
    keyBind: 'pointAccess'
}, {
    title: '게시판 관리',
    id: 'board',
    keyBind: 'boardAccess'
}];

const ACCESS_TYPES = ['N', 'R', 'W', 'D'];

const ORDER = {
    N: 0,
    R: 1,
    W: 2,
    D: 3
}

const AccessItem = ({title, id, initAccess, onChange,}) => {
    const [access, setAccess] = useState('');

    useEffect(() => {
        setAccess(initAccess);
    }, [initAccess])

    const getSortedAccessArr = (str) => {
        const tmpArr = [];
        if (!str) {
            return [];
        }
        for (const s of str.toUpperCase().split('')) {
            const idx = ORDER[s];
            tmpArr[idx] = s;
        }
        return tmpArr;
    }

    const handleChange = (value, e) => {
        const {checked} = e.currentTarget;
        let accessWithType = getSortedAccessArr(access);
        if (value === 'N') {
            if (checked) {
                accessWithType = accessWithType.map(() => '');
                accessWithType[0] = 'N';
            }
        } else {
            if (value === "R") {
                if (!checked) {
                    accessWithType[ORDER["W"]] = '';
                    accessWithType[ORDER["D"]] = '';
                }
            } else if (value === "W") {
                // 쓰기 클릭시
                if (checked) { // 쓰기 : true
                    // 읽기 : true
                    accessWithType[ORDER["R"]] = "R";
                } else { // 쓰기 : false
                    // 삭제 : false
                    accessWithType[ORDER["D"]] = '';
                }
            } else if (value === "D") {
                // 삭제 클릭시
                if (checked) {
                    accessWithType[ORDER["R"]] = "R";
                    accessWithType[ORDER["W"]] = "W";
                }
            }

            if (checked) {
                accessWithType[0] = '';
                accessWithType[ORDER[value]] = value;
            } else {
                accessWithType[ORDER[value]] = '';
            }
        }

        accessWithType = accessWithType.join('');
        onChange(id, accessWithType);
        setAccess(accessWithType);
    }

    return (
        <Tr>
            <TbodyTh>{title}</TbodyTh>
            {
                ACCESS_TYPES.map((accessType, index) => {
                    return (
                        <TbodyTd>
                            <CheckBox>
                                <CheckInput id={`${id}${index}`} type="checkbox"
                                            checked={access.indexOf(accessType) !== -1}
                                            onChange={handleChange.bind(null, accessType)}/>
                                <CheckLabel htmlFor={`${id}${index}`}/>
                            </CheckBox>
                        </TbodyTd>
                    )
                })
            }
        </Tr>
    )
}

const AuthSettingModal = ({openModal, setOpenModal, userInfo}) => {
    const [access, setAccess] = useState({
        user: '',
        channel: '',
        contents: '',
        poll: '',
        point: '',
        board: '',
    });

    const userAccessDataRequest = useRequest("get", `user/${userInfo.id}/access`);
    const updateAdminAuthRequest = useRequest("patch", `user/${userInfo.id}/access/update`);

    useEffect(() => {
        userAccessDataRequest.call();
    }, [])

    useEffect(() => {
        switch (userAccessDataRequest.state) {
            case API_STATE.done:
                const {access} = userAccessDataRequest.response.response;
                setAccess({
                    user: access?.userAccess, // userAccess
                    channel: access?.channelAccess, // channelAccess
                    contents: access?.contentsAccess, // contentsAccess
                    poll: access?.pollAccess, // pollAccess
                    point: access?.pointAccess, // pointAccess
                    board: access?.boardAccess, // boardAccess
                });
                break;
            default:
                break;
        }
    }, [userAccessDataRequest.state, userAccessDataRequest.error, userAccessDataRequest.response])

    const handleChangeAccessItem = (type, accessWithType) => {

        const newObj = {
            ...access,
        }
        newObj[type] = accessWithType;
        setAccess(newObj);
    }

    // userId, userAccess, channelAccess, contentsAccess, pollAccess, pointAccess, boardAccess
    const handleAuthEdit = () => {
        const dataForm = {
            userAccess: access?.user,
            channelAccess: access?.channel,
            contentsAccess: access?.contents,
            pollAccess: access?.poll,
            pointAccess: access?.point,
            boardAccess: access?.board
        }
        console.log("Edit Button >>> ", access)
        console.log("Edit Button Click and the Data from them >> ", dataForm)
        updateAdminAuthRequest.call({
            ...dataForm,
        })
    }

    useEffect(() => {
        switch (updateAdminAuthRequest.state) {
            case API_STATE.done:
                if (updateAdminAuthRequest.response.success) {
                    alert("권한 수정이 정상적으로 완료됐습니다.");
                    setOpenModal(true);
                } else {
                    alert("권한 수정에 실패했습니다.")
                }
                break;
            case API_STATE.error:
                alert(updateAdminAuthRequest.error)
            default:
                break;
        }
    }, [updateAdminAuthRequest.state, updateAdminAuthRequest.error, updateAdminAuthRequest.response])

    return (
        <PopUp hidden={openModal}>
            <PopBox grade>
                <PopHeadTxt>회원 등급 설정</PopHeadTxt>
                <PopClose type="button" onClick={() => setOpenModal(true)}><img src={CloseIcon} alt=''/></PopClose>
                <PopPollListBox>
                    {/* <PopPollList>
                     <PopPollListTxtL>회원 등급</PopPollListTxtL>
                     <PopPollListTxtR>
                     <RadioBox>
                     <ListInput type="radio" name="type1"></ListInput>
                     <LabelName>최고 관리자</LabelName>
                     </RadioBox>
                     <RadioBox>
                     <ListInput type="radio" name="type1"></ListInput>
                     <LabelName>일반 관리자</LabelName>
                     </RadioBox>
                     </PopPollListTxtR>
                     </PopPollList>  */}

                    <PopPollList>
                        <PopPollListTxtL margin>권한 구분</PopPollListTxtL>
                        <TableCont>
                            <Table>
                                <Caption>권한 설정 테이블</Caption>
                                <Thead>
                                    <TheadTr>
                                        <TheadTh>항목</TheadTh>
                                        <TheadTh>권한 없음</TheadTh>
                                        <TheadTh>읽기</TheadTh>
                                        <TheadTh>쓰기</TheadTh>
                                        <TheadTh>삭제</TheadTh>
                                    </TheadTr>
                                </Thead>
                                <TBody>
                                    {
                                        ACCESS_ITEM.map((accessItem, index) => {
                                            return <AccessItem title={accessItem.title}
                                                               id={accessItem.id} initAccess={access[accessItem.id]}
                                                               onChange={handleChangeAccessItem}/>
                                        })
                                    }
                                </TBody>
                            </Table>
                        </TableCont>
                    </PopPollList>
                </PopPollListBox>
                <PopButton big type="button" onClick={handleAuthEdit}>수정</PopButton>
            </PopBox>
        </PopUp>
    );
};

export default AuthSettingModal;
