import styled, {css} from "styled-components";
import SearchIconGray from "asset/img/icon__search_gray.png"
import VeiwIcon from "asset/img/icon__find_in_page.png"
import CheckDone from "asset/img/icon__done.png"


// 카테고리 탭
export const CataTab = styled.div`
  margin: 48px 0;
  height: 60px;
`;
export const CataTabUl = styled.ul`
  display: inline-block;
  width: 100%;
`;
export const CataTabLi = styled.li`
  float: left;
  width: 33.33%;
  height: 60px;
  text-align: center;
  border: 1px solid #757575;
  border-radius: 8px 8px 0 0;
  padding: 18px 0;
  box-sizing: border-box;
  background-color: #f5f5f5;
  color: #757575;

  ${(props) =>
          props.on &&
          css`
            background-color: #fff;
            color: #1a1a1a;
            font-weight: 500;
            border-bottom: 0;
          `}
`;

// 검색박스
export const SearchCont = styled.div`
  position: relative;
  margin: 48px 0 0;
  clear: both;
`;

export const SearchBox = styled.div`
  width: 504px;
  display: inline-block;
`;
export const SearchDiv = styled.div`
  width: 380px;
  height: 36px;
  float: left;
  position: relative;

  &::before {
    content: "";
    background-image: url(${SearchIconGray});
    width: 24px;
    height: 24px;
    background-size: cover;
    position: absolute;
    left: 8px;
    top: 6px;
  }
`;
export const SearchInput = styled.input`
  padding: 8px 8px 8px 40px;
  border: 1px solid #dbdbdb;
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 100%;
  height: 100%;

  &::placeholder {
    color: #1a1a1a;
    font-size: 14px;
  }
`;

export const SearchBtn = styled.button`
  width: 100px;
  height: 36px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background-color: #dbdbdb;
  color: #1a1a1a;
  border-radius: 8px;
  margin-left: 24px;
`;

export const SearchSort = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
`;
export const SearchSortUl = styled.ul`

`;
export const SearchSortLi = styled.li`
  float: left;
  cursor: pointer;
  font-size: 14px;
  color: #424242;


  ${(props) =>
          props.on &&
          css`
            position: relative;
            color: #000065;

            &::before {
              content: "";
              position: absolute;
              left: -24px;
              top: -1px;
              background-image: url(${CheckDone});
              width: 24px;
              height: 24px;
              background-size: cover;
            }
          `}
  & + & {
    margin-left: 28px;
  }
`;


//테이블

export const Colgroup = styled.colgroup`
`;

export const BoardBoxCont = styled.div`

`;

export const BoardBox = styled.div`
  width: 1180px;
  margin: 64px auto 48px;
  ${(props) =>
          props.narrow &&
          css`
            margin: 48px auto;
          `}


`;


export const BoardTable = styled.table`
  width: 100%;
  text-align: center;
`;


export const BoardTableThead = styled.thead`
  border-bottom: 2px solid #000065;
`;

export const BoardTableTr = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const BoardTableTh = styled.th`
  font-size: 18px;
  font-weight: 500;
  padding: 16px 0;
`;

export const BoardTableTbody = styled.tbody`
  padding: 24px 0;
  border-bottom: 2px solid #dbdbdb;
`;

export const BoardTableTd = styled.td`
  padding: 18px 0;
  cursor: none;

  ${(props) =>
          props.left &&
          css`
            text-align: left;
          `}
  ${(props) =>
          props.right &&
          css`
            text-align: right;
          `}
  ${(props) =>
          props.red &&
          css`
            color: #C91615;
          `}
  ${(props) =>
          props.redline &&
          css`
            color: #C91615;
            text-decoration: underline;
          `}
  ${(props) =>
          props.gray &&
          css`
            color: #757575;
          `}

`;

export const BoardTableA = styled.a`
  background-image: url(${VeiwIcon});
  width: 24px;
  height: 24px;
  background-size: cover;
  font-size: 0;
  display: inline-block;
  vertical-align: top;
`;


//채널상세보기

export const ChannelVeiw = styled.div`
  padding-bottom: 80px;
`;

export const ChannelTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 4px solid black;
  //width: 1180px;
  margin-bottom: 48px;

  span {
    font-size: 36px;
    font-weight: 500;
    line-height: 52px;
    color: #1A1A1A;
  }
`;

export const NewChannelButton = styled.button`
  padding: 12px 8px;
  border-radius: 8px;
  background-color: #000065;
  width: 380px;
  height: 60px;

  span {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
  //width: 100%;

  img {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #757575;
    background-color: #F5F5F5;
    border-radius: 8px;
    padding-left: 40px;
    height: 36px;
    width: 620px;


    ::placeholder {
      color: #1A1A1A;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const ChannelSearchButton = styled.button`
  padding: 8px;
  border-radius: 8px;
  background-color: #000065;
  width: 100px;
  margin-left: 20px;

  span {
    display: block;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
`;

export const BoardContainer = styled.div`
`;

export const Board = styled.table`
  display: flex;
  flex-flow: column;
`;

export const BoardHead = styled.thead`

  tr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

export const BoardBody = styled.tbody`
  border-top: 2px solid #000065;
  border-bottom: 2px solid #DBDBDB;
  padding: 24px 0
`;

export const BoardTitle = styled.th`

  span {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const BoardRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  :last-child {
    margin-bottom: 0;
  }

  td {
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }

  }
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

export const PaginationIconContainer = styled.div`

  img + img {
    margin-left: 16px;
  }
`;

export const PaginationNumberContainer = styled.div`
  margin: 0 16px;

  button + button {
    margin-left: 16px;
  }
`;


