import styled from "styled-components";

export const CreatePlatformPollContainer = styled.div`
    padding: 48px 64px 80px;
    max-width: 1308px;
    //max-height: 1080px;
    margin:0 auto;
    background-color: #ffff;
`;

export const MainContainer = styled.div`
    display: flex;
    width: 100%;
    max-height: 787px;

    @media (max-width: 1919px) {
        height: 500px;
    }

    @media (max-height: 1079px) {
        height: 600px;
    }

    form {
        max-width: 800px;
        width: 100%;
        //max-height: 787px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
`;

export const ContentSection = styled.div`
    padding-right: 8px;
    overflow-y: scroll;
    width: 100%;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
    }
`;

export const CreateChannelTitleContainer = styled.div`
    padding-bottom: 12px;
    border-bottom: 4px solid black;
    margin-bottom: 48px;

    span {
        font-size: 36px;
        font-weight: 500;
        line-height: 52px;
        color: #1a1a1a;
    }
`;

export const PreviewWrapper = styled.div`
    max-width: 360px;
    width: calc(100% / 3.277777777777778);
    padding: 8px;
`;

export const Content = styled.div`
    height: ${(props) => props.height};
    width: 100%;

    ${(props) =>
        props.ChannelInput &&
        `
      display: flex;
      flex-flow: column;
      max-width: 608px;
  
      input {
          background-color: #FFFFFF;
          border: 1px solid #757575;
          border-radius: 8px;
          padding: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1A1A1A;
          // width: 608px;
          width: 100%;
      }
      
      span {
          height: 17px;
          color: #757575;
          margin-left: auto;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
      }
      
  `};

    ${(props) =>
        props.ChannelProfile &&
        `
      display: flex;
      justify-content: space-between;
  `};

    ${(props) =>
        props.PollType &&
        `
      display: flex;
  `};

    ${(props) =>
        props.PollItems &&
        `
        width: auto;
        
        span {
            margin-top: auto;
            font-size: 14px;
            color: #1A1A1A;
        }
  `};
`;

export const ContentWrapper = styled.div`
    display: flex;
    padding: ${(props) => (props.Padding ? "0 0 48px 0" : `48px 0 48px 0`)};
    width: 100%;
    max-width: 772px;

    :first-child {
        padding-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    ${(props) =>
        props.Border &&
        `
      border-bottom: 2px solid #DBDBDB;
  `};

    ${(props) =>
        props.PollItems &&
        `
        font-size: 24px;
        
  `};
`;

export const ContentTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    margin-right: 24px;
    max-width: 140px;
    width: 100%;

    ${(props) =>
        props.PollItems &&
        `
        font-size: 24px;
        line-height: 24px;
  `};
`;

export const PollTypeWrapper = styled.div`
    & + & {
        margin-left: 24px;
    }

    input {
        margin-right: 18px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: right;
    margin: ${(props) => (props.Margin ? "48px 0 0 0" : "0")};

    button {
        background-color: #000065;
        width: 180px;
        padding: 11px 8px;
        border-radius: 8px;

        span {
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
        }
    }
    ${(props) =>
        props.AddItem &&
        `
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 153px;
            background-color: #000065;
            padding: 6px 8px;
            border-radius: 8px;

        span {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
        }
    }
        
    `}
`;

// 이미지 파일 업로드
export const AddFileWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
    }

    p {
        max-width: 500px;
        width: 100%;
        font-size: 10px;
        font-weight: 400;
        color: #757575;
        padding: 10px 8px;
        background-color: #f5f5f5;
        border: 1px solid #757575;
        border-radius: 8px;
        height: 36px;
    }
    label {
        padding: 8px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        background-color: #dbdbdb;
        border-radius: 8px;
        width: 100px;
        margin: ${(props) => props.margin || "0 0 0 8px"};
        color: #1a1a1a;
        line-height: 20px;
        cursor: pointer;
    }
`;

export const RewardAmountWrapper = styled.div`
    width: 180px;
    position: relative;

    input {
        :disabled {
            background-color: #f5f5f5;
            color: #757575;
        }
        ::placeholder {
            color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
        }
    }

    span {
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        position: absolute;
        color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
    }
`;

export const KeywordTextWrapper = styled.div`
    padding: 8px;
    border: 1px solid #757575;
    border-radius: 8px;
    margin-bottom: 8px;

    input {
        width: 100%;
    }
`;
