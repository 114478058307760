import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import useRequest from "hooks/useRequest";

import {
    Caption,
    CheckBox,
    CheckInput,
    CheckLabel,
    MenuInfoCont,
    Table,
    TableCont,
    TBody,
    TbodyTd,
    TbodyTh,
    Thead,
    TheadTh,
    TheadTr,
    Tr
} from "pages/User/UserStyle"
import {
    Button,
    ButtonRight,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl
} from "common/styledComponents/commonStyle";

import {isValidEmail} from "common/js/Validation";
import {API_STATE} from "common/constants/state";
import AccntCreateInfoModal from "./modal/AccntCreateInfoModal";

const ACCESS_ITEM = [{
    title: '사용자 관리',
    id: 'user'
}, {
    title: '채널 관리',
    id: 'channel'
}, {
    title: '컨텐츠 관리',
    id: 'contents'
}, {
    title: '설문 관리',
    id: 'poll'
}, {
    title: '포인트 관리',
    id: 'point'
}, {
    title: '게시판 관리',
    id: 'board'
}];

const ACCESS_TYPES = ['N', 'R', 'W', 'D'];

const ORDER = {
    N: 0,
    R: 1,
    W: 2,
    D: 3
}

const AccessItem = ({title, id, initAccess, onChange}) => {
    const [access, setAccess] = useState(initAccess);

    const getSortedAccessArr = (str) => {
        const tmpArr = [];
        if (!str) {
            return [];
        }
        for (const s of str.toUpperCase().split('')) {
            const idx = ORDER[s];
            tmpArr[idx] = s;
        }
        return tmpArr;
    }

    const handleChange = (value, e) => {
        const {checked} = e.currentTarget;
        let accessWithType = getSortedAccessArr(access);
        if (value === 'N') {
            if (checked) {
                accessWithType = accessWithType.map(() => '');
                accessWithType[0] = 'N';
            }
        } else {
            if (checked) {
                accessWithType[0] = '';
                accessWithType[ORDER[value]] = value;
            } else {
                accessWithType[ORDER[value]] = '';
            }
        }
        accessWithType = accessWithType.join('');
        onChange(id, accessWithType);
        setAccess(accessWithType);
    }

    return (
        <Tr>
            <TbodyTh>{title}</TbodyTh>
            {
                ACCESS_TYPES.map((accessType, index) => {
                    return (
                        <TbodyTd key={index}>
                            <CheckBox>
                                <CheckInput id={`${id}${index}`} type="checkbox"
                                            checked={access.indexOf(accessType) !== -1}
                                            onChange={(e) => handleChange(accessType, e)}/>
                                <CheckLabel htmlFor={`${id}${index}`}/>
                            </CheckBox>
                        </TbodyTd>
                    )
                })
            }
        </Tr>
    )
}

const UserCreateAdmin = ({userType}) => {
    const [bizCreateModalOpen, setBizCreateModalOpen] = useState(true);
    const [dataForModal, setDataForModal] = useState({})

    const saveAdminUserRequest = useRequest("post", "/user/admin/new");
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: "",
            department: "",
            email: ""
        }
    });
    const [access, setAccess] = useState({
        user: '',
        channel: '',
        contents: '',
        poll: '',
        point: '',
        board: '',
    });

    const handleChangeAccessItem = (type, accessWithType) => {
        const newObj = {
            ...access,
        }
        newObj[type] = accessWithType;
        setAccess(newObj);
    }

    const validation = () => {
        if (!isValidEmail(watch().email)) {
            alert("이메일 형식을 확인 해 주세요.");
            return false;
        }
        return true;
    }

    const handleCreateUser = (data) => {
        if (!validation()) {
            return;
        } else {
            const {
                user,
                channel,
                contents,
                poll,
                point,
                board
            } = access;

            const formData = {
                name: watch().name,
                email: watch().email,
                department: watch().department,
                userAccess: user,
                channelAccess: channel,
                contentsAccess: contents,
                pollAccess: poll,
                pointAccess: point,
                boardAccess: board,
            }

            saveAdminUserRequest.call(formData);
        }
    };


    useEffect(() => {
        const response = saveAdminUserRequest.response;

        switch (saveAdminUserRequest.state) {
            case API_STATE.done:
                if (response.success) {
                    setDataForModal({
                        userType: "관리자 회원",
                        bizType: 'admin',
                        id: response?.response?.userId,
                        password: response?.response?.password
                    })
                    setBizCreateModalOpen(false)

                } else if (response.error.code === 400) {
                    if (response.error.message.includes('admins_email_key')) {
                        alert(`이메일 중복입니다.`)
                    } else {
                        alert(`error: ${response.error.message}`);
                    }
                }
                break;

            default:
                break;
        }
    }, [saveAdminUserRequest.state, saveAdminUserRequest.error, saveAdminUserRequest.response]);

    return (
        <>
            <MenuInfoCont>
                <List wide>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>담당자</ListLtxt>
                            <ListRtxt><ListInput type="text" {...register('name', {required: true})}/></ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>이메일 주소</ListLtxt>
                            <ListRtxt><ListInput type="text" {...register('email', {required: true})}/></ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>소속</ListLtxt>
                            <ListRtxt><ListInput type="text" {...register('department', {required: true})}/></ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt top>권한 설정</ListLtxt>
                            <ListRtxt>
                                <TableCont>
                                    <Table>
                                        <Caption>권한 설정 테이블</Caption>
                                        <Thead>
                                            <TheadTr>
                                                <TheadTh>항목</TheadTh>
                                                <TheadTh>권한 없음</TheadTh>
                                                <TheadTh>읽기</TheadTh>
                                                <TheadTh>쓰기</TheadTh>
                                                <TheadTh>삭제</TheadTh>
                                            </TheadTr>
                                        </Thead>
                                        <TBody>
                                            {
                                                ACCESS_ITEM.map((accessItem, index) => {
                                                    return <AccessItem title={accessItem.title} id={accessItem.id}
                                                                       initAccess={access[accessItem.id]}
                                                                       onChange={handleChangeAccessItem}
                                                                       key={index}
                                                    />
                                                })
                                            }
                                        </TBody>
                                    </Table>
                                </TableCont>
                            </ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </MenuInfoCont>
            <ButtonRight>
                <Button mini
                    // onClick={() => setBizCreateModalOpen(false)}
                        onClick={handleSubmit(handleCreateUser)}

                        {...(
                            !watch('name') ||
                            !watch('department') ||
                            !watch('email') ||
                            (() => {
                                const values = Object.values(access);
                                return values.some((v) => v === "");
                            })()
                        )
                        && {disabled: true}}
                >
                    생성 하기
                </Button>
            </ButtonRight>
            <AccntCreateInfoModal {...{bizCreateModalOpen, setBizCreateModalOpen, handleCreateUser, dataForModal}} />
        </>
    )
}

export default UserCreateAdmin;
