import React, { useMemo } from 'react';

import {
    PollCheckBox, PollCheckUl, PollCheckLi, PollCheckLabel, PollCheckInput, PollCheckTxt, PollCheckImg
} from "common/styledComponents/commonStyle";


const PopupServeyText = ({data, handleCheck, checkNum}) => {

    return (
        <PollCheckBox>
            <PollCheckUl>
                {data.items.map((d, idx) => {
                    return (
                        <PollCheckLi key={idx}>
                            <PollCheckLabel>
                                <PollCheckInput checked={checkNum.some(d => d === idx)} type="checkbox" onChange={() => handleCheck(idx)} />
                                <PollCheckTxt>{d.text || "답안 내용을 입력해 주세요."}</PollCheckTxt>
                            </PollCheckLabel>
                        </PollCheckLi>
                    );
                })}
                {/* <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>  
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi> 
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>  
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi> 
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>  
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>
                <PollCheckLi>
                    <PollCheckLabel>
                        <PollCheckInput type="checkbox"/>
                        <PollCheckTxt>항목 텍스트가 들어갑니다. width: 278px 고정 항목 텍스트가 들어갑니다. width: 278px 고정</PollCheckTxt>
                    </PollCheckLabel>
                </PollCheckLi>                                                                     */}
            </PollCheckUl>
        </PollCheckBox>         
    );
};

export default PopupServeyText;