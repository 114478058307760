import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {
    Button,
    CloseButton,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    ModalSpan,
    ReportReasonSpan,
    ReturnTdContent,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Title,
    Wrapper
} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import SearchFilter from "common/components/SearchFilter"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {
    DateSearch,
    SearchConditionBox,
    SearchConditionDiv,
    SearchConditionText,
    SearchDateBox,
    SearchDateInput,
    SearchDateInputSpan,
    SearchDateMid,
    SearchDateTxt
} from "./channelSurveySytle"
import {
    CHANNEL_SEARCH_KEY_OPTIONS,
    CHANNEL_SEARCH_KEY_STATUS,
    DATE_FILTER_OPTIONS,
    REPORT_LIST_COLUMNS,
    REPORT_SEARCH_OPTIONS,
    REPORT_SEARCH_TYPE_OPTIONS,
    REPORT_SURVEY_STATUS
} from "common/constants/poll";
import storageService from '../../../util/storage';
import CloseIcon from '../../../asset/img/icon__close_black.svg';
import axios from 'axios';
import Select from 'react-select';
import {API_STATE} from '../../../common/constants/state';

const ChannelSurvey = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const queryPage = searchParams.get('page')
    const queryTitle = searchParams.get('title') || ''
    const queryStatus = searchParams.get('status') || ''
    const queryStartAt = searchParams.get('startAt') || ''
    const queryEndAt = searchParams.get('endAt') || ''
    const queryType = searchParams.get('type') || ''
    const queryCategoryId = searchParams.get('categoryId') || ''
    const queryDateOption = searchParams.get('dateOption') || 'ALL'
    const queryKeyword = searchParams.get('keyword') || ''
    const reportListRequest = useRequest("get", "report/poll/private/list");
    const categoryChannelListRequest = useRequest("get", "category/poll/simple/list");
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? queryPage - 1 : 0,
        limit: 10,
        page: queryPage || 1,
        title: queryTitle || '',
        status: queryStatus ? queryStatus : '',
        startAt: queryStartAt ? queryStartAt : '',
        endAt: queryEndAt ? queryEndAt : '',
        type: queryType ? queryType : '',
    });
    const [totalNum, setTotalNum] = useState(0);
    const [dateFilterOption, setDateFilterOption] = useState(formData.dateOption ? formData.dateOption : queryDateOption);
    const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
    const refStartAt = useRef(null);
    const refEndAt = useRef(null);
    const [onReturnModal, setOnReturnModal] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [reportData, setReportData] = useState({
        createdAt: '', createdBy: '', reason: '', contents: '', id: '',
    });
    const [categoryPlaceholder, setCategoryPlaceholder] = useState(null);

    const getSearchDate = () => {
        const now = dayjs(new Date());
        let date = {
            startAt: now,
            endAt: now,
        };
        switch (dateFilterOption) {
            case '1M':
                date.startAt = now.subtract(1, 'month');
                break;
            case '3M':
                date.startAt = now.subtract(3, 'month');
                break;
            case '6M':
                date.startAt = now.subtract(6, 'month');
                break;
            case '1Y':
                date.startAt = now.subtract(1, 'year');
                break;
            case 'SELECT':
                date.startAt = dayjs(refStartAt.current.value);
                date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
                break;
            case 'ALL':
                date.startAt = null;
                date.endAt = null;
                break;
            default:
                date.startAt = now;
                date.endAt = now;
                break;
        }

        return {
            startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
            endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
        }

    }

    const {startAt, endAt} = getSearchDate();

    const {rows, fetch} = useBoard(reportListRequest, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            status: REPORT_SURVEY_STATUS[data.status],
            reasonForReport: data.reasonForReport.slice(0, 10) + '...',
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            isReason: true,
        }));
    }, {
        limit: 10,
        offset: 0,
        startAt,
        endAt,
        // ...getSearchDate()
    });

    const handleSelectRow = (row) => {
        if (!reportData.id) {
            navigate(`/surveyMange/userSurvey/detail/${row.pollId}/private?${searchParams.toString()}`);
        } else {
            navigate(`/surveyMange/userSurvey/detail/${reportData.id}/private?${searchParams.toString()}`);
        }
    }

    const handleChangeSearchKey = (option) => {
        searchParams.set('type', option.value)
        setFormData({...formData, type: option.value})
    }

    const handleChangeCategory = (option) => {
        searchParams.set('categoryId', option.id)
        setFormData({...formData, categoryId: option.id})
    }

    const handleSearch = (data) => {

        const {selectedOption} = data;
        const {startAt, endAt} = getSearchDate();
        const {type, title, limit, categoryId,} = formData;

        if (type !== '' && !title) {
            alert('검색어 입력 또는 검색 조건을 변경해주세요.')
            return
        }

        searchParams.set('type', type)

        if (selectedOption) {
            searchParams.set('page', '1')
            searchParams.set('status', selectedOption)
            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, status: selectedOption})
        }

        if ((startAt && endAt) || (startAt === '' && endAt === '')) {
            setCurPage(1)
            searchParams.set('startAt', startAt)
            searchParams.set('endAt', endAt)

            if (dateFilterOption) {
                searchParams.set('dateOption', dateFilterOption)
            }

            setFormData({
                ...formData,
                page: 1,
                offset: 0,
                startAt: startAt,
                endAt: endAt,
                dateOption: dateFilterOption,
            })
        }

        if (title || title === '') {
            if (type === 'keyword') {
                searchParams.set('keyword', title)
            } else {
                searchParams.set('title', title)
            }
            searchParams.set('page', '1')

            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, title: title})
        }

        setSearchParams(searchParams)

        if (type === 'keyword') {
            fetch({
                status: selectedOption || '',
                startAt: startAt || '',
                endAt: endAt || '',
                type: type || '',
                page: 1,
                offset: 0,
                keyword: title || '',
                categoryId: categoryId,
                limit
            });
        } else {
            fetch({
                title: title || '',
                status: selectedOption || '',
                startAt: startAt || '',
                endAt: endAt || '',
                type: type || '',
                page: 1,
                offset: 0,
                limit
            });
        }
    }

    const handleDateFilterSelect = (option) => {
        setDateFilterOption(option);
        const {startAt, endAt} = getSearchDate();
        refEndAt.current.value = endAt;

        if (option === 'SELECT') {
            refStartAt.current.value = dayjs().format(DATE.FORMAT.DASH);
        } else {
            refStartAt.current.value = startAt;
        }

        setFormData(prevState => ({...prevState, startAt: startAt, endAt: endAt, dateOption: option}))

    }

    const handleChangeStartAt = () => {
        const {startAt} = getSearchDate();
        refStartAt.current.value = startAt;
        searchParams.set('startAt', startAt)
        setFormData({...formData, startAt: startAt})
    }

    const handleChangeEndAt = () => {
        const {endAt} = getSearchDate();
        refEndAt.current.value = endAt;
        searchParams.set('endAt', endAt)
        setFormData({...formData, endAt: endAt})
    }

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setSearchParams(searchParams)
        setCurPage(pageNum)
    };

    const showReportModal = async (e) => {
        e.stopPropagation()

        const token = storageService.get("auth", "accessToken", "local");
        const id = Number(e.currentTarget.id);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/report/${id}/poll/private`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })

            const {createdAt, createdBy, reason, contents} = response.data.response
            setReportData(prevState => ({
                ...prevState, createdAt: createdAt, createdBy, reason, contents, id,
            }))
            openStopReasonModal()

        } catch (e) {
            console.log(e)
        }
        // setOnReturnModal(true)
    }

    const columnRenderer = (key, value, _, row) => {
        if (key === 'reasonForReport') {
            return <ReturnTdContent onClick={showReportModal} id={row.id}>
                {row.reasonForReport}
            </ReturnTdContent>
        }
        return value
    }

    const openStopReasonModal = () => {
        setOnReturnModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeStopReasonModal = () => {
        document.body.style.overflow = 'auto';
        setOnReturnModal(false)
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 168,
                border: "1px solid #757575",
                borderRadius: "8px",
                background: '#F5F5F5',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            dateOption: queryDateOption || 'ALL',
            queryCategoryId: queryCategoryId || ''
        }))

        setDateFilterOption(queryDateOption)

        fetch({
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            limit: 10,
            keyword: queryKeyword || '',
            categoryId: queryCategoryId || '',
        })

    }, [queryPage, queryTitle, queryStartAt, queryEndAt, queryStatus]);

    useEffect(() => {
        categoryChannelListRequest.call()
    }, []);

    useEffect(() => {
        switch (categoryChannelListRequest.state) {
            case API_STATE.done:
                if (categoryChannelListRequest.response.success) {
                    const {response} = categoryChannelListRequest.response;
                    setCategoryList(response.data.map((value) => (
                        {...value, label: value.name, value: value.name}
                    )));

                    if (queryCategoryId) {
                        setCategoryPlaceholder(response.data.filter((value) => value.id === Number(queryCategoryId))[0])
                    }
                }
                break;
            default:
                break;
        }
    }, [categoryChannelListRequest.state, categoryChannelListRequest.error, categoryChannelListRequest.response, queryCategoryId]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>
                        신고설문 관리
                    </Title>
                    <DateSearch>
                        <SearchConditionBox>
                            <Wrapper maxWidth='626px' Row>
                                <Wrapper maxWidth='289px'>
                                    <SearchConditionText top>검색 조건</SearchConditionText>
                                    <Select
                                        options={REPORT_SEARCH_OPTIONS}
                                        placeholder={queryType ? REPORT_SEARCH_TYPE_OPTIONS[queryType] : REPORT_SEARCH_OPTIONS[0].label}
                                        value={queryType ? CHANNEL_SEARCH_KEY_STATUS[queryType] : CHANNEL_SEARCH_KEY_OPTIONS[0].label}
                                        onChange={handleChangeSearchKey}
                                        styles={customStyles}
                                        isSearchable={false}
                                    />
                                </Wrapper>
                                {formData.type === 'keyword' &&
                                    <Wrapper maxWidth='289px' Row>
                                        <SearchConditionText top>카테고리 선택</SearchConditionText>
                                        <Select
                                            options={categoryList && categoryList}
                                            placeholder={categoryPlaceholder ? categoryPlaceholder.name : '전체'}
                                            onChange={handleChangeCategory}
                                            styles={customStyles}
                                            isSearchable={false}
                                        />
                                    </Wrapper>
                                }
                            </Wrapper>
                        </SearchConditionBox>
                        <SearchConditionBox>
                            <SearchConditionText>등록일 조회 기간</SearchConditionText>
                            <SearchConditionDiv>
                                <SearchFilter options={DATE_FILTER_OPTIONS} option={formData.dateOption}
                                              onSelect={handleDateFilterSelect} type="filter"/>
                                <SearchDateBox>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refStartAt} onChange={() => handleChangeStartAt()}/>
                                        <SearchDateTxt>{startAt || startAt === '' ? startAt : formData.startAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                    <SearchDateMid>~</SearchDateMid>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refEndAt} onChange={() => handleChangeEndAt()}/>
                                        <SearchDateTxt>{endAt || endAt === '' ? endAt : formData.endAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                </SearchDateBox>
                            </SearchConditionDiv>
                        </SearchConditionBox>
                    </DateSearch>
                    <ListSearch
                        initOption={queryStatus ? queryStatus : formData.status}
                        inputValue={formData.title}
                        onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                        onSearch={handleSearch}/>
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={REPORT_LIST_COLUMNS}
                           rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum}
                           columnRenderer={columnRenderer}
                           searchParams={searchParams}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
            {onReturnModal &&
                <StyledModal>
                    <ModalContainer Report>
                        <RewardDetailTitle>설문 신고 사유 확인</RewardDetailTitle>
                        <RewardDetailContent Report>
                            <ModalSpan>신고 일자</ModalSpan>
                            <ModalSpan>
                                {dayjs(new Date(reportData.createdAt)).format(DATE.FORMAT.DOT)}
                            </ModalSpan>
                            <ModalSpan>신고 회원</ModalSpan>
                            <ModalSpan>{reportData.createdBy}</ModalSpan>
                            <ModalSpan>신고 사유</ModalSpan>
                            <ModalSpan>{reportData.reason}</ModalSpan>
                        </RewardDetailContent>
                        <Wrapper Column margin='48px 0 0 0'>
                            <ModalSpan margin='0 0 6px 0'>아래에 사유를 확인해주세요.</ModalSpan>
                            <ReportReasonSpan>{reportData.contents}</ReportReasonSpan>
                        </Wrapper>
                        {/*<Button mini onClick={closeStopReasonModal} margin='48px 0 0 0'>확인</Button>*/}
                        <Wrapper margin='48px 0 0 0' Center>
                            <Button mini line
                                    margin='0 20px 0 auto'
                                    type='button'
                                    onClick={handleSelectRow}>설문 바로가기</Button>
                            <Button mini type='button' margin='0 auto 0 0'
                                    onClick={closeStopReasonModal}>확인</Button>
                        </Wrapper>
                        <CloseButton onClick={closeStopReasonModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }
        </>
    )
}

export default ChannelSurvey;
