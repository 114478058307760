import {
	INITIAL_STATE as authState,
	PERSISTED_STATE as persistedAuthState,
	REDUCER as authReducer,
} from "./auth/reducers";
import {
	INITIAL_STATE as userState,
	PERSISTED_STATE as persistedUserState,
	REDUCER as userReducer,
} from "./user/reducers";
const initialStates = {
	auth: authState,
	user: userState,
};


export const persistedStates = {
	...initialStates,
	auth: persistedAuthState,
	user: persistedUserState,
};

export const CLEAR_STATES = "root/CLEAR_STATES";

export const reducers = (
	{auth, user },
	action
) => {
	if (action.type === CLEAR_STATES) {
		return initialStates;
	} else {
		return {
		auth: authReducer(auth, action),
		user: userReducer(user, action),
	}
}
	
};
