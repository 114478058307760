const DATE = {
  FORMAT: {
    DOT: 'YYYY.MM.DD',
    DOT_TIME: 'YYYY.MM.DD HH:mm',
    DASH: 'YYYY-MM-DD',
    DASH_TIME: 'YYYY-MM-DDTHH:mm',
    LOCAL_KR: 'YYYY년 MM월 DD일'
  }
}

export default DATE
