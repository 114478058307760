import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    background-color: ${(props) => props.backgroundColor};

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
        `}
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.MaxWidth && "608px"};
    margin: ${(props) => props.margin};

    ${(props) =>
        props.Subheading &&
        css`
            width: auto;

            span {
                margin-top: auto;
                font-size: 14px;
                color: #1a1a1a;
            }
        `}

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
        `}

  ${(props) =>
        props.Row &&
        css`
            justify-content: space-between;
        `}

    ${(props) =>
        props.right &&
        css`
            justify-content: right;
        `}
`;
