import React from "react";
import {PollSettingContentTitle, PollSettingContentWrapper,} from "../../styledComponents/commonStyle";
import {Content} from "../../../pages/Temp/CreateRanking/CreateRankingStyle";

function ContentWrapper({children, title, Padding, Border, Subheading, Column, ...rest}) {
    return (
        <PollSettingContentWrapper Border={Border} Padding={Padding}>
            <Content Column={Column}>
                <PollSettingContentTitle Subheading={Subheading} {...rest}>{title}</PollSettingContentTitle>
                {children}
            </Content>
        </PollSettingContentWrapper>
    );
}

export default ContentWrapper;
