import {
    FullPointBox,
    FullPointInput,
    FullPointInputBox,
    FullPointInputTxt,
    PopBox,
    PopButton,
    PopClose,
    PopHeadTxt,
    PopTxt,
    PopUp
} from 'common/styledComponents/commonStyle';
import React, {useEffect, useState} from 'react';
import CloseIcon from "asset/img/icon__close.png";
import {API_STATE} from 'common/constants/state';
import useRequest from '../../../../hooks/useRequest';

const TopupPopup = ({
                        openModal,
                        setOpenModal,
                        id,
                        userInfoRequest,
                        formData,
                        fetch,
                        bizUserInfoRequest,
                        biz,
                    }) => {

    const chargeRequest = useRequest("post", `point/user/${id}/biz/charge`)
    const [chargeAmount, setChargeAmount] = useState('');

    const handleCharge = () => {
        const doCharge = Number(chargeAmount);
        if (doCharge <= 0) {
            alert("충전하실 Berry Amount 를 입력해 주세요.")
            return;
        }
        chargeRequest.call({amount: doCharge})
    }

    const handleBizNumberKeyPress = (e) => {
        const forbiddenCharacters = ['e', '+', '-', '.', 'ArrowDown', 'Down', 'down', 'ArrowUp'];
        if (forbiddenCharacters.includes(e.key)) {
            e.preventDefault();
        }
    }

    useEffect(() => {
        switch (chargeRequest.state) {
            case API_STATE.done:
                console.log("chargeRequest result >>> ", chargeRequest.response)
                const {offset} = formData;
                fetch({
                    ...formData,
                    offset
                })
                if (biz) {
                    alert('충전이 완료됐습니다.')
                    bizUserInfoRequest.call()
                } else {
                    userInfoRequest.call();
                }
                setChargeAmount(0);
                setOpenModal(false)
                return;
            default:
                return;
        }
    }, [chargeRequest.state, chargeRequest.error, chargeRequest.response])

    useEffect(() => {
        if (!openModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [openModal]);

    return (
        <PopUp>
            <PopBox check>
                <PopHeadTxt>운용 포인트 충전</PopHeadTxt>
                <PopClose onClick={() => setOpenModal(false)}>
                    <img src={CloseIcon} alt=''/>
                </PopClose>
                <PopTxt>충전된 운용 포인트는 설문의 보상으로 사용자에게<br/>제공됩니다.</PopTxt>
                <FullPointBox>
                    <PopTxt left>충전 금액을 입력해주세요.</PopTxt>
                    <FullPointInputBox>
                        <FullPointInput value={chargeAmount}
                                        onChange={(e) => setChargeAmount(e.target.value)}
                                        onKeyDown={handleBizNumberKeyPress}
                                        type="number"
                                        placeholder='0'
                        />
                        <FullPointInputTxt>Berry</FullPointInputTxt>
                    </FullPointInputBox>
                    <PopTxt left>! 충전 시 승인 관리자의 확인이 필요합니다.</PopTxt>
                </FullPointBox>
                <PopButton center onClick={handleCharge}>확인</PopButton>
            </PopBox>
        </PopUp>
    );
};

export default TopupPopup;
