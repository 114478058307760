import axios from 'axios';
import storageService from '../../util/storage';
import pollberryApiService from '../../util/pollberryApiService';

async function VideoUpload(video) {
    const token = storageService.get("auth", "accessToken", "local");
    const apiService = pollberryApiService
    apiService.setBaererToken(token);

    try {
        const formData = new FormData();
        formData.append("file", video);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/image/ranking/background/new`,
                formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            formData.delete('file');
            return response.data.response.url;

        } catch (error) {
            console.error(error, 'error');
            formData.delete('file');
            return video;
        }
    } catch (e) {
        console.error(e)
        return []
    }
}

export default VideoUpload;
