import cryptoService from "./cryptoService";

export default class storageService {
	/**
	 * @param {string} group - grpup name
	 * @param {Object} newObject - new object
	 * @param {"session"|"local"} type - storage type
	 * @example storageManager.set("company", { companyNumber: "123-12-1234" }, "local")
	 */
	static set = (group, newObject, type) => {
		// const exist = JSON.parse(window.sessionStorage.getItem(`okceo.${group}`));
		window[`${type}Storage`].setItem(`pollberry.${group}`, cryptoService.enctypt(newObject));
		return;
	};

	/**
	 * @param {string} group - group name
	 * @param {string} key - key
	 * @param {"session"|"local"} type - storage type
	 * @example storageManager.get("company", "companyNumber", "local")
	 */
	static get = (group, key, type) => {
		try {
			const ciphertext = window[`${type}Storage`].getItem(`pollberry.${group}`);
			return cryptoService.dectypt(ciphertext)[key];
		} catch {
			return;
		}
	};

	/**
	 * @param {string} group name
	 * @param {"session"|"local"} type - storage type
	 * @example storageManager.clear("company", "local")
	 */
	static clear = (group, type) => {
		window[`${type}Storage`].removeItem(`pollberry.${group}`);
	};

	/**
	 * @param {"session"|"local"=} type - storage type
	 * @example storageManager.clearAll("local")
	 */
	static clearAll = (type) => {
		if (type) {
			window[`${type}Storage`].clear();
		} else {
			window.localStorage.clear();
			window.sessionStorage.clear();
		}
	};
}
