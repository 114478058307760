import styled, {css} from 'styled-components';
import RightArrowIcon from "asset/img/icon__keyboard_arrow_right.png";

export const ReTokenCont = styled.div`

`;

export const MainTokenBox = styled.div`
    width: 100%;
    height: 262px;
    margin: 48px auto 64px;
`;
export const MainTokenBoxInner = styled.div`
    position: relative;
    border: 1px solid #dbdbdb;
    width: 580px;
    height: 100%;
    border-radius: 8px;
    float: left;
    padding: 37px 40px 0 28px;

    & + & {
        margin-left: 20px;
    }
`;

export const GoPointBtn = styled.button`
    font-size: 14px;
    text-align: right;
    color: #757575;
    position: absolute;
    right: 40px;

    &:after {
        content: "";
        background-image: url(${RightArrowIcon});
        background-size: cover;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: absolute;
        right: -16px;
        top: 3px;
    }
`;
export const MainTokenTxt = styled.p`
    font-size: 28px;
    font-weight: 500;
`;
export const MainTokenSubTxt = styled.p`
    font-size: 14px;
    color: #757575;
    margin-top: 10px;
`;
export const MainNumBerryBox = styled.p`
    text-align: right;
    position: absolute;
    right: 40px;
    bottom: 55px;

`;
export const TokenNumTxt = styled.span`
    font-size: 48px;
    font-weight: 700;
    ${(props) =>
            props.sub &&
            css`
                font-size: 32px;
            `}

`;

export const TokenBerryTxt = styled.span`
    font-size: 18px;
    margin-left: 28px;
    color: #424242;
    ${(props) =>
            props.sub &&
            css`
                font-size: 12px;
            `}
`;

export const SubTokenBox = styled.div`
    width: 100%;
    margin: 48px auto;

`;
export const SubTokenBoxHead = styled.p`
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 24px;

`;
export const SubTokenUl = styled.ul`
    width: 100%;
    display: inline-block;

`;
export const SubTokenLi = styled.li`
    width: 380px;
    height: 126px;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    padding: 20px 40px 24px 24px;
    float: left;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 8px;

    & + & {
        margin-left: 20px;
    }

    &:nth-child(4n) {
        margin-left: 0;
    }

    ${(props) =>
            props.cancle &&
            css`
                border-color: #c2c2c2;
                background-color: #dbdbdb;
            `}


`;
export const SubTokenTxt = styled.p`
    font-weight: 500;

    span {
        margin-left: 8px;
        font-size: 12px;
        color: #757575;
    }

`;
export const SubNumBerryBox = styled.p`
    position: absolute;
    bottom: 24px;
    right: 40px;
`;
