import React from "react";

import {MenuInfoCont, MenuInfoSub} from "pages/Mypage/MypageStyle"
import {ListNew, ListNewUl, ListNewLi} from "pages/User/UserStyle"

import {ListLtxt, ListRtxt, Select, Option} from "common/styledComponents/commonStyle";

import { STATUS_OPTIONS, ROLE } from "common/constants/user";


const UserDetailDefault = ({userInfo, register}) =>{
    return(
      <MenuInfoCont>
        <MenuInfoSub>기본 정보</MenuInfoSub>
        <ListNew>
            <ListNewUl>
                <ListNewLi>
                    <ListLtxt>회원 상태</ListLtxt>
                    <ListRtxt>
                        <Select {...register("status", { required: true })}>
                            {STATUS_OPTIONS.map((option, index) => {
                                const {text, value} = option;
                                return <Option value={value} key={index} {...(userInfo.status === value ? {selected:true} : null)}>{text}</Option>
                            })}
                        </Select>
                    </ListRtxt>
                </ListNewLi>
                <ListNewLi>
                    <ListLtxt>회원 구분</ListLtxt>
                    <ListRtxt>{ROLE[userInfo.role]}</ListRtxt>
                </ListNewLi>
                <ListNewLi>
                    <ListLtxt>생성일</ListLtxt>
                    <ListRtxt>{userInfo.createdAt}</ListRtxt>
                </ListNewLi>
            </ListNewUl>
        </ListNew>
    </MenuInfoCont>
    )
}

export default UserDetailDefault;
