import storageService from "util/storage";
import { CLEAR_STATES } from "../reducers";

export const LOGIN = "auth/LOGIN";


export const login = ({ accessToken }) => ({
	type: LOGIN,
	payload: {
		accessToken: accessToken,
	},
});

export const logout = () => {
	storageService.clearAll()
	return {
		type: CLEAR_STATES,
	};
};
