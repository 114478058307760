export const SET_USER = "user/SET_USER";
export const CLEAR_USER = "user/CLEAR_USER";

export const setUser = (user) => {
	const info = {
		userId: user.userId,
		role: user.role,
		lastLoginAt: user.lastLoginAt,
		access: user.access
	};
	return {
		type: SET_USER,
		payload: {
			info: info,
		},
	};
};

export const clearUser = () => ({
	type: CLEAR_USER,
});
