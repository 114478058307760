import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {
    Button,
    CloseButton,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    ModalSpan,
    ReturnTdContent,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Title
} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {DateSearch, SearchConditionBox, SearchConditionText} from "./inspectionSurveyStyle"
import {
    INSPECTION_LIST_COLUMNS,
    INSPECTION_SEARCH_KEY_OPTIONS,
    INSPECTION_SEARCH_KEY_STATUS,
    USER_SEARCH_KEY_OPTIONS
} from "common/constants/poll";
import {STATUS} from '../../../common/constants/channel';
import CloseIcon from '../../../asset/img/icon__close_black.svg';
import axios from 'axios';
import storageService from '../../../util/storage';
import Select from 'react-select';


const InspectionSurvey = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const queryPage = searchParams.get('page') || 1
    const queryText = searchParams.get('text') || ''
    const queryTitle = searchParams.get('title') || ''
    const queryName = searchParams.get('name') || ''
    const queryType = searchParams.get('type') || ''
    // const [searchCondition, setSearchCondition] = useState('all');
    const [returnReasonId, setReturnReasonId] = useState(null);
    const [returnData, setReturnData] = useState({
        reason: '', title: '',
    });
    const request = useRequest("get", "poll/ready/list");
    const returnReasonRequest = useRequest("get", `poll/${returnReasonId}/return`);
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [onReturnModal, setOnReturnModal] = useState(false);
    const [formData, setFormData] = useState({
        offset: queryPage ? queryPage - 1 : 0,
        limit: 10,
        page: queryPage || 1,
        text: queryName || queryTitle,
        title: queryTitle || '',
        name: queryName || '',
        type: queryType || '',
    });
    const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);

    const {rows, fetch, params} = useBoard(request, (res) => {
            setTotalNum(res.totalCount);
            const {data} = res;
            return data && data.map((data) => ({
                ...data,
                status: STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                period: `${dayjs(new Date(data.startAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.endAt)).format(DATE.FORMAT.DOT)}`,
            }));
        }, {
            offset: (Number(searchParams.get('page')) - 1 || 0),
            limit: 10
        }
    );

    const handleSelectRow = (row) => {
        navigate(`detail/${row.id}?page=${curPage}`);
    }

    // const handleChangeSearchCondition = (value) => {
    //     // setSearchCondition(value);
    //     setFormData(prevState => ({...prevState, type: value}))
    // }

    const handleSearch = (data) => {
        const {type, text, limit, offset,} = formData;
        searchParams.set('page', '1')

        if (type === '') {
            searchParams.set('title', text)
            searchParams.set('name', text)

            setSearchParams(searchParams)


            fetch({
                title: text || '',
                name: text || '',
                offset: 0,
                limit
            });
            return
        }


        if (type === 'name') {
            searchParams.set('name', text)
            setSearchParams(searchParams)

            fetch({
                name: text || '',
                offset: 0,
                limit
            });

            return
        }

        if (type === 'title') {
            searchParams.set('title', text)
            setSearchParams(searchParams)

            fetch({
                title: text || '',
                offset: 0,
                limit
            });
            return
        }
    }

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    }

    const showReturnModal = async (e) => {
        const token = storageService.get("auth", "accessToken", "local");
        document.body.style.overflow = 'hidden';
        const id = Number(e.currentTarget.id);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/poll/${id}/return`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            setReturnData(prevState => ({
                ...prevState,
                reason: response.data.response.reason,
                title: rows.filter((row) => row.id === id)[0].channelName
            }))
        } catch (e) {
            console.log(e)
        }
        setOnReturnModal(true)
    }

    const handleChangeSearchKey = (option) => {
        searchParams.set('type', option.value)
        setFormData({...formData, type: option.value})
    }

    const closeReturnReasonModal = () => {
        document.body.style.overflow = 'auto';
        setOnReturnModal(false)
    }

    const columnRenderer = (key, value, _, row) => {
        if (key === "status" && value === '검수반려') {
            return <ReturnTdContent onClick={showReturnModal} id={row.id}>검수반려</ReturnTdContent>
        }
        return value
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 168,
                border: "1px solid #757575",
                borderRadius: "8px",
                background: '#F5F5F5',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    useEffect(() => {
        // const {offset} = formData

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            text: queryTitle || queryName,
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        if (queryType) {
            searchParams.set('page', '1')
            setCurPage(1)

            if (queryType === '') {

                setSearchParams(searchParams)
                fetch({
                    title: queryTitle,
                    name: queryName,
                    offset: 0,
                    page: 1,
                    limit: 10,
                })
                return
            }

            if (queryType === 'title') {
                setSearchParams(searchParams)

                fetch({
                    title: queryTitle || '',
                    offset: 0,
                    limit: 10,
                    page: 1,

                })
                return
            }

            if (queryType === 'name') {
                setSearchParams(searchParams)

                fetch({
                    name: queryName || '',
                    offset: 0,
                    limit: 10,
                    page: 1,
                })
                return
            }
        }

        fetch({
            title: queryTitle || '',
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            limit: 10,
        })

    }, [queryPage, queryText]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>검수대기 설문 관리</Title>
                    <DateSearch>
                        <SearchConditionBox>
                            <SearchConditionText top>검색 조건</SearchConditionText>
                            {/*<Select mid options={INSPECTION_SEARCH_KEY_OPTIONS} initValue={searchCondition}*/}
                            {/*        onChange={handleChangeSearchCondition}/>*/}
                            <Select
                                options={INSPECTION_SEARCH_KEY_OPTIONS}
                                placeholder={queryType ? INSPECTION_SEARCH_KEY_STATUS[queryType] : USER_SEARCH_KEY_OPTIONS[0].label}
                                value={queryType ? INSPECTION_SEARCH_KEY_STATUS[queryType] : USER_SEARCH_KEY_OPTIONS[0].label}
                                onChange={handleChangeSearchKey}
                                styles={customStyles}
                                isSearchable={false}
                            />
                        </SearchConditionBox>
                    </DateSearch>
                    {/*<ListSearch onSearch={handleSearch}/>*/}
                    <ListSearch
                        onSearch={handleSearch}
                        inputValue={formData.text}
                        onChange={(e) => setFormData(prevState => ({
                            ...prevState,
                            text: e.target.value,
                            name: queryType === 'name' ? e.target.value : '',
                            title: queryType === 'title' ? e.target.value : '',
                        }))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={INSPECTION_LIST_COLUMNS}
                           rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum}
                           showReturnModal={showReturnModal} columnRenderer={columnRenderer}
                           searchParams={searchParams}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
            {onReturnModal &&
                <StyledModal>
                    <ModalContainer return>
                        <RewardDetailTitle>검수 반려 사유 확인</RewardDetailTitle>
                        <RewardDetailContent return>
                            <ModalSpan>{`[${returnData.title}]`}</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>검수가 반려되었습니다.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>아래에 사유를 확인해주세요.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>{returnData.reason}</ModalSpan>
                            <ModalSpan margin='auto 0 0 0'>문의 1:1 문의하기 혹은 info@pollberry.net</ModalSpan>
                        </RewardDetailContent>
                        <Button mini type='button' onClick={closeReturnReasonModal} margin='48px 0 0 0'>확인</Button>
                        <CloseButton type='button' onClick={closeReturnReasonModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }

            {/* <PopUp>
             <PopBox check>
             <PopClose><img src={CloseIcon} alt='' /></PopClose>
             <PopHeadTxt>검수 관리</PopHeadTxt>
             <PopTxt>[설문 타이틀 백자까지 가능하니 최대한 자리를 마련해 두겠읍니다 과연 이걸 다 쓸지는 모르겠지만 말이죠.]</PopTxt>
             <PopTxt>검수가 완료되었습니다.</PopTxt>
             <PopButton center>확인</PopButton>
             </PopBox>
             </PopUp> */}


            {/*
             <PopUp>
             <PopBox check>
             <PopClose><img src={CloseIcon} alt='' /></PopClose>
             <PopHeadTxt>검수 관리</PopHeadTxt>
             <PopTxt>[설문 타이틀 백자까지 가능하니 최대한 자리를 마련해 두겠읍니다 과연 이걸 다 쓸지는 모르겠지만 말이죠.]</PopTxt>
             <PopTxt>검수가 반려되었습니다.</PopTxt>
             <PopTextareaCont>
             <PopTxt>아래에 사유를 입력해주세요.</PopTxt>
             <PopTextareaBox>
             <PopTextarea></PopTextarea>
             </PopTextareaBox>
             <PopTextareaNum>100/500</PopTextareaNum>
             </PopTextareaCont>
             <PopButtonBox>
             <PopButton center>확인</PopButton>
             </PopButtonBox>
             </PopBox>
             </PopUp> */}

            {/*
             <PopUp>
             <PopBox check>
             <PopClose><img src={CloseIcon} alt='' /></PopClose>
             <PopHeadTxt>검수 관리</PopHeadTxt>
             <PopTxt>[설문 타이틀 백자까지 가능하니 최대한 자리를 마련해 두겠읍니다 과연 이걸 다 쓸지는 모르겠지만 말이죠.]</PopTxt>
             <PopTxt>검수가 반려되었습니다.</PopTxt>
             <PopTextareaCont>
             <PopTxt>아래에 사유를 입력해주세요.</PopTxt>
             <PopTextareaBox show>
             [마음에 들지 않습니다. 너 반려. 마음에 들지 않습니다. 스팸. 나체 이미지 또는 성적 행위. 혐오 발언 또는 상징. 사기 또는 거짓. 거짓 정보. 따돌림 또는 괴롭힘. 폭력 또는 위험한 단체. 지식재산권 침해. 자살 또는 자해. 불법 또는 규제 상품 판매. 섭식 장애. 기타 문제. 채널 메시지 신고
             신고하신 채널의 메시지와 알림톡을 차단합니다. 채팅방 대화내용이 삭제되며 채팅 목록에서도 사라집니다. 또한 신고처리를 위해 대화내용의 일부가 카카오팀으로 접수됩니다.]
             </PopTextareaBox>
             <PopTextareaNoti>문의 1:1 문의하기 혹은 info@pollberry.net</PopTextareaNoti>
             </PopTextareaCont>
             <PopButtonBox>
             <PopButton center>확인</PopButton>
             </PopButtonBox>
             </PopBox>
             </PopUp> */}


        </>
        // <>
        // <MenuArea>
        //     <MenuInfoArea>
        //         <Title>검수대기 설문 리스트</Title>
        //         <DateSearch>
        //             <SearchConditionBox>
        //                 <SearchConditionText top>검색조건</SearchConditionText>
        //                 <CommonSelect min>
        //                     <Option>전체</Option>
        //                     <Option>전체</Option>
        //                 </CommonSelect>
        //             </SearchConditionBox>

        //         </DateSearch>
        //         <SearchCont>
        //             <SearchBox>
        //                 <SearchDiv>
        //                     <SearchInput type="text" placeholder="텍스트"></SearchInput>
        //                 </SearchDiv>
        //                 <SearchBtn>검색</SearchBtn>
        //             </SearchBox>
        //         </SearchCont>

        //         <BoardBoxCont>
        //             <BoardBox>
        //                 <BoardTable>
        //                     <Colgroup>
        //                         <col width="6%"/>
        //                         <col width="9%"/>
        //                         <col width="20%"/>
        //                         <col width="13%"/>
        //                         <col width="13%"/>
        //                         <col width="39%"/>
        //                     </Colgroup>
        //                     <BoardTableThead>
        //                         <BoardTableTr>
        //                             <BoardTableTh>NO.</BoardTableTh>
        //                             <BoardTableTh>일자</BoardTableTh>
        //                             <BoardTableTh>회원 명</BoardTableTh>
        //                             <BoardTableTh>Berry 단위</BoardTableTh>
        //                             <BoardTableTh>Coin?</BoardTableTh>
        //                             <BoardTableTh>구분</BoardTableTh>
        //                         </BoardTableTr>
        //                     </BoardTableThead>

        //                     <BoardTableTbody>
        //                         <BoardTableTr>
        //                             <BoardTableTd>1</BoardTableTd>
        //                             <BoardTableTd>00.00.00</BoardTableTd>
        //                             <BoardTableTd>채널 명</BoardTableTd>
        //                             <BoardTableTd right>99 Berry</BoardTableTd>
        //                             <BoardTableTd right>1,000 iNAE</BoardTableTd>
        //                             <BoardTableTd>환전 신청</BoardTableTd>
        //                         </BoardTableTr>
        //                         <BoardTableTr>
        //                             <BoardTableTd>1</BoardTableTd>
        //                             <BoardTableTd>00.00.00</BoardTableTd>
        //                             <BoardTableTd>채널 명</BoardTableTd>
        //                             <BoardTableTd right>99 Berry</BoardTableTd>
        //                             <BoardTableTd right>999,999,999 iNAE</BoardTableTd>
        //                             <BoardTableTd>환전 신청</BoardTableTd>
        //                         </BoardTableTr>
        //                         <BoardTableTr>
        //                             <BoardTableTd>1</BoardTableTd>
        //                             <BoardTableTd>00.00.00</BoardTableTd>
        //                             <BoardTableTd>채널 명</BoardTableTd>
        //                             <BoardTableTd right>99 Berry</BoardTableTd>
        //                             <BoardTableTd right>99 iNAE</BoardTableTd>
        //                             <BoardTableTd>환전 신청</BoardTableTd>
        //                         </BoardTableTr>

        //                     </BoardTableTbody>

        //                 </BoardTable>
        //             </BoardBox>

        //             <PageBox>
        //                 <Paging firstpage icon href="#none">맨앞</Paging>
        //                 <Paging prevpage icon href="#none">앞</Paging>
        //                 <Paging active href="#none">1</Paging>
        //                 <Paging href="#none">2</Paging>
        //                 <Paging href="#none">3</Paging>
        //                 <Paging href="#none">4</Paging>
        //                 <Paging href="#none">5</Paging>
        //                 <Paging href="#none">6</Paging>
        //                 <Paging href="#none">7</Paging>
        //                 <Paging href="#none">8</Paging>
        //                 <Paging href="#none">9</Paging>
        //                 <Paging nextpage icon href="#none">뒤</Paging>
        //                 <Paging lastpage icon href="#none">맨뒤</Paging>
        //             </PageBox>
        //         </BoardBoxCont>
        //     </MenuInfoArea>
        // </MenuArea>

        // </>


    )
}

export default InspectionSurvey;
