import React,{useEffect} from "react";
import {useRoutes , useLocation} from "react-router-dom";
import Layout from '../layout/Layout';
import useRepository from "hooks/useRepository";
import { USER_INFO } from "stores/user/repository";
import { useGlobalStore } from "stores";
import storageService from "util/storage";

const AuthRouter = ({common, withAuth, withoutAuth}) => {
    const location = useLocation();
    const [getUserInfo] = useRepository(USER_INFO);
    const [
		{
			user: { info },
		}
	] = useGlobalStore();

    const token = storageService.get("auth", "accessToken", "local");

	let hasToken = token !== undefined;
	let hasUser = info.idx !== null;


	useEffect(() => {
		if (hasToken === true) {
			getUserInfo().catch((e) => {
                console.log("not workded")
				console.log(e);
			});			
		}
	}, [hasToken, location]);

    // const withAuthentication = [...common, ...withAuth];
    const withAuthentication = [...common, ...withAuth];

    const withoutAuthentication = [...common, ...withoutAuth];

    const routes = useRoutes(hasToken && hasUser ?  withAuthentication : withoutAuthentication);

    return <Layout>{routes}</Layout>;
};

export default AuthRouter;
