import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import {SearchBox, SearchBtn, SearchDiv, SearchInput} from 'common/components/ListSearch/ListSearchStyle';
import {
    BoardBox,
    BoardInTxt,
    BoardTable,
    BoardTableTbody,
    BoardTableTd,
    BoardTableTh,
    BoardTableThead,
    BoardTableTr,
    Colgroup
} from 'common/components/Table/TableStyle';
import {
    RewardTokenDetail,
    RewardTokenDetailNum,
    RewardTokenDetailTxt,
    RewardTokenDiv,
    RewardTokenSub,
    RewardTokenTitle
} from "./RewardDetailSytle";
import SearchFilter from "common/components/SearchFilter"
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {
    SearchConditionBox,
    SearchConditionDiv,
    SearchConditionText,
    SearchDateBox,
    SearchDateInput,
    SearchDateInputSpan,
    SearchDateMid,
    SearchDateTxt
} from "../userSurveySytle";
import {DATE_FILTER_OPTIONS, STATUS} from "common/constants/poll";

const INIT_OPTION = {
    BOARD_PARAMS: {
        offset: 0,
        limit: 6
    },
    KEY: '',
    DATE_FILTER_OPTION: 'ALL',
    // START_AT: '2022-12-19',
    // END_AT: '2023-01-19'
}

const RewardMng = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(window.location.search);
    const [reqParams, setReqParams] = useState({});
    const [searchKey, setSearchKey] = useState(INIT_OPTION.KEY);
    const [dateFilterOption, setDateFilterOption] = useState(INIT_OPTION.DATE_FILTER_OPTION);
    const request = useRequest("get", "point/token/list");

    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(null);
    const [formData, setFormData] = useState({offset: 0, limit: 10, page: curPage});
    const pageInfo = pageMaker(curPage, formData.limit, totalNum);
    // const [curPage, setCurPage] = useState(1);
    // const [formData, setFormData] = useState({offset: 0, limit: 10});
    // const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);

    const refStartAt = useRef(null);
    const refEndAt = useRef(null);

    const [_cStartAt, setCStartAt] = useState(null);
    const [_cEndAt, setCEndAt] = useState(null);

    const getSearchDate = () => {
        const now = dayjs(new Date());
        let date = {
            startAt: now,
            endAt: now
        };
        switch (dateFilterOption) {
            case '1M':
                date.startAt = now.subtract(1, 'month');
                break;
            case '3M':
                date.startAt = now.subtract(3, 'month');
                break;
            case '6M':
                date.startAt = now.subtract(6, 'month');
                break;
            case '1Y':
                date.startAt = now.subtract(1, 'year');
                break;
            case 'SELECT':
                date.startAt = refStartAt.current.value ? dayjs(refStartAt.current.value) : now;
                date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
                break;
            case 'ALL':
                date.startAt = null;
                date.endAt = null;
                break;
            default:
                date.startAt = now;
                date.endAt = now;
                break;
        }
        return {
            startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
            endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
        }

    }

    const {startAt, endAt} = getSearchDate();

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            status: STATUS[data.status],
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            startAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            endAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
        }));
    }, {
        ...INIT_OPTION.BOARD_PARAMS,
        startAt,
        endAt
        // ...getSearchDate()
    });

    const handleSelectRow = (row) => {
        navigate(`detail/${row.id}?page=${curPage}`);
    }

    const handleChangeSearchKey = (value) => {
        setSearchKey(value);
    }

    const handleDateFilterSelect = (option) => {
        setDateFilterOption(option);
        const {startAt, endAt} = getSearchDate();
        refStartAt.current.value = startAt;
        refEndAt.current.value = endAt;
    }

    const handleChangeStartAt = () => {
        const {startAt} = getSearchDate();
        refStartAt.current.value = startAt;
        setCStartAt(startAt);
    }

    const handleChangeEndAt = () => {
        const {endAt} = getSearchDate();
        refEndAt.current.value = endAt;
        setCEndAt(endAt);
    }

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum);
        navigate(`${location.pathname}?${searchParams.toString()}`);
        setFormData({...formData, page: curPage, offset: curPage - 1})

        // setCurPage(pageNum)
        // setFormData({...formData, page: pageNum})
    }

    useEffect(() => {
        setCurPage(parseInt(searchParams.get('page')) || 1)

        const {offset} = formData;

        if (offset === params.offset) {
            return;
        }

        if (parseInt(searchParams.get('page'))) {
            fetch({
                ...params,
                page: parseInt(searchParams.get('page')),
                offset: parseInt(searchParams.get('page')) - 1
            });
        } else {
            fetch({
                ...params,
                offset
            })
        }
    }, [formData]);

    useEffect(() => {
        setFormData({...formData, page: curPage, offset: curPage - 1})
    }, [curPage])

    useEffect(() => {
        setCurPage(parseInt(searchParams.get('page')) || 1)
    }, [])


    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>Reward Token 관리</Title>
                    <RewardTokenDiv>
                        <RewardTokenTitle>Reward Tokens</RewardTokenTitle>
                        <RewardTokenSub>Reward Token은 Berry point를 디지털 자산으로 사용하기 위한 Token입니다.</RewardTokenSub>

                        <RewardTokenDetail>
                            <RewardTokenDetailNum>000,000,000</RewardTokenDetailNum>
                            <RewardTokenDetailTxt>Token</RewardTokenDetailTxt>
                        </RewardTokenDetail>
                    </RewardTokenDiv>
                    <SearchConditionBox Interval>
                        <SearchConditionText reward>조회 기간</SearchConditionText>
                        <SearchConditionDiv>
                            <SearchFilter options={DATE_FILTER_OPTIONS} option={dateFilterOption}
                                          onSelect={handleDateFilterSelect} type="filter"/>
                            <SearchDateBox reward>
                                <SearchDateInputSpan>
                                    <SearchDateInput type="date"
                                                     max={endAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                     ref={refStartAt}
                                                     onChange={() => handleChangeStartAt()}/>
                                    <SearchDateTxt>{startAt}</SearchDateTxt>
                                </SearchDateInputSpan>
                                <SearchDateMid>~</SearchDateMid>
                                <SearchDateInputSpan>
                                    <SearchDateInput type="date"
                                                     min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                     ref={refEndAt}
                                                     onChange={() => handleChangeEndAt()}/>
                                    <SearchDateTxt>{endAt}</SearchDateTxt>
                                </SearchDateInputSpan>
                            </SearchDateBox>
                        </SearchConditionDiv>

                        <SearchBox reward>
                            <SearchDiv>
                                <SearchInput type="text" placeholder="입력"/>
                            </SearchDiv>
                            <SearchBtn>검색</SearchBtn>
                        </SearchBox>
                    </SearchConditionBox>

                    <BoardBox>
                        <BoardInTxt>Total Transactions : 464003</BoardInTxt>
                        <BoardTable>
                            <Colgroup>
                                <col width="6%"/>
                                <col width="17%"/>
                                <col width="13%"/>
                                <col width="31%"/>
                                <col width="17%"/>
                                <col width="8%"/>
                                <col width="8%"/>
                            </Colgroup>
                            <BoardTableThead>
                                <BoardTableTr>
                                    <BoardTableTh>NO.</BoardTableTh>
                                    <BoardTableTh>Age</BoardTableTh>
                                    <BoardTableTh>Height</BoardTableTh>
                                    <BoardTableTh>TxHash</BoardTableTh>
                                    <BoardTableTh>Amount</BoardTableTh>
                                    <BoardTableTh>사용 구분</BoardTableTh>
                                    <BoardTableTh>상태</BoardTableTh>
                                </BoardTableTr>
                            </BoardTableThead>
                            <BoardTableTbody>
                                <BoardTableTr>
                                    <BoardTableTd>1</BoardTableTd>
                                    <BoardTableTd><span>MM/DD/YY</span><span>HH:MM:SS</span></BoardTableTd>
                                    <BoardTableTd>924143</BoardTableTd>
                                    <BoardTableTd>64EC11...9DEAA8</BoardTableTd>
                                    <BoardTableTd>100,000,000 </BoardTableTd>
                                    <BoardTableTd>사용</BoardTableTd>
                                    <BoardTableTd>Success</BoardTableTd>
                                </BoardTableTr>
                                <BoardTableTr>
                                    <BoardTableTd>1</BoardTableTd>
                                    <BoardTableTd><span>MM/DD/YY</span><span>HH:MM:SS</span></BoardTableTd>
                                    <BoardTableTd>924143</BoardTableTd>
                                    <BoardTableTd>64EC11...9DEAA8</BoardTableTd>
                                    <BoardTableTd>100,000,000 </BoardTableTd>
                                    <BoardTableTd>사용</BoardTableTd>
                                    <BoardTableTd red>Fail</BoardTableTd>
                                </BoardTableTr>
                            </BoardTableTbody>
                        </BoardTable>
                    </BoardBox>
                    {/*<Table pageData={formData} totalPages={pageInfo?.totalPages} columns={REWARD_TOKEN_DETAIL_COLUMN}*/}
                    {/*       rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum}/>*/}
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    )
}

export default RewardMng;
