import styled, {css} from "styled-components";
import InfoeMiniIcon from "asset/img/icon__info_mini.png"
import Balloon from "asset/img/balloon-top.svg"
import TextDelIcon from "asset/img/icon__cancel.png";

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;


//지갑 - step1
export const WalletCont = styled.div`
  text-align: center;
`;
export const WalletTop = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 80px auto 48px;
  color: #1a1a1a;
`;
export const WalletStep = styled.div`
  margin: 86px auto 80px;
  color: #1a1a1a;
  position: relative;
  height: 48px;
  width: 584px;
`;
export const WalletStepImg = styled.span`
  display: inline-block;
  height: 48px;
`;
export const WalletTxt = styled.p`
  text-align: left;
  letter-spacing: -0.2px;
  margin: 80px 0 0;
  position: relative;
`;
export const WalletStepOne = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #000032;
  position: absolute;
  left: 0;
  top: -38px;
`;
export const WalletBox = styled.div`
  margin: 0 auto;
  width: 380px;
`;

export const EyeIcon = styled.span`
  background-image: url(${(props) => props.img});
  width: 24px;
  height: 24px;
  background-size: cover;
  position: absolute;
  right: 16px;
  top: 12px;
`;


export const InputCont = styled.div`
  margin: 80px auto 73px;
`;
export const InputDiv = styled.div`
  background-color: white;
  position: relative;
  padding: 12px 56px 12px 16px;
  max-width: 380px;
  border: 1px solid #000065;
  border-radius: 8px;

  & + & {
    margin-top: 8px;
  }
`;

export const Input = styled.input`
  width: 100%;
  border: 0;
  font-size: 16px;

  &::placeholder {
    color: #757575;
  }
`;
export const ErrorMsg = styled.p`
  font-size: 12px;
  color: #E92928;
  position: absolute;
  bottom: -25px;
  left: 0;
`;


//지갑 - step2

export const WalletStepTwo = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #000032;
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translate(-50%, 0);
`;
export const WalletBoxWide = styled.div`
  margin: 0 auto;
  width: 590px;
`;

export const Mnemonic = styled.a`
  font-size: 12px;
  color: #1A1A1A;
  border-bottom: 1px solid #1a1a1a;
  position: absolute;
  right: 30px;

  &:before {
    content: "";
    background-image: url(${InfoeMiniIcon});
    position: absolute;
    right: -30px;
    top: -3px;
    width: 24px;
    height: 24px;
    background-size: cover;
  }
`;

export const MnemonicKeyCont = styled.div`
  margin: 24px 0 48px;
  display: grid;
`;
export const MnemonicKeyUl = styled.ul`
  float: left;
`;

export const MnemonicKeyLi = styled.li`
  background-color: white;
  width: 183px;
  height: 48px;
  padding: 10px 24px;
  box-sizing: border-box;
  text-align: center;
  margin: 24px 20px 0 0;
  border: 1px solid #000065;
  border-radius: 8px;
  float: left;

  &:nth-child(3n) {
    margin-right: 0;
  }
`;
export const MnemonicKeyTxt = styled.span`
  width: 135px;
  height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
`;

export const MnemonicKeyInput = styled.input`
  width: 135px;
  height: 20px;
  text-align: center;
  font-size: 14px;
`;
export const MnemonicButtonGroup = styled.div`
  margin: 24px 0;
`;
export const MnemonicButtonLong = styled.button`
  background-color: #fff;
  border-radius: 8px;
  width: 386px;
  padding: 12px 8px;
  font-size: 24px;
  font-weight: 500;
  color: #000065;
  border: 2px solid #000065;
  margin-right: 20px;
  box-sizing: border-box;
`;
export const MnemonicButtonShort = styled.button`
  background-color: #000065;
  border-radius: 8px;
  width: 183px;
  padding: 12px 8px;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  box-sizing: border-box;
`;


//지갑3
export const MnemonicInfo = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 50 / 5%), 0px 0px 4px rgb(0 0 50 / 15%);
  border-radius: 8px;
  padding: 24px;
  width: 542px;
  position: absolute;
  top: 40px;
  right: -80px;

  &:before {
    content: "";
    background-image: url(${Balloon});
    position: absolute;
    right: 55px;
    top: -10px;
    width: 20px;
    height: 10px;
  }
`;
export const MnemonicTxt = styled.span`
  margin: 24px 0;
`;
export const WalletStepThree = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #000032;
  position: absolute;
  top: -38px;
  right: 0;
  text-align: right;
`;
export const MnemonicKeyErrorMsg = styled.p`
  font-size: 12px;
  color: #E92928;
  text-align: left;
  margin-top: 8px;
`;


//팝업
export const PopCont = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;
export const Dim = styled.div`
  width: 100%;
  height: 100%;
  background: #1A1A1A;
  opacity: 0.2;
`;

export const PopArea = styled.div`
  width: 780px;
  height: 340px;
  padding: 48px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #fff;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

export const PopIcon = styled.p`
  display: inline-block;
  width: 48px;
  height: 48px;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  margin-bottom: 48px
`;

export const InfoTxt = styled.p`
  font-size: 16px;
  color: #1A1A1A;
  margin-bottom: 16px;
`;


export const ConfirmButton = styled.button`
  background-color: #000065;
  border-radius: 8px;
  width: 100px;
  padding: 8px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
`;

//채널 등록

export const ChannelJoinCont = styled.div`
  width: 772px;
  float: left;
`;
export const ChannelJoinUl = styled.ul`
  ${(props) =>
          props.no &&
          css`
            li {
              border-top: 0 !important;
            }
          `}
  ${(props) =>
          props.border &&
          css`
            padding: 24px 0 0;
            margin: 24px 0 0;
            border-top: 2px solid #dbdbdb;
          `}
`;
export const ChannelJoinLi = styled.li`
  padding: 24px 0;
  position: relative;

  & + & {
    border-top: 2px solid #dbdbdb;
  }

  // &:first-child{padding:24px 0 48px;}
`;
export const ChannelJoinDiv = styled.div`
  & + & {
    margin-top: 48px;
  }
`;

export const ThumBoxing = styled.div`
  margin-top: 8px;
`;
export const Thum = styled.span`
  width: 86px;
  height: 86px;
  border: 1px solid #757575;
  background-color: #f5f5f5;
  display: inline-block;
  float: left;
  border-radius: ${(props) => props.circle ? "0" : "50%"};

  img {
    width: 100%;
    height: 100%;
    max-width: 86px;
    max-height: 86px;
  }

  ${(props) =>
          props.pop &&
          css`
            width: 80px;
            height: 80px;
            overflow: hidden;
          `};

`;
export const ThumGroup = styled.div`
  display: inline-block;
  margin-left: 24px;
  width: 498px;
  ${(props) =>
          props.pop &&
          css`
            width: 476px;
          `};


`;
export const ThumInfo = styled.div`

`;
export const ThumNameBox = styled.div`
  background: #F5F5F5;
  border: 1px solid #757575;
  border-radius: 8px;
  padding: 10px 8px;
  box-sizing: border-box;
  width: 390px;
  height: 36px;
  box-sizing: border-box;
  display: inline-block;
  float: left;

  ${(props) =>
          props.pop &&
          css`
            width: 368px;
            position: relative;
          `};

  ${(props) =>
          props.Relative &&
          css`
            position: relative;
          `};
`;
export const InputDelBtn = styled.button`
  width: 16px;
  height: 16px;
  background-image: url(${TextDelIcon});
  background-size: cover;
  font-size: 0;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const InputTxtCount = styled.span`
  position: absolute;
  right: 0;
  top: 67px;
  font-size: 10px;
  color: #757575;
`;


export const ThumName = styled.span`
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  color: #757575;
  ${(props) =>
          props.pop &&
          css`
            padding-right: 18px;
          `};

`;
export const ThumAddBtn = styled.label`
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  background-color: #dbdbdb;
  cursor: pointer;
  float: right;
`;


export const ThumNoti = styled.ul`
  clear: both;
  display: inline-block;
  margin-top: 12px;
`;

export const ThumNotiList = styled.li`
  font-size: 10px;
  color: #757575;

  & + & {
    margin-top: 4px;
  }
`;

export const ChannelJoinImg = styled.div`
  width: 360px;
  display: inline-block;
  float: right;
  padding: 24px 0 0;
`;

export const LengthCountSpan = styled.span`
  color: #757575;
  text-align: right;
  font-size: 10px;
  width: ${(props) => props.width};
  display: inline-block;
`;

export const FlexWrapper = styled.div`
  display: inline-flex;
  flex-flow: column;
`;



