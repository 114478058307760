import Router from "router/"
import GlobalStyle from "asset/style/GlobalStyle";
import { GlobalStoreProvider } from "stores";

function App() {
    return (
        <>
        <GlobalStoreProvider>
            <GlobalStyle/>
            <Router/>
        </GlobalStoreProvider>
        </>
    );
}

export default App;
