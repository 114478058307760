const pageMaker = (curPage , curSize , curTotalList ) => {

    let totalList = curTotalList;
    let size = curSize;
    let totalPages = (Math.ceil( totalList / size ));  
    let cPage = curPage; 

    if(curPage > totalPages) {
        cPage = 1;
    }

    let pageLength = 5; 
    let currentBlock = cPage % pageLength === 0 ? 
        cPage / pageLength : (Math.floor(cPage / pageLength) + 1) ; 

    let startPage=(currentBlock-1) * pageLength+1;
    
    let endPage = startPage + pageLength - 1;
    if(endPage > totalPages) {
        endPage = totalPages;
    } 
    

    let prev = startPage > 1;
    let next = endPage < totalPages

    return {cPage, startPage , endPage , prev , next, totalPages}
};

export default pageMaker;