import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";

import Logo from "asset/img/logo_black.png"
import Person from "asset/img/icon__person.png"
import Lock from "asset/img/icon__lock.png"

import useRequest from "hooks/useRequest";
import { login } from "stores/auth/actions";
import { API_STATE } from "common/constants/state";
import {
    Center,
    ErrorMsg,
    GrayMiniMsg,
    GrayMiniMsgClick,
    Input,
    InputIcon,
    LoginCont,
    LoginInputCont,
    LoginInputDiv,
    LoginLogo
} from "pages/Login/LoginStyle"
import {Button} from "common/styledComponents/commonStyle";
import { useGlobalStore } from "stores";
import FindPwdModal from "./FindPwdModal";
import storageService from "util/storage";

const Login = () => {
    const { register, handleSubmit } = useForm();
    const loginRequest = useRequest("post", "/login");
    const [, dispatch] = useGlobalStore();
    const navigate = useNavigate();
    const handleRegistration = (data) => {
        loginRequest.call(data,'body')
    };
    const [showAlert, setShowAlert] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState(false);
    const activeLogin = (data) =>{
        //추후 블록체인 wallet 기술 들어올 시 적용
        // if(data.response.hasWallet){
        //     navigate('/mypage');
        // }else{
        //     navigate('/join')
        // }
        //navigate('/join')
        navigate('/dashboard')
    }

    useEffect(()=>{
        const token = storageService.get("auth", "accessToken", "local");

	    let hasToken = token !== undefined;

        if(hasToken){
            navigate('/dashboard')
        }
    },[]);

    useEffect(() => {
		switch (loginRequest.state) {
			case API_STATE.done:
				if(loginRequest.response.success){
                    setShowAlert(false)
                    dispatch(login({ accessToken: loginRequest.response.response.apiToken }));
                    activeLogin(loginRequest.response);
                }
                else{
                    setShowErrMsg(true);
                }

				break;
			default:
				break;
		}
	}, [loginRequest.state, loginRequest.error, loginRequest.response, dispatch]);


    return (
        <>
            <Center>
                <LoginCont>
                    <LoginLogo>
                        <img src={Logo} alt="pollberry"/>
                    </LoginLogo>
                    <form onSubmit={handleSubmit(handleRegistration)}>
                        <LoginInputCont>
                            <LoginInputDiv>
                                <InputIcon img={Person}/>
                                <Input placeholder="아이디" name="principal" {...register('principal')}/>
                            </LoginInputDiv>
                            <LoginInputDiv>
                                <InputIcon img={Lock}/>
                                <Input type='password' placeholder="비밀번호" name="credentials" {...register('credentials')}/>
                                {showErrMsg && <ErrorMsg>일치하는 정보가 없습니다.</ErrorMsg> }
                            </LoginInputDiv>
                        </LoginInputCont>

                    <Button>로그인</Button>
                    </form>
                    {/* 비활설화 로그인 버튼 */}
                    {/* <Button disabled>로그인</Button> */}
                    <GrayMiniMsg>
                        <GrayMiniMsgClick href="#none" onClick={()=>setShowAlert(true)} >아이디/비밀번호 찾기</GrayMiniMsgClick>
                    </GrayMiniMsg>
                </LoginCont>
            </Center>
           {showAlert && <FindPwdModal setShowAlert={setShowAlert} />}
        </>
    )

}


export default Login;
