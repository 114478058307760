import React from "react";
import {PageBox, Paging} from "common/styledComponents/commonStyle";

const Page = ({pageInfo, handlePage, ...props}) => {
    const {modalList} = props;

    function pageNumbers() {
        const result = []
        for (let i = pageInfo.startPage; i <= pageInfo.endPage; i++) {
            result.push(
                <Paging active={i === pageInfo.cPage} onClick={() => handlePage(i)} key={i} type='button'>{i}</Paging>
            )
        }
        return result
    }

    return (
        <>
            <PageBox modalList={modalList}>
                <Paging type='button' firstpage icon href="#none" disabled={!pageInfo.prev}
                        onClick={() => handlePage(1)}>맨앞</Paging>
                <Paging type='button' prevpage icon href="#none" disabled={!pageInfo.prev}
                        onClick={() => handlePage(pageInfo.startPage - 1)}>앞</Paging>
                {pageNumbers()}
                <Paging type='button' nextpage icon href="#none" disabled={!pageInfo.next}
                        onClick={() => handlePage(pageInfo.endPage + 1)}>뒤</Paging>
                <Paging type='button' lastpage icon href="#none" disabled={!pageInfo.next}
                        onClick={() => handlePage(pageInfo.totalPages)}>맨뒤</Paging>
            </PageBox>
        </>
    )
}

export default Page;
