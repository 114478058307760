import React, {useState} from "react";
import {
    AddFileWrapper,
    Button,
    PollSettingContentContainer,
    PollSettingContentSection,
    PollSettingContentWrapper,
    PollSettingMainContainer,
    PollSettingTitleContainer,
    TitleInputWrapper,
} from "../../../../common/styledComponents/commonStyle";
import {Container, Content, CustomButton, ItemDetailList, Wrapper,} from "./PuzzleTokenItemManagementStyle";

function PuzzleTokenItemManagement(props) {
    const [newDetailItemData, setNewDetailItemData] = useState([]);

    const puzzleTokenTitleItemData = [
        {name: "성별", id: "gender"},
        {name: "연령대", id: "age"},
        {name: "학력", id: "grade"},
        {name: "거주지", id: "residence"},
        {name: "음식 취향", id: "food_preference"},
    ];

    const puzzleTokenDetailItemData = [
        {
            id: "gender",
            itemList: [{value: "성별1"}, {value: "성별2"}, {value: "성별3"}],
        },
        {id: "age", itemList: [{value: "나이1"}, {value: "나이2"}, {value: "나이3"}]},
        {id: "grade", itemList: [{value: "학력1"}, {value: "학력2"}, {value: "학력3"}]},
        {
            id: "residence",
            itemList: [{value: "거주지1"}, {value: "거주지2"}, {value: "거주지3"}],
        },
        {
            id: "food_preference",
            itemList: [{value: "음식 취향1"}, {value: "음식 취향2"}, {value: "음식 취향3"}],
        },
    ];

    const onDetailItemList = (e) => {
        const id = e.target.id;
        const res = puzzleTokenDetailItemData.filter((data) => data.id === id);
        setNewDetailItemData(res);
        console.log(res, "결과 값");
    };

    return (
        <Container>
            <Wrapper Center Column width="1180px">
                <PollSettingTitleContainer width="100%">
                    <span>퍼즐토큰 항목관리</span>
                </PollSettingTitleContainer>
                <PollSettingMainContainer>
                    <form>
                        <PollSettingContentContainer>
                            <PollSettingContentSection>
                                <PollSettingContentWrapper Padding>
                                    <Content>
                                        <AddFileWrapper width="380px" margin="0 8px 0 24px">
                                            <p>test</p>
                                            <input
                                                type="file"
                                                id="test"
                                                // key={keywordItem.id}
                                                name="test"
                                                accept="image/jpg, image/png, image/jpeg"

                                            />
                                            <label htmlFor="test">파일 첨부</label>
                                            <span>*안내 메세지 / 오류 메세지</span>
                                        </AddFileWrapper>
                                    </Content>
                                </PollSettingContentWrapper>
                                <PollSettingContentWrapper Padding>
                                    <Content width="auto" margin="0 20px 0 0" Column>
                                        <Wrapper Center Header>
                                            <span>제목</span>
                                        </Wrapper>
                                        <Wrapper Column Border height="731px">
                                            <ItemDetailList onClick={onDetailItemList}>
                                                {puzzleTokenTitleItemData.map((data, index) => (
                                                    <li id={data.id} key={index}>
                                                        <span id={data.id}>{data.name}</span>
                                                    </li>
                                                ))}
                                            </ItemDetailList>
                                            <Wrapper padding={"16px 7px"}>
                                                <TitleInputWrapper width="256px">
                                                    <input/>
                                                </TitleInputWrapper>
                                                <Button short margin="0 0 0 8px" type="button">
                                                    추가
                                                </Button>
                                            </Wrapper>
                                        </Wrapper>
                                    </Content>
                                    <Content Column width="auto">
                                        <Wrapper Center Header>
                                            <span>항목</span>
                                        </Wrapper>
                                        <Wrapper Border Column height="731px">
                                            <ItemDetailList Row>
                                                {newDetailItemData.map((data) =>
                                                    data.itemList.map((item, index) => (
                                                        <li key={index}>{item.value}</li>
                                                    )),
                                                )}
                                            </ItemDetailList>
                                            <Wrapper padding={"16px 7px"}>
                                                <TitleInputWrapper width="656px">
                                                    <input/>
                                                </TitleInputWrapper>
                                                <Button short margin="0 0 0 8px" type="button">
                                                    추가
                                                </Button>
                                            </Wrapper>
                                        </Wrapper>
                                    </Content>
                                </PollSettingContentWrapper>
                            </PollSettingContentSection>
                            <Wrapper Right>
                                <CustomButton Preview type="button">
                                    모바일 미리보기
                                </CustomButton>
                                <CustomButton mini type="button">
                                    수정사항 저장하기
                                </CustomButton>
                            </Wrapper>
                        </PollSettingContentContainer>
                    </form>
                </PollSettingMainContainer>
            </Wrapper>
        </Container>
    );
}

export default PuzzleTokenItemManagement;
