import React from "react";
import {
    PollSettingContentTitle,
    PollSettingContentWrapper,
    SubjectList,
    SubjectListBlock,
    SubjectListBox,
    SubjectListSpan
} from "common/styledComponents/commonStyle";
import {
    BarBox,
    BarGraph,
    BarNumber,
    BarPercent,
    BarPerson
} from '../../PlatformManage/PlatformDetail/PlatformPollDetailBalanceStyle';

function RankingResult({...props}) {
    const {items, domain, participantsCount, surveyEnabled} = props;
    return (
        <>
            <PollSettingContentWrapper Padding>
                <PollSettingContentTitle Subheading>{surveyEnabled ? '결과 집계' : '랭킹 순위'}</PollSettingContentTitle>
            </PollSettingContentWrapper>
            <PollSettingContentWrapper Padding Border>
                <SubjectListBox no>
                    {items && items.map((item, idx) => {
                        const {id, selectionCount, text, url, imageUrl} = item;
                        const percent = participantsCount ? (selectionCount / participantsCount * 100).toFixed(1) : 0;
                        return (
                            <SubjectList poll key={id}>
                                <SubjectListSpan bigimg style={{height: "140px"}}>
                                    <img style={{width: "100%", height: "100%"}}
                                        // alt="img"
                                         src={imageUrl && imageUrl.includes('data:') ? imageUrl : typeof url === 'string' ? `${domain}${url}` : null}
                                         alt={url ? 'resultKeywordImage' : null}
                                    />
                                </SubjectListSpan>
                                <SubjectListBlock poll>
                                    {/*<SubjectListBlock>텍스트 / 텍스트 없을 경우 항목 {idx+1}.</SubjectListBlock>*/}
                                    <SubjectListBlock>{text}</SubjectListBlock>
                                    {/* <SubjectListBlock>{text}</SubjectListBlock> */}
                                    {surveyEnabled &&
                                        <>
                                            <SubjectListBlock Rel>
                                                <BarBox poll>
                                                    {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                    {/* first 추가시 1등 */}
                                                    <BarGraph first style={{width: `${percent}%`}}/>
                                                </BarBox>
                                            </SubjectListBlock>
                                            <BarNumber poll>
                                                <BarPercent>{`${percent}%`}</BarPercent>
                                                <BarPerson>{selectionCount}명</BarPerson>
                                            </BarNumber>
                                        </>
                                    }
                                </SubjectListBlock>
                            </SubjectList>
                        )
                    })}
                </SubjectListBox>
            </PollSettingContentWrapper>
        </>
    );
}

export default RankingResult;
