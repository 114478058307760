import React, {useEffect, useState} from 'react';
import {
    Button,
    ExposureBadge,
    ListThumbName,
    ListThumbX,
    MenuArea,
    MenuInfoArea,
    Title,
    Wrapper
} from "common/styledComponents/commonStyle";

import Tab from "common/components/Tab"
import {
    KeyBoxA,
    KeyBoxHead,
    KeyBoxIn,
    KeyBoxKeyLi,
    KeyBoxNoti,
    KeyBoxUl,
    KeyCont,
    KeyNum,
    KeyTxt
} from '../../Contents/KeywordManage/KeywordMangeStyle';
import {KeyWorldList, KeyWorldUl} from '../ChannelSurvey/channelSurveySytle';
import useRequest from '../../../hooks/useRequest';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {API_STATE} from '../../../common/constants/state';

const TABS = [
    {
        text: '트렌드 설문',
        value: 'keyword'
    }, {
        text: '',
        value: ''
    }, {
        text: '',
        value: ''
    },
];

function ChannelViewManagement(props) {
    const navigate = useNavigate();
    const exposureKeywordListRequest = useRequest("get", "keyword/exposure");
    const postExposureKeywordListRequest = useRequest("post", "keyword/exposure");
    const [searchParams, setSearchParams] = useSearchParams();
    const [keywordList, setKeyWordList] = useState([])
    const [exposureKeywords, setExposureKeywords] = useState([])
    const categoryType = searchParams.get('type') || 'keyword';
    const [tab, setTab] = useState(categoryType);

    const handleTabChange = (_tab) => {
        if (tab === _tab) {
            return;
        }
        navigate(`/surveyMange/viewManagement?type=${_tab}`);
        setTab(_tab);
    }

    const addExposureKeywords = (name) => {
        if (exposureKeywords.length === 4) {
            alert('"키워드로 알아보는 트렌드 설문" 키워드는 최대 4개까지 설정할 수 있습니다.')
            return
        }

        if (exposureKeywords.filter(keyword => keyword.name === name).length === 0) {
            setExposureKeywords(prevState => [...prevState, {name: name}])
        }
    }

    const deleteExposureKeywords = (name) => {
        setExposureKeywords(prevState => prevState.filter((value) => (
            name !== value.name
        )))
    }

    const submitKeywords = () => {
        postExposureKeywordListRequest.call({keywords: exposureKeywords})
        console.log('submitKeywords')
    }

    useEffect(() => {
        switch (exposureKeywordListRequest.state) {
            case API_STATE.done:
                if (exposureKeywordListRequest.response.success) {
                    const {response} = exposureKeywordListRequest.response;
                    console.log(response)
                    setKeyWordList(response.data, 'response')
                    if (response.keywords !== null) {
                        setExposureKeywords(response.keywords)
                    }
                } else {
                    alert('실패')
                }
                break;
            default:
                break;
        }
    }, [exposureKeywordListRequest.state, exposureKeywordListRequest.error, exposureKeywordListRequest.response]);

    useEffect(() => {
        switch (postExposureKeywordListRequest.state) {
            case API_STATE.done:
                if (postExposureKeywordListRequest.response.success) {
                    alert('적용되었습니다.')
                    exposureKeywordListRequest.call()
                } else {
                    alert('변경 실패')
                }
                break;
            default:
                break;
        }
    }, [postExposureKeywordListRequest.state, postExposureKeywordListRequest.error, postExposureKeywordListRequest.response]);

    useEffect(() => {
        exposureKeywordListRequest.call()
    }, []);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>채널 설문 노출 관리</Title>
                    <Tab Channel={true} tabList={TABS} initTab={categoryType}
                         onChange={handleTabChange}/>
                    <Wrapper Center Row Column>
                        <Wrapper Center>
                            <Wrapper width='140px' margin='0 20px 0 0'>
                                <span>노출 키워드명</span>
                            </Wrapper>
                            <KeyWorldUl Keyword>
                                {exposureKeywords && exposureKeywords.map((keyword, index) => {
                                    return <KeyWorldList key={index}>
                                        <ListThumbName word>{keyword.name}</ListThumbName>
                                        <ListThumbX type='button'
                                                    onClick={() => deleteExposureKeywords(keyword.name)}
                                        />
                                    </KeyWorldList>
                                })}
                            </KeyWorldUl>
                        </Wrapper>
                        <Wrapper>
                            <Button mini margin='0 0 0 auto' type='button' onClick={submitKeywords}>적용하기</Button>
                        </Wrapper>
                    </Wrapper>
                    <KeyCont>
                        <KeyBoxHead>키워드 명</KeyBoxHead>
                        <KeyBoxIn Channel>
                            <KeyBoxUl>
                                {keywordList && keywordList.map((keyword, index) =>
                                    <KeyBoxKeyLi ChannelView
                                                 key={index}
                                                 id={index}
                                                 onClick={() => addExposureKeywords(keyword.name)}
                                    >
                                        <KeyBoxA>
                                            <KeyTxt>{keyword.name}</KeyTxt>
                                            <KeyNum>({keyword.pollCount ? keyword.pollCount : 0})</KeyNum>
                                        </KeyBoxA>
                                        {keyword.isExposure &&
                                            <ExposureBadge>노출</ExposureBadge>
                                        }
                                    </KeyBoxKeyLi>
                                )}
                            </KeyBoxUl>
                        </KeyBoxIn>
                        <KeyBoxNoti>*키워드 (설문수)</KeyBoxNoti>
                    </KeyCont>
                </MenuInfoArea>
            </MenuArea>
        </>
    );
}

export default ChannelViewManagement;
