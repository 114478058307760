import React from "react";
import {
    BackBtn,
    Button,
    ButtonRight,
    List,
    ListLi,
    ListLtxt,
    ListRTextArea,
    ListRtxt,
    ListUl,
    MenuInfoArea,
    Title,
} from "../../styledComponents/commonStyle";
import {MenuInfoCont} from "../../../pages/Mypage/MypageStyle";

function DetailView({title, Info, register, watch, ...props}) {
    return (
        <MenuInfoArea>
            <Title>
                <BackBtn onClick={() => props.navigate(-1)}/>
                {title}
            </Title>
            <MenuInfoCont survey>
                <List wide>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>구분</ListLtxt>
                            <ListRtxt>{Info.type}</ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </MenuInfoCont>
            <MenuInfoCont survey>
                <List wide>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>작성자</ListLtxt>
                            <ListRtxt>{Info.createdBy}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>이메일</ListLtxt>
                            <ListRtxt>{Info.email}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>문의유형</ListLtxt>
                            <ListRtxt>{Info.qaType}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>제목</ListLtxt>
                            <ListRtxt>{Info.title}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt top>내용</ListLtxt>
                            <ListRtxt>{Info.contents}</ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </MenuInfoCont>
            <MenuInfoCont noline>
                <List wide>
                    <ListUl>
                        <ListLi>
                            <ListLtxt top>답변 내용</ListLtxt>

                            <ListRtxt Max>
                                <ListRTextArea
                                    Max
                                    placeholder="텍스트"
                                    onChange={watch.answer}
                                    {...register("answer", {require: true})}
                                />
                            </ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
                <ButtonRight>
                    <Button mini>답변하기</Button>
                </ButtonRight>
            </MenuInfoCont>
        </MenuInfoArea>
    );
}

export default DetailView;
