import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import useRequest from "hooks/useRequest";
import './styles.css';
import {API_STATE} from "common/constants/state";
import {UPLOAD_IMAGE_BASE_URL} from "common/constants/env";

Quill.register('modules/imageResize', ImageResize);
// const modules = useMemo(() => {
//     return {
//         toolbar: [
//             [{header: '1'}, {header: '2'}],
//             [],
//             ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//             [
//                 {list: 'ordered'},
//                 {list: 'bullet'},
//                 {indent: '-1'},
//                 {indent: '+1'}
//             ],
//             // ['link', 'image', 'video'],
//             ['link', 'image'],
//             ['clean']
//         ],
//         clipboard: {
//             // toggle to add extra line breaks when pasting HTML:
//             matchVisual: false
//         },
//         imageResize: {
//             parchment: Quill.import('parchment'),
//             modules: ['Resize', 'DisplaySize']
//         }
//     }
// }, []);

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "font",
    "size",
];
const Editor = ({setEditorData, editorDate, placeholder}) => {
    const quillRef = useRef(null);
    const addEditorImg = useRequest("post", "image/board/new");
    const [tmpImg, setTmpImg] = useState("");

    const modules = useMemo(() => {
        return {
            toolbar: [
                [{header: '1'}, {header: '2'}],
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                    {list: 'ordered'},
                    {list: 'bullet'},
                    {indent: '-1'},
                    {indent: '+1'}
                ],
                // ['link', 'image', 'video'],
                ['link', 'image'],
                ['clean']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
        }
    }, []);

    useEffect(() => {
        const handleImage = () => {

            console.log("getEditor ? ", quillRef.current.getEditor())
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
            input.onchange = async () => {
                const file = input.files[0];

                // 현재 커서 위치 저장
                const range = quillRef.current.getEditor().getSelection(true);

                //await  quillRef.current.getEditor().insertEmbed(range.index, "image", `/images/loading.gif`);

                try {
                    const formData = new FormData();
                    formData.append("image", file);
                    await addEditorImg.call(formData);
                } catch (e) {
                    quillRef.current.getEditor().deleteText(range.index, 1);
                }
            };
        };

        if (quillRef.current) {

            const toolbar = quillRef.current.getEditor().getModule("toolbar");
            toolbar.addHandler("image", handleImage);
        }
    }, []);
    useEffect(() => {
        switch (addEditorImg.state) {
            case API_STATE.done:
                if (addEditorImg.response.success) {
                    setTmpImg(UPLOAD_IMAGE_BASE_URL + addEditorImg.response.response.url);
                }
                break;
            default:
                break;
        }
    }, [addEditorImg.state, addEditorImg.error, addEditorImg.response]);
    useEffect(() => {
        if (tmpImg !== "") {

            const range = quillRef.current.getEditor().getSelection(true);
            quillRef.current.getEditor().insertEmbed(range.index, "image", tmpImg);

            // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
            quillRef.current.getEditor().setSelection(range.index + 1);
        }
    }, [tmpImg]);

    const handleChange = (html) => {
        setEditorData(html);
    }

    const changeFontSize = (value) => {
        const editor = quillRef.current.getEditor();
        editor.focus();
        editor.format('size', value);
    };

    useEffect(() => {
        const editorClass = document.querySelector('.ql-snow')
        const imgWrapperClass = document.querySelectorAll('.ql-editor')

        if (editorClass) {
            imgWrapperClass.forEach((wrapper) => {
                const imgTag = wrapper.querySelectorAll('img')
                imgTag.forEach(img => {
                    img.style.width = '100%'
                    img.style.objectFit = 'contain'
                })
            })
        }

        // if (editorImage) {
        //     const img = test.querySelector('img')
        //     if (img) {
        //         img.style.width = '100%'
        //         img.style.objectFit = 'contain'
        //     }
        // }
    }, [editorDate]);

    return (
        <>
            <ReactQuill
                ref={quillRef}
                theme={"snow"}
                onChange={e => handleChange(e)}
                value={editorDate}
                modules={modules}
                formats={formats}
                placeholder={placeholder}
            />
            <select onChange={(e) => changeFontSize(e.target.value)}>
                <option value="">글꼴 크기 선택</option>
                <option value="small">작게 (16px)</option>
                <option value="large">큼 (20px)</option>
                <option value="12">12px</option>
                <option value="14">14px</option>
                <option value="18">18px</option>
                <option value="24">24px</option>
            </select>
        </>
    );
}

export default Editor;
