import React, {useEffect, useMemo, useState} from "react";
import useRequest from "hooks/useRequest";
import queryString from 'query-string';
import Categories from "./Categories";
import {Button, LineBar, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle"
import {
    KeyBoxA,
    KeyBoxHead,
    KeyBoxIn,
    KeyBoxKeyLi,
    KeyBoxLeft,
    KeyBoxNoti,
    KeyBoxRight,
    KeyBoxUl,
    KeyCont,
    KeyNum,
    KeyTxt,
    KeyTxtBtn,
    KeyWordInputBox,
    KeyWordInputCount,
    KeyWordInputWrapper
} from './KeywordMangeStyle';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useGlobalStore} from "stores";
import {API_STATE} from '../../../common/constants/state';
import NoticeModal from '../../../common/components/NoticeModal/NoticeModal';

const KeywordMange = () => {
    const qs = queryString.parse(window.location.search);
    const categoryId = qs.categoryId || '';
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [categories, setCategories] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [createKeywordValue, setCreateKeywordValue] = useState('');
    const location = useLocation();
    const selectedCategoryId = location.state?.id;
    const [selectedKeywordId, setSelectedKeywordId] = useState(null);
    const [onRemoveModal, setOnRemoveModal] = useState(false);
    const [onModal, setOnModal] = useState(false);
    const [onResultModal, setOnResultModal] = useState(false);
    const [channelSearchState, setChannelSearchState] = useState({id: searchParams.get('categoryId') || ''});
    const keywordsRequest = useRequest("get", "keyword/list");
    const newKeywordRequest = useRequest("post", "keyword/new");
    const deleteKeywordRequest = useRequest("delete", `keyword/${selectedKeywordId}/delete`);

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const selectCategory = (category) => {
        setSelectedCategory(category);
        if (category && category.keywords) {
            setKeywords(category.keywords.map((keyword) => ({...keyword, editState: false})));
        } else {
            setKeywords([])
        }
    }

    const handleCategorySelect = (category) => {
        selectCategory(category);
    }

    const fetchCategories = () => {
        keywordsRequest.call(undefined, undefined, (res) => {
            const {response} = res;
            const category = response.find((_category) => {
                return selectedCategory.id === _category.id
            });

            setCategories(response);
            selectCategory(category);
        });
    }

    const limitLength = (e) => {
        e.target.value = e.target.value.substring(0, 10)
        setCreateKeywordValue(e.target.value);
    }

    const createKeyword = (e) => {
        if (selectedCategory && selectedCategory.id === undefined) {
            alert('포함 할 카테고리를 선택해주세요.')
            return
        } else if (createKeywordValue === '') {
            alert('추가 키워드를 입력해주세요.')
            return
        }
        newKeywordRequest.call({categoryId: selectedCategory.id, name: createKeywordValue}, 'body')

    }


    const hoverKeyword = (e) => {

        const id = Number(e.currentTarget.id)
        // if (id !== selectedKeywordId) {
        //     const doneEdit = keywords.map((data) =>
        //         ({...data, editState: false})
        //     )
        //     setKeywords(doneEdit)
        // }
        setSelectedKeywordId(id)
    }


    const closeModal = (index) => {
        if (onModal) {
            setOnModal(false)
        } else if (onRemoveModal) {
            setOnRemoveModal(false)
        } else if (onResultModal) {
            setOnResultModal(false)
        }
    }

    const onSubmit = () => {
        deleteKeywordRequest.call()
        closeModal()
    }

    const handleRemoveKeyword = (e, index) => {
        e.stopPropagation()
        const id = Number(e.target.parentNode.id)
        if (id !== selectedKeywordId) {
            const doneEdit = keywords.map((data) =>
                ({...data, editState: false})
            )
            setKeywords(doneEdit)
        }
        setSelectedKeywordId(id)


        if (Number(keywords[index].pollCount) > 0) {
            setOnModal(true)
        } else {
            setOnRemoveModal(true)
        }

        // if (!window.confirm("삭제 하시겠습니까?")) {
        //     return;
        // }

        // deleteKeywordRequest.call()

        // if (selectedKeywordId) {
        //     deleteKeywordRequest.call(selectedKeywordId, 'body')
        // }
    }

    // const editKeyword = (e) => {
    //     const id = e.target.parentNode.id;
    //     const name = e.target.name;
    //
    //     if (name === 'edit') {
    //         const customData = keywords.map((data) => Number(id) === data.id ?
    //             {...data, editState: true} : {...data, editState: false}
    //         )
    //         setKeywords(customData)
    //     }
    //
    //     if (name === 'check') {
    //         const doneEdit = keywords.map((data) =>
    //             ({...data, editState: false})
    //         )
    //         setKeywords(doneEdit)
    //     }
    // }

    const moveChannelList = (e, keyword) => {
        e.stopPropagation();
        setSearchParams({type: 'keyword', categoryId: channelSearchState.id, keyword: keyword})
        navigate(`/surveyMange/channelSurvey?type=keyword&categoryId=${channelSearchState.id}&keyword=${keyword}&page=1`);
    }

    const onChangeKeyword = (e) => {
        const value = e.target.value;
        const customData = keywords.map((data) => data.editState ?
            {...data, name: value} : {...data}
        )
        setKeywords(customData)
    }

    useEffect(() => {
        const selectedCategoryId = location.state?.id;

        keywordsRequest.call(undefined, undefined, (res) => {
            const {response} = res;
            setCategories(response);
            if (categoryId) {
                const category = response.find((category) => {
                    return categoryId === category.id.toString()
                });
                if (category) {
                    selectCategory(category);
                    return;
                }
            }
            // if (response && response[0]) {
            //     selectCategory(response[0])
            // }

            if (selectedCategoryId) {
                let category
                if (selectedCategoryId) {
                    category = response.find((_category) => {
                        return selectedCategoryId === _category.id
                    });
                } else {
                    category = response.find((_category) => {
                        return selectedCategory.id === _category.id
                    });
                }
                selectCategory(category);
                setCategories(response);
            }
        });
    }, []);

    useEffect(() => {
        switch (deleteKeywordRequest.state) {
            case API_STATE.done:
                if (deleteKeywordRequest.response.success) {
                    setOnResultModal(true)
                    fetchCategories()
                }
                break;
            default:
                break;
        }


    }, [deleteKeywordRequest.state, deleteKeywordRequest.error, deleteKeywordRequest.response]);

    useEffect(() => {
        switch (newKeywordRequest.state) {
            case API_STATE.done:
                if (newKeywordRequest.response.success) {
                    alert('키워드가 추가되었습니다.')
                    fetchCategories();
                    setCreateKeywordValue('')
                }
                if (!newKeywordRequest.response.success) {
                    if (newKeywordRequest.response.error.message.includes('duplicate')) {
                        alert(`중복된 키워드가 있습니다.`)
                    } else {
                        alert(newKeywordRequest.response.error.message)
                    }
                }
                break;
            default:
                break;
        }
    }, [newKeywordRequest.state, newKeywordRequest.error, newKeywordRequest.response]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>설문 키워드 관리</Title>
                    <KeyCont>
                        <KeyBoxLeft>
                            <KeyBoxHead>카테고리 명</KeyBoxHead>
                            <KeyBoxIn>
                                <Categories categories={categories} initCategory={selectedCategory}
                                            onSelect={handleCategorySelect} selectedCategoryId={selectedCategoryId}
                                            setChannelSearchState={setChannelSearchState}/>
                            </KeyBoxIn>
                        </KeyBoxLeft>
                        {/*<KeyBoxRight>*/}
                        {/*    <KeyBoxHead>카테고리 이미지</KeyBoxHead>*/}
                        {/*    <CategoryImageWrapper>*/}
                        {/*        <CategoryImage>*/}
                        {/*            <img src={TestImg} alt='프로필 이미지'/>*/}
                        {/*        </CategoryImage>*/}
                        {/*    </CategoryImageWrapper>*/}
                        {/*</KeyBoxRight>*/}
                        {
                            contentsAuth.find(d => d === "W") &&
                            <KeyBoxRight>
                                <KeyBoxHead>키워드 추가</KeyBoxHead>
                                <KeyWordInputWrapper>
                                    <span>{selectedCategory && selectedCategory.name}</span>
                                    <KeyWordInputBox>
                                        <input maxLength={10} id='create_Keyword' name='create_Keyword'
                                               value={createKeywordValue}
                                               onChange={limitLength
                                                   // (e) => setCreateKeywordValue(e.target.value)
                                               }
                                        />
                                        <KeyWordInputCount>{createKeywordValue !== '' ? createKeywordValue.length : 0}/10자</KeyWordInputCount>
                                    </KeyWordInputBox>
                                    <Button mini onClick={createKeyword}>추가하기</Button>
                                </KeyWordInputWrapper>
                            </KeyBoxRight>
                        }

                    </KeyCont>
                    <LineBar/>
                    <KeyCont>
                        <KeyBoxHead>키워드 명</KeyBoxHead>
                        <KeyBoxIn high='high'>
                            <KeyBoxUl>
                                {keywords && keywords.map((keyword, index) =>
                                    <KeyBoxKeyLi key={keyword.id}
                                                 id={keyword.id}
                                                 onMouseOver={hoverKeyword}
                                                 onClick={(e) => moveChannelList(e, keyword.name)}
                                        // onMouseOut={onMouseOutKeyword}
                                    >
                                        <KeyBoxA>
                                            {keyword.editState ?
                                                <input value={keyword.name} onChange={onChangeKeyword}/> :
                                                <>
                                                    <KeyTxt>{keyword.name}</KeyTxt>
                                                    <KeyNum>({keyword.pollCount ? keyword.pollCount : 0})</KeyNum>
                                                </>
                                            }
                                        </KeyBoxA>
                                        {selectedKeywordId === keyword.id &&
                                            <KeyTxtBtn
                                                onClick={(e) => handleRemoveKeyword(e, index)}
                                            >
                                                삭제
                                            </KeyTxtBtn>
                                        }
                                        {/*{isHover && <>*/}
                                        {/*    /!*{keyword.editState ?*!/*/}
                                        {/*    /!*    <KeyBtn name='check' check onClick={editKeyword}/> :*!/*/}
                                        {/*    /!*    <KeyBtn name='edit' edit onClick={editKeyword}/>*!/*/}
                                        {/*    /!*}*!/*/}
                                        {/*    <KeyBtn del*/}
                                        {/*        // onClick={handleRemoveKeyword}*/}
                                        {/*    />*/}
                                        {/*</>}*/}
                                    </KeyBoxKeyLi>
                                )}
                            </KeyBoxUl>
                        </KeyBoxIn>
                        <KeyBoxNoti>*키워드 (설문수)</KeyBoxNoti>

                        {/*<KeywordsContainer>*/}
                        {/*    <KeywordsGrid>*/}
                        {/*        {keywords && keywords.map((keyword) => <span>{keyword.name}</span>)}*/}
                        {/*    </KeywordsGrid>*/}
                        {/*</KeywordsContainer>*/}
                        {/*<KeyBoxNoti>*키워드 (설문수)</KeyBoxNoti>*/}
                    </KeyCont>
                </MenuInfoArea>
                {onModal &&
                    <NoticeModal closeModal={closeModal} title='키워드 관리'>
                        <span>키워드가 등록된 설문이 존재합니다.</span>
                        <span>설문에서 삭제 혹은 수정 후 삭제해주세요.</span>
                    </NoticeModal>
                }
                {onRemoveModal &&
                    <NoticeModal closeModal={closeModal} title='키워드 관리' onSubmit={onSubmit} check>
                        <span>키워드를 삭제하시겠습니까?</span>
                    </NoticeModal>
                }
                {onResultModal &&
                    <NoticeModal closeModal={closeModal} title='키워드 관리'>
                        <span>삭제 되었습니다.</span>
                    </NoticeModal>
                }
            </MenuArea>
        </>
    )
}

export default KeywordMange;
