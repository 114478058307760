import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import {API_STATE} from "common/constants/state";
import DATE from "common/constants/date";
import {UPLOAD_IMAGE_BASE_URL} from "common/constants/env";
import Title from "common/components/Title";
import CommonRankingView from "../CommonRankingView"
import {PollSettingContainer, PollSettingMainContainer} from "common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";

function RankingDetail(props) {
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const rankingDetailRequest = useRequest("get", `ranking/${id}`);
    const updateRankingDetailRequest = useRequest("patch", `ranking/${id}/update`);
    // const deleterankingDetailRequest = useRequest("delete", `poll/${id}/event/delete`);
    const categoryListRequest = useRequest("get", `category/ranking/simple/list`);
    const rankingRewardListRequest = useRequest("get", `ranking/${id}/reward/list`);
    const participationListRequest = useRequest("get", `ranking/${id}/participants`);
    const [categoryList, setCategoryList] = useState(null);
    const [rankingInfo, setRankingInfo] = useState(null);
    const today = dayjs().format(DATE.FORMAT.DASH_TIME);

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();


    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleSubmit = (formData) => {
        console.log('handleSubmit', formData);
        // debugger;
        updateRankingDetailRequest.call(formData);
    }

    const handleDelete = () => {
        debugger;
        // deletePlatformDetailRequest.call()
    }

    useEffect(() => {
        switch (categoryListRequest.state) {
            case API_STATE.done:
                if (categoryListRequest.response.success) {
                    const {response} = categoryListRequest.response;
                    if (!response || !response.data) {
                        return;
                    }
                    const categories = response.data.map((category) => {
                        const {id, name} = category;
                        return {
                            id,
                            value: id,
                            label: name
                        }
                    });
                    // categories.unshift({
                    //     id: '',
                    //     value: '',
                    //     label: '선택하세요'
                    // });
                    setCategoryList(categories);
                    // rankingDetailRequest.call()
                }

                break;
            default:
                break;
        }
    }, [categoryListRequest.state, categoryListRequest.error, categoryListRequest.response]);

    useEffect(() => {
        switch (rankingDetailRequest.state) {
            case API_STATE.done:
                if (rankingDetailRequest.response.success) {
                    const {response} = rankingDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    let rankingInfo = {
                        ...response,
                        // type: response.type.toLowerCase(),
                        startAt: dayjs(new Date(response.startAt)).format(DATE.FORMAT.DASH_TIME),
                        endAt: dayjs(new Date(response.endAt)).format(DATE.FORMAT.DASH_TIME),
                        createdAt: dayjs(new Date(response.createdAt)).format(DATE.FORMAT.DASH_TIME),
                        isView: true,
                    }
                    if (response.backgroundImageUrl) {
                        rankingInfo.backgroundImageUrl = response.backgroundImageUrl.replace(UPLOAD_IMAGE_BASE_URL, "")
                    }
                    if (response.items?.length > 0) {
                        rankingInfo.items = response.items.map((item) => {
                            return {
                                ...item,
                                url: item.url.replace(UPLOAD_IMAGE_BASE_URL, "")
                            }
                        });
                    }
                    setRankingInfo(rankingInfo);
                    // categoryListRequest.call();
                }

                break;
            default:
                break;
        }
    }, [rankingDetailRequest.state, rankingDetailRequest.error, rankingDetailRequest.response]);

    useEffect(() => {
        switch (updateRankingDetailRequest.state) {
            case API_STATE.done:
                if (updateRankingDetailRequest.response.success) {
                    alert("수정 되었습니다.");
                    navigate('/contents/lankList');
                }

                if (updateRankingDetailRequest.response.success === false) {
                    const errorMessage = updateRankingDetailRequest.response.error.message;
                    alert(errorMessage);
                }
                break;
            default:
                break;
        }
    }, [updateRankingDetailRequest.state, updateRankingDetailRequest.error, updateRankingDetailRequest.response]);

    useEffect(() => {
        categoryListRequest.call()
        rankingDetailRequest.call()
    }, []);

    return (
        <PollSettingContainer>
            <Title>랭킹 상세보기</Title>
            <PollSettingMainContainer>
                {/* <CommonRankingView  {...commonViewParams} categoryList={categoryList} onSubmit={handleSubmit} /> */}
                {
                    rankingInfo &&
                    <CommonRankingView  {...rankingInfo} {...{contentsAuth}} categoryList={categoryList}
                                        onSubmit={handleSubmit}
                                        onDelete={handleDelete} today={today} rankingDetailId={id}
                                        berryRankingState={rankingInfo.surveyEnabled} id={id}
                                        rankingRewardListRequest={rankingRewardListRequest}
                                        participationListRequest={participationListRequest}/>
                }
            </PollSettingMainContainer>
        </PollSettingContainer>
    );
}

export default RankingDetail;
