import React, {useState} from 'react';
import {ModalImageWrapper, PopClose, StyledModal} from '../../styledComponents/commonStyle';
import styled from 'styled-components';
import CloseIcon from 'asset/img/icon__close.svg'


const SlideContainer = styled.div`
  position: relative;
  width: 500px;
  height: 500px;
`;

const Slide = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  ${({active}) =>
          active &&
          `opacity: 1;
  `}
`;

const PrevButton = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
`;

const NextButton = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
`;

function Modal({data, state, setState, ...props}) {

    const [currentSlide, setCurrentSlide] = useState(0);

    const prevSlide = () => {
        if (typeof data === 'object') {
            setCurrentSlide(currentSlide === 0 ? data.length - 1 : currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (typeof data === 'object') {
            setCurrentSlide(currentSlide === data.length - 1 ? 0 : currentSlide + 1);
        }
    };

    return (
        state &&
        <StyledModal>
            {/*<ModalContentWrapper>*/}
            <SlideContainer>
                {data && typeof data === 'object' && data.map((value, index) =>
                    <Slide key={index} active={index === currentSlide}>
                        <ModalImageWrapper width='360px' height='300px'>
                            <img src={`${props.domain}${value.url}`} alt='thumbnail'/>
                        </ModalImageWrapper>
                    </Slide>
                )}
                {data && typeof data === 'string' &&
                    <Slide active={true}>
                        <ModalImageWrapper width='360px' height='300px'>
                            <img src={`${props.domain}${data}`} alt='thumbnail'/>
                        </ModalImageWrapper>
                    </Slide>
                }
                <PopClose onClick={() => setState(false)} type='button'>
                    <img src={CloseIcon} alt='icon_close'/>
                </PopClose>
                <PrevButton onClick={prevSlide} type='button'>
                    &#8249;
                </PrevButton>
                <NextButton onClick={nextSlide} type='button'>
                    &#8250;
                </NextButton>
            </SlideContainer>
            {/*</ModalContentWrapper>*/}
        </StyledModal>
    );
}

export default Modal;
