import React, {useEffect, useMemo, useState} from 'react';
import {
    ButtonWrapper,
    ChPreviewName,
    Content,
    ContentSection,
    ContentTitle,
    ContentWrapper,
    CreateChannelContent,
    FlexWrapper,
    HeartImage,
    NoticeWrapper,
    PreviewBottomImage,
    PreviewChannelDescription,
    PreviewChannelDescriptionText,
    PreviewChannelDescriptionTitle,
    PreviewCont,
    PreviewContentBottom,
    PreviewContentTop,
    PreviewThumbnail,
    PreviewTopBarImage,
    PreviewWrapper,
    ProfileFileWrapper,
    ProfilePreviewWrapper,
} from './CreateChannelPageStyle';
import {
    AddFileWrapper,
    BackBtn,
    Button,
    ListInput,
    MenuArea,
    MenuInfoArea,
    RemoveButton,
    Title
} from '../../common/styledComponents/commonStyle';
import {TextByteCheck} from '../../common/js/TextValueCheck';
import {useNavigate} from 'react-router';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import useRequest from '../../hooks/useRequest';
import {API_STATE} from '../../common/constants/state';
import PreviewNone from "asset/img/channel_preview__cont_002_none.png";
import HeartIcon from "asset/img/heart-icon.png";
import PreviewTopBar from "asset/img/topbar.png";
import ChannelPreviewBg from "asset/img/preview_bottom_poll.png";
import PreviewBottomBar from "asset/img/preview_bottom_bar.png";
import RemoveIcon from "asset/img/icon__cancel.png";
import prevImage from "asset/img/img__priview.png";
import {useLocation} from 'react-router-dom';


function CreateChannelPage(props) {
    const [channelName, setChannelName] = useState('');
    const [bizMember, setBizMember] = useState([]);
    const [channelCategory, setChannelCategory] = useState([]);
    const [channelDescription, setChannelDescription] = useState('');
    const [imageState, setImageState] = useState({profileImageUrl: {}, thumbnailImageUrl: {}});
    const navigate = useNavigate();
    const location = useLocation();
    const {register, handleSubmit, watch, control, setValue, reset} = useForm();
    const bizMemberRequest = useRequest("get", "user/biz/list");
    const channelCategoryRequest = useRequest("get", "category/channel/simple/list");
    const createNewChannel = useRequest("post", "channel/new");
    const addChannelProfileRequest = useRequest("post", "image/channel/profile/new");
    const addChannelThumbnailRequest = useRequest("post", "image/channel/thumbnail/new");
    const selectedUserId = location.state?.userId;


    const handleEditInfo = async (data) => {
        const formData = new FormData();
        let loadProfileImageUrl;
        let loadThumbnailImageUrl;

        const profileImageUrl = watch().profileImageUrl[0];
        formData.append("image", profileImageUrl);
        await addChannelProfileRequest.asyncCall(formData, 'body').then((result) => loadProfileImageUrl = result.response.url).catch((e) => console.log(e))
        formData.delete('image');

        const thumbnailImageUrl = watch().thumbnailImageUrl[0]
        formData.append("image", thumbnailImageUrl);
        await addChannelThumbnailRequest.asyncCall(formData, 'body').then((result) => loadThumbnailImageUrl = result.response.url).catch((e) => console.log(e))

        const customData = [data].map(data => ({
            ...data,
            profileImageUrl: loadProfileImageUrl,
            thumbnailImageUrl: loadThumbnailImageUrl
        }))

        createNewChannel.call(...customData, 'body');
    };

    const textOnChange = (e) => {
        const id = e.target.id;
        const text = e.target.value;
        if (id === 'name') {
            setChannelName(watch().name)
            TextByteCheck(text, setChannelName, 15)
            setValue('name', watch().name.substring(0, 15))
        } else if (id === 'contents') {
            setChannelDescription(watch().contents)
            TextByteCheck(text, setChannelDescription, 1000)
            setValue('contents', watch().contents.substring(0, 1000))
        }
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 292,
                border: "1px solid #757575",
                borderRadius: "8px",
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
        }),
        [],
    );

    const onChangeImage = (e) => {
        if (e.target.files.length === 0) {
            return
        }

        let file
        const id = e.target.id

        if (id === 'profileImageUrl') {
            file = watch().profileImageUrl[0];
        }

        if (id === 'thumbnailImageUrl') {
            file = watch().thumbnailImageUrl[0]
        }

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const res = reader.result;

            if (id === 'profileImageUrl') {
                setImageState(prevState => ({...prevState, profileImageUrl: {name: file.name, src: res}}))
            } else if (id === 'thumbnailImageUrl') {
                setImageState(prevState => ({...prevState, thumbnailImageUrl: {name: file.name, src: res}}))
            }
        };
    };

    // function handleKeyDown(e) {
    // if (e.key === 'Enter') {
    //     e.preventDefault();
    // }
    // }

    const removeProfile = (e) => {
        setImageState(prevState => ({...prevState, profileImageUrl: {}}))
        reset(prevState => ({...prevState, profileImageUrl: null}))
    }

    const removeThumbnail = (e) => {
        setImageState(prevState => ({...prevState, thumbnailImageUrl: {}}))
        reset(prevState => ({...prevState, thumbnailImageUrl: null}))
    }


    useEffect(() => {
        bizMemberRequest.call('', 'body');
        channelCategoryRequest.call()
    }, [])

    useEffect(() => {

        switch (bizMemberRequest.state) {
            case API_STATE.done:
                if (bizMemberRequest.response.success) {
                    const result = bizMemberRequest.response.response;

                    if (selectedUserId) {
                        const selectedBizMember = result.filter((res) => res.userId === selectedUserId)
                        const customSelectedBizMember = selectedBizMember.map((value) => ({
                            id: value.id,
                            value: value.userId,
                            label: value.userId,
                        }))
                        setBizMember(customSelectedBizMember)
                        setValue('userId', customSelectedBizMember[0].id)
                    } else {
                        setBizMember(result?.map((data) => (
                                {id: data.id, value: data.userId, label: data.userId})
                            )
                        )
                    }
                }
                break;
            default:
                break;
        }

        switch (channelCategoryRequest.state) {
            case API_STATE.done:
                if (channelCategoryRequest.response.success) {
                    const result = channelCategoryRequest.response.response.data;

                    setChannelCategory(result !== null && result.map((data) => (
                        {id: data.id, value: data.name, label: data.name})
                    )
                    )
                }
                break;
            default:
                break;
        }

        switch (createNewChannel.state) {
            case API_STATE.done:
                if (createNewChannel.response.success) {
                    alert("생성 되었습니다.");
                    navigate('/channel/manage');
                }

                if (!createNewChannel.response.success) {
                    if (createNewChannel.response.error.message.includes('duplicate') && createNewChannel.response.error.message.includes('channels_name_key')) {
                        alert("채널 이름이 중복됩니다.");
                    }
                }
                break;
            default:
                break;
        }

    }, [bizMemberRequest.state,
        bizMemberRequest.error,
        bizMemberRequest.response,
        channelCategoryRequest.state,
        channelCategoryRequest.error,
        channelCategoryRequest.response,
        createNewChannel.state,
        createNewChannel.error,
        createNewChannel.response
    ]);

    return (
        // <MainContainer>
        <MenuArea>
            <MenuInfoArea>
                <form onSubmit={handleSubmit((data) => handleEditInfo(data))}>
                    <Title>
                        <BackBtn onClick={() => navigate(-1)}/>
                        신규 채널 생성하기
                    </Title>
                    <CreateChannelContent>
                        <FlexWrapper>
                            <ContentSection>
                                <ContentWrapper Border padding>
                                    <ContentTitle>비즈회원 아이디</ContentTitle>
                                    <Content>
                                        <Controller
                                            name="userId"
                                            control={control}
                                            render={({field: {onChange}}) =>
                                                <Select
                                                    options={bizMember}
                                                    styles={customStyles}
                                                    value={selectedUserId && bizMember}
                                                    onChange={selectedOption => {
                                                        onChange(selectedOption.id);
                                                    }}
                                                />
                                            }
                                            rules={{required: true}}
                                        />
                                    </Content>
                                </ContentWrapper>
                                <ContentWrapper>
                                    <ContentTitle>채널 카테고리</ContentTitle>
                                    <Content>
                                        <Controller
                                            name="categoryId"
                                            control={control}
                                            render={({field: {onChange}}) =>
                                                <Select
                                                    options={channelCategory}
                                                    styles={customStyles}
                                                    onChange={selectedOption => {
                                                        onChange(selectedOption.id);
                                                    }}
                                                />
                                            }
                                            rules={{required: true}}
                                        />
                                    </Content>
                                </ContentWrapper>
                                <ContentWrapper padding>
                                    <ContentTitle>채널 명</ContentTitle>
                                    <Content ChannelInput>
                                        <ListInput type="text"
                                                   long
                                                   id='name'
                                                   placeholder=""
                                                   value={channelName}
                                                   maxlength='20'
                                                   {...register('name', {
                                                       required: true,
                                                       onChange: textOnChange,
                                                   })}
                                        />
                                        <span>{channelName.length}/15자</span>
                                    </Content>
                                </ContentWrapper>
                                <ContentWrapper padding Border>
                                    <ContentTitle>채널 설명</ContentTitle>
                                    <Content ChannelInput>
                                        <textarea rows={5} maxLength={1000}
                                                  id='contents'
                                                  value={channelDescription}
                                            // onKeyDown={handleKeyDown}
                                                  {...register('contents', {
                                                      required: true,
                                                      onChange: textOnChange
                                                  })}
                                        />
                                        <span>{channelDescription.length}/1000자</span>
                                    </Content>
                                </ContentWrapper>
                                <ContentWrapper>
                                    <ContentTitle>채널 프로필 이미지</ContentTitle>
                                    <Content ChannelProfile>
                                        <ProfileFileWrapper>
                                            <ProfilePreviewWrapper>
                                                {imageState.profileImageUrl &&
                                                    <img
                                                        src={imageState.profileImageUrl.src ? imageState.profileImageUrl.src : prevImage}
                                                        alt='채널 프로필'/>
                                                }
                                            </ProfilePreviewWrapper>
                                            <FlexWrapper margin='0 0 0 24px'>
                                                <AddFileWrapper width='336px'>
                                                    <FlexWrapper Relative>
                                                        <p>{imageState.profileImageUrl && imageState.profileImageUrl.name}</p>
                                                        <RemoveButton
                                                            type='button'
                                                            onClick={(e) => removeProfile(e)}
                                                        >
                                                            <img src={RemoveIcon} alt='remove'/>
                                                        </RemoveButton>
                                                    </FlexWrapper>
                                                    <input
                                                        type="file"
                                                        id='profileImageUrl'
                                                        accept="image/jpg, image/png, image/jpeg"
                                                        {...register('profileImageUrl', {
                                                            required: true,
                                                            onChange: onChangeImage
                                                        })}
                                                    />
                                                    <label htmlFor='profileImageUrl'>파일 첨부</label>
                                                </AddFileWrapper>
                                                <NoticeWrapper>
                                                    <span>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg, jpeg, png, bmp</span>
                                                </NoticeWrapper>
                                            </FlexWrapper>
                                        </ProfileFileWrapper>
                                    </Content>
                                </ContentWrapper>
                                <ContentWrapper padding Border>
                                    <ContentTitle>채널 썸네일 이미지</ContentTitle>
                                    <Content>
                                        <AddFileWrapper width='500px'>
                                            <FlexWrapper Relative>
                                                <p>{imageState.thumbnailImageUrl && imageState.thumbnailImageUrl.name}</p>
                                                <RemoveButton
                                                    type='button'
                                                    onClick={removeThumbnail}
                                                >
                                                    <img src={RemoveIcon} alt='remove'/>
                                                </RemoveButton>
                                            </FlexWrapper>
                                            <input
                                                type="file"
                                                id='thumbnailImageUrl'
                                                accept="image/*"
                                                {...register('thumbnailImageUrl', {
                                                    required: true,
                                                    onChange: onChangeImage
                                                })}
                                            />
                                            <label htmlFor='thumbnailImageUrl'>파일 첨부</label>
                                        </AddFileWrapper>
                                        <NoticeWrapper>
                                            <span>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg, jpeg, png, bmp</span>
                                        </NoticeWrapper>
                                    </Content>
                                </ContentWrapper>


                            </ContentSection>
                            <ButtonWrapper>
                                <Button mini>
                                    생성하기
                                </Button>
                            </ButtonWrapper>
                        </FlexWrapper>
                        <PreviewWrapper>
                            <PreviewCont>
                                <PreviewContentTop>
                                    <PreviewThumbnail>
                                        {imageState.thumbnailImageUrl && imageState.thumbnailImageUrl.src ?
                                            <img src={imageState.thumbnailImageUrl.src} alt='thumbnail_image'/>
                                            : <img src={PreviewNone} alt='PreviewNone'/>
                                        }
                                    </PreviewThumbnail>
                                    <PreviewTopBarImage src={PreviewTopBar} alt='top_bar'/>
                                    {watch().name ?
                                        <ChPreviewName>{watch().name}</ChPreviewName> :
                                        <ChPreviewName>채널이름</ChPreviewName>
                                    }
                                    <HeartImage src={HeartIcon} alt='heart_icon'/>
                                </PreviewContentTop>
                                <PreviewChannelDescription>
                                    <PreviewChannelDescriptionTitle>채널 소개</PreviewChannelDescriptionTitle>
                                    {watch().contents ?
                                        <PreviewChannelDescriptionText>{watch().contents}</PreviewChannelDescriptionText> :
                                        <PreviewChannelDescriptionText Center>채널 설명이
                                            들어갑니다.</PreviewChannelDescriptionText>
                                    }
                                </PreviewChannelDescription>
                                <PreviewContentBottom>
                                    <img src={ChannelPreviewBg} alt='preview_poll'/>
                                    <PreviewBottomImage src={PreviewBottomBar} alt='bottom_bar'/>
                                </PreviewContentBottom>
                            </PreviewCont>
                        </PreviewWrapper>
                    </CreateChannelContent>
                </form>
            </MenuInfoArea>
        </MenuArea>
    );
}

export default CreateChannelPage;
