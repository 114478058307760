import React, {useRef, useState} from "react";
import {Button, TitleInputWrapper,} from "../../../common/styledComponents/commonStyle";
import {Content, ItemDetailList, ItemLi, PuzzleModifyBtn, TokenDelBtn, Wrapper} from "./PuzzleTokenManagementStyle";

function TokenItem(props) {
    const {
        currentTokenTitle,
        tokenItems = [],
        onSelect,
        onAdd,
        onRemove,
        onEdit,
    } = props;
    const titleRef = useRef(null);
    const [editMode, setEditMode] = useState('read');

    const handleClickItemTitle = (e) => {
        e.preventDefault();
        if (titleRef.current.value) {
            titleRef.current.value = '';
        }
        const {title} = e.currentTarget;
        onSelect(title);
        if (currentTokenTitle === title) {
            setEditMode('edit');
            titleRef.current.value = currentTokenTitle;
        } else {
            setEditMode('read');
        }
    }

    const handleSubmitTitle = (e) => {
        e.preventDefault();
        const {value} = titleRef.current;
        const exist = tokenItems.find(tokenItem => tokenItem.title === value);
        if (!value) {
            return;
        }
        if (exist) {
            return;
        }
        onAdd(value);
        titleRef.current.value = '';
        titleRef.current.focus();
    }

    const handleRemoveTitle = (title, e) => {
        e.stopPropagation();
        onRemove(title);
    }

    const cancelEdit = (e) => {
        e.stopPropagation();
        setEditMode('read');
        titleRef.current.value = '';
    }

    const submitEdit = (e) => {
        e.stopPropagation();
        console.log("Edit submit >> ", titleRef.current.value)
        const {value} = titleRef.current;
        const exist = tokenItems.find(tokenItem => tokenItem.title === value);
        if (!value) {
            return;
        }
        if (exist) {
            alert("이미 등록된 제목 입니다.")
            return;
        }
        onEdit(value);
        // 객체 수정 후 onAdd()
        setEditMode('read');
        titleRef.current.value = '';
    }


    return (
        <Content width="auto" margin="0 20px 0 0" Column>
            <Wrapper Center Header>
                <span>제목</span>
            </Wrapper>
            <Wrapper Column Border height="731px">
                <ItemDetailList>
                    {tokenItems.map(tokenItem => {
                        const {id, title} = tokenItem;
                        return (
                            <ItemLi id={id} key={id} title={title} style={{position: 'relative'}}
                                    onClick={handleClickItemTitle} {...(currentTokenTitle === title && {On: true})}>
                                <span>{title}</span>
                                {
                                    currentTokenTitle === title &&
                                    <>
                                        <TokenDelBtn onClick={handleRemoveTitle.bind(null, title)}>삭제</TokenDelBtn>
                                    </>
                                }
                            </ItemLi>
                        )
                    })}
                </ItemDetailList>
                <form onSubmit={handleSubmitTitle}>
                    <Wrapper line padding={"16px 7px"}>
                        <TitleInputWrapper style={{position: "relative", width: "256px"}}>
                            <input ref={titleRef}/>
                            {
                                editMode === 'edit' &&
                                (
                                    <>
                                        <PuzzleModifyBtn href="#none" onClick={cancelEdit}>
                                            취소
                                        </PuzzleModifyBtn>
                                    </>
                                )
                            }
                        </TitleInputWrapper>
                        {
                            editMode === "read" ?
                                (
                                    <Button short margin="0 0 0 8px">
                                        추가
                                    </Button>
                                ) :
                                (
                                    <Button short onClick={submitEdit} margin="0 0 0 8px">
                                        수정
                                    </Button>
                                )
                        }

                    </Wrapper>
                </form>
            </Wrapper>
        </Content>
    );
}

export default TokenItem;
