import React from 'react';
const NotFound = (props) => {
	return (
		<>
			<h3>NotFound</h3>
		</>
	);
};

export default NotFound;
