import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import SideBar from "layout/SideBar/SideBar";
import { useLocation } from "react-router-dom";

const MainContainer = styled.div`
    display: flex;
    min-height: calc(100vh - 56px);
    overflow: hidden;

    ${(props) =>
        props.padding === true &&
        css`
            //padding: 0 calc(100% / 6.274509803921569) 0 0;
        `}
`;

function Main({ children }) {
    const { pathname } = useLocation();
    const [padding, setPadding] = useState(false);

    useEffect(() => {
        if (pathname === "/" || pathname === "/join") {
            setPadding(false);
        } else {
            setPadding(true);
        }
    }, [pathname]);

    return (
        <MainContainer padding={padding}>
            {pathname === "/" || pathname === "/join" ? null : <SideBar />}
            {children}
        </MainContainer>
    );
}

export default Main;
