import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import SearchFilter from "common/components/SearchFilter"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {
  DateSearch,
  SearchConditionBox,
  SearchConditionDiv,
  SearchConditionText,
  SearchDateBox,
  SearchDateInput,
  SearchDateInputSpan,
  SearchDateMid,
  SearchDateTxt
} from "../userSurveySytle";
import {
  DATE_FILTER_OPTIONS,
  POINTMNG_REWARDMNG_STATE_LIST,
  REWARD_STATUS,
  STATUS,
  SURVEY_DETAIL_OPTIONS_VER2
} from '../../../common/constants/point';

const RewardMng = () => {
  const navigate = useNavigate();
  const INIT_OPTION = {
    BOARD_PARAMS: {
      offset: 3,
      limit: 10
    },
    KEY: '',
    DATE_FILTER_OPTION: 'ALL',
    // START_AT: '',
    // END_AT: ''
  }
  const request = useRequest("get", "point/reward/list");
  const [curPage, setCurPage] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const queryPage = searchParams.get('page')
  const queryName = searchParams.get('name')
  const queryStatus = searchParams.get('status')
  const queryStartAt = searchParams.get('startAt')
  const queryEndAt = searchParams.get('endAt')
  const queryType = searchParams.get('type') || ''
  const queryDateOption = searchParams.get('dateOption') || 'ALL'
  const [dateFilterOption, setDateFilterOption] = useState(queryDateOption ? queryDateOption : INIT_OPTION.DATE_FILTER_OPTION);
  const [formData, setFormData] = useState({
    offset: queryPage ? queryPage - 1 : 0,
    limit: 10,
    page: queryPage || 1,
    name: queryName || '',
    status: queryStatus ? queryStatus : '',
    startAt: queryStartAt ? queryStartAt : '',
    endAt: queryEndAt ? queryEndAt : '',
    type: queryType ? queryType : '',
  });
  const [totalNum, setTotalNum] = useState(0);
  const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
  const refStartAt = useRef(null);
  const refEndAt = useRef(null);
  const [_cStartAt, setCStartAt] = useState(null);
  const [_cEndAt, setCEndAt] = useState(null);


  const getSearchDate = () => {
    const now = dayjs(new Date());
    let date = {
      startAt: now,
      endAt: now
    };
    switch (dateFilterOption) {
      case '1M':
        date.startAt = now.subtract(1, 'month');
        break;
      case '3M':
        date.startAt = now.subtract(3, 'month');
        break;
      case '6M':
        date.startAt = now.subtract(6, 'month');
        break;
      case '1Y':
        date.startAt = now.subtract(1, 'year');
        break;
      case 'SELECT':
        date.startAt = refStartAt.current.value ? dayjs(refStartAt.current.value) : now;
        date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
        break;
      case 'ALL':
        date.startAt = null;
        date.endAt = null;
        break;
      default:
        date.startAt = now;
        date.endAt = now;
        break;
    }

    return {
      startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
      endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
    }

  }

  const {startAt, endAt} = getSearchDate();

  const {rows, fetch, params} = useBoard(request, (res) => {
    setTotalNum(res.totalCount);
    const {data} = res;
    return data && data.map((data) => ({
      ...data,
      status: STATUS[data.status],
      type: REWARD_STATUS[data.type],
      createdAt: data.createdAt && dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
      balance: data.balance && data.balance.toLocaleString(),
    }));
  }, {
    ...INIT_OPTION.BOARD_PARAMS,
    startAt,
    endAt
    // ...getSearchDate()
  });

  function updateSearchParams(key, value) {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }

  const handleSelectRow = (row) => {
    switch (row.type) {
      case'채널 설문' :
        return navigate(`/surveyMange/channelSurvey/detail/${row.id}?page=${curPage}`);
      case'회원 설문' :
        return navigate(`/surveyMange/userSurvey/detail/${row.id}/private?page=${curPage}`);
      case'베리랭킹' :
        return navigate(`/contents/lankDetail/${row.id}?page=${curPage}`);
      case '키워드 설문':
        return navigate(`/contents/platformDetail/${row.id}?page=${curPage}`);
      case'밸런스 게임' :
        return navigate(`/contents/platformDetail/${row.id}?page=${curPage}`);
      default:
        return
    }

  }

  // const handleChangeSearchKey = (value) => {
  //     setSearchKey(value);
  // }

  const handleSearch = (data) => {
    const {selectedOption} = data;
    const {type, name, limit,} = formData;
    updateSearchParams('status', selectedOption);
    updateSearchParams('type', type);
    updateSearchParams('name', name);
    updateSearchParams('startAt', startAt);
    updateSearchParams('endAt', endAt);
    updateSearchParams('dateOption', dateFilterOption);

    if (name || name === '') {
      updateSearchParams('page', '1')
      setCurPage(1)
      setFormData({...formData, offset: 0, page: 1})
    }

    if (selectedOption) {
      updateSearchParams('page', '1')
      setCurPage(1)
      setFormData({...formData, offset: 0, page: 1})
    }

    if (startAt !== undefined && endAt !== undefined) {
      setCurPage(1)

      setFormData({
        ...formData,
        page: 1,
        offset: 0,
        startAt: startAt,
        endAt: endAt,
      })
    }

    fetch({
      name: name || '',
      status: selectedOption || '',
      startAt: startAt || '',
      endAt: endAt || '',
      type: type || '',
      page: 1,
      offset: 0,
      limit
    });

  }

  const handleDateFilterSelect = (option) => {
    setDateFilterOption(option);
    const {startAt, endAt} = getSearchDate();
    refEndAt.current.value = endAt;

    if (option === 'SELECT') {
      refStartAt.current.value = dayjs().format(DATE.FORMAT.DASH);

    } else {
      refStartAt.current.value = startAt;
    }

    setFormData(prevState => ({...prevState, startAt: startAt, endAt: endAt, dateOption: option}))

  };

  const handleChangeStartAt = () => {
    const {startAt} = getSearchDate();
    refStartAt.current.value = startAt;
    setCStartAt(startAt);
  }

  const handleChangeEndAt = () => {
    const {endAt} = getSearchDate();
    refEndAt.current.value = endAt;
    setCEndAt(endAt);
  }

  const handlePage = (pageNum) => {
    updateSearchParams('page', pageNum)
    setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
    setCurPage(pageNum)
  };

  useEffect(() => {

    setFormData(prevState => ({
      ...prevState,
      page: Number(queryPage) || 1,
      name: queryName || '',
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      status: queryStatus || '',
      dateOption: queryDateOption || 'ALL'
    }))

    setDateFilterOption(queryDateOption)


    fetch({
      name: queryName || '',
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      status: queryStatus || '',
      limit: 10,
    })

  }, [queryPage, queryName, queryStartAt, queryEndAt, queryStatus]);

  return (
    <>
      <MenuArea>
        <MenuInfoArea>
          <Title btn>보상 상세내역</Title>
          <DateSearch>
            <SearchConditionBox>
              <SearchConditionText>조회 기간</SearchConditionText>
              <SearchConditionDiv>
                <SearchFilter options={DATE_FILTER_OPTIONS} option={formData.dateOption}
                              onSelect={handleDateFilterSelect} type="filter"/>
                <SearchDateBox>
                  <SearchDateInputSpan>
                    <SearchDateInput type="date"
                                     {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                     ref={refStartAt} onChange={() => handleChangeStartAt()}/>
                    <SearchDateTxt>{startAt || startAt === '' ? startAt : formData.startAt}</SearchDateTxt>
                  </SearchDateInputSpan>
                  <SearchDateMid>~</SearchDateMid>
                  <SearchDateInputSpan>
                    <SearchDateInput type="date"
                                     min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                     ref={refEndAt} onChange={() => handleChangeEndAt()}/>
                    <SearchDateTxt>{endAt || endAt === '' ? endAt : formData.endAt}</SearchDateTxt>
                  </SearchDateInputSpan>
                </SearchDateBox>
              </SearchConditionDiv>
            </SearchConditionBox>
          </DateSearch>
          <ListSearch options={SURVEY_DETAIL_OPTIONS_VER2}
                      initOption={queryStatus ? queryStatus : formData.status}
                      onSearch={handleSearch}
                      inputValue={formData.name}
                      onChange={(e) => setFormData(prevState => ({...formData, name: e.target.value}))}
          />
          <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={POINTMNG_REWARDMNG_STATE_LIST}
                 rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}/>
          <Page pageInfo={pageInfo} handlePage={handlePage}/>
        </MenuInfoArea>
      </MenuArea>
    </>
  )
}

export default RewardMng;
