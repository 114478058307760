import React, {useMemo} from 'react';

import {
    ArrowBtn,
    BotCate,
    BotCateLeft,
    BotCateList,
    BotCateRight,
    BotChaImg,
    BotChaLike,
    BotChannel,
    BotChaTxtGroup,
    BotChaTxtName,
    BotChaTxtSpan,
    BotChaTxtStrong,
    BotChaTxtSub,
    BotInfo,
    BotKeyLi,
    BotKeyUl,
    PollBtn,
    PollPreviewCloseButton,
    PollTxt,
    PopPollBox,
    PopUp,
    TopHead,
    TopImg,
    TopInfo,
    TopTxtGroup,
    TopTxtSub,
    TopTxtSubspan,
    TopTxtTit
} from "common/styledComponents/commonStyle";
import TestBgCheck01 from "asset/img/check-test-img01.png";
import BigArrowLeft from "asset/img/big_icon__keyboard_arrow_left.png";
import BigArrowRight from "asset/img/big_icon__keyboard_arrow_right.png";
import ThumImgBg from "asset/img/thum-img.png";
import IconClose from "asset/img/icon__close_bold.svg";


import PopupSurvey from './PopupServey';
import {UPLOAD_IMAGE_BASE_URL} from 'common/constants/env';

const PopupPreview = ({
                          whichPopup,
                          setWhichPopup,
                          questions,
                          thumbnails,
                          channelDetail,
                          title,
                          contents,
                          categoryId,
                          keywords,
                          startAt,
                          endAt,
                          isPaid,
                          categoryList,
                          ...props
                      }) => {

    const totalPopupNum = useMemo(() => (questions.length), [questions])

    const mainImg = useMemo(() => thumbnails.find((d) => d.isRepresentative), [thumbnails]);
    const dday = (new Date(startAt).getDate() - new Date(endAt).getDate()) * -1;

    const categoryType = categoryList.find(d => d.value === Number(categoryId));

    const moveLeft = () => {
        setWhichPopup(whichPopup - 1);
    }

    const moveRight = () => {
        setWhichPopup(whichPopup + 1)
    }

    const popupClose = () => {
        setWhichPopup(false)
    }

    return (
        <>
            {/* whichPopup, setWhichPopup */}
            <PopUp dark hidden={whichPopup !== 0}>
                <ArrowBtn Left disabled={whichPopup === 0} onClick={moveLeft} type='button'>
                    <img src={BigArrowLeft} alt=""/>
                </ArrowBtn>
                <ArrowBtn Right onClick={moveRight} disabled={whichPopup === questions.length} type='button'>
                    <img src={BigArrowRight} alt=""/>
                </ArrowBtn>
                <PopPollBox>
                    {/* 여기서부터 다른 코드 */}
                    <TopImg>
                        <img
                            // src={(mainImg && mainImg.previewImage) || (UPLOAD_IMAGE_BASE_URL + channelDetail?.thumbnailImageUrl) || ThumImgBg}
                            src={(mainImg && mainImg.previewImage) || ThumImgBg}
                            alt=''/>
                    </TopImg>
                    <TopInfo height='300px'>
                        <TopHead>
                            <button type='button' style={{"cursor": "pointer"}} onClick={popupClose}>뒤로가기</button>
                            설문 상세
                        </TopHead>
                        <TopTxtGroup>
                            <TopTxtTit>{title || "설문 제목을 입력해 주세요."}</TopTxtTit>
                            <TopTxtSub>
                                <TopTxtSubspan blue>참여가능</TopTxtSubspan>
                                <TopTxtSubspan>D-{dday}</TopTxtSubspan>
                                <TopTxtSubspan icon>20</TopTxtSubspan>
                            </TopTxtSub>
                        </TopTxtGroup>
                    </TopInfo>
                    <BotInfo>
                        <BotChannel>
                            <BotChaImg>
                                <img src={UPLOAD_IMAGE_BASE_URL + channelDetail?.profileImageUrl || TestBgCheck01}
                                     alt=''/>
                            </BotChaImg>
                            <BotChaTxtGroup>
                                <BotChaTxtName>{channelDetail?.name || "채널명"}</BotChaTxtName>
                                <BotChaLike>♥</BotChaLike>
                                <BotChaTxtSub>
                                    <BotChaTxtSpan>진행중 설문<BotChaTxtStrong>12</BotChaTxtStrong></BotChaTxtSpan>
                                    <BotChaTxtSpan>종아요<BotChaTxtStrong>12,345</BotChaTxtStrong></BotChaTxtSpan>
                                </BotChaTxtSub>
                            </BotChaTxtGroup>
                        </BotChannel>
                        <BotCate>
                            <BotCateList>
                                <BotCateLeft>카테고리</BotCateLeft>
                                <BotCateRight>{categoryType && categoryType.label}</BotCateRight>
                            </BotCateList>
                            <BotCateList>
                                <BotCateLeft>참여인원</BotCateLeft>
                                <BotCateRight>2,301</BotCateRight>
                            </BotCateList>
                            <BotKeyUl>
                                {keywords.map((d, idx) => (<BotKeyLi key={idx}>{d.name}</BotKeyLi>))}
                                {/* <BotKeyLi>키워드에따라</BotKeyLi> */}
                            </BotKeyUl>
                        </BotCate>
                        <PollTxt>
                            {/* <PollTxtarea value={contents || "설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. v 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요.  설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요.  설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요.  설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요.  설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요. 설문 내용을 입력해 주세요."} readOnly></PollTxtarea> */}
                            {contents || "설문 내용을 입력해 주세요."}
                        </PollTxt>
                        <PollBtn>
                            설문 참여하기
                        </PollBtn>
                    </BotInfo>
                    <PollPreviewCloseButton onClick={popupClose}>
                        미리보기 닫기 <img src={IconClose} alt='IconClose'/>
                    </PollPreviewCloseButton>
                </PopPollBox>
            </PopUp>

            {questions.map((d, idx) => {

                return (
                    <PopupSurvey key={idx} {...{
                        order: idx + 1,
                        whichPopup,
                        moveLeft,
                        moveRight,
                        popupClose,
                        data: d
                    }}
                                 questionsLength={questions.length}
                    />
                )
            })}

            {/* <PopupServey {...{order:1, whichPopup, moveLeft, moveRight, popupClose}}/> */}

            {/*{*/}
            {/*    totalPopupNum > 0 && (*/}
            {/*        <PopUp dark hidden={whichPopup !== totalPopupNum + 1}>*/}
            {/*            <ArrowBtn Left onClick={moveLeft}><img src={BigArrowLeft} alt=""/></ArrowBtn>*/}
            {/*            <ArrowBtn Right disabled={whichPopup === totalPopupNum + 1} onClick={moveRight}><img*/}
            {/*                src={BigArrowRight} alt=""/></ArrowBtn>*/}
            {/*            <PopPollBox>*/}
            {/*                <TopInfo>*/}
            {/*                    <TopHead xButton>*/}
            {/*                        <button type='button' style={{"cursor": "pointer"}} onClick={popupClose}>닫기</button>*/}
            {/*                        설문 상세*/}
            {/*                    </TopHead>*/}
            {/*                    <PollQbox>*/}
            {/*                        <PollQTitle>모든 설문이 완료되었어요.<br/> 전체 응답결과를 확인하세요!</PollQTitle>*/}
            {/*                        <PollQNoti>총 참여인원 {`${322}`}</PollQNoti>*/}
            {/*                    </PollQbox>*/}
            {/*                </TopInfo>*/}
            {/*            </PopPollBox>*/}
            {/*        </PopUp>*/}
            {/*    )*/}
            {/*}*/}

        </>
    );
};

export default PopupPreview;
