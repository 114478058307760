import React, {useEffect, useMemo, useState} from 'react';
import {Button, MenuArea, MenuInfoArea, Title, Wrapper} from "common/styledComponents/commonStyle";
import BigArrowRight from "asset/img/big_icon__keyboard_arrow_right.png";
import useRequest from '../../../hooks/useRequest';
import {API_STATE} from '../../../common/constants/state';
import {
    ArrowWrapper,
    DownArrowWrapper,
    KeyBoxA,
    KeyBoxHead,
    KeyBoxIn,
    KeyBoxKeyLi,
    KeyBoxUl,
    KeyCont,
    KeyTxt,
    UpArrowWrapper
} from '../KeywordManage/KeywordMangeStyle';
import storageService from '../../../util/storage';
import axios from 'axios';

function RankingViewManagement(props) {
    const rankingViewListRequest = useRequest("get", "category/ranking/view/list");
    const [rankingId, setRankingId] = useState(null)
    const patchCategoryRankingViewUpdateRequest = useRequest("patch", "category/ranking/view/update");
    const patchRankingCategoryViewUpdateRequest = useRequest("patch", `ranking/category/${rankingId}/view/update`);
    const [rankingCategoryList, setRankingCategoryList] = useState([])
    const [rankingList, setRankingList] = useState([])

    const onSubmit = (e) => {

        const submitRankingCategory = rankingCategoryList.map((category, index) =>
            ({id: category.id, sequence: rankingCategoryList.length - index})
        )

        patchCategoryRankingViewUpdateRequest.call({categories: submitRankingCategory}, 'body')


        if (rankingList && rankingList.length > 0) {
            const submitRankings = rankingList.map((category, index) =>
                ({id: category.id, sequence: rankingList.length - index})
            )

            patchRankingCategoryViewUpdateRequest.call({rankings: submitRankings}, 'body')
        }
    }

    const checkRankingItem = (id) => {
        setRankingList(prevState => prevState.map(value =>
            value.id === id ? {
                ...value,
                checked: true
            } : {...value, checked: false}))
    }

    const onMouseCategory = (id) => {
        setRankingCategoryList(prevState => prevState.map(value =>
            value.id === id ? {
                ...value,
                checked: true
            } : {...value, checked: false}))
    }

    const rankingCategorySequenceUp = (e, sequenceIndex) => {
        e.stopPropagation()
        setRankingList([])

        if (sequenceIndex === 0) {
            return
        }

        setRankingCategoryList(prevState => prevState.map((value, index, arr) => {
            if (index === sequenceIndex) {
                return {...arr[sequenceIndex - 1], checked: false,}
            } else if (index === sequenceIndex - 1) {
                return {...arr[sequenceIndex], checked: false,}
            } else {
                return {...value, checked: false}
            }
        }))

    }

    const rankingCategorySequenceDown = (e, sequenceIndex) => {
        e.stopPropagation()
        setRankingList([])

        if (sequenceIndex + 1 === rankingCategoryList.length || sequenceIndex + 1 === rankingList.length) {
            return
        }

        setRankingCategoryList(prevState => prevState.map((value, index, arr) => {
            if (index === sequenceIndex) {
                return {...arr[sequenceIndex + 1], checked: false}
            } else if (index === sequenceIndex + 1) {
                return {...arr[sequenceIndex], checked: false}
            } else {
                return {...value, checked: false}
            }
        }))
    }

    const rankingSequenceUp = (e, sequenceIndex) => {
        e.stopPropagation()

        if (sequenceIndex === 0) {
            return
        }

        setRankingList(prevState => prevState.map((value, index, arr) => {
            if (index === sequenceIndex) {
                return {...arr[sequenceIndex - 1], checked: false,}
            } else if (index === sequenceIndex - 1) {
                return {...arr[sequenceIndex], checked: false,}
            } else {
                return {...value, checked: false}
            }
        }))
    }

    const rankingSequenceDown = (e, sequenceIndex) => {
        e.stopPropagation()

        if (sequenceIndex + 1 === rankingCategoryList.length || sequenceIndex + 1 === rankingList.length) {
            return
        }


        setRankingList(prevState => prevState.map((value, index, arr) => {
            if (index === sequenceIndex) {
                return {...arr[sequenceIndex + 1], checked: false, sequence: value.sequence - 1}
            } else if (index === sequenceIndex + 1) {
                return {...arr[sequenceIndex], checked: false,}
            } else {
                return {...value, checked: false}
            }
        }))

    }


    const onLoadCategoryRanking = useMemo(() => async (id) => {
        const token = storageService.get("auth", "accessToken", "local");
        setRankingId(id)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/ranking/category/${id}/view/list`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            setRankingList(response.data.response.rankings)

        } catch (e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        switch (rankingViewListRequest.state) {
            case API_STATE.done:
                if (rankingViewListRequest.response.success) {
                    const {response} = rankingViewListRequest.response;
                    setRankingCategoryList(response.categories)
                } else {
                    alert('실패')
                }
                break;
            default:
                break;
        }
    }, [rankingViewListRequest.state, rankingViewListRequest.error, rankingViewListRequest.response]);

    useEffect(() => {
        switch (patchCategoryRankingViewUpdateRequest.state) {
            case API_STATE.done:
                if (patchCategoryRankingViewUpdateRequest.response.success) {
                    alert('랭킹 카테고리 순위 적용되었습니다.')
                    rankingViewListRequest.call()
                } else {
                    alert('변경 실패')
                }
                break;
            default:
                break;
        }
    }, [patchCategoryRankingViewUpdateRequest.state, patchCategoryRankingViewUpdateRequest.error, patchCategoryRankingViewUpdateRequest.response]);

    useEffect(() => {
        switch (patchRankingCategoryViewUpdateRequest.state) {
            case API_STATE.done:
                if (patchRankingCategoryViewUpdateRequest.response.success) {
                    alert('랭킹 순위 적용되었습니다.')
                    rankingViewListRequest.call()
                } else {
                    alert('변경 실패')
                }
                break;
            default:
                break;
        }
    }, [patchRankingCategoryViewUpdateRequest.state, patchRankingCategoryViewUpdateRequest.error, patchRankingCategoryViewUpdateRequest.response]);


    useEffect(() => {
        rankingViewListRequest.call()
    }, []);

    console.log(rankingList, 'rankingList')

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>랭킹 노출 관리</Title>
                    <Wrapper Row>
                        <Wrapper maxWidth='380px'>
                            <KeyCont Ranking>
                                <KeyBoxHead>랭킹 카테고리 명</KeyBoxHead>
                                <KeyBoxIn Ranking>
                                    <KeyBoxUl Ranking>
                                        {rankingCategoryList && rankingCategoryList.map((ranking, index) =>
                                            <KeyBoxKeyLi Ranking
                                                         key={index}
                                                         id={ranking.id}
                                                         onClick={() => onLoadCategoryRanking(ranking.id)}
                                                         onMouseOver={(e) => onMouseCategory(ranking.id)}
                                            >
                                                <KeyBoxA>
                                                    <KeyTxt>{ranking.name}</KeyTxt>
                                                </KeyBoxA>
                                                {ranking.checked &&
                                                    <Wrapper width='80px' Center Row>
                                                        <UpArrowWrapper
                                                            onClick={(e) => rankingCategorySequenceUp(e, index)}
                                                        />
                                                        <DownArrowWrapper
                                                            onClick={(e) => rankingCategorySequenceDown(e, index)}
                                                        />
                                                        <ArrowWrapper>
                                                            <img src={BigArrowRight} alt="rightArrow"/>
                                                        </ArrowWrapper>
                                                    </Wrapper>
                                                }
                                            </KeyBoxKeyLi>
                                        )}
                                    </KeyBoxUl>
                                </KeyBoxIn>
                            </KeyCont>
                        </Wrapper>
                        <Wrapper maxWidth='780px'>
                            <KeyCont Ranking>
                                <KeyBoxHead>랭킹 제목</KeyBoxHead>
                                <KeyBoxIn Ranking>
                                    <KeyBoxUl Ranking>
                                        {rankingList ? rankingList.map((ranking, index) =>
                                            <KeyBoxKeyLi Ranking
                                                         key={index}
                                                         id={Number(index)}
                                                         onMouseOver={() => checkRankingItem(ranking.id)}
                                            >
                                                <KeyBoxA>
                                                    <KeyTxt>{ranking.title}</KeyTxt>
                                                </KeyBoxA>
                                                {ranking.checked &&
                                                    <Wrapper width='52px' Center Row>
                                                        <UpArrowWrapper onClick={(e) => rankingSequenceUp(e, index)}/>
                                                        <DownArrowWrapper
                                                            onClick={(e) => rankingSequenceDown(e, index)}/>
                                                    </Wrapper>
                                                }
                                            </KeyBoxKeyLi>
                                        ) : <span style={{margin: 'auto'}}>데이터가 없습니다.</span>}
                                    </KeyBoxUl>
                                </KeyBoxIn>
                            </KeyCont>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper>
                        <Button mini margin='0 0 0 auto' type='button' onClick={onSubmit}>적용하기</Button>
                    </Wrapper>
                </MenuInfoArea>
            </MenuArea>
        </>
    );
}

export default RankingViewManagement;
