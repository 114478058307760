import React, {useEffect, useRef, useState} from "react";
import useRequest from "hooks/useRequest";
import {useNavigate} from "react-router-dom";
import {
    BackBtn,
    PollSettingContentContainer,
    PollSettingContentSection,
    PollSettingContentWrapper,
    PollSettingMainContainer,
    PollSettingTitleContainer
} from "../../../common/styledComponents/commonStyle";
import {Container, CustomButton, Wrapper,} from "./PuzzleTokenManagementStyle";
import {API_STATE} from "common/constants/state";
import TokenOption from "./TokenOption.js";
import TokenItem from "./TokenItem.js";

function PuzzleTokenItemManagement(props) {
    const navigate = useNavigate();
    const tokenInfoRequest = useRequest("get", "token/puzzle/info");
    const tokenUpdateRequest = useRequest("put", "token/puzzle/info/update");
    const [currentTokenTitle, setCurrentTokenTitle] = useState('');
    const [tokenItems, setTokenItems] = useState([]);
    const [options, setOptions] = useState([]);
    const itemOptionRef = useRef(null);

    const handleSelectTitle = (title) => {
        setCurrentTokenTitle(title);
        itemOptionRef.current.value = null;
    }

    const handleAddTitle = (title) => {
        setTokenItems(() => [
            ...tokenItems,
            {
                title
            }
        ]);
        setCurrentTokenTitle(title);
    }

    const handleEditTitle = (titleData) => {
        const newTokenItems = tokenItems.map(d => d.title === currentTokenTitle ? {...d, title: titleData} : d);
        setTokenItems(newTokenItems);
    }

    const handleRemoveTitle = (title) => {
        const items = tokenItems.filter(tokenItem => {
            return tokenItem.title !== title
        });
        setTokenItems(items);
        setCurrentTokenTitle('');

    }

    const setToCurrentOption = (newOptions) => {
        setTokenItems(tokenItems.map((tokenItem) => {
            const options = tokenItem.options || [];
            if (tokenItem.title !== currentTokenTitle) {
                return {
                    ...tokenItem,
                    options: [...options]
                }
            }
            return {
                ...tokenItem,
                options: newOptions
            }
        }));
    }

    const handleAddOption = (newOptions) => {
        if (!currentTokenTitle) {
            return alert('제목을 선택해주세요.');
        }
        setToCurrentOption(newOptions);
    }

    const handleRemoveOption = (newOptions) => {
        setToCurrentOption(newOptions);
    }


    const handleClickUpdateBtn = () => {
        // debugger;
        tokenUpdateRequest.call([...tokenItems]);
    }

    const handleClick = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    useEffect(() => {
        tokenInfoRequest.call()
    }, []);

    useEffect(() => {
        switch (tokenInfoRequest.state) {
            case API_STATE.done:
                if (tokenInfoRequest.response.success) {
                    if (!tokenInfoRequest.response.response) {
                        return
                    }
                    const {response} = tokenInfoRequest.response;
                    const tokenItems = [
                        ...response
                    ];
                    const initTitle = tokenItems && tokenItems[0].title;
                    // console.log("tokenItems > ", tokenItems)
                    setTokenItems(tokenItems);
                    setCurrentTokenTitle(initTitle);
                }

                break;
            default:
                break;
        }
    }, [tokenInfoRequest.state, tokenInfoRequest.error, tokenInfoRequest.response]);

    useEffect(() => {
        const targetToken = tokenItems.find((tokenItem) => tokenItem.title === currentTokenTitle) || {};
        const options = targetToken.options ? [...targetToken.options] : [];
        setOptions(options);

    }, [currentTokenTitle, tokenItems]);

    useEffect(() => {
        switch (tokenUpdateRequest.state) {
            case API_STATE.done:
                if (tokenUpdateRequest.response.success) {
                    alert("항목이 저장 되었습니다.")
                }

                if (!tokenUpdateRequest.response.success) {
                    alert("실패했습니다.")
                    tokenInfoRequest.call()
                }

                break;
            default:
                break;
        }
    }, [tokenUpdateRequest.state, tokenUpdateRequest.error, tokenUpdateRequest.response]);

    return (
        <Container>
            <Wrapper Center Column width="1180px">
                <PollSettingTitleContainer width="100%">
                    <BackBtn onClick={handleClick}/>
                    <span>퍼즐토큰 항목관리</span>
                </PollSettingTitleContainer>
                <PollSettingMainContainer>
                    {/* <form> */}
                    <PollSettingContentContainer>
                        <PollSettingContentSection>
                            {/* <PollSettingContentWrapper Padding>
                             <Content>
                             <AddFileWrapper width="380px" margin="0 8px 0 24px">
                             <p>test</p>
                             <input
                             type="file"
                             id="test"
                             // key={keywordItem.id}
                             name="test"
                             accept="image/*"
                             />
                             <label htmlFor="test">파일 첨부</label>
                             <span>*안내 메세지 / 오류 메세지</span>
                             </AddFileWrapper>
                             </Content>
                             </PollSettingContentWrapper> */}
                            <PollSettingContentWrapper Padding>
                                <TokenItem currentTokenTitle={currentTokenTitle} tokenItems={tokenItems}
                                           onSelect={handleSelectTitle} onAdd={handleAddTitle}
                                           onRemove={handleRemoveTitle} onEdit={handleEditTitle}
                                />
                                <TokenOption options={options} onAdd={handleAddOption} onRemove={handleRemoveOption}
                                             optionRef={itemOptionRef}/>
                            </PollSettingContentWrapper>
                        </PollSettingContentSection>
                        <Wrapper Right>
                            <CustomButton Preview type="button">
                                모바일 미리보기
                            </CustomButton>
                            <CustomButton mini type="button" onClick={handleClickUpdateBtn}>
                                수정사항 저장하기
                            </CustomButton>
                        </Wrapper>
                    </PollSettingContentContainer>
                    {/* </form> */}
                </PollSettingMainContainer>
            </Wrapper>
        </Container>
    );
}

export default PuzzleTokenItemManagement;
