import styled, {css} from 'styled-components';


export const DateSearch = styled.div`
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: -24px;
    padding: 24px 0;

`;
export const SearchConditionBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    & + & {
        margin-top: 24px;
    }
`;

export const SearchConditionText = styled.span`
    margin-right: 24px;
    font-size: 14px;
    float: left;

    ${(props) =>
            props.top &&
            css`
                line-height: 36px;
            `}
`;
export const SearchConditionDiv = styled.div`
    display: flex;
    align-items: center;
    //position: relative;
    //display: inline-block;
    margin-left: 24px;
`;

export const SearchDateBox = styled.div`
    display: flex;
    //display: inline-block;
    //float: right;
    //margin-top: -6px;
    //margin: -6px 0 0 24px;
    margin-left: 24px;
`;

export const SearchDateInputSpan = styled.div`
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    background: #f5f5f5;
    width: 86px;
    height: 36px;
    box-sizing: border-box;
    //display: inline-block;
    //overflow: hidden;
    padding: 10px 0;
    //float: left;
    position: relative;
    display: flex;
    align-items: center;

    input[type="date"] {
        background: #f5f5f5;
        width: 100%;
        font-size: 14px;

    }
`;
export const SearchDateTxt = styled.span`
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    font-size: 14px;
    z-index: 0;
`;
export const SearchDateInput = styled.input`
    display: inline-block;
    float: right;
    border-radius: 8px;
`;

export const SearchDateMid = styled.span`
    display: inline-block;
    padding: 0 12px;
    //float: left;
    line-height: 36px;
`;


export const ListInputBerry = styled.input`
    width: 180px;
    height: 36px;
    border: 1px solid #757575;
    border-radius: 8px;
    padding: 8px 50px 8px 8px;
    margin-left: 16px;

    &:disabled {
        background-color: #f5f5f5;
        color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
    }

    &::placeholder {
        color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
    }
`;

export const ListInputTxtCount = styled.span`
    position: absolute;
    right: 0;
    bottom: -17px;
    font-size: 10px;
    color: #757575;
    ${(props) =>
            props.in &&
            css`
                bottom: 11px;
                right: 8px;
            `}

    ${(props) =>
            props.In &&
            css`
                bottom: 11px;
                right: 8px;
            `}
`;


export const KeyWorldUl = styled.ul`
    margin-top: 5px;

    ${(props) =>
            props.Keyword &&
            css`
                margin-top: 0;
            `}
`;

export const KeyWorldList = styled.li`
    position: relative;
    display: inline-block;

    & + & {
        margin-left: 40px;
    }
`;

export const KeywordLengthLimitNotice = styled.span`
    font-size: 10px;
    color: #757575;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
`;

export const KeywordWrapper = styled.div`
    position: relative;
    display: inline-block;
    align-items: center;
`;


export const BarBox = styled.span`
    width: 100%;
    max-width: 980px;
    height: 40px;
    background-color: #dbdbdb;
    display: inline-block;
    ${(props) =>
            props.img &&
            css`
                max-width: 816px;
            `}

`;

export const BarGraph = styled.span`
    // width: 60%;
    height: 100%;
    background-color: #757575;
    display: inline-block;
    ${(props) =>
            props.first &&
            css`
                background-color: #000065;
            `}
`;
export const BarNumber = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
`;
export const BarPercent = styled.span`
    display: block;
    text-align: center;
    font-size: 24px;
`;
export const BarPerson = styled.span`
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: 8px;
`;
