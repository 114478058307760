import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router";

import Step3 from "asset/img/step3.png"
import Check from "asset/img/icon__check_circle.png"

import {Center} from "common/styledComponents/commonStyle";
import {WalletCont , WalletTop , WalletStep , WalletStepThree , WalletStepImg , WalletBoxWide , WalletTxt , Mnemonic , InputDiv , Input , EyeIcon , ErrorMsg, BlueButton , DisableButton, MnemonicKeyCont, MnemonicKeyUl, MnemonicKeyLi, MnemonicKeyTxt, MnemonicKeyInput, MnemonicButtonGroup, MnemonicButtonLong, MnemonicButtonShort, MnemonicKeyErrorMsg, PopCont, Dim, PopArea, PopIcon, InfoTxt, ConfirmButton} from "pages/Join/JoinStyle"


const CrtWalletThree = ({setCurrentStep}) => {
    const navigate = useNavigate();
    const [showCompletePopup, setShowCompletePopup] = useState(false);
    const [nemonicKey, setNemonicKey] = useState("");
    
    const handlePasteNemonicKey = () => {
        navigator.clipboard
            .readText()
            .then((cliptext) => {
                setNemonicKey(cliptext);
            });
    }

    const handleClickOk = () => {
        navigate("/");
    }

    return(
        <>
            <Center>
                <WalletCont>
                    <WalletTop>Welcome!</WalletTop>
                    <WalletStep>
                        <WalletStepThree>Step</WalletStepThree>
                        <WalletStepImg><img src={Step3} alt=""></img></WalletStepImg>
                    </WalletStep>
                    <WalletBoxWide>
                        <WalletTxt>
                            [채널명] 회원님,<br></br> 
                            저장한 니모닉키의 유효성을 확인해주세요.                            
                        </WalletTxt>
                        <MnemonicKeyCont>
                            <MnemonicKeyUl>
                                {
                                    nemonicKey 
                                    ? 
                                        nemonicKey.split(',').map((key) => {
                                            return(
                                                <MnemonicKeyLi>
                                                    <MnemonicKeyTxt>{key}</MnemonicKeyTxt>
                                                </MnemonicKeyLi>
                                            )
                                        })
                                    :
                                        <MnemonicKeyLi>
                                            <MnemonicKeyTxt>
                                                <MnemonicKeyInput type="text" placeholder="입력" readOnly></MnemonicKeyInput>
                                            </MnemonicKeyTxt>
                                        </MnemonicKeyLi>
                                }
                            </MnemonicKeyUl>
                            {/* <MnemonicKeyErrorMsg>오류메세지</MnemonicKeyErrorMsg> */}
                        </MnemonicKeyCont>
                        <MnemonicButtonGroup>
                            <MnemonicButtonLong onClick={handlePasteNemonicKey}>니모닉키 붙여넣기</MnemonicButtonLong>
                            <MnemonicButtonShort onClick={()=>setShowCompletePopup(true)}>확인</MnemonicButtonShort>
                        </MnemonicButtonGroup>
                    </WalletBoxWide>
                </WalletCont>                
            </Center>
            {
                showCompletePopup && 
                <PopCont>
                    <Dim></Dim>
                    <PopArea>
                        <PopIcon><img src={Check} alt=""></img></PopIcon>
                        <InfoTxt>디지털 지갑 생성이 완료되었습니다.</InfoTxt>
                        <InfoTxt>로그인 후 [ 채널명 ] 회원님의 채널을 생성하여 투명하고 안전한 Poll을 만드세요.</InfoTxt>
                        <ConfirmButton onClick={handleClickOk}>확인</ConfirmButton>
                    </PopArea>
                </PopCont>
            }
        </>
    )
}

export default CrtWalletThree;