import React from 'react';
import {Wrapper} from '../../../common/styledComponents/commonStyle';
import {
    CloseIconButton,
    IconClose,
    PostModalContainer,
    PreviewMobileContainer,
    PreviewMobileContentBarWrapper,
    PreviewMobileContentTitle,
    PreviewMobileContentWrapper,
    PreviewMobileTime,
    PreviewMobileTitle,
    PreviewMobileTitleWrapper,
    PreviewMobileWrapper,
    StyledPostModal
} from './StyledPostModal';
import Battery from 'asset/img/icon__battery_std.png';
import Wifi from 'asset/img/icon__signal_wifi_bar.png';
import Signal from 'asset/img/icon__signal_cellular_bar.png';
import TopBarBackBlack from 'asset/img/icon__topbar_back_black.svg';
import IconMobileArrowBlack from 'asset/img/icon__mobile_arrow_black.svg';
import CloseBold from 'asset/img/icon__close_bold.svg';
import dayjs from 'dayjs';
import DATE from '../../../common/constants/date';
import parse from 'html-react-parser'

function PostModal({...props}) {
    const today = dayjs().format(DATE.FORMAT.DASH);
    const {title, contents, closeModal, type, createdAt} = props;
    const customCreatedAt = dayjs(new Date(createdAt)).format(DATE.FORMAT.DOT)


    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    function removeHTMLTags(textWithHTML) {
        return textWithHTML.replace(/<\/?[^>]+(>|$)/g, "");
    }


    console.log(typeof contents === 'string', 'contents')
    console.log(parse(contents), 'parse(contents)')

    return (
        <StyledPostModal onClick={handleOutsideClick}>
            <PostModalContainer Type={type === 'tos'}>
                <PreviewMobileContainer>
                    <PreviewMobileWrapper>
                        <img src={Wifi} alt='wifir'/>
                    </PreviewMobileWrapper>
                    <PreviewMobileWrapper>
                        <img src={Battery} alt='battery'/>
                    </PreviewMobileWrapper>
                    <PreviewMobileWrapper>
                        <img src={Signal} alt='signal'/>
                    </PreviewMobileWrapper>
                    <PreviewMobileWrapper>
                        <PreviewMobileTime>11:11</PreviewMobileTime>
                    </PreviewMobileWrapper>
                </PreviewMobileContainer>
                <PreviewMobileTitleWrapper>
                    <img src={TopBarBackBlack} alt='TopBarBackBlack'/>
                    <PreviewMobileTitle>{type === 'notice' ? '공지사항' : 'FAQ'}</PreviewMobileTitle>
                </PreviewMobileTitleWrapper>
                <PreviewMobileContentBarWrapper Row>
                    <Wrapper Column>
                        {/*<MobilePreviewBadge>필독</MobilePreviewBadge>*/}
                        <PreviewMobileContentTitle>{title ? title : 'Title'}</PreviewMobileContentTitle>
                        <PreviewMobileContentTitle date>{customCreatedAt}</PreviewMobileContentTitle>
                    </Wrapper>
                    <img src={IconMobileArrowBlack} alt='IconMobileArrowBlack'/>
                </PreviewMobileContentBarWrapper>
                <PreviewMobileContentWrapper padding='20px' Type={type === 'tos'}>
                    <div style={{width: '100%'}}>
                        {contents ? parse(contents) : 'Content'}
                    </div>
                </PreviewMobileContentWrapper>
                {type !== 'tos' &&
                    <>
                        <PreviewMobileContentBarWrapper Row border>
                            <Wrapper Column>
                                <PreviewMobileContentTitle>{type === 'notice' ? '공지사항' : 'FAQ'}</PreviewMobileContentTitle>
                                <PreviewMobileContentTitle date>{today}</PreviewMobileContentTitle>
                            </Wrapper>
                            <img src={IconMobileArrowBlack} alt='IconMobileArrowBlack'/>
                        </PreviewMobileContentBarWrapper>
                        <PreviewMobileContentBarWrapper Row border>
                            <Wrapper Column>
                                <PreviewMobileContentTitle>{type === 'notice' ? '공지사항' : 'FAQ'} 3</PreviewMobileContentTitle>
                                <PreviewMobileContentTitle date>{today}</PreviewMobileContentTitle>
                            </Wrapper>
                            <img src={IconMobileArrowBlack} alt='IconMobileArrowBlack'/>
                        </PreviewMobileContentBarWrapper>
                    </>
                }
            </PostModalContainer>
            <CloseIconButton type='button' onClick={closeModal}>
                <span>미리보기 닫기</span>
                <IconClose src={CloseBold} alt='iconClose'/>
            </CloseIconButton>
        </StyledPostModal>
    );
}

export default PostModal;
