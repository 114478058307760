import styled, { css } from 'styled-components';

import ResetIcon from "asset/img/icon__restart_alt.png";



export const DashMain = styled.div`
    margin:-24px auto -60px;
`;
export const DashMainL = styled.div`
    float: left;
`;
export const DashMainR = styled.div`
    display: inline-block;
    background-color: #000032;
    border-radius: 12px;
    box-sizing: border-box;
    width: 380px;
    height: 364px;
    margin-left: 20px;    
    padding: 0 20px 25px 20px;
`;
export const DashTop = styled.div`
`;
export const DashUl = styled.ul`
    display: flex;
    li{        
        flex: 1;
    }
`;
export const DashBoxLi = styled.li`
    width: 244px;
    height: 172px;
    box-sizing: border-box;
    padding: 38px 0 40px;
    background-color: #fff;
    border-radius: 12px;
    float: left;
    text-align: center;
    position: relative;
    &+&{
        margin-left: 24px;
    }
`;
export const ResetBtn = styled.a`
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 0;
    background-image: url(${ResetIcon});
    width: 24px;
    height: 24px;  
`;
export const DashNum = styled.p`
    font-size: 36px;
    font-weight: 700;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 220px;
    margin: 0 auto 20px;
`;
export const DashTxt = styled.p`
    color: #424242;
`;
export const DashBottom = styled.div`
    position: relative;
    width: 780px;
    height: 172px;
    box-sizing: border-box;
    padding: 38px 12px 40px;
    background-color: #fff;
    border-radius: 12px;
    margin: 20px 0 26px;
`;
export const DashLi = styled.li`
    float: left;
    text-align: center;
    &+&{
        margin-left: 48px;
    }

`;
export const DashProfile = styled.div`
    padding: 40px 0;
    margin-bottom: 25px;
    border-bottom: 1px solid #757575;
    text-align: center;
    box-sizing: border-box;
`;
export const ProfileIcon = styled.p`
    color: #f5f5f5;
    height: 66px;
    line-height: 66px;
    margin-bottom: 16px;
`;
export const ProfileIconImg = styled.img`
    border-radius: 50%;
    width: 66px;
    height: 66px;;
    overflow: hidden;
`;
export const ProfileTxt = styled.p`
    color: #fff;
    &+&{        
        margin-top: 12px;
    }
`;
export const DashProfileUl = styled.ul`
    color: #fff;
`;
export const DashProfileLi = styled.li`
`;
export const DashInfoTxt = styled.span`
    width: 120px;
    height: 24px;
    overflow: hidden;
    display: inline-block;
`;
export const DashInfoNum = styled.span`
    max-width: 120px;
    font-size: 20px;
    font-weight: 700;
    margin: 0 12px 0 56px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
`;
export const DashInfoBerry = styled.span`
    font-size: 12px;
    vertical-align: text-top;
`;


export const PlatDashProfile = styled.div`
    padding: 28px 0 0;
    box-sizing: border-box;
`;

export const PlatProfileTxt = styled.p`
    border-bottom: 1px solid #757575;
    padding:0 4px 28px;
    font-size:30px;
    font-weight:700;
    color:#fff;
`;
export const PlatProfileNum = styled.p`
    color:#fff;
    font-size:48px;
    font-weight:700;
    margin: 108px 0;
    height: 48px;
    line-height: 48px;
    text-align: center;
    span{
        margin-left:16px;
        font-size:16px;
        font-weight:400;
        vertical-align: baseline;
    }
`;

export const BoxList = styled.div`
    //margin: -12px -10px;
`;
export const ApiBox = styled.div`
    width: 580px;
    height: 315px;
    border-radius: 12px;
    background-color: #fff;
    display: inline-table;
    //margin: 12px 10px;
    box-sizing: border-box;
    margin: 12px 0;
    overflow:hidden;

  & + & {
    margin-left: 20px;
  }

`;
export const BoardBox = styled.div`
    width: 580px;
    height: 252px;
    border-radius: 12px;
    background-color: #fff;
    display: inline-table;
    //margin: 12px 10px;
    padding: 20px 16px;
    box-sizing: border-box;
  margin: 12px 0;
  
  & + & {
    margin-left: 20px;
  }
`;
export const BoardHeadtxt = styled.p`
    position: relative;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    
    ${(props) =>
        props.graph &&
        css`            
        margin: 20px 16px -50px;
        z-index: 5;
        `} 
`;
export const MoreBtn = styled.a`
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 14px;
    color: #757575;
`;
export const BoardBoxList = styled.div`
`;
export const BoardBoxListUl = styled.ul`
`;
export const BoardBoxListLi = styled.li`
    &+&{
        margin-top: 9px;
    }
    span{        
        display: inline-block;
        vertical-align: middle;
    }
    span+span{
        margin-left: 8px;
    }
`;
export const BoardBoxListTag = styled.span`
    width: 53px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;  
    ${(props) =>
        props.red &&
        css`            
            color: #E92928;
            background-color: #fce1e1;  
        `} 
    ${(props) =>
        props.blue &&
        css`            
            color: #F8FBFF;
            background-color: #3189FA;
        `} 
    ${(props) =>
        props.gray &&
        css`            
            background-color: #DBDBDB;
            color: #1A1A1A;
        `}
`;
export const BoardBoxListSort = styled.span`
    font-size: 12px;
    color: #424242;
`;
export const BoardBoxListTit = styled.span`
    width: 368px;
    overflow-x: hidden;
    overflow-y: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${(props) =>
        props.short &&
        css`            
            width: 368px;
        `}
        
`;
export const BoardBoxListDate = styled.span`
    color: #757575;
`;

export const QnaStatusWrapper = styled.div`
  width: 53px;
  height: 20px;
  border-radius: 4px;
  line-height: 100%;
  text-align: center;
  display: inline-block;
  
  ${props => props.Ready && css`
    background-color: #E92928;
  `}
  
  ${props => props.Done && css`
    background-color: #3189FA;
  `}
  
`;

export const QnaStatus = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;

  ${props => props.Ready && css`
    //color: #E92928;
    color: #FCE1E1;
  `}
  
  ${props => props.Done && css`
    //color: #3189FA;
    color: #F8FBFF;
    //background-color: #F8FBFF;

  `}
`;
