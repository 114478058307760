import React from "react";
import {BrowserRouter} from "react-router-dom";
import AuthRouter from "router/AuthRouter";
import Login from "pages/Login";
import Join from "pages/Join";
import Mypage from "pages/Mypage";

import NotFound from "common/NotFound";
import Channel from "pages/Channel/Channel";
//사용자 관리
import UserCreate from "pages/User/UserCrt";
import User from "pages/User/UserCrt";
import UserList from "pages/User/UserList";
import UserDetail from "pages/User/UserDetail";
import UserDetailList from "pages/User/UserList/UserDetailList";
import ChannelView from "pages/Channel/ChannelView";
import PuzzleTokenList from "pages/User/PuzzleTokenList";
import PuzzleTokenManagement from "pages/User/PuzzleTokenManagement";

import TestPage from "../pages/Temp/Poll_Ad_006";

import Bigdata from "../pages/Bigdata/Bigdata";
import CreateChannelPage from "../pages/CreateChannelPage/CreateChannelPage";
import CreatePlatformPoll from "../pages/CreatePlatformPoll/CreatePlatformPoll";
import Dashboard from "pages/Dashboard";
import PlatformPollDetailBalance from "../pages/PlatformPollDetail/PlatformPollDetailBalance";
import PlatformPollDetailKeywordRanking
  from "../pages/PlatformPollDetailKeyworkRanking/PlatformPollDetailKeywordRanking";
import RankingDetailViewHotRanking from "../pages/Temp/RankingDetailViewHotRanking/RankingDetailViewHotRanking";
import PuzzleTokenItemManagement from "../pages/User/PuzzleToken/PuzzleTokenItemManagement/PuzzleTokenItemManagement";
//import CreateRanking from "../pages/CreateRanking/CreateRanking";
//Content Menu
import CatManage from "pages/Contents/CategoryMange";
// import CategoryDetail from "pages/Contents/CategoryMange/CategoryDetail"
import CategoryChannelDetail from "pages/Contents/CategoryMange/CategoryDetail/Channel";
import CategoryPollDetail from "pages/Contents/CategoryMange/CategoryDetail/Poll";
import CategoryRankingDetail from "pages/Contents/CategoryMange/CategoryDetail/Ranking";
import KeywordMange from "pages/Contents/KeywordManage";
import LankMangeList from "pages/Contents/LankManage";
import CreateRanking from "pages/Contents/LankManage/CreateRanking";
import RankingDetail from "pages/Contents/LankManage/RankingDetail";
import PlatformMangeList from "pages/Contents/PlatformManage";
import CreatePlatform from "pages/Contents/PlatformManage/CreatePlatform";
import PlatformDetail from "pages/Contents/PlatformManage/PlatformDetail";

//게시판관리
import NoticeList from "pages/Post/Notice";
import FaqList from "pages/Post/Faq";
import PolicyList from "pages/Post/Policy";
import QnaList from "pages/Post/Qna";

//설문 관리
import InspectionSurvey from "pages/ServeyManage/InspectionSurvey";
import InspectionSurveyDetail from "pages/ServeyManage/InspectionSurvey/Detail";
import ChannelSurvey from "pages/ServeyManage/ChannelSurvey";
import ChannelSurveyDetail from "pages/ServeyManage/ChannelSurvey/Detail";
import ChannelSurveyCreate from "pages/ServeyManage/ChannelSurvey/Create";
import UserSurvey from "pages/ServeyManage/UserSurvey";
import UserSurveyDetail from "pages/ServeyManage/UserSurvey/Detail";
import NoticeRegister from "../pages/Post/Notice/NoticeRegister";
import FaqRegister from "../pages/Post/Faq/FaqRegister";
import PolicyRegister from "../pages/Post/Policy/PolicyRegister";
import QnaDetail from "../pages/Post/Qna/QnaDetail";
import NoticeDetail from "../pages/Post/Notice/NoticeDetail";
import FaqDetail from "../pages/Post/Faq/FaqDetail";
import PolicyDetail from "../pages/Post/Policy/PolicyDetail";
import UsageMng from "pages/PointManage/UsageMng";
import TopUpMng from "pages/PointManage/TopUpMng";
import ExchangeMng from "pages/PointManage/ExchangeMng";
import RewardMng from "pages/PointManage/RewardMng";
import RewardTokenMng from "pages/PointManage/RewardTokenMng";
import UserMng from "pages/PointManage/UserMng";
import RewardDetail from "pages/PointManage/RewardDetail";
import BerryPointDetail from "pages/PointManage/BerryPointDetail";
import ReportSurvey from '../pages/ServeyManage/ReportSurvey';
import ChannelViewManagement from '../pages/ServeyManage/ChannelViewMangement';
import RankingViewManagement from '../pages/Contents/RankingViewMangement';
import AdRewardManagementPage from '../pages/AdRewardManagementPage';
import CouponManagement from '../pages/PointManage/CouponManagement';

const Router = () => {
  const common = [
    {path: "/", element: <Login/>},
    {path: "join", element: <Join/>},
    // { path: "puzzleToken", element: <PuzzleToken />},

    {path: "*", element: <NotFound/>},
  ];
  const withAuthentication = [
    {path: "mypage", element: <Mypage/>},
    {
      path: "channel",
      children: [
        {path: "manage", element: <Channel/>},
        {path: "detail/:id", element: <ChannelView/>},
        {path: "detail/:id/poll/:id", element: <ChannelSurveyDetail/>},
      ]
    },
    {
      path: "createChannel",
      element: <CreateChannelPage/>,
    },

    {path: "bigdata/:id", element: <Bigdata/>},


    {path: "userCreate", element: <UserCreate/>},
    // { path: "userDetailList/:id", element: <UserDetailList /> },
    {path: "ChannelView", element: <ChannelView/>},
    {path: "test", element: <TestPage/>},
    // { path: "create-channel", element: <CreateChannelPage /> },
    {path: "create-platform-poll", element: <CreatePlatformPoll/>},
    {path: "user", element: <User/>},
    {path: "platform-poll-detail-balance", element: <PlatformPollDetailBalance/>},
    {path: "dashboard", element: <Dashboard/>},
    {
      path: "platform-poll-detail-keyword-ranking",
      element: <PlatformPollDetailKeywordRanking/>,
    },
    {
      path: "ranking-detail-view-hot-ranking",
      element: <RankingDetailViewHotRanking/>,
    },
    {
      path: "puzzle-token-item-management",
      element: <PuzzleTokenItemManagement/>,
    },
    {
      path: "contents",
      children: [
        {path: "categoryManage", element: <CatManage/>},
        {path: "rankingViewManagement", element: <RankingViewManagement/>},
        // { path: "categoryDetail/:id", element: <CategoryDetail /> },
        {path: "categoryChannelDetail/:id", element: <CategoryChannelDetail/>},
        {path: "categoryPollDetail/:id", element: <CategoryPollDetail/>},
        {path: "categoryRankingDetail/:id", element: <CategoryRankingDetail/>},
        {path: "keywordMange", element: <KeywordMange/>},
        {path: "platformList", element: <PlatformMangeList/>},
        {path: "platformCreate", element: <CreatePlatform/>},
        {path: "platformDetail/:id", element: <PlatformDetail/>},
        {path: "lankList", element: <LankMangeList/>},
        {path: "lankCreate", element: <CreateRanking/>},
        {path: "lankDetail/:id", element: <RankingDetail/>},
      ],
    },
    {
      path: "notice",
      children: [
        {path: "list", element: <NoticeList/>},
        {path: "detail/:id", element: <NoticeDetail/>},
      ],
    },
    {
      path: "noticePost",
      element: <NoticeRegister/>,
    },
    {
      path: "faq",
      children: [
        {path: "list", element: <FaqList/>},
        {path: "detail/:id", element: <FaqDetail/>},
      ],
    },
    {
      path: "faqPost",
      element: <FaqRegister/>,
    },
    {
      path: "tos",
      children: [
        {path: "list", element: <PolicyList/>},
        {path: "tosDetail/:id", element: <PolicyDetail/>},
      ],
    },
    {path: "tosPost", element: <PolicyRegister/>},
    {
      path: "qna",
      children: [
        {path: "list", element: <QnaList/>},
        {path: "qnaDetail/:id", element: <QnaDetail/>},
      ],
    },
    {
      path: "user",
      children: [
        {path: "list", element: <UserList/>},
        {path: "userDetail/:id", element: <UserDetail/>},
      ],
    },
    {
      path: "puzzleToken",
      children: [
        {path: "list", element: <PuzzleTokenList/>},
        {path: "management", element: <PuzzleTokenManagement/>},
      ],
    },
    {
      path: "surveyMange",
      children: [
        {path: "inspection", element: <InspectionSurvey/>},
        {path: "inspection/detail/:id", element: <InspectionSurveyDetail/>},
        {path: "channelSurvey", element: <ChannelSurvey/>},
        {path: "channelSurvey/detail/:id", element: <ChannelSurveyDetail/>},
        {path: "channelSurvey/create", element: <ChannelSurveyCreate/>},
        {path: "userSurvey", element: <UserSurvey/>},
        {path: "userSurvey/detail/:id/private", element: <UserSurveyDetail/>},
        {path: "reportSurvey", element: <ReportSurvey/>},
        {path: "viewManagement", element: <ChannelViewManagement/>},
      ],
    },
    {
      path: "pointMng",
      children: [
        {path: "refundMng", element: <UsageMng/>},
        {path: "topUpMng", element: <TopUpMng/>},
        {path: "exchangeMng", element: <ExchangeMng/>},
        {path: "rewardMng", element: <RewardMng/>},
        {path: "rewardTokenMng", element: <RewardTokenMng/>},
        {path: "userMng", element: <UserMng/>},
        {path: "userMng/detail/:id", element: <UserDetailList/>},
        {path: "userMng/detail/:id/:biz", element: <UserDetailList/>},
        {path: "rewardDetail", element: <RewardDetail/>},
        {path: "berryPoint", element: <BerryPointDetail/>},
        {path: "adRewardManagement", element: <AdRewardManagementPage/>},
        {path: "CouponManagement", element: <CouponManagement/>},
      ],
    },
  ];
  const withoutAuthentication = [];

  return (
    <BrowserRouter>
      <AuthRouter
        common={common}
        withAuth={withAuthentication}
        withoutAuth={withoutAuthentication}
      />
    </BrowserRouter>
  );
};

export default Router;
