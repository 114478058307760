import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import DATE from "common/constants/date";
import Table from "common/components/Table";
import Page from "common/components/Page";
import pageMaker from "common/components/Page/pageMaker";
import {
    Button,
    CustomTdContent,
    DropdownIndicator,
    MenuArea,
    MenuInfoArea,
    Title
} from "common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";
import {SearchConditionBox, SearchConditionText} from '../../ServeyManage/UserSurvey/userSurveySytle';
import {
    NOTICE_BOARD_LIST_COLUMNS,
    NOTICE_SEARCH_KEY_OPTIONS,
    NOTICE_TYPE_STATUS
} from '../../../common/constants/noticeBoard';
import Select from 'react-select';
import ListSearch from '../../../common/components/ListSearch';

const NoticeList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const queryType = searchParams.get('type') || ''
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? queryPage - 1 : 0,
        limit: 10,
        page: queryPage || 1,
        title: queryTitle || '',
        type: '',
    });
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", "notice/list");

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const boardAuth = useMemo(() => {
        const accessAuth = info?.access?.boardAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return (
            data &&
            data.map((data) => ({
                ...data,
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                noticeType: NOTICE_TYPE_STATUS[data.noticeType],
            }))
        );
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    const handleSelectRow = (row) => {
        navigate(`/notice/detail/${row.id}?page=${curPage}`);
    };

    function updateSearchParams(key, value) {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    }

    const handleSearch = (data) => {
        const {type, title, limit,} = formData;

        updateSearchParams('type', type);
        updateSearchParams('title', title);

        if (title || title === '') {
            updateSearchParams('page', '1')
            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, title: title})
        }

        fetch({
            // ...formData,
            title: title || '',
            type: type || '',
            page: 1,
            offset: 0,
            limit
        });

    };

    const moveNoticePost = () => {
        navigate("/noticePost");
    };

    const handlePage = (pageNum) => {
        updateSearchParams('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    };

    const handleChangeSearchKey = (option) => {
        updateSearchParams('type', option.value)
        setFormData({...formData, type: option.value})
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 168,
                border: "1px solid #757575",
                borderRadius: "8px",
                background: '#F5F5F5',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    const columnRenderer = (key, value, isTop) => {
        if (key === "id" && isTop) {
            return <CustomTdContent>필독</CustomTdContent>
        }
        return value
    }


    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
        }))

        fetch({
            // ...formData,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
            limit: 10,
        })

    }, [queryPage, queryTitle]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>
                        공지사항 관리
                        {
                            boardAuth.find(d => d === "W") &&
                            <Button tit onClick={moveNoticePost}>
                                공지사항 등록하기
                            </Button>
                        }

                    </Title>
                    <SearchConditionBox>
                        <SearchConditionText top>검색 조건</SearchConditionText>
                        <Select
                            options={NOTICE_SEARCH_KEY_OPTIONS}
                            styles={customStyles}
                            placeholder={formData.type ? NOTICE_TYPE_STATUS[formData.type] : NOTICE_TYPE_STATUS[queryType]}
                            components={{DropdownIndicator}}
                            onChange={handleChangeSearchKey}
                            isSearchable={false}
                            value={formData.type ? NOTICE_TYPE_STATUS[formData.type] : NOTICE_TYPE_STATUS[queryType]}
                        />
                    </SearchConditionBox>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={NOTICE_BOARD_LIST_COLUMNS}
                           rows={rows}
                           onSelectRow={handleSelectRow} totalNum={totalNum}
                           columnRenderer={columnRenderer}
                           searchParams={searchParams}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    );
};

export default NoticeList;
