import styled, {css} from "styled-components";

// 카테고리 탭
export const CataTab = styled.div`
    margin: 48px 0;
    height: 60px;

    ${(props) =>
            props.Channel &&
            css`
                margin: 24px 0;
            `}
`;


export const CataTabUl = styled.ul`
    display: inline-block;
    width: 100%;
`;
export const CataTabLi = styled.li`
    float: left;
    width: 33.33%;
    height: 60px;
    text-align: center;
    border: 1px solid #757575;
    border-radius: 8px 8px 0 0;
    padding: 18px 0;
    box-sizing: border-box;
    background-color: #f5f5f5;
    color: #757575;
    //cursor: pointer;

    ${(props) =>
            props.On &&
            css`
                background-color: #fff;
                color: #1a1a1a;
                font-weight: 500;
                border-bottom: 0;
            `}

    ${(props) =>
            props.Channel &&
            css`
                background-color: #FFF;
                border: 0;
                border-bottom: 1px solid #757575;
            `}
`;
