import styled, { css } from 'styled-components';


export const DateSearch = styled.div`    
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: -24px;
    padding: 24px 0;

`;
export const SearchConditionBox = styled.div`    
    position: relative;
    display: flex;
    align-items: center;
    &+&{
        margin-top: 24px;
    }
`;

export const SearchConditionText = styled.span`    
    margin-right:24px;
    font-size: 14px;
    float: left;

    ${(props) =>
        props.top &&
        css`
        line-height: 36px;
        `}  
`;
