import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import SearchFilter from "common/components/SearchFilter"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"

import DATE from "common/constants/date";

import {
    DateSearch,
    SearchConditionBox,
    SearchConditionDiv,
    SearchConditionText,
    SearchDateBox,
    SearchDateInput,
    SearchDateInputSpan,
    SearchDateMid,
    SearchDateTxt
} from "./userSurveySytle"

import {
    DATE_FILTER_OPTIONS,
    STATUS,
    USER_LIST_COLUMNS,
    USER_SEARCH_FILTER_OPTIONS,
    USER_SEARCH_KEY_OPTIONS,
    USER_SEARCH_KEY_STATUS
} from "common/constants/poll";
import Select from 'react-select';

// const INIT_OPTION = {
//     BOARD_PARAMS: {
//         offset: 0,
//         limit: 10
//     },
//     KEY: 'all',
//     DATE_FILTER_OPTION: 'ALL',
//     // START_AT: '2022-12-19',
//     // END_AT: '2023-01-19'
// }

const UserSurvey = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const queryPage = searchParams.get('page')
    const queryTitle = searchParams.get('title') || ''
    const queryStatus = searchParams.get('status') || ''
    const queryStartAt = searchParams.get('startAt') || ''
    const queryEndAt = searchParams.get('endAt') || ''
    const queryType = searchParams.get('type') || ''
    const queryDateOption = searchParams.get('dateOption') || 'ALL'
    const request = useRequest("get", "poll/private/list");
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? queryPage - 1 : 0,
        limit: 10,
        page: queryPage || 1,
        title: queryTitle || '',
        status: queryStatus ? queryStatus : '',
        startAt: queryStartAt ? queryStartAt : '',
        endAt: queryEndAt ? queryEndAt : '',
        type: queryType ? queryType : '',
    });
    const [dateFilterOption, setDateFilterOption] = useState(formData.dateOption ? formData.dateOption : queryDateOption);
    const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
    const refStartAt = useRef(null);
    const refEndAt = useRef(null);
    const [_cStartAt, setCStartAt] = useState(null);

    const getSearchDate = () => {
        const now = dayjs(new Date());
        let date = {
            startAt: now,
            endAt: now
        };
        switch (dateFilterOption) {
            case '1M':
                date.startAt = now.subtract(1, 'month');
                break;
            case '3M':
                date.startAt = now.subtract(3, 'month');
                break;
            case '6M':
                date.startAt = now.subtract(6, 'month');
                break;
            case '1Y':
                date.startAt = now.subtract(1, 'year');
                break;
            case 'SELECT':
                date.startAt = dayjs(refStartAt.current.value);
                date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;

                break;
            case 'ALL':
                date.startAt = null;
                date.endAt = null;
                break;
            default:
                date.startAt = now;
                date.endAt = now;
                break;
        }
        return {
            startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
            endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
        }

    }

    const {startAt, endAt} = getSearchDate();

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            status: STATUS[data.status],
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            period: `${dayjs(new Date(data.startAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.endAt)).format(DATE.FORMAT.DOT)}`,
            // startAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            // endAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
        }));
    }, {
        limit: 10,
        offset: 0,
        startAt,
        endAt
        // ...getSearchDate()
    });

    const handleSelectRow = (row) => {
        navigate(`detail/${row.id}/private?page=${curPage}`);
    }

    const handleChangeSearchKey = (option) => {
        searchParams.set('type', option.value)
        setFormData({...formData, type: option.value})
    }

    const handleSearch = (data) => {
        const {selectedOption} = data;
        const {startAt, endAt} = getSearchDate();
        const {type, title, limit} = formData;

        if (type && !title) {
            alert('검색 조건에 맞는 검색어를 입력해주세요')
            return
        }

        searchParams.set('type', type)

        if (selectedOption) {
            searchParams.set('page', '1')
            searchParams.set('status', selectedOption)
            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, status: selectedOption})
        }

        if (startAt && endAt) {
            setCurPage(1)
            searchParams.set('startAt', startAt)
            searchParams.set('endAt', endAt)


            if (dateFilterOption) {
                searchParams.set('dateOption', dateFilterOption)
            }

            setFormData({
                ...formData,
                page: 1,
                offset: 0,
                startAt: startAt,
                endAt: endAt,
                dateOption: dateFilterOption,
            })
        }

        if (title) {
            searchParams.set('page', '1')
            searchParams.set('title', title)

            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, title: title})
        }

        setSearchParams(searchParams)

        if (type === 'name') {
            fetch({
                name: title || '',
                status: selectedOption || '',
                startAt: startAt || '',
                endAt: endAt || '',
                page: 1,
                offset: 0,
                limit
            });
        } else if (type === 'title') {
            fetch({
                title: title || '',
                status: selectedOption || '',
                startAt: startAt || '',
                endAt: endAt || '',
                page: 1,
                offset: 0,
                limit
            });

        } else if (type === '') {
            fetch({
                title: title || '',
                name: title || '',
                status: selectedOption || '',
                startAt: startAt || '',
                endAt: endAt || '',
                page: 1,
                offset: 0,
                limit
            });

        }

        // fetch({
        //     // title: title || '',
        //     status: selectedOption || '',
        //     startAt: startAt || '',
        //     endAt: endAt || '',
        //     type: type || '',
        //     page: 1,
        //     offset: 0,
        //     limit
        // });
    }

    const handleDateFilterSelect = (option) => {
        setDateFilterOption(option);
        const {startAt, endAt} = getSearchDate();
        refEndAt.current.value = endAt;

        if (option === 'SELECT') {
            refStartAt.current.value = dayjs().format(DATE.FORMAT.DASH);
        } else {
            refStartAt.current.value = startAt;
        }

        setFormData(prevState => ({...prevState, startAt: startAt, endAt: endAt, dateOption: option}))

    }

    const handleChangeStartAt = () => {
        const {startAt} = getSearchDate();
        refStartAt.current.value = startAt;
        searchParams.set('startAt', startAt)
        setFormData({...formData, startAt: startAt})
    }

    const handleChangeEndAt = () => {
        const {endAt} = getSearchDate();
        refEndAt.current.value = endAt;
        searchParams.set('endAt', endAt)
        setFormData({...formData, endAt: endAt})
    }

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setSearchParams(searchParams)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 168,
                border: "1px solid #757575",
                borderRadius: "8px",
                background: '#F5F5F5',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );


    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            dateOption: queryDateOption || 'ALL'
        }))

        setDateFilterOption(queryDateOption)

        const fetchOptions = {
            offset: queryPage ? Number(queryPage) - 1 : 0,
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            limit: 10,
        }

        if (queryType === 'name') {
            fetchOptions.name = queryTitle || ''
        } else if (queryType === 'title') {
            fetchOptions.title = queryTitle || ''
        } else {
            fetchOptions.title = queryTitle || ''
            fetchOptions.name = queryTitle || ''
        }

        fetch(fetchOptions)

    }, [queryPage, queryTitle, queryStartAt, queryEndAt, queryStatus]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>회원 설문 관리</Title>
                    <DateSearch>
                        <SearchConditionBox>
                            <SearchConditionText top>검색 조건</SearchConditionText>
                            <Select
                                options={USER_SEARCH_KEY_OPTIONS}
                                placeholder={queryType ? USER_SEARCH_KEY_STATUS[queryType] : USER_SEARCH_KEY_OPTIONS[0].label}
                                value={queryType ? USER_SEARCH_KEY_STATUS[queryType] : USER_SEARCH_KEY_OPTIONS[0].label}
                                onChange={handleChangeSearchKey}
                                styles={customStyles}
                                isSearchable={false}
                            />
                        </SearchConditionBox>
                        <SearchConditionBox>
                            <SearchConditionText>등록일 조회 기간</SearchConditionText>
                            <SearchConditionDiv>
                                <SearchFilter options={DATE_FILTER_OPTIONS} option={formData.dateOption}
                                              onSelect={handleDateFilterSelect} type="filter"/>
                                <SearchDateBox>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refStartAt} onChange={() => handleChangeStartAt()}/>
                                        <SearchDateTxt>{startAt || startAt === '' ? startAt : formData.startAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                    <SearchDateMid>~</SearchDateMid>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         min={_cStartAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refEndAt} onChange={() => handleChangeEndAt()}/>
                                        <SearchDateTxt>{endAt || endAt === '' ? endAt : formData.endAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                </SearchDateBox>
                            </SearchConditionDiv>
                        </SearchConditionBox>
                    </DateSearch>
                    <ListSearch options={USER_SEARCH_FILTER_OPTIONS}
                                onSearch={handleSearch}
                                initOption={queryStatus ? queryStatus : formData.status}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={USER_LIST_COLUMNS} rows={rows}
                           onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    )
}

export default UserSurvey;
