import React, {useState} from "react";
import {
    AddFileWrapper,
    ButtonWrapper,
    Content,
    ContentContainer,
    ContentSection,
    ContentTitle,
    ContentWrapper,
    CreateChannelTitleContainer,
    CreatePlatformPollContainer,
    KeywordTextWrapper,
    MainContainer,
    PollTypeWrapper,
    PreviewWrapper,
    RewardAmountWrapper,
} from "./CreatePlatformPollStyle";
import {TextByteCheck} from "../../common/js/TextValueCheck";
import {ListInput} from "../../common/styledComponents/commonStyle";
import {useFieldArray, useForm} from "react-hook-form";
import AddIcon from "../../asset/img/icon__add_white.png";

function CreatePlatformPoll(props) {
    const [pollTitle, setPollTitle] = useState("");
    const [reward, setReward] = useState("");
    const pollKeywordData = [
        {title: "항목 1", id: 1, value: "", name: "keyword_1", file: "", fileName: ""},
        {title: "항목 2", id: 2, value: "", name: "keyword_2", file: "", fileName: ""},
        {title: "항목 3", id: 3, value: "", name: "keyword_3", file: "", fileName: ""},
        {title: "항목 4", id: 4, value: "", name: "keyword_4", file: "", fileName: ""},
        {title: "항목 5", id: 5, value: "", name: "keyword_5", file: "", fileName: ""},
        {title: "항목 6", id: 6, value: "", name: "keyword_6", file: "", fileName: ""},
        {title: "항목 7", id: 7, value: "", name: "keyword_7", file: "", fileName: ""},
        {title: "항목 8", id: 8, value: "", name: "keyword_8", file: "", fileName: ""},
        {title: "항목 9", id: 9, value: "", name: "keyword_9", file: "", fileName: ""},
        {title: "항목 10", id: 10, value: "", name: "keyword_10", file: "", fileName: ""},
    ];

    const pollItems = [
        {title: "항목 1", id: 1, value: "", name: "balance_item_1", file: "", fileName: ""},
        {title: "항목 2", id: 2, value: "", name: "balance_item_2", file: "", fileName: ""},
    ];

    const [rewardAmountState, setRewardAmountState] = useState(true);

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
        reset,
        setValue,
        control,
    } = useForm({
        defaultValues: {
            pollKeywords: pollKeywordData,
            pollItems: pollItems,
        },
    });

    const {
        fields: pollKeywordsFields,
        append: pollKeywordsAppend,
        replace: pollKeywordsReplace,
    } = useFieldArray({
        control,
        name: "pollKeywords",
    });

    const {
        fields: pollItemsFields,
        append: pollItemsAppend,
        replace: pollItemsReplace,
    } = useFieldArray({
        control,
        name: "pollItems",
    });

    const onSubmit = (data) => {
        console.log(data, "form 데이터 값");
    };

    const onChangePollTitle = () => {
        if (watch().hasOwnProperty("pollTitle") || watch().polltitle !== "") {
            setPollTitle(watch().pollTitle);
            TextByteCheck(watch().pollTitle, setPollTitle, 20);
        }
    };

    const onRewardAmount = (e) => {
        const id = e.target.id;

        if (id === "yes_reward") {
            setRewardAmountState(false);
        } else if (id === "no_reward") {
            setRewardAmountState(true);
            setReward("");
        }
    };

    const addPollKeywordData = () => {
        const nextId = Number(Math.max(...watch().pollKeywords.map((data) => data.id))) + 1;

        pollKeywordsAppend({
            title: `항목 ${nextId}`,
            id: nextId,
            value: "",
            name: `keyword_${nextId}`,
        });
    };

    const RewardNumberChange = (e) => {
        setReward(e.target.value);
    };

    const addImageArray = (e, file, image, replace) => {
        const id = e.target.id;
        const filename = file.name;
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const res = reader.result;
            const test = image.map((data) =>
                data.name === id ? {...data, file: res, fileName: filename} : data,
            );
            replace(test);
        };
    };

    return (
        <CreatePlatformPollContainer>
            <CreateChannelTitleContainer>
                <span>플랫폼 설문 생성하기</span>
            </CreateChannelTitleContainer>
            <MainContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ContentContainer>
                        <ContentSection>
                            <ContentWrapper Border Padding>
                                <ContentTitle>질문 유형</ContentTitle>
                                <Content PollType>
                                    <PollTypeWrapper>
                                        <input
                                            type="radio"
                                            id="balance"
                                            name="pollType"
                                            value="balance"
                                            {...register("pollType")}
                                        />
                                        <label htmlFor="balance">밸런스 게임</label>
                                    </PollTypeWrapper>
                                    <PollTypeWrapper>
                                        <input
                                            type="radio"
                                            id="keyword"
                                            name="pollType"
                                            value="keyword"
                                            {...register("pollType")}
                                        />
                                        <label htmlFor="keyword">키워드 랭킹</label>
                                    </PollTypeWrapper>
                                </Content>
                            </ContentWrapper>
                            <ContentWrapper>
                                <ContentTitle>설문 제목</ContentTitle>
                                <Content ChannelInput>
                                    <input
                                        type="text"
                                        required
                                        name="pollTitle"
                                        id="pollTitle"
                                        value={pollTitle}
                                        {...register("pollTitle", {
                                            onChange: onChangePollTitle,
                                        })}
                                    />
                                    <span>{pollTitle.length}/20자</span>
                                </Content>
                            </ContentWrapper>
                            <ContentWrapper Border Padding>
                                <ContentTitle>설문 노출 기간</ContentTitle>
                                <Content>대기 중</Content>
                            </ContentWrapper>
                            <ContentWrapper>
                                <ContentTitle>보상 지급 여부</ContentTitle>
                                <Content PollType>
                                    <PollTypeWrapper>
                                        <input
                                            type="radio"
                                            id="yes_reward"
                                            name="reward"
                                            value="yes_reward"
                                            {...register("reward", {onChange: onRewardAmount})}
                                        />
                                        <label htmlFor="yes_reward">지급</label>
                                    </PollTypeWrapper>
                                    <PollTypeWrapper>
                                        <input
                                            type="radio"
                                            id="no_reward"
                                            name="reward"
                                            value="no_reward"
                                            {...register("reward", {onChange: onRewardAmount})}
                                        />
                                        <label htmlFor="no_reward">지급 안함</label>
                                    </PollTypeWrapper>
                                </Content>
                            </ContentWrapper>
                            <ContentWrapper Border Padding>
                                <ContentTitle>보상 단위</ContentTitle>
                                <Content>
                                    <RewardAmountWrapper disabled={rewardAmountState}>
                                        <ListInput
                                            disabled={rewardAmountState}
                                            width="180px"
                                            type="number"
                                            maxLength="4"
                                            name="reward_amount"
                                            id="reward_amount"
                                            placeholder="nnnn"
                                            value={reward}
                                            {...register("reward_amount", {
                                                onChange: RewardNumberChange,
                                            })}
                                        />
                                        <span>Berry</span>
                                    </RewardAmountWrapper>
                                </Content>
                            </ContentWrapper>
                            <ContentWrapper>
                                <ContentTitle PollItems>설문 항목</ContentTitle>
                                <Content PollItems>
                                    <span>
                                        이미지 권장크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x
                                        1000) / jpg, jpeg, png, bmp
                                    </span>
                                </Content>
                            </ContentWrapper>
                            {watch().pollType === "keyword"
                                ? pollKeywordsFields.map((keyword, index) => (
                                    <ContentWrapper Padding key={index}>
                                        <ContentTitle>{keyword.title}</ContentTitle>
                                        <Content>
                                            <KeywordTextWrapper>
                                                <input
                                                    type="text"
                                                    required
                                                    key={keyword.id}
                                                    id={keyword.id}
                                                    value={watch().pollKeywords[index].value}
                                                    {...register(`pollKeywords.${index}.value`)}
                                                />
                                            </KeywordTextWrapper>
                                            <AddFileWrapper>
                                                <p>{keyword.fileName}</p>
                                                <input
                                                    type="file"
                                                    key={keyword.id}
                                                    id={keyword.name}
                                                    name={`pollKeywords.${index}.name`}
                                                    accept="image/jpg, image/png, image/jpeg"
                                                    {...register(`pollKeywords.${index}.file`, {
                                                        onChange: (e) =>
                                                            addImageArray(
                                                                e,
                                                                watch().pollKeywords[index]
                                                                    .file[0],
                                                                watch().pollKeywords,
                                                                pollKeywordsReplace,
                                                            ),
                                                    })}
                                                />
                                                <label htmlFor={keyword.name}>파일 첨부</label>
                                            </AddFileWrapper>
                                        </Content>
                                    </ContentWrapper>
                                ))
                                : watch().pollType === "balance" &&
                                pollItemsFields.map((item, index) => (
                                    <ContentWrapper Padding key={index}>
                                        <ContentTitle>{item.title}</ContentTitle>
                                        <Content>
                                            <KeywordTextWrapper>
                                                <input
                                                    type="text"
                                                    required
                                                    key={item.id}
                                                    id={item.id}
                                                    value={watch().pollItems[index].value}
                                                    {...register(`pollItems.${index}.value`)}
                                                />
                                            </KeywordTextWrapper>
                                            <AddFileWrapper>
                                                <p>{item.fileName}</p>
                                                <input
                                                    type="file"
                                                    id={item.name}
                                                    key={item.id}
                                                    name={`pollKeywords.${index}.name`}
                                                    accept="image/jpg, image/png, image/jpeg"
                                                    {...register(`pollItems.${index}.file`, {
                                                        onChange: (e) =>
                                                            addImageArray(
                                                                e,
                                                                watch().pollItems[index].file[0],
                                                                watch().pollItems,
                                                                pollItemsReplace,
                                                            ),
                                                    })}
                                                />
                                                <label htmlFor={item.name}>파일 첨부</label>
                                            </AddFileWrapper>
                                        </Content>
                                    </ContentWrapper>
                                ))}
                            {watch().pollType === "keyword" && (
                                <ButtonWrapper AddItem>
                                    <button type="button" onClick={addPollKeywordData}>
                                        <img src={AddIcon} alt="항목 추가"/>
                                        <span>항목 추가 (nn/nn)</span>
                                    </button>
                                </ButtonWrapper>
                            )}
                        </ContentSection>
                        <ButtonWrapper Margin>
                            <button type="submit">
                                <span>생성 하기</span>
                            </button>
                        </ButtonWrapper>
                    </ContentContainer>
                </form>
                <PreviewWrapper>test</PreviewWrapper>
            </MainContainer>
        </CreatePlatformPollContainer>
    );
}

export default CreatePlatformPoll;
