import React, {useEffect, useState} from "react";
import {SearchBox, SearchBtn, SearchCont, SearchDiv, SearchInput} from './ListSearchStyle';
import SearchFilter from 'common/components/SearchFilter'
import {useSearchParams} from 'react-router-dom';

const ListSearch = ({
                        options = [],
                        onSearch,
                        initOption = '',
                        keyword = '검색어를',
                        qna,
                        inputValue,
                        onChange,
                        role,
                        ...props
                    }) => {
    const [selectedOption, setSelectedOption] = useState(initOption);
    let [searchParams, setSearchParams] = useSearchParams();
    const {margin} = props

    const getSearchData = () => {
        // debugger;
        let data = {
            // text: searchInput.current.value,
            text: inputValue,
            selectedOption
        }
        // if (selectedOption) {
        // data = {
        //     ...data,
        //     selectedOption
        // }
        // }
        return data;
    }
    const search = () => {
        const data = getSearchData();
        if (!onSearch) {
            return;
        }
        onSearch(data);
    }
    const handleSelect = (option) => {
        setSelectedOption(option);

        if (role) {
            searchParams.set('role', option);
        } else {
            searchParams.set('status', option);
        }

        searchParams.set('page', '1');
        setSearchParams(searchParams);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        search();
    }

    useEffect(() => {
        setSelectedOption(initOption)
    }, [initOption]);

    return (
        <>
            <SearchCont margin={margin}>
                <form onSubmit={handleSubmit}>
                    <SearchBox>
                        <SearchDiv>
                            <SearchInput type="text" placeholder={qna ? qna : `${keyword} 입력 해주세요.`}
                                         value={inputValue}
                                         onChange={onChange}/>
                        </SearchDiv>
                        <SearchBtn>검색</SearchBtn>
                    </SearchBox>
                </form>
                {options && <SearchFilter options={options} option={selectedOption} onSelect={handleSelect}/>}
            </SearchCont>
        </>
    )
}

export default ListSearch;
