import styled, { css } from "styled-components";
import { PollSettingContainer } from "../../../common/styledComponents/commonStyle";

export const Container = styled(PollSettingContainer)`
    padding: 48px 0 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    width: ${(props) => props.width || "100%"};
    margin: ${(props) => props.margin};

    ${(props) =>
        props.Subheading &&
        css`
            width: auto;

            span {
                margin-top: auto;
                font-size: 14px;
                color: #1a1a1a;
            }
        `}

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
        `}

  ${(props) =>
        props.Row &&
        css`
            justify-content: space-between;
        `}

  ${(props) =>
        props.right &&
        css`
            justify-content: right;
        `}
`;

export const RankingTextInput = styled.input`
    background-color: #ffffff;
    border: 1px solid #757575;
    border-radius: 8px;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
    width: 608px;
`;
