import React from "react";
import {PreveiwBoxBottom, PreveiwBoxTop, PreveiwCont, RankListLineImg, RankTit} from "./CreatePlatformPollStyle";
import PreviewNone from "asset/img/preview__cont_002_none.png";


const PreviewBalance = ({title, items, domain}) => {

    const onErrorImg = (e) => {
        e.target.src = PreviewNone
    }

    return (
        <PreveiwCont>
            <PreveiwBoxTop>
                {title ?
                    <RankTit>{title}</RankTit> : <RankTit>설문 제목</RankTit>}
            </PreveiwBoxTop>
            <PreveiwBoxBottom>
                {items && items.map((item, index) => {
                    return (
                        // item.imageUrl &&
                        <RankListLineImg key={index}>
                            <img
                                src={item.imageUrl && item.imageUrl.includes('data:') ? item.imageUrl : `${domain}${item.url}`}
                                onError={onErrorImg}
                                alt='preview_image'/>
                        </RankListLineImg>
                        // :
                        // <RankListLineImg key={index}>
                        //     <img alt='preview_image' src={PreviewNone}/>
                        // </RankListLineImg>
                    )
                })}
                {/*{items.length !== 2 &&*/}
                {/*    <RankListLineImg>*/}
                {/*        <img alt='preview_image' src={PreviewNone}/>*/}
                {/*    </RankListLineImg>*/}
                {/*}*/}
            </PreveiwBoxBottom>
        </PreveiwCont>
    )
}
export default PreviewBalance;
