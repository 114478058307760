import CommonDetail from './CommonDetail'
import {useNavigate} from "react-router-dom";

const CategoryRankingDetail = () =>{
    const navigate = useNavigate();

    const handleSelectTableRow = (row) => {
        navigate(`/contents/lankDetail/${row.id}`);
    }

    return(
        <CommonDetail category="ranking" onSelectTableRow={handleSelectTableRow} />
    )
}

export default CategoryRankingDetail;