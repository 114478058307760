import { setUser } from "stores/user/actions";

export const USER_INFO = {
	method: "get",
	url: "user/info",
	onSuccess: async ({ response, dispatch }) => {
		const responseData = response.response;
		await dispatch(setUser(responseData));
		return responseData;
	},
	onError: ({ error, dispatch }) => {
		switch (error.statusCode) {
			
			default:
				break;
		}
		return error;
	},
};
