import * as env from "common/constants/env";
import axios from "axios";

const singleton = Symbol();
const singletonEnforcer = Symbol();

class PollberryApiService {
	constructor(enforcer) {
		if (enforcer !== singletonEnforcer) {
			throw new Error("Cannot construct singleton");
		}

		this.session = axios.create({
			baseURL: `${env.API_URL}/`,
			withCredentials: true,
		});
	}

	static get instance() {
		// Try to get an efficient singleton
		if (!this[singleton]) {
			this[singleton] = new PollberryApiService(singletonEnforcer);
		}

		return this[singleton];
	}

	setBaererToken = (token) => {
		this.session.defaults.headers.common = { Authorization: `Bearer ${token}` };
	};

	get = (...params) => this.session.get(...params);
	post = (...params) => this.session.post(...params);
	put = (...params) => this.session.put(...params);
	patch = (...params) => this.session.patch(...params);
	delete = (...params) => this.session.delete(...params);
}

export default PollberryApiService.instance;
