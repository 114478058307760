import React,{useState , useEffect} from "react";
import styled from "styled-components";
import HeaderLogo from "asset/img/logo__pollberry_wh_4x.png"
import {useNavigate} from "react-router-dom";
import { useGlobalStore } from "stores";
import storageService from "util/storage";
import { logout } from "stores/auth/actions";

const HeaderDiv = styled.header`    
    height:56px;
    background-color:#000032;
`;

const Hlogo = styled.h1`    
    display:inline-block;
    height:56px;
    float:left;
`;
const Htxt = styled.span`    
    display:inline-block;
    font-weight:500;
    color:#fff;
    padding:16px 0 16px 24px;
`;

const Hmeue = styled.div`    
    position:absolute;
    right:24px;
    top:16px;
`;
const HmenuBox = styled.ul`   
`;
const HmenuList = styled.li`   
    float:left;
    color:#fff;
    &+&{margin-left:36px;}
`;
const HmenuListLink = styled.a`   
    color:#fff;
`;


const Header = () =>{
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [
		{
			user: { info },
		}, dispatch
	] = useGlobalStore();
    const handleLogout = () => {
		dispatch(logout());
        navigate('/');
	};

    // useEffect(() => {
    //     const id = setInterval(() => {
    //         setCount(c => c + 1);
    //     }, 1000);
    //     return () => clearInterval(id);
    //   }, []);

    const token = storageService.get("auth", "accessToken", "local");
    let hasToken = token !== undefined;
   
    return(
        <HeaderDiv>
            <Hlogo onClick={() => navigate('/')}><img src={HeaderLogo} alt="pollberry" /></Hlogo>
            <Htxt>관리자 페이지</Htxt>
            {hasToken && <Hmeue>
                <HmenuBox>
                    {/* <HmenuList>{count} 경과</HmenuList> */}
                    <HmenuList >{info && info.userId} </HmenuList>
                    <HmenuList>
                        <HmenuListLink href="/mypage">마이페이지</HmenuListLink>
                    </HmenuList>
                    <HmenuList>
                        <HmenuListLink href="#none"  onClick={handleLogout}>로그아웃</HmenuListLink>
                    </HmenuList>
                </HmenuBox>
            </Hmeue>}
        </HeaderDiv>
    )
}

export default Header;
