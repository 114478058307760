import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";

import {MenuArea, MenuInfoArea} from "common/styledComponents/commonStyle";

import SurveyManageDetail from "pages/ServeyManage/SurveyManageDetail"
import {API_STATE} from "common/constants/state";
import DATE from "common/constants/date";

const UserSurveyDetail = () => {
    const params = useParams();
    const {id} = params;
    const pollDetailRequest = useRequest("get", `poll/${id}/private`);
    const [detailInfo, setDetailInfo] = useState({});
    const [onThumbnail, setOnThumbnail] = useState(false)


    useEffect(() => {
        pollDetailRequest.call()
    }, []);

    useEffect(() => {
        switch (pollDetailRequest.state) {
            case API_STATE.done:
                if (pollDetailRequest.response.success) {
                    const {response} = pollDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    const detailInfo = {
                        ...response,
                        startAt: dayjs(new Date(response.startAt)).format(DATE.FORMAT.DOT_TIME),
                        endAt: dayjs(new Date(response.endAt)).format(DATE.FORMAT.DOT_TIME)
                    }
                    setDetailInfo(detailInfo)
                }

                break;
            default:
                break;
        }
    }, [pollDetailRequest.state, pollDetailRequest.error, pollDetailRequest.response]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <SurveyManageDetail detailInfo={detailInfo} type="user" onThumbnail={onThumbnail}
                                        setOnThumbnail={setOnThumbnail}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    )
}

export default UserSurveyDetail;
