import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import {API_STATE} from "../../../../common/constants/state";
import PostListDetail from "../../../../common/components/PostListDetail/PostListDetail"
import {MenuArea,} from "../../../../common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";
import {useForm} from 'react-hook-form';

const NoticeDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const updateNoticeDetailRequest = useRequest("patch", `notice/${id}/update`);
    const noticeDetailRequest = useRequest("get", `notice/${id}`);
    const noticeRemoveRequest = useRequest("delete", `notice/${id}/delete`);
    const [noticeInfo, setNoticeInfo] = useState({});
    const {register, handleSubmit, watch, setValue, control} = useForm();
    // const [noticeContent, setNoticeContent] = useState(null);
    const [editorDate, setEditorData] = useState("");

    const handleEditInfo = (data) => {
        updateNoticeDetailRequest.call(data, "body");
    };

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const boardAuth = useMemo(() => {
        const accessAuth = info?.access?.boardAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const limitLength = (e) => {
        e.target.value = e.target.value.substring(0, 40)
        setValue('title', e.target.value);
    }

    const removePostItem = () => {
        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }
        noticeRemoveRequest.call('', "body")
    }

    useEffect(() => {
        noticeDetailRequest.call(id, "body");
    }, []);

    useEffect(() => {
        switch (updateNoticeDetailRequest.state) {
            case API_STATE.done:
                if (updateNoticeDetailRequest.response.success) {
                    alert('수정 되었습니다');
                    navigate('/notice/list');
                }
                break;
            default:
                break;
        }
    }, [updateNoticeDetailRequest.state, updateNoticeDetailRequest.error, updateNoticeDetailRequest.response]);

    useEffect(() => {
        switch (noticeRemoveRequest.state) {
            case API_STATE.done:
                if (noticeRemoveRequest.response.success) {
                    alert('삭제 되었습니다');
                    navigate('/notice/list');
                }
                break;
            default:
                break;
        }
    }, [noticeRemoveRequest.state, noticeRemoveRequest.error, noticeRemoveRequest.response]);


    useEffect(() => {
        switch (noticeDetailRequest.state) {
            case API_STATE.done:
                if (noticeDetailRequest.response.success) {
                    const {response} = noticeDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    setNoticeInfo(response);
                    setValue("title", response.title);
                    setValue("isTop", response.isTop);
                    setValue("type", response.type);
                    setValue("status", response.status);
                    setValue("noticeType", response.noticeType);
                    setValue("contents", response.contents);
                    setValue("createdAt", response.createdAt);
                    setEditorData(response.contents);
                }
                break;
            default:
                break;
        }
    }, [noticeDetailRequest.state, noticeDetailRequest.error, noticeDetailRequest.response]);

    return (
        <MenuArea>
            <form onSubmit={handleSubmit(handleEditInfo)}>
                <PostListDetail
                    info={noticeInfo}
                    handleEditInfo={handleEditInfo}
                    register={register}
                    navigate={navigate}
                    watch={watch()}
                    type='notice'
                    title="공지사항 상세보기"
                    registerTitle="title"
                    registerContents="contents"
                    setEditorData={setEditorData}
                    editorDate={editorDate}
                    boardAuth={boardAuth}
                    control={control}
                    limitLength={limitLength}
                    onRemove={removePostItem}
                />
            </form>
        </MenuArea>
    );
};

export default NoticeDetail;
