import styled, {css} from 'styled-components';
import AddWhiteIcon from "asset/img/icon__add.png"
import TrashIcon from "asset/img/icon__delete.png"
import DelIconBig from "asset/img/icon__cancel_big.png";
import AddImg from "asset/img/btn__edit-img.png"


export const MenuArea = styled.div`
  width: 100%;
  height: 100%;
`;

//마이페이지
export const MenuInfoArea = styled.div`
  width: 1180px;
  height: 100%;
  background-color: #fff;
  margin: 48px auto 80px;
  color: #1a1a1a;
`;

export const MenuInfoCont = styled.div`
  border-bottom: 1px solid #dbdbdb;
  clear: both;
  position: relative;
  padding: 24px 0 64px;
  margin-bottom: 24px;

  ${(props) =>
          props.survey &&
          css`
            padding: 24px 0 48px;
            margin-bottom: 24px;
          `}

  ${(props) =>
          props.noline &&
          css`
            border-bottom: 0;
          `}

  ${(props) =>
          props.createChannel &&
          css`
            display: flex;
          `}

  ${(props) =>
          props.cate &&
          css`
            border-bottom: 0;
            padding: 24px 0;
          `}
`;
export const MyLeft = styled.div`
  width: 380px;
  margin: ${(props) => props.margin || '0 105px'};
  ${(props) =>
          props.list &&
          css`
            margin: 0 220px 0 0;
          `}
  ${(props) =>
          props.user &&
          css`
            width: 350px;
            margin: 0;
            text-align: center;
          `}
`;
export const MyImg = styled.div`
  margin: 0 auto 24px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    max-height: 180px;
    border-radius: 50%;
    overflow: hidden;
  }

  ${(props) =>
          props.keyword &&
          css`
            margin: 40px auto;
            width: 120px;
            height: 120px;
          `}

`;
export const MyImgUpload = styled.label`
  cursor: pointer;

  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  &:after {
    content: "";
    background-image: url(${AddImg});
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const MyImgUploadWoImg = styled.label`
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
`;

export const UploadBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

export const UploadInput = styled.input`
  &[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    border: 0;
    z-index: 1;
    font-size: 0;
    cursor: pointer;
  }
`;
export const UploadLabel = styled.label`
  width: 100%;
  height: 100%;
  display: block;
  background-color: #d9d9d9;
  //width: 180px;
  //height: 180px;
  border-radius: 50%;
  cursor: pointer;
`;

export const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;


export const MyChannelName = styled.p`
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  ${(props) =>
          props.margin &&
          css`
            margin: 24px auto 30px;
          `}
`;
export const MyRight = styled.div`
  height: ${(props) => props.height || '430px'};
  ${(props) =>
          props.none &&
          css`
            height: auto;
          `}
`;


// commonStyle 로 옮김 오류 확인 후 삭제 예정
// export const List = styled.div`
//     width: 590px;

//     margin:${(props) => props.margin || '0 0 0 10px'};
//     ${(props) =>
//     props.channel &&
//     css`
//         margin:0 0 0 100px;
//     `}

// `;
// export const ListUl = styled.ul`
// `;

// export const ListLi = styled.li`
//     &+&{margin-top:48px;}
// `;

// export const ListLtxt = styled.span`
//     width:140px;
//     display:inline-block;
// `;
// export const ListRtxt = styled.span`
//     margin-left:24px;
//     display:inline-block;
//     ${(props) =>
//     props.line &&
//     css`
//         text-decoration:underline;
//     `}
//     ${(props) =>
//     props.max &&
//     css`
//         max-width:1016px;
//     `}

// `;
// export const ListRlink = styled.a`
//     text-decoration:underline;
//     color:#1a1a1a;
//     font-weight:700;
// `;


/* 포인트 */
export const MenuInfoSub = styled.h3`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 48px;
  position: relative;
  ${(props) =>
          props.ssub &&
          css`
            margin: 80px 0 48px
          `}
  ${(props) =>
          props.small &&
          css`
            font-size: 16px;
          `}
  ${(props) =>
          props.cate &&
          css`
            font-size: 18px;
            margin-bottom: 0;
          `}

`;
export const MenuInfoSubButton = styled.button`
  font-size: 14px;
  position: absolute;
  right: 0;

  &::before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    background-size: cover;
  }

  ${(props) =>
          props.add &&
          css`
            width: 134px;
            height: 36px;
            border-radius: 8px;
            padding: 8px 8px 8px 36px;
            background-color: #000065;
            color: #fff;
            text-align: left;

            &::before {
              background-image: url(${AddWhiteIcon});
              top: 6px;
              left: 8px;
            }
          `}
  ${(props) =>
          props.del &&
          css`
            color: #757575;
            text-align: right;
            padding: 0 24px 0 0;

            &::before {
              background-image: url(${TrashIcon});
              top: -2px;
              right: 0;
            }
          `}
  ${(props) =>
          props.rel &&
          css`
            position: relative !important;
          `}

  ${(props) =>
          props.Rel &&
          css`
            position: relative !important;
          `}

  ${(props) =>
          props.delbtn &&
          css`
            position: relative !important;

            &::before {
              background-image: url(${DelIconBig});
              top: -34px;
              right: -40px;
            }
          `}
`;

export const ItemDeleteButton = styled.button`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  line-height: 16px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const RemoveRankingItemButton = styled.button`
  height: 24px;
  width: 24px;
  line-height: 24px;
  margin: auto 0;
  //position: absolute;
  //right: 0;
  //top: 50%;
  //transform: translateY(-50%);


  img {
    height: 100%;
    width: 100%;
  }
`;


export const AddCount = styled.span`
  font-size: 14px;
  margin-left: 3px;
`;
export const MenuInfoSubRight = styled.span`
  font-size: 14px;
  margin-left: 24px;
`;


export const PointBox = styled.div`
  width: 100%;
  height: 126px;

  & + & {
    margin-top: 24px;
  }
`;

export const PointListDiv = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 380px;
  height: 126px;
  box-sizing: border-box;
  padding: 16px 40px 24px 24px;
  // margin-top:48px;
`;
export const PointToptxt = styled.p`
  font-weight: 500;
  margin-bottom: 16px;
`;
export const PointBottomGroup = styled.p`
  text-align: right;
  position: relative;
`;
export const PointBottomStrong = styled.strong`
  font-size: 32px;
  font-weight: 700;
  margin-right: 60px;
  max-width: 200px;
  overflow: hidden;
  display: inline-block;
`;
export const PointBottomSpan = styled.span`
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: 15px;
`;


//채널 회원 정보
export const ListInput = styled.input`
  border: 1px solid #757575;
  padding: 8px;
  border-radius: 8px;
`;


//비밀번호변경하기

export const MnemonicKeyCont = styled.div`
  display: grid;
  margin-top: 16px;
`;

export const MnemonicKeyUl = styled.ul`
  //float: left;
`;

export const MnemonicKeyLi = styled.li`
  background-color: #ffffff;
  width: 183px;
  height: 48px;
  padding: 10px 24px;
  box-sizing: border-box;
  text-align: center;
  margin: 12px 20px 0 0;
  border: 1px solid #000065;
  border-radius: 8px;
  float: left;

  &:nth-child(1) {
    margin-top: 0;
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  &:nth-child(3) {
    margin-top: 0;
  }


  &:nth-child(3n) {
    margin-right: 0;
  }
`;
export const MnemonicKeyTxt = styled.div`
  width: 135px;
  height: 20px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
`;

export const MnemonicKeyInput = styled.input`
  width: 135px;
  height: 20px;
  text-align: center;
  font-size: 14px;
`;

export const MnemonicKeyDiv = styled.div`
  & + & {
    margin-top: 8px;
  }

  width: 589px;
  height: 48px;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid #000065;
  border-radius: 8px;
  position: relative;
  //display: inline-block;
  ${(props) =>
          props.short &&
          css`
            width: 515px;
            text-align: left;
            float: left;
          `}
`;

export const MnemonicKeyInputLong = styled.input`
  width: 100%;

  &::placeholder {
    color: #757575;
  }
`;

export const KeyInputBox = styled.div`

  span {
    display: inline-block;
    margin-bottom: 16px;
  }

  ${(props) => props.password && css`
    margin-bottom: 40px;
  `};

  ${(props) => props.MnemonicKey && css`
    margin: 16px 0 48px 0;
  `};

  ${(props) => props.Admin && css`
    margin-top: 48px;
  `};

  & + & {
    margin-top: 8px;
  }
`;

export const MnemonicButtonGroup = styled.div`
  margin: 24px 0;
`;
export const MnemonicButtonLong = styled.button`
  background-color: #fff;
  border-radius: 8px;
  width: 386px;
  padding: 12px 8px;
  font-size: 24px;
  font-weight: 500;
  color: #000065;
  border: 2px solid #000065;
  margin-right: 20px;
  box-sizing: border-box;
`;
export const MnemonicButtonShort = styled.button`
  background-color: #000065;
  border-radius: 8px;
  width: 183px;
  padding: 12px 8px;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  box-sizing: border-box;
`;

export const MnemonicKeyErrorMsg = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #E92928;
  text-align: left;
  margin-top: 4px;
  width: 100%;
`;

export const IconImageWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

export const MyPagePasswordInput = styled.input`
  width: 589px;
  height: 48px;
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid #000065;
  border-radius: 8px;
`;

export const Wrapper = styled.div`

  ${props => props.Relative && css`
    position: relative;
  `}
`;
