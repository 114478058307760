import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useRequest from "hooks/useRequest";
import {API_STATE} from "common/constants/state";
import dayjs from 'dayjs'
import DATE from "common/constants/date";
import {Chart} from "react-google-charts";
import {Click, MenuArea, MenuInfoArea} from "common/styledComponents/commonStyle";

import {
    ApiBox,
    BoardBox,
    BoardBoxListDate,
    BoardBoxListLi,
    BoardBoxListSort,
    BoardBoxListTit,
    BoardBoxListUl,
    BoardHeadtxt,
    BoxList,
    DashBottom,
    DashBoxLi,
    DashInfoBerry,
    DashInfoNum,
    DashInfoTxt,
    DashLi,
    DashMain,
    DashMainL,
    DashMainR,
    DashNum,
    DashProfile,
    DashProfileLi,
    DashProfileUl,
    DashTop,
    DashTxt,
    DashUl,
    MoreBtn,
    PlatDashProfile,
    PlatProfileNum,
    PlatProfileTxt,
    ProfileIcon,
    ProfileIconImg,
    ProfileTxt,
    QnaStatus,
    QnaStatusWrapper,
    ResetBtn
} from "./dashboardStyle";
import profileIcon from "asset/img/profile_icon.png";
import {useGlobalStore} from "stores";

const useDashboardRequest = (url, onSuccess) => {
    const request = useRequest("get", url);

    useEffect(() => {
        switch (request.state) {
            case API_STATE.done:
                if (request.response.success) {
                    const {response} = request.response;
                    if (!response) {
                        return;
                    }
                    onSuccess && onSuccess(response);
                }

                break;
            default:
                break;
        }
    }, [request.state, request.error, request.response]);

    return [request];
};

const DashBox1 = ({count, text, onRefresh, link}) => {
    const navigate = useNavigate();
    const handleClickCount = () => {
        navigate(link);
    }
    const handleClickBtnRefresh = () => {
        onRefresh && onRefresh();
    }
    return (
        <DashBoxLi>
            <ResetBtn href="#none" onClick={handleClickBtnRefresh}>새로고침</ResetBtn>
            <DashNum onClick={handleClickCount}>{count}</DashNum>
            <DashTxt>{text}</DashTxt>
        </DashBoxLi>
    )
}

const Dashboard = () => {
    const [
        {
            user: {info},
        },
    ] = useGlobalStore()
    const navigate = useNavigate();
    const [channelCount, setChannelCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [qaCount, setQaCount] = useState(0);
    const [pollCount, setPollCount] = useState({});
    const [noticeList, setNoticeList] = useState([]);
    const [qaList, setQaList] = useState([]);
    const [userChart, setUserChart] = useState([]);
    const [pollChart, setPollChart] = useState([]);

    const [channelCountRequest] = useDashboardRequest("channel/count", (res) => {
        setChannelCount(res.response.count);
    });

    const [userCountRequest] = useDashboardRequest("user/count", (res) => {
        setUserCount(Number(res.count));
    });

    const [qaCountRequest] = useDashboardRequest("qa/ready/count", (res) => {
        setQaCount(res.count);
    });

    const [pollCountRequest] = useDashboardRequest("poll/count", (res) => {
        setPollCount({
            complete: res.countInComplete,
            onGoing: res.countInOngoing,
            ready: res.countInReady
        });
    });

    const [chartReqeust] = useDashboardRequest("stats/week/user", (res) => {
        let tmpData = [["Year", "채널 수", "회원 수"]];
        if (res && res?.length > 0) {
            res?.map(data => {
                const tmpDate = data.date.split("-");
                tmpData.push([tmpDate[2] + "일", data.channelCount, data.userCount]);
            })
            setUserChart(tmpData);
        }

    });

    const [chartPollReqeust] = useDashboardRequest("stats/week/poll", (res) => {
        let tmpData = [["Year", "채널 설문 수", "회원 설문 수"]];
        if (res && res?.length > 0) {
            res?.map(data => {
                const tmpDate = data.date.split("-");
                tmpData.push([tmpDate[2] + "일", data.publicPollsCount, data.privatePollsCount]);
            })
            setPollChart(tmpData);
        }

    });

    const [noticeListRequest] = useDashboardRequest("notice/dashboard/list", (res) => {
        const {data} = res;
        if (!data) {
            return;
        }
        setNoticeList(data.map((data) => ({
            ...data,
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
        })));
    });

    const [qaListRequest] = useDashboardRequest("qa/dashboard/list", (res) => {
        const {data} = res;
        if (!data) {
            return;
        }
        setQaList(data.map((data) => ({
            ...data,
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
        })));
    });

    useEffect(() => {
        channelCountRequest.call();
        userCountRequest.call();
        qaCountRequest.call();
        pollCountRequest.call();
        chartReqeust.call();
        chartPollReqeust.call();
        noticeListRequest.call({offset: 0, limit: 5}, 'queryString');
        qaListRequest.call({offset: 0, limit: 5}, 'queryString');
    }, []);

    const handleRefreshChannelCount = () => {
        channelCountRequest.call();
    };

    const handleRefreshUserCount = () => {
        userCountRequest.call();
    };

    const handleRefreshQaCount = () => {
        qaCountRequest.call();
    };

    const handleRefreshPollCount = () => {
        pollCountRequest.call();
    };

    const data2 = [
        ["Year", "채널 설문 수", "회원 설문 수"],
        ["22일", 5, 1],
        ["23일", 10, 10],
        ["24일", 10, 30],
        ["25일", 15, 50],
        ["26일", 15, 50],
        ["27일", 20, 50],
        ["28일", 32, 70],
    ];
    const options = {

        curveType: "function",
        legend: {position: "bottom"},
    };

    return (
        <>
            <MenuArea>
                <MenuInfoArea gray>
                    <DashMain>
                        <DashMainL>
                            <DashTop>
                                <DashUl>
                                    <DashBox1 text="채널 수" link={'/channel/manage'} count={channelCount}
                                              onRefresh={handleRefreshChannelCount}/>
                                    <DashBox1 text="회원 수" link={'/user/list'} count={userCount}
                                              onRefresh={handleRefreshUserCount}/>
                                    <DashBox1 text="1:1 문의" link={'/qna/list'} count={qaCount}
                                              onRefresh={handleRefreshQaCount}/>
                                    {/* <DashBoxLi>
                                     <ResetBtn href="#none">새로고침</ResetBtn>
                                     <DashNum>{dashboardInfo.channelCount}</DashNum>
                                     <DashTxt>채널 수</DashTxt>
                                     </DashBoxLi> */}
                                    {/* <DashBoxLi>
                                     <ResetBtn href="#none">새로고침</ResetBtn>
                                     <DashNum>000,000,000,000</DashNum>
                                     <DashTxt>회원 수</DashTxt>
                                     </DashBoxLi> */}
                                    {/* <DashBoxLi>
                                     <ResetBtn href="#none">새로고침</ResetBtn>
                                     <DashNum>000,000,000,000</DashNum>
                                     <DashTxt>1:1 문의</DashTxt>
                                     </DashBoxLi> */}
                                </DashUl>
                            </DashTop>
                            <DashBottom>
                                <DashUl style={{display: "flex"}}>
                                    <DashLi>
                                        <DashNum onClick={() => {
                                            navigate('/surveyMange/channelSurvey')
                                        }}>{pollCount.onGoing}</DashNum>
                                        <DashTxt>진행중인 설문</DashTxt>
                                    </DashLi>
                                    <DashLi>
                                        <DashNum onClick={() => {
                                            navigate('/surveyMange/inspection')
                                        }}>{pollCount.ready}</DashNum>
                                        <DashTxt>검수대기 설문</DashTxt>
                                    </DashLi>
                                    <DashLi>
                                        <DashNum onClick={() => {
                                            navigate('/surveyMange/channelSurvey?status=EXPIRED')
                                        }}>{pollCount.complete}</DashNum>
                                        <DashTxt>완료된 설문</DashTxt>
                                    </DashLi>
                                </DashUl>
                                <ResetBtn href="#none" onClick={handleRefreshPollCount}>새로고침</ResetBtn>
                            </DashBottom>
                        </DashMainL>
                        <DashMainR>
                            {(info.role !== "ROLE_SUPER" && info.role !== "ROLE_ADMIN") ?
                                <>
                                    <DashProfile>
                                        {/* <ProfileIcon>이미지아이콘 없을때 텍스트</ProfileIcon> */}
                                        <ProfileIcon><ProfileIconImg src={profileIcon}></ProfileIconImg></ProfileIcon>
                                        <ProfileTxt>channel name</ProfileTxt>
                                        <ProfileTxt>회원등급</ProfileTxt>
                                    </DashProfile>
                                    <DashProfileUl>
                                        <DashProfileLi>
                                            <DashInfoTxt>보상액</DashInfoTxt>
                                            <DashInfoNum>000,000,000</DashInfoNum>
                                            <DashInfoBerry>Berry</DashInfoBerry>
                                        </DashProfileLi>
                                        <DashProfileLi>
                                            <DashInfoTxt>포인트 발행액</DashInfoTxt>
                                            <DashInfoNum>000,000,000</DashInfoNum>
                                            <DashInfoBerry>Berry</DashInfoBerry>
                                        </DashProfileLi>
                                        <DashProfileLi>
                                            <DashInfoTxt>코인 환전액</DashInfoTxt>
                                            <DashInfoNum>000,000,000,000</DashInfoNum>
                                            <DashInfoBerry>Berry</DashInfoBerry>
                                        </DashProfileLi>
                                    </DashProfileUl>
                                </>
                                :
                                <PlatDashProfile>
                                    <PlatProfileTxt>Reward Token</PlatProfileTxt>
                                    <PlatProfileNum>647,454<span>Token</span></PlatProfileNum>
                                </PlatDashProfile>


                            }


                        </DashMainR>

                        <BoxList>

                            <ApiBox>
                                <BoardHeadtxt graph>사용자 통계</BoardHeadtxt>
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="100%"
                                    data={userChart}
                                    options={options}
                                />
                            </ApiBox>
                            <ApiBox>
                                <BoardHeadtxt graph>설문 통계</BoardHeadtxt>
                                <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="100%"
                                    data={pollChart}
                                    options={options}
                                />
                            </ApiBox>
                            <BoardBox>
                                <BoardHeadtxt>공지사항<MoreBtn onClick={() => {
                                    navigate('/notice/list')
                                }}>더보기</MoreBtn></BoardHeadtxt>
                                <BoardBoxListUl>
                                    {noticeList && noticeList.map((notice, index) => {
                                        return (
                                            <BoardBoxListLi key={index}>
                                                <Click onClick={() => {
                                                    navigate(`/notice/detail/${notice.id}`)
                                                }}>
                                                    <BoardBoxListSort>{notice.type}</BoardBoxListSort>
                                                    <BoardBoxListTit>{notice.title}</BoardBoxListTit>
                                                    <BoardBoxListDate>{notice.createdAt}</BoardBoxListDate>
                                                </Click>
                                            </BoardBoxListLi>
                                        )
                                    })}
                                    {/* <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListSort>채널</BoardBoxListSort>
                                     <BoardBoxListTit>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListSort>일반</BoardBoxListSort>
                                     <BoardBoxListTit>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListSort>일반</BoardBoxListSort>
                                     <BoardBoxListTit>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListSort>일반</BoardBoxListSort>
                                     <BoardBoxListTit>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi> */}
                                </BoardBoxListUl>
                            </BoardBox>

                            <BoardBox>
                                <BoardHeadtxt>1:1 문의 내역<MoreBtn onClick={() => {
                                    navigate('/qna/list')
                                }}>더보기</MoreBtn></BoardHeadtxt>
                                <BoardBoxListUl>
                                    {qaList && qaList.map((qa, index) => {
                                        return (
                                            <BoardBoxListLi key={index}>
                                                <Click onClick={() => {
                                                    navigate(`/qna/qnaDetail/${qa.id}`)
                                                }}>
                                                    {/*<BoardBoxListSort>{qa.status}</BoardBoxListSort>*/}
                                                    {qa.status && qa.status === 'READY' ?
                                                        <QnaStatusWrapper Ready>
                                                            <QnaStatus Ready>{qa.status}</QnaStatus>
                                                        </QnaStatusWrapper> :
                                                        <QnaStatusWrapper Done>
                                                            <QnaStatus Done>{qa.status}</QnaStatus>
                                                        </QnaStatusWrapper>
                                                    }
                                                    <BoardBoxListTit>{qa.title}</BoardBoxListTit>
                                                    <BoardBoxListDate>{qa.createdAt}</BoardBoxListDate>
                                                </Click>
                                            </BoardBoxListLi>
                                        )
                                    })}
                                    {/* <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListTag gray>답변완료</BoardBoxListTag>
                                     <BoardBoxListSort>전체</BoardBoxListSort>
                                     <BoardBoxListTit short>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListTag red>답변 전</BoardBoxListTag>
                                     <BoardBoxListSort>채널</BoardBoxListSort>
                                     <BoardBoxListTit short>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListTag red>답변 전</BoardBoxListTag>
                                     <BoardBoxListSort>채널</BoardBoxListSort>
                                     <BoardBoxListTit short>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListTag blue>처리중</BoardBoxListTag>
                                     <BoardBoxListSort>전체</BoardBoxListSort>
                                     <BoardBoxListTit short>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi>
                                     <BoardBoxListLi>
                                     <Click href="#none">
                                     <BoardBoxListTag blue>처리중</BoardBoxListTag>
                                     <BoardBoxListSort>전체</BoardBoxListSort>
                                     <BoardBoxListTit short>철 없을 적 내 기억 속에 비행기 타고가요. 철 없을 적 내 기억 속에 비행기 타고가요.</BoardBoxListTit>
                                     <BoardBoxListDate>2022.08.17</BoardBoxListDate>
                                     </Click>
                                     </BoardBoxListLi> */}
                                </BoardBoxListUl>
                            </BoardBox>
                        </BoxList>
                    </DashMain>
                </MenuInfoArea>
            </MenuArea>

        </>
    )
}

export default Dashboard;
