import React, {useEffect, useMemo} from 'react'
import {MenuInfoCont, MenuInfoSub, MyImg, MyImgUpload, MyLeft} from "pages/Mypage/MypageStyle";
import {
    Button,
    Center,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl,
    NoticeWrapper,
    Wrapper
} from "common/styledComponents/commonStyle";
import Select, {components} from 'react-select';
import Indicator from '../../../../asset/img/icon__arrow_drop_down_gray.png';
import {STATUS, STATUS_OPTIONS} from '../../../../common/constants/contents';
import {Controller} from 'react-hook-form';

const Info = ({...props}) => {
    const {
        info,
        categoryNameOnchange,
        navigate,
        category,
        contentsAuth,
        handleStatus,
        watch,
        control,
        setValue,
        register,
        domain,
        removeCategory,
        openConfirmModal,
    } = props;

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                borderRadius: "8px",
            }),
            control: (provided) => ({
                ...provided,
                width: 122,
                height: 36,
                border: "1px solid #757575",
                borderRadius: "8px",
                backgroundColor: '#F5F5F5'
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: 0,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <img src={Indicator} alt="Dropdown arrow"/>
                </components.DropdownIndicator>
            )
        );
    };

    const onChangeImage = (e, index) => {
        const file = e.target.files[0]

        if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onloadend = () => {
                const res = reader.result;
                setValue(`Url.Url`, file)
                setValue(`Url.imageUrl`, res)
            }
        }
        e.target.value = null;
    };

    useEffect(() => {
        // setImageSrc(info.Url);
        setValue('status', info.status)
        setValue('name', info.name)
        setValue('Url', info.Url)
    }, [info])

    return (
        <MenuInfoCont cate>
            <MenuInfoSub cate>카테고리 이미지</MenuInfoSub>
            <Center>
                <MyLeft>
                    <MyImg>
                        {watch.Url &&
                            <img
                                src={watch.Url.imageUrl && watch.Url.imageUrl.includes('data:') ? watch.Url.imageUrl : watch.Url !== '' && `${domain}${watch.Url}`}
                                alt={watch.Url !== '' ? `profile_image` : ''}
                            />
                        }
                        <MyImgUpload htmlFor="Url">
                            <Controller
                                name="Url"
                                control={control}
                                render={({field: {onChange}}) =>
                                    <input
                                        type="file"
                                        accept="image/jpg, image/png, image/jpeg"
                                        id='Url'
                                        onChange={(e,) => (
                                            onChange(e), onChangeImage(e)
                                        )}
                                    />
                                }
                                // rules={{required: true}}
                            />
                        </MyImgUpload>
                    </MyImg>
                </MyLeft>
                <List cate>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>카테고리 상태</ListLtxt>
                            <ListRtxt>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({field: {onChange, value}}) =>
                                        <Select
                                            // isDisabled={true}
                                            isSearchable={false}
                                            options={STATUS_OPTIONS}
                                            styles={customStyles}
                                            // placeholder={info.status ? STATUS_[info.status] : STATUS_[value]}
                                            value={value}
                                            placeholder={STATUS[value]}
                                            components={{DropdownIndicator}}
                                            // onChange={(selectedOption) => (selectedOption.value, handleStatus(selectedOption.value))}
                                            onChange={(selectedOption) => (value !== 'STOP' && selectedOption.value === "STOP" ? openConfirmModal() : onChange(selectedOption.value))}
                                            // onChange={(selectedOption) => onChange(selectedOption.value)}
                                        />
                                    }
                                    // rules={{required: true}}
                                />
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>카테고리 명</ListLtxt>
                            <ListRtxt>
                                <ListInput type="text"
                                           placeholder=""
                                    // onChange={categoryNameOnchange}
                                           {...register('name', {onChange: categoryNameOnchange})}
                                />
                                <NoticeWrapper CategoryCharacterLimit>
                                    <span>{watch.name ? watch.name.length : 0}/10</span>
                                </NoticeWrapper>
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>카테고리 구분</ListLtxt>
                            <ListRtxt>{info.categoryName}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>생성일</ListLtxt>
                            <ListRtxt>{info.createdAt}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>생성 아이디</ListLtxt>
                            <ListRtxt>{info.createdBy}</ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </Center>
            {/* disabled 추가시 비활성화 */}
            <Wrapper Row={category === 'poll'} margin='64px 0 0 0' Right>
                {category === 'poll' &&
                    <Button mini preview
                            onClick={() => navigate('/contents/keywordMange/', {state: {id: info.id}})}>
                        등록된 키워드 보기
                    </Button>}
                <Wrapper width='auto'>
                    {
                        contentsAuth.some(d => d === "D") &&
                        <Wrapper margin='0 20px 0 0' width='auto'>
                            <Button mini line type='button' onClick={removeCategory}>삭제하기</Button>
                        </Wrapper>
                    }
                    {
                        contentsAuth.some(d => d === "W") &&
                        <Button mini type='submit'>수정하기</Button>
                    }
                </Wrapper>
            </Wrapper>
        </MenuInfoCont>
    )
}

export default Info
