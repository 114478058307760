import styled, {css} from "styled-components";
import PollPrevieBg from "asset/img/preview__cont_002.png";
import PreviewNone from "asset/img/preview__cont_002_none.png";
import PreviewKeyword from "asset/img/preview__cont_002_keyword.png";
import {Wrapper} from '../../../../common/styledComponents/commonStyle';

export const CreatePlatformPollContainer = styled.div`
  padding: 48px 64px 80px;
  max-width: 1308px;
  // max-height: 1080px;
  height: 100%;
  width: 100%;
  background-color: #ffff;
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1919px) {
    //height: 500px;
  }

  @media (max-height: 1079px) {
    //height: 600px;
  }

  form {
    max-width: 810px;
    width: 100%;
    //max-height: 787px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const PreveiwBoxBottom = styled.div`

`;

export const PreveiwCont = styled.div`
  width: 360px;
  height: 740px;
  background-color: #fff;
  padding: 90px 20px;
  background-image: url(${PollPrevieBg});
  cursor: default;
`;

export const PreveiwBoxTop = styled.div`
  margin: 38px auto 48px
`;

export const ContentSection = styled.div`
  padding-right: 8px;
  //overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
`;

export const CreateChannelTitleContainer = styled.div`
  padding-bottom: 12px;
  border-bottom: 4px solid black;
  margin-bottom: 48px;

  span {
    font-size: 36px;
    font-weight: 500;
    line-height: 52px;
    color: #1a1a1a;
  }
`;

export const PreviewWrapper = styled.div`
  max-width: 360px;
  width: calc(100% / 3.277777777777778);
  padding: 8px;
`;

export const Content = styled.div`
  height: ${(props) => props.height};
  width: 100%;
  line-height: 1.8;

  ${(props) =>
          props.ChannelInput && css`
            display: flex;
            flex-flow: column;
            max-width: 608px;

            input {
              background-color: #FFFFFF;
              border: 1px solid #757575;
              border-radius: 8px;
              padding: 8px;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #1A1A1A;
              // width: 608px;
              width: 100%;
            }

            span {
              height: 17px;
              color: #757575;
              margin-left: auto;
              font-weight: 400;
              font-size: 10px;
              line-height: 14px;
              margin-top: 4px;
            }

          `};

  ${(props) =>
          props.ChannelProfile && css`
            display: flex;
            justify-content: space-between;
          `};

  ${(props) =>
          props.PollType && css`
            display: flex;
          `};

  ${(props) =>
          props.PollItems && css`
            width: auto;

            span {
              margin-top: auto;
              font-size: 14px;
              color: #1A1A1A;
            }
          `};

  ${(props) =>
          props.balance && css`
            max-width: 608px;
            position: relative;
          `};

  ${(props) =>
          props.keyword && css`
            max-width: 478px;
            position: relative;
          `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: ${(props) => (props.Padding ? "0 0 48px 0" : `48px 0 48px 0`)};
  width: 100%;
  //max-width: 772px;

  :first-child {
    padding-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  ${(props) =>
          props.BorderTop && css`
            border-top: 2px solid #DBDBDB;
          `};
  ${(props) =>
          props.short && css`
            max-width: 740px;
          `};

  ${(props) =>
          props.Border && css`
            border-bottom: 2px solid #DBDBDB;
          `};

  ${(props) =>
          props.PollItems && css`
            font-size: 24px;
          `};

  ${(props) =>
          props.spaceBetween && css`
            justify-content: space-between;
          `};

  ${(props) =>
          props.Center && css`
            align-items: center;
          `};
`;

export const ContentTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  margin-right: 24px;
  width: 140px;
  //width: 100%;

  ${(props) =>
          props.PollItems && css`
            font-size: 24px;
            line-height: 24px;
          `};

  ${(props) =>
          props.keyword && css`
            margin-right: 10px;
          `};
`;

export const PollTypeWrapper = styled.div`
  & + & {
    margin-left: 24px;
  }

  input {
    margin-right: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: ${(props) => (props.Margin ? "48px 0 0 0" : "0")};

  ${(props) =>
          props.AddItem && css`
            button {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 153px;
              background-color: #000065;
              padding: 6px 8px;
              border-radius: 8px;
              margin: -24px 4px 48px 0;

              span {
                color: #ffffff;
                font-size: 14px;
                font-weight: 500;
                line-height: 15px;
              }
            }

          `}
`;

// 이미지 파일 업로드
export const AddFileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  p {
    max-width: 500px;
    width: 100%;
    font-size: 10px;
    font-weight: 400;
    color: #757575;
    padding: 10px 8px;
    background-color: #f5f5f5;
    border: 1px solid #757575;
    border-radius: 8px;
    height: 36px;
  }

  label {
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background-color: #dbdbdb;
    border-radius: 8px;
    width: 100px;
    margin: ${(props) => props.margin || "0 0 0 8px"};
    color: #1a1a1a;
    line-height: 20px;
    cursor: pointer;
  }
`;

export const RewardAmountWrapper = styled.div`
  width: 180px;
  position: relative;

  input {
    :disabled {
      background-color: #f5f5f5;
      color: #757575;
    }

    ::placeholder {
      color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
    }
  }

  span {
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    position: absolute;
    color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
  }
`;

export const KeywordTextWrapper = styled.div`
  padding: 8px;
  border: 1px solid #757575;
  border-radius: 8px;
  margin-bottom: 8px;
  max-width: 568px;
  line-height: 20px;
  position: relative;

  input {
    width: 100%;
    font-size: 14px;
  }

  ${props => props.platformBalance && css`
    max-width: none;

    span {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      color: #757575;
      font-size: 10px;
      line-height: 14px;
    }
  `}
`;


// 미리보기
export const PreviewCont = styled.div`
  width: 360px;
  height: 740px;
  background-color: #fff;
  padding: 90px 20px;
  background-image: url(${PollPrevieBg});
  cursor: default;
`;

export const PreviewDiv = styled.div`
  p, li {
    font-size: 10px;
    line-height: 20px;
    color: #757575;
  }

  li {
    list-style: inside;
    margin-left: 10px;
  }
`;

export const PreviewImg = styled.div`
  width: 360px;
  height: 740px;
  cursor: default;
  margin-bottom: 9px;
`;
export const KeywordImgBox = styled.div`
  content: "";
  background-image: url(${PreviewKeyword});
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const KeywordImgTit = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: #1a1a1a;
  line-height: 1.5;
  margin: 90px 20px 0;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

`;
export const CircleBox = styled.div`
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  background-color: ${props => props.bgColor};
  background-size: cover;
  border: 0;

  ${(props) =>
          props.target === 1 &&
          css`
            width: 180px;
            height: 180px;
            top: 235px;
            left: 10px;
            background-color: #0000fe;
          `}
  ${(props) =>
          props.target === 7 &&
          css`
            width: 100px;
            height: 100px;
            top: 206px;
            left: 184px;
            background: #fe0166;
          `}
  ${(props) =>
          props.target === 2 &&
          css`
            width: 180px;
            height: 180px;
            top: 296px;
            left: 231px;
            background: #8ee33b;

          `}
  ${(props) =>
          props.target === 6 &&
          css`
            width: 100px;
            height: 100px;
            top: 426px;
            left: 31px;
            background: #0000fe;
          `}
  ${(props) =>
          props.target === 4 &&
          css`
            width: 140px;
            height: 140px;
            top: 439px;
            left: 134px;
            background: #fc7826;
          `}
  ${(props) =>
          props.target === 3 &&
          css`
            width: 180px;
            height: 180px;
            top: 531px;
            left: -22px;
            background: #fe0166;
          `}
  ${(props) =>
          props.target === 5 &&
          css`
            width: 140px;
            height: 140px;
            top: 559px;
            left: 231px;
            background: #fdd102;
          `}
  img {
    border: 0;
  }
`;

export const CircleTxt = styled.span`
  position: relative;
  z-index: 5;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
`;

export const CircleImgClear = styled.span`
  position: absolute;
  left: 0;
  top: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;
export const CircleImg = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.bgColor};


  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #292929;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
export const KeywordTxtBox = styled.div`
  padding: 11px 0;
  font-size: 14px;
  color: #fff;
  background: #0000fe;
  text-align: center;
  position: absolute;
  bottom: 40px;
  width: 320px;
  left: 20px;
`;

export const PreviewBoxTop = styled.div`
  margin: 38px auto 48px
`;

export const RankTit = styled.p`
  color: #000;
  font-weight: 700;
  font-size: 20px
`;
export const PreviewBoxBottom = styled.div`

`;


export const RankListImg = styled.div`
  width: 320px;
  height: 200px;
  background-color: #d9d9d9;
  background-image: ${(props) => props.image ? `none` : `url(${PreviewNone})`};
  background-size: cover;
  background-position: 0 80%;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
  }

  & + & {
    margin-top: 16px;
  }

`;

export const RankListLineImg = styled.div`
  width: 320px;
  height: 200px;
  background-color: #ffffff;
  background-image: ${(props) => props.image ? `none` : `url(${PreviewNone})`};
  background-size: cover;
  background-position: 0 80%;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #424242;

  img {
    width: 100%;
    height: 100%;
  }

  & + & {
    margin-top: 16px;
  }

`;

export const RemoveButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 16px;

  img {
    width: 16px;
    height: 16px;
  }

  ${props => props.removeItem && css`
    position: static;
    transform: unset;

    img {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const FileNameWrapper = styled(Wrapper)`
  position: relative;
  max-width: 500px;

  ${(props) =>
          props.balance && css`
            max-width: 500px;
            position: relative;
          `};

  ${(props) =>
          props.keyword && css`
            max-width: 370px;
            position: relative;
          `};
`;

export const ItemPrevImage = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 24px;
  background-color: #f5f5f5;

  img {
    height: 100%;
    width: 100%
  }
`;

export const PlatformRewardWrapper = styled(Wrapper)`
  span {
    :first-child {
      width: 89px;
    }

    :last-child {
      margin-left: 8px;
    }
  }
`;

