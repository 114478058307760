import React, {useEffect, useState} from "react";
import dayjs from 'dayjs'
import DATE from "common/constants/date";
import useRequest from "hooks/useRequest";
import {API_STATE} from "common/constants/state";
import {useNavigate} from "react-router-dom";
import CommonRankingView from "../CommonRankingView"
import {MenuArea, MenuInfoArea} from "common/styledComponents/commonStyle";
import Title from '../../../../common/components/Title';

function CreateRanking(props) {
    const navigate = useNavigate();

    const categoryListRequest = useRequest("get", `category/ranking/simple/list`);
    const saveRankingRequest = useRequest("post", "ranking/new");
    const [categoryList, setCategoryList] = useState([]);

    const today = dayjs().format(DATE.FORMAT.DASH_TIME);
    // const commonViewParams = {
    //     // type: 'balance',
    //     // title: '',
    //     // startAt: today,
    //     // endAt: today,
    //     // source: '',
    //     // backgroundImage: '',
    //     // categoryId: 2,
    //     // categoryList: [],
    //     // items,
    //     // isPaid: true,
    //     // paidUnit: '',
    //     // paidAmount,
    // }

    const handleSubmit = (formData) => {
        saveRankingRequest.call(formData);
        // savePlatformNewRequest.call(formData);
    }

    useEffect(() => {
        switch (saveRankingRequest.state) {
            case API_STATE.done:
                if (saveRankingRequest.response.success) {
                    alert("랭킹이 생성 되었습니다.");
                    navigate('/contents/lankList');
                } else {

                }

                break;
            default:
                break;
        }
    }, [saveRankingRequest.state, saveRankingRequest.error, saveRankingRequest.response]);


    useEffect(() => {
        switch (categoryListRequest.state) {
            case API_STATE.done:
                if (categoryListRequest.response.success) {
                    const {response} = categoryListRequest.response;
                    if (!response || !response.data) {
                        return;
                    }
                    const categories = response.data.map((category) => {
                        const {id, name} = category;
                        return {
                            id,
                            value: id,
                            label: name
                        }
                    });
                    categories.unshift({
                        id: '',
                        value: '',
                        label: '선택하세요'
                    });
                    setCategoryList(categories);
                }

                break;
            default:
                break;
        }
    }, [categoryListRequest.state, categoryListRequest.error, categoryListRequest.response]);

    useEffect(() => {
        categoryListRequest.call()
    }, []);

    return (
        <MenuArea>
            <MenuInfoArea>
                <Title>랭킹 등록하기</Title>
                <CommonRankingView categoryList={categoryList} onSubmit={handleSubmit} today={today}/>
            </MenuInfoArea>
        </MenuArea>
    );
}

export default CreateRanking;
