import React, {useMemo, useState} from "react";
import {
  BERRY_RANKING_STATUS_OPTIONS,
  PUBLIC_RANKING_STATUS_OPTIONS,
  STATUS,
  VIEW_STATUS
} from "common/constants/ranking";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import Preview from "./Preview"
import parseISO from 'date-fns/parseISO'
import Select from 'react-select'
import DelIconBig from "asset/img/icon__cancel_big.png";

import {
  ButtonWrapper,
  ContentSection,
  ListInputBerry,
  ListRtxtSubNum,
  RankingTextInput,
  RewardAmountWrapper
} from "./CreateRankingStyle";
import {
  AddFileWrapper,
  Button,
  CloseButton,
  CustomHeader,
  DelFile,
  DropdownIndicator,
  GrayButton,
  ListInput,
  ListRspan,
  ModalContainer,
  ModalText,
  NoticeSub,
  NoticeWrapper,
  PollSettingContentContainer,
  RewardDetailContent,
  RewardDetailTitle,
  StyledDatePicker,
  StyledModal,
  StyledTextArea,
  Wrapper
} from "common/styledComponents/commonStyle";
import ContentWrapper from "common/components/ContentWrapper/ContentWrapper";
import {Content} from "../RankingDetail/RankingDetailViewHotRankingStyle";
import ArrowRight from "asset/img/icon__arrow_forward.png"
import AddIcon from "asset/img/icon__add_white.png";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko'
import {useGlobalStore} from "stores";
import dayjs from 'dayjs';
import DATE from '../../../../common/constants/date';
import RewardModal from '../../../../common/components/RewardModal/RewardModal';
import ParticipationCountModal from '../../../../common/components/ParticipationCountModal/ParticipationCountModal';
import ItemsUpload from '../../../../common/js/ItemsUpload';
import VideoUpload from '../../../../common/js/VideoUpload';
import {ItemDeleteButton, RemoveRankingItemButton} from '../../../Mypage/MypageStyle';
import CloseIcon from '../../../../asset/img/icon__close_black.svg';
import useRequest from '../../../../hooks/useRequest';
import RankingResult from '../RankingDetail/RankingResult';

function CreateRanking({...props}) {
  const {
    title,
    startAt,
    endAt,
    sourceBy,
    categoryList = [],
    categoryId,
    isPaid,
    paidUnit,
    items,
    onSubmit,
    isView,
    today,
    rankingDetailId,
    openAt,
    closeAt,
    participantsCount,
    paidAmount,
    surveyEnabled,
    createdAt,
    publicStatus,
    berryStatus,
    videoUrl,
    dateType,
    berryCreatedAt,
    berryRankingState,
    rankingRewardListRequest,
    participationListRequest
  } = props;

  const RANKING_ITEM_MAX_LENGTH = 100;
  const [openSelected, setOpenSelected] = useState(null);
  const [closeSelected, setCloseSelected] = useState(null);
  const [endAtSelected, setEndAtSelected] = useState(null);
  const [onRewardModal, setOnRewardModal] = useState(false);
  const [onParticipationCountModal, setOnParticipationCountModal] = useState(false);
  const [onCompleteModal, setOnCompleteModal] = useState(false);       // 이미지 업로드용 state
  const surveyCompleteRequest = useRequest("patch", `poll/${rankingDetailId}/complete`);
  const defaultItem = {text: "", url: ""};
  const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

  const [
    {
      user: {info},
    },
  ] = useGlobalStore();

  const contentsAuth = useMemo(() => {
    const accessAuth = info?.access?.contentsAccess;
    const authArray = accessAuth.split("");
    return authArray;
  }, [info]);

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors, isSubmitting},
    setValue,
    control,
  } = useForm({
    defaultValues: {
      items: items || [{text: "", url: ""}],
      title: title && title,
      videoUrl: videoUrl && videoUrl,
      startAt: startAt && parseISO(startAt),
      endAt: endAt && parseISO(endAt),
      surveyEnabled: surveyEnabled ? surveyEnabled : false,
      categoryId: categoryId && categoryId,
      closeAt: closeAt && parseISO(closeAt),
      openAt: openAt && parseISO(openAt),
      publicStatus: publicStatus && publicStatus,
      berryStatus: berryStatus && berryStatus,
      sourceBy: sourceBy && sourceBy,
      createdAt: createdAt ? dayjs(new Date(createdAt)).format(DATE.FORMAT.DASH) : dayjs(new Date(today)).format(DATE.FORMAT.DASH),
      isPaid: isPaid || false,
      paidUnit: paidUnit || 0,
      dateType: dateType || 'DAILY',
      berryCreatedAt: berryCreatedAt ? dayjs(new Date(berryCreatedAt)).format(DATE.FORMAT.DASH) : dayjs(new Date(today)).format(DATE.FORMAT.DASH),
    },
  });


  const {
    fields: rankingItemsFields,
    append: rankingItemsAppend,
    remove: rankingItemsRemove,
    update: rankingItemsUpdate,
  } = useFieldArray({
    control,
    name: "items",
  });

  const _onSubmit = async (data) => {

    if (data.surveyEnabled === null) {
      alert('berry ranking 생성 여부를 확인해주세요.')
      return
    }

    if (!data.startAt || !data.endAt) {
      alert('랭킹 기간을 설정해주세요.')
      return
    }

    if (data.surveyEnabled) {
      if (Number(data.rewardAmount) <= 0) {
        alert('Berry Ranking의 보상 단위를 입력해주세요.')
        return
      }

      if (!data.openAt || !data.closeAt) {
        alert('투표 참여 기간을 입력해주세요.')
        return
      }
    }

    if (!data.videoUrl) {
      alert('배경 파일을 확인해주세요.')
    }


    let formData = {
      ...data,
      surveyEnabled: data.surveyEnabled,
      title: data.title,
      startAt: new Date(data.startAt),
      endAt: new Date(data.endAt),
      sourceBy: data.sourceBy,
      categoryId: parseInt(data.categoryId, 10),
      paidUnit: parseInt(data.paidUnit),
      openAt: data.openAt,
      closeAt: data.closeAt,
      videoUrl: data.videoUrl,
      items: data.items,
    }

    if (data.publicStatus === 'STOP') {
      formData = {
        ...formData,
        berryStatus: 'STOP'
      }
    }

    if (isView && data.status) {
      formData = {
        ...formData,
        status: data.status
      }
    }

    await ItemsUpload(formData.items, 'ranking').then((images) => {
      formData = {...formData, items: images}
    }).catch(error => console.log(error))

    if (formData.videoUrl.src) {
      await VideoUpload(formData.videoUrl.url).then((video) => {
        formData = {...formData, videoUrl: video}
      }).catch(error => console.log(error))
    }

    delete formData.createdAt;

    onSubmit && onSubmit(formData);
  };

  const addRankingData = () => {
    rankingItemsAppend({...defaultItem});
  };

  const handleClickRemoveRanking = (idx) => {
    if (watch().items.length === 1) {
      return;
    }

    rankingItemsRemove(idx);
  };

  const removeItemImage = (e, index) => {
    if (watch().items[index].imageUrl) {
      rankingItemsUpdate(index, {text: '', url: ''})
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const openAtTimeDate = new Date(openAt);

    if (openAt) {
      return openAtTimeDate.getTime() <= selectedDate.getTime();

    } else {
      return currentDate.getTime() < selectedDate.getTime();
    }
  };

  const filterEndAtTime = (time) => {
    const selectedDate = new Date(time);

    if (openSelected) {
      return openSelected.getTime() < selectedDate.getTime();
    } else if (openAt) {
      return new Date(openAt).getTime() < selectedDate.getTime()
    }
  };

  const onOpenSelected = (date) => {

    if (closeSelected && date > closeSelected) {
      setCloseSelected(date)
    }

    setOpenSelected(date)
  };

  const onCloseSelected = (date) => {
    setCloseSelected(date)
  };

  const onStartAtSelected = (date) => {
    if (endAtSelected && date > endAtSelected) {
      setEndAtSelected(date)
    }
  };

  const onEndAtSelected = (date) => {
    setEndAtSelected(date)
  };

  const SelectCustomMediumStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: 280,
        border: "1px solid #757575",
        borderRadius: "8px",
        backgroundColor: "#F5F5F5",
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
    }),
    [],
  );

  const ViewStatusSelectCustomStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        width: 122,
        border: "1px solid #757575",
        borderRadius: "8px",
        backgroundColor: "#F5F5F5",
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
      }),
    }),
    [],
  );

  const openDetailModal = (e) => {
    document.body.style.overflow = 'hidden';
    const id = e.currentTarget.id

    if (id === 'reward') {
      setOnRewardModal(true)
    } else if (id === 'participantsCount') {
      setOnParticipationCountModal(true)
    }

  }

  const closeDetailModal = (e) => {
    document.body.style.overflow = 'auto';
    const id = e.currentTarget.id

    if (id === 'reward') {
      setOnRewardModal(false)

    } else if (id === 'participantsCount') {
      setOnParticipationCountModal(false)
    }
  }

  const addVideoUrl = (e) => {
    const file = e.target.files[0];
    const name = file.name;

        if (file) {
            if (file.size > 30 * 1024 * 1024) {
                alert("등록하신 파일의 크기를 확인해주세요.");
            } else if (name.length > 30) {
                setValue('videoUrl', {name: name.substring(0, 30) + '...', url: file, src: URL.createObjectURL(file)})
            } else {
                setValue('videoUrl', {name: name, url: file, src: URL.createObjectURL(file)})
            }
        }
    }

  const onChangeImage = (e, index) => {
    const file = e.target.files[0]

    if (file) {
      const name = file.name;
      if (name.length) {
        if (name.length > 30) {
          setValue(`items[${index}].name`, name.substring(0, 30) + '...')
        } else {
          setValue(`items[${index}].name`, name)
        }
      }
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        const res = reader.result;
        setValue(`items[${index}].rank`, Number(index) + 1)
        setValue(`items[${index}].url`, file)
        setValue(`items[${index}].imageUrl`, res)
      }
    }
    e.target.value = null;
  };

  const onLimitLength = (e, limit) => {
    const id = e.target.id;
    e.target.value = e.target.value.substring(0, limit)
    setValue(id, e.target.value);
  }

  const handleBizNumberKeyPress = (e) => {
    const forbiddenCharacters = ['e', 'E', '+', '-', '.', 'ArrowDown', 'Down', 'down', 'ArrowUp'];
    if (forbiddenCharacters.includes(e.key)) {
      e.preventDefault();
    }
  }

  const onChangeItemText = (index) => {
    setValue(`items[${index}].rank`, Number(index) + 1)
  }

  const openCompleteReasonModal = () => {
    setOnCompleteModal(true)
    document.body.style.overflow = 'hidden';
  }

  const closeCompleteReasonModal = () => {
    setOnCompleteModal(false)
    document.body.style.overflow = 'auto';
  }

  const patchCompleteReason = () => {
    surveyCompleteRequest.call({reasonForComplete: watch().reasonForComplete}, 'body')
  }

  return (
    <Wrapper>
      <Wrapper width="820px" padding='0 14px 0 0 ' Column>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <ContentSection>
            <Wrapper width="100%" Column>
              <PollSettingContentContainer>
                <ContentWrapper title='Public Ranking' Subheading maxWidth='100%'/>
                {rankingDetailId &&
                  <ContentWrapper title={"랭킹 상태"} Padding>
                    <Controller
                      name="publicStatus"
                      control={control}
                      render={({field: {onChange}}) =>
                        <Select
                          components={DropdownIndicator}
                          styles={SelectCustomMediumStyles}
                          options={PUBLIC_RANKING_STATUS_OPTIONS}
                          placeholder={STATUS[watch().publicStatus]}
                          onChange={selectedOption => {
                            onChange(selectedOption.value);
                          }}
                          isSearchable={false}
                        />
                      }
                      // rules={{required: true}}
                    />
                  </ContentWrapper>
                }
                <ContentWrapper title={"랭킹 제목"} Padding>
                  <Wrapper Column Right>
                    <ListInput
                      long
                      type="text"
                      // required
                      id="title"
                      {...register("title", {
                        onChange: (e) => onLimitLength(e, 40),
                      })}
                      paddingRight
                    />
                    <NoticeWrapper TextCountNew>
                      <span>{watch().title && watch().title.length ? watch().title.length : 0}/40자</span>
                    </NoticeWrapper>
                  </Wrapper>
                </ContentWrapper>
                <ContentWrapper title={"Public Ranking 등록일"} Padding>
                  <Content>
                    <Wrapper Center>
                      <span>{watch().createdAt}</span>
                    </Wrapper>
                  </Content>
                </ContentWrapper>
                <ContentWrapper title="랭킹 기간" Padding="Padding">
                  <Wrapper Center>
                    <Controller
                      name="startAt"
                      control={control}
                      render={({field: {value, onChange}}) =>
                        <div>
                          <StyledDatePicker
                            Start
                            id="startAt"
                            dateFormat="yyyy-MM-dd"
                            selected={value}
                            onChange={(date, e) => {
                              onChange(date, e)
                              onStartAtSelected(date)
                            }}
                            placeholderText="YYYY-MM-DD"
                            locale={ko}
                            renderCustomHeader={(params) => <CustomHeader {...params} />}
                            maxDate={parseISO(watch().createdAt) || new Date()}
                          />
                          {errors.startAt && (
                            <NoticeWrapper>
                              <span>{errors.startAt && `*${errors.startAt?.message}`}</span>
                            </NoticeWrapper>
                          )}
                        </div>
                      }
                    />
                    <ListRspan date><img src={ArrowRight} alt=""/></ListRspan>
                    <ListRspan date>
                      <Controller
                        name="endAt"
                        control={control}
                        render={({field: {value, onChange}}) =>
                          <div>
                            <StyledDatePicker
                              End
                              id="endAt"
                              dateFormat="yyyy-MM-dd"
                              selected={value}
                              onChange={(date) => {
                                onChange(date);
                                onEndAtSelected(date)
                              }}
                              minDate={watch().startAt}
                              placeholderText="YYYY-MM-DD"
                              locale={ko}
                              maxDate={new Date()}
                              renderCustomHeader={(params) =>
                                <CustomHeader {...params} />}
                            />
                          </div>
                        }
                      />
                    </ListRspan>
                    <Wrapper width="auto" margin='0 0 0 48px'>
                      <Controller
                        name="dateType"
                        control={control}
                        render={({field: {onChange}}) =>
                          <Select
                            components={DropdownIndicator}
                            styles={ViewStatusSelectCustomStyles}
                            options={VIEW_STATUS}
                            placeholder={watch().dateType}
                            onChange={selectedOption => {
                              onChange(selectedOption.value);
                            }}
                            isSearchable={false}
                          />
                        }
                        // rules={{required: true}}
                      />
                    </Wrapper>
                  </Wrapper>
                </ContentWrapper>
                <ContentWrapper title="랭킹 출처" Padding="Padding">
                  <Wrapper Column Right>
                    <ListInput
                      long
                      type="text"
                      id="sourceBy"
                      // maxLength={15}
                      {...register("sourceBy", {
                        // onChange: (e) => onLimitLength(e, 15),
                      })}
                      paddingRight
                    />
                    {/*<NoticeWrapper TextCountNew>*/}
                    {/*    <span>{watch().sourceBy && watch().sourceBy.length ? watch().sourceBy.length : 0}/15자</span>*/}
                    {/*</NoticeWrapper>*/}
                  </Wrapper>
                </ContentWrapper>
                <ContentWrapper Padding="Padding" title="랭킹 카테고리">
                  <Wrapper width="auto">
                    <Controller
                      name="categoryId"
                      control={control}
                      render={({field: {onChange}}) =>
                        <Select
                          components={DropdownIndicator}
                          styles={SelectCustomMediumStyles}
                          options={categoryList}
                          placeholder={watch().categoryId && categoryList ? categoryList.filter((item) => item.id === watch().categoryId)[0].label : null}
                          onChange={selectedOption => {
                            onChange(selectedOption?.id);
                          }}
                          isSearchable={false}
                        />
                      }
                    />
                  </Wrapper>
                </ContentWrapper>
                <ContentWrapper
                  title="배경 파일"
                  Padding="Padding"
                  Border="Border"
                >
                  <Wrapper Column>
                    <Controller
                      name="videoUrl"
                      control={control}
                      render={({field: {onChange, value}}) =>
                        <AddFileWrapper style={{width: "608px"}}>
                          <p style={{position: "relative"}}>
                            {watch().videoUrl && watch().videoUrl.name}
                            <DelFile/>
                          </p>
                          <input
                            type="file"
                            id="videoUrl"
                            onChange={(e) =>
                              (onChange(e), addVideoUrl(e))
                            }
                            accept="video/*"
                          />
                          <label htmlFor="videoUrl">파일 첨부</label>
                        </AddFileWrapper>
                      }
                    />
                    <NoticeWrapper margin='4px 0 0 0'>
                      <span>동영상 권장크기 : 30MB 이하</span>
                    </NoticeWrapper>
                  </Wrapper>
                </ContentWrapper>
                <ContentWrapper title='Berry Ranking' Subheading maxWidth='100%'/>

                <ContentWrapper title="등록 여부" Border={!watch().surveyEnabled} Padding>
                  <Wrapper width="140px" margin="0 24px 0 0" Radio>
                    <Controller
                      name="surveyEnabled"
                      control={control}
                      render={({field: {onChange, value}}) =>
                        <>
                          <input
                            type="radio"
                            id="berryRanking"
                            name="surveyEnabled"
                            value="true"
                            checked={watch().surveyEnabled}
                            onChange={(e) => onChange(true)}
                          />
                          <label htmlFor="berryRanking">등록 함</label>
                        </>
                      }
                    />
                  </Wrapper>
                  {berryRankingState ||
                    <Wrapper width="140px" Radio>
                      <Controller
                        name="surveyEnabled"
                        control={control}
                        render={({field: {onChange, value}}) =>
                          <>
                            <input
                              type="radio"
                              id="publicRanking"
                              name="surveyEnabled"
                              value="false"
                              checked={!watch().surveyEnabled}
                              onChange={(e) => onChange(false)}
                            />
                            <label htmlFor="publicRanking">등록 안함</label>
                          </>
                        }
                      />
                    </Wrapper>}
                </ContentWrapper>

                {watch().surveyEnabled &&
                  <>
                    {isView &&
                      <>
                        <ContentWrapper title={"랭킹 상태"} Padding>
                          <Controller
                            name="berryStatus"
                            control={control}
                            render={({field: {onChange}}) =>
                              <Select
                                components={DropdownIndicator}
                                styles={SelectCustomMediumStyles}
                                options={BERRY_RANKING_STATUS_OPTIONS}
                                placeholder={STATUS[watch().berryStatus]}
                                onChange={selectedOption => {
                                  onChange(selectedOption.value);
                                }}
                                isSearchable={false}
                              />
                            }
                            // rules={{required: true}}
                          />
                        </ContentWrapper>
                        <ContentWrapper title={"Berry Ranking 등록일"} Padding>
                          <Content>
                            <Wrapper Center>
                              <span>{watch().berryCreatedAt}</span>
                            </Wrapper>
                          </Content>
                        </ContentWrapper>
                      </>
                    }
                    <ContentWrapper title="설문 참여 기간" Padding>
                      <Content>
                        <Controller
                          name="openAt"
                          control={control}
                          render={({field: {value, onChange}}) =>
                            <div>
                              <StyledDatePicker
                                Open
                                id="openAt"
                                dateFormat="yyyy-MM-dd-hh:mm aa"
                                selected={value}
                                onChange={(date, e) => {
                                  onChange(date, e);
                                  onOpenSelected(date)
                                }}
                                minDate={openAt ? new Date(openAt) : new Date()}
                                placeholderText="YYYY-MM-DD-HH:MM"
                                locale={ko}
                                showTimeSelect
                                shouldCloseOnSelect={true}
                                // closeOnScroll={false}
                                filterTime={filterPassedTime}
                                renderCustomHeader={(params) =>
                                  <CustomHeader {...params} />}
                              />
                            </div>
                          }
                        />

                        <ListRspan date><img src={ArrowRight} alt=""/></ListRspan>
                        <Controller
                          name="closeAt"
                          control={control}
                          render={({field: {value, onChange}}) =>
                            <div>
                              <StyledDatePicker
                                Close
                                id="closeAt"
                                dateFormat="yyyy-MM-dd-hh:mm aa"
                                selected={value}
                                onChange={(date, e) => {
                                  onChange(date, e);
                                  onCloseSelected(date)
                                }}
                                minDate={openSelected === null ? new Date() : openSelected}
                                placeholderText="YYYY-MM-DD-HH:MM"
                                locale={ko}
                                showTimeSelect
                                shouldCloseOnSelect={true}
                                // closeOnScroll={false}
                                filterTime={filterEndAtTime}
                                renderCustomHeader={(params) =>
                                  <CustomHeader {...params} />}
                              />
                            </div>
                          }
                        />
                      </Content>
                    </ContentWrapper>
                    {isView &&
                      <ContentWrapper Padding title={"설문 참여자"}>
                        <Content>
                          <Wrapper>
                            <ListRspan disabled={!watch().isPaid}>
                              <span>{participantsCount ? participantsCount : 0}</span>
                              <ListRtxtSubNum>명</ListRtxtSubNum>
                              <GrayButton type='button' short
                                          margin='0 0 0 24px'
                                          id='participantsCount'
                                          onClick={openDetailModal}>상세내역</GrayButton>
                            </ListRspan>
                          </Wrapper>
                        </Content>
                      </ContentWrapper>
                    }
                    <ContentWrapper title='투표 보상 ' Subheading Padding maxWidth='100%'/>
                    <ContentWrapper title="보상 지급 여부" Padding>
                      <Content PollType>
                        <Wrapper width="140px" margin="0 24px 0 0" Radio>
                          <Controller
                            name="isPaid"
                            control={control}
                            render={({field: {onChange, value}}) =>
                              <>
                                <input
                                  type="radio"
                                  id="yes_reward"
                                  value="true"
                                  checked={watch().isPaid}
                                  onChange={e => (
                                    onChange(true)
                                  )}
                                />
                                <label htmlFor="yes_reward">지급</label>
                              </>
                            }
                          />
                        </Wrapper>
                        <Wrapper width="140px" margin="0 24px 0 0" Radio>
                          <Controller
                            name="isPaid"
                            control={control}
                            render={({field: {onChange, value}}) =>
                              <>
                                <input
                                  type="radio"
                                  id="no_reward"
                                  value="false"
                                  checked={!watch().isPaid}
                                  onChange={e => (
                                    onChange(false)
                                  )}
                                />
                                <label htmlFor="no_reward">지급 안함</label>
                              </>
                            }
                          />
                        </Wrapper>
                      </Content>
                    </ContentWrapper>
                    <ContentWrapper Padding title={"보상 단위"}>
                      <Content>
                        <Wrapper disabled={!watch().isPaid}>
                          <ListRspan Rel>
                            <RewardAmountWrapper disabled={!watch().isPaid}>
                              <ListInputBerry disabled={!watch().isPaid}
                                              type="number"
                                              maxLength="4"
                                              id="paidUnit"
                                              placeholder="0"
                                              onKeyDown={handleBizNumberKeyPress}
                                              {...register("paidUnit", {

                                                // onChange: handleChangeRewardNumber,
                                              })}
                              />
                              <ListRtxtSubNum berry In>Berry</ListRtxtSubNum>
                            </RewardAmountWrapper>
                          </ListRspan>
                        </Wrapper>
                      </Content>
                    </ContentWrapper>
                    {rankingDetailId &&
                      <>
                        <ContentWrapper title={"보상 현황"} Border={!watch().surveyEnabled} Padding>
                          <Content>
                            <Wrapper>
                              <ListRspan disabled={!watch().isPaid}>
                                <span>{paidAmount ? paidAmount : 0}</span>
                                <ListRtxtSubNum>Berry</ListRtxtSubNum>
                                <GrayButton type='button' short
                                            margin='0 0 0 24px'
                                            id='reward'
                                            onClick={openDetailModal}>상세내역</GrayButton>
                              </ListRspan>
                            </Wrapper>
                          </Content>
                        </ContentWrapper>
                      </>
                    }
                  </>
                }
                {(watch().berryStatus === '' || !isView) &&
                  <>
                    <ContentWrapper title="랭킹 순위" Subheading="Subheading">
                      <NoticeWrapper Recommend>
                        <NoticeSub>
                          이미지 권장크기 및 확장파일 : 1000 x 1000 (윈도 대상
                          750 x 1000) / jpg, jpeg, png, bmp
                        </NoticeSub>
                      </NoticeWrapper>
                    </ContentWrapper>
                    {watch().items.map((item, index) => {
                      const title = `${index + 1}위`;
                      return (
                        <>
                          <ContentWrapper key={index} title={title} Padding>
                            <div
                              style={{
                                width: "86px",
                                height: "86px",
                                marginRight: "10px",
                                backgroundColor: "#f5f5f5"
                              }}
                            >
                              {(item.imageUrl || item.url) &&
                                <img style={{width: "100%", height: "100%"}}
                                     src={item.imageUrl && item.imageUrl.includes('data:') ? item.imageUrl : item.url !== '' && `${domain}${item.url}`}
                                     alt={item.url !== '' ? `itemImage` : ''}
                                />
                              }
                            </div>
                            <Wrapper Column maxWidth="485px">
                              <Controller
                                name={`items[${index}].text`}
                                control={control}
                                render={({field: {onChange, value}}) => {
                                  return (
                                    <Wrapper margin="0 0 8px 0">
                                      <RankingTextInput
                                        type="text"
                                        id={index}
                                        value={value}
                                        // onChange={(e) => onChange(e.target.value)}
                                        onChange={(e) => (onChangeItemText(index), onChange(e.target.value))}
                                      />
                                    </Wrapper>
                                  )
                                }}
                              />
                              <Controller
                                name={`items[${index}].url`}
                                control={control}
                                render={({field: {onChange, value}}) => {
                                  return (
                                    <>
                                      <AddFileWrapper width="370px"
                                                      justifyContent='unset'>
                                        <div style={{position: 'relative'}}>
                                          <p>{item.name}</p>
                                          <ItemDeleteButton type="button"
                                                            itemDel
                                                            onClick={(e) => {
                                                              removeItemImage(e, index)
                                                            }}
                                          >
                                            <img src={DelIconBig}
                                                 alt='deleteBtn'/>
                                          </ItemDeleteButton>
                                        </div>
                                        <input
                                          type="file"
                                          accept="image/jpg, image/jpeg, image/png, image/bmp"
                                          id={`items[${index}].url`}
                                          onChange={(e,) => (
                                            onChange(e), onChangeImage(e, index)
                                          )}
                                        />
                                        <label
                                          htmlFor={`items[${index}].url`}>
                                          파일첨부
                                        </label>
                                      </AddFileWrapper>
                                    </>
                                  )
                                }}
                              />
                              <div>{errors?.rankingItems?.length > 0 && errors?.rankingItems[index]?.url?.message && `*${errors?.rankingItems[index]?.url?.message}`}</div>
                            </Wrapper>
                            <Wrapper width='40px' margin='0 0 0 16px'>
                              <RemoveRankingItemButton type="button"
                                                       onClick={() => {
                                                         handleClickRemoveRanking(index)
                                                       }}>
                                <img src={DelIconBig} alt='deleteBtn'/>
                              </RemoveRankingItemButton>
                            </Wrapper>
                          </ContentWrapper>
                        </>
                      )
                    })}
                    {contentsAuth.some(d => d === "W") &&
                      <ButtonWrapper AddItems>
                        <Button short type="button"
                                onClick={addRankingData} {...(rankingItemsFields.length === RANKING_ITEM_MAX_LENGTH && {disabled: true})}>
                          <img src={AddIcon} alt="항목 추가"/>
                          항목 추가 ({rankingItemsFields.length}/{RANKING_ITEM_MAX_LENGTH})
                        </Button>
                      </ButtonWrapper>
                    }
                  </>
                }
                {(watch().berryStatus !== '' && isView) &&
                  <>
                    <ContentWrapper Column>
                      <RankingResult items={watch().items} domain={domain}
                                     participantsCount={participantsCount}
                                     surveyEnabled={surveyEnabled}/>
                    </ContentWrapper>
                  </>
                }
              </PollSettingContentContainer>
            </Wrapper>
          </ContentSection>
          {contentsAuth.some(d => d === "W") &&
            <Wrapper Right margin="48px 0 0 0 ">
              {(isView && watch().surveyEnabled) &&
                <Button mini line margin='0 20px 0 0'
                        onClick={openCompleteReasonModal}
                        type='button'
                >
                  수동 완료
                </Button>
              }
              <Button type="submit" mini disabled={isSubmitting}>
                {isView ? "수정" : "등록"}
                하기
              </Button>
            </Wrapper>
          }
        </form>
      </Wrapper>
      <Wrapper width="auto">
        {/* 미리보기 */}
        <Preview title={watch().title}
                 startAt={watch().startAt && dayjs(new Date(watch().startAt)).format(DATE.FORMAT.LOCAL_KR)}
                 endAt={watch().endAt && dayjs(new Date(watch().endAt)).format(DATE.FORMAT.LOCAL_KR)}
                 source={watch().sourceBy}
                 items={watch().items}
                 videoUrl={watch().videoUrl && watch().videoUrl.src ? watch().videoUrl.src : `${domain}${watch().videoUrl}`}
          // categoryName={watch().categoryId ? categoryList.filter((item) => item.id === watch().categoryId)[0].label : null}
                 categoryName={watch().categoryId && categoryList ? categoryList.filter((item) => item.id === watch().categoryId)[0].label : null}
                 domain={domain}
                 surveyEnabled={watch().surveyEnabled}/>
      </Wrapper>
      {onRewardModal &&
        <RewardModal closeDetailModal={closeDetailModal}
                     request={rankingRewardListRequest}/>
      }
      {onParticipationCountModal &&
        <ParticipationCountModal closeDetailModal={closeDetailModal}
                                 request={participationListRequest}/>
      }
      {onCompleteModal &&
        <StyledModal>
          <ModalContainer stop>
            <RewardDetailTitle>수동 완료</RewardDetailTitle>
            <RewardDetailContent>
              <span>{title}</span>
              <Wrapper Column>
                <ModalText TextCenter margin='0 0 6px 0'>아래에 수동 완료 사유를 입력해주세요.</ModalText>
                <StyledTextArea {...register('reasonForComplete', {})}/>
                <Wrapper>
                  <ModalText lengthLimit margin='4px 0 0 auto'>
                    {watch().reasonForComplete ? watch().reasonForComplete.length : 0}/500</ModalText>
                </Wrapper>
              </Wrapper>
            </RewardDetailContent>
            <Button mini margin='48px 0 0 0' type='button' onClick={patchCompleteReason}>확인</Button>
            <CloseButton type='button' onClick={closeCompleteReasonModal}>
              <img src={CloseIcon} alt='close'/>
            </CloseButton>
          </ModalContainer>
        </StyledModal>
      }
    </Wrapper>
  );
}

export default CreateRanking;
