import styled, {css} from "styled-components";

import SearchIconGray from "asset/img/icon__search_gray.png"
import CheckDone from "asset/img/icon__done.png"
import SettingIconActive from "asset/img/icon__settings_active.png"
import SettingIcon from "asset/img/icon__settings.png"

// 검색박스
export const SearchCont = styled.div`
    //position: relative;
    display: flex;
    justify-content: space-between;
    margin: ${(props) => props.margin || '48px  0 0 0'};
    //margin: 48px 0 0;


    clear: both;
`;

export const SearchBox = styled.div`
    width: 504px;
    display: inline-block;

    ${(props) =>
            props.reward &&
            css`
                width: 485px;
                float: right;

                div {
                    width: 373px;
                }

                input {
                    background-color: #f5f5f5;
                }

                button {
                    margin-left: 12px;
                }
            `}
`;
export const SearchDiv = styled.div`
    width: 380px;
    height: 36px;
    float: left;
    position: relative;

    &::before {
        content: "";
        background-image: url(${SearchIconGray});
        width: 24px;
        height: 24px;
        background-size: cover;
        position: absolute;
        left: 8px;
        top: 6px;
    }
`;
export const SearchInput = styled.input`
    padding: 8px 8px 8px 40px;
    border: 1px solid #dbdbdb;
    //background-color:;
    border-radius: 8px;
    width: 100%;
    height: 100%;

    &::placeholder {
        color: #1a1a1a;
        font-size: 14px;
    }
`;

export const SearchBtn = styled.button`
    width: 100px;
    height: 36px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background-color: #dbdbdb;
    color: #1a1a1a;
    border-radius: 8px;
    margin-left: 24px;
`;

export const SearchSort = styled.div`
    //position: absolute;
    //right: 0;
    //top: 3px;
    ${(props) =>
            props.filter &&
            css`
                position: relative;
                display: inline-block;
            `}
    ${(props) =>
            props.Filter &&
            css`
                position: relative;
                display: inline-block;
            `}
`;
export const SearchSortUl = styled.ul`

`;
export const SearchSortLi = styled.li`
    float: left;
    cursor: pointer;
    font-size: 14px;
    color: #424242;


    ${(props) =>
            props.on &&
            css`
                position: relative;
                color: #000065;
                font-weight: 500;

                &::before {
                    content: "";
                    position: absolute;
                    left: -24px;
                    top: -1px;
                    background-image: url(${CheckDone});
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                }
            `}
    & + & {
        margin-left: 24px;
    }

    ${(props) =>
            props.On &&
            css`
                position: relative;
                color: #000065;
                font-weight: 500;

                &::before {
                    content: "";
                    position: absolute;
                    left: -24px;
                    top: -1px;
                    background-image: url(${CheckDone});
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                }
            `}
    & + & {
        margin-left: 24px;
    }
`;


export const ShowListOption = styled.button`
    font-size: 14px;
    font-weight: 500;
    color: #424242;
    position: absolute;
    top: 6px;
    right: 0;

    &:before {
        content: "";
        position: absolute;
        left: -28px;
        top: -1px;
        background-image: url(${SettingIcon});
        width: 24px;
        height: 24px;
        background-size: cover;
    }

    &:hover {
        color: #000065;

        &::before {
            background-image: url(${SettingIconActive});
        }
    }

    ${(props) =>
            props.on &&
            css`
                color: #000065;

                &::before {
                    background-image: url(${SettingIconActive});
                }
            `}
`;
