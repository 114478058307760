import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useRequest from "hooks/useRequest";
import {API_STATE} from "common/constants/state";
import {UPLOAD_IMAGE_BASE_URL} from "common/constants/env";
import {
    BackBtn,
    Button,
    ButtonRight,
    CloseButton,
    List,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    SubjectList,
    SubjectListBlock,
    SubjectListBox,
    SubjectListSpan,
    Title
} from "common/styledComponents/commonStyle";
import {BarBox, BarGraph, BarNumber, BarPercent, BarPerson} from "pages/Temp/Poll_Ad_006/pollad006Sytle";
import {MenuInfoSub, MenuInfoSubRight} from "pages/Mypage/MypageStyle"
import {BIG_DATA_SEARCH_LIST} from "common/constants/bigData"
import {
    BigDiv,
    BigPollCountBox,
    BigPollCountBtn,
    BigPollCountName,
    BigPollCountNum,
    BigPollCountUnit,
    BigPollLi,
    BigPollListTxt,
    BigPollStateLi,
    BigPollStateUl,
    BigPollSubject,
    BigPollTxt,
    BigPollUl,
    BigResultBox,
    BigSearchTxt,
} from "./BigdataStyle";

import ImgBack2 from "asset/img/img__priview.png"
import Table from '../../common/components/Table';
import {SURVEY_PARTICIPATION_COUNT} from '../../common/constants/channel';
import Page from '../../common/components/Page';
import CloseIcon from '../../asset/img/icon__close_black.svg';
import pageMaker from '../../common/components/Page/pageMaker';
import useBoard from '../../hooks/useBoard';
import dayjs from 'dayjs';
import DATE from '../../common/constants/date';

const Bigdata = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [puzzleInfos, setPuzzleInfos] = useState([]);
    const [clicked, setClicked] = useState([]);
    const {id} = params;
    const bigdataRequest = useRequest("post", `poll/${id}/data`);
    const platformDetailRequest = useRequest("get", `poll/${id}`);
    const [detailInfo, setDetailInfo] = useState();
    const [detailResultInfo, setDetailResultInfo] = useState();
    const [participantsCount, setParticipantsCount] = useState(false);
    const [keywords, setKeywords] = useState();
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({offset: 0, limit: 10});
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const [onParticipationDetailModal, setOnParticipationDetailModal] = useState(false);       // 이미지 업로드용 state
    const request = useRequest("get", `poll/${15}/reward/list`);
    const {rows, fetch} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
        }));
    });


    useEffect(() => {
        platformDetailRequest.call()
    }, []);

    useEffect(() => {
        const {offset, limit} = formData;

        fetch({
            ...params,
            offset,
            limit
        });
    }, [formData]);

    useEffect(() => {
        switch (platformDetailRequest.state) {
            case API_STATE.done:
                if (platformDetailRequest.response.success) {
                    const {response} = platformDetailRequest.response;

                    if (!response) {
                        return;
                    }
                    setDetailInfo({
                        title: response.title,
                        status: response.status,
                        participantsCount: response.participantsCount
                    })
                }

                break;
            default:
                break;
        }
    }, [platformDetailRequest.state, platformDetailRequest.error, platformDetailRequest.response]);


    const handleItemClick = (title, op, index) => {

        const tmpForm = {
            title: title,
            options: [op]
        }
        setPuzzleInfos([tmpForm]);
        if (clicked.includes(index)) {
            setClicked(clicked.filter(c => c !== index));
        } else {
            setClicked([...clicked, index])
        }

    }

    const handleGetBigData = () => {
        const newForm = BIG_DATA_SEARCH_LIST.map(item => {
            return {
                title: item.title,
                options: item.options.filter(t => clicked.includes(t))
            }
        })

        bigdataRequest.call({puzzleInfos: newForm});
    }

    const openRewardModal = () => {
        setOnParticipationDetailModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeRewardModal = () => {
        setOnParticipationDetailModal(false)
        document.body.style.overflow = 'auto';
    }

    const handlePage = (pageNum) => {
        setCurPage(pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
    }

    useEffect(() => {
        switch (bigdataRequest.state) {
            case API_STATE.done:
                if (bigdataRequest.response.success) {
                    const {response} = bigdataRequest.response;
                    if (!response) {
                        return;
                    }
                    console.log("response >  ", response)
                    setDetailResultInfo(response.questions)
                    setParticipantsCount(response.participantsCount)
                    setKeywords(response.keywords)
                }

                break;
            default:
                break;
        }
    }, [bigdataRequest.state, bigdataRequest.error, bigdataRequest.response]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>
                        <BackBtn onClick={() => navigate(-1)}/>빅데이터 결과 상세보기
                    </Title>
                    <BigDiv>
                        <BigPollSubject>“{detailInfo?.title}” 설문조사 결과 대시보드</BigPollSubject>
                        <BigPollTxt>참여자의 상태에 따라 데이터를 필터링하거나 검색 할 수 있습니다.</BigPollTxt>
                        <BigPollCountBox>
                            <BigPollCountName>설문 참여 인원</BigPollCountName>
                            <BigPollCountNum>{detailInfo?.participantsCount}</BigPollCountNum>
                            <BigPollCountUnit>명</BigPollCountUnit>
                            <BigPollCountBtn onClick={openRewardModal}>상세내역</BigPollCountBtn>
                        </BigPollCountBox>
                    </BigDiv>
                    <BigDiv>
                        <BigSearchTxt><strong>검색 조건 설정</strong>조회를 원하시는 참여자의 상태를 선택해주세요.</BigSearchTxt>

                        <BigPollUl>
                            {BIG_DATA_SEARCH_LIST.map((list, index) => {
                                return (
                                    <BigPollLi key={index}>
                                        <BigPollListTxt>{list.title}</BigPollListTxt>
                                        <BigPollStateUl>
                                            {list.options.map((op, i) => {
                                                const keypoint = op;
                                                if (clicked.includes(keypoint)) {
                                                    return <BigPollStateLi on key={keypoint}
                                                                           onClick={() => handleItemClick(list.title, op, keypoint)}>{op}</BigPollStateLi>
                                                } else {
                                                    return <BigPollStateLi key={keypoint}
                                                                           onClick={() => handleItemClick(list.title, op, keypoint)}>{op}</BigPollStateLi>
                                                }
                                            })}
                                        </BigPollStateUl>
                                    </BigPollLi>
                                )
                            })}

                        </BigPollUl>
                        <ButtonRight bigdata>
                            <Button bigdata onClick={() => handleGetBigData()}>조회하기</Button>
                        </ButtonRight>
                    </BigDiv>

                    {detailResultInfo && detailResultInfo.length > 0 &&
                        <BigDiv>
                            <BigResultBox>
                                <MenuInfoSub style={{minWidth: "140px", display: "inline-block"}}>검색 결과</MenuInfoSub>
                                <MenuInfoSubRight>선택한 조건값에 따른 결과입니다.</MenuInfoSubRight>
                                {detailResultInfo && detailResultInfo.map((item, index) => {
                                    return (<>
                                        <MenuInfoSub key={index}>질문 {index + 1}</MenuInfoSub>
                                        <List wide>
                                            <ListUl>
                                                <ListLi>
                                                    <ListLtxt top>질문 제목</ListLtxt>
                                                    <ListRtxt Max>{item?.title}</ListRtxt>
                                                </ListLi>
                                                <ListLi>
                                                    <ListLtxt>답변 형태</ListLtxt>
                                                    <ListRtxt>{item?.answerType === "SINGULAR" ? '단일 선택' : '복수 선택'}</ListRtxt>
                                                    {item?.answerType !== "SINGULAR" &&
                                                        <ListRtxt Notice>
                                                            {`최소 ${item.minimum}개 ~ 최대 ${item.maximum}개 선택`}
                                                        </ListRtxt>
                                                    }
                                                </ListLi>
                                                <ListLi>
                                                    <ListLtxt top>항복 구분</ListLtxt>
                                                    <ListRtxt>
                                                        {item.itemType === "TEXT" ? '텍스트형' : '이미지형'}
                                                    </ListRtxt>
                                                    <SubjectListBox>
                                                        {item.items.map((subItem, index) => {
                                                            const percent = `${(isNaN((subItem.selectionCount / participantsCount * 100)) ? 0 : subItem.selectionCount / participantsCount * 100).toFixed(2) || 0}%`;
                                                            return (
                                                                <>
                                                                    {item?.itemType !== "TEXT" ? (
                                                                        <SubjectList key={index}>
                                                                            <SubjectListSpan bigimg>
                                                                                <img
                                                                                    src={subItem.url.includes('amazonaws') ? subItem.url : UPLOAD_IMAGE_BASE_URL + subItem.url || ImgBack2}
                                                                                    alt="img"
                                                                                />
                                                                            </SubjectListSpan>
                                                                            <SubjectListBlock imgnew>
                                                                                <SubjectListBlock>항목 {index + 1}.</SubjectListBlock>
                                                                                <SubjectListBlock short>
                                                                                    {subItem.text}
                                                                                </SubjectListBlock>
                                                                                <SubjectListBlock Rel>
                                                                                    <BarBox img>
                                                                                        {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                                                        {/* first 추가시 1등 */}
                                                                                        <BarGraph first
                                                                                                  style={{width: percent}}/>
                                                                                    </BarBox>
                                                                                    <BarNumber>
                                                                                        <BarPercent>{percent}</BarPercent>
                                                                                        <BarPerson>{subItem.puzzleSelectionCount.toLocaleString()}명</BarPerson>
                                                                                    </BarNumber>
                                                                                </SubjectListBlock>
                                                                            </SubjectListBlock>
                                                                        </SubjectList>
                                                                    ) : (
                                                                        <SubjectList key={index}>
                                                                            <SubjectListBlock>항목 {index + 1}.</SubjectListBlock>
                                                                            <SubjectListBlock
                                                                                short>{subItem.text}</SubjectListBlock>
                                                                            <SubjectListBlock Rel>
                                                                                <BarBox>
                                                                                    {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                                                    {/* first 추가시 1등 */}
                                                                                    <BarGraph first
                                                                                              style={{width: percent}}/>
                                                                                </BarBox>
                                                                                <BarNumber>
                                                                                    <BarPercent>{percent}</BarPercent>
                                                                                    <BarPerson>{subItem.selectionCount.toLocaleString()}명</BarPerson>
                                                                                </BarNumber>
                                                                            </SubjectListBlock>
                                                                        </SubjectList>
                                                                    )}

                                                                </>
                                                            )
                                                        })}
                                                    </SubjectListBox>

                                                </ListLi>
                                            </ListUl>
                                        </List>
                                    </>)
                                })}
                            </BigResultBox>
                        </BigDiv>}
                    {/* {detailInfo?.status !== "DONE" && <BigDiv>
                     <BigPollNot>설문이 아직 완료되지 않았어요.</BigPollNot>
                     </BigDiv>}                     */}
                    {keywords && keywords?.length > 0 && <BigDiv>
                        <BigSearchTxt><strong>관심사 키워드</strong>이 설문에 참여한 참여자의 관심사 키워드입니다.</BigSearchTxt>
                        <BigPollStateUl six>
                            {keywords?.map((item, index) => {
                                return (
                                    <BigPollStateLi key={index}>#{item?.name}</BigPollStateLi>
                                )
                            })}

                        </BigPollStateUl>
                    </BigDiv>}
                </MenuInfoArea>
            </MenuArea>
            {onParticipationDetailModal &&
                <StyledModal>
                    <ModalContainer Reward>
                        <RewardDetailTitle>설문 참여 회원</RewardDetailTitle>
                        <span style={{margin: '38px 0 0 auto'}}>{`총 참여 인원 ${totalNum}명`}</span>
                        <RewardDetailContent>
                            <Table Member
                                   pageData={formData}
                                   totalPages={pageInfo?.totalPages}
                                   columns={SURVEY_PARTICIPATION_COUNT}
                                   rows={rows}
                                   totalNum={totalNum}
                                   searchParams={searchParams}
                            />
                            <Page pageInfo={pageInfo} handlePage={handlePage}/>
                        </RewardDetailContent>
                        <Button mini onClick={closeRewardModal} margin='48px 0 0 0'>확인</Button>
                        <CloseButton onClick={closeRewardModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }

        </>
    )
}

export default Bigdata;
