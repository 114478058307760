import React, {useEffect, useState} from "react";
import useRequest from "hooks/useRequest";
import {
    IconImageWrapper,
    KeyInputBox,
    MnemonicKeyCont,
    MnemonicKeyDiv,
    MnemonicKeyErrorMsg,
    MnemonicKeyInputLong,
    MnemonicKeyLi,
    MnemonicKeyTxt,
    MnemonicKeyUl,
    MyPagePasswordInput,
    Wrapper
} from "./MypageStyle";
import {
    MenuArea,
    MenuInfoArea,
    PopBox,
    PopButton,
    PopButtonBox,
    PopClose,
    PopHeadTxt,
    PopTxt,
    PopUp,
    Title
} from "common/styledComponents/commonStyle";
import {API_STATE} from "common/constants/state";

import Eye from "asset/img/icon__visibility.png"
import EyeOff from "asset/img/icon__visibility_off.png"
import CloseIcon from "asset/img/icon__close.png";
import MypageBiz from './MypageBiz';
import MypageAdm from './MypageAdm';
import {useForm} from 'react-hook-form';


const Mypage = () => {
    const myPageRequest = useRequest("get", "user/me");
    const [myInfo, setMyInfo] = useState({});
    const [pwModal, setPwModal] = useState(true);
    const [nemoModal, setNemoModal] = useState(true);
    const {register, handleSubmit, watch, setValue, control, reset} = useForm();

    const [passwordState, setPasswordState] = useState({
        prevPassword: false,
        curPassword: false,
        curPasswordCheck: false,
        mnemonicKeyHide: false,
    });

    const MnemonicData = [
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
        {text: ''},
    ]

    // const {formState: {errors}} = useForm()

    const onSubmit = (e, data) => {
        e.preventDefault()
        // .call({...data, name: myInfo.name});
    };

    const handlePasswordState = (type) => {
        // console.log(type)/**/
        if (type === 'prevPassword') {
            setPasswordState(prevState => ({...prevState, prevPassword: !prevState.prevPassword}))
        } else if (type === 'curPassword') {
            setPasswordState(prevState => ({...prevState, curPassword: !prevState.curPassword}))
        } else if (type === 'curPasswordCheck') {
            setPasswordState(prevState => ({...prevState, curPasswordCheck: !prevState.curPasswordCheck}))
        } else if (type === 'MnemonicKey') {
            setPasswordState(prevState => ({...prevState, mnemonicKeyHide: !prevState.mnemonicKeyHide}))
        }
    }

    const closePop = (e) => {
        const id = e.currentTarget.id

        if (id === 'passwordPopUp') {
            setPwModal(true)
            reset({
                curPassword: '',
                curPasswordCheck: '',
            })
        } else if (id === 'mnemonicKeyPopUp') {
            setNemoModal(true)
            reset({
                mnemonicKeyPassword: ''
            })
        }
    }

    useEffect(() => {
        myPageRequest.call()
    }, []);

    useEffect(() => {
        switch (myPageRequest.state) {
            case API_STATE.done:
                if (myPageRequest.response.success) {
                    setMyInfo(myPageRequest.response.response)
                }

                break;
            default:
                break;
        }
    }, [myPageRequest.state, myPageRequest.error, myPageRequest.response]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>마이 페이지</Title>
                    <h1 hidden={true}>my page</h1>
                    {myInfo.role === "ROLE_SUPER" || myInfo.role === "ROLE_ADMIN" ?
                        <MypageAdm  {...{myInfo, setPwModal, setNemoModal}}/> :
                        <MypageBiz {...{myInfo, setPwModal, setNemoModal}} />
                    }
                    {/* {
                     myInfo.role === "ROLE_BIZZ" &&
                     <ButtonRight>
                     <Button>채널 개설하기</Button>
                     </ButtonRight>
                     }                                          */}
                </MenuInfoArea>
            </MenuArea>

            <form onSubmit={(e, data) => onSubmit(e, data)}>
                <PopUp hidden={pwModal}>
                    <PopBox my>
                        <PopClose id='passwordPopUp'
                                  onClick={closePop}>
                            <img src={CloseIcon} alt=''/>
                        </PopClose>
                        <PopHeadTxt>비밀번호 변경하기 (Private Key)</PopHeadTxt>
                        {(myInfo.role === "ROLE_SUPER" || myInfo.role === "ROLE_ADMIN") &&
                            <div>
                                <PopTxt left>비밀번호를 변경하기 위해 니모닉키 입력이 필요합니다.</PopTxt>
                                <MnemonicKeyCont>
                                    <MnemonicKeyUl>
                                        {MnemonicData.map((mnemonic, index) =>
                                            <MnemonicKeyLi key={index}>
                                                <MnemonicKeyTxt>{mnemonic.text}</MnemonicKeyTxt>
                                            </MnemonicKeyLi>
                                        )}
                                    </MnemonicKeyUl>
                                    <MnemonicKeyErrorMsg>*일치하는 정보가 없습니다.</MnemonicKeyErrorMsg>
                                </MnemonicKeyCont>
                            </div>
                        }
                        {(myInfo.role === "ROLE_SUPER" || myInfo.role === "ROLE_ADMIN") ||
                            <KeyInputBox password>
                                <span>현재 비밀번호를 입력해주세요.</span>
                                <Wrapper Relative>
                                    <MyPagePasswordInput type={passwordState.prevPassword ? 'text' : 'password'}
                                                         id='prevPassword'
                                                         placeholder="비밀번호를 입력해주세요."/>
                                    <IconImageWrapper onClick={() => handlePasswordState('prevPassword')}>
                                        <img src={passwordState.prevPassword ? Eye : EyeOff} alt="password"/>
                                    </IconImageWrapper>
                                </Wrapper>
                            </KeyInputBox>
                        }
                        <KeyInputBox Admin>
                            {(myInfo.role === "ROLE_SUPER" || myInfo.role === "ROLE_ADMIN") ||
                                <span>새로운 비밀번호를 입력해주세요.</span>
                            }
                            <Wrapper Relative>
                                <MyPagePasswordInput type={passwordState.curPassword ? 'text' : 'password'}
                                                     id='curPassword'
                                                     placeholder="비밀번호를 입력해주세요."
                                                     {...register('curPassword',)}
                                />
                                <IconImageWrapper onClick={() => handlePasswordState('curPassword')}>
                                    <img src={passwordState.curPassword ? Eye : EyeOff} alt="password"/>
                                </IconImageWrapper>
                            </Wrapper>
                        </KeyInputBox>
                        <KeyInputBox>
                            <Wrapper Relative>
                                <MyPagePasswordInput
                                    type={passwordState.curPasswordCheck ? 'text' : 'password'}
                                    id='curPasswordCheck'
                                    placeholder="비밀번호를 재입력하여 확인해주세요."
                                    {...register('curPasswordCheck',)}
                                />
                                <IconImageWrapper onClick={() => handlePasswordState('curPasswordCheck')}>
                                    <img src={passwordState.curPasswordCheck ? Eye : EyeOff} alt="password"/>
                                </IconImageWrapper>
                            </Wrapper>
                        </KeyInputBox>
                        {watch().curPassword !== watch().curPasswordCheck &&
                            <MnemonicKeyErrorMsg>*입력하신 정보가 일치하지 않습니다.</MnemonicKeyErrorMsg>
                        }
                        {myInfo.role === "ROLE_SUPER" || myInfo.role === "ROLE_ADMIN" ?
                            <PopButton Admin>변경하기</PopButton> :
                            <PopButton Admin>확인</PopButton>
                        }
                    </PopBox>
                </PopUp>

                {/* <PopUp>
                 <PopBox my>
                 <PopClose><img src={CloseIcon} alt='' /></PopClose>
                 <PopHeadTxt>비밀번호 변경하기 (Private Key)</PopHeadTxt>
                 <PopTxt pw>비밀번호 변경이 완료되었습니다.</PopTxt>
                 <PopButton center>확인</PopButton>
                 </PopBox>
                 </PopUp> */}


                <PopUp hidden={nemoModal}>
                    <PopBox nick>
                        <PopClose
                            id='mnemonicKeyPopUp'
                            // onClick={() => setNemoModal(true)}
                            onClick={closePop}
                        >
                            <img src={CloseIcon} alt='closeIcon'/>
                        </PopClose>
                        <PopHeadTxt>니모닉키</PopHeadTxt>
                        <PopTxt red>Pollberry는 사용자의 니모닉키를 저장하지 않습니다.</PopTxt>
                        <PopTxt left>제공되는 니모닉키를 절대 공개하지 마세요. 이 정보를 알고 있는 사람은 계정에 접근할 수 있습니다.</PopTxt>
                        <KeyInputBox MnemonicKey>
                            <MnemonicKeyDiv short>
                                <MnemonicKeyInputLong
                                    type={passwordState.mnemonicKeyHide ? 'text' : 'password'}
                                    placeholder="비밀번호를 입력해주세요."
                                    {...register('mnemonicKeyPassword', {})}
                                />
                                <IconImageWrapper onClick={() => handlePasswordState('MnemonicKey')}>
                                    <img src={passwordState.mnemonicKeyHide ? Eye : EyeOff} alt=""/>
                                </IconImageWrapper>
                            </MnemonicKeyDiv>
                            <PopButton right>확인</PopButton>
                        </KeyInputBox>
                        <PopTxt>로컬에 저장된 니모닉키 불러오기</PopTxt>
                        <MnemonicKeyCont>
                            <MnemonicKeyUl>
                                {MnemonicData.map((mnemonic, index) =>
                                    <MnemonicKeyLi key={index}>
                                        <MnemonicKeyTxt>{mnemonic.text}</MnemonicKeyTxt>
                                    </MnemonicKeyLi>
                                )}
                            </MnemonicKeyUl>
                        </MnemonicKeyCont>
                        <PopButtonBox>
                            <PopButton half line>니모닉키 복사하기</PopButton>
                            <PopButton half>확인</PopButton>
                        </PopButtonBox>

                    </PopBox>
                </PopUp>
            </form>


            {/* <PopUp>
             <PopBox>
             <PopIcon><img src={CheckCircle} alt='' /></PopIcon>
             <PopTxt>디지털 지갑 생성이 완료되었습니다.</PopTxt>
             <PopTxt>로그인 후 [<span>채널명</span>] 회원님의 채널을 생성하여 투명하고 안전한 Poll을 만드세요.</PopTxt>
             <PopButton wide>확인</PopButton>
             </PopBox>
             </PopUp> */}


            {/* <PopUp>
             <PopBox in>
             <PopClose><img src={CloseIcon} alt=''/></PopClose>
             <PopHeadTxt>블록체인 지갑 주소</PopHeadTxt>
             <PopTxt>pollberry 디지털 지갑은 외우기 어려운 주소가 아닌 채널명 혹은 닉네임으로 디지털 자산을 주고 받을 수 있습니다.</PopTxt>
             <PopInputTxt>
             <PopInput type='text' placeholder="텍스트영역"></PopInput>
             <PopButton right>복사</PopButton>
             </PopInputTxt>
             <PopButton center>확인</PopButton>
             </PopBox>
             </PopUp> */}
        </>
    )
}

export default Mypage;
