import styled, {css} from 'styled-components';


export const DateSearch = styled.div`
  border-bottom: 2px solid #f5f5f5;
  margin-bottom: -24px;
  padding: 24px 0;

`;
export const SearchConditionBox = styled.div`
  //position: relative;
  display: flex;
  align-items: center;
  //justify-content: space-between;

  & + & {
    margin-top: 24px;
  }


  ${(props) =>
          props.reward &&
          css`
            //height: 36px;
          `}

  ${(props) =>
          props.Interval &&
          css`
            justify-content: space-between;
          `}
`;


export const SearchConditionText = styled.span`
  margin-right: 24px;
  font-size: 14px;
  float: left;

  ${(props) =>
          props.top &&
          css`
            line-height: 36px;
          `}

  ${(props) =>
          props.reward &&
          css`
            float: left;
            line-height: 36px;
          `}

`;
export const SearchConditionDiv = styled.div`
  display: flex;
  align-items: center;
  //position: relative;
  //display: inline-block;
  margin-left: 24px;
`;
export const SearchDateBox = styled.div`
  display: flex;
  align-items: center;
  //display: inline-block;
  //float: right;
  margin-left: 24px;

  ${(props) =>
          props.reward &&
          css`
            margin-left: 24px;
          `}
`;
export const SearchDateInputSpan = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  background: #f5f5f5;
  width: 86px;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  //display: inline-block;
  //overflow: hidden;
  padding: 8px 0;
  //float: left;
  position: relative;

  input[type="date"] {
    background: #f5f5f5;
    width: 100%;
    font-size: 14px;
  }
`;
export const SearchDateTxt = styled.span`
  position: absolute;
  //top: 6px;
  //left: 6px;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  font-size: 14px;
  z-index: 0;
`;

export const SearchDateInput = styled.input`
  display: inline-block;
  float: right;
  border-radius: 8px;
  //margin-top: -6px;
  //margin: -6px 0 0 24px;
  //z-index: 5;
`;
export const SearchDateMid = styled.span`
  display: inline-block;
  padding: 0 12px;
  float: left;
  line-height: 36px;
`;

export const BarBox = styled.span`
  width: 100%;
  max-width: 980px;
  height: 40px;
  background-color: #dbdbdb;
  display: inline-block;
  ${(props) =>
          props.img &&
          css`
            max-width: 816px;
          `}

`;

export const BarGraph = styled.span`
  // width: 60%;
  height: 100%;
  background-color: #757575;
  display: inline-block;
  ${(props) =>
          props.first &&
          css`
            background-color: #000065;
          `}
`;
export const BarNumber = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const BarPercent = styled.span`
  display: block;
  text-align: center;
  font-size: 24px;
`;
export const BarPerson = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  margin-top: 8px;
`;

