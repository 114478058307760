import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import {RANKING_LIST_COLUMNS, STATUS} from "common/constants/ranking";
import DATE from "common/constants/date";
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";
import ListSearch from '../../../common/components/ListSearch';

const LankMangeList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        title: queryTitle || '',
    });
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", "ranking/list");

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            // type: TYPE[data.type],
            berryStatus: STATUS[data.berryStatus],
            publicStatus: STATUS[data.publicStatus],
            period: data.openAt && data.closeAt && `${dayjs(new Date(data.openAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.closeAt)).format(DATE.FORMAT.DOT)}`,
        }));
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    const handleSelectRow = (row) => {
        navigate(`/contents/lankDetail/${row.id}?${searchParams.toString()}`);
    }

    const handleSearch = (data) => {
        const {text} = data;
        const {limit} = formData;

        if (text || text === '') {
            searchParams.set('title', text);
            searchParams.set('page', '1')
            setCurPage(1)
        }

        setSearchParams(searchParams)

        fetch({
            title: text || '',
            offset: 0,
            limit
        });
    };

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
        setCurPage(pageNum)
    };

    const columnRenderer = (key, value, _, row) => {
        if (key === "participantsCount") {
            return <span>{row.berryStatus ? value : null}</span>
        }
        return value
    }

    useEffect(() => {
        setSearchParams(searchParams)

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        })

    }, [queryPage, queryTitle]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>
                        랭킹 관리
                        {contentsAuth.find(d => d === "W") &&
                            <Button tit onClick={() => navigate('/contents/lankCreate')}>신규 랭킹 등록하기</Button>
                        }
                    </Title>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={RANKING_LIST_COLUMNS}
                           rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}
                           columnRenderer={columnRenderer}
                    />
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    )
}

export default LankMangeList;
