import styled, { css } from 'styled-components';

//RewardToken상세내역
export const RewardTokenDiv = styled.div` 
    margin: 48px 0;
    border-bottom:2px solid #f5f5f5;
`;
export const RewardTokenTitle = styled.p` 
    font-size:28px;
    font-weight:500;
`;
export const RewardTokenSub = styled.p` 
    margin-top:12px;
    font-size:14px;
    color:#757575;
`;
export const RewardTokenDetail = styled.div` 
    margin: 64px 0 80px;
    text-align:center;
`;
export const RewardTokenDetailNum = styled.span` 
font-size:48px;
font-weight:700;
display:inline-block;
`;
export const RewardTokenDetailTxt = styled.span` 
font-size:18px;
display:inline-block;
margin-left:16px;
`;



