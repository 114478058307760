import {
    Button,
    CloseButton,
    ModalContainer,
    ModalSpan,
    ReportReasonSpan,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Wrapper
} from 'common/styledComponents/commonStyle';
import React from 'react';
import dayjs from 'dayjs';
import DATE from '../../../../common/constants/date';
import CloseIcon from 'asset/img/icon__close_black.svg';


const CancelReasonPopup = ({openCancelRsModal, setOpenCancelRsModal, cancelReasonData, closeCanCelReasonModal}) => {

    return (
        // <PopUp hidden={openCancelRsModal}>
        //     <PopBox Cancel>
        //         <PopHeadTxt>포인트 충전 취소 사유</PopHeadTxt>
        //         <PopClose onClick={() => setOpenCancelRsModal(true)}><img src={CloseIcon} alt=''/></PopClose>
        //         <PopTable Cancel>
        //             <PopTxt>
        //                 <PopLeftTxt left>취소 일자</PopLeftTxt>
        //                 <PopRightTxt right>
        //                     {cancelReasonData && dayjs(new Date(cancelReasonData.createdAt)).format(DATE.FORMAT.DOT)}
        //                 </PopRightTxt>
        //             </PopTxt>
        //             <PopTxt>
        //                 <PopLeftTxt left>충전 포인트</PopLeftTxt>
        //                 <PopRightTxt right>{cancelReasonData && cancelReasonData.amount} Berry</PopRightTxt>
        //             </PopTxt>
        //             <PopTxt>
        //                 <PopLeftTxt left>충전 관리자</PopLeftTxt>
        //                 <PopRightTxt right>{cancelReasonData && cancelReasonData.createdBy}</PopRightTxt>
        //             </PopTxt>
        //         </PopTable>
        //         <FullPointBox Cancel>
        //             <PopTxt>아래에 사유를 확인해주세요.</PopTxt>
        //             <PointCanCelReasonSpan>
        //                 {cancelReasonData && cancelReasonData.reasonForCancel}
        //             </PointCanCelReasonSpan>
        //         </FullPointBox>
        //         <Button mini center margin='48px 0 0 0' onClick={() => setOpenCancelRsModal(true)}>확인</Button>
        //     </PopBox>
        // </PopUp>

        <StyledModal>
            <ModalContainer Cancel>
                <RewardDetailTitle>포인트 충전 취소 사유</RewardDetailTitle>
                <RewardDetailContent Cancel>
                    <ModalSpan>취소 일자</ModalSpan>
                    <ModalSpan>
                        {cancelReasonData && dayjs(new Date(cancelReasonData.createdAt)).format(DATE.FORMAT.DOT)}
                    </ModalSpan>
                    <ModalSpan>승인 번호</ModalSpan>
                    <ModalSpan>대기 중</ModalSpan>
                    <ModalSpan>취소 포인트</ModalSpan>
                    <ModalSpan>{cancelReasonData && cancelReasonData.amount}</ModalSpan>
                    <ModalSpan>충전 관리자</ModalSpan>
                    <ModalSpan>{cancelReasonData && cancelReasonData.createdBy}</ModalSpan>
                </RewardDetailContent>
                <Wrapper Column margin='48px 0 0 0' height='100%' maxHeight='318px'>
                    <ModalSpan margin='0 0 6px 0'>아래에 사유를 확인해주세요.</ModalSpan>
                    <ReportReasonSpan Cancel>{cancelReasonData.reasonForCancel}</ReportReasonSpan>
                </Wrapper>
                <Button mini type='button' onClick={closeCanCelReasonModal} margin='auto 0 0 0'>확인</Button>
                <CloseButton type='button' onClick={closeCanCelReasonModal}>
                    <img src={CloseIcon} alt='close'/>
                </CloseButton>
            </ModalContainer>
        </StyledModal>
    );
};

export default CancelReasonPopup;
