import styled, {css} from "styled-components";
import {PollSettingContainer} from "common/styledComponents/commonStyle";

import PlayMini from "asset/img/icon__arrow_right_mini.png"
import PreviewBg from "asset/img/preview__cont_006.png"

export const Container = styled(PollSettingContainer)`
  padding: 48px 0 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.margin};

  ${(props) =>
          props.Subheading &&
          css`
            width: auto;

            span {
              margin-top: auto;
              font-size: 14px;
              color: #1a1a1a;
            }
          `}

  ${(props) =>
          props.Column &&
          css`
            flex-flow: column;
          `}

  ${(props) =>
          props.Row &&
          css`
            justify-content: space-between;
          `}

  ${(props) =>
          props.right &&
          css`
            justify-content: right;
          `}
`;

export const RankingTextInput = styled.input`
  background-color: #ffffff;
  border: 1px solid #757575;
  border-radius: 8px;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  width: 478px;
`;


export const PreviewCont = styled.div`
  width: 360px;
  height: 740px;
  background-color: #1A1A1A;
  border-radius: 20px;
  padding: 36px 0 0 20px;
  background-image: url(${PreviewBg});
  cursor: default;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) =>
          props.video &&
          css`
            padding: 0;
            position: relative;
            padding-bottom: 177.77%;
            border-radius: 20px;
            overflow: hidden;
          `}

`;

export const VideoDim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 투명한 검은색 레이어, 마지막 숫자(0.5)는 투명도 조절 */
  border-radius: 20px;
  z-index: 2; /* React Player 위로 올리기 위해 설정 */
`


export const PreviewBoxing = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  //background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  padding: 36px 0 0 20px;
  box-sizing: border-box;
  border-radius: 20px;
  z-index: 2;
`;
export const PreviewBoxTop = styled.div`
  color: #fff;
  font-weight: 700;
  display: flex;
  flex-direction: column;
`;
export const RankTxt = styled.p`

`;
export const RankCateUl = styled.ul`
  margin: 15px 0 18px;
`;

export const RankCateLi = styled.li`
  float: left;

  & + & {
    margin-left: 20px;
  }
`;
export const RankTit = styled.span`
  //margin: 16px 0;
  font-size: 20px;
  font-weight: 700;
  //height: 20px;
`;
export const RankSub = styled.div`
  color: #a8a8a8;
  font-size: 13px;
  margin: 8px 0 0 0;
`;
export const PreviewBoxBottom = styled.div`
  margin: 10px 0;
`;
export const RankListUl = styled.ul`
  margin: 32px auto 0;
  overflow-y: auto;
  height: 414px;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0;
  }
`;
export const RankListLi = styled.li`
  margin: 14px 0;
  color: #fff;
  height: 26px;
  display: flex;
  line-height: 26px;
`;

export const RankListNum = styled.span`
  width: 10px;
  margin-right: 20px;
`;

export const RankListImg = styled.img`
  width: 26px;
  max-height: 26px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 0;
  background-size: cover;
  margin-right: 20px;
`;
export const RankListTxt = styled.span`
  font-size: 14px;
  font-weight: 500;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: inline-block;
  white-space: nowrap;
  color: ${(props) => (props.active ? "#fdd102" : "#fff")};
  margin-right: 20px;

`;
export const RankAll = styled.p`
  text-align: center;
`;
export const RankAllShow = styled.a`
  font-size: 13px;
  color: #fff;
  position: relative;

  &::before {
    content: "";
    background-image: url(${PlayMini});
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: -24px;
    top: -2px;
  }

`;

export const ListInputBerry = styled.input`
  width: 180px;
  height: 36px;
  border: 1px solid #757575;
  border-radius: 8px;
  padding: 8px 50px 8px 8px;
`;

export const RewardAmountWrapper = styled.span`
  input {
    :disabled {
      background-color: #f5f5f5;
      color: #757575;
    }

    ::placeholder {
      color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
    }
  }

  span {
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    position: absolute;
    color: ${(props) => (props.disabled ? "#757575" : "#1A1A1A")};
  }
`;
export const ListRtxtSubNum = styled.span`
  font-size: 14px;
  margin: 0 8px 0 24px;
  font-weight: 400;
  ${(props) =>
          props.berry &&
          css`
            font-weight: 500;
            margin: 0;
          `}
  ${(props) =>
          props.in &&
          css`
            position: absolute;
            top: 7px;
            right: 8px;
          `}

  ${(props) =>
          props.In &&
          css`
            position: absolute;
            top: 7px;
            right: 8px;
          `}


`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: ${(props) => (props.Margin ? "48px 0 0 0" : "0")};

  button {
    background-color: #000065;
    width: 180px;
    padding: 11px 8px;
    border-radius: 8px;

    span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
    }
  }

  ${(props) =>
          props.AddItem &&
          `
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 153px;
            background-color: #000065;
            padding: 6px 8px;
            border-radius: 8px;

        span {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
        }
    }
        
    `}
  ${(props) =>
          props.AddItems &&
          `
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 163px;
            background-color: #000065;
            padding: 6px 8px;
            border-radius: 8px;

        span {
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
        }
    }
        
    `}
`;

export const ContentSection = styled.div`
  display: flex;
  flex-flow: column;
  //height: 100%;
  height: 708px;
  overflow: hidden;
  overflow-y: auto;
  padding: 24px 0 64px;


  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
`;
