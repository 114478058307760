import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    background-color: ${(props) => props.backgroundColor};

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
        `}
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    max-width: ${(props) => props.MaxWidth && "608px"};
    margin: ${(props) => props.margin};

    ${(props) =>
        props.Subheading &&
        css`
            width: auto;

            span {
                margin-top: auto;
                font-size: 14px;
                color: #1a1a1a;
            }
        `}

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
        `}

  ${(props) =>
        props.Row &&
        css`
            justify-content: space-between;
        `}

    ${(props) =>
        props.right &&
        css`
            justify-content: right;
        `}
`;



export const BarBox = styled.span`  
    width: 100%;
    max-width: 980px;
    height: 40px;
    background-color: #dbdbdb;
    display: inline-block;
    ${(props) =>
        props.img &&
        css`
            max-width: 816px;
        `}
    ${(props) =>
        props.po11 &&
        css`
            max-width: 484px;
        `}

`;

export const BarGraph = styled.span`  
    // width: 60%;
    height: 100%;
    background-color: #757575;
    display: inline-block;
    ${(props) =>
        props.first &&
        css`
            background-color: #000065;
        `}
`;
export const BarNumber = styled.span`  
    position: absolute;
    right: 0;
    bottom: 0;
    ${(props) =>
        props.poll &&
        css`
            right: 10%;
            bottom: -2px;
            transform: translateX(50%);
        `}
`;
export const BarPercent = styled.span`  
    display: block;
    text-align: center;
    font-size: 24px;
`;
export const BarPerson = styled.span`  
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: 8px;
`;