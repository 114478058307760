import React from "react";
import styled from "styled-components";

import Info from "asset/img/icon__info_w.png"

export const PopCont = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
`;
export const Dim = styled.div`
    width:100%;
    height:100%;
    background:#1A1A1A;
    opacity:0.2;
`;

export const PopArea = styled.div`
    width:780px;
    height:340px;
    padding:48px;
    box-sizing:border-box;
    position:absolute;
    left:50%;
    top:50%;
    background-color:#fff;
    border-radius:8px;
    transform:translate(-50%, -50%);
`;

export const PopIcon = styled.p`
    display:inline-block;
    width:48px;
    height:48px;
    left:50%;
    position:relative;
    transform:translate(-50%,0);
    margin-bottom:48px
`;

export const InfoTxt = styled.p`
    font-size:16px;
    color:#1A1A1A;
    margin-bottom:16px;
`;


export const ConfirmButton = styled.button`
    background-color:#000065;
    border-radius:8px;
    width:100px;
    padding:8px;
    font-size:14px;
    color:#fff;
    font-weight:500;
    position:absolute;
    right:48px;
    bottom:48px;
`;

const FindPwdModal = ({setShowAlert}) =>{

    return (
        <PopCont>
             <Dim></Dim>
             <PopArea>
             <PopIcon><img src={Info} alt=""></img></PopIcon>
             <InfoTxt>비즈회원의 아이디/비밀번호 확인 및 재설정은 아래 고객센터에서 확인 가능합니다.</InfoTxt>
             <InfoTxt>E-mail : info@pollberry.net</InfoTxt>
             <ConfirmButton onClick={()=>setShowAlert(false)}>확인</ConfirmButton>
             </PopArea>
        </PopCont>
    )
}


export default FindPwdModal;
