import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";

import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import SearchFilter from "common/components/SearchFilter"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"

import DATE from "common/constants/date";
import {
    DateSearch,
    SearchConditionBox,
    SearchConditionDiv,
    SearchConditionText,
    SearchDateBox,
    SearchDateInput,
    SearchDateInputSpan,
    SearchDateMid,
    SearchDateTxt
} from "../userSurveySytle";
import {
    DATE_FILTER_OPTIONS,
    POINTMNG_REFUNDMNG_STATE_LIST,
    SURVEY_DETAIL_OPTIONS_USAGE,
    USAGE_STATUS
} from '../../../common/constants/point';

const INIT_OPTION = {
    BOARD_PARAMS: {
        offset: 0,
        limit: 10
    },
    KEY: '',
    DATE_FILTER_OPTION: 'ALL',
    // START_AT: '2022-12-19',
    // END_AT: '2023-01-19'
}

const UsageMng = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [curPage, setCurPage] = useState(null);
    const [formData, setFormData] = useState({offset: 0, limit: 10, page: curPage});
    const [totalNum, setTotalNum] = useState(0);
    const pageInfo = pageMaker(curPage, formData.limit, totalNum);
    const queryPage = searchParams.get('page')
    const queryName = searchParams.get('name')
    const queryStatus = searchParams.get('status')
    const queryStartAt = searchParams.get('startAt')
    const queryEndAt = searchParams.get('endAt')
    const queryDateOption = searchParams.get('dateOption') || 'ALL'
    const [dateFilterOption, setDateFilterOption] = useState(INIT_OPTION.DATE_FILTER_OPTION);
    const request = useRequest("get", "point/usage/list");
    const refStartAt = useRef(null);
    const refEndAt = useRef(null);

    const getSearchDate = () => {
        const now = dayjs(new Date());
        let date = {
            startAt: now,
            endAt: now
        };
        switch (dateFilterOption) {
            case '1M':
                date.startAt = now.subtract(1, 'month');
                break;
            case '3M':
                date.startAt = now.subtract(3, 'month');
                break;
            case '6M':
                date.startAt = now.subtract(6, 'month');
                break;
            case '1Y':
                date.startAt = now.subtract(1, 'year');
                break;
            case 'SELECT':
                date.startAt = refStartAt.current.value ? dayjs(refStartAt.current.value) : now;
                date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
                break;
            case 'ALL':
                date.startAt = null;
                date.endAt = null;
                break;
            default:
                date.startAt = now;
                date.endAt = now;
                break;
        }
        return {
            startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
            endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
        }

    }

    const {startAt, endAt} = getSearchDate();

    const {rows, fetch, params} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            status: USAGE_STATUS[data.status],
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            startAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
            endAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
        }));
    }, {
        ...INIT_OPTION.BOARD_PARAMS,
        startAt,
        endAt
        // ...getSearchDate()
    });

    const handleSelectRow = (row) => {
        navigate(`detail/${row.id}`);
    }

    function updateSearchParams(key, value) {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    }

    const handleSearch = (data) => {
        const {selectedOption} = data;
        const {type, name, limit,} = formData;
        updateSearchParams('status', selectedOption);
        updateSearchParams('type', type);
        updateSearchParams('name', name);
        updateSearchParams('startAt', startAt);
        updateSearchParams('endAt', endAt);
        updateSearchParams('dateOption', dateFilterOption);

        if (name || name === '') {
            updateSearchParams('page', '1')
            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1})
        }

        if (selectedOption) {
            updateSearchParams('page', '1')
            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1})
        }

        if (startAt !== undefined && endAt !== undefined) {
            setCurPage(1)

            setFormData({
                ...formData,
                page: 1,
                offset: 0,
                startAt: startAt,
                endAt: endAt,
            })
        }

        fetch({
            name: name || '',
            status: selectedOption || '',
            startAt: startAt || '',
            endAt: endAt || '',
            type: type || '',
            page: 1,
            offset: 0,
            limit
        });
    }

    const handleDateFilterSelect = (option) => {
        setDateFilterOption(option);
        const {startAt, endAt} = getSearchDate();
        refStartAt.current.value = startAt;
        refEndAt.current.value = endAt;
    }

    const handleChangeStartAt = () => {
        const {startAt} = getSearchDate();
        refStartAt.current.value = startAt;
    }

    const handleChangeEndAt = () => {
        const {endAt} = getSearchDate();
        refEndAt.current.value = endAt;
    }

    const handlePage = (pageNum) => {
        updateSearchParams('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    };

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage) || 1,
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            dateOption: queryDateOption || 'ALL'
        }))

        setDateFilterOption(queryDateOption)

        fetch({
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            limit: 10,
        })

    }, [queryPage, queryName, queryStartAt, queryEndAt, queryStatus]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>사용 관리</Title>
                    <DateSearch>
                        <SearchConditionBox>
                            <SearchConditionText>조회 기간</SearchConditionText>
                            <SearchConditionDiv>
                                <SearchFilter options={DATE_FILTER_OPTIONS} option={dateFilterOption}
                                              onSelect={handleDateFilterSelect} type="filter"/>
                                <SearchDateBox>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refStartAt} onChange={() => handleChangeStartAt()}/>
                                        <SearchDateTxt>{formData.startAt ? formData.startAt : startAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                    <SearchDateMid>~</SearchDateMid>
                                    <SearchDateInputSpan>
                                        <SearchDateInput type="date"
                                                         min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                                         ref={refEndAt} onChange={() => handleChangeEndAt()}/>
                                        <SearchDateTxt>{formData.endAt ? formData.endAt : endAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                </SearchDateBox>
                            </SearchConditionDiv>
                        </SearchConditionBox>
                    </DateSearch>

                    <ListSearch options={SURVEY_DETAIL_OPTIONS_USAGE} onSearch={handleSearch}/>
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={POINTMNG_REFUNDMNG_STATE_LIST}
                           rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>

            {/* 채널 거래 내역보기 */}
            {/* <PopUp>
             <PopBox pay>
             <PopClose><img src={CloseIcon} alt=''/></PopClose>
             <PopHeadTxt>채널 거래 내역보기</PopHeadTxt>
             <PayTable>
             <PayTableTr>
             <PayTableTh>구매자명 (회원 ID)</PayTableTh>
             <PayTableTh>채널명</PayTableTh>
             </PayTableTr>
             <PayTableTr>
             <PayTableTd>scarlettG_G</PayTableTd>
             <PayTableTd>channel name hihihihihihihi</PayTableTd>
             </PayTableTr>
             <PayTableTr>
             <PayTableTh>거래일시</PayTableTh>
             <PayTableTh>취소일시</PayTableTh>
             </PayTableTr>
             <PayTableTr>
             <PayTableTd>2000.00.00 00:00</PayTableTd>
             <PayTableTd>-</PayTableTd>
             </PayTableTr>
             <PayTableTr>
             <PayTableTh>상품명</PayTableTh>
             <PayTableTh>충전금액</PayTableTh>
             </PayTableTr>
             <PayTableTr>
             <PayTableTd>포인트</PayTableTd>
             <PayTableTd>70,000 원</PayTableTd>
             </PayTableTr>
             <PayTableTr>
             <PayTableTh>승인번호</PayTableTh>
             <PayTableTh>결제대행사</PayTableTh>
             </PayTableTr>
             <PayTableTr>
             <PayTableTd>00000000</PayTableTd>
             <PayTableTd>-</PayTableTd>
             </PayTableTr>
             <PayTableTr>
             <PayTableTh>결제수단</PayTableTh>
             <PayTableTh>비고</PayTableTh>
             </PayTableTr>
             <PayTableTr>
             <PayTableTd>수기 입력</PayTableTd>
             <PayTableTd>충전 정보 입력 아이디</PayTableTd>
             </PayTableTr>
             </PayTable>
             <PopButtonBox>
             <PopButton twin line>충전 취소</PopButton>
             <PopButton twin>확인</PopButton>
             </PopButtonBox>
             </PopBox>
             </PopUp> */}

            {/* 상세보기  */}
            {/* <PopUp>
             <PopBox detail>
             <PopClose><img src={CloseIcon} alt=''/></PopClose>
             <PopHeadTxt>상세보기</PopHeadTxt>
             <PopPollListBox detail>
             <PopPollList>
             <PopPollListTxtL>일시</PopPollListTxtL>
             <PopPollListTxtR>2000.00.00 00:00</PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL>설문 타이틀</PopPollListTxtL>
             <PopPollListTxtR underline>설문타이틀이 길게 들어갈수도 있어요. 그러면 이렇게 두줄로도 가능. 문타이틀이 길게 들어갈수도 있어요. 그러면 이렇게 두줄로도 가능.</PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL>구분</PopPollListTxtL>
             <PopPollListTxtR>설문 보상</PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL>보상액</PopPollListTxtL>
             <PopPollListTxtR>000,000,000 Berry</PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL>보상 제공자</PopPollListTxtL>
             <PopPollListTxtR>channel name</PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL>Token 정보</PopPollListTxtL>
             <PopPollListTxtR>nikc1asd51asdasd5fg5s4d8asd5s5das45d1as2d5w5d15d</PopPollListTxtR>
             </PopPollList>
             </PopPollListBox>
             <PopButtonBox>
             <PopButton center>확인</PopButton>
             </PopButtonBox>
             </PopBox>
             </PopUp> */}
        </>
    )
}

export default UsageMng;
