import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Register from "../../../../common/components/Register";
import useRequest from "../../../../hooks/useRequest";
import {API_STATE} from "common/constants/state";

function PolicyRegister(props) {
    const navigate = useNavigate();
    const request = useRequest("post", "tos/new");
    const [editorDate, setEditorData] = useState("");

    useEffect(() => {
        switch (request.state) {
            case API_STATE.done:
                if (request.response.success) {
                    alert('저장 되었습니다');
                    navigate('/tos/list');
                }
                break;
            default:
                break;
        }
    }, [request.state, request.error, request.response]);

    return (
        <>
            <Register
                request={request}
                type='tos'
                title={"이용약관 등록하기"}
                topShow={false}
                setEditorData={setEditorData}
                editorDate={editorDate}
            />
        </>
    );
}

export default PolicyRegister;
