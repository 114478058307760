import React, { useState } from "react";
import {
    AddFileWrapper,
    Button,
    ItemTextInputWrapper,
    PollSettingContainer,
    PollSettingContentContainer,
    PollSettingContentSection,
    PollSettingContentTitle,
    PollSettingContentWrapper,
    PollSettingMainContainer,
    PollSettingTitleContainer,
    Select,
    TitleInputWrapper,
} from "../../../common/styledComponents/commonStyle";
import { Content, Wrapper } from "./RankingDetailViewHotRankingStyle";

function RankingDetailViewHotRanking(props) {
    const resultTestData = [
        { title: "항목 1", id: 1, value: "" },
        { title: "항목 2", id: 2, value: "" },
        { title: "항목 3", id: 3, value: "" },
        { title: "항목 4", id: 4, value: "" },
        { title: "항목 5", id: 5, value: "" },
        { title: "항목 6", id: 6, value: "" },
        { title: "항목 7", id: 7, value: "" },
        { title: "항목 8", id: 8, value: "" },
        { title: "항목 9", id: 9, value: "" },
        { title: "항목 10", id: 10, value: "" },
    ];

    const pollKeywordTestData = [
        {
            title: "1위",
            id: 1,
            value: "",
            name: "platformPoll_detail_keyword_1",
            file: "",
            fileName: "",
        },
        {
            title: "2위",
            id: 2,
            value: "",
            name: "platformPoll_detail_keyword_2",
            file: "",
            fileName: "",
        },
        {
            title: "3위",
            id: 3,
            value: "",
            name: "platformPoll_detail_keyword_3",
            file: "",
            fileName: "",
        },
        {
            title: "4위",
            id: 4,
            value: "",
            name: "platformPoll_detail_keyword_4",
            file: "",
            fileName: "",
        },
        {
            title: "5위",
            id: 5,
            value: "",
            name: "platformPoll_detail_keyword_5",
            file: "",
            fileName: "",
        },
        {
            title: "6위",
            id: 6,
            value: "",
            name: "platformPoll_detail_keyword_6",
            file: "",
            fileName: "",
        },
        {
            title: "7위",
            id: 7,
            value: "",
            name: "platformPoll_detail_keyword_7",
            file: "",
            fileName: "",
        },
        {
            title: "8위",
            id: 8,
            value: "",
            name: "platformPoll_detail_keyword_8",
            file: "",
            fileName: "",
        },
        {
            title: "9위",
            id: 9,
            value: "",
            name: "platformPoll_detail_keyword_9",
            file: "",
            fileName: "",
        },
        {
            title: "10위",
            id: 10,
            value: "",
            name: "platformPoll_detail_keyword_10",
            file: "",
            fileName: "",
        },
    ];

    return (
        <PollSettingContainer>
            <PollSettingTitleContainer>
                <span>랭킹 상세보기</span>
            </PollSettingTitleContainer>
            <PollSettingMainContainer>
                <form>
                    <PollSettingContentContainer>
                        <PollSettingContentSection>
                            <PollSettingContentWrapper Border>
                                <Content>
                                    <Wrapper>
                                        <PollSettingContentTitle>랭킹 유형</PollSettingContentTitle>
                                        <span>Hot Ranking</span>
                                    </Wrapper>
                                    <Wrapper>
                                        <PollSettingContentTitle>랭킹 상태</PollSettingContentTitle>
                                        <Select width="122px">
                                            <option value={"test_1"}>test_1</option>
                                            <option value={"test_2"}>test_2</option>
                                            <option value={"test_3"}>test_3</option>
                                            <option value={"test_4"}>test_4</option>
                                        </Select>
                                    </Wrapper>
                                </Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper>
                                <PollSettingContentTitle>랭킹 제목</PollSettingContentTitle>
                                <Content MaxWidth>
                                    <TitleInputWrapper>
                                        <input
                                            type="text"
                                            required
                                            name="pollTitle"
                                            id="pollTitle"
                                        />
                                        <span>0/20자</span>
                                    </TitleInputWrapper>
                                </Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper Padding>
                                <PollSettingContentTitle>랭킹 기간</PollSettingContentTitle>
                                <Content>대기 중</Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper Padding>
                                <PollSettingContentTitle>랭킹 출처</PollSettingContentTitle>
                                <Content MaxWidth>
                                    <TitleInputWrapper>
                                        <input
                                            type="text"
                                            required
                                            name="ranking_source"
                                            id="ranking_source"
                                        />
                                    </TitleInputWrapper>
                                </Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper Padding>
                                <PollSettingContentTitle>카테고리 명</PollSettingContentTitle>
                                <Content>
                                    <Select width="292px">
                                        <option value={"test_1"}>test_1</option>
                                        <option value={"test_2"}>test_2</option>
                                        <option value={"test_3"}>test_3</option>
                                        <option value={"test_4"}>test_4</option>
                                    </Select>
                                </Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper Padding Border>
                                <PollSettingContentTitle>배경 파일</PollSettingContentTitle>
                                <Content MaxWidth>
                                    <AddFileWrapper width="500px">
                                        <p></p>
                                        <input
                                            type="file"
                                            // id={keywordItem.name}
                                            // key={keywordItem.id}
                                            // name="test"
                                            accept="image/*"
                                        />
                                        <label>파일 첨부</label>
                                    </AddFileWrapper>
                                </Content>
                            </PollSettingContentWrapper>
                            <PollSettingContentWrapper>
                                <PollSettingContentTitle Subheading>
                                    랭킹 순위
                                </PollSettingContentTitle>
                                <Content Subheading>
                                    <span>
                                        이미지 권장크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x
                                        1000) / jpg, jpeg, png, bmp
                                    </span>
                                </Content>
                            </PollSettingContentWrapper>
                            {pollKeywordTestData.map((keywordItem) => (
                                <PollSettingContentWrapper key={keywordItem.id}>
                                    <PollSettingContentTitle>
                                        {keywordItem.title}
                                    </PollSettingContentTitle>
                                    <Wrapper
                                        width="80px"
                                        margin="0 24px 0 0"
                                        backgroundColor="#f5f5f5"
                                    />
                                    <Content MaxWidth>
                                        <Wrapper Column>
                                            <ItemTextInputWrapper>
                                                <input
                                                    type="text"
                                                    required
                                                    // key={item.id}
                                                    id={keywordItem.id}
                                                    // value={watch().pollItems[index].value}
                                                />
                                            </ItemTextInputWrapper>
                                            <AddFileWrapper>
                                                <p>{keywordItem.fileName}</p>
                                                <input
                                                    type="file"
                                                    id={keywordItem.name}
                                                    key={keywordItem.id}
                                                    // name="test"
                                                    accept="image/*"
                                                />
                                                <label>파일 첨부</label>
                                            </AddFileWrapper>
                                        </Wrapper>
                                    </Content>
                                </PollSettingContentWrapper>
                            ))}
                            <PollSettingContentWrapper Padding>
                                <Content right>
                                    <Button preview mini>
                                        미리보기
                                    </Button>
                                    <Button mini>수정 하기</Button>
                                </Content>
                            </PollSettingContentWrapper>
                        </PollSettingContentSection>
                    </PollSettingContentContainer>
                </form>
            </PollSettingMainContainer>
        </PollSettingContainer>
    );
}

export default RankingDetailViewHotRanking;
