import React from "react";
import {
    PollSettingContentTitle,
    PollSettingContentWrapper,
    SubjectList,
    SubjectListBlock,
    SubjectListBox,
    SubjectListSpan
} from "common/styledComponents/commonStyle";
import {BarBox, BarGraph, BarNumber, BarPercent, BarPerson} from "./PlatformPollDetailBalanceStyle";

import DefaultBg from 'asset/img/img-back.jpg'

function PlatformPollResult({participantsCount, items, domain}) {

    return (
        <>

            <PollSettingContentWrapper BorderTop>
                <PollSettingContentTitle Subheading>결과 집계</PollSettingContentTitle>
            </PollSettingContentWrapper>
            <PollSettingContentWrapper Padding Border>
                <SubjectListBox no>
                    {items && items.map((item, idx) => {
                        const {id, selectionCount, text, url, imageUrl} = item;
                        const percent = participantsCount ? Math.floor(selectionCount / participantsCount * 100) : 0;
                        return (
                            <SubjectList poll key={id}>
                                <SubjectListSpan bigimg style={{height: "140px"}}>
                                    <img style={{width: "100%", height: "100%"}}
                                        // src={item.imageUrl && item.imageUrl.includes('data:') ? item.imageUrl : `${domain}${item.url}`}
                                        // alt="img"
                                         src={imageUrl && imageUrl.includes('data:') ? imageUrl : url && typeof url === 'string' ? `${domain}${url}` : DefaultBg}
                                         alt={url ? 'resultKeywordImage' : null}
                                    />
                                </SubjectListSpan>
                                <SubjectListBlock poll>
                                    {/*<SubjectListBlock>텍스트 / 텍스트 없을 경우 항목 {idx+1}.</SubjectListBlock>*/}
                                    <SubjectListBlock>{text}</SubjectListBlock>
                                    {/* <SubjectListBlock>{text}</SubjectListBlock> */}
                                    <SubjectListBlock Rel>
                                        <BarBox poll>
                                            {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                            {/* first 추가시 1등 */}
                                            <BarGraph first style={{width: `${percent}%`}}/>
                                        </BarBox>
                                    </SubjectListBlock>
                                    <BarNumber poll>
                                        <BarPercent>{`${percent}%`}</BarPercent>
                                        <BarPerson>{selectionCount}명</BarPerson>
                                    </BarNumber>
                                </SubjectListBlock>
                            </SubjectList>
                        )
                    })}
                    {/* <SubjectList>
                     <SubjectListSpan bigimg><img src={ImgBack2} alt="img"/></SubjectListSpan>
                     <SubjectListBlock img>
                     <SubjectListBlock>항목 00.</SubjectListBlock>
                     <SubjectListBlock>항목항목항목</SubjectListBlock>
                     <SubjectListBlock rel>
                     <BarBox img>
                     width:% 퍼센트 값에 따라 막대 사이즈 변화
                     first 추가시 1등
                     <BarGraph first style={width60}></BarGraph>
                     </BarBox>
                     <BarNumber>
                     <BarPercent>40%</BarPercent>
                     <BarPerson>1,141,400명</BarPerson>
                     </BarNumber>
                     </SubjectListBlock>
                     </SubjectListBlock>
                     </SubjectList>
                     <SubjectList>
                     <SubjectListSpan bigimg><img src={ImgBack2} alt="img"/></SubjectListSpan>
                     <SubjectListBlock img>
                     <SubjectListBlock>항목 00.</SubjectListBlock>
                     <SubjectListBlock>항목항목항목</SubjectListBlock>
                     <SubjectListBlock rel>
                     <BarBox img>
                     width:% 퍼센트 값에 따라 막대 사이즈 변화
                     first 추가시 1등
                     <BarGraph style={width40}></BarGraph>
                     </BarBox>
                     <BarNumber>
                     <BarPercent>40%</BarPercent>
                     <BarPerson>1,141,400명</BarPerson>
                     </BarNumber>
                     </SubjectListBlock>
                     </SubjectListBlock>
                     </SubjectList>                                 */}
                </SubjectListBox>

            </PollSettingContentWrapper>
        </>
    );
}

export default PlatformPollResult;
