import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import useRequest from "hooks/useRequest";

import {MenuInfoCont} from "pages/Mypage/MypageStyle"
import {
    ButtonRight,
    GrayButton,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRlink,
    ListRtxt,
    ListUl
} from "common/styledComponents/commonStyle";
import {API_STATE} from "common/constants/state";

const MypageAdm = ({myInfo, setPwModal, setNemoModal}) => {
    const {
        register,
        handleSubmit,
        watch
    } = useForm({
        defaultValues: {
            department: myInfo.department,
            email: myInfo.email
        }
    });
    const editInfoRequest = useRequest("patch", "/user/admin/update");
    const handleEditInfo = (data) => {
        editInfoRequest.call({...data, name: myInfo.name});
    };

    useEffect(() => {
        switch (editInfoRequest.state) {
            case API_STATE.done:
                if (editInfoRequest.response.success) {
                    alert("변경 되었습니다.");
                } else {

                }

                break;
            default:
                break;
        }
    }, [editInfoRequest.state, editInfoRequest.error, editInfoRequest.response]);

    return (
        <>
            <MenuInfoCont info>
                <List channel>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>회원 등급</ListLtxt>
                            <ListRtxt>{myInfo.role === "ROLE_SUPER" ? "최고관리자" : "관리자"}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>아이디</ListLtxt>
                            <ListRtxt>{myInfo.userId}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>비밀번호</ListLtxt>
                            <ListRtxt>
                                <ListRlink onClick={() => setPwModal(false)}>비밀번호 변경하기</ListRlink>
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>블록체인 지갑 주소</ListLtxt>
                            <ListRtxt>{myInfo.walletAddress}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>니모닉키</ListLtxt>
                            <ListRtxt>
                                <ListRlink onClick={() => setNemoModal(false)}>니모닉키 확인하기</ListRlink>
                            </ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </MenuInfoCont>

            <MenuInfoCont info>
                <form onSubmit={handleSubmit(handleEditInfo)}>
                    <List channel>
                        <ListUl>
                            <ListLi>
                                <ListLtxt>성함</ListLtxt>
                                <ListRtxt>{myInfo.name}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>소속</ListLtxt>
                                <ListRtxt>
                                    <ListInput type="text" defaultValue={myInfo.department}
                                               placeholder="입력"  {...register('department', {required: true})}/>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>이메일주소</ListLtxt>
                                <ListRtxt>
                                    <ListInput type="text" defaultValue={myInfo.email}
                                               placeholder="입력"  {...register('email', {required: true})}/>
                                </ListRtxt>
                            </ListLi>
                        </ListUl>
                    </List>
                    <ButtonRight>
                        <GrayButton
                            {...(
                                myInfo.department === watch('department') &&
                                myInfo.email === watch('email')
                            )
                            && {disabled: true}}
                        >정보 변경하기</GrayButton>
                    </ButtonRight>
                </form>
            </MenuInfoCont>


        </>
    )
}

export default MypageAdm;
