import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import useRequest from "hooks/useRequest";

import UserCreateAdmin from "pages/User/UserCrt/Admin";
import {MenuInfoCont} from "pages/User/UserStyle"
import {
    Button,
    ButtonRight,
    LabelName,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListSpan,
    ListUl,
    MenuArea,
    MenuInfoArea,
    Option,
    RadioBox,
    Select,
    Title,
} from "common/styledComponents/commonStyle";
import {API_STATE} from "common/constants/state";
import {isValidEmail} from "common/js/Validation";

import AccntCreateInfoModal from "./modal/AccntCreateInfoModal";

const User = () => {
    const {register, handleSubmit, watch, reset, setValue} = useForm({
        defaultValues: {
            name: "",
            department: "",
            email: "",
            bizType: 'PRIVATE'
        }
    });
    const [userType, setUserType] = useState("admin");
    // const [bizType, setBizType] = useState("PRIVATE");
    const [bizCreateModalOpen, setBizCreateModalOpen] = useState(true);
    const [dataForModal, setDataForModal] = useState({});
    const saveBizUserRequest = useRequest("post", "/user/biz/new");

    const validation = () => {
        if (!isValidEmail(watch().bizEmail)) {
            alert("이메일 형식을 확인 해 주세요.");
            return false;
        }
        if (watch().bizType === "PRIVATE" && watch().bizNum1.length + watch().bizNum2.length + watch().bizNum3.length !== 10) {
            alert("사업자 등록 번호를 확인 해 주세요.");
            return false;
        }
        if (watch().bizType === "INCORPORATED" && watch().corpNum1.length + watch().corpNum2.length !== 13) {
            alert("법인 등록 번호를 확인 해 주세요.");
            return false;
        }

        return true;
    }

    const handleCreateUser = (data) => {
        if (!validation()) {
            return;
        } else {
            const formData = {
                name: watch()?.bizName,
                email: watch()?.bizEmail,
                companyName: watch()?.companyName,
                companyNumber: data.bizType === "PRIVATE" ?
                    `${watch().bizNum1}-${watch().bizNum2}-${watch().bizNum3}`
                    :
                    `${watch().corpNum1}-${watch().corpNum2}`,
                phone: `${watch()?.phone1}-${watch()?.phone2}-${watch()?.phone3}`,
                companyType: data.bizType
            }
            saveBizUserRequest.call(formData);
        }
    };

    const handleBizNumberKeyPress = (e) => {
        const forbiddenCharacters = ['e', '+', '-', '.'];
        if (forbiddenCharacters.includes(e.key)) {
            e.preventDefault();
        }
    }

    const handleNumberChange = (e) => {
        const value = e.target.value

        if (value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }
    }

    useEffect(() => {
        const response = saveBizUserRequest.response;

        switch (saveBizUserRequest.state) {
            case API_STATE.done:
                if (response.success) {
                    setDataForModal({
                        userType: "비즈 회원",
                        // bizType,
                        id: response?.response?.userId,
                        password: response?.response?.password
                    })
                    setBizCreateModalOpen(false)
                } else if (!response.success) {
                    if (response.error.message.includes('admins_email_key')) {
                        alert(`이메일 중복입니다.`)
                    } else {
                        alert(`error: ${response.error.message}`)
                    }
                }
                break;

            default:
                break;
        }
    }, [saveBizUserRequest.state, saveBizUserRequest.error, saveBizUserRequest.response]);

    return (
        <>
            <MenuArea>
                {/* <div className="menu">메뉴</div> */}
                <MenuInfoArea>
                    <Title>신규 회원 생성하기</Title>
                    <MenuInfoCont>
                        <List wide>
                            <ListUl>
                                <ListLi>
                                    <ListLtxt>회원 구분</ListLtxt>
                                    <ListRtxt onChange={() => {
                                        setDataForModal({});
                                        reset();
                                    }}>
                                        <RadioBox>
                                            <ListInput type="radio" name="sample" checked={userType === "admin"}
                                                       onChange={() => setUserType("admin")}/>
                                            <LabelName>관리자</LabelName>
                                        </RadioBox>
                                        <RadioBox>
                                            <ListInput type="radio" name="sample" checked={userType === "biz"}
                                                       onChange={() => setUserType("biz")}/>
                                            <LabelName>비즈 회원</LabelName>
                                        </RadioBox>
                                    </ListRtxt>
                                </ListLi>

                                {/* 라디오버튼 = 비즈회원계정 */}
                                {userType === "biz" && (
                                    <>
                                        <ListLi>
                                            <ListLtxt>회사명</ListLtxt>
                                            <ListRtxt><ListInput type="text"
                                                                 placeholder="" {...register('companyName')}/></ListRtxt>
                                        </ListLi>
                                        <ListLi>
                                            <ListLtxt>사업자 구분</ListLtxt>
                                            <ListRtxt>
                                                <RadioBox>
                                                    <ListInput type="radio"
                                                               checked={watch().bizType === "PRIVATE"}
                                                               value='PRIVATE'
                                                               {...register('bizType')}
                                                    />
                                                    <LabelName>개인 사업자</LabelName>
                                                </RadioBox>
                                                <RadioBox>
                                                    <ListInput type="radio"
                                                               checked={watch().bizType === "INCORPORATED"}
                                                               value='INCORPORATED'
                                                               {...register('bizType')}
                                                    />
                                                    <LabelName>법인 사업자</LabelName>
                                                </RadioBox>
                                            </ListRtxt>
                                        </ListLi>
                                        {watch().bizType === "PRIVATE" &&
                                            <ListLi>
                                                <ListLtxt>사업자 등록 번호</ListLtxt>
                                                <ListRtxt>
                                                    <ListSpan>
                                                        <ListInput
                                                            type="number"
                                                            maxLength={3}
                                                            {...register('bizNum1', {
                                                                onChange: handleNumberChange,
                                                            })}
                                                            onKeyUp={handleBizNumberKeyPress}
                                                        />
                                                    </ListSpan>
                                                    <ListSpan>
                                                        <ListInput
                                                            type="number"
                                                            {...register('bizNum2', {
                                                                onChange: handleNumberChange,
                                                            })}
                                                            maxLength={2}
                                                            onKeyUp={handleBizNumberKeyPress}
                                                        />
                                                    </ListSpan>
                                                    <ListSpan>
                                                        <ListInput
                                                            type="number"
                                                            {...register('bizNum3', {
                                                                onChange: handleNumberChange,
                                                            })}
                                                            maxLength={5}
                                                            onKeyUp={handleBizNumberKeyPress}
                                                        />
                                                    </ListSpan>
                                                </ListRtxt>
                                            </ListLi>}
                                        {watch().bizType === "INCORPORATED" &&
                                            <ListLi>
                                                <ListLtxt>법인 등록 번호</ListLtxt>
                                                <ListRtxt>
                                                    <ListSpan>
                                                        <ListInput two
                                                                   type="number"
                                                                   {...register('corpNum1',
                                                                       {onChange: handleNumberChange,}
                                                                   )}
                                                                   maxLength={6}
                                                                   onKeyUp={handleBizNumberKeyPress}
                                                        />
                                                    </ListSpan>
                                                    <ListSpan>
                                                        <ListInput two
                                                                   type="number"
                                                                   {...register('corpNum2',
                                                                       {onChange: handleNumberChange,}
                                                                   )}
                                                                   maxLength={7}
                                                                   onKeyUp={handleBizNumberKeyPress}
                                                        />
                                                    </ListSpan>
                                                </ListRtxt>
                                            </ListLi>
                                        }
                                        <ListLi>
                                            <ListLtxt>담당자</ListLtxt>
                                            <ListRtxt><ListInput
                                                type="text" {...register('bizName')}/></ListRtxt>
                                        </ListLi>
                                        <ListLi>
                                            <ListLtxt>연락처</ListLtxt>
                                            <ListRtxt>

                                                <ListSpan>
                                                    <ListInput
                                                        type="number"
                                                        {...register('phone1')}
                                                        maxLength={4}
                                                        onChange={handleNumberChange}
                                                        onKeyUp={handleBizNumberKeyPress}
                                                    />
                                                </ListSpan>
                                                <ListSpan>
                                                    <ListInput
                                                        type="number"
                                                        {...register('phone2')}
                                                        maxLength={4}
                                                        onChange={handleNumberChange}
                                                        onKeyUp={handleBizNumberKeyPress}
                                                    />
                                                </ListSpan>
                                                <ListSpan>
                                                    <ListInput
                                                        type="number"
                                                        {...register('phone3')}
                                                        maxLength={4}
                                                        onChange={handleNumberChange}
                                                        onKeyUp={handleBizNumberKeyPress}
                                                    />
                                                </ListSpan>
                                            </ListRtxt>
                                        </ListLi>
                                        <ListLi>
                                            <ListLtxt>이메일 주소</ListLtxt>
                                            <ListRtxt><ListInput
                                                type="text" {...register('bizEmail')}/></ListRtxt>
                                        </ListLi>
                                        <ListLi>
                                            <ListLtxt>계약 구분</ListLtxt>
                                            <ListRtxt>
                                                <Select>
                                                    <Option>텍스트 영역</Option>
                                                    <Option>텍스트 영역</Option>
                                                    <Option>텍스트 영역</Option>
                                                </Select>
                                            </ListRtxt>
                                        </ListLi>

                                        <MenuInfoCont>
                                            <ButtonRight>
                                                <Button mini
                                                        {...(
                                                            !watch("companyName") ||
                                                            !watch("bizName") ||
                                                            (!watch("phone1") || !watch("phone2") || !watch("phone3")) ||
                                                            !watch("bizEmail") ||
                                                            (() => {
                                                                if (watch().bizType === "PRIVATE") {
                                                                    return (!watch("bizNum1") || !watch("bizNum2") || !watch("bizNum3"))
                                                                }
                                                                if (watch().bizType === "INCORPORATED") {
                                                                    return (!watch("corpNum1") || !watch("corpNum2"))
                                                                }
                                                            })()
                                                        )
                                                        && {disabled: true}}
                                                    // onClick={() => setBizCreateModalOpen(false)}
                                                        onClick={handleSubmit(handleCreateUser)}

                                                >
                                                    생성 하기
                                                </Button>
                                            </ButtonRight>
                                        </MenuInfoCont>

                                    </>
                                )}
                            </ListUl>
                        </List>
                        {userType === "admin" && <UserCreateAdmin userType={userType}/>}
                    </MenuInfoCont>
                </MenuInfoArea>
            </MenuArea>

            <AccntCreateInfoModal {...{bizCreateModalOpen, setBizCreateModalOpen, handleCreateUser, dataForModal}} />


            {/*
             <PopUp>
             <PopBox grade>
             <PopClose><img src={CloseIcon} alt='' /></PopClose>
             <PopHeadTxt>회원 등급 설정</PopHeadTxt>

             <PopPollListBox>
             <PopPollList>
             <PopPollListTxtL>회원 등급</PopPollListTxtL>
             <PopPollListTxtR>
             <RadioBox>
             <ListInput type="radio" name="type1"></ListInput>
             <LabelName>최고 관리자</LabelName>
             </RadioBox>
             <RadioBox>
             <ListInput type="radio" name="type1"></ListInput>
             <LabelName>일반 관리자</LabelName>
             </RadioBox>
             </PopPollListTxtR>
             </PopPollList>
             <PopPollList>
             <PopPollListTxtL margin>권한 구분</PopPollListTxtL>
             <TableCont>
             <Table>
             <Caption>권한 설정 테이블</Caption>
             <Thead>
             <TheadTr>
             <TheadTh>항목</TheadTh>
             <TheadTh>권한 없음</TheadTh>
             <TheadTh>읽기</TheadTh>
             <TheadTh>쓰기</TheadTh>
             <TheadTh>삭제</TheadTh>
             </TheadTr>
             </Thead>
             <TBody>
             <Tr>
             <TbodyTh>항목</TbodyTh>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             </Tr>
             <Tr>
             <TbodyTh>항목</TbodyTh>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             <TbodyTd>
             <CheckBox>
             <CheckInput type="checkbox"></CheckInput>
             <CheckLabel></CheckLabel>
             </CheckBox>
             </TbodyTd>
             </Tr>
             </TBody>
             </Table>
             </TableCont>
             </PopPollList>
             </PopPollListBox>
             <PopButton big>수정</PopButton>
             </PopBox>
             </PopUp> */}


            {/* <PopUp>
             <PopBox point>
             <PopClose><img src={CloseIcon} alt='' /></PopClose>
             <PopHeadTxt>포인트 내역보기</PopHeadTxt>
             <PopPointMenu>
             <PopPointMenuUl>
             <PopPointMenuLi on><a href="#none">전체</a></PopPointMenuLi>
             <PopPointMenuLi><a href="#none">보상</a></PopPointMenuLi>
             <PopPointMenuLi><a href="#none">구매</a></PopPointMenuLi>
             <PopPointMenuLi><a href="#none">보내기</a></PopPointMenuLi>
             <PopPointMenuLi><a href="#none">받기</a></PopPointMenuLi>
             </PopPointMenuUl>
             <PopPointMenuMonth>
             <MonthPrev href="#none">이전</MonthPrev>
             <MonthTxt>2023년 00월</MonthTxt>
             <MonthNext href="#none">다음</MonthNext>
             </PopPointMenuMonth>
             </PopPointMenu>
             <PopPollListBox>
             <BoardBox point>
             <BoardTable>
             <Colgroup>
             <col width="10%"/>
             <col width="13%"/>
             <col width="13%"/>
             <col width="24%"/>
             <col width="40%"/>
             </Colgroup>
             <BoardTableThead>
             <BoardTableTr>
             <BoardTableTh>NO.</BoardTableTh>
             <BoardTableTh>일자</BoardTableTh>
             <BoardTableTh>구분</BoardTableTh>
             <BoardTableTh>포인트</BoardTableTh>
             <BoardTableTh>비고</BoardTableTh>
             </BoardTableTr>
             </BoardTableThead>
             <BoardTableTbody>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>000,000,000</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>1</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             <BoardTableTr>
             <BoardTableTd>10</BoardTableTd>
             <BoardTableTd>00.00.00</BoardTableTd>
             <BoardTableTd>일이삼사</BoardTableTd>
             <BoardTableTd right><span>1</span> Berry</BoardTableTd>
             <BoardTableTd>channel name</BoardTableTd>
             </BoardTableTr>
             </BoardTableTbody>
             </BoardTable>
             </BoardBox>

             <PageBox>
             <Paging firstpage icon href="#none" >맨앞</Paging>
             <Paging prevpage icon href="#none">앞</Paging>
             <Paging active href="#none">1</Paging>
             <Paging href="#none">2</Paging>
             <Paging href="#none">3</Paging>
             <Paging href="#none">4</Paging>
             <Paging href="#none">5</Paging>
             <Paging nextpage icon href="#none">뒤</Paging>
             <Paging lastpage icon href="#none">맨뒤</Paging>
             </PageBox>
             </PopPollListBox>
             <PopButton center>확인</PopButton>
             </PopBox>
             </PopUp> */}


        </>
    )
}

export default User;
