import React, {useEffect, useMemo, useState} from "react";
import Title from "common/components/Title";
import {
    Button,
    ButtonRight,
    CloseButton,
    DropdownIndicator,
    ListInputFile,
    ListLtxt,
    ListRTextArea,
    ListRtxt,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    ModalSpan,
    RemoveButton,
    ReturnTdContent,
    RewardDetailContent,
    RewardDetailTitle,
    StyledModal,
    Thumbnail,
    Wrapper,
} from "common/styledComponents/commonStyle";
import {
    ChannelJoinDiv,
    ChannelJoinLi,
    ChannelJoinUl,
    FlexWrapper,
    LengthCountSpan,
    ThumAddBtn,
    ThumGroup,
    ThumInfo,
    ThumName,
    ThumNameBox,
    ThumNoti,
    ThumNotiList
} from "pages/Join/JoinStyle"
import {BoardBoxCont, ChannelVeiw} from "pages/Channel/ChannelStyle"
import useRequest from '../../hooks/useRequest';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {API_STATE} from '../../common/constants/state';
import Page from '../../common/components/Page';
import pageMaker from '../../common/components/Page/pageMaker';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import useBoard from '../../hooks/useBoard';
import dayjs from 'dayjs';
import DATE from '../../common/constants/date';
import Table from '../../common/components/Table';
import {CHANNEL_DETAIL_STATUS, POLL_LIST_COLUMNS, STATUS} from '../../common/constants/channel';
import {useGlobalStore} from "stores";
import RemoveIcon from '../../asset/img/icon__cancel.png';
import prevImage from "asset/img/img__priview.png";
import storageService from '../../util/storage';
import axios from 'axios';
import CloseIcon from '../../asset/img/icon__close_black.svg';


const ChannelView = () => {
    const idParams = useParams();
    const navigate = useNavigate();
    const {id} = idParams;
    const {register, handleSubmit, watch, setValue, control, reset} = useForm();
    const channelDetailRequest = useRequest("get", `channel/${id}`);
    const channelPollRequest = useRequest("get", `poll/channel/${id}/list`);
    const categoryListRequest = useRequest("get", `category/channel/simple/list`);
    const addChannelProfileRequest = useRequest("post", "image/channel/profile/new");
    const addChannelThumbnailRequest = useRequest("post", "image/channel/thumbnail/new");
    const patchChannelRequest = useRequest("patch", `channel/${id}/update`);
    const [channelCategory, setChannelCategory] = useState([]);
    const [profileImageSrc, setProfileImageSrc] = useState('');
    const [categoryDefaultValue, setCategoryDefaultValue] = useState([]);
    const [thumbnailImageSrc, setThumbnailImageSrc] = useState('');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [formData, setFormData] = useState({offset: 0, limit: 5});
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const [channelDetailInfo, setChannelDetailInfo] = useState({});
    const [channelStatusInfo, setChannelStatusInfo] = useState([]);
    const [stopData, setStopData] = useState({
        reason: '', title: '',
    });
    const [onReturnModal, setOnReturnModal] = useState(false);

    const domain = process.env.REACT_APP_UPLOAD_IMAGE_BASE_URL;

    const {rows, fetch, params} = useBoard(channelPollRequest, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return (
            data &&
            data.map((data) => ({
                ...data,
                status: STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                period: `${dayjs(new Date(data.startAt)).format(DATE.FORMAT.DOT)} ~ ${dayjs(new Date(data.endAt)).format(DATE.FORMAT.DOT)}`,
                // viewStatus: data.viewStatus ? '활성화' : '비활성화',
            }))
        );
    });

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const channelAuth = useMemo(() => {
        const accessAuth = info?.access?.channelAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const formSubmit = async (data) => {
        const formData = new FormData();
        let loadProfileImageUrl = data.profileImageUrl;
        let loadThumbnailImageUrl = data.thumbnailImageUrl;

        if (typeof watch().profileImageUrl !== 'string') {
            const profileImageUrl = watch().profileImageUrl[0];
            formData.append("image", profileImageUrl);
            await addChannelProfileRequest.asyncCall(formData, 'body').then((result) => loadProfileImageUrl = result.response.url).catch((e) => console.log(e))
            formData.delete('image');
        }

        if (typeof watch().thumbnailImageUrl !== 'string') {
            const thumbnailImageUrl = watch().thumbnailImageUrl[0]
            formData.append("image", thumbnailImageUrl);
            await addChannelThumbnailRequest.asyncCall(formData, 'body').then((result) => loadThumbnailImageUrl = result.response.url).catch((e) => console.log(e))
            formData.delete('image');
        }

        const customData = [data].map(data => ({
            ...data,
            profileImageUrl: loadProfileImageUrl,
            thumbnailImageUrl: loadThumbnailImageUrl
        }))

        patchChannelRequest.call(...customData, 'body')
    };

    const handleSelectRow = (row) => {
        if (row.status === '검수중' || row.status === '검수반려') {
            navigate(`/surveyMange/inspection/detail/${row.id}`);
            return
        }

        navigate(`poll/${row.id}`);
    }

    const handlePage = (pageNum) => {
        setCurPage(pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
    }

    const onChangeImage = (e) => {
        let file
        const id = e.target.id;

        if (id === 'profileImageUrl') {
            file = watch().profileImageUrl[0]
        }

        if (id === 'thumbnailImageUrl') {
            file = watch().thumbnailImageUrl[0]
        }

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const res = reader.result;

            if (id === 'profileImageUrl') {
                setProfileImageSrc(res)
            } else if (id === 'thumbnailImageUrl') {
                setThumbnailImageSrc(res)
            }
        };
    };

    const CreateCustomStyles = (width) => {
        return useMemo(
            () => ({
                option: (provided, state) => ({
                    ...provided,
                }),
                control: (provided) => ({
                    ...provided,
                    width: width,
                    border: "1px solid #757575",
                    borderRadius: "8px",
                }),
                singleValue: (provided, state) => ({
                    ...provided,
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: 'none',
                }),
            }),
            [width]
        );
    };


    const removeProfile = (e) => {
        setProfileImageSrc(null)
        reset(prevState => ({...prevState, profileImageUrl: null}))
    }

    const removeThumbnail = (e) => {
        setThumbnailImageSrc(null)
        reset(prevState => ({...prevState, thumbnailImageUrl: null}))
    }

    const handleTextContentLength = (e) => {
        e.target.value = e.target.value.slice(0, 1000)
        setValue('contents', e.target.value);
    }

    const openStopReasonModal = () => {
        setOnReturnModal(true)
        // document.body.style.overflow = 'hidden';
    }

    const closeStopReasonModal = () => {
        // document.body.style.overflow = 'auto';
        setOnReturnModal(false)
    }

    const showReturnModal = async (e) => {
        const token = storageService.get("auth", "accessToken", "local");
        const id = Number(e.currentTarget.id);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/poll/${id}/stop`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            setStopData(prevState => ({
                ...prevState, reason: response.data.response.reason,
                title: rows.filter((row) => row.id === id)[0].title
            }))
            openStopReasonModal()

        } catch (e) {
            console.log(e)
        }
        // setOnReturnModal(true)
    }

    const columnRenderer = (key, value, _, row) => {
        if (key === "viewStatus") {
            return (<ReturnTdContent id={row.id} Activate={row.viewStatus}
                                     onClick={showReturnModal}>
                {row.viewStatus ? '활성화' : '비활성화'}
            </ReturnTdContent>)
        }
        return value
    }

    useEffect(() => {
        channelDetailRequest.call({id}, 'body')
        categoryListRequest.call('', 'body')
    }, [])

    useEffect(() => {
        const {offset, limit} = formData;

        fetch({
            ...params,
            offset,
            limit
        });
    }, [formData]);

    useEffect(() => {
        switch (channelDetailRequest.state) {
            case API_STATE.done:
                if (channelDetailRequest.response.success) {
                    const {response} = channelDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    setChannelDetailInfo(response);
                    setValue("contents", response?.contents);
                    setValue("categoryId", response?.categoryId);
                    setValue("profileImageUrl", response?.profileImageUrl);
                    setValue("thumbnailImageUrl", response?.thumbnailImageUrl);
                    setValue("status", response?.status);

                    if (response?.status) {
                        const customChannelStatusInfo = Object.keys(CHANNEL_DETAIL_STATUS).map((data, index) =>
                            ({
                                id: Number(index),
                                value: data,
                                label: CHANNEL_DETAIL_STATUS[data]
                            })
                        )
                        setChannelStatusInfo(customChannelStatusInfo)
                    }
                }
                break;
            default:
                break;
        }

        switch (categoryListRequest.state) {
            case API_STATE.done:
                if (categoryListRequest.response.success) {
                    const {response} = categoryListRequest.response;
                    if (!response) {
                        return;
                    }
                    const result = response.data;

                    setChannelCategory(result.map((v) => (
                        {id: v.id, value: v.name, label: v.name}
                    )))

                    const customCategoryDefaultValue = result.filter((data) => data.id === watch().categoryId)
                    setValue('categoryId', customCategoryDefaultValue[0]?.id)
                    setCategoryDefaultValue([{
                        value: customCategoryDefaultValue[0]?.name,
                        label: customCategoryDefaultValue[0]?.name
                    }])
                }
                break;
            default:
                break;
        }

        switch (channelPollRequest.state) {
            case API_STATE.done:
                if (channelPollRequest.response.success) {
                    const {response} = channelPollRequest.response;
                    if (!response) {
                        return;
                    }
                }
                break;
            default:
                break;
        }

        switch (patchChannelRequest.state) {
            case API_STATE.done:
                alert('수정이 완료되었습니다.')
                navigate('/channel/manage')
                break;
            default:
                break;
        }

    }, [channelDetailRequest.state, channelDetailRequest.error, channelDetailRequest.response, channelPollRequest.state, channelPollRequest.error, channelPollRequest.response, patchChannelRequest.state]);

    return (
        <>
            <MenuArea>
                <form onSubmit={handleSubmit((data) => formSubmit(data))}>
                    <MenuInfoArea bottomLine>
                        <Title>채널 상세보기</Title>
                        <ChannelVeiw>
                            <ChannelJoinUl no>
                                <Wrapper maxWidth='1030px' Row>
                                    <ChannelJoinLi>
                                        <ListLtxt>채널 상태</ListLtxt>
                                        <ListRtxt>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({field: {onChange}}) =>
                                                    <Select
                                                        components={{DropdownIndicator}}
                                                        options={channelStatusInfo}
                                                        styles={CreateCustomStyles(122)}
                                                        placeholder={watch()?.status && channelStatusInfo.filter((data) => data.value === watch()?.status)[0].label}
                                                        onChange={selectedOption => {
                                                            onChange(selectedOption.value);
                                                        }}
                                                        isSearchable={false}
                                                    />
                                                }
                                                rules={{required: true}}
                                            />
                                        </ListRtxt>
                                    </ChannelJoinLi>
                                    <Wrapper maxWidth='444px'>
                                        <ChannelJoinLi>
                                            <ListLtxt>채널 관리자</ListLtxt>
                                            <ListRtxt>{channelDetailInfo.userId}</ListRtxt>
                                        </ChannelJoinLi>
                                    </Wrapper>
                                </Wrapper>
                            </ChannelJoinUl>
                            <ChannelJoinUl no border>
                                <ChannelJoinLi>
                                    <ListLtxt>채널 카테고리</ListLtxt>
                                    <ListRtxt>
                                        <Controller
                                            name="categoryId"
                                            control={control}
                                            render={({field: {onChange}}) =>
                                                <Select
                                                    components={{DropdownIndicator}}
                                                    options={channelCategory}
                                                    // styles={customStyles}
                                                    styles={CreateCustomStyles(280)}
                                                    placeholder={categoryDefaultValue[0]?.value}
                                                    onChange={selectedOption => {
                                                        onChange(selectedOption.id);
                                                    }}
                                                />
                                            }
                                            rules={{required: true}}
                                        />
                                    </ListRtxt>
                                </ChannelJoinLi>
                                <ChannelJoinLi>
                                    <ListLtxt>채널 명</ListLtxt>
                                    <ListRtxt>{channelDetailInfo.name}</ListRtxt>
                                </ChannelJoinLi>
                                <ChannelJoinLi>
                                    <ListLtxt top>채널 설명</ListLtxt>
                                    <FlexWrapper>
                                        <ListRTextArea
                                            mid
                                            placeholder=""
                                            {...register('contents', {
                                                require: true,
                                                onChange: (e) => handleTextContentLength(e)
                                            })}
                                        />
                                        <LengthCountSpan>{`${watch().contents ? watch().contents.length : 0} / 1000자`}</LengthCountSpan>
                                    </FlexWrapper>
                                </ChannelJoinLi>
                                {/*<ChannelJoinLi>*/}
                                {/*    <ListLtxt top>채널 지갑주소</ListLtxt>*/}
                                {/*    <ListRtxt>*/}
                                {/*        <ListInput*/}
                                {/*            long*/}
                                {/*            type="text"*/}
                                {/*            name="test"*/}
                                {/*            id="test"*/}
                                {/*        />*/}
                                {/*    </ListRtxt>*/}
                                {/*</ChannelJoinLi>*/}
                            </ChannelJoinUl>
                            <ChannelJoinUl border>
                                <ChannelJoinLi>
                                    <ChannelJoinDiv>
                                        <ListLtxt top>채널 프로필 이미지</ListLtxt>
                                        <ListRtxt>
                                            <Thumbnail Round profileImageSrc>
                                                {typeof watch().profileImageUrl === 'string' ?
                                                    <img src={`${domain}${watch().profileImageUrl}`} alt='profile'/> :
                                                    profileImageSrc ? <img src={profileImageSrc} alt='profile'/>
                                                        : <img src={prevImage} alt='profile'/>
                                                }
                                            </Thumbnail>
                                            <ThumGroup>
                                                <ThumInfo>
                                                    <ThumNameBox Relative>
                                                        {watch().profileImageUrl && watch().profileImageUrl[0]?.name ?
                                                            <ThumName>{watch().profileImageUrl[0].name}</ThumName> :
                                                            null
                                                            // <ThumName>파일명 노출 / 길게 작성될 시 좌측부터 ‘...’</ThumName>
                                                        }
                                                        <RemoveButton
                                                            type='button'
                                                            onClick={(e) => removeProfile(e)}
                                                        >
                                                            <img src={RemoveIcon} alt='remove'/>
                                                        </RemoveButton>
                                                    </ThumNameBox>
                                                    <ThumAddBtn htmlFor="profileImageUrl">파일 첨부</ThumAddBtn>
                                                    <Controller
                                                        name="profileImageUrl"
                                                        control={control}
                                                        render={({field: {onChange}}) =>
                                                            <ListInputFile
                                                                type="file"
                                                                id='profileImageUrl'
                                                                accept="image/jpg, image/png, image/jpeg"
                                                                onChange={(e) => {
                                                                    onChange(e.target.files)
                                                                    onChangeImage(e)
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </ThumInfo>
                                                <ThumNoti>
                                                    <ThumNotiList>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg,
                                                        jpeg,
                                                        png, bmp</ThumNotiList>
                                                    <ThumNotiList>*안내메시지</ThumNotiList>
                                                </ThumNoti>
                                            </ThumGroup>
                                        </ListRtxt>
                                    </ChannelJoinDiv>
                                    <ChannelJoinDiv>
                                        <ListLtxt top>채널 썸네일 이미지</ListLtxt>
                                        <ListRtxt>
                                            <Thumbnail>
                                                {typeof watch().thumbnailImageUrl === 'string' ?
                                                    <img src={`${domain}${watch().thumbnailImageUrl}`} alt='profile'/> :
                                                    thumbnailImageSrc ? <img src={thumbnailImageSrc} alt='profile'/>
                                                        : <img src={prevImage} alt='profile'/>
                                                }
                                            </Thumbnail>
                                            <ThumGroup>
                                                <ThumInfo>
                                                    <ThumNameBox Relative>
                                                        {watch().thumbnailImageUrl && watch().thumbnailImageUrl[0]?.name ?
                                                            <ThumName>{watch().thumbnailImageUrl[0].name}</ThumName> :
                                                            null
                                                            // <ThumName>파일명 노출 / 길게 작성될 시 좌측부터 ‘...’</ThumName>
                                                        }
                                                        <RemoveButton
                                                            type='button'
                                                            onClick={(e) => removeThumbnail(e)}
                                                        >
                                                            <img src={RemoveIcon} alt='remove'/>
                                                        </RemoveButton>
                                                    </ThumNameBox>
                                                    <ThumAddBtn htmlFor="thumbnailImageUrl">파일 첨부</ThumAddBtn>
                                                    <Controller
                                                        name="thumbnailImageUrl"
                                                        control={control}
                                                        render={({field: {onChange}}) =>
                                                            <ListInputFile
                                                                type="file"
                                                                id='thumbnailImageUrl'
                                                                accept="image/jpg, image/png, image/jpeg"
                                                                onChange={(e) => {
                                                                    onChange(e.target.files)
                                                                    onChangeImage(e)
                                                                }}
                                                            />

                                                        }
                                                    />
                                                </ThumInfo>
                                                <ThumNoti>
                                                    <ThumNotiList>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg,
                                                        jpeg,
                                                        png, bmp</ThumNotiList>
                                                    <ThumNotiList>*안내메시지</ThumNotiList>
                                                </ThumNoti>
                                            </ThumGroup>
                                        </ListRtxt>
                                    </ChannelJoinDiv>
                                </ChannelJoinLi>
                            </ChannelJoinUl>
                            {
                                channelAuth.some(d => d === "W") &&
                                <ButtonRight>
                                    <Button mini type='submit'>수정 하기</Button>
                                </ButtonRight>
                            }
                        </ChannelVeiw>
                    </MenuInfoArea>
                    <MenuInfoArea>
                        <BoardBoxCont>
                            <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={POLL_LIST_COLUMNS}
                                   rows={rows}
                                   onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}
                                   columnRenderer={columnRenderer} defaultListValue={true}/>
                            <Page pageInfo={pageInfo} handlePage={handlePage}/>
                        </BoardBoxCont>
                    </MenuInfoArea>
                </form>
            </MenuArea>
            {onReturnModal &&
                <StyledModal>
                    <ModalContainer return>
                        <RewardDetailTitle>비활성화 사유 확인</RewardDetailTitle>
                        <RewardDetailContent return>
                            <ModalSpan>{`[${stopData.title}]`}</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>설문이 비활성화 됐습니다.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>아래에 사유를 확인해주세요.</ModalSpan>
                            <ModalSpan margin='0 0 48px 0'>{stopData.reason}</ModalSpan>
                            <ModalSpan margin='auto 0 0 0'>문의 1:1 문의하기 혹은 info@pollberry.net</ModalSpan>
                        </RewardDetailContent>
                        <Button mini onClick={closeStopReasonModal} margin='48px 0 0 0'>확인</Button>
                        <CloseButton onClick={closeStopReasonModal}>
                            <img src={CloseIcon} alt='close'/>
                        </CloseButton>
                    </ModalContainer>
                </StyledModal>
            }
        </>
    )


}

export default ChannelView;
