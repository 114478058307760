import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";


import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"

import {ROLE, SEARCH_OPTIONS, STATUS, USER_LIST_COLUMNS} from "common/constants/user";
import DATE from "common/constants/date";
import {useGlobalStore} from "stores";

const UserList = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const queryPage = searchParams.get('page')
    const queryName = searchParams.get('name') || ''
    const queryRole = searchParams.get('role') || ''
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage || 1,
        name: queryName || '',
        role: queryRole ? queryRole : '',
    });
    const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
    const request = useRequest("get", "user/list");

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const userAuth = useMemo(() => {
        const accessAuth = info?.access?.userAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);


    const {rows, fetch} = useBoard(request, (res) => {
            setTotalNum(res.totalCount);
            // setTotalNum(12);
            const {data} = res;
            return data && data.map((data) => ({
                ...data,
                role: ROLE[data.role],
                status: STATUS[data.status],
                createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT)
            }));
        },
        {
            offset: (parseInt(searchParams.get('page')) - 1 || 0),
            limit: 10
        }
    );

    const handleSelectRow = (row) => {
        navigate(`/user/userDetail/${row.id}?page=${curPage}`);
    }

    const handleSearch = (data) => {
        const {selectedOption} = data;
        const {name, limit, role} = formData;

        searchParams.set('role', role)

        if (selectedOption) {
            searchParams.set('page', '1')
            searchParams.set('role', selectedOption)
            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, status: selectedOption})
        }


        if (name || name === '') {
            searchParams.set('page', '1')
            searchParams.set('name', name)

            setCurPage(1)
            setFormData({...formData, offset: 0, page: 1, name: name})
        }

        setSearchParams(searchParams)

        fetch({
            name: name || '',
            role: selectedOption || '',
            offset: 0,
            limit
        });
    }

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setSearchParams(searchParams)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, name: ''})
        setCurPage(pageNum)
    };

    const columnRenderer = (key, value) => {
        if (key === "status" && value === "정지") {
            return <span style={{color: "#C91615"}}>{value}</span>
        }
        return value
    }

    // useEffect(() => {
    //
    //     setFormData(prevState => ({
    //         ...prevState,
    //         page: Number(queryPage) || 1,
    //         name: queryName || '',
    //         offset: queryPage ? Number(queryPage) - 1 : 0,
    //         role: queryRole || '',
    //     }))
    //
    //
    //     fetch({
    //         name: queryName || '',
    //         offset: queryPage ? Number(queryPage) - 1 : 0,
    //         role: queryRole || '',
    //         limit: 10,
    //     })
    //
    // }, [queryPage, queryName]);

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            page: 1,
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            role: queryRole || '',
        }))

        setSearchParams(searchParams)

        fetch({
            name: queryName || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            role: queryRole || '',
            limit: 10,
        })

    }, [queryRole, queryPage]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>사용자 관리
                        {
                            userAuth.find(d => d === "W") &&
                            <Button tit onClick={() => navigate('/userCreate')}>신규 회원 생성하기</Button>
                        }
                    </Title>
                    <ListSearch
                        options={SEARCH_OPTIONS}
                        initOption={queryRole ? queryRole : formData.role}
                        onSearch={handleSearch}
                        inputValue={formData.name}
                        onChange={(e) => setFormData(prevState => ({...formData, name: e.target.value}))}
                        role='role'
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={USER_LIST_COLUMNS} rows={rows}
                           onSelectRow={handleSelectRow} columnRenderer={columnRenderer} totalNum={totalNum}
                           searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    )
}

export default UserList;
