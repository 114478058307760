import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import DATE from "common/constants/date";
import ListSearch from "common/components/ListSearch";
import Table from "common/components/Table";
import Page from "common/components/Page";
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import pageMaker from "../../../common/components/Page/pageMaker";
import {useGlobalStore} from "stores";
import {TOS_LIST_COLUMNS} from '../../../common/constants/noticeBoard';

const PolicyList = () => {
    const navigate = useNavigate();
    const [totalNum, setTotalNum] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [curPage, setCurPage] = useState(1);
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        title: searchParams || '',
    });
    const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
    const request = useRequest("get", "tos/list");

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const boardAuth = useMemo(() => {
        const accessAuth = info?.access?.boardAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const {rows, fetch, params} = useBoard(request, (res) => {
            setTotalNum(res.totalCount);
            const {data} = res;
            return (
                data &&
                data.map((data) => ({
                    ...data,
                    createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
                    updatedAt: data.updatedAt ? dayjs(new Date(data.updatedAt)).format(DATE.FORMAT.DOT) : null,
                }))
            );
        },
    );

    const handleSelectRow = (row) => {
        navigate(`/tos/tosDetail/${row.id}?page=${curPage}`);
    };

    function updateSearchParams(key, value) {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    }

    const handlePage = (pageNum) => {
        updateSearchParams('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    };

    const handleSearch = (data) => {
        const {text} = data;

        if (text || text === '') {
            updateSearchParams('title', text);
            updateSearchParams('page', '1')
            setCurPage(1)
            // setFormData({...formData, offset: 0, page: 1, title: title})
        }

        fetch({
            ...formData,
            title: text || '',
            page: 1,
            offset: 0,
        });
    };

    const movePolicyPost = () => {
        navigate("/tosPost");
    };

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            // offset: 1
        })

    }, [queryPage, queryTitle,]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>
                        이용약관 관리
                        {
                            boardAuth.find(d => d === "W") &&
                            <Button tit onClick={movePolicyPost}>
                                이용약관 등록하기
                            </Button>
                        }

                    </Title>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}

                    />
                    <Table pageData={formData} columns={TOS_LIST_COLUMNS} rows={rows} onSelectRow={handleSelectRow}
                           totalNum={totalNum} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
        // <>
        //     <MenuArea>
        //         <MenuInfoArea>
        //             <Title btn>이용약관 관리
        //                 <Button tit >이용약관 등록하기</Button>
        //             </Title>

        //             <BoardBox>
        //             <BoardTable>
        //                 <Colgroup>
        //                     <col width="5.5%"/>
        //                     <col width="10%"/>
        //                     <col width="40%"/>
        //                     <col width="10%"/>
        //                     <col width="10%"/>
        //                     <col width="7.5%"/>
        //                 </Colgroup>
        //                 <BoardTableThead>
        //                     <BoardTableTr>
        //                         <BoardTableTh>NO.</BoardTableTh>
        //                         <BoardTableTh>구분</BoardTableTh>
        //                         <BoardTableTh>제목</BoardTableTh>
        //                         <BoardTableTh>작성자</BoardTableTh>
        //                         <BoardTableTh>등록일</BoardTableTh>
        //                         <BoardTableTh>조회</BoardTableTh>
        //                     </BoardTableTr>
        //                 </BoardTableThead>
        //                 <BoardTableTbody>
        //                     <BoardTableTr>
        //                         <BoardTableTd>1</BoardTableTd>
        //                         <BoardTableTd>구분내용</BoardTableTd>
        //                         <BoardTableTd>제목내용</BoardTableTd>
        //                         <BoardTableTd>작성자내용</BoardTableTd>
        //                         <BoardTableTd>등록일내용</BoardTableTd>
        //                         <BoardTableTd>조회내용</BoardTableTd>

        //                     </BoardTableTr>

        //                 </BoardTableTbody>
        //             </BoardTable>
        //         </BoardBox>

        //             <Page />
        //         </MenuInfoArea>
        //     </MenuArea>
        // </>
    );
};

export default PolicyList;
