export const STATUS_OPTIONS = [
    {value: 'NORMAL', label: '정상'},
    {value: 'STOP', label: '정지'},
]

export const STATUS = {
    NORMAL: '정상',
    STOP: '정지',
}

export const INSPECTION_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '9%'
}, {
    key: 'channelName',
    title: '채널명',
    width: '16%'
}, {
    key: 'title',
    title: '설문 제목',
    width: '*'
}, {
    key: 'createdAt',
    title: '등록일',
    width: '9%'
}, {
    key: 'startAt',
    title: '시작일',
    width: '9%'
}, {
    key: 'endAt',
    title: '종료일',
    width: '9%'
}, {
    key: 'status',
    title: '상태',
    width: '16%'
}];

export const INSPECTION_SEARCH_KEY_OPTIONS = [
    {
        text: '전체',
        value: 'all'
    },
    {
        text: '채널명',
        value: 'name'
    }, {
        text: '설문 제목',
        value: 'title'
    }
];


export const CHANNEL_LIST_COLUMNS = [
    {
        key: 'id',
        title: 'NO.',
        width: '3.8%'
    }, {
        key: 'channelName',
        title: '채널명',
        width: '4.5%'
    }, {
        key: 'title',
        title: '설문 제목',
        width: '15%'
    }, {
        key: 'createdAt',
        title: '등록일',
        width: '5%'
    }, {
        key: 'period',
        title: '설문 기간',
        width: '8%'
    }, {
        key: 'participantsCount',
        title: '참여자',
        width: '4.5%'
    }, {
        key: 'status',
        title: '상태',
        width: '3.8%'
    }
];

export const CHANNEL_SEARCH_KEY_OPTIONS = [{
    text: '전체',
    value: 'all'
}, {
    text: '채널명',
    value: 'name'
}, {
    text: '설문 제목',
    value: 'title'
}];

export const CHANNEL_SEARCH_FILTER_OPTIONS = [{
    text: '전체',
    value: ''
}, {
    text: '진행중',
    value: 'ONGOING'
}, {
    text: '완료',
    value: 'COMPLETE'
}];

export const POINTMNG_REFUNDMNG_STATE_LIST = [
    {
        key: 'id',
        title: 'NO.',
        width: '5%'
    },
    {
        key: 'createdAt',
        title: '일자',
        width: '8%'
    },
    {
        key: 'type',
        title: '구분',
        width: '10%'
    },
    {
        key: 'userName',
        title: '회원 명',
        width: '15%'
    },
    // {
    //     key: 'berry',
    //     title: 'Berry 단위',
    //     width: '25%'
    // },
    // {
    //     key: 'token',
    //     title: 'Swap Token 단위',
    //     width: '25%'
    // },
    {
        key: 'Reward Token',
        title: 'Swap Token 단위',
        width: '50%'
    },
    {
        key: 'status',
        title: '상태',
    },
]

export const POINTMNG_TOPUPMNG_STATE_LIST = [
    {
        key: 'id',
        title: 'NO.'
    },
    {
        key: 'createdAt',
        title: '일자'
    },
    {
        key: 'userType',
        title: '회원 구분'
    },
    {
        key: 'amount',
        title: '충전 Berry'
    },
    {
        key: 'name',
        title: 'Berry 합산액'
    },
    {
        key: 'userName',
        title: '등록 관리자'
    },
    {
        key: 'berry',
        title: '상태'
    }
]

export const USER_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '5%'
}, {
    key: 'nickname',
    title: '닉네임',
    width: '15%'
}, {
    key: 'title',
    title: '설문 제목',
    width: '*'
}, {
    key: 'createdAt',
    title: '등록일',
    width: '7.5%'
}, {
    key: 'startAt',
    title: '시작일',
    width: '7.5%'
}, {
    key: 'endAt',
    title: '종료일',
    width: '7.5%'
}, {
    key: 'participantsCount',
    title: '참여자',
    width: '15%'
}, {
    key: 'status',
    title: '상태',
    width: '9.5%'
}];

export const USER_SEARCH_KEY_OPTIONS = [
    {
        text: '전체',
        value: 'all'
    },
    {
        text: '닉네임',
        value: 'name'
    }, {
        text: '설문 제목',
        value: 'title'
    }
];

export const USER_SEARCH_KEY_OPTIONS_TWO = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '닉네임',
        value: 'name'
    },
    {
        text: '채널 명',
        value: 'title'
    }
];

export const USER_SEARCH_FILTER_OPTIONS = [{
    text: '전체',
    value: ''
}, {
    text: '진행중',
    value: 'ONGOING'
}, {
    text: '완료',
    value: 'COMPLETE'
}];

export const SURVEY_DETAIL_OPTIONS = [{
    text: '정상',
    value: 'ONGOING'
}, {
    text: '정지',
    value: 'STOP'
}, {
    text: '완료',
    value: 'COMPLETE'
}, {
    text: '검수중',
    value: 'INSPECTION'
}, {
    text: '검수 반려',
    value: 'RETURN'
}
];

export const SURVEY_DETAIL_OPTIONS_VER2 = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '정상',
        value: 'ONGOING'
    },
    {
        text: '정지',
        value: 'STOP'
    },
    {
        text: '완료',
        value: 'COMPLETE'
    }
];

export const SURVEY_DETAIL_OPTIONS_CHARGE = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '충전',
        value: 'ONGOING'
    },
    {
        text: '취소',
        value: 'STOP'
    }
];

export const SURVEY_DETAIL_OPTIONS_USAGE = [
    {
        text: '전체',
        value: ''
    },
    {
        text: '정상',
        value: 'ONGOING'
    },
    {
        text: '취소',
        value: 'STOP'
    }
];

export const USER_SURVEY_DETAIL_OPTIONS = [{
    text: '진행중',
    value: 'ONGOING'
}, {
    text: '완료',
    value: 'COMPLETE'
}
];

export const INSPECTION_SURVEY_DETAIL_OPTIONS = [{
    text: '검수중',
    value: 'ONGOING'
}, {
    text: '검수 반려',
    value: 'RETURN'
}
];

export const POINTMNG_REWARDMNG_STATE_LIST = [
    {
        key: 'id',
        title: 'NO.'
    },
    {
        key: 'date',
        title: '일자'
    },
    {
        key: 'sender',
        title: '구분'
    },
    {
        key: 'title',
        title: '설문 제목'
    },
    {
        key: 'berry',
        title: '보상 합계'
    },
    // {
    //     key: 'gubun',
    //     title: '구분'
    // },
    {
        key: 'gubun',
        title: '참여 인원'
    },
    {
        key: 'gubun',
        title: '상태'
    },
]

export const POINTMNG_EXCHANGEMNG_STATE_LIST = [
    {
        key: 'number',
        title: 'NO.'
    },
    {
        key: 'date',
        title: '일자'
    },
    {
        key: 'name',
        title: '회원 명'
    },
    {
        key: 'berry',
        title: 'Berry 단위'
    },
    {
        key: 'coin',
        title: 'Coin'
    },
    {
        key: 'gubun',
        title: '구분'
    },
]

export const POINTMNG_USERMNG_STATE_LIST = [
    {
        key: 'number',
        title: 'NO.'
    },
    {
        key: 'useDate',
        title: '사용일'
    },
    {
        key: 'memberType',
        title: '회원 구분'
    },
    {
        key: 'name',
        title: '회원 명'
    },
    {
        key: 'berryState',
        title: 'Berry 현황'
    }
]

export const POINTMNG_USERMNG_POINT_DETAIL = [
    {
        key: 'id',
        title: 'NO.'
    },
    {
        key: 'latestAt',
        title: '일자'
    },
    {
        key: 'role',
        title: '회원 구분'
    },
    {
        key: 'userName',
        title: '회원 명'
    },
    {
        key: 'totalPoint',
        title: 'Berry Point 현황'
    },
    {
        key: '--',
        title: 'Reward Token 현황'
    },
    {
        key: 'status',
        title: '상태'
    },
]
