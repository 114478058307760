import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Register from "../../../../common/components/Register";
import useRequest from "../../../../hooks/useRequest";
import {API_STATE} from "common/constants/state";

function FaqRegister(props) {
    const navigate = useNavigate();
    const request = useRequest("post", "faq/new");
    // const [requestData, setRequestData] = useState({});
    const [editorDate, setEditorData] = useState("");
    // const onChangeType = (e) => {
    //     const type = e.target.value;
    //     setRequestData({...requestData, type: type, contents: "", imageUrls: []});
    // };
    //
    // const onChangeTitle = (e) => {
    //     const title = e.target.value;
    //     setRequestData({...requestData, title: title, contents: "", imageUrls: []});
    // };
    //
    // const onSubmit = () => {
    //     request.call({...requestData, contents: editorDate}, "body");
    // };

    useEffect(() => {
        switch (request.state) {
            case API_STATE.done:
                if (request.response.success) {
                    alert('저장 되었습니다');
                    navigate('/faq/list');
                }
                break;
            default:
                break;
        }
    }, [request.state, request.error, request.response]);

    return (
        <>
            <Register
                request={request}
                type='faq'
                title={"FAQ 등록하기"}
                topShow={true}
                setEditorData={setEditorData}
                editorDate={editorDate}
            />
        </>
    );
}

export default FaqRegister;
