import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import useRequest from "hooks/useRequest";
import useImageUpload from "hooks/useImageUpload";
import {API_STATE} from "common/constants/state";
import {UPLOAD_IMAGE_BASE_URL} from "common/constants/env";
import TestImg from "asset/img/img__priview.png"

import {
    MenuInfoCont,
    MenuInfoSub,
    MyImg,
    MyLeft,
    MyRight,
    PointBottomGroup,
    PointBottomSpan,
    PointBottomStrong,
    PointBox,
    PointListDiv,
    PointToptxt,
    UploadBox,
    UploadImage,
    UploadInput,
    UploadLabel
} from "pages/Mypage/MypageStyle"
import {
    ButtonRight,
    Center,
    GrayButton,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRlink,
    ListRtxt,
    ListUl
} from "common/styledComponents/commonStyle";

const ImageUpload = ({value, onChange}) => {
    const {imageSrc, imageName, onUploadImage} = useImageUpload("image/channel/profile/new", "image");
    const [_value, setValue] = useState(value);

    useEffect(() => {
        if (!imageSrc) {
            return;
        }
        onChange && onChange(imageSrc);
        setValue(imageName);
    }, [imageSrc, imageName]);

    return (
        // <MyImg>
        //     <img style={{width: "100%", height: "100%"}} src={imageSrc ? UPLOAD_IMAGE_BASE_URL + imageSrc : TestImg}></img>
        //     <MyImgUpload for="test">
        //         <MyImgUploadBtn><img src={AddImg} alt='등록하기'></img></MyImgUploadBtn>
        //         <ListInputFile
        //             type="file"
        //             id="test"
        //             accept="image/*"
        //             onChange={(e) => onUploadImage(e.target.files[0])}
        //         />
        //     </MyImgUpload>
        // </MyImg>
        <UploadBox>
            <UploadInput
                id="file"
                type="file"
                accept="image/jpg, image/png, image/jpeg"
                name="file"
                onChange={(e) => onUploadImage(e.target.files[0])}
            />
            <UploadLabel htmlFor="file"/>
            <UploadImage src={imageSrc ? UPLOAD_IMAGE_BASE_URL + imageSrc : TestImg} alt=""/>
        </UploadBox>
    )
}

const MypageBiz = ({myInfo}) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset
    } = useForm();


    const editInfoRequest = useRequest("patch", "/user/biz/update");
    const handleEditInfo = (data) => {
        editInfoRequest.call(data);
    };

    useEffect(() => {
        const {
            name,
            email,
            phone,
            profileImageUrl
        } = myInfo;
        reset({
            name,
            email,
            phone,
            profileImageUrl: profileImageUrl || ""
        })
    }, [myInfo]);

    useEffect(() => {
        switch (editInfoRequest.state) {
            case API_STATE.done:
                if (editInfoRequest.response.success) {
                    alert("변경 되었습니다.");
                } else {

                }

                break;
            default:
                break;
        }
    }, [editInfoRequest.state, editInfoRequest.error, editInfoRequest.response]);

    return (
        <>
            <MenuInfoCont>
                <Center>
                    <MyLeft>
                        <MyImg>
                            {/* <ImageUploader /> */}
                            {/* <input type="file"></input> */}
                            <ImageUpload
                                value={myInfo.profileImageUrl}
                                onChange={(value) => {
                                    setValue("profileImageUrl", UPLOAD_IMAGE_BASE_URL + value);
                                }}
                            />
                        </MyImg>
                        {/* <MyChannelName>{myInfo.companyName}</MyChannelName> */}
                        <PointBox>
                            <PointListDiv>
                                <PointToptxt>포인트 현황</PointToptxt>
                                <PointBottomGroup>
                                    <PointBottomStrong>{myInfo.totalPoint}</PointBottomStrong>
                                    <PointBottomSpan>Berry</PointBottomSpan>
                                </PointBottomGroup>
                            </PointListDiv>
                        </PointBox>
                    </MyLeft>

                    <MyRight>
                        <List>
                            <ListUl>
                                <ListLi>
                                    <ListLtxt>회원 등급</ListLtxt>
                                    <ListRtxt>비즈 회원</ListRtxt>
                                </ListLi>
                                <ListLi>
                                    <ListLtxt>아이디</ListLtxt>
                                    <ListRtxt>{myInfo.userId}</ListRtxt>
                                </ListLi>
                                <ListLi>
                                    <ListLtxt>비밀번호</ListLtxt>
                                    <ListRtxt>
                                        <ListRlink href="#none">비밀번호 변경하기</ListRlink>
                                    </ListRtxt>
                                </ListLi>
                                <ListLi>
                                    <ListLtxt>블록체인 지갑 주소</ListLtxt>
                                    <ListRtxt>{myInfo.walletAddress}</ListRtxt>
                                </ListLi>
                                <ListLi>
                                    <ListLtxt>니모닉키</ListLtxt>
                                    <ListRtxt>
                                        <ListRlink href="#none">니모닉키 확인하기</ListRlink>
                                    </ListRtxt>
                                </ListLi>
                            </ListUl>
                        </List>
                    </MyRight>
                </Center>
            </MenuInfoCont>
            <MenuInfoCont info>
                <MenuInfoSub>채널 회원 정보</MenuInfoSub>
                <form onSubmit={handleSubmit(handleEditInfo)}>
                    <List channel>
                        <ListUl>
                            <ListLi>
                                <ListLtxt>회사명</ListLtxt>
                                <ListRtxt>{myInfo.companyName}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>사업자등록번호</ListLtxt>
                                <ListRtxt>{myInfo.companyNumber}</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>담당자</ListLtxt>
                                <ListRtxt>
                                    <ListInput type="text" placeholder="입력"
                                               defaultValue={myInfo.name} {...register('name')}/>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>담당자 이메일주소</ListLtxt>
                                <ListRtxt>
                                    <ListInput type="text" placeholder="입력"
                                               defaultValue={myInfo.email}  {...register('email')}/>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>담당자 연락처</ListLtxt>
                                <ListRtxt>
                                    <ListInput type="text" placeholder="입력"
                                               defaultValue={myInfo.phone}  {...register('phone')}/>
                                </ListRtxt>
                            </ListLi>
                        </ListUl>
                    </List>
                    <ButtonRight>
                        <GrayButton
                            {...(
                                myInfo.name === watch().name &&
                                myInfo.email === watch().email &&
                                myInfo.phone === watch().phone
                            )
                            && {disabled: true}}
                        >정보 변경하기</GrayButton>
                    </ButtonRight>
                </form>
            </MenuInfoCont>
        </>
    )
}

export default MypageBiz;
