import React from 'react';
import Header from './Header';
import styled from 'styled-components';
import Main from 'pages/Main/Main';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

function Layout({children}) {

    return (
        <Container>
            <Header/>
            <Main>
                {children}
            </Main>
        </Container>
    );
}

export default Layout;
