import React, {useEffect, useMemo, useRef, useState} from "react";
import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import Page from "common/components/Page";
import useRequest from "../../../hooks/useRequest";
import dayjs from "dayjs";
import DATE from "../../../common/constants/date";
import useBoard from "../../../hooks/useBoard";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    DateSearch,
    SearchConditionBox,
    SearchConditionDiv,
    SearchConditionText,
    SearchDateBox,
    SearchDateInput,
    SearchDateInputSpan,
    SearchDateMid,
    SearchDateTxt
} from "../../ServeyManage/ChannelSurvey/channelSurveySytle";
import SearchFilter from "../../../common/components/SearchFilter";
import ListSearch from "../../../common/components/ListSearch";
import Table from "../../../common/components/Table";
import {
    DATE_FILTER_OPTIONS,
    QNA_LIST_COLUMNS,
    QNA_QA_TYPE_STATUS,
    QNA_SEARCH_FILTER_OPTIONS,
    QNA_SEARCH_KEY_OPTIONS,
    STATUS,
} from "../../../common/constants/qna";
import pageMaker from "../../../common/components/Page/pageMaker";
import Select from 'react-select';

const QnaList = () => {
    const request = useRequest("get", "qa/list");
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const queryPage = searchParams.get('page')
    const queryTitle = searchParams.get('title')
    const queryStatus = searchParams.get('status')
    const queryStartAt = searchParams.get('startAt')
    const queryEndAt = searchParams.get('endAt')
    const queryType = searchParams.get('type') || ''
    const queryDateOption = searchParams.get('dateOption') || 'ALL'
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? queryPage - 1 : 0,
        limit: 10,
        page: queryPage || 1,
        title: queryTitle || '',
        status: queryStatus ? queryStatus : '',
        startAt: queryStartAt ? queryStartAt : '',
        endAt: queryEndAt ? queryEndAt : '',
        type: queryType ? queryType : '',
    });
    const [dateFilterOption, setDateFilterOption] = useState(formData.dateOption ? formData.dateOption : queryDateOption);
    const [totalNum, setTotalNum] = useState(0);
    const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
    const refStartAt = useRef(null);
    const refEndAt = useRef(null);

    const getSearchDate = () => {
        const now = dayjs(new Date());
        let date = {
            startAt: queryStartAt ? dayjs(queryStartAt) : now,
            endAt: queryEndAt ? dayjs(queryEndAt) : now,
        };

        switch (dateFilterOption) {
            case "1M":
                date.startAt = now.subtract(1, "month");
                break;
            case "3M":
                date.startAt = now.subtract(3, "month");
                break;
            case "6M":
                date.startAt = now.subtract(6, "month");
                break;
            case "1Y":
                date.startAt = now.subtract(1, "year");
                break;
            case "SELECT":
                date.startAt = dayjs(refStartAt.current.value);
                date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
                break;
            case 'ALL':
                date.startAt = '';
                date.endAt = '';
                break;
            default:
                date.startAt = now;
                date.endAt = now;
                break;
        }

        return {
            startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
            endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
        };
    };

    const {startAt, endAt} = getSearchDate();

    const {rows, fetch, params} = useBoard(
        request,
        (res) => {
            setTotalNum(res.totalCount);
            const {data} = res;
            return (
                data &&
                data.map((data) => ({
                    ...data,
                    qaType: QNA_QA_TYPE_STATUS[data.qaType],
                    status: STATUS[data.status],
                    createAt: dayjs(new Date(data.createAt)).format(DATE.FORMAT.DOT),
                    startAt: dayjs(new Date(data.createAt)).format(DATE.FORMAT.DOT),
                    endAt: dayjs(new Date(data.createAt)).format(DATE.FORMAT.DOT),
                }))
            );
        },
        {
            limit: 10,
            offset: 0,
            startAt,
            endAt,
        },
    );

    function updateSearchParams(key, value) {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    }

    const handleSelectRow = (row) => {
        if (formData.title) {
            navigate(`/qna/qnaDetail/${row.id}?page=${curPage}?title=${formData.title}`);
        } else {
            navigate(`/qna/qnaDetail/${row.id}?page=${curPage}`);
        }
    };

    const handleChangeSearchKey = (option) => {
        updateSearchParams('type', option.value)
        setFormData({...formData, type: option.value})
    };

    const handleDateFilterSelect = (option) => {
        setDateFilterOption(option);
        const {startAt, endAt} = getSearchDate();
        refEndAt.current.value = endAt;

        if (option === 'SELECT') {
            refStartAt.current.value = dayjs().format(DATE.FORMAT.DASH);

        } else {
            refStartAt.current.value = startAt;
        }

        setFormData(prevState => ({...prevState, startAt: startAt, endAt: endAt, dateOption: option}))

    };

    const handleChangeStartAt = () => {
        const {startAt} = getSearchDate();
        refStartAt.current.value = startAt;
        updateSearchParams('startAt', startAt)
        setFormData({...formData, startAt: startAt})
    }

    const handleChangeEndAt = () => {
        const {endAt} = getSearchDate();
        refEndAt.current.value = endAt;
        updateSearchParams('endAt', endAt)
        setFormData({...formData, endAt: endAt})
    }

    const handlePage = (pageNum) => {
        updateSearchParams('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
        setCurPage(pageNum)
    };

    const handleSearch = (data) => {
        const {selectedOption} = data;
        const {startAt, endAt} = getSearchDate();
        const {type, title, limit,} = formData;

        searchParams.set('dateOption', dateFilterOption);
        updateSearchParams('type', type);

        if (title || title === '') {
            searchParams.set('page', '1')

            setCurPage(1)
            searchParams.set('title', title);
            setFormData({...formData, offset: 0, page: 1})

        }

        if (selectedOption) {
            searchParams.set('page', '1')
            setCurPage(1)
            updateSearchParams('status', selectedOption);
            setFormData({...formData, offset: 0, page: 1})
        }

        if (startAt && endAt) {
            setCurPage(1)

            if (dateFilterOption) {
                setSearchParams({startAt: startAt, endAt: endAt, dateOption: dateFilterOption})
            } else {
                setSearchParams({startAt: startAt, endAt: endAt})
            }

            setFormData({
                ...formData,
                page: 1,
                offset: 0,
                startAt: startAt,
                endAt: endAt,
                dateOption: dateFilterOption,
            })
        }

        setSearchParams(searchParams)

        fetch({
            title: title || '',
            status: selectedOption || '',
            startAt: startAt || '',
            endAt: endAt || '',
            type: type || '',
            offset: 0,
            limit

        });
    }

    const customStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
            }),
            control: (provided) => ({
                ...provided,
                width: 168,
                border: "1px solid #757575",
                borderRadius: "8px",
                background: '#F5F5F5',
            }),
            singleValue: (provided, state) => ({
                ...provided,
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none',
            }),
        }),
        [],
    );

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage) || 1,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            dateOption: queryDateOption || 'ALL'
        }))

        setDateFilterOption(queryDateOption)

        fetch({
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
            type: queryType || '',
            startAt: queryStartAt || '',
            endAt: queryEndAt || '',
            status: queryStatus || '',
            limit: 10,
        })

    }, [queryPage, queryTitle, queryStartAt, queryEndAt, queryStatus]);

    console.log(searchParams, 'searchParams')

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>1:1 문의 관리 </Title>
                    <DateSearch>
                        <SearchConditionBox>
                            <SearchConditionText top>검색 조건</SearchConditionText>
                            <Select
                                options={QNA_SEARCH_KEY_OPTIONS}
                                placeholder={queryType ? QNA_QA_TYPE_STATUS[queryType] : QNA_SEARCH_KEY_OPTIONS[0].label}
                                value={queryType ? QNA_QA_TYPE_STATUS[queryType] : QNA_SEARCH_KEY_OPTIONS[0].label}
                                onChange={handleChangeSearchKey}
                                styles={customStyles}
                                isSearchable={false}
                            />
                        </SearchConditionBox>
                        <SearchConditionBox>
                            <SearchConditionText>등록일 조회 기간</SearchConditionText>
                            <SearchConditionDiv>
                                <SearchFilter
                                    options={DATE_FILTER_OPTIONS}
                                    option={formData.dateOption}
                                    onSelect={handleDateFilterSelect}
                                    type="filter"
                                />
                                <SearchDateBox>
                                    <SearchDateInputSpan>
                                        <SearchDateInput
                                            type="date"
                                            {...(dateFilterOption !== "SELECT" && {
                                                disabled: true,
                                            })}
                                            ref={refStartAt}
                                            onChange={() => handleChangeStartAt()}
                                        />
                                        <SearchDateTxt>{startAt || startAt === '' ? startAt : formData.startAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                    <SearchDateMid>~</SearchDateMid>
                                    <SearchDateInputSpan>
                                        <SearchDateInput
                                            type="date"
                                            {...(dateFilterOption !== "SELECT" && {
                                                disabled: true,
                                            })}
                                            ref={refEndAt}
                                            min={startAt}
                                            onChange={() => handleChangeEndAt()}
                                        />
                                        <SearchDateTxt>{endAt || endAt === '' ? endAt : formData.endAt}</SearchDateTxt>
                                    </SearchDateInputSpan>
                                </SearchDateBox>
                            </SearchConditionDiv>
                        </SearchConditionBox>
                    </DateSearch>
                    <ListSearch options={QNA_SEARCH_FILTER_OPTIONS}
                                initOption={queryStatus ? queryStatus : formData.status}
                                onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                                qna='제목 및 닉네임으로 검색할 수 있습니다.'
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={QNA_LIST_COLUMNS} rows={rows}
                           onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    );
};

export default QnaList;
