import React from "react";
import {
    PreviewBoxBottom,
    PreviewBoxing,
    PreviewBoxTop,
    PreviewCont,
    RankAll,
    RankAllShow,
    RankCateLi,
    RankCateUl,
    RankListImg,
    RankListLi,
    RankListNum,
    RankListTxt,
    RankListUl,
    RankSub,
    RankTit,
    RankTxt,
    VideoDim
} from "./CreateRankingStyle";
import ReactPlayer from 'react-player/lazy'

function Preview({...props}) {

    const {
        title,
        startAt,
        endAt,
        source,
        items,
        videoUrl,
        categoryName,
        domain,
        surveyEnabled,
    } = props;

    return (
        // videoW : 가로동영상, videoH : 세로동영상
        <PreviewCont video>
            <VideoDim/>
            <ReactPlayer
                url={videoUrl}
                className="react-player"
                width="100%"
                height="100%"
                muted={true}
                loop={true}
                playing={true}/>
            <PreviewBoxing>
                <PreviewBoxTop>
                    <RankTxt>{surveyEnabled ? 'Berry Ranking' : 'Public Ranking'}</RankTxt>
                    <RankCateUl>
                        <RankCateLi>{categoryName ? categoryName : "Category"}</RankCateLi>
                    </RankCateUl>
                    <RankTit>{title && title.length > 15 ? title.slice(0, 15) + '...' : title}</RankTit>
                    <RankSub>{startAt} ~ {endAt}</RankSub>
                    <RankSub>
                        <span>출처 : {source && source.length > 15 ? source.slice(0, 15) + '...' : source}</span>
                    </RankSub>
                </PreviewBoxTop>
                <PreviewBoxBottom>
                    <RankListUl>
                        {items && items.map((item, idx) => {
                            return (
                                <RankListLi key={idx}>
                                    <RankListNum>{idx + 1}</RankListNum>
                                    <RankListImg
                                        src={item.imageUrl && item.imageUrl.includes('data:') ? item.imageUrl : item.url !== '' ? `${domain}${item.url}` : null}
                                    />
                                    <RankListTxt active>{item.text}</RankListTxt>
                                    {/*<RankListTxt active>순위 변동 감지~</RankListTxt>*/}
                                </RankListLi>
                            );
                        })}
                    </RankListUl>
                    <RankAll>
                        <RankAllShow href="#none">전체 랭킹 순위 보기</RankAllShow>
                    </RankAll>
                </PreviewBoxBottom>
            </PreviewBoxing>
        </PreviewCont>
    );
}

export default Preview;
