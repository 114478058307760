import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {ROLE} from "common/constants/user";
import {POINTMNG_USERMNG_POINT_DETAIL, POINTMNG_USERMNG_POINT_DETAIL_STATUS} from '../../../common/constants/point';

const UserMng = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const INIT_OPTION = {
    BOARD_PARAMS: {
      offset: (parseInt(searchParams.get('page')) - 1 || 0),
      limit: 10
    },
    // KEY: '',
    // DATE_FILTER_OPTION: '1M',
    // START_AT: '2022-12-19',
    // END_AT: '2023-01-19'
  }
  const [totalNum, setTotalNum] = useState(0);
  const [curPage, setCurPage] = useState(null);
  const queryPage = searchParams.get('page')
  const queryName = searchParams.get('name')
  const queryStartAt = searchParams.get('startAt')
  const queryEndAt = searchParams.get('endAt')
  const [formData, setFormData] = useState({offset: 0, limit: 10, page: curPage});
  // const [dateFilterOption, setDateFilterOption] = useState(formData.dateOption ? formData.dateOption : queryDateOption);

  const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
  const request = useRequest("get", "point/user/list");

  // const refStartAt = useRef(null);
  // const refEndAt = useRef(null);

  // const getSearchDate = () => {
  //     const now = dayjs(new Date());
  //     let date = {
  //         startAt: now,
  //         endAt: now
  //     };
  //     switch (dateFilterOption) {
  //         case '1M':
  //             date.startAt = now.subtract(1, 'month');
  //             break;
  //         case '3M':
  //             date.startAt = now.subtract(3, 'month');
  //             break;
  //         case '6M':
  //             date.startAt = now.subtract(6, 'month');
  //             break;
  //         case '1Y':
  //             date.startAt = now.subtract(1, 'year');
  //             break;
  //         case 'SELECT':
  //             date.startAt = dayjs(refStartAt.current.value);
  //             date.endAt = dayjs(refEndAt.current.value);
  //             break;
  //         default:
  //             date.startAt = now;
  //             date.endAt = now;
  //             break;
  //     }
  //     return {
  //         startAt: date.startAt.format(DATE.FORMAT.DASH),
  //         endAt: date.endAt.format(DATE.FORMAT.DASH)
  //     }
  //
  // }

  // const {startAt, endAt} = getSearchDate();

  const {rows, fetch, params} = useBoard(request, (res) => {
    setTotalNum(res.totalCount);
    const {data} = res;
    return data && data.map((data) => ({
      ...data,
      role: ROLE[data.role],
      status: data?.status ? POINTMNG_USERMNG_POINT_DETAIL_STATUS[data.status] : "--",
      createdAt: data.createdAt && dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
      pointBalance: data.pointBalance.toLocaleString(),
      tokenBalance: data.tokenBalance.toLocaleString(),
    }));
  }, {
    ...INIT_OPTION.BOARD_PARAMS,
    // startAt,
    // endAt
    // ...getSearchDate()
  });

  const handleSelectRow = (row) => {
    if (row.role.includes('일반')) {
      navigate(`detail/${row.id}?page=${curPage}`);
    } else if (row.role.includes('비즈')) {
      navigate(`detail/${row.id}/biz?page=${curPage}`);
    }
  }

  // const handleChangeSearchKey = (value) => {
  //     setSearchKey(value);
  // }

  const handleSearch = (data) => {
    const {selectedOption} = data;
    const {type, name, limit,} = formData;

    updateSearchParams('name', name);
    // updateSearchParams('startAt', startAt);
    // updateSearchParams('endAt', endAt);

    if (name || name === '') {
      updateSearchParams('page', '1')
      setCurPage(1)
      setFormData({...formData, offset: 0, page: 1})
    }

    if (selectedOption) {
      updateSearchParams('page', '1')
      setCurPage(1)
      setFormData({...formData, offset: 0, page: 1})
    }

    // if (startAt !== undefined && endAt !== undefined) {
    //     setCurPage(1)
    //
    //     setFormData({
    //         ...formData,
    //         page: 1,
    //         offset: 0,
    //         startAt: startAt,
    //         endAt: endAt,
    //     })
    // }

    fetch({
      name: name || '',
      // status: selectedOption || '',
      // startAt: startAt || '',
      // endAt: endAt || '',
      // type: type || '',
      page: 1,
      offset: 0,
      limit
    });
  }

  function updateSearchParams(key, value) {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }

  const handlePage = (pageNum) => {
    updateSearchParams('page', pageNum)
    setFormData({...formData, page: pageNum, offset: pageNum - 1, title: ''})
    setCurPage(pageNum)
  };

  useEffect(() => {

    setFormData(prevState => ({
      ...prevState,
      page: Number(queryPage) || 1,
      name: queryName || '',
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
    }))


    fetch({
      name: queryName || '',
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      limit: 10,
    })

  }, [queryPage, queryName, queryStartAt, queryEndAt]);

  return (
    <>
      <MenuArea>
        <MenuInfoArea>
          <Title btn>회원별 내역</Title>
          <ListSearch onSearch={handleSearch}
                      keyword="회원명을"
                      inputValue={formData.name}
                      onChange={(e) => setFormData(prevState => ({...formData, name: e.target.value}))}
          />
          <Table
            pageData={formData} totalPages={pageInfo?.totalPages} columns={POINTMNG_USERMNG_POINT_DETAIL}
            rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum}
            searchParams={searchParams}
          />
          <Page pageInfo={pageInfo} handlePage={handlePage}/>
        </MenuInfoArea>
      </MenuArea>
    </>
  )
}

export default UserMng;
