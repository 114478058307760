import React, {useState} from "react";

import {MenuInfoCont} from "pages/Mypage/MypageStyle";

import {
    Button,
    ButtonRight,
    GrayButton,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListUl
} from "common/styledComponents/commonStyle";
import AuthSettingModal from "./modal/AuthSettingModal";

const UserDetailAdmin = ({userInfo, register, userAuth}) => {

    const [openModal, setOpenModal] = useState(true);

    return (
        <>
            <MenuInfoCont>
                <List>
                    <ListUl>
                        <ListLi>
                            <ListLtxt>아이디</ListLtxt>
                            <ListRtxt>{userInfo.userId}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>담당자</ListLtxt>
                            <ListRtxt>{userInfo.name}</ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>이메일 주소</ListLtxt>
                            <ListRtxt>
                                <ListInput type="email" defaultValue={userInfo.email}
                                           placeholder="입력"  {...register('email')}
                                />
                            </ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>소속</ListLtxt>
                            <ListRtxt><ListInput type="text" defaultValue={userInfo.department}
                                                 placeholder="입력"  {...register('department')}/></ListRtxt>
                        </ListLi>
                        <ListLi>
                            <ListLtxt>월렛 주소</ListLtxt>
                            <ListRtxt>{userInfo.walletAddress}</ListRtxt>
                        </ListLi>
                    </ListUl>
                </List>
            </MenuInfoCont>
            {
                userAuth.some(d => d === "W") &&
                <ButtonRight mini>
                    <GrayButton type="button" onClick={() => setOpenModal(false)}>권한 설정</GrayButton>
                    <Button mini>수정 하기</Button>
                </ButtonRight>
            }


            <AuthSettingModal {...{openModal, setOpenModal, userInfo}} />
        </>
    )
}

export default UserDetailAdmin;
