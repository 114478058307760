import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRequest from "../../../../hooks/useRequest";
import { API_STATE } from "../../../../common/constants/state";
import DetailView from "../../../../common/components/DetailView/DetailView";
import {MenuArea} from '../../../../common/styledComponents/commonStyle';

const QnaDetail = () => {
    const params = useParams();
    const { id } = params;
    const { register, handleSubmit, watch, setValue } = useForm();
    const updateQnaDetailRequest = useRequest("patch", `qa/${id}/answer`);
    const qnaDetailRequest = useRequest("get", `qa/${id}`);
    const [qnaInfo, setQnaInfo] = useState({});

    const navigate = useNavigate();

    const handleEditInfo = (data) => {
        updateQnaDetailRequest.call(data, "body");
    };

    useEffect(() => {
        qnaDetailRequest.call(id, "body");
    }, []);

    useEffect(() => {
        switch (qnaDetailRequest.state) {
            case API_STATE.done:
                if (qnaDetailRequest.response.success) {
                    const { response } = qnaDetailRequest.response;
                    if (!response) return;

                    setQnaInfo(response);
                    setValue("answer", response.answer);
                }

                // if (updateQnaDetailRequest.response.success) {
                //     const { response } = qnaDetailRequest.response;
                //     if (!response) return;
                //     console.log("업데이트 성공이래");
                // }

                break;
            default:
                break;
        }
    }, [qnaDetailRequest.state, qnaDetailRequest.error, qnaDetailRequest.response]);

    return (
        <MenuArea>
            <form onSubmit={handleSubmit(handleEditInfo)}>
                <DetailView
                    Info={qnaInfo}
                    watch={watch()}
                    register={register}
                    navigate={navigate}
                    title={"1:1 문의 상세보기"}
                />
            </form>
        </MenuArea>
    );
};

export default QnaDetail;
