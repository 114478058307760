import pollberryApiService from '../../util/pollberryApiService';
import storageService from '../../util/storage';
import axios from 'axios';

async function uploadImage(item, token, apiType) {
    const formData = new FormData();
    formData.append("image", item.url);

    if (item.imageUrl) {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/image/${apiType}/item/new`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            formData.delete('image');

            return {
                id: item.id,
                text: item.text,
                url: response.data.response.url
            };
        } catch (error) {
            console.error(error, 'error');
            formData.delete('image');
            return item;
        }
    }
    return item;
}

async function processImageGroup(imageGroup, token, apiType) {
    const resultGroup = await Promise.all(imageGroup.map(async (item) => {
        return await uploadImage(item, token, apiType);
    }));
    return resultGroup;
}

async function SurveyItemsUpload(images, apiType) {
    const token = storageService.get("auth", "accessToken", "local");
    const apiService = pollberryApiService;
    apiService.setBaererToken(token);

    return await Promise.all(images.map(async (imageGroup) => {
        return await processImageGroup(imageGroup, token, apiType);
    }));
}

export default SurveyItemsUpload;
