import React, {useEffect, useState} from 'react';
import useRequest from "hooks/useRequest";
import {useForm} from "react-hook-form";

import {API_STATE} from "common/constants/state";
import {
    ListInputFile,
    PopBox,
    PopButton,
    PopCenterTxt,
    PopClose,
    Poperror,
    PopHeadTxt,
    PopInputLong,
    PopInputTxt,
    PoptextCount,
    PopTxt,
    PopUp
} from "common/styledComponents/commonStyle";
import {
    InputDelBtn,
    Thum,
    ThumAddBtn,
    ThumBoxing,
    ThumGroup,
    ThumInfo,
    ThumName,
    ThumNameBox,
    ThumNoti,
    ThumNotiList
} from "pages/Join/JoinStyle"

import CloseIcon from "asset/img/icon__close.png";

const AddPopup = ({tab, onAdd, setShowAddPopup, setShowConfirmPopup, showConfirmPopup}) => {
    const addCategoryRequest = useRequest("post", `/category/${tab}/new`);
    const [errorMsg, setErrorMsg] = useState('');
    const [imageSrc, setImageSrc] = useState("");
    const [categoryNm, setCategoryNm] = useState("");
    const [imageFileSrc, setImageFileSrc] = useState(null);
    const [imageSrcName, setImageSrcName] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const addCategoryImgRequest = useRequest("post", "image/category/channel/new");
    const {
        register,
        handleSubmit,
        watch,
    } = useForm({
        defaultValues: {},
    });
    const tabStatus = {
        channel: '채널',
        poll: '설문',
        ranking: '랭킹',
    }

    const onSubmit = (data) => {
        console.log(data, 'data')
        const {name} = data;
        setCategoryName(name);

        if (imageFileSrc) {
            console.log('이거?')
            addCategoryImgRequest.call(imageFileSrc);
        } else {
            addCategoryRequest.call(data, 'body')
        }
    }

    useEffect(() => {
        switch (addCategoryImgRequest.state) {
            case API_STATE.done:
                if (addCategoryImgRequest.response.success) {
                    // addCategoryRequest.call({name : categoryName, url : UPLOAD_IMAGE_BASE_URL + addCategoryImgRequest.response.response.url });
                    addCategoryRequest.call({name: categoryName, url: addCategoryImgRequest.response.response.url});
                }
                break;
            default:
                break;
        }
    }, [addCategoryImgRequest.state, addCategoryImgRequest.error, addCategoryImgRequest.response]);


    const encodeFileToBase64 = (fileBlob) => {
        const formData = new FormData();
        formData.append("image", fileBlob);
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        setImageSrcName(fileBlob.name);
        setImageFileSrc(formData);
        return new Promise((resolve) => {
            reader.onload = () => {
                setImageSrc(reader.result);

                resolve();
            };
        });
    };

    const deleteImge = () => {
        setImageSrcName("");
        setImageFileSrc({});
        setImageSrc("");
    }

    useEffect(() => {
        switch (addCategoryRequest.state) {
            case API_STATE.done:
                if (addCategoryRequest.response.success) {
                    setShowConfirmPopup(true);
                    onAdd && onAdd(tab);
                }
                if (addCategoryRequest.response.error) {
                    const error = addCategoryRequest?.response?.error;
                    if (error?.message?.indexOf('duplicate key') !== -1) {
                        setErrorMsg('동일한 카테고리명이 존재합니다.');
                        return;
                    }
                    if (error?.message?.indexOf('multipart/form-data') !== -1) {
                        setErrorMsg('카테고리 이미지를 추가 해 주세요.');
                        return;
                    }
                    setErrorMsg(error?.message);
                }
                break;
            default:
                break;
        }
    }, [addCategoryRequest.state, addCategoryRequest.error, addCategoryRequest.response]);

    const onChangePollTitle = () => {
        if (watch().hasOwnProperty("name") || watch().name !== "") {
            setCategoryNm(watch().name);

        }
    };

    return (
        <PopUp>
            {!showConfirmPopup ?
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* 카테고리명 썸네일 없을때 PopBox cate 에 short 추가 >  PopBox cate short*/}
                    <PopBox cate={tab === 'channel'}>
                        <PopClose><img src={CloseIcon} alt='' onClick={() => setShowAddPopup(false)}/></PopClose>
                        <PopHeadTxt>카테고리 추가</PopHeadTxt>

                        <PopTxt left>{`${tabStatus[tab]}을 구분하기 위한 카테고리를 입력해주세요.`}</PopTxt>
                        <PopInputTxt>
                            <PopInputLong
                                type='text'
                                placeholder="카테고리명을 추가하세요."
                                maxLength={10}
                                {...register("name", {
                                    onChange: onChangePollTitle,
                                })}
                            />
                            <PoptextCount>{categoryNm.length}/10자</PoptextCount>
                            <Poperror>{errorMsg}</Poperror>
                        </PopInputTxt>
                        {tab === 'channel' &&
                            <>
                                <PopTxt left>카테고리 썸네일</PopTxt>
                                <ThumBoxing>
                                    <Thum pop>
                                        {imageSrc && <img src={imageSrc} alt='category_thumbnail'/>}
                                    </Thum>
                                    <ThumGroup pop>
                                        <ThumInfo>
                                            <ThumNameBox pop>
                                                <ThumName pop>{imageSrcName}</ThumName>
                                                <InputDelBtn onClick={() => deleteImge()}>X</InputDelBtn>
                                            </ThumNameBox>
                                            <ThumAddBtn htmlFor="filesample">파일 첨부</ThumAddBtn>
                                            <ListInputFile type="file"
                                                           accept="image/jpg, image/png, image/jpeg"
                                                           id="filesample"
                                                           onChange={(e) => {
                                                               encodeFileToBase64(e.target.files[0]);
                                                           }}/>
                                        </ThumInfo>
                                        <ThumNoti>
                                            <ThumNotiList>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg, jpeg,
                                                png,
                                                bmp</ThumNotiList>
                                        </ThumNoti>
                                    </ThumGroup>
                                </ThumBoxing>
                            </>
                        }
                        <PopButton center>확인</PopButton>
                    </PopBox>
                </form>
                :

                <PopBox cate>
                    <PopClose><img src={CloseIcon} alt='' onClick={() => setShowAddPopup(false)}/></PopClose>
                    <PopHeadTxt>카테고리 추가</PopHeadTxt>

                    {/* 추가완료일때 */}
                    <PopCenterTxt>[{categoryName}]<br></br>추가가 완료 되었습니다.</PopCenterTxt>

                    <PopButton center onClick={() => {
                        setShowAddPopup(false);
                        setShowConfirmPopup(false);
                    }}>확인</PopButton>
                </PopBox>

            }


        </PopUp>

    )
}

export default AddPopup;
