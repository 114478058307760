import React from "react";
import {MenuInfoCont, MyLeft, MyImg, MenuInfoSub, PointBox, PointUl, PointLi, PointListDiv, PointToptxt, PointBottomGroup, PointBottomStrong, PointBottomSpan} from "pages/Mypage/MypageStyle"
import {Center, GrayButton, Button, ButtonRight, List, ListUl, ListLi, ListLiHori, ListLtxt, ListRtxt, ListRlink, ListInput, ListRspan, ListRspanImg, ListThumb, ListThumbName, ListThumbX, MenuArea, MenuInfoArea, Title, BackBtn, Select, Option, PageBox, Paging, LineBar, TitleNotice, SubjectListBox, SubjectList, SubjectListSpan, SubjectListBlock, ListRtxtSubText, ListRtxtSubNum } from "common/styledComponents/commonStyle";
import {MyNickBox, MyNick, MyNickUser, ListNew, ListNewUl, ListNewLi} from "pages/User/UserStyle";


import { BarBox, BarGraph, BarNumber, BarPercent, BarPerson} from "pages/Temp/Poll_Ad_006/pollad006Sytle";





import ImgBack from "asset/img/img-back.jpg"
import ImgBack2 from "asset/img/img__priview.png"

const Poll_Ad_006 = () =>{


    const width = {
        width : "60%",
    };

    return(
        <>
        <MenuArea>
            <MenuInfoArea>
                <Title>
                    <BackBtn href="#none"></BackBtn>설문 상세보기
                    <TitleNotice>*등록된 설문은 수정이 불가능합니다.</TitleNotice>
                </Title>

                <MenuInfoCont survey>
                    <List wide>
                        <ListUl flex>
                            <ListLiHori>
                                <ListLtxt>생성자</ListLtxt>
                                <ListRtxt line>nickname</ListRtxt>
                            </ListLiHori>
                            <ListLiHori>
                                <ListLtxt>설문 상태</ListLtxt>
                                <ListRtxt>
                                    <Select mid>
                                        <Option>선택</Option>
                                    </Select>
                                </ListRtxt>
                            </ListLiHori>
                        </ListUl>
                    </List>
                </MenuInfoCont>

                <MenuInfoCont survey>
                    <MenuInfoSub>기본 정보</MenuInfoSub>
                    <List wide>
                        <ListUl>
                            <ListLi>
                                <ListLtxt top>설문 제목</ListLtxt>
                                <ListRtxt max>설문 제목이 들어가는 자리입니다 설문 제목이 들어가는 자리입니다 설문 제목이 들어가는 자리입니다</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt top>설문 설명</ListLtxt>
                                <ListRtxt max>설문 설명이 들어가는 자리입니다 설문 설명이 들어가는 자리입니다 설문 설명이 들어가는 자리입니다</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>설문 기간</ListLtxt>
                                <ListRtxt>
                                    <ListRspan>0000.00.00 00:00</ListRspan>
                                    <ListRspan>~</ListRspan>
                                    <ListRspan>0000.00.00 00:00</ListRspan>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>설문 초대 회원</ListLtxt>
                                <ListRtxt>
                                    <ListRspan>000,000,000</ListRspan>
                                    <ListRspan>명</ListRspan>
                                    <GrayButton short>상세내역</GrayButton>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>설문 키워드</ListLtxt>
                                <ListRtxt>
                                    <ListRspan>키워드</ListRspan>
                                    <ListRspan>키워드</ListRspan>
                                    <ListRspan>키워드</ListRspan>
                                    <ListRspan>키워드</ListRspan>
                                    <ListRspan>키워드</ListRspan>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt top>썸네일 이미지</ListLtxt>
                                <ListRtxt>
                                    <ListRspanImg>
                                        <ListThumb main>
                                            <img src={ImgBack} alt="img"/>
                                        </ListThumb>
                                        <ListThumbName>한글한글글</ListThumbName>
                                        <ListThumbX href="#none"></ListThumbX>
                                    </ListRspanImg>
                                    <ListRspanImg>
                                        <ListThumb>
                                            <img src={ImgBack} alt="img"/>
                                        </ListThumb>
                                        <ListThumbName>한글한글글</ListThumbName>
                                        <ListThumbX href="#none"></ListThumbX>
                                    </ListRspanImg>
                                    <ListRspanImg>
                                        <ListThumb>
                                            <img src={ImgBack} alt="img"/>
                                        </ListThumb>
                                        <ListThumbName>한글한글글</ListThumbName>
                                        <ListThumbX href="#none"></ListThumbX>
                                    </ListRspanImg>
                                </ListRtxt>
                            </ListLi>
                        </ListUl>
                    </List>
                </MenuInfoCont>

                <MenuInfoCont survey>
                    <MenuInfoSub>설문 보상</MenuInfoSub>
                    <List wide>
                        <ListUl>
                            <ListLi>
                                <ListLtxt>보상 지급 여부</ListLtxt>
                                <ListRtxt>지급/지급안함</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt what>보상 단위</ListLtxt>
                                <ListRtxt>
                                    <ListRtxtSubText>
                                        1단계 회원
                                        <ListRtxtSubNum>nnn,nnn</ListRtxtSubNum>
                                        <ListRtxtSubNum berry>Berry</ListRtxtSubNum>
                                    </ListRtxtSubText>
                                    <ListRtxtSubText>
                                        2단계 회원
                                        <ListRtxtSubNum>nnn,nnn</ListRtxtSubNum>
                                        <ListRtxtSubNum berry>Berry</ListRtxtSubNum>
                                    </ListRtxtSubText>
                                </ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>현재 보상 상태</ListLtxt>
                                <ListRtxt>
                                    <ListRspan>000,000,000</ListRspan>
                                    <ListRspan>Berry</ListRspan>
                                    <GrayButton short>상세내역</GrayButton>
                                </ListRtxt>
                            </ListLi>
                        </ListUl>
                    </List>
                </MenuInfoCont>

                <MenuInfoCont survey>
                    <MenuInfoSub>질문 세부사항</MenuInfoSub>
                    <MenuInfoSub>질문 1</MenuInfoSub>
                    <List wide>
                        <ListUl>
                            <ListLi>
                                <ListLtxt top>질문 제목</ListLtxt>
                                <ListRtxt max>질문 제목이 들어가는 자리입니다</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>답변 형태</ListLtxt>
                                <ListRtxt>단일 선택</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt top>항복 구분</ListLtxt>
                                <ListRtxt>
                                    텍스트형
                                </ListRtxt>

                                <SubjectListBox>
                                    <SubjectList>
                                        <SubjectListBlock>항목 00.</SubjectListBlock>
                                        <SubjectListBlock>항목항목항목</SubjectListBlock>
                                        <SubjectListBlock rel>
                                            <BarBox>
                                                {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                {/* first 추가시 1등 */}
                                                <BarGraph first style={width}></BarGraph>
                                            </BarBox>
                                            <BarNumber>
                                                <BarPercent>40%</BarPercent>
                                                <BarPerson>1,141,400명</BarPerson>
                                            </BarNumber>
                                        </SubjectListBlock>
                                    </SubjectList>
                                    <SubjectList>
                                        <SubjectListBlock>항목 00.</SubjectListBlock>
                                        <SubjectListBlock>항목항목항목</SubjectListBlock>
                                        <SubjectListBlock rel>
                                            <BarBox>
                                                {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                <BarGraph style={width}></BarGraph>
                                            </BarBox>
                                            <BarNumber>
                                                <BarPercent>40%</BarPercent>
                                                <BarPerson>1,141,400명</BarPerson>
                                            </BarNumber>
                                        </SubjectListBlock>
                                    </SubjectList>
                                    <SubjectList>
                                        <SubjectListBlock>항목 00.</SubjectListBlock>
                                        <SubjectListBlock>항목항목항목</SubjectListBlock>
                                        <SubjectListBlock rel>
                                            <BarBox>
                                                {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                <BarGraph style={width}></BarGraph>
                                            </BarBox>
                                            <BarNumber>
                                                <BarPercent>40%</BarPercent>
                                                <BarPerson>1,141,400명</BarPerson>
                                            </BarNumber>
                                        </SubjectListBlock>
                                    </SubjectList>
                                </SubjectListBox>
                            </ListLi>
                        </ListUl>
                    </List>

                    <MenuInfoSub ssub>질문 2</MenuInfoSub>
                    <List wide>
                        <ListUl>
                            <ListLi>
                                <ListLtxt top>질문 제목</ListLtxt>
                                <ListRtxt max>질문 제목이 들어가는 자리입니다</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt>답변 형태</ListLtxt>
                                <ListRtxt>복수 선택</ListRtxt>
                            </ListLi>
                            <ListLi>
                                <ListLtxt top>항복 구분</ListLtxt>
                                <ListRtxt>
                                    이미지형

                                    {/* <SubjectListBox>
                                        <SubjectList>
                                            <SubjectListSpan head>항목 00</SubjectListSpan>
                                            <SubjectListSpan img><img src={ImgBack} alt="img"/></SubjectListSpan>
                                            <SubjectListSpan>항목항목항목 항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목항목</SubjectListSpan>
                                        </SubjectList>
                                        <SubjectList>
                                            <SubjectListSpan head>항목 00</SubjectListSpan>
                                            <SubjectListSpan img><img src={ImgBack} alt="img"/></SubjectListSpan>
                                            <SubjectListSpan>항목항목항목</SubjectListSpan>
                                        </SubjectList>
                                        <SubjectList>
                                            <SubjectListSpan head>항목 00</SubjectListSpan>
                                            <SubjectListSpan img><img src={ImgBack} alt="img"/></SubjectListSpan>
                                            <SubjectListSpan>항목항목항목</SubjectListSpan>
                                        </SubjectList>
                                    </SubjectListBox>   */}
                                </ListRtxt>
                            </ListLi>

                            <SubjectListBox>
                                <SubjectList>
                                    <SubjectListSpan bigimg><img src={ImgBack2} alt="img"/></SubjectListSpan>
                                    <SubjectListBlock img>
                                        <SubjectListBlock>항목 00.</SubjectListBlock>
                                        <SubjectListBlock>항목항목항목</SubjectListBlock>
                                        <SubjectListBlock rel>
                                            <BarBox img>
                                                {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                {/* first 추가시 1등 */}
                                                <BarGraph first style={width}></BarGraph>
                                            </BarBox>
                                            <BarNumber>
                                                <BarPercent>40%</BarPercent>
                                                <BarPerson>1,141,400명</BarPerson>
                                            </BarNumber>
                                        </SubjectListBlock>
                                    </SubjectListBlock>
                                </SubjectList>
                                <SubjectList>
                                    <SubjectListSpan bigimg><img src={ImgBack2} alt="img"/></SubjectListSpan>
                                    <SubjectListBlock img>
                                        <SubjectListBlock>항목 00.</SubjectListBlock>
                                        <SubjectListBlock>항목항목항목</SubjectListBlock>
                                        <SubjectListBlock rel>
                                            <BarBox img>
                                                {/* width:% 퍼센트 값에 따라 막대 사이즈 변화 */}
                                                {/* first 추가시 1등 */}
                                                <BarGraph first style={width}></BarGraph>
                                            </BarBox>
                                            <BarNumber>
                                                <BarPercent>40%</BarPercent>
                                                <BarPerson>1,141,400명</BarPerson>
                                            </BarNumber>
                                        </SubjectListBlock>
                                    </SubjectListBlock>
                                </SubjectList>
                            </SubjectListBox>
                        </ListUl>
                    </List>
                </MenuInfoCont>
            </MenuInfoArea>
        </MenuArea>

        </>
    )
}

export default Poll_Ad_006;
