import {
    ListInput,
    PopBox,
    PopButton,
    PopCheck,
    PopClose,
    PopHeadTxt,
    PopLeftTxt,
    PopRightTxt,
    PopTable,
    PopTxt,
    PopUp,
} from "common/styledComponents/commonStyle";
import {useNavigate} from "react-router-dom";
import CloseIcon from "asset/img/icon__close.png";
import React, {useEffect, useState} from 'react';
import {API_STATE} from "common/constants/state";
import useRequest from "hooks/useRequest";

const AccntCreateInfoModal = ({bizCreateModalOpen, setBizCreateModalOpen, handleCreateUser, dataForModal}) => {
    const [emailYn, setEmailYn] = useState(false);
    const navigate = useNavigate();
    const sendEmailRequest = useRequest("post", "/user/email/send");

    const sendEmailConfirm = (data) => {
        if (emailYn) {
            const formData = {
                userId: dataForModal?.id,
                password: dataForModal?.password
            }

            sendEmailRequest.call(formData);
        } else {
            alert(dataForModal?.userType + "이 생성 완료 되었습니다.");
            navigate('/user/list');
        }
    }

    useEffect(() => {
        switch (sendEmailRequest.state) {
            case API_STATE.done:
                if (sendEmailRequest.response.success) {
                    alert(dataForModal?.userType + "이 생성 완료 되었습니다.");
                    navigate('/user/list');
                } else {

                }

                break;

            default:
                break;
        }
    }, [sendEmailRequest.state, sendEmailRequest.error, sendEmailRequest.response]);
    return (
        <PopUp hidden={bizCreateModalOpen}>
            <PopBox info>
                <PopHeadTxt>회원생성 정보 안내</PopHeadTxt>
                <PopClose onClick={() => setBizCreateModalOpen(true)}><img src={CloseIcon} alt=''/></PopClose>
                <PopTxt left>생성하신 회원의 정보를 확인해주세요.</PopTxt>
                <PopTable>
                    <PopTxt>
                        <PopLeftTxt>회원 구분</PopLeftTxt><PopRightTxt>{dataForModal?.userType}</PopRightTxt>
                    </PopTxt>
                    <PopTxt>
                        <PopLeftTxt>아이디</PopLeftTxt><PopRightTxt>{dataForModal?.id}</PopRightTxt>
                    </PopTxt>
                    <PopTxt>
                        <PopLeftTxt>임시 비밀번호</PopLeftTxt><PopRightTxt>{dataForModal?.password}</PopRightTxt>
                    </PopTxt>
                </PopTable>
                <PopCheck>
                    <ListInput type='checkbox' checked={emailYn} onClick={() => setEmailYn(!emailYn)}/>
                    <PopTxt check>이메일로 회원 정보 발송하기</PopTxt>
                </PopCheck>
                <PopButton center onClick={() => sendEmailConfirm(dataForModal)}>확인</PopButton>
            </PopBox>
        </PopUp>
    );
};

export default AccntCreateInfoModal;
