import styled, {css} from 'styled-components';
import {CustomTdContent, ModalContainer, StyledModal, Wrapper} from '../../../common/styledComponents/commonStyle';

export const StyledPostModal = styled(StyledModal)`
    flex-flow: column;
`;

export const PostModalContainer = styled(ModalContainer)`
    border-radius: 20px;
    border: 1px solid #DBDBDB;
    background: #FFF;
    display: flex;
    width: 360px;
    height: 740px;
    padding-bottom: 105px;

    ${props => props.Type && css`
        padding-bottom: 0;
    `}

`;

export const PreviewMobileContainer = styled(Wrapper)`
    justify-content: right;
    padding: 3px 8px;
`;

export const PreviewMobileWrapper = styled(Wrapper)`
    width: auto;
    margin: 0 0 0 2px;
`;

export const PreviewMobileTime = styled.span`
    color: #757575;
    font-size: 14px;
    font-weight: 400;
`;

export const PreviewMobileTitleWrapper = styled(Wrapper)`
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 6px;
    position: relative;

    img {
        position: absolute;
        left: 0;
    }
`;

export const PreviewMobileTitle = styled.span`
    color: #1A1A1A;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
`;

export const PreviewMobileContentBarWrapper = styled(Wrapper)`
    padding: 16px 22px 18px;

    img {
        height: 26px;
    }

    ${props => props.border && css`
        border-bottom: 1px solid #E8E8E8;
    `}
`;

export const MobilePreviewBadge = styled(CustomTdContent)`
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 28px;
    line-height: 28px;
    padding: 0;
`;

export const PreviewMobileContentTitle = styled.span`
    font-size: 14px;

    ${props => props.date && css`
        color: #5C5C5C;
        font-size: 13px;
        font-weight: 500;
        line-height: 145%; /* 18.85px */
        margin-top: 14px;
    `}

`;

export const PreviewMobileContentWrapper = styled(Wrapper)`
    padding: 20px;
    background: #F5F5F5;
    color: #5C5C5C;
    font-size: 13px;
    font-weight: 400;
    line-height: 145%; /* 18.85px */
    height: 268px;
    overflow: auto;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
    }

    ${props => props.Type && css`
        height: 100%;
        border-radius: 20px;
    `};

    span, p {
        word-break: break-word;
    }


    img {
        width: 100%;
        object-fit: contain;
    }
`;

export const IconClose = styled.img`
`;

export const CloseIconButton = styled.button`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    background-color: unset;

    span {
        margin-right: 4px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 145%;
    }
`;

