import { useState, useEffect } from "react";
import { API_STATE } from "common/constants/state";

const useBoard = (request, getRows, initParams = {
	offset: 0,
	limit: 10
}) => {
		const {call, response, state, error} = request;
    const [rows, setRows] = useState([]);
    const [params, setParams] = useState(initParams);
 
    // useEffect(() => {
		// 	call(initParams, 'queryString');
    // },[]);

    useEffect(() => {
			switch (state) {
				case API_STATE.done:
					if(response.success){
						const rows = getRows ? getRows(response.response) : response.response.data;
						setRows(rows);
					}
					break;
				default:
					break;
			}
		}, [state, error, response]);

		const fetch = (params) => {
			setParams({
				...params
			});
			call({
				// ...initParams,
				...params
			}, 'queryString');
		}

	return {rows, fetch, params};
};

export default useBoard;
