import styled, {css} from "styled-components";
import TextDelIcon from "asset/img/icon__cancel.png";
import EditBtnSmall from "asset/img/icon__edit_small.png";
import CheckBtnSmall from "asset/img/icon__done_small.png";
import {Wrapper} from '../../../common/styledComponents/commonStyle';
import WhiteArrowDropUp from 'asset/img/icon__arrow_drop_up_white.svg';
import WhiteArrowDropDown from 'asset/img/icon__arrow_drop_down_white.svg';
import GrayArrowDropUp from 'asset/img/icon__arrow_drop_up_gray.svg';
import GrayArrowDropDown from 'asset/img/icon__arrow_drop_down_gray.svg';

export const KeyCont = styled.div`
    width: 100%;
    height: 284px;
    margin: 48px 0 80px;

    ${(props) =>
            props.Ranking &&
            css`
                height: 100%;
                margin: 12px 0 48px 0;
            `}
`;


export const KeyBoxLeft = styled.div`
    width: 782px;
    float: left;
`;
export const KeyBoxHead = styled.p`
    margin: 0 auto 15px;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
`;
export const KeyBoxIn = styled.div`
    width: 100%;
    height: 240px;
    border: 1px solid #dbdbdb;
    border-top: 2px solid #000065;
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;

    }

    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    ${(props) =>
            props.noline &&
            css`
                border: none;
                border-top: 2px solid #000065;
            `}
    ${(props) =>
            props.high &&
            css`
                height: 300px;
            `}

    ${(props) =>
            props.Channel &&
            css`
                height: 295px;
            `}

    ${(props) =>
            props.Ranking &&
            css`
                height: 649px;
            `}

`;
export const KeyBoxUl = styled.ul`
    display: flex;
    flex-wrap: wrap;

    ${(props) =>
            props.Ranking &&
            css`
                flex-flow: column;
            `}

`;

export const KeyBoxA = styled.div`
    //width: 100%;
    height: 100%;
    //display: inline-block;
    box-sizing: border-box;
    //position: relative;
    //cursor: pointer;

    :hover {
        background-color: #f5f5f5;
    }

    ${props => props.On && css`
        background-color: #f5f5f5;
        font-weight: 500;
    `}
    input {
        width: 305px;
        border-radius: 8px;
    }
`;

export const CateDel = styled.a`
    padding: 2px 6px;
    color: #e92928;
    font-size: 14px;
    font-weight: 500;
    width: 42px;
    height: 28px;
    border: 1px solid #e92928;
    box-sizing: border-box;
    border-radius: 8px;
    position: absolute;
    right: 20px;
    top: 16px;
    display: none;

    &:hover {
        background-color: #e92928;
        color: #fff;
        display: inline-block;
    }
`;
export const KeyBoxCataLi = styled.li`
    width: 33.33%;
    min-width: 256px;
    height: 60px;
    position: relative;
    float: left;
    padding: 18px 20px;

    &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }

    ${(props) =>
            props.On &&
            css`
                a:last-child {
                    display: inline-block
                }

                //a:first-child {
                background-color: #f5f5f5;
                font-weight: 500;
                //color: #1A1A1A;
                //}
            `}
`;
export const KeyBoxRight = styled.div`
    width: 380px;
    float: right;
`;

export const KeyBoxKeyLi = styled.li`
    width: 33.33%;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    cursor: pointer;
    //float: left;
    position: relative;

    &:hover {
        background-color: #f5f5f5;
    }

    ${(props) =>
            props.on &&
            css`
                background-color: #f5f5f5;
                display: inline-block;
            `};

    ${(props) =>
            props.del &&
            css`
                &::after {
                    background-image: url(${TextDelIcon});
                    content: "";
                    width: 16px;
                    height: 16px;
                    background-size: cover;
                    position: absolute;
                    top: 22px;
                    right: 22px;
                    display: inline-block;
                    cursor: pointer;
                }

                a {
                    background-color: #f5f5f5;
                }
            `};

    ${(props) =>
            props.ChannelView &&
            css`
                width: 25%;
            `};

    ${(props) =>
            props.Ranking &&
            css`
                width: 100%;
            `};

`;

export const KeyBtn = styled.button`
    content: "";
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 22px;

    ${(props) =>
            props.edit &&
            css`
                right: 44px;
                background-image: url(${EditBtnSmall});
            `}
    ${(props) =>
            props.check &&
            css`
                right: 44px;
                background-image: url(${CheckBtnSmall});
            `}
    ${(props) =>
            props.del &&
            css`
                right: 22px;
                background-image: url(${TextDelIcon});
            `}

`;

export const KeyTxtBtn = styled.button`
    width: 42px;
    height: 28px;
    color: #e92928;
    font-size: 14px;
    border: 1px solid #e92928;
    background-color: #fff;
    border-radius: 8px;
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 12px;

    &:hover {
        background-color: #e92928;
        color: #fff;
    }
`;

export const KeyTxt = styled.span`
`;

export const KeyInput = styled.input`
`;

export const KeyNum = styled.span`
    font-size: 14px;
    color: #757575;
    margin-left: 8px;
`;
export const KeyEditBox = styled.span`
    display: inline-block;
    position: absolute;
    top: 12px;

`;

export const KeyEditInput = styled.input`
    padding: 8px;
    width: 305px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid #757575;
    border-radius: 8px;

`;

export const EditButton = styled.button`
    width: 100px;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #000065;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    &:disabled {
        color: #757575;
        background-color: #000032;
    }
`;
export const KeyBoxNoti = styled.p`
    font-size: 14px;
    color: #757575;
    text-align: right;
    margin-top: 8px;
`;


// 사용안함
export const KeyEditBox2 = styled.div`
    position: relative;
    display: flex;
`;
export const KeyEditInput2 = styled.input`
    padding: 8px;
    width: 656px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid #757575;
    border-radius: 8px;
    margin-right: 10px;

`;
export const EditButton2 = styled.button`
    width: 100px;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #757575;
    background: #000065;
    /* position: absolute;
    right: 8px; */
`;

export const KeywordsContainer = styled.div`
    display: grid;
    border-top: 2px solid #000065;
    height: 300px;
    overflow-y: scroll;
`;

export const KeywordsGrid = styled.div`
    //height: 60px;
    //width: 393px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 60px;
    text-align: center;

    span {
        padding: 18px 0;
    }
`;

export const CategoryImageWrapper = styled.div`
    border-top: 2px solid #000065;
`;

export const KeyWordInputWrapper = styled.div`
    border-top: 2px solid #000065;
    padding-top: 46px;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 240px;

    span {
        font-size: 18px;
        font-weight: 500;
        height: 26px;
        margin-bottom: 12px;
    }

    input {
        border: 1px solid #000065;
        width: 100%;
        border-radius: 8px;
        height: 60px;
        margin-bottom: 48px;
        padding: 0 16px;
    }
`;

export const KeyWordInputBox = styled.div`
    position: relative;
    width: 100%;
`;
export const KeyWordInputCount = styled.span`
    position: absolute;
    top: 23px;
    right: 8px;
    font-size: 10px !important;
    color: #757575;
`;

export const UpArrowWrapper = styled(Wrapper)`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: url(${GrayArrowDropUp}) no-repeat center #FFFFFF;

    :active {
        background-color: #000065;
        background-image: url(${WhiteArrowDropUp});
    }
`

export const ArrowWrapper = styled(Wrapper)`
    width: 24px;
    height: 24px;
`

export const DownArrowWrapper = styled(Wrapper)`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    background: url(${GrayArrowDropDown}) no-repeat center #FFFFFF;

    :active {
        background-color: #000065;
        background-image: url(${WhiteArrowDropDown});
    }
`



