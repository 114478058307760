import styled, {css} from 'styled-components';
import CheckActive from "asset/img/icon__check_box_active.png"
import Check from "asset/img/icon__check_box.png"
import {Wrapper} from '../../common/styledComponents/commonStyle';


//회원상세보기
export const MyNickBox = styled.p`
  font-size: 36px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const MyNick = styled.span`
  width: 140px;
  text-align: left;
  display: inline-block;
`;

export const MyNickUser = styled.span`
  width: 200px;
  text-align: left;
  margin-left: 24px;
  display: inline-block;
  overflow: hidden;
`;
export const ListNew = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -48px;
  ${(props) =>
          props.half &&
          css`
            margin-top: -24px;
          `}
`;
export const ListNewUl = styled.ul`
`;
export const ListNewLi = styled.li`
  width: 50%;
  height: 36px;
  float: left;
  margin-top: 48px;
`;


//회원생성
export const MenuInfoCont = styled.div`
  border-bottom: 0;
  clear: both;
  position: relative;
  padding: 24px 0 64px;


`;


/* 포인트 */
export const MenuInfoSub = styled.h3`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 48px;
`;
export const PointBox = styled.div`
  width: 100%;
  height: 126px;
`;
export const PointUl = styled.ul`
`;
export const PointLi = styled.li`
  float: left;

  & + & {
    margin-left: 20px;
  }
`;
export const PointListDiv = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 380px;
  height: 126px;
  box-sizing: border-box;
  padding: 16px 40px 24px 24px;
`;
export const PointToptxt = styled.p`
  font-weight: 500;
  margin-bottom: 16px;
`;
export const PointBottomGroup = styled.p`
  text-align: right;
  position: relative;
`;
export const PointBottomStrong = styled.strong`
  font-size: 32px;
  font-weight: 700;
  margin-right: 60px;
  max-width: 200px;
  overflow: hidden;
  display: inline-block;
`;
export const PointBottomSpan = styled.span`
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: 15px;
`;


export const CheckBox = styled.div`
  display: inline-block;
  position: relative;

`;
export const CheckInput = styled.input`
  display: none;

  & + label {
    position: relative;
    margin-right: 24px;
  }

  &:checked + label::before {
    background: url(${CheckActive}) no-repeat;
  }
`;
export const CheckLabel = styled.label`
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(${Check}) no-repeat;
    cursor: pointer;
  }
`;

//포인트 인풋(충전)
export const PointInputBox = styled.div`
  display: inline-block;
  position: relative;
  margin-top: 48px;
`;
export const PointInputUl = styled.ul`

`;
export const PointInputLi = styled.li`
  & + & {
    margin-top: 24px;
  }
`;


//테이블
export const Table = styled.table`
  display: inline-block;
  width: 980px;
  text-align: center;
`;
export const Caption = styled.caption`
  display: none;
`;
export const Thead = styled.thead`
`;
export const TheadTr = styled.tr`
  height: 48px;
  padding: 12px 0;
  background-color: #c2c2c2;
  border-bottom: 2px solid #757575;
`;
export const TheadTh = styled.th`
  font-size: 18px;
  font-weight: 500;
  width: 196px;
`;
export const TBody = styled.tbody`
  border-bottom: 2px solid #dbdbdb;
`;
export const Tr = styled.tr`
  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
`;
export const TbodyTh = styled.th`
  font-weight: 500;
  padding: 12px 0;
  width: 196px;
`;
export const TbodyTd = styled.td`
  padding: 12px 0;
  width: 196px;
`;

export const TableCont = styled.span`
  display: flex;
  width: 100%;
  text-align: center;
`;

export const UserProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const MyImg = styled.div`
  margin: 0 auto 24px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const UserDetailContent = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 135px;
  ${props => props.bizz && css`
    justify-content: space-between;
  `}
`;

export const BalanceWrapper = styled.div`
  background-color: #F5F5F5;
  width: 380px;
  height: 126px;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 16px 38px 24px 24px;

  & + & {
    margin-left: 20px;
  }
`;

export const BalanceTextWrapper = styled(Wrapper)`
  padding: 0 0 0 80px;
  display: flex;
  justify-content: right;
`;


export const BalanceText = styled.span`
  font-weight: 500;

  ${props => props.Number && css`
    font-size: 32px;
    font-weight: 700;
  `}

  ${props => props.Unit && css`
    //position: absolute;
    //bottom: 0;
    //right: 0;
    margin: auto 0 0 24px;
    font-size: 12px;
    font-weight: 400;
  `}

`;





