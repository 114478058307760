export const STATUS = {
  INSPECTION: '검수중',
  ONGOING: '진행중',
  COMPLETE: '완료',
  RETURN: '검수반려',
  NORMAL: '검수 완료',
  STOP: '정지',
}

export const POINT_LIST_STATUS = {
  NORMAL: '정상',
  READY: '대기',
  RETURN: '반려',
}

export const POINTMNG_USERMNG_POINT_DETAIL_STATUS = {
  NORMAL: '정상',
  STOP: '정지',
  WITHDRAWAL: '탈퇴',
}

export const CHARGE_STATUS = {
  NORMAL: '충전',
  CANCEL: '취소',
  '': '충전 내역 없음',
}

export const USAGE_STATUS = {
  NORMAL: '정상',
  CANCEL: '취소',
}

export const REWARD_STATUS = {
  POLL: '채널 설문',
  PRIVATE: '회원 설문',
  RANKING: '베리랭킹',
  KEYWORD: '트렌드 랭킹',
  BALANCE: '밸런스 게임',
}

export const DATE_FILTER_OPTIONS = [{
  text: '전체',
  value: 'ALL'
}, {
  text: '1개월',
  value: '1M'
}, {
  text: '3개월',
  value: '3M'
}, {
  text: '6개월',
  value: '6M'
}, {
  text: '1년',
  value: '1Y'
}, {
  text: '직접설정',
  value: 'SELECT'
}];

export const POINTMNG_REFUNDMNG_STATE_LIST = [
  {
    key: 'id',
    title: 'NO.',
    width: '5%'
  }, {
    key: 'createdAt',
    title: '일자',
    width: '8%'
  }, {
    key: 'type',
    title: '구분',
    width: '10%'
  }, {
    key: 'userName',
    title: '닉네임',
    width: '15%'
  }, {
    key: 'Reward Token',
    title: 'Reward Token',
    width: '50%'
  }, {
    key: 'status',
    title: '상태',
  },
]

export const POINTMNG_TOPUPMNG_STATE_LIST = [
  {
    key: 'id',
    title: 'NO.',
    width: '3%'
  },
  {
    key: 'createdAt',
    title: '일자',
    width: '5%'
  },
  {
    key: 'channelName',
    title: '채널 명',
    width: '10%'
  },
  {
    key: 'amount',
    title: '충전 Berry',
    width: '10%'
  },
  {
    key: 'balance',
    title: 'Berry 합산액',
    width: '10%'
  },
  {
    key: 'createdBy',
    title: '등록 관리자',
    width: '10%'
  },
  {
    key: 'status',
    title: '구분',
    width: '5%'
  }
]

export const BERRY_POINT_DETAIL_COLUMN = [{
  key: 'id',
  title: 'NO.',
  width: '5%'
}, {
  key: 'createdAt',
  title: '일자',
  width: '7%'
}, {
  key: 'amount',
  title: '충전 Berry',
  width: '15%'
}, {
  key: 'balance',
  title: 'Berry 합산',
  width: '15%'
}, {
  key: 'createdBy',
  title: '관리자 아이디',
  width: '15%'
}, {
  key: 'admin',
  title: '승인 관리자',
  width: '15%'
}, {
  key: 'status',
  title: '상태',
  width: '7%'
}];

export const SURVEY_DETAIL_OPTIONS_VER2 = [
  {
    text: '전체',
    value: ''
  },
  {
    text: '진행중',
    value: 'ONGOING'
  },
  {
    text: '정지',
    value: 'STOP'
  },
  {
    text: '완료',
    value: 'COMPLETE'
  }
];

export const SURVEY_DETAIL_OPTIONS_CHARGE = [
  {
    text: '전체',
    value: ''
  },
  {
    text: '충전',
    value: 'NORMAL'
  },
  {
    text: '취소',
    value: 'CANCEL'
  }
];

export const SURVEY_DETAIL_OPTIONS_USAGE = [
  {
    text: '전체',
    value: ''
  },
  {
    text: '정상',
    value: 'ONGOING'
  },
  {
    text: '취소',
    value: 'STOP'
  }
];

export const POINTMNG_REWARDMNG_STATE_LIST = [
  {
    key: 'id',
    title: 'NO.',
    width: '5%'
  }, {
    key: 'createdAt',
    title: '일자',
    width: '5%'
  }, {
    key: 'type',
    title: '구분',
    width: '5%'
  },
  {
    key: 'title',
    title: '제목',
    width: '20%'
  },
  {
    key: 'balance',
    title: '보상 합계',
    width: '7%'
  },
  {
    key: 'participantsCount',
    title: '참여 인원',
    width: '7%'
  },
  {
    key: 'status',
    title: '상태',
    width: '5%'
  },
]

export const POINTMNG_USERMNG_POINT_DETAIL = [
  {
    key: 'id',
    title: 'NO.',
    width: '5%'
  },
  {
    key: 'createdAt',
    title: '일자',
    width: '7%'
  },
  {
    key: 'role',
    title: '회원 구분',
    width: '10%'
  },
  {
    key: 'userId',
    title: '닉네임/채널명',
    width: '10%'
  },
  {
    key: 'pointBalance',
    title: 'Berry Point 현황',
    width: '15%'
  },
  {
    key: 'tokenBalance',
    title: 'Reward Token 현황',
    width: '15%'
  },
  {
    key: 'status',
    title: '상태',
    width: '5%'
  },
]

export const USER_POINT_LIST = [
  {
    key: 'id',
    title: 'NO.',
    width: '5%'
  },
  {
    key: 'createdAt',
    title: '일자',
    width: '8.5%'
  },
  {
    key: 'type',
    title: '구분',
    width: '8.5%'
  },
  {
    key: 'amount',
    title: 'Berry',
    width: '29%'
  },
  {
    key: 'balance',
    title: '잔여 Berry',
    width: '20%'
  }
]

export const BIZZ_POINT_LIST = [
  {
    key: 'id',
    title: 'NO.',
    width: '5%'
  },
  {
    key: 'createdAt',
    title: '일자',
    width: '8.5%'
  },
  {
    key: 'type',
    title: '구분',
    width: '8.5%'
  },
  {
    key: 'amount',
    title: 'Berry',
    width: '29%'
  },
  {
    key: 'balance',
    title: '잔여 Berry',
    width: '20%'
  }, {
    key: 'userId',
    title: '관리자 아이디',
    width: '20%'
  },
]

export const USER_POINT_DETAIL_LIST_TYPE = {
  USAGE: '사용',
  SEND: '보내기',
  RECEIVE: '받기',
  REWARD: '보상',
}

export const BIZ_POINT_DETAIL_LIST_TYPE = {
  CHARGE: '충전',
  CANCEL: '취소',
  REWARD: '보상 지급',
}

export const AD_REWARD_LIST_COLUMN = [
  {
    key: 'id',
    title: 'No.',
    width: '7.5%'
  }, {
    key: 'createdAt',
    title: '일자',
    width: '8.5%'
  }, {
    key: 'userName',
    title: '닉네임',
    width: '10%'
  }, {
    key: 'type',
    title: '구분',
    width: '37%'
  }, {
    key: 'amount',
    title: '보상 Berry',
    width: '37%'
  },
];

export const AD_REWARD_TYPE = {
  BANNER_CPM: '배너(CPM)',
  BANNER_CPC: '배너(CPC)',
  VIDEO_CPM: '동영상(VIDEO_CPM)',
};

export const AD_REWARD_LIST_TYPE_OPTIONS = [
  {
    text: '전체',
    value: ''
  }, {
    text: '배너(CPM)',
    value: 'BANNER_CPM'
  }, {
    text: '배너(CPC)',
    value: 'BANNER_CPC'
  }, {
    text: '동영상(CPM)',
    value: 'VIDEO_CPM'
  }
];

export const COUPON_MANAGEMENT_LIST_COLUMN = [
  {
    key: 'id',
    title: 'No.',
    width: '7%'
  }, {
    key: 'createdAt',
    title: '일자',
    width: '8.5%'
  }, {
    key: 'userName',
    title: '닉네임',
    width: '10%'
  }, {
    key: 'brand',
    title: '브랜드명',
    width: '18.5%'
  }, {
    key: 'product',
    title: '상품명',
    width: '35.5%'
  }, {
    key: 'amount',
    title: '금액',
    width: '13.5%'
  }, {
    key: 'type',
    title: '구분',
    width: '6%'
  }
];

export const COUPON_TYPE = {
  N: '사용 가능',
  Y: '사용 완료',
  C: '환불',
};

export const COUPON_SEARCH_OPTIONS = [
  {
    label: '전체',
    value: ''
  }, {
    label: '닉네임',
    value: 'name'
  }, {
    label: '브랜드명',
    value: 'brand'
  }, {
    label: '상품명',
    value: 'product'
  }
];

export const COUPON_SEARCH_TYPE_OPTIONS = {
  '': '전체',
  'name': '닉네임',
  'brand': '브랜드명',
  'product': '상품명',
};
