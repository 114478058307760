import React from "react";
import {SearchSort, SearchSortLi, SearchSortUl} from 'common/components/ListSearch/ListSearchStyle';

const ListSearchFilter = ({options = [], option = '', onSelect, type = '', onChange}) => {
  return (
    <SearchSort
      //{...(type === 'filter' && {filter: true})}
      Filter={type === 'filter'}>
      <SearchSortUl>
        {options.map((_option, idx) => {
          return <SearchSortLi
            // on={_option.value === option}
            On={_option.value === option}
            onClick={() => {
              onSelect && onSelect(_option.value)
              onChange && onChange(_option.value)
            }}
            key={idx}
          >{_option.text}</SearchSortLi>
        })}
      </SearchSortUl>
    </SearchSort>
  )
}

export default ListSearchFilter;
