import React from 'react';

import {
    PollCheckBox,
    PollCheckImg,
    PollCheckInput,
    PollCheckLabel,
    PollCheckLi,
    PollCheckTxt,
    PollCheckUl
} from "common/styledComponents/commonStyle";
import DefaultImage from "asset/img/img__priview.png";


const PopupServeyImg = ({data, handleCheck, checkNum}) => {

    return (
        <PollCheckBox>
            <PollCheckUl>
                {data.items.map((d, idx) => {
                    return (
                        <PollCheckLi square key={idx}>
                            <PollCheckLabel square>
                                <PollCheckInput square type="checkbox" checked={checkNum.some(d => d === idx)}
                                                onChange={() => handleCheck(idx)}/>
                                <PollCheckImg>
                                    <img src={d.imageUrl || DefaultImage} alt='previewItemImage'/>
                                </PollCheckImg>
                            </PollCheckLabel>
                            <PollCheckTxt square>{d.text.slice(0, 18) + '...' || "답안 내용"}</PollCheckTxt>
                        </PollCheckLi>
                    );
                })}
                {/*
                 <PollCheckLi square>
                 <PollCheckLabel square>
                 <PollCheckInput square type="checkbox"/>
                 <PollCheckImg><img src={TestBgCheck01}/></PollCheckImg>
                 <PollCheckTxt square>항목 2 텍스트</PollCheckTxt>
                 </PollCheckLabel>
                 </PollCheckLi>
                 <PollCheckLi square>
                 <PollCheckLabel square>
                 <PollCheckInput square type="checkbox"/>
                 <PollCheckImg><img src={TestBgCheck02}/></PollCheckImg>
                 <PollCheckTxt square>항목 1 텍스트 width: 131px height: 변동</PollCheckTxt>
                 </PollCheckLabel>
                 </PollCheckLi>
                 <PollCheckLi square>
                 <PollCheckLabel square>
                 <PollCheckInput square type="checkbox"/>
                 <PollCheckImg><img src={TestBgCheck02}/></PollCheckImg>
                 <PollCheckTxt square>항목 2 텍스트</PollCheckTxt>
                 </PollCheckLabel>
                 </PollCheckLi>
                 <PollCheckLi square>
                 <PollCheckLabel square>
                 <PollCheckInput square type="checkbox"/>
                 <PollCheckImg><img src={TestBgCheck02}/></PollCheckImg>
                 <PollCheckTxt square>항목 1 텍스트 width: 131px height: 변동</PollCheckTxt>
                 </PollCheckLabel>
                 </PollCheckLi>
                 <PollCheckLi square>
                 <PollCheckLabel square>
                 <PollCheckInput square type="checkbox"/>
                 <PollCheckImg><img src={TestBgCheck02}/></PollCheckImg>
                 <PollCheckTxt square>항목 2 텍스트</PollCheckTxt>
                 </PollCheckLabel>
                 </PollCheckLi>                                                                    */}
            </PollCheckUl>
        </PollCheckBox>
    );
};

export default PopupServeyImg;
