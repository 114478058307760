import React, {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import {
  Button,
  FullPointBox,
  FullPointInput,
  FullPointInputBox,
  FullPointInputTxt,
  MenuArea,
  MenuInfoArea,
  NoticeWrapper,
  PointReturnInfoWrapper,
  PopTxt,
  ReturnTdContent,
  Title,
  Wrapper
} from "common/styledComponents/commonStyle";
import {
  RewardTokenDetail,
  RewardTokenDetailNum,
  RewardTokenDetailTxt,
  RewardTokenDiv,
  RewardTokenSub,
  RewardTokenTitle
} from "./BerryPointDetailSytle";
import SearchFilter from "common/components/SearchFilter"
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import DATE from "common/constants/date";
import {
  SearchConditionBox,
  SearchConditionDiv,
  SearchConditionText,
  SearchDateBox,
  SearchDateInput,
  SearchDateInputSpan,
  SearchDateMid,
  SearchDateTxt
} from "../userSurveySytle";
import {DATE_FILTER_OPTIONS} from "common/constants/poll";
import Table from '../../../common/components/Table';
import {BERRY_POINT_DETAIL_COLUMN, POINT_LIST_STATUS} from '../../../common/constants/point';
import OperationPointModal from './Modal/OperationPointModal';
import {API_STATE} from '../../../common/constants/state';
import storageService from '../../../util/storage';
import axios from 'axios';
import ListSearch from '../../../common/components/ListSearch';

const INIT_OPTION = {
  BOARD_PARAMS: {
    offset: 0,
    limit: 6
  },
  KEY: '',
  DATE_FILTER_OPTION: 'ALL',
  // START_AT: '2022-12-19',
  // END_AT: '2023-01-19'
}

const BerryPointDetail = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  // const navigate = useNavigate();
  const [dateFilterOption, setDateFilterOption] = useState(INIT_OPTION.DATE_FILTER_OPTION);
  const queryPage = searchParams.get('page')
  const queryName = searchParams.get('name')
  const queryStatus = searchParams.get('status')
  const queryStartAt = searchParams.get('startAt')
  const queryEndAt = searchParams.get('endAt')
  const queryDateOption = searchParams.get('dateOption') || 'ALL'
  const request = useRequest("get", "point/issue/list");
  const pointBalanceRequest = useRequest("get", "point/issue/balance");
  const pointRequest = useRequest("post", "point/issue");
  const [approveId, setApproveId] = useState(null);
  const pointChargeReturnRequest = useRequest("patch", `point/${approveId}/issue/return`);
  const pointApproveRequest = useRequest("patch", `point/${approveId}/issue/approve`);
  const returnReasonRequest = useRequest("get", `point/${approveId}/return`);
  const [totalNum, setTotalNum] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [formData, setFormData] = useState({offset: 0, limit: 6, page: curPage, name: queryName || '',});
  const pageInfo = pageMaker(queryPage ? Number(queryPage) : 1, formData.limit, totalNum);
  const refStartAt = useRef(null);
  const refEndAt = useRef(null);
  const [totalPoint, setTotalPoint] = useState('');
  const [openChargeModal, setOpenChargeModal] = useState(false);
  const [openChargeApproveModal, setOpenChargeApproveModal] = useState(false);
  const [onReturnReasonModalState, setOnReturnReasonModalState] = useState(false);
  const [openReturnReason, setOpenReturnReason] = useState(false);
  const [chargeAmount, setChargeAmount] = useState('');
  const [reasonForReturn, setReasonForReturn] = useState('');
  const [approveAmount, setApproveAmount] = useState(0);
  const [pointInfo, SetPointInfo] = useState(null);
  const [returnReason, setReturnReason] = useState(null);


  const getSearchDate = () => {
    const now = dayjs(new Date());
    let date = {
      startAt: now,
      endAt: now
    };
    switch (dateFilterOption) {
      case '1M':
        date.startAt = now.subtract(1, 'month');
        break;
      case '3M':
        date.startAt = now.subtract(3, 'month');
        break;
      case '6M':
        date.startAt = now.subtract(6, 'month');
        break;
      case '1Y':
        date.startAt = now.subtract(1, 'year');
        break;
      case 'SELECT':
        date.startAt = refStartAt.current.value ? dayjs(refStartAt.current.value) : now;
        date.endAt = refEndAt.current.value ? dayjs(refEndAt.current.value) : now;
        break;
      case 'ALL':
        date.startAt = null;
        date.endAt = null;
        break;
      default:
        date.startAt = now;
        date.endAt = now;
        break;
    }
    return {
      startAt: date.startAt ? date.startAt.format(DATE.FORMAT.DASH) : '',
      endAt: date.endAt ? date.endAt.format(DATE.FORMAT.DASH) : '',
    }

  }

  const {startAt, endAt} = getSearchDate();

  const {rows, fetch, params} = useBoard(request, (res) => {
    setTotalNum(res.totalCount);
    const {data} = res;
    return data && data.map((data) => ({
      ...data,
      amount: data.amount.toLocaleString(),
      balance: data.balance.toLocaleString(),
      status: POINT_LIST_STATUS[data.status],
      createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
    }));
  }, {
    ...INIT_OPTION.BOARD_PARAMS,
    startAt,
    endAt
    // ...getSearchDate()
  });

  // 게시물 들어가는 함수 대기 중?
  // const handleSelectRow = (row) => {
  //     if (formData.title) {
  //         navigate(`/detail/${row.id}?page=${curPage}?title=${formData.title}`);
  //     } else {
  //         navigate(`/detail/${row.id}?page=${curPage}`);
  //     }
  // };

  const handleSelectRow = (row) => {
    SetPointInfo(row)

    if (row.status === '반려') {
      setApproveId(row.id)
    }
  };

  const handleDateFilterSelect = (option) => {
    setDateFilterOption(option);
    const {startAt, endAt} = getSearchDate();
    refStartAt.current.value = startAt;
    refEndAt.current.value = endAt;
  }

  const handleChangeStartAt = () => {
    const {startAt} = getSearchDate();
    refStartAt.current.value = startAt;
  }

  const handleChangeEndAt = () => {
    const {endAt} = getSearchDate();
    refEndAt.current.value = endAt;
  }

  function updateSearchParams(key, value) {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }

  const handlePage = (pageNum) => {
    updateSearchParams('page', pageNum)
    setFormData({...formData, page: pageNum, offset: pageNum - 1, name: ''})
    setCurPage(pageNum)
  };

  const handleCharge = () => {
    const doCharge = Number(chargeAmount);
    if (doCharge <= 0) {
      alert("충전하실 Berry Amount 를 입력해 주세요.")
      return;
    }
    pointRequest.call({amount: doCharge}, 'body')
    setReasonForReturn('')
  }

  const approveCharge = () => {
    pointApproveRequest.call({amount: approveAmount}, 'body')
  }

  const returnCharge = () => {
    pointChargeReturnRequest.call({reasonForReturn: reasonForReturn}, 'body')
  }

  const onReturnReason = () => {
    setOpenReturnReason(true)
  }

  const onReturnReasonModal = async (e) => {
    const token = storageService.get("auth", "accessToken", "local");
    const id = Number(e.currentTarget.id);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}/point/${id}/return`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })

      setReturnReason(response.data.response.reasonForReturn)
      setOnReturnReasonModalState(true)
      // setStopData(prevState => ({
      //     ...prevState, reason: response.data.response.reason,
      //     title: rows.filter((row) => row.id === id)[0].title
      // }))
      // openStopReasonModal()

    } catch (e) {
      console.log(e)
    }
    // setOnReturnModal(true)
  }

  const onChargeReadyModal = (row) => {
    setApproveAmount(row.amount)
    setApproveId(row.id)
    setOpenChargeApproveModal(true)
  }

  const resetModalState = () => {
    setOpenChargeApproveModal(false)
    setOpenReturnReason(false)
    setReasonForReturn('')
  }

  const columnRenderer = (key, value, _, row) => {
    if (key === "status" && value === '반려') {
      return <ReturnTdContent id={row.id} onClick={onReturnReasonModal}>{value}</ReturnTdContent>
    }

    if (key === "status" && value === '대기') {
      return <ReturnTdContent id={row.id} onClick={() => onChargeReadyModal(row)}>{value}</ReturnTdContent>
    }
    return value
  }

  const handleSearch = (data) => {
    const {text} = data;
    const {limit} = formData;
    const {startAt, endAt} = getSearchDate();


    if (text || text === '') {
      searchParams.set('name', text);
      searchParams.set('page', '1')
      setCurPage(1)
    }

    if ((startAt && endAt) || (startAt === '' && endAt === '')) {
      setCurPage(1)
      searchParams.set('startAt', startAt)
      searchParams.set('endAt', endAt)

      if (dateFilterOption) {
        searchParams.set('dateOption', dateFilterOption)
      }

      setFormData({
        ...formData,
        page: 1,
        offset: 0,
        startAt: startAt,
        endAt: endAt,
        dateOption: dateFilterOption,
      })
    }

    setSearchParams(searchParams)

    fetch({
      name: text || '',
      startAt: startAt || '',
      endAt: endAt || '',
      page: 1,
      offset: 0,
      limit
    });
  };

  useEffect(() => {
    pointBalanceRequest.call()
  }, []);


  useEffect(() => {
    switch (pointBalanceRequest.state) {
      case API_STATE.done:
        if (pointBalanceRequest.response.success) {

          if (!pointBalanceRequest.response.response) {
            return;
          }
          setTotalPoint(pointBalanceRequest.response.response.balance);
        }

        if (!pointBalanceRequest.response.success) {
          alert('실패')
        }
        return;
      default:
        return;
    }
  }, [pointBalanceRequest.state, pointBalanceRequest.error, pointBalanceRequest.response])


  useEffect(() => {
    switch (pointRequest.state) {
      case API_STATE.done:
        if (pointRequest.response.success) {
          alert('요청이 완료됐습니다.')
          setOpenChargeModal(false)
          fetch({
            offset: 0,
            limit: 6
          })
        }

        if (!pointRequest.response.success) {
          alert('실패')
        }
        return;
      default:
        return;
    }
  }, [pointRequest.state, pointRequest.error, pointRequest.response])

  useEffect(() => {
    switch (pointApproveRequest.state) {
      case API_STATE.done:
        if (pointApproveRequest.response.success) {
          alert('요청이 완료됐습니다.')
          setOpenChargeApproveModal(false)
          pointBalanceRequest.call()
          fetch({
            offset: 0,
            limit: 6
          })
        }

        if (!pointApproveRequest.response.success) {
          alert('실패')
        }
        return;
      default:
        return;
    }
  }, [pointApproveRequest.state, pointApproveRequest.error, pointApproveRequest.response])

  useEffect(() => {
    switch (pointChargeReturnRequest.state) {
      case API_STATE.done:
        if (pointChargeReturnRequest.response.success) {
          alert('요청이 완료됐습니다.')
          // setOpenChargeReturnModal(false)
          setOpenChargeApproveModal(false)
          fetch({
            offset: 0,
            limit: 6
          })
        }

        if (!pointChargeReturnRequest.response.success) {
          alert('실패')
        }
        return;
      default:
        return;
    }
  }, [pointChargeReturnRequest.state, pointChargeReturnRequest.error, pointChargeReturnRequest.response])

  useEffect(() => {
    switch (returnReasonRequest.state) {
      case API_STATE.done:
        if (returnReasonRequest.response.success) {
          setReturnReason(returnReasonRequest.response.response.reasonForReturn)
          setOnReturnReasonModalState(true)
        }
        if (!returnReasonRequest.response.success) {
          alert('조회에 실패하였습니다. 잠시 후 다시 시도해주세요.')
        }
        return;
      default:
        return;
    }
  }, [returnReasonRequest.state, returnReasonRequest.error, returnReasonRequest.response])


  useEffect(() => {

    setFormData(prevState => ({
      ...prevState,
      page: Number(queryPage) || 1,
      name: queryName || '',
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      status: queryStatus || '',
      dateOption: queryDateOption || 'ALL'
    }))

    setDateFilterOption(queryDateOption)

    fetch({
      name: queryName || '',
      offset: queryPage ? Number(queryPage) - 1 : 0,
      startAt: queryStartAt || '',
      endAt: queryEndAt || '',
      status: queryStatus || '',
      limit: 6,
    })

  }, [queryPage, queryName, queryStartAt, queryEndAt, queryStatus]);

  return (
    <>
      <MenuArea>
        <MenuInfoArea>
          <Title>Berry Point 상세내역</Title>
          <RewardTokenDiv>
            <RewardTokenTitle>운용 Point 현황</RewardTokenTitle>
            <RewardTokenSub>Pollberry 서비스 안에서 운용되는 Piont 입니다.</RewardTokenSub>

            <RewardTokenDetail>
              <RewardTokenDetailNum>{totalPoint.toLocaleString()}</RewardTokenDetailNum>
              <RewardTokenDetailTxt>Berry</RewardTokenDetailTxt>
              <Button width={'315px'} style={{marginLeft: '180px'}}
                      onClick={() => setOpenChargeModal(true)}
              >포인트 충전</Button>
            </RewardTokenDetail>
          </RewardTokenDiv>
          <SearchConditionBox Interval>
            <SearchConditionText reward>조회 기간</SearchConditionText>
            <SearchConditionDiv>
              <SearchFilter options={DATE_FILTER_OPTIONS} option={dateFilterOption}
                            onSelect={handleDateFilterSelect} type="filter"/>
              <SearchDateBox reward>
                <SearchDateInputSpan>
                  <SearchDateInput type="date"
                                   max={endAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                   ref={refStartAt} onChange={() => handleChangeStartAt()}/>
                  <SearchDateTxt>{formData.startAt ? formData.startAt : startAt}</SearchDateTxt>
                </SearchDateInputSpan>
                <SearchDateMid>~</SearchDateMid>
                <SearchDateInputSpan>
                  <SearchDateInput type="date"
                                   min={startAt} {...(dateFilterOption !== 'SELECT' && {disabled: true})}
                                   ref={refEndAt}
                                   onChange={() => handleChangeEndAt()}/>
                  <SearchDateTxt>{formData.endAt ? formData.endAt : endAt}</SearchDateTxt>
                </SearchDateInputSpan>
              </SearchDateBox>
            </SearchConditionDiv>
            <ListSearch
              onSearch={handleSearch}
              inputValue={formData.name}
              onChange={(e) => setFormData(prevState => ({...formData, name: e.target.value}))}
              margin='0'
            />
            {/*<SearchBox reward>*/}
            {/*    <SearchDiv>*/}
            {/*        <SearchInput type="text" placeholder="입력"/>*/}
            {/*    </SearchDiv>*/}
            {/*    <SearchBtn>검색</SearchBtn>*/}
            {/*</SearchBox>*/}
          </SearchConditionBox>
          <Table pageData={formData}
                 totalPages={pageInfo?.totalPages}
                 columns={BERRY_POINT_DETAIL_COLUMN}
                 rows={rows}
                 onSelectRow={handleSelectRow}
                 totalNum={totalNum}
            // searchParams={searchParams}
                 columnRenderer={columnRenderer}
          />
          <Page pageInfo={pageInfo} handlePage={handlePage}/>
        </MenuInfoArea>
      </MenuArea>
      {/*포인트 충전 요청 모달*/}
      {openChargeModal &&
        <OperationPointModal
          setOpenModal={setOpenChargeModal}
          title='운용 포인트 충전'
          handleCharge={handleCharge}
        >
          <PopTxt>
            충전된 운용 포인트는 각 채널의 설문 및 플랫폼 설문의 보상으로 사용자에게 제공되며 포인트는 Reward Token과 1:1 가치를 가지고 있습니다.
          </PopTxt>
          <FullPointBox>
            <PopTxt left>충전 금액을 입력해주세요.</PopTxt>
            <FullPointInputBox>
              <FullPointInput value={chargeAmount}
                              onChange={(e) => setChargeAmount(e.target.value)}
                              type="number"
              />
              <FullPointInputTxt>Berry</FullPointInputTxt>
            </FullPointInputBox>
            <PopTxt left>! 충전 시 승인 관리자의 확인이 필요합니다.</PopTxt>
          </FullPointBox>
        </OperationPointModal>
      }

      {/*충전 승인 모달*/}
      {openChargeApproveModal &&
        <OperationPointModal
          setOpenModal={setOpenChargeApproveModal}
          fetch={pointRequest}
          title='포인트 충전 승인'
          handleCharge={approveCharge}
          Approve={openChargeApproveModal}
          onReturnReason={onReturnReason}
          returnCharge={returnCharge}
          openReturnReason={openReturnReason}
          resetModalState={resetModalState}
        >
          <FullPointBox Approve={openChargeApproveModal}>
            <span>{approveAmount} Berry</span>
            <PopTxt Approve={openChargeApproveModal}>충전된 운용 포인트를 확인 후 승인해주세요.</PopTxt>
            {openReturnReason &&
              <>
                <Wrapper Column margin='61px 0 0 0'>
                  <span>반려 시 사유를 아래에 입력해주세요.</span>
                  <FullPointInputBox Return={openReturnReason}>
                    <FullPointInput value={reasonForReturn}
                                    onChange={(e) => setReasonForReturn(e.target.value)}
                                    Return={openReturnReason}
                                    type="text"
                    />
                  </FullPointInputBox>
                  <NoticeWrapper TextCount>
                    <span>{reasonForReturn.length}/500</span>
                  </NoticeWrapper>
                </Wrapper>
              </>
            }
          </FullPointBox>
        </OperationPointModal>
      }

      {/*반려 사유 모달*/}
      {onReturnReasonModalState &&
        <OperationPointModal
          setOpenModal={setOnReturnReasonModalState}
          fetch={pointRequest}
          title='포인트 충전 반려 사유'
          handleCharge={() => setOnReturnReasonModalState(false)}
        >
          <FullPointBox Return={onReturnReasonModalState}>
            <PointReturnInfoWrapper Column>
              <span>충전 일자 {pointInfo.createdAt}</span>
              <span>충전 포인트 {pointInfo.amount}</span>
              <span>충전 관리자 {pointInfo.admin}</span>
              <Wrapper margin='48px 0 0 0' Column style={{whiteSpace: 'break-space'}}>
                <span>충전이 반려되었습니다.</span>
                <span> 아래 사유를 확인해주세요..</span>
                <span>{returnReason}</span>
              </Wrapper>
            </PointReturnInfoWrapper>
          </FullPointBox>
        </OperationPointModal>
      }
    </>
  )
}

export default BerryPointDetail;
