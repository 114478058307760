import React from "react";
import {KeyBoxA, KeyBoxCataLi, KeyBoxUl} from './KeywordMangeStyle';

const Categories = ({...props}) => {
    const {categories, initCategory, onSelect, selectedCategoryId, setChannelSearchState} = props;

    const handleClick = (category) => {
        setChannelSearchState({id: category.id})
        onSelect && onSelect(category);
    }

    return (
        <KeyBoxUl>
            {categories && categories.map((category) => {
                return <KeyBoxCataLi key={category.id}
                                     id={parseInt(category.id)}
                                     On={selectedCategoryId ? selectedCategoryId === category.id :
                                         initCategory.id === category.id}
                                     onClick={() => handleClick(category)}>
                    <KeyBoxA
                        On={initCategory.id === category.id}>
                        {category.name}
                        ({category.keywords ? category.keywords.length : 0})
                    </KeyBoxA>
                    {/*{selectedCategoryId ? selectedCategoryId === category.id && <CateDel href="#none">삭제</CateDel>*/}
                    {/*    : initCategory.id === category.id && <CateDel href="#none">삭제</CateDel>}*/}
                </KeyBoxCataLi>
            })}
        </KeyBoxUl>
    )
}

export default Categories
