export const TYPE = {
    MAJOR: 'Public Ranking',
    MINOR: 'Berry Ranking'
};

export const STATUS = {
    ONGOING: '진행중',
    COMPLETE: '완료',
    STOP: '정지'
};

export const RANKING_LIST_COLUMNS = [{
    key: 'id',
    title: 'NO.',
    width: '9%'
}, {
    key: 'categoryName',
    title: '카테고리',
    width: '16%'
}, {
    key: 'title',
    title: '랭킹 제목',
    width: '*'
}, {
    key: 'publicStatus',
    title: 'Public Ranking',
    width: '9%'
}, {
    key: 'berryStatus',
    title: 'Berry Ranking',
    width: '9%'
}, {
    key: 'period',
    title: '투표 기간',
    width: '15%'
}, {
    key: 'participantsCount',
    title: '참여자수',
    width: '9%'
},
];

export const MINOR_STATUS_OPTIONS = [{
    text: STATUS.ONGOING,
    value: 'ONGOING'
}, {
    text: STATUS.COMPLETE,
    value: 'COMPLETE'
}, {
    text: STATUS.STOP,
    value: 'STOP'
}];

export const BERRY_RANKING_STATUS_OPTIONS = [
    {
        label: STATUS.ONGOING,
        value: 'ONGOING'
    }, {
        label: STATUS.COMPLETE,
        value: 'COMPLETE'
    }, {
        label: STATUS.STOP,
        value: 'STOP'
    }
];


export const PUBLIC_RANKING_STATUS_OPTIONS = [
    {
        label: STATUS.ONGOING,
        value: 'ONGOING'
    }, {
        label: STATUS.STOP,
        value: 'STOP'
    }
];

export const VIEW_STATUS = [
    {
        label: 'Daily',
        value: 'DAILY'
    }, {
        label: 'Week',
        value: 'WEEK'
    },
    {
        label: 'Month',
        value: 'MONTH'
    }
];
