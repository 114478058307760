import React from "react";
import {ListRTextArea} from "common/styledComponents/commonStyle";
import {ListInputTxtCount} from "pages/ServeyManage/ChannelSurvey/channelSurveySytle"

const TestTextarea = ({value, onBlur, withLength, maxLength, onChange, ...rest}) => {
    // const [_value, _setValue] = useState(value);
    const handleBlur = () => {
        onBlur && onBlur(value);
    }

    // useEffect(() => {
    //     _setValue(value);
    // }, [value]);

    return (
        <>
            <ListRTextArea placeholder=""
                           value={value}
                           maxLength={maxLength}
                           onChange={onChange}
                // onChange={(e) => {
                //     _setValue(e.target.value)
                // }}
                           onBlur={handleBlur}
                           {...rest}
            />
            {withLength && value && value.length ?
                <ListInputTxtCount>{value && value.length}/{maxLength}자</ListInputTxtCount>
                : <ListInputTxtCount>0/{maxLength}자</ListInputTxtCount>
            }
        </>
    )
}

export default TestTextarea;
