import React, {useEffect, useState} from "react";
import {Option, Select} from "common/styledComponents/commonStyle";

export default function (props) {
    const {
        options,
        initValue,
        onChange,
        ...rest
    } = props;

    const [value, setValue] = useState(initValue);

    useEffect(() => {
        onChange && onChange(value);
    }, [value]);

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    const _onChange = (e) => {
        // console.log("what is the select value >>> " , e.target.value)
        // if (!e.target.value) {
        //     return;
        // }
        setValue(e.target.value);
    }

    return (
        <Select {...rest}
                onChange={_onChange}
                value={value}
        >
            {options && options.map((option, index) => {
                const {value, text} = option;
                return <Option value={value} key={index}>{text}</Option>
            })}
        </Select>
    )
};
