import React, {useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import queryString from 'query-string';
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";

import Tab from "common/components/Tab"
import CategoryManList from './CategoryManList';
import AddPopup from './AddPopup';
import {useGlobalStore} from 'stores';

const CATEGORY_TYPE = {
    channel: 'channel',
    poll: 'poll',
    ranking: 'ranking',
};

const TABS = [{
    text: '채널',
    value: 'channel'
}, {
    text: '설문',
    value: 'poll'
}, {
    text: '랭킹',
    value: 'ranking'
},];


function CategoryMange(props) {
    const navigate = useNavigate();
    const qs = queryString.parse(window.location.search);
    const categoryType = (qs.type && CATEGORY_TYPE[qs.type]) || 'channel';

    const [tab, setTab] = useState(categoryType);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [time, setTime] = useState(new Date().getTime());

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleTabChange = (_tab) => {
        if (tab === _tab) {
            return;
        }
        navigate(`/contents/categoryManage?type=${_tab}`);
        setTab(_tab);
    }

    const handleAddCategory = (tab) => {
        setTab('');
        setTimeout(() => {
            setTab(tab);
        }, 200);
    }

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>
                        카테고리 관리
                        {
                            contentsAuth.find(d => d === "W") &&
                            <Button tit onClick={() => {
                                setShowAddPopup(true);
                            }}>
                                카테고리 추가하기
                            </Button>
                        }

                    </Title>
                    <Tab tabList={TABS} initTab={tab} onChange={handleTabChange}/>
                    <>
                        {tab === 'channel' && <CategoryManList category={tab}/>}
                        {tab === 'poll' && <CategoryManList category={tab}/>}
                        {tab === 'ranking' && <CategoryManList category={tab}/>}
                    </>
                </MenuInfoArea>
            </MenuArea>

            {
                showAddPopup &&
                <AddPopup tab={tab} onAdd={handleAddCategory} setShowAddPopup={setShowAddPopup}
                          showConfirmPopup={showConfirmPopup} setShowConfirmPopup={setShowConfirmPopup}/>
            }

            {/* <PopUp>
             <PopBox>
             <PopTxt>채널의 산업군을 구분하기 위한 카테고리를 입력해주세요.</PopTxt>
             <PopInputTxt>
             <PopInput type='text' placeholder="텍스트 영역입니다. (아이디, 닉네임, 채널명)"></PopInput>
             <Poperror>*오류메세지 영역</Poperror>
             </PopInputTxt>
             <PopButton cate>추가</PopButton>
             </PopBox>
             </PopUp> */}

            {/* {showConfirmPopup &&  <PopUp>
             <PopBox cate>
             <PopCateUl>
             <PopCateli>산업군</PopCateli>
             <PopCateli>텍스트</PopCateli>
             <PopCateli>추가완료</PopCateli>
             </PopCateUl>
             <PopButton cate onClick={()=>setShowConfirmPopup(false)}>확인</PopButton>
             </PopBox>
             </PopUp>} */}


        </>
    );
}

export default CategoryMange;
