export const QNA_LIST_COLUMNS = [
    {
        key: "id",
        title: "NO.",
        width: "5.5%",
    },
    {
        key: "type",
        title: "구분",
        width: "10%",
    },
    {
        key: "qaType",
        title: "문의유형",
        width: "10%",
    },
    {
        key: "title",
        title: "제목",
        width: "40%",
    },
    {
        key: "createdBy",
        title: "작성자",
        width: "10%",
    },
    {
        key: "createAt",
        title: "등록일",
        width: "10%",
    },
    {
        key: "status",
        title: "답변 상태",
        width: "7.5%",
    },
];

export const QNA_SEARCH_FILTER_OPTIONS = [
    {
        text: "전체",
        value: "",
    },
    {
        text: "답변 대기",
        value: "READY",
    },
    {
        text: "답변 완료",
        value: "DONE",
    },
];

export const DATE_FILTER_OPTIONS = [
    {
        text: "전체",
        value: "ALL",
    }, {
        text: "1개월",
        value: "1M",
    },
    {
        text: "3개월",
        value: "3M",
    },
    {
        text: "6개월",
        value: "6M",
    },
    {
        text: "1년",
        value: "1Y",
    },
    {
        text: "직접설정",
        value: "SELECT",
    },
];

export const STATUS = {
    READY: "답변 대기",
    DONE: "답변 완료",
};

export const QNA_SEARCH_KEY_OPTIONS = [
    {
        label: "전체",
        value: "",
    },
    {
        label: "회원가입",
        value: "JOIN",
    },
    {
        label: "회원정보",
        value: "USER_INFO",
    },
    {
        label: "블록체인",
        value: "BLOCKCHAIN",
    },
    {
        label: "설문참여",
        value: "DO_POLL",
    },
    {
        label: "설문생성",
        value: "NEW_POLL",
    },
    {
        label: "보상관련",
        value: "REWARD",
    },
    {
        label: "디지털지갑",
        value: "WALLET",
    },
    {
        label: "랭킹",
        value: "RANKING",
    },
    {
        label: "친구",
        value: "FRIEND",
    },
    {
        label: "신고",
        value: "REPORT",
    },
    {
        label: "오류",
        value: "ERROR",
    },
    {
        label: "기타",
        value: "ETC",
    },
    {
        label: "제휴",
        value: "PARTNER",
    },
];


export const QNA_QA_TYPE_STATUS = {
    '': '전체',
    JOIN: "회원가입",
    USER_INFO: "회원정보",
    BLOCKCHAIN: "블록체인",
    DO_POLL: "설문참여",
    NEW_POLL: "설문생성",
    REWARD: "보상관련",
    WALLET: "디지털지갑",
    RANKING: "랭킹",
    FRIEND: "친구",
    REPORT: "신고",
    ERROR: "오류",
    ETC: "기타",
    PARTNER: "제휴"
};
