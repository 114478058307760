import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import Title from "common/components/Title";
import UserDetailDefault from "pages/User/UserDetail/Default";
import UserDetailAdmin from "pages/User/UserDetail/Admin";
import UserDetailBizz from "pages/User/UserDetail/Bizz";
import UserDetailUser from "pages/User/UserDetail/User";
import {
    Button,
    CloseButton,
    MenuArea,
    MenuInfoArea,
    ModalContainer,
    ModalDetailContent,
    ModalText,
    RewardDetailTitle,
    StyledModal,
    StyledTextArea,
    Wrapper
} from "common/styledComponents/commonStyle";
import {ROLE, ROLE_ADMIN, ROLE_BIZZ, ROLE_SUPER, ROLE_USER} from "common/constants/user";
import {API_STATE} from "common/constants/state";
import DATE from "common/constants/date";
import {useGlobalStore} from "stores";
import CloseIcon from '../../../asset/img/icon__close_black.svg';
import {isValidEmail} from '../../../common/js/Validation';
import PointListModal from '../../../common/components/PointListModal/PointListModal';


const UserDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const {register, handleSubmit, setValue, watch} = useForm();
    const updateUserDetailRequest = useRequest("patch", `user/${id}/update`);
    const channelListRequest = useRequest("get", `channel/simple/list`);
    const userDetailRequest = useRequest("get", `user/${id}`);
    // const pointListRequest = useRequest("get", `user/${id}/point/list`);
    const [userInfo, setUserInfo] = useState({});
    let [searchParams, setSearchParams] = useSearchParams();
    const queryPage = searchParams.get('page')
    const [userChannelDetail, setUserChannelDetail] = useState(null);
    const [onModal, setOnModal] = useState(false);
    const [onPointModal, setOnPointModal] = useState(false);

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const userAuth = useMemo(() => {
        const accessAuth = info?.access?.userAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleEditInfo = (data) => {
        console.log("handleEditInfo >>> ", data);

        if (userInfo.role === ROLE_ADMIN || userInfo.role === ROLE_SUPER) {
            if (data && !isValidEmail(data.email)) {
                alert('이메일 형식을 확인해주세요.')
                return
            }
        }

        if (data && (data.status === 'STOP' || data.status === 'WITHDRAWAL')) {
            if (!data.reasonForStop) {
                openModal();
            } else {
                updateUserDetailRequest.call({...data});
            }
        } else {
            updateUserDetailRequest.call({...data});
        }
    }

    const openModal = () => {
        setOnModal(true)
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () => {
        setOnModal(false)
        document.body.style.overflow = 'auto';
    }

    const handleReasonForStopValue = (e) => {
        e.target.value = e.target.value.slice(0, 500)
        setValue('reasonForStop', e.target.value);
    }

    const openPointModal = (e) => {
        document.body.style.overflow = 'hidden';
        setOnPointModal(true)

    }

    const closePointModal = (e) => {
        document.body.style.overflow = 'auto';
        setOnPointModal(false)
    }

    useEffect(() => {
        userDetailRequest.call()
        channelListRequest.call('', 'body')
    }, []);

    useEffect(() => {
        switch (userDetailRequest.state) {
            case API_STATE.done:
                if (userDetailRequest.response.success) {
                    const {response} = userDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    const userInfo = {
                        ...response,
                        createdAt: dayjs(new Date(response.createdAt)).format(DATE.FORMAT.DOT)
                    }
                    setUserInfo(userInfo);
                }

                break;
            default:
                break;
        }
    }, [userDetailRequest.state, userDetailRequest.error, userDetailRequest.response]);


    useEffect(() => {
        switch (channelListRequest.state) {
            case API_STATE.done:
                if (channelListRequest.response.success) {
                    const {response} = channelListRequest.response;

                    if (!response) {
                        return
                    }

                    if (userInfo) {
                        const resTest = response.filter((data) => data.userName === userInfo.userId)
                        setUserChannelDetail(resTest[0])
                    }
                }
                break;
            default:
                break;
        }

    }, [channelListRequest.state, channelListRequest.error, channelListRequest.response, userInfo]);


    useEffect(() => {
        switch (updateUserDetailRequest.state) {
            case API_STATE.done:
                if (updateUserDetailRequest.response.success) {
                    alert("수정이 완료되었습니다");
                    if (queryPage) {
                        navigate(`/user/list?page=${queryPage}`);
                    } else {
                        navigate("/user/list");
                    }

                    closePointModal()
                }

                if (!updateUserDetailRequest.response.success) {
                    alert(updateUserDetailRequest.response.error.message)
                }
                break;
            default:
                break;
        }
    }, [updateUserDetailRequest.state, updateUserDetailRequest.error, updateUserDetailRequest.response]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title>회원 상세보기</Title>
                    <form onSubmit={handleSubmit(handleEditInfo)}>
                        <UserDetailDefault userInfo={userInfo} register={register}/>
                        {
                            (userInfo.role === ROLE_ADMIN || userInfo.role === ROLE_SUPER) &&
                            <UserDetailAdmin userInfo={userInfo} userAuth={userAuth} register={register}/>
                        }
                        {
                            userInfo.role === ROLE_BIZZ &&
                            <UserDetailBizz userInfo={userInfo} userAuth={userAuth} register={register}
                                            setFormValue={setValue}
                                            userChannelDetail={userChannelDetail}
                                            openPointModal={openPointModal}
                            />
                        }
                        {
                            userInfo.role === ROLE_USER &&
                            <UserDetailUser userAuth={userAuth} userInfo={userInfo} openPointModal={openPointModal}/>
                        }
                        {onModal &&
                            <StyledModal>
                                <ModalContainer stop>
                                    <ModalDetailContent>
                                        <RewardDetailTitle Center>회원 상태 변경</RewardDetailTitle>
                                        <Wrapper Row>
                                            <ModalText>회원 구분</ModalText>
                                            <ModalText>{ROLE[userInfo.role]}</ModalText>
                                        </Wrapper>
                                        <Wrapper Row>
                                            <ModalText>아이디</ModalText>
                                            <ModalText>{userInfo.userId}</ModalText>
                                        </Wrapper>
                                        <Wrapper Column>
                                            <ModalText margin='0 0 6px 0'>
                                                아래에 {watch().status === 'STOP' ? '정지' : '탈퇴'} 사유를 입력해주세요.
                                            </ModalText>
                                            {watch().status === 'STOP' ?
                                                <StyledTextArea {...register('reasonForStop', {
                                                    onChange: handleReasonForStopValue
                                                })}/>
                                                : <StyledTextArea {...register('reasonForWithdrawal', {
                                                    onChange: handleReasonForStopValue
                                                })}/>}

                                            <Wrapper>
                                                <ModalText lengthLimit margin='4px 0 0 0' width='100%' TextRight>
                                                    {watch().reasonForStop ? watch().reasonForStop.length : 0}/500</ModalText>
                                            </Wrapper>
                                        </Wrapper>
                                        <Wrapper Center>
                                            <Button preview mini onClick={closeModal}>취소</Button>
                                            <Button mini type='submit'>확인</Button>
                                        </Wrapper>
                                        <CloseButton onClick={closeModal}>
                                            <img src={CloseIcon} alt='close'/>
                                        </CloseButton>
                                    </ModalDetailContent>
                                </ModalContainer>
                            </StyledModal>}
                    </form>
                </MenuInfoArea>
            </MenuArea>

            {onPointModal &&
                <PointListModal closeDetailModal={closePointModal} biz={userInfo.role === ROLE_BIZZ} id={id}/>
            }
        </>
    )
}

export default UserDetail;
