import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from 'dayjs'
import useRequest from "hooks/useRequest";
import useBoard from "hooks/useBoard";
import DATE from "common/constants/date";
import {CHANNEL_LIST_COLUMNS, STATUS} from 'common/constants/channel';
import ListSearch from "common/components/ListSearch"
import Table from 'common/components/Table'
import Page from 'common/components/Page'
import pageMaker from "common/components/Page/pageMaker"
import {Button, MenuArea, MenuInfoArea, Title} from "common/styledComponents/commonStyle";
import {useGlobalStore} from "stores";

const Channel = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryTitle = searchParams.get('title');
    const queryPage = searchParams.get('page');
    const [totalNum, setTotalNum] = useState(0);
    const [curPage, setCurPage] = useState(1);
    const [formData, setFormData] = useState({
        offset: queryPage ? Number(queryPage) - 1 : 0,
        limit: 10,
        page: queryPage ? Number(queryPage) : 1,
        title: queryTitle || '',
    });
    const pageInfo = pageMaker(formData.offset + 1, formData.limit, totalNum);
    const request = useRequest("get", "channel/list");
    const {rows, fetch} = useBoard(request, (res) => {
        setTotalNum(res.totalCount);
        const {data} = res;
        return data && data.map((data) => ({
            ...data,
            status: STATUS[data.status],
            createdAt: dayjs(new Date(data.createdAt)).format(DATE.FORMAT.DOT),
        }));
    }, {
        offset: (parseInt(searchParams.get('page')) - 1 || 0),
        limit: 10
    });

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const channelAuth = useMemo(() => {
        const accessAuth = info?.access?.channelAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleSelectRow = (row) => {
        navigate(`/channel/detail/${row.id}?${searchParams}`);
    }

    const handleSearch = (data) => {
        const {text} = data;
        const {limit} = formData;

        if (text || text === '') {
            searchParams.set('title', text);
            searchParams.set('page', '1')
            setCurPage(1)
        }

        setSearchParams(searchParams)

        fetch({
            title: text || '',
            offset: 0,
            limit
        });
    };

    const handlePage = (pageNum) => {
        searchParams.set('page', pageNum)
        setFormData({...formData, page: pageNum, offset: pageNum - 1})
        setCurPage(pageNum)
    };

    const moveCreateChannel = () => {
        navigate("/createChannel");
    };

    useEffect(() => {
        setSearchParams(searchParams)

        setFormData(prevState => ({
            ...prevState,
            page: Number(queryPage),
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        }))

        fetch({
            ...formData,
            title: queryTitle || '',
            offset: queryPage ? Number(queryPage) - 1 : 0,
        })

    }, [queryPage, queryTitle]);

    return (
        <>
            <MenuArea>
                <MenuInfoArea>
                    <Title btn>채널 관리
                        {channelAuth?.find(d => d === "W") &&
                            (
                                <Button tit onClick={moveCreateChannel}>
                                    신규 채널 생성하기
                                </Button>
                            )
                        }
                    </Title>
                    <ListSearch onSearch={handleSearch}
                                inputValue={formData.title}
                                onChange={(e) => setFormData(prevState => ({...formData, title: e.target.value}))}
                    />
                    <Table pageData={formData} totalPages={pageInfo?.totalPages} columns={CHANNEL_LIST_COLUMNS}
                           rows={rows} onSelectRow={handleSelectRow} totalNum={totalNum} searchParams={searchParams}/>
                    <Page pageInfo={pageInfo} handlePage={handlePage}/>
                </MenuInfoArea>
            </MenuArea>
        </>
    );
}

export default Channel;
