import styled, { css } from "styled-components";



export const BarBox = styled.span`  
    width: 100%;
    max-width: 980px;
    height: 40px;
    background-color: #dbdbdb;
    display: inline-block;
    ${(props) =>
        props.img &&
        css`
            max-width: 816px;
        `}

`;

export const BarGraph = styled.span`  
    // width: 60%;
    height: 100%;
    background-color: #757575;
    display: inline-block;
    ${(props) =>
        props.first &&
        css`
            background-color: #000065;
        `}
`;
export const BarNumber = styled.span`  
    position: absolute;
    right: 0;
    bottom: 0;
`;
export const BarPercent = styled.span`  
    display: block;
    text-align: center;
    font-size: 24px;
`;
export const BarPerson = styled.span`  
    display: block;
    text-align: center;
    font-size: 16px;
    margin-top: 8px;
`;