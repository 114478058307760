import React from "react";
import {AddCount, ItemDeleteButton, MenuInfoSub, MenuInfoSubButton} from "pages/Mypage/MypageStyle"
import {
    AddFileWrapper,
    DropdownIndicator,
    InputRadioWrapper,
    ItemInputWrapper,
    ItemRemoveButton,
    List,
    ListInput,
    ListLi,
    ListLtxt,
    ListRtxt,
    ListSelectTxt,
    ListUl,
    NoticeWrapper,
    SubjectList,
    SubjectListBox,
    Wrapper
} from "common/styledComponents/commonStyle";
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import ImgBack from "asset/img/img-back.jpg"
import DelIconBig from "asset/img/icon__cancel_big.png";
import {QUESTION_DEFAULT} from '../../../../common/constants/poll';

const MAX_ITEM_LENGTH = 100;

const Item = ({...props}) => {

    const {
        itemType,
        item,
        itemsIndex,
        questionsIndex,
        register,
        limitLength,
        setValue,
        control,
        getValues,
        domain,
    } = props;

    const removeItem = () => {
        const currentQuestions = getValues('questions');

        if (currentQuestions[questionsIndex].items.length === 1) {
            alert('최소 1개 이상은 있어야 합니다.')
            return
        }
        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }
        const result = currentQuestions[questionsIndex].items.filter((v, index) => index !== itemsIndex)
        setValue(`questions[${questionsIndex}].items`, result)
    }

    const removeItemImage = () => {
        const currentQuestions = getValues('questions');

        const result = currentQuestions[questionsIndex].items.map((item, index) => index === itemsIndex ?
            {
                ...item,
                name: '',
                imageUrl: '',
                url: '',
            } : item)
        setValue(`questions[${questionsIndex}].items`, result)
    }

    const onChangeImage = (e) => {
        const file = e.target.files[0]

        if (file) {
            const name = file.name;
            if (name.length) {
                if (name.length > 30) {
                    setValue(`questions[${questionsIndex}].items[${itemsIndex}].name`, name.substring(0, 30) + '...')
                } else {
                    setValue(`questions[${questionsIndex}].items[${itemsIndex}].name`, name)
                }
            }
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onloadend = () => {
                const res = reader.result;
                setValue(`questions[${questionsIndex}].items[${itemsIndex}].url`, file)
                setValue(`questions[${questionsIndex}].items[${itemsIndex}].imageUrl`, res)
            }
        }
        e.target.value = null;
    };

    if (itemType === 'TEXT') {
        return (
            <SubjectList input key={itemsIndex}>
                <ItemInputWrapper>
                    <ListInput long
                               type="text"
                               placeholder=""
                               {...register(`questions[${questionsIndex}].items[${itemsIndex}].text`,
                                   {onChange: (e) => limitLength(e, 50)})}
                    />
                    <NoticeWrapper CategoryCharacterLimit>
                        <span>{item.text.length || 0}/50자</span>
                    </NoticeWrapper>
                </ItemInputWrapper>
                <ItemRemoveButton type="button"
                                  TEXT
                                  onClick={() => {
                                      removeItem(itemsIndex)
                                  }}>
                    <img src={DelIconBig} alt='deleteBtn'/>
                </ItemRemoveButton>
            </SubjectList>
        )
    } else if (itemType === 'URL') {
        return (
            <ItemInputWrapper margin='0 0 24px 0' key={itemsIndex}>
                <ItemInputWrapper width='auto'>
                    <img
                        src={
                            typeof item.url === 'string' ?
                                item.url.includes('amazonaws') ? item.url : item.url === '' ? ImgBack : `${domain}${item.url}`
                                : item.imageUrl || ImgBack}
                        style={{width: "80px", height: "80px"}}
                        alt="img"/>
                </ItemInputWrapper>
                <Wrapper Column margin='0 0 0 24px'>
                    <ItemInputWrapper>
                        <ListInput item
                                   type="text"
                                   placeholder=""
                                   {...register(`questions[${questionsIndex}].items[${itemsIndex}].text`,
                                       {onChange: (e) => limitLength(e, 50)})}
                        />
                        <NoticeWrapper CategoryCharacterLimit>
                            <NoticeWrapper CategoryCharacterLimit width='36px'>
                                <span>{item.text.length || 0}/50자</span>
                            </NoticeWrapper>
                        </NoticeWrapper>
                    </ItemInputWrapper>
                    <Wrapper margin='8px 0 0 0'>
                        <AddFileWrapper width="370px"
                                        justifyContent='unset'>
                            <ItemInputWrapper width='auto'>
                                <p>{item.name}</p>
                                <ItemDeleteButton type="button"
                                                  itemDel
                                                  onClick={(e) => {
                                                      removeItemImage(e)
                                                  }}
                                >
                                    <img src={DelIconBig} alt='deleteBtn'/>
                                </ItemDeleteButton>
                            </ItemInputWrapper>
                            <Controller
                                name={`questions[${questionsIndex}].items[${itemsIndex}].url`}
                                control={control}
                                render={() =>
                                    <input
                                        type="file"
                                        accept="image/jpg, image/png, image/jpeg"
                                        onChange={(e) => onChangeImage(e)}
                                        id={`questions[${questionsIndex}].items[${itemsIndex}].url`}
                                    />
                                }
                                // rules={{required: true}}
                            />
                            <label htmlFor={`questions[${questionsIndex}].items[${itemsIndex}].url`}>
                                파일첨부
                            </label>
                        </AddFileWrapper>
                    </Wrapper>
                    <ItemRemoveButton type="button"
                                      URL
                                      onClick={(e) => {
                                          removeItem(itemsIndex)
                                      }}>
                        <img src={DelIconBig} alt='deleteBtn'/>
                    </ItemRemoveButton>
                </Wrapper>
            </ItemInputWrapper>
        )
    }
}

export const Question = ({...props}) => {
    const {
        question,
        questionsIndex,
        removeQuestion,
        control,
        setValue,
        register,
        SelectCustomSmallStyles,
        limitLength,
        getValues,
        domain,
        questionsUpdate
    } = props;

    const addItem = () => {
        if (question.items.length >= MAX_ITEM_LENGTH) {
            alert("등록 가능 답변 초과");
            return;
        }
        const currentQuestions = getValues('questions');
        currentQuestions[questionsIndex].items.push({text: ''});
        setValue('questions', currentQuestions)
    }

    const resetQuestionItems = (e) => {
        const type = e.target.value;

        questionsUpdate(questionsIndex, {...QUESTION_DEFAULT, itemType: type})
    }

    const resetQuestionAnswerType = (value) => {
        if (value === 1) {
            setValue(`questions[${questionsIndex}].answerType`, 'SINGULAR')
            setValue(`questions[${questionsIndex}].minimum`, 1)
        }
        setValue(`questions[${questionsIndex}].maximum`, 1)
    }

    const resetQuestionAnswerLength = () => {
        setValue(`questions[${questionsIndex}].minimum`, 1)
        setValue(`questions[${questionsIndex}].maximum`, 1)
        setValue(`questions[${questionsIndex}].answerType`, 'SINGULAR')
    }

    return (
        <>
            <MenuInfoSub idx={questionsIndex} key={questionsIndex}>
                질문 {questionsIndex + 1}
                <MenuInfoSubButton del type='button' onClick={() => removeQuestion(questionsIndex)}>질문
                    삭제하기</MenuInfoSubButton>
            </MenuInfoSub>
            <List wide style={{paddingBottom: "80px"}}>
                <ListUl>
                    <ListLi>
                        <ListLtxt top>질문 제목</ListLtxt>
                        <ListRtxt>
                            <ItemInputWrapper>
                                <Controller
                                    name={`questions[${questionsIndex}].title`}
                                    control={control}
                                    render={({field: {onChange, value}}) =>
                                        <ListInput long
                                                   type="text"
                                                   placeholder=""
                                                   value={question.title}
                                            // value={value}
                                                   onChange={(e) => (limitLength(e, 40), onChange(e))}
                                        />
                                    }
                                    // rules={{required: true}}
                                />
                                <NoticeWrapper CategoryCharacterLimit>
                                    <span>{question.title.length || 0}/40자</span>
                                </NoticeWrapper>
                            </ItemInputWrapper>
                        </ListRtxt>
                    </ListLi>
                    <ListLi>
                        <Wrapper width='auto'>
                            <ListLtxt>답변 형태</ListLtxt>
                            <Wrapper width='auto' margin='0 0 0 24px'>
                                <Wrapper width='auto'>
                                    <InputRadioWrapper>
                                        <input type="radio"
                                               defaultValue="SINGULAR"
                                               id={`questions[${questionsIndex}].SINGULAR`}
                                               checked={question.answerType === 'SINGULAR'}
                                               {...register(`questions[${questionsIndex}].answerType`)}
                                               onChange={resetQuestionAnswerLength}
                                        />
                                        <label htmlFor={`questions[${questionsIndex}].SINGULAR`}>단일 선택</label>
                                    </InputRadioWrapper>
                                    <InputRadioWrapper>
                                        <input type="radio" defaultValue="PLURAL"
                                               id={`questions[${questionsIndex}].PLURAL`}
                                               checked={question.answerType === 'PLURAL'}
                                               {...register(`questions[${questionsIndex}].answerType`)}
                                        />
                                        <label htmlFor={`questions[${questionsIndex}].PLURAL`}>복수 선택</label>
                                    </InputRadioWrapper>
                                </Wrapper>
                                <Wrapper width='auto'>
                                    <Wrapper width='auto' Center>
                                        <ListSelectTxt>최소</ListSelectTxt>
                                        <Controller
                                            name={`questions[${questionsIndex}].minimum`}
                                            control={control}
                                            render={({field: {onChange, value}}) =>
                                                <Wrapper width='auto'>
                                                    <Select
                                                        options={question.items.map((item, idx) => {
                                                            return {label: idx + 1, value: idx + 1}
                                                        })}
                                                        value={value}
                                                        placeholder={value}
                                                        styles={SelectCustomSmallStyles}
                                                        components={{DropdownIndicator}}
                                                        isSearchable={false}
                                                        onChange={selectedOption => {
                                                            onChange(selectedOption.value);
                                                        }}
                                                        isDisabled={question.answerType === 'SINGULAR'}
                                                    />
                                                </Wrapper>
                                            }
                                            // rules={{required: true}}
                                        />
                                    </Wrapper>
                                    <Wrapper width='auto' Center>
                                        <ListSelectTxt>최대</ListSelectTxt>
                                        <Controller
                                            name={`questions[${questionsIndex}].maximum`}
                                            control={control}
                                            render={({field: {onChange, value}}) =>
                                                <Wrapper width='auto'>
                                                    <Select
                                                        options={question.items.map((item, idx) => {
                                                            return {label: idx + 1, value: idx + 1}
                                                        })}
                                                        placeholder={value}
                                                        value={value}
                                                        styles={SelectCustomSmallStyles}
                                                        components={{DropdownIndicator}}
                                                        isSearchable={false}
                                                        isDisabled={question.answerType === 'SINGULAR'}
                                                        onChange={selectedOption => (
                                                            resetQuestionAnswerType(selectedOption.value), onChange(selectedOption.value)
                                                        )}
                                                    />
                                                </Wrapper>
                                            }
                                            // rules={{required: true}}
                                        />
                                    </Wrapper>
                                </Wrapper>
                            </Wrapper>
                        </Wrapper>
                    </ListLi>
                    <ListLi>
                        <ListLtxt top>항목 구분</ListLtxt>
                        <ListRtxt>
                            <Wrapper width='auto'>
                                <InputRadioWrapper>
                                    <input type="radio"
                                           value="TEXT"
                                           id={`questions[${questionsIndex}].TEXT`}
                                           checked={question.itemType === 'TEXT'}
                                           {...register(`questions[${questionsIndex}].itemType`, {onChange: (e) => resetQuestionItems(e)})}
                                    />
                                    <label htmlFor={`questions[${questionsIndex}].TEXT`}>텍스트형</label>
                                </InputRadioWrapper>
                                <InputRadioWrapper>
                                    <input type="radio"
                                           value="URL"
                                           id={`questions[${questionsIndex}].URL`}
                                           checked={question.itemType === 'URL'}
                                           {...register(`questions[${questionsIndex}].itemType`, {onChange: (e) => resetQuestionItems(e)})}
                                    />
                                    <label htmlFor={`questions[${questionsIndex}].URL`}>이미지형</label>
                                </InputRadioWrapper>
                            </Wrapper>
                            <SubjectListBox input>
                                {question.items && question.items.map((item, index) => {
                                    return (
                                        item &&
                                        <Item itemType={question.itemType} item={item} itemsIndex={index}
                                              questionsIndex={questionsIndex} key={index}
                                            // onChange={handleChangeItem} onDelete={handleDeleteItem}
                                              register={register} limitLength={limitLength}
                                              setValue={setValue} control={control}
                                              getValues={getValues} domain={domain}
                                        />

                                    )
                                })}
                            </SubjectListBox>
                            <MenuInfoSubButton add Rel type='button' onClick={() => addItem()}
                                               style={{width: "auto"}}>
                                답변 추가
                                <AddCount>({question.items.length}/{MAX_ITEM_LENGTH})</AddCount>
                            </MenuInfoSubButton>
                        </ListRtxt>
                    </ListLi>
                </ListUl>
            </List>
        </>
    )
}
