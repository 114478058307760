import styled, { css } from "styled-components";
import { Button, PollSettingContainer } from "../../../../common/styledComponents/commonStyle";

export const Container = styled(PollSettingContainer)`
    padding: 48px 0 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
`;

export const Wrapper = styled.div`
    display: flex;
    width: ${(props) => props.width || "100%"};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
    background-color: ${(props) => props.backgroundColor};

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
            align-items: center;
            justify-content: space-between;
        `}

    ${(props) =>
        props.Border &&
        css`
            border: 1px solid #dbdbdb;
            border-top: 2px solid #000065;
        `}

    ${(props) =>
        props.Center &&
        css`
            justify-content: center;
        `}

    ${(props) =>
        props.Right &&
        css`
            justify-content: right;
        `}

    ${(props) =>
        props.Header &&
        css`
            span {
                font-size: 18px;
            }

            margin: 0 0 15px 0;
        `}
`;

export const Content = styled.div`
    display: flex;
    width: ${(props) => props.width || "100%"};
    max-width: ${(props) => props.MaxWidth && "608px"};
    margin: ${(props) => props.margin};
    border: ${(props) => props.margin};

    ${(props) =>
        props.Subheading &&
        css`
            width: auto;

            span {
                margin-top: auto;
                font-size: 14px;
                color: #1a1a1a;
            }
        `}

    ${(props) =>
        props.Column &&
        css`
            flex-flow: column;
        `}

    ${(props) =>
        props.right &&
        css`
            justify-content: right;
        `}
`;

export const ItemDetailList = styled.ul`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    li {
        width: 100%;
        height: 60px;
        text-align: center;
        line-height: 60px;
    }

    ${(props) =>
        props.Row &&
        css`
            flex-flow: row;
            flex-wrap: wrap;

            li {
                width: 50%;
            }
        `}
`;

export const CustomButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    font-size: 18px;

    ${(props) =>
        props.Preview &&
        css`
            background-color: #ffffff;
            border: 2px solid #000065;
            color: #000065;
        `}

    & + & {
        margin-left: 20px;
    }
`;
