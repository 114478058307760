import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from 'dayjs'

import useRequest from "hooks/useRequest";
import DATE from "common/constants/date";
import {API_STATE} from "common/constants/state";
import Title from "common/components/Title";
import {UPLOAD_IMAGE_BASE_URL} from "common/constants/env";
import {PollSettingContainer} from "common/styledComponents/commonStyle";
import CommonPlatformView from "../CommonPlatformView"
import {useGlobalStore} from "stores";

function PlatformPollDetailBalance(props) {
    const navigate = useNavigate();
    const params = useParams();
    const {id} = params;
    const platformDetailRequest = useRequest("get", `poll/${id}/event`);
    const updatePlatformDetailRequest = useRequest("patch", `poll/${id}/event/update`);
    const deletePlatformDetailRequest = useRequest("delete", `poll/${id}/event/delete`);
    const platformParticipantsListRequest = useRequest("get", `poll/${id}/event/participants`);
    const platformRewardListRequest = useRequest("get", `poll/${id}/event/reward/list`);
    const platformCompleteRequest = useRequest("patch", `poll/${id}/event/complete`);
    const [platformInfo, setPlatformInfo] = useState();
    const [onStopModal, setOnStopModal] = useState(false);
    const [onCompleteReasonModal, setOnCompleteReasonModal] = useState(false);

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();


    const contentsAuth = useMemo(() => {
        const accessAuth = info?.access?.contentsAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleSubmit = (formData) => {
        updatePlatformDetailRequest.call(formData);
    }

    const handleDelete = () => {
        deletePlatformDetailRequest.call()
    }


    useEffect(() => {
        platformDetailRequest.call()
    }, []);

    useEffect(() => {
        switch (platformDetailRequest.state) {
            case API_STATE.done:
                if (platformDetailRequest.response.success) {
                    const {response} = platformDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    let platformInfo = {
                        ...response,
                        type: response.type.toLowerCase(),
                        startAt: dayjs(new Date(response.startAt)).format(DATE.FORMAT.DASH_TIME),
                        endAt: dayjs(new Date(response.endAt)).format(DATE.FORMAT.DASH_TIME),
                        isView: true
                    }
                    if (platformInfo.items.length > 0) {
                        platformInfo.items = platformInfo.items.map((item) => {
                            // const name = (item.imageUrl.indexOf(UPLOAD_IMAGE_BASE_URL) !== -1)
                            let name;
                            let url = item.url;

                            if (url && url?.indexOf(UPLOAD_IMAGE_BASE_URL) !== -1) {
                                // name = url.split(`${UPLOAD_IMAGE_BASE_URL}eventpoll/item/`)[1];
                                name = url.split(`${UPLOAD_IMAGE_BASE_URL}eventpoll/item/`)[1];
                                url = url.split(`${UPLOAD_IMAGE_BASE_URL}`)[1];
                            }
                            return {
                                ...item,
                                url: typeof url === 'string' ? url : '',
                                name
                            }
                        })
                    }
                    setPlatformInfo(platformInfo);
                }

                break;
            default:
                break;
        }
    }, [platformDetailRequest.state, platformDetailRequest.error, platformDetailRequest.response]);

    useEffect(() => {
        switch (updatePlatformDetailRequest.state) {
            case API_STATE.done:
                if (updatePlatformDetailRequest.response.success) {
                    alert("수정 되었습니다.");
                    navigate("/contents/platformList");
                }

                if (updatePlatformDetailRequest.response.success === false) {
                    const errorMessage = updatePlatformDetailRequest.response.error.message;
                    alert(`error: ${errorMessage}`);
                }

                break;
            default:
                break;
        }
    }, [updatePlatformDetailRequest.state, updatePlatformDetailRequest.error, updatePlatformDetailRequest.response]);

    useEffect(() => {
        switch (deletePlatformDetailRequest.state) {
            case API_STATE.done:
                if (deletePlatformDetailRequest.response.success) {
                    alert("삭제 되었습니다.");
                    navigate("/contents/platformList");
                }

                if (deletePlatformDetailRequest.response.success === false) {
                    const errorMessage = deletePlatformDetailRequest.response.error.message;
                    alert(`error: ${errorMessage}`);
                    navigate("/contents/platformList");
                }
                break;
            default:
                break;
        }
    }, [deletePlatformDetailRequest.state, deletePlatformDetailRequest.error, deletePlatformDetailRequest.response]);

    useEffect(() => {
        switch (platformCompleteRequest.state) {
            case API_STATE.done:
                if (platformCompleteRequest.response.success) {
                    alert("수동완료 처리되었습니다.");
                    navigate("/contents/platformList");
                } else {
                    alert('실패')
                }
                break;
            default:
                break;
        }
    }, [platformCompleteRequest.state, platformCompleteRequest.error, platformCompleteRequest.response]);


    return (
        <PollSettingContainer>
            <Title>플랫폼 설문 상세보기</Title>
            {platformInfo &&
                <>
                    <CommonPlatformView {...platformInfo} contentsAuth={contentsAuth} onSubmit={handleSubmit}
                                        onDelete={handleDelete} setOnStopModal={setOnStopModal}
                                        onStopModal={onStopModal}
                                        platformParticipantsListRequest={platformParticipantsListRequest}
                                        platformRewardListRequest={platformRewardListRequest}
                                        platformCompleteRequest={platformCompleteRequest}
                                        setOnCompleteReasonModal={setOnCompleteReasonModal}
                                        onCompleteReasonModal={onCompleteReasonModal}
                    />
                </>
            }
        </PollSettingContainer>
    );
}

export default PlatformPollDetailBalance;
