import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import useRequest from "../../../../hooks/useRequest";
import {API_STATE} from "../../../../common/constants/state";
import PostListDetail from "../../../../common/components/PostListDetail/PostListDetail";
import {MenuArea} from '../../../../common/styledComponents/commonStyle';
import {useGlobalStore} from "stores";

const PolicyDetail = () => {
    const params = useParams();
    const {id} = params;
    const {register, handleSubmit, watch, setValue, control} = useForm();
    const [editorDate, setEditorData] = useState("");
    const updateQnaDetailRequest = useRequest("patch", `tos/${id}/update`);
    const policyDetailRequest = useRequest("get", `tos/${id}`);
    const tosRemoveRequest = useRequest("delete", `tos/${id}/delete`);
    const [policyInfo, setPolicyInfo] = useState({});
    const navigate = useNavigate();

    const [
        {
            user: {info},
        },
    ] = useGlobalStore();

    const boardAuth = useMemo(() => {
        const accessAuth = info?.access?.boardAccess;
        const authArray = accessAuth.split("");
        return authArray;
    }, [info]);

    const handleEditInfo = (data) => {
        updateQnaDetailRequest.call(data, "body");
    };

    const removePostItem = () => {
        if (!window.confirm("삭제 하시겠습니까?")) {
            return;
        }
        tosRemoveRequest.call('', "body")
    }

    useEffect(() => {
        switch (updateQnaDetailRequest.state) {
            case API_STATE.done:
                if (updateQnaDetailRequest.response.success) {
                    alert('수정 되었습니다');
                    navigate('/tos/list');
                }
                break;
            default:
                break;
        }
    }, [updateQnaDetailRequest.state, updateQnaDetailRequest.error, updateQnaDetailRequest.response]);

    useEffect(() => {
        switch (tosRemoveRequest.state) {
            case API_STATE.done:
                if (tosRemoveRequest.response.success) {
                    alert('삭제 되었습니다');
                    navigate('/tos/list');
                }
                break;
            default:
                break;
        }
    }, [tosRemoveRequest.state, tosRemoveRequest.error, tosRemoveRequest.response]);


    useEffect(() => {
        policyDetailRequest.call(id, "body");
    }, []);

    useEffect(() => {
        switch (policyDetailRequest.state) {
            case API_STATE.done:
                if (policyDetailRequest.response.success) {
                    const {response} = policyDetailRequest.response;
                    if (!response) {
                        return;
                    }
                    setPolicyInfo(response);
                    setValue("title", response.title);
                    setValue("type", response.type);
                    setValue("status", response.status);
                    setValue("contents", response.contents);
                    setValue("createdAt", response.createdAt);
                    setEditorData(response.contents);
                }
                break;
            default:
                break;
        }
    }, [policyDetailRequest.state, policyDetailRequest.error, policyDetailRequest.response]);

    return (
        <MenuArea>
            <form onSubmit={handleSubmit(handleEditInfo)}>
                <PostListDetail
                    info={policyInfo}
                    register={register}
                    navigate={navigate}
                    watch={watch()}
                    type='tos'
                    title="이용약관 상세보기"
                    registerTitle="title"
                    registerContents="contents"
                    setEditorData={setEditorData}
                    editorDate={editorDate}
                    boardAuth={boardAuth}
                    control={control}
                    onRemove={removePostItem}
                />
            </form>
        </MenuArea>
    );
};

export default PolicyDetail;
