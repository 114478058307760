import React,{useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import Step2 from "asset/img/step2.png"


import {Center} from "common/styledComponents/commonStyle";
import {WalletCont , WalletTop , WalletStep , WalletStepTwo , WalletStepImg , WalletBoxWide , WalletTxt , Mnemonic , MnemonicInfo, MnemonicTxt, InputDiv , Input , EyeIcon , ErrorMsg, BlueButton , DisableButton, MnemonicKeyCont, MnemonicKeyUl, MnemonicKeyLi, MnemonicKeyTxt, MnemonicKeyInput, MnemonicButtonGroup, MnemonicButtonLong, MnemonicButtonShort} from "pages/Join/JoinStyle"

const CrtWalletTwo = ({setCurrentStep}) => {
    const [tooltip, setToolTip] = useState(false);
    const nemonicKey = 'abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn,abcdefghijklmn';
    const [insArr, setInsArr] = useState(nemonicKey.split(','));

    const copyNemonicKey = () => {

    }
    return(
        <>
            <Center>
                <WalletCont>
                    <WalletTop>Welcome!</WalletTop>
                    <WalletStep>
                        <WalletStepTwo>Step</WalletStepTwo>
                        <WalletStepImg><img src={Step2} alt=""></img></WalletStepImg>
                    </WalletStep>

                    <WalletBoxWide>
                        <WalletTxt>
                        [채널명] 회원님,<br></br> 
                        디지털 자산을 지키기 위해 니모닉키를 안전하게 저장해주세요.
                        <Mnemonic onClick={()=>setToolTip(!tooltip)}>
                            ‘니모닉키’란?                
                           {tooltip && <MnemonicInfo>
                                <MnemonicTxt>
                                    니모닉(Mnemonic)이란 디지털 지갑을 복구하기 위한 12개의 단어입니다.
                                    니모닉키를 통해 계정 정보를 불러 올 수 있습니다. 
                                    만약 니모닉키를 잃어버릴 경우 계정 정보를 다시 불러올 수 없습니다.

                                    Pollberry는 생성된 디지털 지갑의 니모닉키를 보관하지 않습니다.

                                    반드시 안전하게 저장하여 디지털 자산 정보를 안전하게 지키세요.
                                </MnemonicTxt>
                            </MnemonicInfo>}
                        </Mnemonic>
                        </WalletTxt>

                        <MnemonicKeyCont>
                            <MnemonicKeyUl>
                                {insArr.map((data)=>{
                                    return(
                                        <MnemonicKeyLi>
                                            <MnemonicKeyTxt>{data}</MnemonicKeyTxt>
                                        </MnemonicKeyLi>
                                    )
                                })}
                            </MnemonicKeyUl>
                        </MnemonicKeyCont>
                        <MnemonicButtonGroup>
                            <CopyToClipboard 
                                text={nemonicKey}
                                onCopy={() => alert('니모닉키가 복사되었습니다.')}
                            >
                                <MnemonicButtonLong onClick={copyNemonicKey}>니모닉키 복사하기</MnemonicButtonLong>
                            </CopyToClipboard>
                            <MnemonicButtonShort onClick={()=>setCurrentStep(2)}>확인</MnemonicButtonShort>
                        </MnemonicButtonGroup>
                    </WalletBoxWide>
                </WalletCont>
                
            </Center>
        </>
    )
}

export default CrtWalletTwo;