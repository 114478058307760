import React from "react";
import {useNavigate} from "react-router-dom";
import {Title, BackBtn} from "common/styledComponents/commonStyle";

export default ({children, ...rest}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  return <Title {...rest}><BackBtn onClick={handleClick}/>{children}</Title>
}

