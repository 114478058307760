import React from "react";
import Sampleimg from "asset/img/area__preview.png"


import {Select, Option, ListInput, MenuInfoArea, Title, Sub, Button, List, ListUl, ListLi, ListLtxt, ListRtxt, ListRlink, ListInputFile, ButtonRight} from "common/styledComponents/commonStyle";
import {InputTxtCount, Thum, ThumGroup, ThumNoti, ThumInfo, ThumNameBox, ThumName, ThumAddBtn, ThumNotiList, ChannelJoinCont, ChannelJoinUl, ChannelJoinLi, ChannelJoinDiv, ChannelJoinImg} from "pages/Join/JoinStyle"

const CrtChennal = () => {

    return(
        <>
            <MenuInfoArea>
                <Title>
                    채널 등록
                    <Sub>Pollberry 채널 페이지에 등록될 채널 정보를 입력해주세요.</Sub>
                </Title>
                <ChannelJoinCont>
                    <ChannelJoinUl>
                        <ChannelJoinLi>
                            <ListLtxt>채널 이름<strong>*</strong></ListLtxt>
                            <ListRtxt>
                                <ListInput long type="text" placeholder="입력"></ListInput>
                                <InputTxtCount>0/20자</InputTxtCount>
                            </ListRtxt>
                        </ChannelJoinLi>
                        <ChannelJoinLi>
                            <ChannelJoinDiv>
                                <ListLtxt top>채널 프로필 이미지</ListLtxt>
                                <ListRtxt>
                                    <Thum>
                                        {/* <img src=""></img> */}
                                    </Thum>
                                    <ThumGroup>
                                        <ThumInfo>
                                            <ThumNameBox>
                                                <ThumName>파일명 노출 / 길게 작성될 시 좌측부터 ‘...’ 표기 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출</ThumName>
                                            </ThumNameBox>
                                            <ThumAddBtn htmlFor="filesample">파일 첨부</ThumAddBtn>
                                            <ListInputFile type="file" id="filesample"></ListInputFile>
                                        </ThumInfo>
                                        <ThumNoti>
                                            <ThumNotiList>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg, jpeg, png, bmp</ThumNotiList>
                                            <ThumNotiList>*안내메시지</ThumNotiList>
                                        </ThumNoti>
                                    </ThumGroup>
                                </ListRtxt>
                            </ChannelJoinDiv>
                            <ChannelJoinDiv>
                                <ListLtxt top>채널 썸네일 이미지</ListLtxt>
                                <ListRtxt>
                                    <Thum circle>
                                        {/* <img src=""></img> */}
                                    </Thum>
                                    <ThumGroup>
                                        <ThumInfo>
                                            <ThumNameBox>
                                                <ThumName>파일명 노출 / 길게 작성될 시 좌측부터 ‘...’ 표기 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출 파일명 노출</ThumName>
                                            </ThumNameBox>
                                            <ThumAddBtn htmlFor="filesample">파일 첨부</ThumAddBtn>
                                            <ListInputFile type="file" id="filesample"></ListInputFile>
                                        </ThumInfo>
                                        <ThumNoti>
                                            <ThumNotiList>*권장 크기 및 확장파일 : 1000 x 1000 (윈도 대상 750 x 1000) / jpg, jpeg, png, bmp</ThumNotiList>
                                            <ThumNotiList>*안내메시지</ThumNotiList>
                                        </ThumNoti>
                                    </ThumGroup>
                                </ListRtxt>
                            </ChannelJoinDiv>
                        </ChannelJoinLi>
                        <ChannelJoinLi>
                            <ListLtxt>채널 카테고리</ListLtxt>
                            <ListRtxt>
                                <Select>
                                    <Option>텍스트</Option>
                                </Select>
                            </ListRtxt>
                        </ChannelJoinLi>
                        <ChannelJoinLi>
                            <ListLtxt>채널 설명</ListLtxt>
                            <ListRtxt>
                                <ListInput long type="text" placeholder="입력"></ListInput>
                                <InputTxtCount>0/20자</InputTxtCount>
                            </ListRtxt>
                        </ChannelJoinLi>
                    </ChannelJoinUl>

                    <ButtonRight big>
                        <Button mini>확인</Button>
                    </ButtonRight>
                </ChannelJoinCont>

                <ChannelJoinImg>
                    <img src={Sampleimg}></img>
                </ChannelJoinImg>


            </MenuInfoArea>
        </>
    )
}

export default CrtChennal;
